import debug from 'debug'
import { AuthProvider } from 'react-admin'
import { schemaTraverserPromise } from './dataProvider/buildQuery'
import { apolloClient } from './dataProvider/graphql'
import { SchemaTraverser } from './dataProvider/introspections/SchemaTraverser'
import { CheckTablePermission } from './graphQL/CheckTablePermission'
import { CurrentUserCheck } from './graphQL/CurrentUserCheck'
import { clearEndpointCache } from './hooks/useEndpointAvailable'
import {
  DASHBOARD_ACCESS_ALLOWED_ROLES,
  SMPL_USER_AUTH_API_URL,
} from './lib/config'
import { getLoginPathPageNavigation } from './utils/afterLoginURL'
const log = debug('app:authProvider')

const SESSION_KEY = 'sid'

export const MFAError = 'MFA' as const
export const MFAErrorFailure = 'MFA_FAILURE' as const

export type Permissions = {
  loaded: boolean
  tablePermissions: TablePermission
}

export type OnTablePermissions = {
  canSelect: boolean
  canDelete: boolean
  canInsert: boolean
  canUpdate: boolean
}

export type TablePermission = {
  [tableName: string]: OnTablePermissions | undefined
}

export type GetIdentity = {
  loaded: boolean
  loading: boolean
  identity?: {
    fullName: string
    id: string
    role: string
  }
}

type TablePermissionRaw = {
  name: string
  select: string // "true" or "false"
  delete: string // "true" or "false"
  insert: string // "true" or "false"
  update: string // "true" or "false"
}[]

type MFAResponse = { isMfaRequired: boolean }
// some customers require verification through 2FA tokens
type SuccessResponse = {
  id: string
  path: string
  provider: string
  role: 'admin' | 'user' | 'service' | string
  uid: string
}

async function makeAuthRequest(method: string, endpoint: string, body: Object) {
  const res = await fetch(`${SMPL_USER_AUTH_API_URL}/${endpoint}`, {
    method,
    headers: body
      ? {
          'content-type': 'application/json',
        }
      : {},
    body: body ? JSON.stringify(body) : '',
  })

  let parsed: any
  try {
    parsed = res.status === 204 ? undefined : await res.json()
  } catch (e) {
    console.error('could not parse json from response', res.status)
    console.error(res.statusText)
  }

  if (parsed?.isMfaError) {
    throw MFAErrorFailure
  } else if (parsed?.isMfaRequired) {
    throw MFAError
  }

  if (!res.ok) {
    throw new Error('ra.auth.sign_in_error')
  }
  return parsed
}

export function getToken() {
  return localStorage.getItem(SESSION_KEY)
}

type paramsDefinition = {
  username: string
  password: string
  /** one-time-password (sent via e-mail or from 2FA app) */
  otp?: string
  status: number
}

let cachedAPIAvailable: Promise<boolean> | null = null

async function handleLogoutState() {
  clearEndpointCache()
  cachedAPIAvailable = null
  localStorage.removeItem(SESSION_KEY)
  await apolloClient.resetStore() // can save the current user, permissions and other data
}

const authProvider: AuthProvider = {
  async login(params) {
    cachedAPIAvailable = null
    const { username, password, otp } = params

    const res: SuccessResponse = await makeAuthRequest('POST', 'auth/login', {
      username,
      password,
      otp,
    })
    const successResponse = res as SuccessResponse

    if (!successResponse.id) {
      throw new Error('missing session id in response')
    }

    const allowedLoginRoles: string[] = DASHBOARD_ACCESS_ALLOWED_ROLES
      ? typeof DASHBOARD_ACCESS_ALLOWED_ROLES === 'string'
        ? JSON.parse(DASHBOARD_ACCESS_ALLOWED_ROLES)
        : DASHBOARD_ACCESS_ALLOWED_ROLES
      : ['admin']

    if (!allowedLoginRoles.includes(successResponse.role)) {
      const e = new Error(
        'This account does not have the required access rights to log in. Please use an account with admin rights to log in.'
      )
      // @ts-expect-error
      e.status = 403
      throw e
    }

    localStorage.setItem(SESSION_KEY, successResponse.id)
    return
  },
  async logout(params) {
    try {
      await makeAuthRequest('POST', 'auth/logout', {})
    } catch (e) {
      console.error('failed to log out', e)
    }
    await handleLogoutState()
    // make sure the page is reloaded so that the user can re-login
    const path = getLoginPathPageNavigation()
    location.href = path // we reload the page just in case

    return
  },
  async checkError(error: (Error & { status?: number }) | { status?: number }) {
    const { status, message } = (error as unknown) as Error & {
      status?: number
    }
    if (
      status === 401 ||
      status === 400 ||
      status === 403 ||
      message?.includes('access denied') // can be RBAC: access denied
    ) {
      await handleLogoutState()
      // make sure the page is reloaded so that the user can re-login
      const path = getLoginPathPageNavigation(
        location.pathname + location.search
      )
      location.href = path // we reload the page just in case
      return Promise.reject({
        redirect: path,
      })
    }
  },
  async checkAuth(params) {
    const potentialToken = getToken()
    if (potentialToken) {
      // if there is a token in local storage, check if the API is available
      // since the AUTH_CHECK is called multiple times on every page load, we cache the result
      // this AUTH_CHECK is running a lot of times, so we cache the result
      // and make sure to query only once by caching the promise
      if (!cachedAPIAvailable) {
        cachedAPIAvailable = checkIfAPIAvailable()
      }
      const accessible = await cachedAPIAvailable

      if (!accessible) {
        await handleLogoutState()
        return Promise.reject({
          redirect: '/login',
        })
      }
      return Promise.resolve()
    } else {
      await handleLogoutState()
      return Promise.reject({
        redirect: '/login',
      })
    }
  },
  async getPermissions(params) {
    if (!getToken()) {
      return Promise.resolve({
        loaded: false,
        tablePermissions: {},
      })
    }

    // INFO this is often time not there yet, which will trigger log out - race condition not sure how to fix yet...
    let schemaTraverser = await Promise.race([
      schemaTraverserPromise,
      new Promise<SchemaTraverser>((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('SchemaTraverser not ready in time. Deadlock?'))
        }, 10000)
      }),
    ])

    const accessibleRessources = schemaTraverser
      ? schemaTraverser.resourceNames
      : []

    console.log('query DB for permission on ressource', accessibleRessources)

    const permissionRes: {
      data: { checkRessourcePermissions: TablePermissionRaw }
    } = await apolloClient.query({
      query: CheckTablePermission,
      variables: {
        ressourceNames: accessibleRessources,
      },
      fetchPolicy: 'cache-first',
    })

    return Promise.resolve({
      loaded: true,
      tablePermissions: convertToTablePermissionObject(
        permissionRes.data.checkRessourcePermissions
      ),
    })
  },
  async getIdentity() {
    return await apolloClient
      .query({
        // will be cached automatically by apollo!
        query: CurrentUserCheck,
        fetchPolicy: 'cache-first', // should be already cached through checkIfAPIAvailable
      })
      .then((rs) => {
        const data = rs.data?.currentUser
        const fullName =
          data?.name ||
          `${data?.givenName || ''} ${data?.familyName || ''}`.trim()

        return {
          id: data?.id,
          fullName: fullName ? fullName : rs.data.currentUser?.username,
          role: data?.role,
        }
      })
  },
}
export default authProvider

export async function checkIfAPIAvailable() {
  try {
    log('checking if API server is available')
    const rs = await apolloClient.query({
      query: CurrentUserCheck,
      fetchPolicy: 'network-only',
    })

    const data = rs.data?.currentUser?.id
    log('loaded user data:', rs?.data?.currentUser)
    return !!data
  } catch (e) {
    console.error('failed to check if data api is up', e)
    return false
  }
}

function convertToTablePermissionObject(
  tablePermissions: TablePermissionRaw
): TablePermission {
  const tablePermissionObject: TablePermission = {}

  for (const permission of tablePermissions) {
    const { name } = permission

    tablePermissionObject[name] = {
      canSelect: permission.select === 'true',
      canDelete: permission.delete === 'true',
      canInsert: permission.insert === 'true',
      canUpdate: permission.update === 'true',
    }
  }

  return tablePermissionObject
}
