import { Button, CardActions, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DehazeIcon from '@material-ui/icons/Dehaze'
import arrayMove from 'array-move'
import { useRefresh } from 'ra-core'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { FieldProps, useDataProvider, useNotify } from 'react-admin'
import { useMutation } from 'react-apollo-hooks'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'
import { apolloClient } from '../../dataProvider/graphql'
import { MovieForSortableListFieldByIdQuery } from '../../graphQL/movie'
import { SeriesForSortableListFieldByIdQuery } from '../../graphQL/series'
import { smplColors } from '../../layout/themes'
import { DataGridModal } from '../renderInput'
import { CustomShowButton } from './SortableListFieldsCategories'

import MovieIcon from '@material-ui/icons/Movie'
import TheatersIcon from '@material-ui/icons/Theaters'
import { CreateCmsMovieContentCategory } from '../../__generated__/CreateCmsMovieContentCategory'
import { UpdateCmsMovieContentCategoryByCategoryId } from '../../__generated__/UpdateCmsMovieContentCategoryByCategoryId'
import {
  CmsMovieContentCategoryInput,
  CmsMovieContentCategoryPatch,
} from '../../__generated__/globalTypes'
import noCover from '../../assets/NoCoverImage.jpg'

import {
  CreateCmsMovieContentCategoryMutation,
  UpdateCmsMovieContentCategoryByCategoryIdMutation,
} from '../../graphQL/cmsMovieContentByCategoryId'

import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'

const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(85vw - 240px)',
    display: 'flex',
    flexDirection: 'column',
  },
  containerSort: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '0.25rem',
  },
  itemHandle: {
    opacity: 0.8,
    cursor: 'row-resize',
    color: smplColors.primary.main,
    width: '40px',
  },
  // INFO giving items a variable width would lead to 'jumping' behavior of the last element in row
  containerItem: {
    position: 'relative',
    listStyleType: 'none',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background:
      'linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.05) 100%)',
    // backdropFilter: 'blur(10px)',
    paddingLeft: '0',
    paddingRight: '1.5rem',
    borderRadius: '0.5rem',
    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.1) 100%)',
      '& img': {
        filter: 'grayscale(0)',
        opacity: 1,
      },
      '& svg': {
        opacity: 0.75,
      },
    },
  },
  itemText: {
    width: 'auto',
    textAlign: 'center',
  },
  itemTitle: {
    // width: '400px',
    flex: '1',
  },
  itemImage: {
    width: 'auto',
    minWidth: '150px',
    height: '80px',
    borderRadius: '0.25rem',
    marginLeft: '0.5rem',
    filter: 'grayscale(0.75)',
    opacity: 0.5,
  },
  icon: {
    position: 'absolute',
    color: smplColors.primary.main,
    right: 120,
    transform: 'scale(2) rotate(5deg)',
    opacity: '0.1',
    pointerEvents: 'none',
  },
  actionBar: {
    paddingLeft: '40px',
  },
  sortableHelper: {
    zIndex: 100,
    background: `${smplColors.secondary.main}55`,
    border: `1px solid ${smplColors.secondary.main}`,
    boxShadow: '1rem 1rem 1rem #00000035',
    backdropFilter: 'blur(6px)',
    '& img': {
      filter: 'grayscale(0)',
      opacity: 0.5,
    },
    '& svg': {
      opacity: 1,
      color: smplColors.secondary.main,
    },
    '& div:nth-child(5)': {
      color: smplColors.secondary.main,
    },
  },
  paper: {
    backgroundColor: 'white',
    width: '80vw',
    height: '80vh',
    left: '10vw',
    top: '10vh',
    overflow: 'auto',
  },
  tinyMenu: {
    display: 'flex',
    flexDirection: 'column',
    height: '80px',
    justifyContent: 'center',
    borderRadius: '0.5rem',
  },
  arrowButtons: {
    height: '40px',
    '& svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%) scale(1)',
      transition: '0.4s',
    },
    '&:hover': {
      '& svg': {
        transform: 'translate(-50%, -50%) scale(1.5)',
      },
    },
  },
}))

const targetResource = 'cmsMovieContentCategoriesByCategoryId'

const SortContainer = SortableContainer<{ children: ReactNode }>(
  ({ children }: any) => {
    const classes = useStyles()
    return <ul className={classes.containerSort}>{children}</ul>
  }
)
const DragHandle = SortableHandle(() => {
  const classes = useStyles()
  return <DehazeIcon className={classes.itemHandle} />
})

type SortableItemProps = {
  value: CmsMovieContentCategoryInput & {
    imageUrl?: string | undefined
    title?: string
  }
  toTop: (itemIndex: number) => void
  toBottom: (itemIndex: number) => void
  itemIndex: number
}
const SortableItem = SortableElement<SortableItemProps>(
  (props: SortableItemProps) => {
    const classes = useStyles()
    const { value, toTop, toBottom, itemIndex } = props
    const isSeries = value.contentSeriesId !== null

    return (
      <>
        <li className={classes.containerItem}>
          <div className={classes.tinyMenu}>
            <Tooltip title="Move item to top" placement="left">
              <Button
                color="primary"
                onClick={() => toTop(itemIndex)}
                startIcon={<KeyboardArrowUpIcon />}
                className={classes.arrowButtons}
                style={{ borderRadius: '0.5rem 0 0 0' }}
              />
            </Tooltip>
            <Tooltip title="Move item to bottom" placement="left">
              <Button
                color="primary"
                onClick={() => toBottom(itemIndex)}
                startIcon={<KeyboardArrowDownIcon />}
                className={classes.arrowButtons}
                style={{ borderRadius: '0 0 0 0.5rem' }}
              />
            </Tooltip>
          </div>
          <DragHandle />
          <img
            className={classes.itemImage}
            src={
              value.imageUrl !== undefined
                ? `${value.imageUrl}?edits=eyJyZXNpemUiOnsid2lkdGgiOjI1NSwiaGVpZ2h0IjoxNDQsImZpdCI6Imluc2lkZSJ9fQ%3D%3D`
                : noCover
            }
            alt=""
          />
          {isSeries ? (
            // <div className={classes.itemTag}>Series</div>
            <TheatersIcon className={classes.icon} />
          ) : (
            // <div className={classes.itemTag}>Movie</div>
            <MovieIcon className={classes.icon} />
          )}
          <div className={classes.itemTitle}>{value.title}</div>
          <CustomShowButton
            contentResourceType={isSeries ? 'CmsSery' : 'CmsMovie'}
            contentId={isSeries ? value.contentSeriesId : value.contentMovieId}
          />
        </li>
      </>
    )
  }
)

const AddMovieSubCat = ({
  openModal,
  label,
}: {
  openModal: Function
  label?: string
}) => {
  return (
    <Button
      aria-label="Open Selection Modal"
      color="primary"
      onClick={() => openModal()}
      size="small"
      startIcon={<MovieIcon />}
    >
      {label ?? 'Add'}
    </Button>
  )
}
const AddSeriesSubCat = ({
  openModal,
  label,
}: {
  openModal: Function
  label?: string
}) => {
  return (
    <Button
      aria-label="Open Selection Modal"
      color="primary"
      onClick={() => openModal()}
      size="small"
      startIcon={<TheatersIcon />}
    >
      {label ?? 'Add'}
    </Button>
  )
}

export const SortableListFieldsMovies = (props: FieldProps) => {
  const { record } = props

  const { id: categoryId, __typename: baseResource } = record!
  const [refetch, setRefetch] = useState(true)
  const [queriedData, setQueriedData] = useState<
    Array<{ [fieldName in string]: any }>
  >()
  const [openSelectMovieModal, setOpenSelectMovieModal] = useState(false)
  const [openSelectSeryModal, setOpenSelectSeryModal] = useState(false)

  const [selectedContent, setSelectedContent] = useState<{
    contentId: SMPL$Guid
    contentResource: 'CmsMovie' | 'CmsSery'
  } | null>(null)
  const [{ items }, setItems] = useState<{
    items: CmsMovieContentCategoryInput[] | null
  }>({ items: null })

  const dataProvider = useDataProvider()
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()

  // CmsMovie
  const openSelectMovie = () => {
    setOpenSelectMovieModal(true)
  }
  const closeSelectMovie = () => {
    setOpenSelectMovieModal(false)
  }

  // CmsSery
  const openSelectSery = () => {
    setOpenSelectSeryModal(true)
  }
  const closeSelectSery = () => {
    setOpenSelectSeryModal(false)
  }

  const allCategoriesQuery = useRef()

  const createCmsMovieContentCategory = useMutation<CreateCmsMovieContentCategory>(
    CreateCmsMovieContentCategoryMutation
  )

  // put item from specific index top of the list
  const toTop = async (itemIndex: number) => {
    const element = document.querySelectorAll('li')
    element[itemIndex].classList.add('sortableListUpwards')
    setTimeout(() => {
      element[itemIndex].classList.remove('sortableListUpwards')
    }, 500)
    await new Promise((r) => setTimeout(r, 500))
    if (itemIndex === 0) {
      notify('item is already on top of the list', 'warning')
    }
    const itemToMoveOnTop = items?.splice(itemIndex, 1)
    if (itemToMoveOnTop && items) {
      setItems({ items: [...itemToMoveOnTop, ...items] })
    } else {
      notify('An error occurred. Please refresh the page.', 'warning')
    }
  }

  // put item from specific index bottom of the list
  const toBottom = async (itemIndex: number) => {
    const element = document.querySelectorAll('li')
    element[itemIndex].classList.add('sortableListDownwards')
    setTimeout(() => {
      element[itemIndex].classList.remove('sortableListDownwards')
    }, 500)
    await new Promise((r) => setTimeout(r, 500))
    if (items) {
      if (itemIndex === items.length - 1) {
        notify('item is already on bottom of the list', 'warning')
      }
      const itemToMoveOnBottom = items?.splice(itemIndex, 1)
      if (itemToMoveOnBottom) {
        setItems({ items: [...items, ...itemToMoveOnBottom] })
      }
    } else {
      notify('An error occurred. Please refresh the page.', 'warning')
    }
  }

  // update list with newly added content, if any content was selected from the modals
  useEffect(() => {
    if (selectedContent) {
      dataProvider
        .getManyReference(baseResource, {
          // @ts-ignore
          pagination: { page: 1, perPage: 1000 },
          filter: {},
          sort: { field: 'sortOrder', order: 'ASC' },
          target: targetResource,
          id: categoryId,
        })
        .then(({ data }: any) => {
          allCategoriesQuery.current = data
        })
        .then(async () => {
          // check if the selected element already exists in the current list
          // @ts-ignore
          const isContentAlreadyInList = allCategoriesQuery!.current!.find(
            (e: { [fieldName in string]: any }) => {
              if (selectedContent.contentResource === 'CmsMovie') {
                if (e?.contentMovieId === selectedContent.contentId) {
                  return true
                } else {
                  return false
                }
              } else {
                if (e?.contentSeriesId === selectedContent.contentId) {
                  return true
                } else {
                  return false
                }
              }
            }
          )
          // content (CmsMovie/CmsSery) does already exist in related list, nothing to do here
          if (isContentAlreadyInList) {
            setSelectedContent(null)
            notify('Can not add content more then once', 'warning')
          }
          // create new CmsMovieContentCategory entry for linking the content with the current category
          else {
            await createCmsMovieContentCategory({
              variables: {
                input: {
                  id: '000000000000000', // will be replaced on creation
                  categoryId: categoryId,
                  contentMovieId:
                    selectedContent?.contentResource === 'CmsMovie'
                      ? selectedContent.contentId
                      : null,
                  contentSeriesId:
                    selectedContent?.contentResource === 'CmsSery'
                      ? selectedContent.contentId
                      : null,
                } as CmsMovieContentCategoryInput,
              },
            })
            notify(
              `added ${
                selectedContent?.contentResource === 'CmsMovie'
                  ? 'movie'
                  : 'sery'
              } with id ${selectedContent.contentId}`,
              'success'
            )
            setSelectedContent(null)
            refresh()
          }
        })
        .catch((error: any) => console.error(error))
    }
  }, [
    allCategoriesQuery,
    selectedContent,
    refresh,
    notify,
    categoryId,
    baseResource,
    dataProvider,
  ])

  // on refresh: fetching data to render
  useEffect(() => {
    if (refetch) {
      dataProvider
        .getManyReference(baseResource, {
          // @ts-ignore
          pagination: { page: 1, perPage: 1000 },
          filter: {},
          sort: { field: 'sortOrder', order: 'ASC' },
          target: targetResource,
          id: categoryId,
        })
        .then(({ data }: any) => {
          // TODO: add typing via apollo (CmsMovieContentCategory)
          setQueriedData(data)
        })
        .catch((error: any) => console.error(error))
    }
    setRefetch(false)
  }, [
    dataProvider,
    JSON.stringify(queriedData),
    refetch,
    baseResource,
    categoryId,
  ])

  useEffect(() => {
    // sortOrder is ASC therefore the data array will have elem with sortOrder=1 at the beginning
    const movieOrSeriesPromises: Promise<any>[] = []

    if (typeof queriedData !== 'undefined' && !refetch && items === null) {
      queriedData.forEach((elem: any) => {
        // fetch movie metadata
        if (elem?.contentMovieId) {
          movieOrSeriesPromises.push(
            apolloClient.query({
              query: MovieForSortableListFieldByIdQuery,
              variables: {
                id: elem.contentMovieId,
              },
            })
          )
        }
        // fetch series metadata
        if (elem?.contentSeriesId) {
          movieOrSeriesPromises.push(
            apolloClient.query({
              query: SeriesForSortableListFieldByIdQuery,
              variables: {
                id: elem.contentSeriesId,
              },
            })
          )
        }
      })
      // manipulate sorted items (CmsMovieContentCategory) and add required fields title, widescreenImageUrl
      const allItemsWithMetadata: any = []
      Promise.all(movieOrSeriesPromises).then((movieItems) => {
        queriedData.forEach((elem: any, index) => {
          const currentItem = {
            ...elem,
            sortOrder:
              elem.sortOrder !== null
                ? elem.sortOrder
                : queriedData.indexOf(elem) + 1,
          }

          if (movieItems?.[index]?.data?.qry) {
            // get title, if any
            if (movieItems[index].data.qry?.title) {
              currentItem.title = movieItems[index].data.qry.title
            }
            // get widescreen image url, if any
            if (movieItems[index].data.qry?.widescreenImage?.masterUrl)
              currentItem.imageUrl =
                movieItems[index].data.qry.widescreenImage.masterUrl
          }
          allItemsWithMetadata.push(currentItem)
        })
        if (allItemsWithMetadata) {
          setItems({ items: [...allItemsWithMetadata] })
        }
      })
    }
  }, [JSON.stringify(queriedData), refetch, JSON.stringify(items)])

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setItems(({ items }) => {
      if (items) {
        return { items: arrayMove(items, oldIndex, newIndex) }
      }
      return { items }
    })
  }

  const fakeActionProps: FakeSortListActionsProps = {
    basePath: '/' + baseResource,
    openSelectMovie: openSelectMovie,
    openSelectSery: openSelectSery,
  }

  if (!items)
    return (
      <div className={classes.container}>
        <FakeSortListActions {...fakeActionProps} />

        {/* Modal for adding a CmsMovie*/}
        <DataGridModal
          open={openSelectMovieModal}
          onClose={closeSelectMovie}
          resource={'CmsMovie'}
          basePath={`/${'CmsMovie'}`}
          location={{
            hash: '',
            pathname: `/${'CmsMovie'}`,
            search: '',
            state: null,
          }}
          calledFromModal={true}
          setFunction={(contentId: SMPL$Guid) => {
            setSelectedContent({ contentId, contentResource: 'CmsMovie' })
          }}
          openedModal={setOpenSelectMovieModal}
          hasCreateInModal={false}
        />
        {/* Modal for adding a CmsSery*/}
        <DataGridModal
          open={openSelectSeryModal}
          onClose={closeSelectSery}
          resource={'CmsSery'}
          basePath={`/${'CmsSery'}`}
          location={{
            hash: '',
            pathname: `/${'CmsSery'}`,
            search: '',
            state: null,
          }}
          calledFromModal={true}
          setFunction={(contentId: SMPL$Guid) => {
            setSelectedContent({ contentId, contentResource: 'CmsSery' })
          }}
          openedModal={setOpenSelectSeryModal}
          hasCreateInModal={false}
        />
      </div>
    )

  if (items && queriedData) {
    const actionProps: SortListActionsProps = {
      items: items,
      previousData: queriedData,
      baseResource: baseResource,
      openSelectMovie: openSelectMovie,
      openSelectSery: openSelectSery,
    }

    return (
      <>
        <div className={classes.container}>
          <SortListActions {...actionProps} />
          <SortContainer
            onSortEnd={onSortEnd}
            useDragHandle
            helperClass={classes.sortableHelper}
          >
            {items.map((value, index) => (
              <SortableItem
                toTop={toTop}
                toBottom={toBottom}
                key={value.id}
                index={index}
                itemIndex={index}
                value={value}
              />
            ))}
          </SortContainer>
        </div>

        {/* Modal for adding a CmsMovie*/}
        <DataGridModal
          open={openSelectMovieModal}
          onClose={closeSelectMovie}
          resource={'CmsMovie'}
          basePath={`/${'CmsMovie'}`}
          location={{
            hash: '',
            pathname: `/${'CmsMovie'}`,
            search: '',
            state: null,
          }}
          calledFromModal={true}
          setFunction={(contentId: SMPL$Guid) => {
            setSelectedContent({ contentId, contentResource: 'CmsMovie' })
          }}
          openedModal={setOpenSelectMovieModal}
          hasCreateInModal={false}
        />
        {/* Modal for adding a CmsSery*/}
        <DataGridModal
          open={openSelectSeryModal}
          onClose={closeSelectSery}
          resource={'CmsSery'}
          basePath={`/${'CmsSery'}`}
          location={{
            hash: '',
            pathname: `/${'CmsSery'}`,
            search: '',
            state: null,
          }}
          calledFromModal={true}
          setFunction={(contentId: SMPL$Guid) => {
            setSelectedContent({ contentId, contentResource: 'CmsSery' })
          }}
          openedModal={setOpenSelectSeryModal}
          hasCreateInModal={false}
        />
      </>
    )
  }

  return <div></div>
}

type FakeSortListActionsProps = {
  basePath: string
  openSelectMovie: Function
  openSelectSery: Function
}

const FakeSortListActions = (props: FakeSortListActionsProps) => {
  const { openSelectMovie, openSelectSery } = props
  const classes = useStyles()
  return (
    <CardActions className={classes.actionBar}>
      <Button
        aria-label="Save to database"
        color="primary"
        variant="contained"
        size="small"
        disabled
      >
        Save Order
      </Button>
      <AddMovieSubCat openModal={openSelectMovie} label="Add Movie" />
      <AddSeriesSubCat openModal={openSelectSery} label="Add Series" />
    </CardActions>
  )
}

type SortListActionsProps = {
  items: Array<{ [fieldName in string]: any }>
  previousData: Array<{ [fieldName in string]: any }>
  baseResource: string
  openSelectMovie: Function
  openSelectSery: Function
}

const SortListActions = (props: SortListActionsProps) => {
  const { items, previousData, openSelectMovie, openSelectSery } = props
  const notify = useNotify()
  const classes = useStyles()
  const updateCmsMovieContentCategory = useMutation<UpdateCmsMovieContentCategoryByCategoryId>(
    UpdateCmsMovieContentCategoryByCategoryIdMutation
  )

  // saving order
  const update = useCallback(
    async (
      currentOrder: number,
      previousElem: { [fieldName in string]: any }
    ) => {
      /**  TODO: properly check for fields which are not allowed within {@link CmsMovieContentCategoryPatch}*/
      delete previousElem.nodeId
      delete previousElem.__typename
      delete previousElem.isValid
      await updateCmsMovieContentCategory({
        variables: {
          id: previousElem.id,
          patch: {
            ...previousElem,
            sortOrder: currentOrder,
          } as CmsMovieContentCategoryPatch,
        },
      })
    },
    []
  )

  // saving order
  const updateDatabase = useCallback(async () => {
    let updated = false // is there at least 1 change in order?
    const promises = items.map(async (item) => {
      const index = items.indexOf(item)
      const previousElem = previousData.find(
        (e: { [fieldName in string]: any }) => e.id === item.id
      )
      if (
        previousElem &&
        (previousElem.sortOrder === null ||
          index + 1 !== previousElem.sortOrder)
      ) {
        const currentOrder = index + 1
        updated = true
        return await update(currentOrder, previousElem)
      }
    })

    await Promise.all(promises).then(() => {
      if (updated) {
        notify('Changes to the sort order have been saved.', 'success')
      } else {
        notify('No changes in the sort order.', 'warning')
      }
    })
  }, [items, previousData, update, notify])

  return (
    <CardActions className={classes.actionBar}>
      <Button
        aria-label="Save to database"
        onClick={updateDatabase}
        color="primary"
        variant="outlined"
        size="small"
      >
        Save Order
      </Button>
      <AddMovieSubCat openModal={openSelectMovie} label="Add Movie" />
      <AddSeriesSubCat openModal={openSelectSery} label="Add Series" />
      {/* <ExportButton {...props} /> */}
    </CardActions>
  )
}
