import gql from 'graphql-tag'
import pluralize from 'pluralize'
import { Resource } from './ReactAdmin-Types'
import { nameFromResource } from './SchemaTraverser'
import { getFragmentForType } from './getFragmentForType'

export type GetManyParams = {
  readonly ids: string[]
}

const getListQuery = function getListQuery(
  queryName: string,
  resource: Resource
) {
  const {
    fragment: AllTypesFragment,
    fragmentName: AllTypesFragmentName,
  } = getFragmentForType(resource.type)
  const query = gql
  return query`
          ${AllTypesFragment}
  
          query ${queryName}($ids: [Guid!]!) {
              data: ${queryName}(filter: { id: { in: $ids }}) {
                  totalCount
                  nodes {
                      ...${AllTypesFragmentName}
                  }
              }
          }
      `
}

export function getListQueryName(resource: Resource) {
  return `all${pluralize(nameFromResource(resource))}`
}

export function graphQlQueryDescriptor(
  queryName: string,
  resource: Resource,
  params: GetManyParams
) {
  return {
    query: getListQuery(queryName, resource),
    variables: params, // params = { ids: [...] }
    parseResponse: (response: Object) => {
      const {
        // @ts-ignore
        data: {
          data: { nodes, totalCount },
        },
      } = response

      return {
        data: nodes,
        total: totalCount,
      }
    },
  }
}
