import Button from '@material-ui/core/Button'
import InputLabel from '@material-ui/core/InputLabel'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useState } from 'react'
import { useNotify } from 'react-admin'
import { useMutation } from 'react-apollo-hooks'
import Select from 'react-dropdown-select'
import { UpdateUser } from '../../__generated__/UpdateUser'
import { UserPatch } from '../../__generated__/globalTypes'
import { UsersMutation } from '../../graphQL/userCountry'
import { countryOptions } from '../settings/OptionSetting'

const useStyles = makeStyles(() => ({
  selectionLabel: {
    marginTop: 10,
  },
  selectionContainer: {
    display: 'flex',
    marginBottom: '10px',
    marginTop: '10px',
  },
  selectBox: {
    // width is sometimes overwritten by system values, fix:
    minWidth: '230px',
    maxWidth: '230px',
    marginRight: '10px',
    overflow: 'show',
  },
}))

type CountrySelectionFieldsProps = {
  id: string
  onSave: (text: string) => void
  withLabel?: boolean
}

type SaveCountryButtonProps = {
  id: string
  selectedCountry: Array<{ [fieldName in string]: string }>
  onSave: (text: string) => void
}

const SaveCountryButton = (props: SaveCountryButtonProps) => {
  const { id, selectedCountry, onSave } = props
  const { value } = selectedCountry[0]
  const notify = useNotify()
  const approve = useMutation<UpdateUser>(UsersMutation)

  const handleSave = async () => {
    const userPatch: UserPatch = {
      paymentCountry: value.toUpperCase(),
    }

    await approve({
      variables: {
        id: id,
        patch: userPatch,
      },
    })

    notify('Payment country successfully set to ' + value.toUpperCase() + '.')

    onSave(value)
  }

  return (
    <Button onClick={() => handleSave()} variant="contained" color="primary">
      Save
    </Button>
  )
}

export const CountrySelectionFields = (props: CountrySelectionFieldsProps) => {
  const { id, onSave, withLabel = true } = props
  const classes = useStyles()
  const [selectedCountry, setSelectedCountry] = useState([
    // default save is to Germany without the need of selection
    countryOptions[0],
  ])
  const theme = useTheme()

  return (
    <div>
      {withLabel ? (
        <InputLabel id="select-label" className={classes.selectionLabel}>
          Set Country
        </InputLabel>
      ) : null}

      <div className={classes.selectionContainer}>
        <Select
          className={classes.selectBox}
          options={countryOptions}
          values={selectedCountry}
          onChange={(values) => {
            setSelectedCountry(values)
          }}
          placeholder="Germany"
          dropdownPosition="auto"
          dropdownHeight="200px"
          clearOnSelect
          searchable={true}
          multi={false}
          create={true}
          color={theme.palette.primary.main}
          onCreateNew={(values) => {
            const formatted = (values.value = values.value
              .substring(0, 2)
              .toUpperCase())
            setSelectedCountry([{ value: formatted, label: formatted }])
          }}
        />
        <SaveCountryButton
          id={id}
          selectedCountry={selectedCountry}
          onSave={onSave}
        />
      </div>
    </div>
  )
}
