import Collapse from '@material-ui/core/Collapse'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { withStyles } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import React, { Fragment } from 'react'
import { translate } from 'react-admin'
import compose from 'recompose/compose'

const styles = {
  listItem: {
    margin: '0.06rem 0.5rem',
    padding: '1rem 0.5rem',
    background: '#00000008',
    width: 'calc(100% - 0.8rem)',
    borderRadius: '0.5rem',
  },
  listItemOpen: {
    margin: '0.06rem 0.5rem',
    padding: '1rem 0.5rem',
    background: '#00000008',
    width: 'calc(100% - 0.8rem)',
    borderRadius: '0.5rem',
  },
  listItemText: {
    paddingLeft: 2,
    fontSize: '0.8rem',
  },
  sidebarIsOpen: {
    marginLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  sidebarIsClosed: {
    paddingLeft: 0,
    transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  icon: {
    minWidth: '38px',
  },
}

const SubMenu = ({
  handleToggle,
  sidebarIsOpen,
  isOpen,
  name,
  icon,
  classes,
  children,
  translate,
}) => (
  <Fragment>
    <ListItem
      dense
      button
      onClick={handleToggle}
      className={isOpen ? classes.listItemOpen : classes.listItem}
    >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <ListItemText
        inset
        primary={isOpen ? translate(name) : ''}
        secondary={isOpen ? '' : translate(name)}
        className={classes.listItemText}
      />
    </ListItem>
    <Collapse in={isOpen} timeout="auto" unmountOnExit>
      <List
        dense
        component="div"
        disablePadding
        className={
          sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
        }
      >
        {children}
      </List>
      {/* <Divider /> */}
    </Collapse>
  </Fragment>
)

const enhance = compose(withStyles(styles), translate)

export default enhance(SubMenu)
