import gql from 'graphql-tag'

export const AllAMLTagsQuery = gql`
  query allAMLTagsReasons {
    monitoringTags {
      nodes {
        tag: type
      }
    }
  }
`

export const ToggleAMLTagMutation = gql`
  mutation toggleAMLTag($input: LogAndSetMonitoringTagsInput!) {
    logAndSetMonitoringTags(input: $input) {
      clientMutationId
    }
  }
`
