import { IntrospectionType } from 'graphql'
import gql from 'graphql-tag'
import isEmpty from 'lodash/isEmpty'
import lowerFirst from 'lodash/lowerFirst'
import { Record } from 'react-admin'
import { cleanFields } from '../../genericData/customSave/movieSave'
import { Resource } from './ReactAdmin-Types'
import { getFragmentForType } from './getFragmentForType'

export type CreateOneParams = {
  data: Record
}

function transformCreateOneParamsToVariables(params: CreateOneParams) {
  console.log('createOne params', params)
  const { data: input } = params

  const inputWithFakeId = cleanFields({ ...input, id: '000000000000000' })

  return {
    input: inputWithFakeId,
  }
}

const createOneQuery = function createOneQuery(
  queryName: string,
  resource: Resource
) {
  // TODO: move to postgraphile file
  const InputName = `${resource.type.name}Input`
  const lowerName = lowerFirst(resource.type.name)

  const {
    fragment: AllTypesFragment,
    fragmentName: AllTypesFragmentName,
  } = getFragmentForType(resource.type)
  const query = gql
  return query`
          ${AllTypesFragment}
          mutation ${queryName}($input: ${InputName}!) {
              data: ${queryName}(input: { ${lowerName}: $input }) {
                clientMutationId
                ${lowerName} {
                  ...${AllTypesFragmentName}
                }
              }
          }
      `
}

export function createOneQueryName(resource: IntrospectionType) {
  return `create${resource.name}`
}

export function graphQlQueryDescriptor(
  queryName: string,
  resource: Resource,
  params: CreateOneParams
) {
  const variables = transformCreateOneParamsToVariables(params)
  if (isEmpty(variables.input)) {
    throw new Error(
      `Nothing to create! No value was specified for this object: ${JSON.stringify(
        params
      )}`
    )
  }
  return {
    query: createOneQuery(queryName, resource),
    variables: variables,
    parseResponse: (response: Object) => {
      // TODO: move to postgraphile file
      const lowerName = lowerFirst(resource.type.name)
      // @ts-ignore
      if (!response || !response.data || !response.data.data) {
        console.error('graphql could not find any data!', response)
        throw new Error(
          `Could not create ${resource.type.name} with input ${JSON.stringify(
            params
          )}`
        )
      }

      const {
        // @ts-ignore
        data: {
          data: { [lowerName]: newData },
        },
      } = response
      return {
        // data: params.id,
        data: newData,
      }
    },
  }
}
