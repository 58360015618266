import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { MutableRefObject, useEffect, useRef, useState } from 'react'
import { NumberInput, maxValue, minValue } from 'react-admin'
import { useField, useForm } from 'react-final-form'
import { GraphQLFilter } from '../../dataProvider/buildFilter'
import { getSchemaTraverser } from '../../dataProvider/buildQuery'
import {
  FieldFilterInfo,
  FieldTraverser,
  ResourceTraverser,
} from '../../dataProvider/introspections/SchemaTraverser'
import { functionGetListParamsToVariables } from '../../dataProvider/introspections/getList'
import { CustomFilter } from '../CustomFilter'
import { Filter } from '../CustomFilterForm'
import { GroupOrder, SMART_CATEGORY } from '../settings/TypeDisplaySetting'
import { CustomInputFormProps } from './MovieInputForm'

const SMART_CATEGORY_FILTER_DESCRIPTION_GROUP = SMART_CATEGORY + '_DESCRIPTION'
const SMART_CATEGORY_FLAG = 'automation:smartCategory'

const linkToScheduledTasks = window.location.origin + '/ScheduledTask'

export type SupportedCategoryAutomationCmsResources = 'CmsMovie' | 'CmsSery'
type SupportedCategoryAutomationSortOptions =
  | 'random'
  | 'wishlist'
  | 'watchlist'
  | 'licenseStart'
export type CustomDataSource =
  | undefined
  | null
  | {
      filter?: GraphQLFilter | undefined
      CmsMovie?: {
        normalizedGraphQLFilter: GraphQLFilter
      }
      CmsSery?: {
        normalizedGraphQLFilter: GraphQLFilter
      }
      contentLimit: number
      sortOption: SupportedCategoryAutomationSortOptions
    }

const useStyles = makeStyles(() => ({
  fullWidth: {
    width: '100%',
  },
  card: { marginBottom: 10 },
  cardContent: { padding: 16 },
}))

export const CUSTOMDATASOURCE_FIELDNAME = 'customDataSource'
export const FLAGS_FIELDNAME = 'flags'

export const CategoryInputForm = (props: CustomInputFormProps) => {
  const categoryInputFormClasses = useStyles()
  const {
    inputGroup,
    record,
    classes,
    setDisableSaveButtonFlag,
    getGroupsExpansion,
    saveCurrentGroupExpansion,
    isSmartCategoryEnabled,
    setSmartCategoryEnabled,
    isInitialCustomFormRenderRef,
  } = props

  /**
   * Initialized with the value from the db and subsequently utilized as local state-object when editing/changing filter rules
   * via the respective filter input components ({@link CategoryAutomationFilterComponent})
   */
  const customDataSourceRef: MutableRefObject<CustomDataSource> = useRef(null)

  // limit the content which will be added to this category via filter rules (default to 30)
  const queryLimitInitialValueRef = useRef<number>(30)
  // configure the option which will be used to sort the generated content
  const sortOptionInitialValueRef = useRef<SupportedCategoryAutomationSortOptions>(
    'random'
  )

  /**
   *
   * - render "Smart Category"-UI if category automation flag exists
   * - remove category automation flag when disabling "Smart Category" switch
   *
   * */
  const FakeFieldHandlingInitialSmartCategoryUILoadingState = () => {
    const { input: flagsField } = useField(FLAGS_FIELDNAME)
    const { change } = useForm()
    useEffect(() => {
      if (
        isInitialCustomFormRenderRef.current &&
        flagsField &&
        flagsField.value &&
        flagsField.value.some((flag: string) =>
          flag.startsWith(SMART_CATEGORY_FLAG)
        )
      ) {
        setSmartCategoryEnabled(true)
        isInitialCustomFormRenderRef.current = false
      } else {
        setCategoryAutomationFlag(
          flagsField.value,
          'clearFilter',
          change,
          setSmartCategoryEnabled
        )
        isInitialCustomFormRenderRef.current = false
      }
    }, [isInitialCustomFormRenderRef.current])
    return <></>
  }
  /** */

  const FilterBasedContentGenerationField = () => {
    const { input: customDataSourceField } = useField(
      CUSTOMDATASOURCE_FIELDNAME
    )
    const { input: flagsField } = useField(FLAGS_FIELDNAME)
    const { change } = useForm()

    // load customDataSource once if exist (in case of category-"EDIT") and update input form filter for respective resource
    if (!customDataSourceRef.current) {
      const initialCustomDataSourceFieldValue = customDataSourceField.value
      const initialCustomDataSourceFieldValueParsed: CustomDataSource | any =
        (initialCustomDataSourceFieldValue &&
          JSON.parse(initialCustomDataSourceFieldValue)) ||
        null
      customDataSourceRef.current = initialCustomDataSourceFieldValueParsed
      sortOptionInitialValueRef.current =
        customDataSourceRef?.current?.sortOption ?? 'random'
    }
    setCategoryAutomationFlag(
      flagsField.value,
      sortOptionInitialValueRef.current,
      change,
      isSmartCategoryEnabled
    )

    return (
      <>
        <Card className={categoryInputFormClasses.card}>
          <CardContent style={{ marginBottom: '2em' }}>
            <div
              style={{
                display: 'inline-flex',
                marginBottom: '1em',
              }}
            >
              <Typography
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                color="textSecondary"
              >
                {'Set Content Limit'}
              </Typography>
            </div>
            <NumberInput
              className={categoryInputFormClasses.fullWidth}
              label=""
              hiddenLabel
              helperText={
                'Limits the number of content which will be added to this category. Minimum 1 and Maximum 100.'
              }
              validate={[
                minValue(1, 'Only numbers in range of 1 to 100 allowed'),
                maxValue(100, 'Only numbers in range of 1 to 100 allowed'),
              ]}
              parse={(v: any) => {
                if (isNumberBetween1And100(v)) {
                  return JSON.stringify({
                    ...customDataSourceRef.current,
                    contentLimit: parseInt(v),
                  })
                } else {
                  return JSON.stringify(customDataSourceRef.current)
                }
              }}
              format={(v: any) => {
                if (v) {
                  const parsedV = JSON.parse(v)
                  queryLimitInitialValueRef.current = parseInt(
                    parsedV.contentLimit
                  )
                  customDataSourceRef.current = parsedV
                  return parseInt(parsedV.contentLimit)
                } else {
                  return null
                }
              }}
              // TODO: Accessing json-property via "."-notation does not work (we only want to manipulate "contentLimit" field with this NumberInput-Component)...
              // We work around this via custom "format"/"parse"-functions (see above).
              // Maybe a newer version of React ADMIN (currently 3.18.3) can properly parse and edit specific JSON fields via source...
              source={CUSTOMDATASOURCE_FIELDNAME}
              required={false}
              disabled={
                customDataSourceRef.current &&
                (customDataSourceRef.current.CmsMovie ||
                  customDataSourceRef.current.CmsSery)
                  ? false
                  : true
              }
            />
          </CardContent>
        </Card>
        <Card className={categoryInputFormClasses.card}>
          <CardContent style={{ marginBottom: '2em' }}>
            <div
              style={{
                display: 'inline-flex',
                marginBottom: '1em',
              }}
            >
              <Typography
                style={{
                  justifyContent: 'center',
                  alignItems: 'center',
                  display: 'flex',
                }}
                color="textSecondary"
              >
                {'Select A Sorting Preference'}
              </Typography>
            </div>
            <RadioGroup
              aria-labelledby="radio-buttons-group-sort-options"
              defaultValue={sortOptionInitialValueRef.current}
              name="radio-buttons-group"
              row
              onChange={(value) => {
                sortOptionInitialValueRef.current = value.target
                  .value as SupportedCategoryAutomationSortOptions
                customDataSourceRef.current = {
                  ...customDataSourceRef.current,
                  sortOption: sortOptionInitialValueRef.current,
                  contentLimit: queryLimitInitialValueRef.current,
                }
                setCategoryAutomationFlag(
                  flagsField.value,
                  sortOptionInitialValueRef.current,
                  change,
                  isSmartCategoryEnabled
                )
                change(
                  CUSTOMDATASOURCE_FIELDNAME,
                  JSON.stringify(customDataSourceRef.current)
                )
              }}
            >
              <FormControlLabel
                value="random"
                control={<Radio />}
                label="Random"
              />
              <FormControlLabel
                value="watchlist"
                control={<Radio />}
                label="Most Watchlist"
              />
              <FormControlLabel
                value="wishlist"
                control={<Radio />}
                label="Most Wishlist"
              />
              <FormControlLabel
                value="licenseStart"
                control={<Radio />}
                label="License Start"
              />
            </RadioGroup>
          </CardContent>
        </Card>
        {/* Set up filter for automated category content creation based on resource (data source) */}
        <CategoryAutomationFilterComponent
          filterValues={customDataSourceRef.current?.filter}
        ></CategoryAutomationFilterComponent>
      </>
    )
  }

  const CategoryAutomationFilterComponent = (props: {
    filterValues: GraphQLFilter | undefined
  }) => {
    const { filterValues } = props

    const traverser = getSchemaTraverser()
    if (!traverser) {
      throw new Error('No SchemaTraverser')
    }

    const resourceTraverserCmsMovie = traverser.getResourceTraverserByName(
      'CmsMovie'
    )
    const resourceTraverserCmsSery = traverser.getResourceTraverserByName(
      'CmsSery'
    )

    if (!resourceTraverserCmsMovie || !resourceTraverserCmsSery) {
      throw new Error('Ressource not found')
    }

    const filterableCmsMovieFields = resourceTraverserCmsMovie.filterableFields
    const filterableCmsSeryFields = resourceTraverserCmsSery.filterableFields
    const filterableRelatedCmsMovieFields =
      resourceTraverserCmsMovie.filterableManyToOneOrManyFieldDescriptors
    const filterableRelatedCmsSeryFields =
      resourceTraverserCmsSery.filterableManyToOneOrManyFieldDescriptors
    const filterableFields = [
      // CmsMovie fields
      ...filterableCmsMovieFields,
      // only add new fields from CmsSery
      ...filterableCmsSeryFields.filter(
        (seryField) =>
          !filterableCmsMovieFields.some(
            (movieField) => movieField.name === seryField.name
          )
      ),
    ]
    const relatedFilterableFields = [
      // CmsMovie related fields
      ...filterableRelatedCmsMovieFields,
      // only add new related fields from CmsSery
      ...filterableRelatedCmsSeryFields.filter(
        (relatedSeryField) =>
          !filterableRelatedCmsMovieFields.some(
            (relatedMovieField) =>
              relatedMovieField.alias === relatedSeryField.alias
          )
      ),
    ]

    const { change } = useForm()
    const [showErrorIcon, setShowErrorIcon] = useState(false)

    return (
      <Card className={categoryInputFormClasses.card}>
        <CardContent>
          <div
            style={{
              display: 'inline-flex',
              marginBottom: '1em',
            }}
          >
            <Typography
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
              color="textSecondary"
            >
              {'Configure Filter Rules For Content Generation'}
            </Typography>
            {/* {showErrorIcon ? (
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                enterDelay={100}
                title={
                  'Field name, Filter Operation and Filter Text Input fields must be set.'
                }
                placement="top"
              >
                <IconButton aria-label="Field name, Filter Operation and Filter Text Input fields must be set.">
                  <ErrorOutlineIcon color="error" />
                </IconButton>
              </Tooltip>
            ) : null} */}
          </div>
          <div style={{ width: '100%' }}>
            {
              // @ts-ignore
              <CustomFilter
                setFilters={(
                  data: GraphQLFilter | undefined,
                  filterStates: Partial<Filter>[]
                ) => {
                  // no filters found for current resource
                  if (!data?.and || data?.and?.length === 0) {
                    // check if any filters are set in the field 'customDataSource' and remove them
                    if (customDataSourceRef.current) {
                      // if only a single key exists it must be 'limitContent', therefore we void the field 'customDataSource' since there are no filters set for any resource ('CmsMovie'/'CmsSery')
                      customDataSourceRef.current = undefined
                      change(CUSTOMDATASOURCE_FIELDNAME, undefined)
                    }
                  }
                  // filters found for current resource
                  else {
                    // fetch all filter from the currently active filters (in the UI) which are supported for the respective resource (currently only CmsMovie and CmsSery)
                    // and get the normalized filter (meaning we resolve nested fields like "category.slug", which are only internally handled but not valid graphql filter)
                    const normalizedCmsMovieFilter = selectOnlySupportedFiltersForResource(
                      'CmsMovie',
                      data,
                      filterableCmsMovieFields,
                      filterableRelatedCmsMovieFields,
                      resourceTraverserCmsMovie
                    )
                    const normalizedCmsSeryFilter = selectOnlySupportedFiltersForResource(
                      'CmsSery',
                      data,
                      filterableCmsSeryFields,
                      filterableRelatedCmsSeryFields,
                      resourceTraverserCmsSery
                    )

                    // if there a no valid filters, set custom data source to undefined
                    if (!normalizedCmsMovieFilter && !normalizedCmsSeryFilter) {
                      customDataSourceRef.current = undefined
                    } else {
                      customDataSourceRef.current = {
                        filter: data,
                        contentLimit: queryLimitInitialValueRef.current,
                        sortOption: sortOptionInitialValueRef.current,
                      }
                      if (normalizedCmsMovieFilter) {
                        customDataSourceRef.current['CmsMovie'] = {
                          normalizedGraphQLFilter: normalizedCmsMovieFilter,
                        }
                      }
                      if (normalizedCmsSeryFilter) {
                        customDataSourceRef.current['CmsSery'] = {
                          normalizedGraphQLFilter: normalizedCmsSeryFilter,
                        }
                      }
                    }
                    change(
                      CUSTOMDATASOURCE_FIELDNAME,
                      JSON.stringify(customDataSourceRef.current)
                    )
                  }
                }}
                setDisableSaveButton={(flag: boolean) => {
                  setDisableSaveButtonFlag(flag)
                }}
                filterableFields={filterableFields}
                relatedFilterableFields={relatedFilterableFields}
                resource={'CmsMovie'} // FIXME: does this matter? since we already handle the provision of the available fields (CmsMovie and CmsSery)
                context="form"
                forcedFilters={{}}
                filterValues={filterValues}
                activeFilterPresets={[]}
                possibleFilterPresets={[]}
                selectFilterPreset={undefined}
                disableSearchField={true}
              ></CustomFilter>
            }
          </div>
        </CardContent>
      </Card>
    )
  }

  return (
    <div>
      {/* Load regular category input fields (Primary Group) */}
      {GroupOrder.primary.map((group) => {
        if (inputGroup[group]) {
          const groupsExpansion = getGroupsExpansion()
          if (
            groupsExpansion === undefined ||
            groupsExpansion[group] === undefined
          ) {
            saveCurrentGroupExpansion(group, true)
          }

          return (
            <>
              {group === SMART_CATEGORY ? (
                <>
                  {isSmartCategoryEnabled ? (
                    <Accordion
                      key={group}
                      defaultExpanded={
                        groupsExpansion === undefined ||
                        groupsExpansion[group] === undefined
                          ? false // default false if first time opening this resource or a new group
                          : groupsExpansion[group]
                      }
                      onChange={(e, expanded) => {
                        saveCurrentGroupExpansion(group, expanded)
                      }}
                    >
                      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Typography variant="h6">{SMART_CATEGORY}</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={categoryInputFormClasses.fullWidth}>
                          <Accordion
                            key={SMART_CATEGORY_FILTER_DESCRIPTION_GROUP}
                            defaultExpanded={
                              groupsExpansion === undefined ||
                              groupsExpansion[
                                SMART_CATEGORY_FILTER_DESCRIPTION_GROUP
                              ] === undefined
                                ? true // default false if first time opening this resource or a new group
                                : groupsExpansion[
                                    SMART_CATEGORY_FILTER_DESCRIPTION_GROUP
                                  ]
                            }
                            onChange={(e, expanded) => {
                              saveCurrentGroupExpansion(
                                SMART_CATEGORY_FILTER_DESCRIPTION_GROUP,
                                expanded
                              )
                            }}
                          >
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                              <div
                                style={{
                                  display: 'inline-flex',
                                }}
                              >
                                <Typography
                                  style={{
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  color="textSecondary"
                                >
                                  {'Description'}
                                </Typography>
                              </div>
                            </AccordionSummary>
                            <div
                              style={{
                                backgroundColor: '#f4f4f4',
                                borderLeft: '4px solid #ddd',
                                margin: '10px 0',
                                padding: '15px',
                              }}
                            >
                              <p>
                                Using our filtering rules, you can specify which
                                content should be automatically linked to this
                                category, and limit the amount of content via
                                the "Content limit" field.
                              </p>
                              <p>
                                The system will automatically scan all available
                                content and populate the category with matching
                                items, according to the intervals configured for
                                this automation task (see&nbsp;
                                <a
                                  href={linkToScheduledTasks}
                                  rel="noreferrer"
                                  target="_blank"
                                >
                                  scheduled tasks
                                </a>
                                ).
                              </p>
                            </div>
                          </Accordion>
                          <div style={{ marginBottom: '2em' }} />
                          <FilterBasedContentGenerationField />
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <FakeFieldHandlingInitialSmartCategoryUILoadingState />
                  )}
                </>
              ) : (
                <>
                  <div className={classes.invisibleGroup}>
                    {inputGroup[group]}
                  </div>
                  <Divider light={true} className={classes.divider} />
                </>
              )}
            </>
          )
        }
      })}
    </div>
  )
}

const isNumberBetween1And100 = (numberAsString: string) => {
  const parsedNumber = parseInt(numberAsString)
  if (isNaN(parsedNumber) || parsedNumber < 1 || parsedNumber > 100) {
    return false
  } else {
    return true
  }
}

export const resourceAsHumanReadableString = (
  resource: SupportedCategoryAutomationCmsResources
) => {
  return resource === 'CmsMovie' ? 'Movies' : 'Series'
}

const selectOnlySupportedFiltersForResource = (
  resource: SupportedCategoryAutomationCmsResources,
  selectedFilters: GraphQLFilter,
  filterableFields: FieldFilterInfo[],
  filterableRelatedFields: {
    alias: string
    field: FieldTraverser
    relationshipType: {
      referenceType: ResourceTraverser
      relatedType: string
      relatedField: string
    }
    filterableSubfields: FieldFilterInfo[]
    template: string | null
  }[],
  resourceTraverser: ResourceTraverser
) => {
  const selectedFiltersCopy = { ...selectedFilters }
  const supportedFieldNames = [
    ...filterableFields.map((field) => field.name),
    ...filterableRelatedFields.map((field) => field.alias),
  ]

  selectedFiltersCopy.and = selectedFiltersCopy.and?.filter((filter) => {
    // we split the current filters field name because related field filters start with the name of the related table followed by a dot,
    // subsequently followed by a fields name (e.g. "season.slug" as a related field for CmsSery)
    const currentFilterFieldNames = Object.keys(filter)[0].split('.')
    if (
      currentFilterFieldNames &&
      currentFilterFieldNames.length > 0 &&
      supportedFieldNames.includes(currentFilterFieldNames[0])
    ) {
      return true
    } else {
      return false
    }
  })

  const normalizedFilter =
    selectedFiltersCopy?.and && selectedFiltersCopy.and.length > 0
      ? functionGetListParamsToVariables(
          { sort: null, pagination: null, filter: selectedFiltersCopy },
          [],
          resourceTraverser.resource
        ).filter
      : undefined

  return normalizedFilter
}

// TODO: refactor
const setCategoryAutomationFlag = (
  flags: string[],
  sortOption: SupportedCategoryAutomationSortOptions | 'clearFilter',
  change: <F extends string>(name: F, value?: any) => void,
  setSmartCategoryEnabled: any
) => {
  // clear automation flag if "SMART CATEGORY" is disabled
  if (sortOption === 'clearFilter') {
    // remove existing category automation flags
    flags = (flags || []).filter(
      (flag) => !flag.startsWith(SMART_CATEGORY_FLAG)
    )
    change(FLAGS_FIELDNAME, flags)
    setSmartCategoryEnabled(false)

    return
  }

  // replace existing automation flag if changed
  if (!flags?.includes(SMART_CATEGORY_FLAG)) {
    // remove existing category automation flags
    flags = (flags || []).filter(
      (flag) => !flag.startsWith(SMART_CATEGORY_FLAG)
    )
    flags.push(SMART_CATEGORY_FLAG)
    change(FLAGS_FIELDNAME, flags)
  }
}
