import { Chip, IconButton, useMediaQuery } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Typography from '@material-ui/core/Typography'
import { Theme, makeStyles } from '@material-ui/core/styles'
import DateRangeIcon from '@material-ui/icons/DateRange'
import GetAppIcon from '@material-ui/icons/GetApp'
import { subDays } from 'date-fns'
import { motion } from 'framer-motion'
import React, { useEffect, useState } from 'react'
import { Loading, useGetIdentity, useNotify, usePermissions } from 'react-admin'
import { DateRangePicker } from 'react-date-range'
import 'react-date-range/dist/styles.css' // required for react-date-range - main css file
import 'react-date-range/dist/theme/default.css' // required for react-date-range - theme css file
import { RouteComponentProps } from 'react-router'
import { GetIdentity, Permissions } from '../../authProvider'
import { authedFetch } from '../../dataProvider/authedFetch'
import { NodeActionProps } from '../../genericData/node-actions/NodeActionType'
import useInterval from '../../hooks/useInterval'
import {
  CUSTOMER,
  EXTERNAL_LICENSE_REPORTING_ENDPOINT,
  REPORTING_TYPE_ALLOWED_ROLES,
  SMPL_CUSTOMER,
  SUPPORTED_CUSTOMERS,
} from '../../lib/config'
import { filterNotEmpty } from '../../lib/filterNonEmpty'

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    padding: '16px',
    overflow: 'inherit',
    minHeight: '52px',
    marginBottom: '32px',
  },
  cardTitleDescr: {
    margin: 0,
    marginTop: '12px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  util: {
    height: 50,
    marginBottom: 10,
    background: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'space-between',
  },
  selectCard: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      marginLeft: 5,
      marginRight: 5,
    },
  },
  select: {
    minWidth: 100,
  },
  selectButton: {},
  main: {
    height: '70vh',
  },
  list: {
    height: 'inherit',
    overflow: 'auto',
  },
  listButton: {
    marginRight: 10,
  },
  altText: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: 5,
    },
  },
}))

type ReportType =
  | 'license'
  | 'user'
  | 'content'
  | 'subscription'
  | 'dailySubscription'
  | 'marketing'
  | 'watchlistAnomaly'
  | 'partnerSource'

type FileType =
  // report type: license
  | 'individual'
  | 'cumulative'
  | 'total'
  | 'gema'
  | 'user'
  | 'license'
  | 'content_report' // NK and Kixi
  // report type: user
  | 'user_activity'
  | 'inactive_user'
  // report type: content
  | 'movie_content'
  | 'episode_content'
  // report type: subscription
  | 'subscription'
  // report type: marketing
  | 'marketing'
  | 'marketing_report' // NK and Kixi
  // report type: 'dailySubscription'
  | 'dailySubscription'
  // report type: 'watchlistAnomaly'
  | 'watchlistAnomaly'
  // report type: 'partnerSource'
  | 'partnerSource'

const ReportTypeMapping: { [key in ReportType]: FileType[] } = {
  license: [
    'individual',
    'cumulative',
    'total',
    'gema',
    'user',
    'content_report',
    'license',
  ],
  user: ['user_activity', 'inactive_user'],
  content: ['movie_content', 'episode_content'],
  subscription: ['subscription'],
  dailySubscription: ['dailySubscription'],
  marketing: ['marketing', 'marketing_report'],
  watchlistAnomaly: ['watchlistAnomaly'],
  partnerSource: ['partnerSource'],
}

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]

type Props = RouteComponentProps & NodeActionProps

type Cache = {
  fileFormat: string
  fileType: FileType
  fileName: string
  reportMonth: number
  reportYear: number
  createdDateTime?: string
  status: 'inProgress' | 'finished'
}

export const LicenseReport = (props: Props) => {
  const classes = useStyles()
  const notify = useNotify()

  const reportAndRoles: {
    reportType: ReportType
    allowedRoles: string[]
    accessibleForCustomers: SUPPORTED_CUSTOMERS[]
  }[] = JSON.parse(REPORTING_TYPE_ALLOWED_ROLES)

  const [reportTypes, setReportTypes] = useState<
    | {
        reportType: ReportType
        accessibleForCustomers: SUPPORTED_CUSTOMERS[]
      }[]
    | undefined
  >(undefined)

  const [allowedFileTypes, setAllowedFileTypes] = useState<FileType[]>([])

  const {
    permissions,
    loaded: permissionLoaded,
  } = usePermissions<Permissions>()

  const { loaded: identityLoaded, identity } = useGetIdentity() as GetIdentity

  useEffect(() => {
    if (permissions && identityLoaded) {
      const accessibleReportTypes = reportAndRoles
        .filter((rar) => rar.allowedRoles.includes(identity.role))
        .map((rar) => {
          return {
            reportType: rar.reportType,
            accessibleForCustomers: rar.accessibleForCustomers,
          }
        })

      setReportTypes(accessibleReportTypes)
      setAllowedFileTypes(
        accessibleReportTypes
          .map((r) => r.reportType)
          .map((rt) => ReportTypeMapping[rt])
          .reduce((acc, val) => acc.concat(val), [])
      )
    }
  }, [permissionLoaded, identityLoaded])

  const [existingCache, setExistingCache] = useState<Cache[]>()

  const YEARS: number[] = []

  const day = new Date()
  const thisYear = day.getFullYear()
  day.setMonth(day.getMonth() - 1)
  const lastMonth = day.getMonth()

  for (let year = 2019; year <= thisYear; year++) {
    YEARS.push(year)
  }

  const [selectedReportType, setSelectedReportType] = useState<ReportType>(
    'license'
  )
  const handleChangeReportType = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedReportType(event.target.value as ReportType)

    switch (event.target.value as ReportType) {
      case 'dailySubscription':
        setShowDateRangePicker(true) // open the date-range picker if it was not open
        break
      case 'watchlistAnomaly':
        setShowDateRangePicker(true) // open the date-range picker if it was not open
        break
      default:
        setShowDateRangePicker(false) // close the date-range picker if it was open
        break
    }
  }

  const [selectedMonth, setSelectedMonth] = useState(lastMonth)
  const handleChangeMonth = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedMonth(Number(event.target.value))
  }
  const [selectedYear, setSelectedYear] = useState(thisYear)
  const handleChangeYear = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedYear(Number(event.target.value))
  }

  const [showDateRangePicker, setShowDateRangePicker] = useState(false)
  const [dateRangeValue, setDateRangeValue] = useState({
    startDate: subDays(new Date(), 6),
    endDate: new Date(),
    key: 'selection',
  })

  const toogleDateRangePicker = () => {
    setShowDateRangePicker(!showDateRangePicker)
  }

  const [serverError, setServerError] = useState<boolean>(false)
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))
  const isMedium = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  const [refetch, setRefetch] = useState<boolean>(true)
  const [onCreate, setOnCreate] = useState<boolean>(false)

  // while this is true we will do polling refresh every 10 sec
  const [createInProgress, setCreateInProgress] = useState(false)

  const licenseReportingPath = EXTERNAL_LICENSE_REPORTING_ENDPOINT.replace(
    /\/$/,
    ''
  ) // remove trailing slash

  useEffect(() => {
    if (refetch) {
      const cacheQuery = async () => {
        try {
          // the cache should have been sorted be year/month by server
          // we don't use licenseReportServiceClient here because it throw error on 400, we want to displays the error message coming from server
          const res = await authedFetch(
            licenseReportingPath + '/getCachedOverview',
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                customer: SMPL_CUSTOMER,
              }),
            }
          )
          if (res.ok && res.status !== 204) {
            const existingLicenseReporting: {
              cache: Cache[]
            } = await res.json()
            if (existingLicenseReporting && existingLicenseReporting.cache) {
              const cache = existingLicenseReporting.cache
              let createInProgress = false
              for (let i = 0; i < cache.length; i++) {
                if (cache[i].status === 'inProgress') {
                  createInProgress = true
                  break
                }
              }
              setCreateInProgress(createInProgress)
              setExistingCache(cache)
              setRefetch(false)
              return
            }
          } else {
            const pRes = await res.json()
            setExistingCache(undefined)
            notify(pRes.message, 'error')
            setRefetch(false)
          }
        } catch (error) {
          console.error(error)
          setServerError(true)
          notify(
            'Cannot connect to the server. If this problem persists, please contact the support.',
            'error'
          )
          setRefetch(false)
        }
      }
      cacheQuery()
    }
  }, [refetch])

  useInterval(
    () => {
      setRefetch(true)
    },
    createInProgress ? 10000 : null
  )

  const createEndpointMappingByReportType = (reportType: ReportType) => {
    let endpoint = ''
    switch (reportType) {
      case 'license':
        endpoint = '/createReport'
        break
      case 'user':
        endpoint = '/createUserReport'
        break
      case 'content':
        endpoint = '/createContentReport'
        break
      case 'subscription':
        endpoint = '/createSubscriptionReport'
        break
      case 'dailySubscription':
        endpoint = '/createDailySubscriptionReport'
        break
      case 'marketing':
        endpoint = '/createMarketingReport'
        break
      case 'watchlistAnomaly':
        endpoint = '/createWatchlistAnomalyReport'
        break
      case 'partnerSource':
        endpoint = '/createPartnerSourceReport'
        break
      default:
        console.error(
          'selectedReportType',
          selectedReportType,
          'is not supported yet'
        )
    }
    return endpoint
  }

  const getEndpointMappingByFileType = (fileType: FileType) => {
    let endpoint = '/getReport'
    switch (fileType) {
      case 'user_activity':
      case 'inactive_user':
        endpoint = '/getUserReport'
        break
      case 'movie_content':
      case 'episode_content':
        endpoint = '/getContentReport'
        break
      case 'subscription':
        endpoint = '/getSubscriptionReport'
        break
      case 'marketing':
        endpoint = '/getMarketingReport'
        break
      case 'dailySubscription':
        endpoint = '/getDailySubscriptionReport'
        break
      case 'watchlistAnomaly':
        endpoint = '/getWatchlistAnomalyReport'
        break
      case 'partnerSource':
        endpoint = '/getPartnerSourceReport'
        break
    }
    return endpoint
  }

  const createNewReport = async () => {
    try {
      let endpoint = createEndpointMappingByReportType(selectedReportType)
      const res = await authedFetch(`${licenseReportingPath}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          customer: SMPL_CUSTOMER,
          specifiedMonth: selectedMonth,
          specifiedYear: selectedYear,
          // INFO for daily report, time is not important
          startDate: dateRangeValue.startDate.toDateString(),
          endDate: dateRangeValue.endDate.toDateString(),
        }),
      })
      const pRes = await res.json()
      if (res.ok && res.status !== 204) {
        notify(pRes.message, 'info')
        setRefetch(true)
      } else {
        notify(pRes.message, 'error')
      }
    } catch (error) {
      console.error(error)
      notify(
        'Cannot connect to the server. If this problem persists, please contact the support.',
        'error'
      )
    }
  }

  const downloadFile = async (item: {
    fileFormat: string
    fileType: FileType
    fileName: string
    reportMonth: number
    reportYear: number
  }) => {
    const { reportMonth, reportYear, fileType, fileFormat, fileName } = item

    let endpoint = getEndpointMappingByFileType(fileType)
    await authedFetch(`${licenseReportingPath}${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        customer: SMPL_CUSTOMER,
        specifiedMonth: reportMonth,
        specifiedYear: reportYear,
        fileType,
        fileFormat,
        fileName,
      }),
    })
      .then((response) => response.blob())
      .then((blob) => {
        var url = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = url
        a.download = fileName
        document.body.appendChild(a) // append the element to the dom -> otherwise it will not work in firefox
        a.click()
        a.remove() // remove the element again
      })
  }

  if (refetch && !createInProgress && !reportTypes) return <Loading />

  return (
    <div>
      <Card className={classes.cardTitle}>
        <Typography variant="h5">Reporting</Typography>
        <p className={classes.cardTitleDescr}>
          Download existing report or create new one for previous months. The
          reports are only saved temporarily.
        </p>
        <p className={classes.cardTitleDescr}>
          The payment numbers (in €) shown in the reports are rough estimates
          based on the number of subscription terms existing in the selected
          month (without taking into account any VAT reduction, refund, etc.)
          and <b>should not be used for accounting purposes</b>. For accounting
          purposes, please use the numbers of the respective payment providers.
        </p>
      </Card>
      <Card className={classes.util}>
        <Card className={classes.selectCard}>
          <Typography variant="body1">
            {isXSmall ? 'Create:' : 'Create New Report:'}
          </Typography>

          <Select
            className={classes.select}
            value={selectedReportType}
            onChange={handleChangeReportType}
          >
            {reportTypes.map((type, index) => {
              const { reportType, accessibleForCustomers } = type
              const isAccessible = accessibleForCustomers.includes(CUSTOMER)
              return (
                <MenuItem
                  key={reportType}
                  value={reportType}
                  disabled={!isAccessible}
                >
                  {reportType}
                </MenuItem>
              )
            })}
          </Select>

          {/* Month and Year selector */}
          {['license', 'partnerSource'].includes(selectedReportType) ? (
            <>
              <Select
                className={classes.select}
                value={selectedMonth}
                onChange={handleChangeMonth}
              >
                {MONTHS.map((month, index) => {
                  return (
                    <MenuItem key={month} value={index}>
                      {month}
                    </MenuItem>
                  )
                })}
              </Select>
              <Select
                className={classes.select}
                value={selectedYear}
                onChange={handleChangeYear}
              >
                {YEARS.map((year) => {
                  return (
                    <MenuItem key={year} value={Number(year)}>
                      {year}
                    </MenuItem>
                  )
                })}
              </Select>
            </>
          ) : (
            <></>
          )}

          {/* Daterange picker */}
          {['dailySubscription', 'watchlistAnomaly'].includes(
            selectedReportType
          ) ? (
            <>
              <Typography component="span" variant="body2">
                FROM:{' '}
                <Chip
                  color="primary"
                  variant="outlined"
                  label={dateRangeValue.startDate.toDateString()}
                />
              </Typography>
              <Typography component="span" variant="body2">
                TO:{' '}
                <Chip
                  color="primary"
                  variant="outlined"
                  label={dateRangeValue.endDate.toDateString()}
                />
              </Typography>
              <IconButton
                aria-label="date-range"
                onClick={toogleDateRangePicker}
              >
                <DateRangeIcon />
              </IconButton>
            </>
          ) : (
            <></>
          )}

          <Button
            variant="contained"
            color="primary"
            size="medium"
            startIcon={
              onCreate === false ? null : (
                <CircularProgress size={14} color="inherit" />
              )
            }
            onClick={async () => {
              setOnCreate(true)
              await createNewReport()
              setOnCreate(false)
            }}
            className={classes.selectButton}
          >
            Create
          </Button>
        </Card>
        <div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setRefetch(true)}
          >
            Reload
          </Button>
        </div>
      </Card>
      {showDateRangePicker ? (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0 }}
          style={{ marginBottom: 10 }}
        >
          <DateRangePicker
            leditableDateInputs={true}
            onChange={(item) => setDateRangeValue(item.selection)}
            ranges={[dateRangeValue]}
          />
        </motion.div>
      ) : (
        <></>
      )}
      {existingCache ? (
        <Card className={classes.main}>
          <div className={classes.list}>
            <List>
              {existingCache
                .map((item) => {
                  const { fileType } = item
                  if (!allowedFileTypes.includes(fileType)) {
                    return null
                  }
                  return (
                    <span key={item.fileName}>
                      <ListItem>
                        <Button
                          variant="text"
                          color="primary"
                          size="medium"
                          onClick={async () => {
                            await downloadFile(item)
                          }}
                          className={classes.listButton}
                          disabled={item.status === 'inProgress'}
                        >
                          {item.status === 'inProgress' ? (
                            isXSmall ? (
                              <CircularProgress size={14} color="inherit" />
                            ) : (
                              <>
                                <CircularProgress
                                  size={14}
                                  style={{ marginRight: 5 }}
                                  color="inherit"
                                />{' '}
                                Creating
                              </>
                            )
                          ) : isXSmall ? (
                            <GetAppIcon />
                          ) : (
                            <>
                              <GetAppIcon /> Download
                            </>
                          )}
                        </Button>
                        <ListItemText
                          primary={truncateString(
                            item.fileName,
                            isXSmall ? 50 : isSmall ? 60 : isMedium ? 70 : 1000,
                            '...'
                          )}
                          secondary={`Month: ${
                            MONTHS[item.reportMonth]
                          } | Year: ${
                            item.reportYear
                          } | Type: ${item.fileType.toUpperCase()} | Format: ${item.fileFormat.toUpperCase()} ${
                            item.createdDateTime
                              ? `| Created On: ${item.createdDateTime}`
                              : ''
                          } `}
                        />
                      </ListItem>
                      <Divider />
                    </span>
                  )
                })
                .filter(filterNotEmpty)}
            </List>
          </div>
        </Card>
      ) : (
        <Card>
          <div className={classes.altText}>
            {serverError ? (
              <span>
                Cannot connect to the server. If this problem persists, please
                contact the support.
              </span>
            ) : (
              <span>There are currently no reports.</span>
            )}
          </div>
        </Card>
      )}
    </div>
  )
}

const truncateString = function (
  fullStr: string,
  strLen: number,
  separator: string
) {
  if (fullStr.length <= strLen) return fullStr

  separator = separator || '...'

  var sepLen = separator.length,
    charsToShow = strLen - sepLen,
    frontChars = Math.ceil(charsToShow / 2),
    backChars = Math.floor(charsToShow / 2)

  return (
    fullStr.substr(0, frontChars) +
    separator +
    fullStr.substr(fullStr.length - backChars)
  )
}
