import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  heading: {
    fontSize: theme.typography.pxToRem(13),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(13),
    color: theme.palette.text.secondary,
  },
  textBox: {
    minWidth: '260px',
  },
}))

export const ListFields = (props: any) => {
  const { record } = props
  const listData: { [fieldName in string]: any }[] =
    record[props.field ? props.field : props.source]
  const classes = useStyles()

  return (
    <span className={classes.root}>
      {listData.filter(Boolean).map((data, index) => {
        delete data.__typename
        const label = 'sourceLabel' in data ? data.sourceLabel : null
        const language = 'language' in data ? data.language : null
        return (
          <Accordion key={JSON.stringify(data)}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography className={classes.heading}>
                {label || index}
              </Typography>
              <Typography className={classes.secondaryHeading}>
                {language}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                multiline
                defaultValue={JSON.stringify(data, null, 2)}
                InputProps={{
                  readOnly: true,
                  disableUnderline: true,
                }}
                className={classes.textBox}
              />
            </AccordionDetails>
          </Accordion>
        )
      })}
    </span>
  )
}
