import {
  Button,
  CardContent,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import LinearProgress from '@material-ui/core/LinearProgress'
import { formatDistanceToNow } from 'date-fns'
import debug from 'debug'
import React from 'react'
import { createSimpleAuthenticatedClient } from '../genericData/node-actions/user/lib/simpleClient'
import { SMPL_DOMAIN } from '../lib/config'

const log = debug('app:routes:apps:MovieCache')
type Props = {}
type Status = {
  done: boolean
  step: number
  progress: number
  total: number
  data: any
  startDate: null | string
}
type State = {
  loading: boolean
  status: Status | null
  lastSeedTime: Date | null
  menuAnchor: null | HTMLElement
}

export class MovieCache extends React.Component<Props, State> {
  makeRequest: ReturnType<typeof createSimpleAuthenticatedClient>
  progressInterval: ReturnType<typeof setInterval> | null = null

  constructor(props: Props) {
    super(props)
    // FIXME
    // line below throw:
    // Access to fetch at 'https://cachinator.universum.dev.smpl.services/apiseed/status' from origin 'http://localhost:7000' has been blocked by CORS policy: Response to preflight request doesn't pass access control check: No 'Access-Control-Allow-Origin' header is present on the requested resource. If an opaque response serves your needs, set the request's mode to 'no-cors' to fetch the resource with CORS disabled.
    this.makeRequest = createSimpleAuthenticatedClient(
      `https://cachinator.${SMPL_DOMAIN}/`
    )

    this.state = {
      loading: true,
      status: null,
      lastSeedTime: null,
      menuAnchor: null,
    }
  }

  requestStatus = async () => {
    log('requesting status')
    const status = await this.makeRequest('GET', 'apiseed/status')
    log('status', status)

    this.setState({
      status,
      loading: false,
    })
  }

  componentDidMount() {
    this.progressInterval = setInterval(this.requestStatus, 1000)
    this.requestStatus()
  }

  componentWillUnmount() {
    const { progressInterval } = this
    if (progressInterval) {
      clearInterval(progressInterval)
    }
  }

  canReseed() {
    const { loading, status } = this.state
    return (!loading && !status) || (status && status.done)
  }

  openMenu = (event: any) => {
    this.setState({ menuAnchor: event.currentTarget })
  }
  closeMenu = () => {
    this.setState({ menuAnchor: null })
  }

  triggerBoth = async () => {
    this.closeMenu()
    if (!this.canReseed()) {
      return window.alert('already running')
    }
    this.setState({
      loading: true,
    })

    const status = await this.makeRequest('POST', 'auto')
    window.alert(
      'API reseeding triggered. Cloudflare will be purged automatically.'
    )
    this.setState({
      loading: false,
      status,
    })
  }

  triggerCacheClear = async () => {
    this.closeMenu()
    if (this.state.loading) {
      return
    }
    this.setState({
      loading: true,
    })

    const data = await this.makeRequest('POST', 'cloudflare')

    if (data && data.success) {
      window.alert(
        'Cloudflare cache purged. This might take a while until it takes effect.'
      )
    } else {
      window.alert('Cloudflare cache purge failed.')
    }

    this.setState({
      loading: false,
    })
  }

  triggerReseed = async () => {
    this.closeMenu()
    if (!this.canReseed()) {
      return window.alert('already running!')
    }

    this.setState({
      loading: true,
    })

    const status = await this.makeRequest('POST', 'apiseed')
    window.alert('API reseeding started.')

    this.setState({
      loading: false,
      status,
    })
  }

  renderProgressBar(status: Status) {
    const { /*done,*/ step, progress, total, data } = status

    const hasProgress = progress >= 0 && total >= 0
    const percentage = hasProgress ? (progress / total) * 100 : 0
    log('rendering status', status, percentage)

    return (
      <>
        <Typography>
          {step +
            (data ? ' ' + data : '') +
            (hasProgress ? ` (${progress}/${total})` : '')}
        </Typography>
        <LinearProgress
          variant="determinate"
          // active={!done}
          value={Math.min(percentage, 100)}
        />
      </>
    )
  }

  render() {
    const {
      loading,
      status,
      lastSeedTime: lastSeedRaw,
      menuAnchor,
    } = this.state
    const lastSeedTime = lastSeedRaw
      ? lastSeedRaw
      : status && status.startDate
      ? `${formatDistanceToNow(new Date(status.startDate))} ago`
      : 'unknown'

    return (
      <div>
        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              Movie Cache Control
            </Typography>
            <Typography component="p">
              The Movie API contains a database of movies which are loaded from
              WordPress and a cache (Cloudflare) that sits in front of it. In
              order to show new movies or categories on the website, we have to
              retrieve the movies from WordPress and only after that is
              finished, the cache must be cleared.
            </Typography>
            <Typography component="p">
              Both steps will be triggered automatically when using the button.
            </Typography>
          </CardContent>
        </Card>
        <Card style={{ marginTop: 30 }}>
          <CardContent style={{ textAlign: 'center' }}>
            <div style={{ marginBottom: 20 }}>
              <Button
                disabled={loading}
                onClick={this.triggerBoth}
                color="primary"
                variant="contained"
              >
                Reload Movies & Clear Cache
              </Button>
              <Button
                disabled={loading}
                onClick={this.openMenu}
                color="primary"
                variant="outlined"
                style={{ marginLeft: 12 }}
                aria-owns={menuAnchor ? 'simple-menu' : undefined}
                aria-haspopup="true"
              >
                Advanced Features
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={menuAnchor}
                open={Boolean(menuAnchor)}
                onClose={this.closeMenu}
              >
                <MenuItem onClick={this.triggerReseed}>
                  Only Reload Movie Database
                </MenuItem>
                <MenuItem onClick={this.triggerCacheClear}>
                  Only Clear Cloudflare Cache
                </MenuItem>
              </Menu>
            </div>
            <Typography>
              Last load from WordPress{' '}
              {status && !status.done ? 'is still running since ' : null}
              {String(lastSeedTime)}.
            </Typography>

            {status ? this.renderProgressBar(status) : null}
          </CardContent>
        </Card>
      </div>
    )
  }
}
