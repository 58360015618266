// get the URL that was loaded during the initial page load
const initialURL = new URL(location.href)

export function getAfterLoginRedirect() {
  if (initialURL.pathname === '/login') {
    return initialURL.searchParams.get('redirect') || '/'
  } else {
    return initialURL.pathname + initialURL.search
  }
}

export function getLoginPathPageNavigation(redirect?: string) {
  const loginPath = '/login'
  if (redirect) {
    return `${loginPath}?redirect=${encodeURIComponent(
      location.pathname + location.search
    )}`
  } else {
    return loginPath
  }
}
