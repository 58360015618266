import { Button, ButtonGroup, CircularProgress } from '@material-ui/core'
import { Record, useRefresh } from 'ra-core'
import { useState } from 'react'
import { Identifier, useNotify } from 'react-admin'
import { MEDIA_CONVERT_CONTROLLER_API } from '../../../../lib/config'
import { NodeActionProps } from '../../NodeActionType'
import { createSimpleAuthenticatedClient } from '../../user/lib/simpleClient'

const CreateJobButton = (
  props: NodeActionProps & { label?: String } & {
    multipleIds?: Array<Identifier>
  }
) => {
  const notify = useNotify()
  const { record, label = 'Start Transcoding', multipleIds } = props
  const [isLoading, setLoading] = useState(false)
  const refresh = useRefresh()

  const endpoint: string = 'createJob'

  const mediaConvertClient = createSimpleAuthenticatedClient(
    MEDIA_CONVERT_CONTROLLER_API
  )

  const createJob = (record: Record) => {
    try {
      setLoading(true)
      // Call Post Request from backend with job id

      if (!record && multipleIds) {
        // recursively call createJob for each of the id
        multipleIds.forEach((id) => {
          createJob((record = { id }))
        })
        return
      }
      const { id } = record
      const jobId: Identifier = id
      const data: Object = {
        id: jobId,
      }

      console.log('Start Transcoding for Job with id/vendor_id', jobId)

      mediaConvertClient('POST', endpoint, data)
        .then((res) => {
          console.log(res)
          setLoading(false)
          notify('transcoding job successfully started', 'success')
          refresh()
        })
        .catch((error) => {
          console.error(error)
          notify('failed to start transcoding job', 'error')
          setLoading(false)
          refresh()
        })
    } catch (err) {
      console.error(err)
      notify('failed to start transcoding job', 'error')
      setLoading(false)
    }
  }

  return (
    <ButtonGroup
      color="primary"
      aria-label="text primary button group"
      size="small"
    >
      <Button
        size="medium"
        color="primary"
        onClick={() => {
          createJob(record)
        }}
      >
        {isLoading && (
          <CircularProgress
            color="primary"
            size={25}
            thickness={2}
            style={{ marginRight: '5px' }}
          />
        )}
        {label}
      </Button>
    </ButtonGroup>
  )
}

CreateJobButton.label = 'CreateJobButton'

export default CreateJobButton
