import produce from 'immer'
import trim from 'lodash/trim'
import { DataProviderProxy, Record } from 'ra-core'
import { authedFetch } from '../../dataProvider/authedFetch'
import { IMG_CLOUDFRONT_URL_PREFIX } from '../../lib/config'
import {
  CustomSaveProps,
  SMPL_TEMP_CATEGORIES,
  SMPL_TEMP_CATEGORIES_FOR_REMOVAL,
  SMPL_TEMP_IMG_FILES,
  SMPL_TEMP_LICENSE,
  SMPL_TEMP_PERSON,
  SMPL_TEMP_STRINGLIST_FOR_REMOVAL,
} from '../GenericEditPage'
import { TempImg } from '../customInputs/ImageInput'
import { CmsLicense } from '../customInputs/LicenseInput'
import { TempPerson } from '../customInputs/PersonInput'

export const cmsWithCustomMovieSave = [
  'CmsClip',
  'CmsMovie',
  'CmsFeedObject',
  'CmsSery',
  'CmsSeason',
  'CmsEpisode',
  'CmsTrailer',
]

/**
 * Call extractAndRemoveCustomFields() first then use this, otherwise immer may get into errors with the extras SMPL fields
 *
 * Remove __typename which prevent saving, they will be added automatically in some fields
 *
 * Replace "" with 'null' which will prevent saving
 *
 * @param values
 */
export function cleanFields(values: Record) {
  // make deep copy with immer.produce
  // https://immerjs.github.io/immer/#with-immer

  const newValues = produce(values, (draft) => {
    Object.keys(draft).forEach((key) => {
      if (Array.isArray(draft[key])) {
        let valueArray = cleanFields(draft[key])
        draft[key] = valueArray
      } else if (draft[key] && typeof draft[key] === 'object') {
        delete draft[key].__typename
      } else if (typeof draft[key] === 'string') {
        if (draft[key].length === 0) {
          // replace "" with null
          draft[key] = null
        } else {
          // call trim prevent space in front or at the end
          draft[key] = trim(draft[key])
        }
      }
    })
    if (draft.hasOwnProperty('__typename')) {
      delete draft.__typename
    }
    if (draft.hasOwnProperty('activeLicenseOnPlatform')) {
      delete draft.activeLicenseOnPlatform
    }
  })

  return newValues
}

export function extractAndRemoveCustomFields(values: Record) {
  let customValues: {
    [x: string]:
      | {
          [x: string]: string
        }[]
      | TempPerson[]
      | TempImg
  } = {}
  for (const [key, value] of Object.entries(values)) {
    if (
      key === SMPL_TEMP_CATEGORIES ||
      key === SMPL_TEMP_PERSON ||
      key === SMPL_TEMP_IMG_FILES ||
      key === SMPL_TEMP_LICENSE ||
      key === SMPL_TEMP_CATEGORIES_FOR_REMOVAL ||
      key === SMPL_TEMP_STRINGLIST_FOR_REMOVAL
    ) {
      customValues = {
        ...customValues,
        [key]: value,
      }
    }
  }

  const valuesWithoutCustomFields = removeCustomFields(values)

  return { valuesWithoutCustomFields, customValues }
}

function removeCustomFields(values: Record) {
  // remove all custom fields
  const valuesWithoutCustom = produce(values, (draft) => {
    delete draft[SMPL_TEMP_CATEGORIES]
    delete draft[SMPL_TEMP_PERSON]
    delete draft[SMPL_TEMP_IMG_FILES]
    delete draft[SMPL_TEMP_LICENSE]
    delete draft[SMPL_TEMP_CATEGORIES_FOR_REMOVAL]
    delete draft[SMPL_TEMP_STRINGLIST_FOR_REMOVAL]
  })

  return valuesWithoutCustom
}

async function makeConnectionToCategories(
  dataProvider: DataProviderProxy,
  resource: string,
  categories:
    | {
        [x: string]: string
      }[]
    | undefined,
  entryId: string
) {
  if (categories) {
    try {
      await Promise.all(
        categories.map(async (cat) => {
          return await dataProvider.create('CmsMovieContentCategory', {
            data: {
              id: '000000000000000',
              categoryId: cat.id,
              contentMovieId: resource === 'CmsMovie' ? entryId : null,
              contentSeriesId: resource === 'CmsSery' ? entryId : null,
            },
          })
        })
      )
    } catch (error) {
      // INFO: known Error: duplicate key value violates unique constraint "..."
      // if using import from imdb Button (need fix in imdbImportInput)
      console.error(error)
    }
  }
}

async function makeConnectionToPersons(
  dataProvider: DataProviderProxy,
  resource: string,
  persons: TempPerson[] | undefined,
  entryId: string
) {
  if (persons) {
    try {
      await Promise.all(
        persons.map(async (person) => {
          return await dataProvider.create('CmsMovieContentPerson', {
            data: {
              id: '000000000000000',
              connectionType: person.connectionType,
              personId: person.id,
              contentMovieId: resource === 'CmsMovie' ? entryId : null,
              contentSeriesId: resource === 'CmsSery' ? entryId : null,
              contentSeasonId: resource === 'CmsSeason' ? entryId : null,
              contentEpisodeId: resource === 'CmsEpisode' ? entryId : null,
            },
          })
        })
      )
    } catch (error) {
      // INFO: known Error: duplicate key value violates unique constraint "..."
      // if using import from imdb Button (need fix in imdbImportInput)
      console.error(error)
    }
  }
}

async function updateConnectedLicenses(
  dataProvider: DataProviderProxy,
  resource: string,
  licenses: CmsLicense[] | undefined,
  entryId: string
) {
  if (licenses) {
    try {
      await Promise.all(
        licenses.map(async (license) => {
          return await dataProvider.update('CmsLicense', {
            id: license.id,
            data: {
              ...license,
              contentMovieId: resource === 'CmsMovie' ? entryId : undefined,
              contentEpisodeId: resource === 'CmsEpisode' ? entryId : undefined,
            },
            previousData: { ...license },
          })
        })
      )
    } catch (error) {
      console.error(error)
    }
  }
}

export async function uploadImageAndIdToForm(
  dataProvider: DataProviderProxy,
  resource: string,
  tempImages: TempImg | undefined
) {
  if (!tempImages) return

  const uploadedRes = await Promise.all(
    Object.keys(tempImages).map(async (tI) => {
      try {
        // @ts-ignore
        const imgFile: File | undefined = tempImages[tI]
        if (!imgFile) {
          throw new Error(
            `${tI} is undefined. User probably delete after choosing an image. Ignore.`
          )
        }

        // get width and height for CmsImage object
        const { width, height } = await new Promise<{
          width: number
          height: number
        }>(function (resolve, reject) {
          const img = new Image()
          let width, height
          img.onload = () => {
            width = img.width
            height = img.height
            if (width && height) {
              URL.revokeObjectURL(img.src)
              resolve({ width, height })
            } else {
              reject(new Error('No Dimension'))
            }
          }
          img.onerror = () => {
            reject(new Error('Not an image.'))
          }
          img.src = URL.createObjectURL(imgFile)
        })

        // UPLOAD on AWS
        // INFO: may need to adapt this and code in DropZone if upload target for image is not AWS

        // Get presigned url for upload
        const bodyData = {
          name: imgFile.name,
        }
        const params = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(bodyData),
        }
        const res = await authedFetch(
          process.env.PUBLIC_URL + '/api/getPreSignedS3Url',
          params
        )
        const resData = await res.json()

        if (resData.success !== true)
          throw new Error('Failed to get presigned s3 url for upload.')

        // Create form for upload
        const { url, fields } = resData.preSignedUrl
        const formData = new FormData()
        formData.append('Content-Type', imgFile.type)
        Object.entries(fields).forEach(([k, v]) => {
          formData.append(k, v as string)
        })
        formData.append('file', imgFile)

        // Uploading to AWS
        const uploadRes = await fetch(url, {
          method: 'POST',
          body: formData,
        })

        if (!uploadRes.ok) throw new Error('Failed to upload to AWS.')

        // Create new CmsImage with url + fields.key from fetch above
        const cloudfrontUrl = IMG_CLOUDFRONT_URL_PREFIX
        const masterUrl = cloudfrontUrl + '/' + fields.key

        const createdImageRes = await dataProvider.create('CmsImage', {
          data: {
            id: '000000000000000',
            name: imgFile.name.replace(/\.[^/.]+$/, ''),
            masterUrl: masterUrl,
            width: width,
            height: height,
          },
        })

        if (!createdImageRes.data) throw new Error('Failed to create CmsImage.')

        return {
          [tI]: createdImageRes.data.id as string,
        }
      } catch (error) {
        console.error(error)
        return null
      }
    })
  )
  let uploadImageRes: { [x: string]: string } = {}

  uploadedRes
    .filter((upRes) => upRes !== null)
    .map((upRes) => {
      uploadImageRes = { ...uploadImageRes, ...upRes }
    })

  return uploadImageRes
}

export function mergeValues(v1: Record, v2: object) {
  return { ...v1, ...v2 } as Record
}

export const movieCreateSave = async (props: CustomSaveProps) => {
  const { dataProvider, previousValues, resource, values } = props

  const {
    valuesWithoutCustomFields,
    customValues,
  } = extractAndRemoveCustomFields(values as Record)
  let cleanValues = cleanFields(valuesWithoutCustomFields)

  try {
    const uploadImageRes = await uploadImageAndIdToForm(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_IMG_FILES] as TempImg
    )

    if (uploadImageRes) {
      cleanValues = mergeValues(cleanValues, uploadImageRes)
    }

    const createdCmsObject = await dataProvider.create(resource, {
      data: {
        // @ts-ignore Ignore because in create there are no id in cleanValues
        id: '000000000000000', // will be replaced server side with an actual id
        ...cleanValues,
      },
    })

    const createdEntryId = createdCmsObject.data.id

    await makeConnectionToCategories(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_CATEGORIES] as {
        [x: string]: string
      }[],
      createdEntryId as string
    )

    await makeConnectionToPersons(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_PERSON] as TempPerson[],
      createdEntryId as string
    )

    await updateConnectedLicenses(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_LICENSE] as CmsLicense[],
      createdEntryId as string
    )

    return {
      data: createdCmsObject.data,
    }
  } catch (error) {
    throw error
  }
}

export const movieEditSave = async (props: CustomSaveProps) => {
  const { dataProvider, previousValues, resource, values } = props
  const {
    valuesWithoutCustomFields,
    customValues,
  } = extractAndRemoveCustomFields(values as Record)
  let cleanValues = cleanFields(valuesWithoutCustomFields)
  const updatedEntryId = cleanValues.id

  try {
    await makeConnectionToCategories(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_CATEGORIES] as {
        [x: string]: string
      }[],
      updatedEntryId as string
    )

    await makeConnectionToPersons(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_PERSON] as TempPerson[],
      updatedEntryId as string
    )

    const uploadImageRes = await uploadImageAndIdToForm(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_IMG_FILES] as TempImg
    )

    if (uploadImageRes) {
      cleanValues = mergeValues(cleanValues, uploadImageRes)
    }
  } catch (error) {
    throw error
  }

  try {
    const updatedCmsObject = await dataProvider.update(resource, {
      // @ts-ignore
      id: values.id,
      data: { ...cleanValues },
      previousData: previousValues!,
    })
    return { data: updatedCmsObject.data }
  } catch (error) {
    //@ts-ignore
    if (error.message && error.message.includes(toBeRedirectError)) {
      // simply return the clean unchanged values, there was probably only connection of category, person etc.
      // this will lead to redirect to show page
      return { data: cleanValues }
    } else {
      throw error
    }
  }
}
