import { makeStyles } from '@material-ui/core'
import { motion } from 'framer-motion'

const useStyles = makeStyles(() => ({
  creditsWrap: {
    width: '100%',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  credits: {
    fontSize: '0.75rem',
    color: '#35353550',
  },
}))
export function SmplFooter() {
  const classes = useStyles()
  return (
    <motion.div
      initial={{ y: -50, opacity: 0, zIndex: -1 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ ease: 'backOut', duration: 1, delay: 0.5 }}
    >
      <div className={classes.creditsWrap}>
        <div className={classes.credits}>Made with ♥ by simpleTechs GmbH</div>
      </div>
    </motion.div>
  )
}
