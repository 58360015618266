import AccountTreeIcon from '@material-ui/icons/AccountTree'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import AssignmentIcon from '@material-ui/icons/Assignment'
import BugReportIcon from '@material-ui/icons/BugReport'
import CachedIcon from '@material-ui/icons/Cached'
import CancelIcon from '@material-ui/icons/Cancel'
import ChatIcon from '@material-ui/icons/Chat'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClearIcon from '@material-ui/icons/Clear'
import DescriptionIcon from '@material-ui/icons/Description'
import DvrIcon from '@material-ui/icons/Dvr'
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed'
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered'
import GavelIcon from '@material-ui/icons/Gavel'
import GetAppIcon from '@material-ui/icons/GetApp'
import GradeIcon from '@material-ui/icons/Grade'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import LinkIcon from '@material-ui/icons/Link'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import LocalPlayIcon from '@material-ui/icons/LocalPlay'
import MessageIcon from '@material-ui/icons/Message'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import MovieIcon from '@material-ui/icons/Movie'
import PeopleAltIcon from '@material-ui/icons/PeopleAlt'
import PublishIcon from '@material-ui/icons/Publish'
import ReceiptIcon from '@material-ui/icons/Receipt'
import RestoreIcon from '@material-ui/icons/Restore'
import SelectAllIcon from '@material-ui/icons/SelectAll'
import SkipNextIcon from '@material-ui/icons/SkipNext'
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo'
import StoreIcon from '@material-ui/icons/Store'
import SubscriptionsIcon from '@material-ui/icons/Subscriptions'
import TheatersIcon from '@material-ui/icons/Theaters'
import TransferWithinAStationIcon from '@material-ui/icons/TransferWithinAStation'
import WallpaperIcon from '@material-ui/icons/Wallpaper'
import { smplColors } from '../layout/themes'

export const smplTexts: {
  appbar: { [key: string]: string }
  menu: {
    [key: string]: {
      menuName: string
      tooltip: string
      icon: any
    }
  }
  datagrid: { [key: string]: string }
  showPageTabs: {
    [key: string]: {
      tabName: string
      tooltip: string
      icon: any
    }
  }
  editPage: {
    [key: string]: {
      title: string
      tooltip: string
      icon: any
    }
  }
} = {
  appbar: {
    search: 'Search the whole database',
  },
  menu: {
    CmsCategory: {
      menuName: 'Categories',
      tooltip: '',
      icon: <AccountTreeIcon />,
    },
    CmsClip: {
      menuName: 'Clips',
      tooltip: '',
      icon: <SubscriptionsIcon />,
    },
    CmsEpisode: {
      menuName: 'Episodes',
      tooltip: '',
      icon: <TheatersIcon />,
    },
    CmsFeedObject: {
      menuName: 'Feed Objects',
      tooltip: '',
      icon: <ChatIcon />,
    },
    CmsImage: {
      menuName: 'Images',
      tooltip: '',
      icon: <WallpaperIcon />,
    },
    CmsMovie: {
      menuName: 'Movies',
      tooltip: '',
      icon: <MovieIcon />,
    },
    CmsPerson: {
      menuName: 'Persons',
      tooltip: '',
      icon: <PeopleAltIcon />,
    },
    CmsSeason: {
      menuName: 'Seasons',
      tooltip: '',
      icon: <DynamicFeedIcon />,
    },
    CmsSery: {
      menuName: 'Series',
      tooltip: '',
      icon: <DvrIcon />,
    },
    CmsContract: {
      menuName: 'Contracts',
      tooltip: '',
      icon: <ReceiptIcon />,
    },
    CmsLicense: {
      menuName: 'Licenses',
      tooltip: '',
      icon: <GavelIcon />,
    },
    CmsLicensor: {
      menuName: 'Licensors',
      tooltip: '',
      icon: <HomeWorkIcon />,
    },
    TranscodingJob: {
      menuName: 'Transcoding Jobs',
      tooltip: '',
      icon: <SlowMotionVideoIcon />,
    },
    MerchantConfiguration: {
      menuName: 'Merchants',
      tooltip: '',
      icon: <StoreIcon />,
    },
    PaymentExternalUser: {
      menuName: 'External Users',
      tooltip: '',
      icon: <TransferWithinAStationIcon />,
    },
    PaymentInvoice: {
      menuName: 'Invoices',
      tooltip: '',
      icon: <DescriptionIcon />,
    },
    PaymentScheduledCancellation: {
      menuName: 'Scheduled Cancellations',
      tooltip: '',
      icon: <CancelIcon />,
    },
    PaymentTransaction: {
      menuName: 'Transactions',
      tooltip: '',
      icon: <CheckCircleIcon />,
    },
    PaymentTransactionLog: {
      menuName: 'Transactions Log',
      tooltip: '',
      icon: <AssignmentIcon />,
    },
    Subscription: {
      menuName: 'Subscriptions',
      tooltip: '',
      icon: <CachedIcon />,
    },
    SubscriptionProduct: {
      menuName: 'Products',
      tooltip: '',
      icon: <LocalOfferIcon />,
    },
    SubscriptionTerm: {
      menuName: 'Terms',
      tooltip: '',
      icon: <RestoreIcon />,
    },
    VoucherCampaign: {
      menuName: 'Campaigns',
      tooltip: '',
      icon: <GradeIcon />,
    },
    VoucherCode: {
      menuName: 'Vouchers',
      tooltip: '',
      icon: <LocalPlayIcon />,
    },
  },
  datagrid: {
    filter: 'Create as many filters as you wish',
    autotask: 'Rerun automation task',
    export: 'Create and download .csv file',
    config: 'Toggle and sort table columns',
  },
  showPageTabs: {
    //! general
    Details: {
      tabName: 'Details',
      tooltip: '',
      icon: <DescriptionIcon />,
    },
    Related: {
      tabName: 'Related',
      tooltip: '',
      icon: <LinkIcon />,
    },
    System: {
      tabName: 'System',
      tooltip: '',
      icon: <SelectAllIcon />,
    },
    Advanced: {
      tabName: 'Advanced',
      tooltip: '',
      icon: <MoreHorizIcon />,
    },
    //! Categories
    Subcategories: {
      tabName: 'Subcategories',
      tooltip: '',
      icon: <AllInboxIcon />,
    },
    SmplSort: {
      tabName: 'Sort order',
      tooltip: '',
      icon: <FormatListNumberedIcon />,
    },
    //! Mediacontents
    Review: {
      tabName: 'Review',
      tooltip: '',
      icon: <SkipNextIcon />,
    },
    //! Transcoding
    Input: {
      tabName: 'Input',
      tooltip: '',
      icon: <GetAppIcon />,
    },
    Output: {
      tabName: 'Output',
      tooltip: '',
      icon: <PublishIcon />,
    },
    Debug: {
      tabName: 'Debug',
      tooltip: '',
      icon: <BugReportIcon />,
    },
    Comment: {
      tabName: 'Comment',
      tooltip: '',
      icon: <MessageIcon />,
    },
  },
  editPage: {
    saveButton: {
      title: 'Save',
      tooltip: '',
      icon: null,
    },
    saveAndBackToListButton: {
      title: 'Save & Back to List',
      tooltip: '',
      icon: <ArrowBackIcon />,
    },
    discardChangesButton: {
      title: 'Discard Changes',
      tooltip: '',
      icon: (
        <ClearIcon
          style={{ color: smplColors.error.main, transform: 'scale(1.2)' }}
        />
      ),
    },
  },
}
