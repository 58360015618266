import { IconButton, InputAdornment } from '@material-ui/core'
import Tooltip from '@material-ui/core/Tooltip'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import { showNotification as showNotificationAction, useQuery } from 'ra-core'
import { TextFieldProps } from 'react-admin'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { GOOGLE_DEV_ID, SMPL_ENV } from '../../lib/config'
import { EnhancedTextField } from './EnhancedTextField'

type Props = TextFieldProps

type InnerProps = TextFieldProps & {
  showNotification: (
    message: string,
    type: 'warning' | 'info' | 'error'
  ) => void
  inputProps?: { useFieldValueAsUrl: boolean }
}

const handleClickCopy = (
  url: string,
  showNotification: (
    message: string,
    type: 'warning' | 'info' | 'error'
  ) => void
) => {
  var temp = document.createElement('textarea')
  temp.value = url
  temp.setAttribute('readonly', '')
  // @ts-ignore
  temp.style = { position: 'absolute', left: '-999px' }
  document.body.appendChild(temp)
  temp.select()
  document.execCommand('copy')
  document.body.removeChild(temp)
  showNotification('URL added to clip board', 'info')
}

const handleClickOpen = (url: string) => {
  var win = window.open(url, '_blank')
  if (win) {
    win.focus()
  }
}

const PaymentProviderIdFieldImpl = (props: InnerProps) => {
  const { source, record, className, showNotification, ...otherProps } = props

  const { loading, error, data } = useQuery({
    type: 'getManyReference',
    resource: record!.__typename,
    payload: {
      id: record!.id,
      target: 'paymentTransactionLogsByPaymentTransactionId',
    },
  })

  const fieldValue: string = record![source!]
  var url: string | undefined // if this stay undefined then only copy button will be shown
  var openUrlTooltipsLabel = 'Open link in a new window'

  if (data && data.length > 0) {
    const {
      paypalTransactionId,
      stripeTransactionId,
      googleTransactionId,
      // there are more
    } = data[0]
    let provider: string | undefined = paypalTransactionId
      ? 'paypal'
      : stripeTransactionId
      ? 'stripe'
      : googleTransactionId
      ? 'googleIAP'
      : undefined

    switch (provider) {
      case 'paypal':
        // Paypal Invoice
        // extract the paypal invoice Id from log
        var invoiceId: string
        if (fieldValue.startsWith('I-')) {
          invoiceId = fieldValue
        } else {
          const d = data.find(
            (d: any) =>
              d.transactionStep === 'PAYMENT.SALE.COMPLETED' || // renewal and non trial term has this
              d.transactionStep === 'PAYPAL_SUBSCRIPTION_ACTIVATION' // the one above don't exist for trial term
          )
          if (!d) {
            showNotification(
              'Can not create link to Paypal. Please contact the support.',
              'warning'
            )
            break
          }
          if (d.transactionStep === 'PAYPAL_SUBSCRIPTION_ACTIVATION') {
            invoiceId = d.paypalTransactionId
          } else {
            invoiceId = JSON.parse(d.log).resource.billing_agreement_id
          }
        }
        url =
          (SMPL_ENV === 'dev'
            ? 'https://www.sandbox.paypal.com/billing/subscriptions/'
            : 'https://www.paypal.com/billing/subscriptions/') + invoiceId
        openUrlTooltipsLabel = 'Open paypal invoice page in new tab'
        break
      case 'stripe':
        // Stripe Invoice
        url =
          (SMPL_ENV === 'dev'
            ? 'https://dashboard.stripe.com/test/invoices/'
            : 'https://dashboard.stripe.com/invoices/') + fieldValue
        openUrlTooltipsLabel = 'Open stripe invoice page in new tab'
        break
      case 'googleIAP':
        // Google IAP
        if (GOOGLE_DEV_ID) {
          url =
            'https://play.google.com/console/u/1/developers/' +
            GOOGLE_DEV_ID +
            '/orders/' +
            fieldValue
          openUrlTooltipsLabel = 'Open Google Play invoice page in new tab'
        } else {
          showNotification(
            'Can not create link to Google Play. Google developer id is missing in configuration. Please contact the support.',
            'warning'
          )
        }
        break
      default:
        break
    }
  }
  const InputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <Tooltip title="Copy value to clipboard">
          <IconButton
            aria-label="Copy value to clipboard"
            onClick={() => {
              handleClickCopy(fieldValue, showNotification)
            }}
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        {url ? (
          <Tooltip title={openUrlTooltipsLabel}>
            <IconButton
              aria-label={openUrlTooltipsLabel}
              onClick={() => {
                handleClickOpen(url!)
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </InputAdornment>
    ),
  }

  return (
    <EnhancedTextField
      className={`${className}`}
      source={source}
      record={record}
      InputProps={InputProps}
    />
  )
}

function MaxLengthUrlFieldImpl(props: InnerProps) {
  const { source, record, className, showNotification, ...otherProps } = props
  if (!source || !record || record[source] === undefined) {
    console.error(
      'MaxLengthUrlField, trying to render a field that is not in the record',
      source,
      record
    )
    return null
  }

  const { inputProps } = otherProps

  const { useFieldValueAsUrl } = inputProps || {
    useFieldValueAsUrl: true,
  }

  const fieldValue: string = record[source]
  var url: string | undefined // if this stay undefined then only copy button will be shown
  var openUrlTooltipsLabel = 'Open link in a new window'

  if (useFieldValueAsUrl) {
    url = fieldValue
  } else if (typeof fieldValue === 'string') {
    // default list of thing we handle
    if (source === 'paymentProviderId') {
      // because we need to run useQuery hook to get specific related table
      return <PaymentProviderIdFieldImpl {...props} />
    } // add more
  }

  const InputProps = {
    endAdornment: (
      <InputAdornment position="end">
        <Tooltip title="Copy value to clipboard">
          <IconButton
            aria-label="Copy value to clipboard"
            onClick={() => {
              handleClickCopy(fieldValue, showNotification)
            }}
          >
            <FileCopyIcon />
          </IconButton>
        </Tooltip>
        {url ? (
          <Tooltip title={openUrlTooltipsLabel}>
            <IconButton
              aria-label={openUrlTooltipsLabel}
              onClick={() => {
                handleClickOpen(url!)
              }}
            >
              <OpenInNewIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </InputAdornment>
    ),
  }

  return (
    <EnhancedTextField
      className={`${className}`}
      source={source}
      record={record}
      InputProps={InputProps}
    />
  )
}

export const MaxLengthUrlField = compose<InnerProps, Props>(
  connect(null, { showNotification: showNotificationAction })
)(MaxLengthUrlFieldImpl)
