import { IntrospectionType } from 'graphql'
import gql from 'graphql-tag'
import produce from 'immer'
import isEmpty from 'lodash/isEmpty'
import lowerFirst from 'lodash/lowerFirst'
import { Record } from 'react-admin'
import { Resource } from './ReactAdmin-Types'
import { getFragmentForType } from './getFragmentForType'

export type UpdateOneParams = {
  [key: string]: any
  id: string
  data: Record
  previousData: Record
}

function transformUpdateOneParamsToVariables(params: UpdateOneParams) {
  console.log('deleteOne params', params)
  const { id, data, previousData } = params
  const patch: { [key: string]: any } = {}

  Object.keys(previousData).forEach((key) => {
    const prev = previousData[key]
    const newVal = data[key]
    if (prev !== newVal) {
      patch[key] = newVal
    }
  })
  console.log('UPDATE: applying patch', patch)

  return {
    id,
    patch,
  }
}

const updateOneQuery = function updateOneQuery(
  queryName: string,
  resource: Resource
) {
  // TODO: move to postgraphile file
  const PatchName = `${resource.type.name}Patch`
  const lowerName = lowerFirst(resource.type.name)
  const patchVariableName = `${lowerName}Patch`

  const {
    fragment: AllTypesFragment,
    fragmentName: AllTypesFragmentName,
  } = getFragmentForType(resource.type)
  const query = gql
  return query`
          ${AllTypesFragment}
          mutation ${queryName}($id: Guid!, $patch: ${PatchName}!) {
              data: ${queryName}(input: { id: $id, ${patchVariableName}: $patch }) {
                clientMutationId
                ${lowerName} {
                  ...${AllTypesFragmentName}
                }
              }
          }
      `
}

export function updateOneQueryName(resource: IntrospectionType) {
  return `update${resource.name}ById`
}

// pasted from movieSave.ts here because imported from there will result in error
function cleanFields(values: Record) {
  // make deep copy with immer.produce
  // https://immerjs.github.io/immer/#with-immer

  const newValues = produce(values, (draft) => {
    Object.keys(draft).forEach((key) => {
      if (Array.isArray(draft[key])) {
        let valueArray = cleanFields(draft[key])
        draft[key] = valueArray
      } else if (draft[key] && typeof draft[key] === 'object') {
        delete draft[key].__typename
      } else if (typeof draft[key] === 'string' && draft[key].length === 0) {
        // replace "" with null
        draft[key] = null
      }
    })
    if (draft.hasOwnProperty('__typename')) {
      delete draft.__typename
    }
  })

  return newValues
}

export function graphQlQueryDescriptor(
  queryName: string,
  resource: Resource,
  params: UpdateOneParams
) {
  const variables = transformUpdateOneParamsToVariables(params)

  const patch = cleanFields(variables.patch as Record)

  if (isEmpty(patch)) {
    // TODO: find out why this crash the page
    throw new Error(
      `Nothing to update! No value has changed for id ${variables.id}`
    )
  }
  return {
    query: updateOneQuery(queryName, resource),
    variables: {
      ...variables,
      patch: patch,
    },
    parseResponse: (response: Object) => {
      // TODO: move to postgraphile file
      const lowerName = lowerFirst(resource.type.name)
      // @ts-ignore
      if (!response || !response.data || !response.data.data) {
        console.error('graphql could not find any data!', response)
        throw new Error(
          `Could not update ${resource.type.name} with id "${params.id}"!`
        )
      }

      const {
        // @ts-ignore
        data: {
          data: { [lowerName]: newData },
        },
      } = response
      return {
        // data: params.id,
        data: newData,
      }
    },
  }
}
