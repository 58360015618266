import { StringListFilter } from '../../__generated__/globalTypes'
import { monitoringTags } from '../../commons/flexgold/constants'
import { FieldFilter, GraphQLFilter } from '../../dataProvider/buildFilter'
import { isUniversumCustomer } from '../../lib/config'

export type FilterPreset = {
  label: string
  internal: string
  filter: GraphQLFilter
  hidden?: boolean
}

export const getRandomKey = () => {
  return Math.random().toFixed(10).split('.')[1]
}

/** TAGGED IDENTIFICATION DATA FILTER */
const allTaggedIdentificationDataFilterInternal = 'allTaggedIdentificationData'
const allTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'Tagged Identification Data',
  internal: allTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          anyNotEqualTo: 'x',
        },
      },
    ],
  } as GraphQLFilter,
}

const depositOver500kTaggedIdentificationDataFilterInternal =
  'depositOver500kTaggedIdentificationData'
const depositOver500kTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'Over 500k Deposits Tagged Identification Data',
  internal: depositOver500kTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          contains: monitoringTags.depositsOver500k,
        },
      },
    ],
  } as GraphQLFilter,
}

const frequentDepositsAndPayoutsTaggedIdentificationDataFilterInternal =
  'frequentDepositsAndPayoutsTaggedIdentificationData'
const frequentDepositsAndPayoutsTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'Frequent Deposits and Payouts Tagged Identification Data',
  internal: frequentDepositsAndPayoutsTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          contains: monitoringTags.freqDepositsAndPayouts,
        },
      },
    ],
  } as GraphQLFilter,
}

const depositsOutScale5xSalaryTaggedIdentificationDataFilterInternal =
  'depositsOutScale5xSalaryTaggedIdentificationData'
const depositsOutScale5xSalaryTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'Deposits Out-Scale 5x Salary Tagged Identification Data',
  internal: depositsOutScale5xSalaryTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          contains: monitoringTags.depostisOutScale5xSalary,
        },
      },
    ],
  } as GraphQLFilter,
}

const highRiskIndustryTaggedIdentificationDataFilterInternal =
  'highRiskIndustryTaggedIdentificationData'
const highRiskIndustryTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'High Risk Industry Tagged Identification Data',
  internal: highRiskIndustryTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          contains: monitoringTags.highRiskIndustry,
        },
      },
    ],
  } as GraphQLFilter,
}

const highRiskOtherTaggedIdentificationDataFilterInternal =
  'highRiskOtherTaggedIdentificationData'
const highRiskOtherTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'High Risk Other Tagged Identification Data',
  internal: highRiskOtherTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          contains: monitoringTags.highRiskOther,
        },
      },
    ],
  } as GraphQLFilter,
}

const pepTaggedIdentificationDataFilterInternal = 'pepTaggedIdentificationData'
const pepTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'Pep Tagged Identification Data',
  internal: pepTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          contains: monitoringTags.pep,
        },
      },
    ],
  } as GraphQLFilter,
}

const noAmlTaggedIdentificationDataFilterInternal =
  'noAmlTaggedIdentificationData'
const noAmlTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'No AML Tagged Identification Data',
  internal: noAmlTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          contains: monitoringTags.noAmlProof,
        },
      },
    ],
  } as GraphQLFilter,
}

const frequentAddressChangeTaggedIdentificationDataFilterInternal =
  'frequentAddressChangeTaggedIdentificationData'
const frequentAddressChangeTaggedIdentificationDataFilter: FilterPreset = {
  hidden: true,
  label: 'Frequent Address Change Tagged Identification Data',
  internal: frequentAddressChangeTaggedIdentificationDataFilterInternal,
  filter: {
    and: [
      {
        monitoringTags: {
          contains: monitoringTags.freqAddressChange,
        },
      },
    ],
  } as GraphQLFilter,
}

/** TRANSCODING JOB FILTER */
const transcodingJobIsInQueue: FieldFilter = {
  status: { equalTo: 'IN_QUEUE' },
}

const transcodingJobIsReadyForTranscoding: FieldFilter = {
  status: { equalTo: 'READY_FOR_TRANSCODING' },
}

const transcodingJobIsInProgress: FieldFilter = {
  status: { equalTo: 'IN_PROGRESS' },
}

const transcodingJobIsCompleted: FieldFilter = {
  status: { equalTo: 'COMPLETED' },
}

const transcodingJobIsReadyForQA: FieldFilter = {
  status: { equalTo: 'READY_FOR_QA' },
}

// this is an error status provided by AWS and not by us (we handle the "status" field)
const transcodingJobHasAwsError: FieldFilter = {
  awsStatus: { equalTo: 'ERROR' },
}

const transcodingJobHasError: FieldFilter = {
  status: { equalTo: 'ERROR' },
}

const transcodingJobIsRejected: FieldFilter = {
  status: { equalTo: 'REJECTED' },
}

/** OTHER FILTER */
const coverImageExists: FieldFilter = {
  // label: Image Asset Missing (Cover)
  coverImageId: { isNull: false },
}

const widescreenImageExists: FieldFilter = {
  // label: Image Asset Missing (Widescreen)
  widescreenImageId: { isNull: false },
}

const missingImageAssetTrue: FieldFilter = {
  // Missing Image(s)
  missingImageAsset: {
    equalTo: true,
  },
}

const missingImagesFalse: FieldFilter = {
  // Missing Image(s)
  missingImageAsset: {
    equalTo: false,
  },
}

const hasVideoSourceFalse: FieldFilter = {
  // label: Video Asset Missing
  hasVideoSource: {
    equalTo: false,
  },
}

// replace this with the one below missingImageFilter after db has been updated
const missingImageFilterEpi: FilterPreset = {
  label: 'Image Asset Missing',
  internal: 'imageAssetMissingEpisode',
  filter: {
    and: [coverImageExists, widescreenImageExists],
  } as GraphQLFilter,
}

// FIXME: this is not a helpful filter. Switch to widescreen=null OR coverImage = null once OR is supported!
// const missingImageFilter: FilterPreset = {
//   label: 'Image Assets Set',
//   internal: 'imageAssetMissing',
//   filter: {
//     and: [missingImageAssetTrue],
//   } as GraphQLFilter,
// }

// jobs with status IN_QUEUE or IN_PROGRESS
const allTranscodingJobsInQueueFilterInternal = 'allTranscodingJobsInQueue'
const allTranscodingJobsInQueueFilter: FilterPreset = {
  hidden: true,
  label: 'Jobs In Queue',
  internal: allTranscodingJobsInQueueFilterInternal,
  filter: {
    or: [
      transcodingJobIsInQueue,
      transcodingJobIsInProgress,
      transcodingJobIsReadyForTranscoding,
    ],
  } as GraphQLFilter,
}

// jobs with status COMPLETED or READY_FOR_QA
const allTranscodingJobsFinishedFilterInternal = 'allTranscodingJobsFinished'
const allTranscodingJobsFinishedFilter: FilterPreset = {
  hidden: true,
  label: 'Finished Jobs',
  internal: allTranscodingJobsFinishedFilterInternal,
  filter: {
    or: [transcodingJobIsCompleted, transcodingJobIsReadyForQA],
  } as GraphQLFilter,
}

// all other jobs
const allTranscodingJobsFailedFilterInternal = 'allTranscodingJobsFailed'
const allTranscodingJobsFailedFilter: FilterPreset = {
  hidden: true,
  label: 'Failed Jobs',
  internal: allTranscodingJobsFailedFilterInternal,
  filter: {
    or: [
      transcodingJobHasAwsError,
      transcodingJobHasError,
      transcodingJobIsRejected,
    ],
  } as GraphQLFilter,
}

const missingVideoSourceFilter: FilterPreset = {
  label: 'Video Asset Missing',
  internal: 'missingVideoSource',
  filter: {
    and: [hasVideoSourceFalse],
  } as GraphQLFilter,
}

const noActiveLicense: FilterPreset = {
  internal: 'noActiveLicense',
  label: 'No Active License',
  filter: {
    and: [
      {
        // TODO: as var
        activeLicenseOnPlatform: {
          containedBy: [null],
        },
      },
    ],
  } as GraphQLFilter,
  // value: [
  //   {
  //     fieldName: 'activeLicenseOnPlatform',
  //     filterOp: 'containedBy',
  //     label: 'No Active License',
  //     value: [null],
  //   },
  // ],
}

function getActiveOnPlatformNotEqualTo(platform: string): FieldFilter {
  return {
    activeLicenseOnPlatform: {
      anyNotEqualTo: platform,
    } as StringListFilter,
  }
}

function activeLicenseOnPlatform(platform: string): FieldFilter {
  return {
    activeLicenseOnPlatform: {
      contains: [platform],
    } as StringListFilter,
  }
}

// FOR UNI
const noActiveLicenseHoH: FilterPreset = {
  internal: 'noActiveLicenseHoH',
  label: 'No License HoH',
  filter: {
    and: [getActiveOnPlatformNotEqualTo('homeofhorror')],
  } as GraphQLFilter,
}

const hasVideoSourceTrue: FieldFilter = {
  hasVideoSource: { equalTo: true },
}

const onlyImageMissingHoH: FilterPreset = {
  internal: 'onlyImageAssetMissingHoH',
  label: 'Only Image Asset Missing (HoH)',
  filter: {
    and: [
      activeLicenseOnPlatform('homeofhorror'),
      hasVideoSourceTrue,
      missingImageAssetTrue,
    ],
  } as GraphQLFilter,
}

const onlyVideoSourceMissingHoH: FilterPreset = {
  internal: 'onlyVideoSourceMissingHoH',
  label: 'Only Video Asset Missing (HoH)',
  filter: {
    and: [
      activeLicenseOnPlatform('homeofhorror'),
      hasVideoSourceFalse,
      missingImagesFalse,
    ],
  } as GraphQLFilter,
}

// FILMTASTIC

const noActiveLicenseFT: FilterPreset = {
  internal: 'noActiveLicenseFT',
  label: 'No License Filmtastic',
  filter: {
    and: [getActiveOnPlatformNotEqualTo('filmtastic')],
  } as GraphQLFilter,
}

const onlyImageMissingFT: FilterPreset = {
  internal: 'onlyImageMissingFT',
  label: 'Only Image Asset Missing (FT)',
  filter: {
    and: [
      activeLicenseOnPlatform('filmtastic'),
      hasVideoSourceTrue,
      missingImageAssetTrue,
    ],
  } as GraphQLFilter,
}

const onlyVideoSourceMissingFT: FilterPreset = {
  internal: 'onlyVideoSourceMissingFT',
  label: 'Only Video Asset Missing (FT)',
  filter: {
    and: [
      activeLicenseOnPlatform('filmtastic'),
      hasVideoSourceFalse,
      missingImagesFalse,
    ],
  } as GraphQLFilter,
}

// ARTHOUSECNMA

const noActiveLicenseACNMA: FilterPreset = {
  internal: 'noActiveLicenseACNMA',
  label: 'No License ACNMA',
  filter: {
    and: [getActiveOnPlatformNotEqualTo('arthousecnma')],
  } as GraphQLFilter,
}

const onlyImageMissingACNMA: FilterPreset = {
  internal: 'onlyImageMissingACNMA',
  label: 'Only Image Asset Missing (ACNMA)',
  filter: {
    and: [
      activeLicenseOnPlatform('arthousecnma'),
      hasVideoSourceTrue,
      missingImageAssetTrue,
    ],
  } as GraphQLFilter,
}

const onlyVideoSourceMissingACNMA: FilterPreset = {
  internal: 'onlyVideoSourceMissingACNMA',
  label: 'Only Video Asset Missing (ACNMA)',
  filter: {
    and: [
      activeLicenseOnPlatform('arthousecnma'),
      hasVideoSourceFalse,
      missingImagesFalse,
    ],
  } as GraphQLFilter,
}

const universumFilter = [
  // missingImageFilter,
  missingVideoSourceFilter,
  noActiveLicense,

  // Home of Horror
  noActiveLicenseHoH,
  onlyImageMissingHoH,
  onlyVideoSourceMissingHoH,
  // Filmtastic
  noActiveLicenseFT,
  onlyImageMissingFT,
  onlyVideoSourceMissingFT,
  // ArthouseCNMA
  noActiveLicenseACNMA,
  onlyImageMissingACNMA,
  onlyVideoSourceMissingACNMA,
]

export const filterPresetRessourceMapping: {
  [x: string]: FilterPreset[]
} = {
  CmsMovie: [
    ...(isUniversumCustomer
      ? universumFilter
      : [
          // missingImageFilterEpi,
          missingVideoSourceFilter,
          // noActiveLicense, // INFO turn this one on after all customer db has been updated
        ]),
  ],
  CmsEpisode: [
    missingImageFilterEpi,
    missingVideoSourceFilter,
    // noActiveLicense, // INFO turn this one on after all customer db has been updated
  ],
  TranscodingJob: [
    allTranscodingJobsInQueueFilter,
    allTranscodingJobsFinishedFilter,
    allTranscodingJobsFailedFilter,
  ],
  IdentificationDatum: [
    allTaggedIdentificationDataFilter,
    depositOver500kTaggedIdentificationDataFilter,
    frequentDepositsAndPayoutsTaggedIdentificationDataFilter,
    depositsOutScale5xSalaryTaggedIdentificationDataFilter,
    highRiskIndustryTaggedIdentificationDataFilter,
    pepTaggedIdentificationDataFilter,
    noAmlTaggedIdentificationDataFilter,
    frequentAddressChangeTaggedIdentificationDataFilter,
    highRiskOtherTaggedIdentificationDataFilter,
  ],
}
