import gql from 'graphql-tag'

export const FreezeReasonsQuery = gql`
  query allVaultFreezeReasons {
    vaultFreezeReasons {
      nodes {
        reason: type
      }
    }
  }
`

export const ToggleFreezeMutation = gql`
  mutation toggleFreeze(
    $input: LogVaultFreezeReasonsAndUpdateVaultStatusInput!
  ) {
    logVaultFreezeReasonsAndUpdateVaultStatus(input: $input) {
      clientMutationId
    }
  }
`
