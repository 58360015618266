import { makeStyles } from '@material-ui/core/styles'
import Maybe from 'graphql/tsutils/Maybe'
import { TextInput } from 'react-admin'
import { IsJsonString } from './ObjectArrayJsonFields'

type ObjectJsonFieldsProps = {
  name: string
  helperText: Maybe<string>
}

const useStyles = makeStyles({
  jsonField: {
    width: '100%',
    justifySelf: 'center',
    maxHeight: '35vh',
    overflow: 'auto',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    '& > p': {
      display: 'none',
    },
    marginTop: '8px',
    marginBottom: '4px',
  },
})

const jsonStringValidation = (
  value: { [fieldName in string]: any } | string
) => {
  if (!value) {
    return
  }
  if (typeof value === 'object') {
    return
  }
  if (value.length === 0) {
    return
  }
  if (!IsJsonString(value)) {
    return 'The field is not in a valid format.'
  }
  return
}

const validateJsonString = [jsonStringValidation]

export const ObjectJsonFields = (props: ObjectJsonFieldsProps) => {
  const { name, helperText } = props
  const classes = useStyles()

  /**
   * INFO: Known warning in console: Failed prop type: The prop `value` is marked as required in `ResettableTextField`, but its value is `undefined`.
   * This happens if this field is empty in form e.g. undefined
   */
  return (
    <TextInput
      source={name}
      format={(
        input: { [fieldName in string]: any } | string | null | undefined
      ) => {
        if (!input) {
          return
        }
        if (typeof input === 'string') {
          return input
        }

        const stringifiedValue = JSON.stringify(input, null, 2)
        return stringifiedValue
      }}
      parse={(input: string) => {
        // what ever returned here will go to back to format through validation
        if (input.length === 0) {
          return
        }
        let parsedValue
        try {
          parsedValue = JSON.parse(input)
        } catch (error) {
          // input is not in correct Json format, validate will handle that
          return input
        }
        return parsedValue
      }}
      validate={validateJsonString} // if not passed then user will not be able to save and get an error popup
      className={classes.jsonField}
      multiline
      helperText={helperText}
    />
  )
}
