// based on https://github.com/taras/memoize-getters/blob/master/src/index.js
export function memoizeGetters(Class) {
  const descriptors = Object.getOwnPropertyDescriptors(Class.prototype)

  for (let key in descriptors) {
    let descriptor = descriptors[key]
    if (descriptor && descriptor.get) {
      Object.defineProperty(
        Class.prototype,
        key,
        Object.assign({}, descriptor, {
          get: function memoizedGetter() {
            const value = descriptor.get.call(this)
            // overwrite this function with the pure value
            Object.defineProperty(this, key, {
              value,
              writable: false,
            })
            return value
          },
        })
      )
    }
  }
}
