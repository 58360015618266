import { NoData } from './NoData'

export const VideoInfoFields = (props: any) => {
  const { source, record } = props
  let jsonObject = null
  if (record[source]) {
    jsonObject = JSON.parse(record[source])
    return jsonObject.media.track[1]['Format']
  } else {
    return <NoData />
  }
}
