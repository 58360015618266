import clone from 'lodash/cloneDeep'
import merge from 'lodash/merge'

import { CustomerTheme } from './customerThemeType'
import { FilmhomeTheme } from './filmhome'
import { FlexgoldTheme } from './flexgold'
import { KixiTheme } from './kixi'
import { NetzkinoTheme } from './netzkino'
import { UniversumTheme } from './universum'

import { CUSTOMER, SUPPORTED_CUSTOMERS } from '../../lib/config'
import { ARDTheme } from './ard'
import { FlorianhommTheme } from './florianhomm'
import { StandardTheme } from './standard'

export const themesByCustomer: {
  [key in SUPPORTED_CUSTOMERS]: Partial<CustomerTheme>
} & {
  standard: CustomerTheme
} = {
  standard: StandardTheme,
  ard: ARDTheme,
  kixi: KixiTheme,
  netzkino: NetzkinoTheme,
  universum: UniversumTheme,
  filmhome: FilmhomeTheme,
  flexgold: FlexgoldTheme,
  florianhomm: FlorianhommTheme,
}
export const theme: CustomerTheme = merge(
  clone(themesByCustomer.standard),
  themesByCustomer[CUSTOMER] ?? {}
)
