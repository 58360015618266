import { LinearProgress } from '@material-ui/core'
import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { SmplFooter } from '../layout/SmplFooter'
import appComponentById from './index'

class AppPageLoaderImpl extends React.PureComponent {
  static propTypes = {
    apps: PropTypes.any,
    match: PropTypes.shape({
      params: PropTypes.shape({
        appName: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }

  render() {
    const {
      apps,
      match: {
        params: { appName },
      },
      ...routeProps
    } = this.props

    if (!apps) {
      return <LinearProgress variant="indeterminate" />
    }
    let AppComponent = appComponentById[appName]

    if (!AppComponent) {
      AppComponent = appComponentById.NotFound
    }

    return (
      <Fragment key={appName}>
        <AppComponent {...routeProps} />
        <SmplFooter />
      </Fragment>
    )
  }
}
export const AppPageLoader = connect(function mapStateToProps({ apps }) {
  return {
    apps,
  }
})(AppPageLoaderImpl)
export const hasComponent = (appName) => {
  return !!appComponentById[appName]
}
