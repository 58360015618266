import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { WithStyles, createStyles, withStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import { BooleanField } from 'react-admin'
import { NoData } from './NoData'

const styles = createStyles({
  success: {
    color: 'green',
  },
  failure: {
    color: 'red',
  },
})

type InnerProps = WithStyles<typeof styles>

const ChecksImpl = (props: any & InnerProps) => {
  const { source, record, classes } = props

  let jsonObject = null
  if (record[source]) {
    jsonObject = JSON.parse(record[source])

    return (
      <div>
        {Object.entries(jsonObject).map((elements) => {
          if (elements[0] === 'assets') {
            return null
          } else {
            let flag: boolean = false
            //@ts-ignore
            if (elements[1]['status'] === 'successful') {
              flag = true
            }

            return (
              <Accordion key={elements[0]}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography>
                    {elements[0]} :{/*
                    //@ts-ignore */}
                    {flag ? (
                      <BooleanField
                        className={classes.success}
                        record={{ id: 'null', boolean: flag }}
                        source="boolean"
                      />
                    ) : (
                      <BooleanField
                        className={classes.failure}
                        record={{ id: 'null', boolean: flag }}
                        source="boolean"
                      />
                    )}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>Show Assets which failed status check</Typography>
                </AccordionDetails>
              </Accordion>
            )
          }
        })}
      </div>
    )
  } else {
    return <NoData />
  }
}

const Checks = withStyles(styles)(React.memo(ChecksImpl))

export default Checks
