import { Button } from '@material-ui/core'
import { useRefresh } from 'ra-core'
import { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { MEDIA_CONVERT_CONTROLLER_API } from '../../../../lib/config'
import { useConfirmation } from '../../../ConfirmationService'
import { NodeActionProps } from '../../NodeActionType'
import { createSimpleAuthenticatedClient } from '../../user/lib/simpleClient'

const CreateResourceButton = (props: NodeActionProps) => {
  const { record, resource, targetResource } = props
  const [create, setCreate] = useState(false)
  const { videoSource } = record
  const notify = useNotify()
  const refresh = useRefresh()
  const confirm = useConfirmation()

  const endpoint: string = 'createResourceForJob'

  // excahnge config api string for http://localhost:8000 for testing
  const mediaConvertClient = createSimpleAuthenticatedClient(
    MEDIA_CONVERT_CONTROLLER_API
  )

  // on approve
  useEffect(() => {
    //// send data to to mediaService create end point with known information
    if (create && targetResource) {
      const {
        vendorId,
        metadata,
        awsStatus,
        videoSource,
        mediaInfo,
        runtimeInSeconds,
        introStartTime,
        introEndTime,
        creditsStartTime,
      } = record
      const minRecord = {
        vendorId: vendorId,
        metadata: metadata,
        awsStatus: awsStatus,
        videoSource: videoSource,
        targetRes: targetResource,
        mediaInfo: mediaInfo,
        videoInfo: {
          runtimeInSeconds,
          introStartTime,
          introEndTime,
          creditsStartTime,
        },
      }

      console.log('Start creating a resource for id/vendor_id', vendorId)

      mediaConvertClient('POST', endpoint, { Message: minRecord })
        .then((data) => {
          // open new Tab
          const { movieId, episodeId, clipId } = data
          if (!movieId && !episodeId && !clipId) {
            notify('No resource was created', 'warning')
            return
          }
          const id = clipId || episodeId || movieId
          const hostname = window.location.origin
          let pathname = '/' + targetResource + '/' + id + '/show'
          const url = hostname + pathname
          notify('Resource successfully created.', 'success')
          refresh()
          window.open(url, '_blank')
        })
        .catch((error: any) => {
          notify('Failed to create ressource.', 'warning')
          console.error(error.error || error.message)
          refresh()
        })
      setCreate(false)
    }
  }, [create, record, resource, videoSource, refresh, targetResource, notify])

  const onConfirm = () => {
    setCreate(true)
  }

  const onDeny = () => {
    setCreate(false)
  }

  const queryCreateConfirmation = () => {
    const target =
      targetResource === 'CmsClip'
        ? 'Clip'
        : targetResource === 'CmsMovie'
        ? 'Movie'
        : 'Episode'
    confirm({
      catchOnCancel: true,
      title: 'Create a new ' + target + '?',
      description:
        'No ' +
        target +
        ' could be found for this transcoding job. If one already exist, please set it manually under Edit.',
      onConfirm: onConfirm,
      onDeny: onDeny,
    })
  }

  return (
    <Button
      color="primary"
      onClick={() => queryCreateConfirmation()}
      variant="outlined"
      size="small"
    >
      Create{' '}
      {targetResource === 'CmsClip'
        ? 'Clip'
        : targetResource === 'CmsMovie'
        ? 'Movie'
        : 'Epsiode'}
    </Button>
  )
}

CreateResourceButton.label = 'CreateResourceButton'

export default CreateResourceButton
