import { MemoExoticComponent } from 'react'
import { CategorySortableTreePage } from '../customPages/CategorySortableTreePage'

type T = any

export const ListResourceSetting: {
  [name in string]: {
    [name in string]: {
      comp?: MemoExoticComponent<T> | null
      displayName: string
    }
  }
} = {
  CmsCategory: {
    default: { displayName: 'Table' },
    custom: { comp: CategorySortableTreePage, displayName: 'Tree' },
  },
}
