import { Fade, Tooltip } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { makeStyles } from '@material-ui/core/styles'
import ErrorIcon from '@material-ui/icons/Error'
import OpenInNewIcon from '@material-ui/icons/OpenInNew'
import Eyecon from '@material-ui/icons/RemoveRedEye'
import { useState } from 'react'
import { useQueryWithStore } from 'react-admin'
import Lightbox from 'react-awesome-lightbox'
import 'react-awesome-lightbox/build/style.css'
import { smplColors } from '../../layout/themes'
import { PREVIEW_IMAGE_HEIGHT, PREVIEW_IMAGE_WIDTH } from '../../lib/config'

const useStyles = makeStyles(() => ({
  imgToolbar: { display: 'flex', justifyContent: 'flex-end' },
  imgIconTopCorner: {
    height: '25px',
    // width: '25px',
    border: '1px solid lightgrey',
    backgroundColor: 'white',
    borderRadius: '1px 1px 1px 1px',
    zIndex: 1000,
  },
  toolIcon: {
    marginLeft: 5,
    marginRight: 5,
    color: smplColors.primary.main,
    cursor: 'pointer',
  },
  imgOuterContainer: {
    display: 'inline-flex',
    borderRadius: '0.5em',
    border: '1px solid #eaeaea',
    boxSizing: 'border-box',
    width: '100%',
    // maxHeight: 200,
    padding: 4,
    position: 'relative',
    top: -27,
    backgroundColor: 'lightgray',
  },
  imgContainer: {
    width: PREVIEW_IMAGE_WIDTH,
    height: PREVIEW_IMAGE_HEIGHT,
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
    objectFit: 'contain',
  },
}))

export type ImageServerResizeMode =
  | 'contain'
  | 'cover'
  | 'fill'
  | 'inside'
  | 'outside'

export interface ImageServerEdit {
  blur?: number
  resize?: {
    width: number
    height: number
    fit: ImageServerResizeMode
  }
  background?: {
    r: number
    g: number
    b: number
    alpha: null | number
  }
  trim?: number // threshold, see https://sharp.pixelplumbing.com/api-resize#trim
}

export const imagePreviewEdits: ImageServerEdit = {
  resize: {
    width:
      (typeof PREVIEW_IMAGE_WIDTH === 'string'
        ? parseInt(PREVIEW_IMAGE_WIDTH)
        : PREVIEW_IMAGE_WIDTH) * 2, // for retina
    height:
      (typeof PREVIEW_IMAGE_HEIGHT === 'string'
        ? parseInt(PREVIEW_IMAGE_HEIGHT)
        : PREVIEW_IMAGE_HEIGHT) * 2, // for retina
    fit: 'inside',
  },
}

export function buildImageServerUrl(url: string, edits: ImageServerEdit) {
  if (!url) {
    return ''
  }
  let prefix = '?'
  if (url.includes('?')) {
    prefix = '&'
  }
  return (
    url + prefix + 'edits=' + encodeURIComponent(btoa(JSON.stringify(edits)))
  )
}
export const CustomImageField = (props: any) => {
  const { record, source } = props
  const classes = useStyles()
  const resource = 'CmsImage'
  let imageId
  // Check if rendering preview from within CmsImage-Object and source is "masterUrl"
  if (source === 'masterUrl' && record.__typename === 'CmsImage') {
    imageId = record.id
  } else {
    imageId = record[source]
  }

  const { data, loading, error } = useQueryWithStore({
    type: 'getOne',
    resource,
    payload: {
      id: imageId,
    },
  })
  const [isLightbox, setIsLightbox] = useState(false)
  const toggleLightbox = () => {
    setIsLightbox((current) => !current)
  }

  if (error) {
    return <ErrorIcon />
  }

  if (loading) {
    return <LinearProgress />
  }

  const src: string | undefined = data?.masterUrl
  const imgName: string | undefined = data?.name
  return (
    <div>
      {isLightbox ? (
        <Lightbox image={src} title={imgName} onClose={toggleLightbox} />
      ) : null}
      <div className={classes.imgToolbar}>
        <div className={classes.imgIconTopCorner}>
          <Tooltip
            title="Open CmsImage object in new tab"
            placement="top"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 500 }}
            enterDelay={100}
          >
            <Eyecon
              className={classes.toolIcon}
              onClick={() => {
                const url =
                  process.env.PUBLIC_URL + '/CmsImage/' + imageId + '/show'
                var win = window.open(url, '_blank')
                if (win) {
                  win.focus()
                }
              }}
            />
          </Tooltip>
          <Tooltip
            title="Display source image in new tab"
            placement="top"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 500 }}
            enterDelay={100}
          >
            <OpenInNewIcon
              className={classes.toolIcon}
              onClick={() => {
                const url = buildImageServerUrl(src, imagePreviewEdits)
                var win = window.open(url, '_blank')
                if (win) {
                  win.focus()
                }
              }}
            />
          </Tooltip>
        </div>
      </div>
      <div className={classes.imgOuterContainer}>
        <div className={classes.imgContainer}>
          <Tooltip
            title={imgName}
            placement="bottom"
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 500 }}
            enterDelay={100}
          >
            <img
              alt={data?.name}
              src={buildImageServerUrl(src, imagePreviewEdits)}
              className={classes.img}
              onClick={toggleLightbox}
              // style={{
              //   aspectRatio: `${data.width} / ${data.height}`,
              // }}
            />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
