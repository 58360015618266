import { Tooltip } from '@material-ui/core'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import FlagIcon from '@material-ui/icons/Flag'
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo'
import WarningIcon from '@material-ui/icons/Warning'
import { motion } from 'framer-motion'
import { MenuItemLink } from 'react-admin'

const styles = {
  sublink: {
    display: 'flex',
    width: 'calc(100% - 0.75rem)',
    margin: '0.06rem 0.5rem',
    padding: '1rem 0.5rem 1rem 2rem',
    borderRadius: '0.5rem',
    height: 64,
  },
  sublinkWithIcon: {
    margin: '0.06rem 0.5rem',
    padding: '1rem 0.5rem',
    background: '#00000008',
    width: 'calc(100% - 0.8rem)',
    borderRadius: '0.5rem',
    height: 64,
  },
}

const NO_FILTER = 'NO_FILTER'

export const transcodingJobMenus: {
  label: string
  filter: undefined | string
}[] = [
  { label: 'All Jobs', filter: NO_FILTER },
  { label: 'Finished Jobs', filter: 'allTranscodingJobsFinished' },
  {
    label: 'Queued Jobs',
    filter: 'allTranscodingJobsInQueue',
  },
  { label: 'Failed Jobs', filter: 'allTranscodingJobsFailed' },
]

export const renderTranscodingJobMenus = () => {
  const isAllJobsPageActive =
    location.href.includes('TranscodingJob') &&
    !location.href.includes('allTranscodingJobsFinished') &&
    !location.href.includes('allTranscodingJobsInQueue') &&
    !location.href.includes('allTranscodingJobsFailed')

  return transcodingJobMenus.map((menu, i) => {
    return (
      <motion.div
        key={menu.label}
        initial={{ x: -150 }}
        animate={{ x: 0 }}
        transition={{ delay: i / 25 }}
      >
        <Tooltip
          key={menu.label}
          placement="right"
          title={
            menu.label
            //   smplTexts.menu[name]?.tooltip ? smplTexts.menu[name].tooltip : ''
          }
        >
          <MenuItemLink
            key={menu.label}
            to={{
              pathname: '/TranscodingJob',
              search:
                menu.filter === NO_FILTER
                  ? 'filter={}' // disable potentially previously set filters in case of navigating from a transcodingjob menu page with a preset filter
                  : `filter=%7B"__preset"%3A%7B"matches"%3A%5B"${menu.filter}"%5D%7D%7D`,
            }}
            leftIcon={
              menu.label === 'All Jobs' ? (
                <AllInboxIcon />
              ) : menu.label === 'Finished Jobs' ? (
                <FlagIcon />
              ) : menu.label === 'Queued Jobs' ? (
                <SlowMotionVideoIcon />
              ) : menu.label === 'Failed Jobs' ? (
                <WarningIcon />
              ) : null
            }
            primaryText={`${menu.label}`}
            style={styles.sublink}
            isActive={() => {
              // Sub menus for the same resource get set as active, despite different keys. This is hacky and error prone work around.
              // TODO: improve logic for setting sub menu for the same resource (but different filters) as active
              return menu.filter !== NO_FILTER
                ? location.href.includes(menu.filter)
                : isAllJobsPageActive
            }}
          />
        </Tooltip>
      </motion.div>
    )
  })
}
