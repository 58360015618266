import { makeStyles } from '@material-ui/core/styles'
import Maybe from 'graphql/tsutils/Maybe'
import { useState } from 'react'
import { TextInput } from 'react-admin'
import { IsJsonString } from '../customFields/ObjectArrayJsonFields'

type JsonInputProps = {
  name: string
  helperText: Maybe<string>
}

const useStyles = makeStyles({
  jsonField: {
    width: '100%',
    justifySelf: 'center',
    maxHeight: '30vh',
    overflow: 'auto',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    '& > p': {
      display: 'none',
    },
    marginTop: '8px',
    marginBottom: '4px',
  },
})

const jsonStringValidation = (value: any) => {
  if (!value) {
    return
  }
  if (value.length === 0) {
    return
  }
  if (!IsJsonString(value)) {
    return 'The field is not in a valid format.'
  }
  return
}

const validateJsonString = [jsonStringValidation]

// also see ObjectArrayJsonFields
export const JsonInput = (props: JsonInputProps) => {
  const { name, helperText, ...rest } = props
  const classes = useStyles()

  // this solves a weird internal issue in react-final-form for formatOnBlur
  // where fieldState = form.getFieldState(state.name) returns undefined for the field,
  // but only on initial render...

  // also solves Field jumping back to the last possible input after the
  const [touched, setTouched] = useState(false)
  return (
    <TextInput
      source={name}
      format={(input: string | null | undefined, ...args: any[]) => {
        if (!input) {
          return
        }
        if (IsJsonString(input)) {
          return JSON.stringify(JSON.parse(input), null, 2)
        }
        return input
      }}
      formatOnBlur={touched}
      parse={(input: string) => {
        // what ever returned here will go to back to format through validation
        if (input.length === 0) {
          return
        }
        return input
      }}
      onFocus={() => {
        setTouched(true)
      }}
      validate={validateJsonString}
      multiline
      className={classes.jsonField}
      helperText={helperText}
      {...rest}
      // @ts-ignore
      defaultValue={touched ? '' : rest.defaultValue}
    />
  )
}
