import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { NetworkStatus } from 'apollo-client'
import { useCallback, useRef } from 'react'
import { Error, Loading, useNotify } from 'react-admin'
import { useQuery } from 'react-apollo-hooks'
import { RouteComponentProps } from 'react-router'
import { Users } from '../../__generated__/Users'
import { NodeActionProps } from '../../genericData/node-actions/NodeActionType'
import { UsersQuery } from '../../graphQL/userCountry'
import { TutorialsCard } from './TutorialsCard'
import { UserPanel } from './UserPanel'

const useStyles = makeStyles(() => ({
  cardTitle: {
    padding: '16px',
    overflow: 'inherit',
    minHeight: '52px',
    marginBottom: '32px',
  },
  cardTitleDescr: {
    margin: 0,
    marginTop: '12px',
    fontSize: '1rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    fontWeight: 400,
    lineHeight: 1.5,
    letterSpacing: '0.00938em',
  },
  util: {
    marginBottom: '10px',
    background: 'none',
    boxShadow: 'none',
    display: 'flex',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 10,
    },
  },

  usersCard: {
    overflow: 'visible',
  },
}))

type Props = RouteComponentProps & NodeActionProps

export const UserCountry = (props: Props) => {
  const classes = useStyles()
  const notify = useNotify()

  // Query Users for users that
  // don't have paymentCountry set && role is user
  // oldest entry first
  const { data, error, refetch, networkStatus } = useQuery<Users>(UsersQuery, {
    variables: {
      role: 'user',
      paymentCountryIsNull: true, // INFO : change this to false for testing
      activeSubscription: true,
      // amount: 10,
    },
    notifyOnNetworkStatusChange: true, // we want to know if there is a refetch
  })

  const handleCopy = useCallback(
    (text: string | null) => {
      if (text === null) return
      var temp = document.createElement('textarea')
      temp.value = text
      temp.setAttribute('readonly', '')
      // @ts-ignore
      temp.style = { position: 'absolute', left: '-999px' }
      document.body.appendChild(temp)
      temp.select()
      document.execCommand('copy')
      document.body.removeChild(temp)
      notify('Added to clip board', 'info')
    },
    [notify]
  )

  const refetchRef = useRef(refetch)
  refetchRef.current = refetch
  // keep reference avoid UserPanel rerenders
  const onSave = useCallback(() => {
    // refetchRef.current()
  }, [])

  if (error) {
    return (
      <Error
        error={{ name: 'Network Error', message: 'Can not query Users.' }}
      />
    )
  }
  if (networkStatus === NetworkStatus.loading) {
    return <Loading />
  }

  const handleClickOpenWirecardPage = () => {
    const url = 'https://sso.wirecard.com/sso'
    var win = window.open(url, '_blank')
    if (win) {
      win.focus()
    }
  }

  if (data && data.allUsers && data.allUsers.nodes.length > 0) {
    // INFO: only user with active subscription
    const usersWithActiveSub = data.allUsers.nodes.filter(
      (user) =>
        // we filter at query for isActive === activeSubscription so it's enough to know that nodes contain something
        user &&
        user.subscriptionsByUserId &&
        user.subscriptionsByUserId.nodes?.length > 0
    )

    if (usersWithActiveSub.length > 0) {
      return (
        <div>
          <Card className={classes.cardTitle}>
            <Typography variant="h5">Set User Country</Typography>
            <p className={classes.cardTitleDescr}>
              Expand row to set the payment country of users.
            </p>
            <p>
              You can temporarily add a country that is not in the list by
              entering the country and clicking "Add" below. Please use{' '}
              <a
                href="https://laendercode.net/en/countries.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                ISO 3166 ALPHA-2 Country Codes
              </a>{' '}
              (e.g. Canada - CA, etc.) instead of country name.
            </p>
          </Card>
          <Card className={classes.util}>
            {/* Open Wirecard Login Page */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickOpenWirecardPage()}
            >
              Open Wirecard
            </Button>
            {/*  Button for refetch */}
            <Button
              variant="contained"
              color="primary"
              onClick={() => refetch()}
              startIcon={
                networkStatus === NetworkStatus.refetch ? (
                  <CircularProgress size={14} color="inherit" />
                ) : null
              }
            >
              Reload
            </Button>
          </Card>
          <TutorialsCard />
          <Card className={classes.usersCard}>
            {usersWithActiveSub.map((user) => (
              <UserPanel
                key={user?.id}
                user={user}
                onSave={onSave}
                onCopy={handleCopy}
              />
            ))}
          </Card>
        </div>
      )
    } else {
      return (
        <Card>
          <p className={classes.cardTitleDescr}>
            All users with an active subscription have a payment country entry.
            To change a user's existing payment country entry, use the Edit
            function under User.
          </p>
        </Card>
      )
    }
  }
}
