import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormGroup from '@material-ui/core/FormGroup'
import { makeStyles } from '@material-ui/core/styles'
import IconClose from '@material-ui/icons/Close'
import DehazeIcon from '@material-ui/icons/Dehaze'
import arrayMove from 'array-move'
import React, { ReactNode, useState } from 'react'
import { FieldTitle } from 'react-admin'
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from 'react-sortable-hoc'

import { camelCaseToText, truncateString } from '../genericData/renderField'
import { TypeDisplaySetting } from '../genericData/settings/TypeDisplaySetting'
import LocalStorage, { DATAGRID_STORAGE_KEY } from './LocalStorage'

const useStyles = makeStyles(() => ({
  itemHandle: {
    opacity: 0.7,
    position: 'relative',
    cursor: 'row-resize',
    marginBottom: '-7px',
    width: '40px',
  },
  containerSort: {
    display: 'inline-grid',
  },
}))

interface SelectionDialogProps {
  //   columns: {
  //     label: string
  //     source: string
  //   }[]
  selection: Record<string, boolean>
  onClose: () => void
  onColumnClicked: (columnName: string) => void
  resource: string
  storage: typeof LocalStorage
  storageKey?: string
  label?: string
  sortable?: boolean
}

export const DragHandle = SortableHandle(() => {
  const classes = useStyles()
  return <DehazeIcon className={classes.itemHandle} />
})

export const SortContainer = SortableContainer<{ children: ReactNode }>(
  ({ children }: any) => {
    const classes = useStyles()
    return <ul className={classes.containerSort}>{children}</ul>
  }
)

export const SelectionDialog = (props: SelectionDialogProps) => {
  const {
    selection,
    onClose,
    onColumnClicked: onColumnClickedFunc,
    resource,
    storage,
    storageKey = DATAGRID_STORAGE_KEY,
    sortable = true,
    label,
  } = props

  const [items, setItems] = useState(getInitSelectionArray())

  function getInitSelectionArray() {
    const selectionArray = Object.keys(selection)
    return selectionArray
  }

  const onColumnClicked = (event: React.ChangeEvent<HTMLInputElement>) => {
    const columnName = event.target.value
    onColumnClickedFunc(columnName)
  }

  function onSortEnd({
    oldIndex,
    newIndex,
  }: {
    oldIndex: number
    newIndex: number
  }) {
    const newItems = arrayMove(items, oldIndex, newIndex)

    // update state
    setItems(newItems)

    // update storage
    const itemsSelection: Record<string, unknown> = {}
    newItems.forEach((item) => {
      itemsSelection[item] = selection[item]
    })
    storage.set(storageKey, resource, itemsSelection)
  }

  return (
    <Dialog
      maxWidth="xs"
      aria-labelledby="ra-selection-dialog-title"
      onClose={onClose}
      open
    >
      <DialogTitle id="ra-selection-dialog-title">
        {label || 'Configuration'}
      </DialogTitle>
      <DialogContent>
        <SortContainer onSortEnd={onSortEnd} helperClass="sortableHelper">
          <FormGroup>
            {items.map((value, index) => {
              return (
                <SortableItem
                  key={value}
                  sortable={sortable}
                  index={index}
                  value={value}
                  selection={selection}
                  resource={resource}
                  onColumnClicked={onColumnClicked}
                />
              )
            })}
          </FormGroup>
        </SortContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          <IconClose />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

type SortableItemProps = {
  value: string
  selection: Record<string, boolean>
  resource: string
  onColumnClicked: (
    event: React.ChangeEvent<HTMLInputElement>,
    changed: boolean
  ) => void
  sortable?: boolean
}

const SortableItem = SortableElement<SortableItemProps>(
  (props: SortableItemProps) => {
    const { value, selection, resource, onColumnClicked, sortable } = props

    const fieldLabel = TypeDisplaySetting[resource]
      ? // custom name stand usually in editAndCreate
        TypeDisplaySetting[resource]?.editAndCreate.fields[value]?.props?.label
        ? truncateString(
            TypeDisplaySetting[resource]?.editAndCreate.fields[value].props
              ?.label!,
            20
          )
        : truncateString(camelCaseToText(value), 20)
      : truncateString(camelCaseToText(value), 20)

    return (
      <div>
        {sortable ? <DragHandle /> : <></>}
        <FormControlLabel
          key={value}
          control={
            <Checkbox
              checked={!!selection[value]}
              onChange={onColumnClicked}
              value={value}
            />
          }
          label={
            <FieldTitle label={fieldLabel} source={value} resource={resource} />
          }
        />
      </div>
    )
  }
)
