import { Button } from '@material-ui/core'
import { ButtonProps } from '@material-ui/core/Button'
import { useCallback } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

type Props = ButtonProps & {
  to: string
}
type InternalProps = Props & RouteComponentProps<any>
function LinkButtonImpl({
  match,
  history,
  location,
  staticContext,

  to,
  onClick,
  ...props
}: InternalProps) {
  const onClickHandler = useCallback(
    (e: any) => {
      history.push(to)
      e.preventDefault()
      if (typeof onClick === 'function') {
        onClick(e)
      }
    },
    [to, onClick, history]
  )
  return <Button href={to} {...props} onClick={onClickHandler} />
}

//@ts-ignore
export const LinkButton = withRouter<InternalProps>(LinkButtonImpl)
