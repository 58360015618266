import { makeStyles } from '@material-ui/core/styles'
import Maybe from 'graphql/tsutils/Maybe'
import { TextInput } from 'react-admin'

type StringArrayJsonFieldsProps = {
  name: string
  helperText: Maybe<string>
}

const useStyles = makeStyles({
  jsonField: {
    width: '100%',
    justifySelf: 'center',
    maxHeight: '30vh',
    overflow: 'auto',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    '& > p': {
      display: 'none',
    },
    marginTop: '8px',
    marginBottom: '4px',
  },
})

const arrayJsonStringValidation = (
  value: { [fieldName in string]: any }[] | string | undefined
) => {
  if (!value) {
    return
  }
  if (typeof value === 'object') {
    return
  }
  if (value.length === 0) {
    return
  }
  if (!IsJsonString(value)) {
    return 'The field is not in a valid format.'
  }
  return
}

const validateArrayJsonString = [arrayJsonStringValidation]

// also see JsonInput
export const ObjectArrayJsonFields = (props: StringArrayJsonFieldsProps) => {
  const { name, helperText } = props
  const classes = useStyles()
  return (
    <TextInput
      source={name}
      format={(
        input: { [fieldName in string]: any }[] | string | null | undefined
      ) => {
        if (!input) {
          return
        }
        if (typeof input === 'string') {
          return input
        }
        let stringifiedValue
        if (Array.isArray(input)) {
          // remove __typename in every array element on Save (returned from apollo)
          const currentValue = input.map(
            (value: object & { __typename?: string }) => {
              return value
            }
          )
          stringifiedValue = JSON.stringify(currentValue, null, 2)
        }
        return stringifiedValue
      }}
      parse={(input: string) => {
        // what ever returned here will go to back to format through validation
        if (input.length === 0) {
          return
        }
        let parsedValue
        try {
          parsedValue = JSON.parse(input)
        } catch (error) {
          // input is not in correct Json format, validate will handle that
          return input
        }
        return parsedValue
      }}
      validate={validateArrayJsonString} // if not passed then user will not be able to save and get an error popup
      className={classes.jsonField}
      multiline
      helperText={helperText}
    />
  )
}

export function IsJsonString(str: string) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}
