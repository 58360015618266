import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/core/styles'
import React from 'react'
import { lightTheme } from '../layout/themes'

export interface ConfirmationOptions {
  catchOnCancel?: boolean
  title?: string
  description?: string
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean
  onSubmit: () => void
  onClose: () => void
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  description,
  onSubmit,
  onClose,
}: ConfirmationDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{description}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <ThemeProvider theme={lightTheme}>
          <Button color="primary" variant="contained" onClick={onClose}>
            CANCEL
          </Button>
          <Button color="primary" variant="contained" onClick={onSubmit}>
            CONFIRM
          </Button>
        </ThemeProvider>
      </DialogActions>
    </Dialog>
  )
}
