import gql from 'graphql-tag'

export const CheckTablePermission = gql`
  query CheckTablePermission($ressourceNames: [String!]!) {
    checkRessourcePermissions(input: { resourceNames: $ressourceNames }) {
      name
      select
      update
      insert
      delete
    }
  }
`
