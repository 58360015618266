import { getToken } from '../../../../authProvider'

export function createSimpleClient(
  baseURL: string,
  defaultSettings: RequestInit = {}
) {
  const basePath = baseURL.replace(/\/$/, '') // remove trailing slash
  return async function makeRequest(
    method: 'GET' | 'POST' | 'HEAD' | 'OPTIONS',
    endpoint: string,
    body?: Object,
    options?: RequestInit
  ) {
    const URL = basePath + (endpoint.startsWith('/') ? '' : '/') + endpoint
    const settings = {
      ...defaultSettings,
      ...options,
    }
    const fetchSettings = {
      ...settings,
      method,
      ...(body
        ? {
            body: JSON.stringify(body),
            headers: {
              'Content-Type': 'application/json',
              ...(settings.headers || null),
            },
          }
        : null),
    }

    const res = await fetch(URL.replace(/\/$/, ''), fetchSettings)
    if (res.ok && res.status !== 204) {
      try {
        const jsonData = await res.json()
        return jsonData
      } catch (error) {
        console.log('success API call, returns no body', error)
        return res
      }
    } else if (res.status >= 400) {
      console.error('error while loading data', res.status, res.statusText, res)
      throw res
    }

    return res
  }
}

export function createSimpleAuthenticatedClient(
  baseURL: string,
  defaultSettings: RequestInit = {}
) {
  return createSimpleClient(baseURL, {
    ...defaultSettings,
    headers: {
      ...(defaultSettings.headers || null),
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
