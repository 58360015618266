import gql from 'graphql-tag'
import lowerFirst from 'lodash/lowerFirst'
import pluralize from 'pluralize'
import { Resource } from './ReactAdmin-Types'
import { nameFromResource } from './SchemaTraverser'
import { getFragmentForType } from './getFragmentForType'

export type GetOneParams = {
  [key: string]: any
  id: string
}

function functionGetListParamsToVariables(params: GetOneParams) {
  console.log('getOne params', params)
  const { id, ...otherParams } = params
  return {
    id,
    ...otherParams,
  }
}

const getOneQuery = function getOneQuery(
  queryName: string,
  resource: Resource
) {
  const {
    fragment: AllTypesFragment,
    fragmentName: AllTypesFragmentName,
  } = getFragmentForType(resource.type)
  const query = gql
  return query`
          ${AllTypesFragment}
  
          query ${queryName}($id: Guid!) {
              data: ${queryName}(id: $id) {
                ...${AllTypesFragmentName}
              }
          }
      `
}

export function getOneQueryName(resource: Resource) {
  return `${lowerFirst(pluralize.singular(nameFromResource(resource)))}ById`
}

export function graphQlQueryDescriptor(
  queryName: string,
  resource: Resource,
  params: GetOneParams
) {
  return {
    query: getOneQuery(queryName, resource),
    variables: functionGetListParamsToVariables(params), // params = { id: ... }
    parseResponse: (response: Object) => {
      // @ts-ignore
      if (!response || !response.data || !response.data.data) {
        console.error('graphql could not find any data!', response)
        throw new Error(
          `Could not find ${resource.type.name} with id "${params.id}"`
        )
      }
      const {
        // @ts-ignore
        data: { data },
      } = response
      return {
        data,
      }
    },
  }
}
