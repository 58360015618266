import { IntrospectionNamedTypeRef, IntrospectionObjectType } from 'graphql'
import groupBy from 'lodash/groupBy'
import { filterNotEmpty } from '../../lib/filterNonEmpty'
import { plainFields } from './utils'

const byIdSuffixRegex = /Id$/

type RelationshipInfo = {
  fieldName: string
  relatedFieldName: string
  typeName: string
}

export function getRelationshipFieldsForType(
  type: IntrospectionObjectType
): RelationshipInfo[] {
  const { fields } = type
  const fieldsByName = groupBy(fields, 'name') // is array

  const possibleRelations = plainFields(fields).filter(({ name }) =>
    byIdSuffixRegex.test(name)
  )
  const relationshipFields = possibleRelations
    .map(function hasRelationField(field) {
      const nameWithoutId = field.name.replace(byIdSuffixRegex, '')
      const relationFields = fieldsByName[nameWithoutId]
      if (!relationFields) {
        return null
      }
      const typeNameOfRelation = relationFields
        .filter((relField) => {
          return relField.type.kind === 'OBJECT'
        })
        .map((relField) => {
          const { type } = relField
          return (type as IntrospectionNamedTypeRef<IntrospectionObjectType>)
            .name
        })[0]
      if (typeNameOfRelation) {
        return {
          fieldName: field.name,
          relatedFieldName: nameWithoutId,
          typeName: typeNameOfRelation,
        }
      } else {
        return null
      }
    })
    .filter(filterNotEmpty)
  return relationshipFields
}
