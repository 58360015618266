import gql from 'graphql-tag'

export const EpisodeMutation = gql`
  mutation UpdateEpisode($id: Guid!, $patch: CmsEpisodePatch!) {
    updateCmsEpisodeById(input: { id: $id, cmsEpisodePatch: $patch }) {
      cmsEpisode {
        id
      }
    }
  }
`
