import { Button, makeStyles, Tooltip } from '@material-ui/core'
import { GET_LIST, SortPayload } from 'ra-core'
import { useCallback } from 'react'
import { ListProps, useGetIdentity } from 'react-admin'
import { getToken } from '../authProvider'
import { getDataResolver } from '../dataProvider/buildQuery'
import { GetListParams } from '../dataProvider/introspections/getList'
import { useSchemaTraverser } from '../hooks/useSchemaTraverser'
import { BLACKLIST_TABLE_EXPORT_BY_ROLE } from '../lib/config'
import { smplTexts } from './CustomTextMappings'

const useStyles = makeStyles(() => ({
  actionButton: {
    padding: '0.5rem 1rem',
    background: '#cdcdcd',
  },
}))

const iframeId = 'exportDownloadIframe' + Math.random()

function getQuerySource(query: any) {
  // result from graphql-tag does not have a type...
  return query.loc.source.body
}

function downloadCSV(resource: string, queryString: string) {
  // @ts-ignore
  const iframe: HTMLIFrameElement =
    document.getElementById(iframeId) ||
    document.documentElement.appendChild(document.createElement('iframe'))
  iframe.id = iframeId
  iframe.style.display = 'none'
  iframe.src =
    process.env.PUBLIC_URL +
    `/api/export/${resource}?data=${queryString}&token=${getToken()}`
}

type ExportButtonType = ListProps & {
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  color?: 'default' | 'inherit' | 'primary' | 'secondary'
  startIcon?: JSX.Element
  buttonText?: string
  filterValues?: { [key: string]: any }
  currentSort?: SortPayload
}

export function ExportButton(props: ExportButtonType) {
  const {
    filterValues,
    currentSort,
    resource,
    size,
    variant,
    color,
    buttonText,
    startIcon,
  } = props

  const classes = useStyles()

  const tableWithExportBlackList: {
    tableName: string
    blackListedRole: string[]
  }[] = JSON.parse(BLACKLIST_TABLE_EXPORT_BY_ROLE)

  const isTableInExportBlacklist = tableWithExportBlackList.find(
    (bl) => bl.tableName === resource
  )

  const { identity, loaded: loadedIdentity } = useGetIdentity()

  const schemaTraverser = useSchemaTraverser()
  if (!schemaTraverser) {
    throw new Error('no schema traverser')
  }
  if (!resource) {
    throw new Error('resource unknown')
  }
  const startExport = useCallback(async () => {
    const params: GetListParams = {
      sort: currentSort ? currentSort : null,
      pagination: null,
      filter: filterValues,
    }

    const resolver = getDataResolver()
    const res = resolver(GET_LIST, resource, params)
    if (!res) {
      throw new Error('invalid request type, not allowed by data resolver')
    }

    const query = getQuerySource(res.query)
    const { variables } = res

    const queryString = JSON.stringify({
      query: query.replace(/ +/g, ' ').trim(), // remove unnecessary whitespace
      variables,
      authorization: getToken(),
    })

    // send with get request, variables need to be encoded to prevent errors on backend
    downloadCSV(resource, encodeURIComponent(queryString))
  }, [filterValues, currentSort, resource])

  if (!loadedIdentity && !!isTableInExportBlacklist) {
    return null
  }

  if (
    isTableInExportBlacklist &&
    isTableInExportBlacklist.blackListedRole.includes(identity.role)
  ) {
    return null
  }

  return (
    <Tooltip title={smplTexts.datagrid.export}>
      <Button
        size={size ? size : 'medium'}
        color={color ? color : 'default'}
        variant={variant ? variant : 'text'}
        startIcon={startIcon ? startIcon : null}
        onClick={startExport}
        className={classes.actionButton}
      >
        {buttonText ? buttonText : 'Export'}
      </Button>
    </Tooltip>
  )
}
