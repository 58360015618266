import {
  IntrospectionField,
  IntrospectionObjectType,
  IntrospectionType,
} from 'graphql'
import gql from 'graphql-tag'
import { getSchemaTraverser } from '../buildQuery'
import {
  nonRelationalOrFunctionalListObjectFields,
  nonRelationalOrFunctionalObjectFields,
  plainFields,
} from './utils'

export const getFragmentForType = function (type: IntrospectionType) {
  if (!type || !type.name || !('fields' in type)) {
    throw new Error(
      'getFragmentForType failed because type is invalid or does not have fields'
    )
  }

  const traverser = getSchemaTraverser()
  if (!traverser) {
    throw new Error('no SchemaTraverser')
  }
  const objectType = traverser.objects

  const fragmentName = `AllFieldsOn${type.name}`
  const fragment = gql`
        fragment ${fragmentName} on ${type.name} {
          ${plainFields((type as IntrospectionObjectType).fields)
            .map(({ name }: IntrospectionField) => {
              return name
            })
            .join(', ')}
          
          ${nonRelationalOrFunctionalObjectFields(
            (type as IntrospectionObjectType).fields
          ).map(({ name, type }: IntrospectionField) => {
            // @ts-ignore
            const typeName = type.name
            const allFieldsOnTypeName = objectType
              .filter((o) => {
                return o.name === typeName
              })
              .map((o) => {
                // @ts-ignore
                return o.fields.map(({ name }) => name)
              })
            return `, 
                ${name} {
                  ${allFieldsOnTypeName}
                }
                `
          })}

          ${nonRelationalOrFunctionalListObjectFields(
            (type as IntrospectionObjectType).fields
          ).map(({ name, type }: IntrospectionField) => {
            // support for non nullable list nested in NON_NULL
            if (type.kind === 'NON_NULL') {
              type = type.ofType
            }

            // @ts-ignore
            const typeName = type.ofType.name

            const allFieldsOnTypeName = objectType
              .filter((o) => {
                return o.name === typeName
              })
              .map((o) => {
                // @ts-ignore
                return o.fields
                  .map((field: any) => {
                    const {
                      name,
                      type: { kind },
                    } = field

                    // we only want SCALAR or LIST but not OBJECT, which is prob. a relation to another resource
                    if (name && (kind === 'SCALAR' || kind === 'LIST')) {
                      return name
                    }

                    return null
                  })
                  .filter((name: string | null) => name !== null)
              })

            if (allFieldsOnTypeName.length > 0) {
              return `, 
                ${name} {
                  ${allFieldsOnTypeName}
                }
                `
            }
            return ''
          })}
        }
      `

  return {
    fragment,
    fragmentName,
  }
}
