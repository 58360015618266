import { Record, RedirectionSideEffect } from 'ra-core'
import { DeleteWithUndoButton } from 'ra-ui-materialui'
import { ButtonProps } from 'ra-ui-materialui/esm/button/Button'
import { FC, ReactElement, SyntheticEvent } from 'react'
import { CustomDeleteWithConfirmButton } from './CustomDeleteWithConfirmButton'

export const CustomDeleteButton: FC<CustomDeleteButtonProps> = ({
  undoable,
  ...props
}) =>
  undoable ? (
    <DeleteWithUndoButton {...props} />
  ) : (
    <CustomDeleteWithConfirmButton {...props} />
  )

interface Props {
  basePath?: string
  classes?: object
  className?: string
  btnClassName?: string
  confirmClasses?: string
  icon?: ReactElement
  label?: string
  onClick?: (e: MouseEvent) => void
  record: Record
  redirect?: RedirectionSideEffect
  resource?: string
  // May be injected by Toolbar
  handleSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => Promise<Object>
  handleSubmitWithRedirect?: (redirect?: RedirectionSideEffect) => void
  invalid?: boolean
  pristine?: boolean
  saving?: boolean
  submitOnEnter?: boolean
  undoable?: boolean
  confirmTitle?: string
  confirmContent: string
}

export type CustomDeleteButtonProps = Props & ButtonProps

CustomDeleteButton.defaultProps = {
  undoable: true,
}
