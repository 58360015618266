import { Button } from '@material-ui/core'
import { showNotification as showNotificationAction } from 'ra-core'
import { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useEndpointAvailable } from '../../../hooks/useEndpointAvailable'
import { SMPL_CUSTOMER, SMPL_USER_AUTH_API_URL } from '../../../lib/config'
import { NodeActionProps } from '../NodeActionType'
import { createSimpleAuthenticatedClient } from './lib/simpleClient'
import { resetPassword } from './lib/userService'

function ResetPassword({
  record: user,
  showNotification,
}: NodeActionProps & { showNotification: typeof showNotificationAction }) {
  const [sending, setSending] = useState(false)

  const authEndpointAPI: string = SMPL_USER_AUTH_API_URL
  const endpoint = '/auth/forgot-password'
  const isEndpointAvailable = useEndpointAvailable(
    createSimpleAuthenticatedClient(authEndpointAPI),
    endpoint
  )

  const clickHandler = useCallback(
    async function resetPasswordFunc(customer: string = 'default') {
      if (sending) return
      setSending(true)
      const cleanUp = () => setSending(false)
      if (!user) {
        throw new Error('cannot add subscription for undefined user')
      }
      const { username } = user
      if (typeof username !== 'string') {
        throw new Error('invalid user object! Id is missing!')
      }
      let authUrl = authEndpointAPI
      try {
        switch (customer) {
          case 'ft':
            authUrl = SMPL_USER_AUTH_API_URL.toLowerCase().replace(
              'homeofhorror',
              'filmtastic'
            )
            break
          case 'acnma':
            authUrl = SMPL_USER_AUTH_API_URL.toLowerCase().replace(
              'homeofhorror',
              'arthousecnma'
            )
            break
          default:
            break
        }

        await resetPassword({
          username,
          customer,
          authServiceClient: createSimpleAuthenticatedClient(authUrl),
          endpoint,
        })

        showNotification(
          'Password reset request sent, the user should receive an email'
        )
      } catch (e) {
        console.error('could not reset password', e)
        showNotification('Could not reset password!', 'warning')
      }
      cleanUp()
    },
    [user, sending, showNotification]
  )

  if (!isEndpointAvailable) {
    return null
  }

  if (SMPL_CUSTOMER.toLocaleLowerCase().includes('universum')) {
    return (
      <>
        <Button
          disabled={sending}
          onClick={() => clickHandler('hoh')}
          variant="outlined"
          size="small"
        >
          {sending ? 'Sending...' : 'Reset Password (HoH)'}
        </Button>
        <Button
          disabled={sending}
          onClick={() => clickHandler('ft')}
          variant="outlined"
          size="small"
        >
          {sending ? 'Sending...' : 'Reset Password (FT)'}
        </Button>
        <Button
          disabled={sending}
          onClick={() => clickHandler('acnma')}
          variant="outlined"
          size="small"
        >
          {sending ? 'Sending...' : 'Reset Password (ACNMA)'}
        </Button>
      </>
    )
  } else {
    return (
      <Button
        disabled={sending}
        onClick={() => clickHandler()}
        variant="outlined"
        size="small"
      >
        {sending ? 'Sending...' : 'Reset Password'}
      </Button>
    )
  }
}
ResetPassword.label = 'ResetPassword'

export default connect(null, {
  showNotification: showNotificationAction,
})(ResetPassword)
