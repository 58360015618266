import {
  Card,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core'
import { upperFirst } from 'lodash'
import { useState } from 'react'
import { useQuery } from 'react-apollo-hooks'
import {
  GetCampaignStats,
  GetCampaignStatsVariables,
} from '../../__generated__/flexgold/GetCampaignStats'
import { getCampaignStats } from '../../graphQL/flexgold/campaign'
import { DialogSelect } from './dialogSelect'

const useStyles = makeStyles({
  root: {
    padding: '2rem 2rem 2rem 2rem',
    borderRadius: 25,
  },
  tableContainer: { maxHeight: '65vh' },
})

const columnLabels: Record<keyof GetCampaignStatsVariables, string> = {
  register: 'Register',
  login: 'Login',
  masterDataStarted: 'Master Data Started',
  masterDataPersonalDetailsAd: 'Master Data Personal Details Ad',
  addedMasterData: 'Added Master Data',
  createdBankAccount: 'Created Bank Account',
  kycRedirectedToIdNow: 'Kyc Redirected To Id Now',
  kycPart1Finished: 'Kyc Part1 Finished',
  kycRedirectedToChat: 'Kyc Redirected To Chat',
  kycFinished: 'Kyc Finished',
  beginVaultCreation: 'Begin Vault Creation',
  vaultTypeSelected: 'Vault Type Selected',
  vaultCreated: 'Vault Created',
  prepaidProcessStarted: 'Prepaid Process Started',
  vaultDetailsCopied: 'Vault Details Copied',
  transferIbanCopied: 'Transfer Iban Copied',
  instantOrderStarted: 'Instant Order Started',
  instantOrderChoseCurrency: 'Instant Order Chose Currency',
  instantOrderChoseValue: 'Instant Order Chose Value',
  instantOrderSent: 'Instant Order Sent',
  instantOrderExecuted: 'Instant Order Executed',
  futureOrderStarted: 'Future Order Started',
  futureOrderChoseCurrency: 'Future Order Chose Currency',
  futureOrderChoseDate: 'Future Order Chose Date',
  futureOrderChoseValue: 'Future Order Chose Value',
  futureOrderChoseLimit: 'Future Order Chose Limit',
  futureOrderSent: 'Future Order Sent',
  futureOrderExecuted: 'Future Order Executed',
  repeatOrderStarted: 'Repeat Order Started',
  repeatOrderChoseCurrency: 'Repeat Order Chose Currency',
  repeatOrderChoseDate: 'Repeat Order Chose Date',
  repeatOrderPlanChoseValue: 'Repeat Order Plan Chose Value',
  repeatOrderPlanChoseLimit: 'Repeat Order Plan Chose Limit',
  repeatOrderPlanSent: 'Repeat Order Plan Sent',
  repeatOrderExecuted: 'Repeat Order Executed',
  masterDataPersonalDetailsAdded: 'Master Data Personal Details Added',
  revenue: 'Revenue',
  cost: 'Cost',
} as const
const columnOptions = Object.keys(columnLabels)

const defaultColumns = {
  register: true,
  login: true,
} as const

export const CampaignStatistic = () => {
  const classes = useStyles()

  const [shownColumns, setShownColumns] = useState<string[]>(
    Object.keys(defaultColumns)
  )
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { loading, data, error, errors, refetch } = useQuery<
    GetCampaignStats,
    GetCampaignStatsVariables
  >(getCampaignStats, {
    variables: defaultColumns,
  })

  const handleOk = (selection: string[]) => {
    setIsDialogOpen(false)
    setShownColumns(selection)
    refetch(selection.reduce((acc, curr) => ((acc[curr] = true), acc), {}))
  }

  return (
    <Card className={classes.root}>
      <DialogSelect
        isOpen={isDialogOpen}
        options={columnOptions}
        labels={columnLabels}
        currentSelection={shownColumns}
        handleClose={() => setIsDialogOpen(false)}
        handleOpen={() => setIsDialogOpen(true)}
        handleOk={handleOk}
      />
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader>
          <TableHead>
            <TableCell>Label</TableCell>
            {shownColumns.map((col) => (
              <TableCell key={col}>{columnLabels[col]}</TableCell>
            ))}
          </TableHead>
          <TableBody>
            {loading ? (
              <TableCell>Fetching campaign statistic</TableCell>
            ) : error || errors ? (
              <TableCell>
                An error occured when fetching the campaign statistic.
              </TableCell>
            ) : data ? (
              data.allCampaignAnalyses?.nodes
                .filter(
                  (data) =>
                    data.channel?.trim() !== '' &&
                    data.campaign?.trim() !== '' &&
                    data.adGroup?.trim() !== '' &&
                    data.ad?.trim() !== ''
                )
                .map((data) => (
                  <TableRow
                    hover
                    key={`${data.channel}${data.campaign}${data.adGroup}${data.ad}`}
                  >
                    <TableCell>{getRowLabel(data)}</TableCell>
                    {shownColumns.map((col) => (
                      <TableCell key={col}>{data[col]}</TableCell>
                    ))}
                  </TableRow>
                ))
            ) : null}
          </TableBody>
        </Table>
      </TableContainer>
    </Card>
  )
}

function getRowLabel(
  labels: Record<'channel' | 'campaign' | 'adGroup' | 'ad', string | null>
): string {
  if (labels.ad != null) {
    return `___ ${upperFirst(labels.ad)}`
  }
  if (labels.adGroup != null) {
    return `__ ${upperFirst(labels.adGroup)}`
  }
  if (labels.campaign != null) {
    return `_ ${upperFirst(labels.campaign)}`
  }
  if (labels.channel != null) {
    return upperFirst(labels.channel)
  }
  return 'TOTAL'
}

export default CampaignStatistic
