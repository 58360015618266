export const animTransition = {
  type: 'spring',
  damping: 20,
  stiffness: 100,
  mass: 2,
}
export const animInitial = {
  opacity: 0,
  y: 100,
}
export const animTarget = {
  opacity: 1,
  y: 0,
}
export const animInitialScale = {
  opacity: 0,
  scale: 0,
}
export const animTargetScale = {
  opacity: 1,
  scale: 1,
}
export const animDelay = 0.15
