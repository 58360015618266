import { getToken } from '../authProvider'

/**
 * @deprecated - use createSimpleAuthenticatedClient() instead
 *
 * Makes a request that includes the session id as Authorization header.
 * If not specifically setup `credentials: 'omit'` will be set!
 * This ignores all cookies and fixes a bug where safari sends the Basic auth header
 * if the frontend (or any service with the same root domain) used it
 *
 * This also helps with API requests working "accidentally"
 * because you are logged in in the frontend (as an admin) and have a cookie set
 *
 * @param url
 * @param requestSettings fetch setting overrides
 * @returns
 */
export function authedFetch(url: string, requestSettings: RequestInit) {
  const token = getToken()
  if (!token) {
    throw new Error('Can not start authenticated request without auth token')
  }
  return fetch(url, {
    credentials: 'omit', // this is trying to solve that frontend cookies might break the dashboard
    ...(requestSettings || {}),
    headers: {
      ...(requestSettings?.headers || null),
      Authorization: `Bearer ${getToken()}`,
    },
  })
}
