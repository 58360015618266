import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { isKixiCustomer, isNetzkinoCustomer } from '../../lib/config'

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textBox: {
    minWidth: '260px',
  },
}))

export const ObjectFields = (props: any) => {
  const { record } = props
  const objectData =
    record[props.targetField ? props.targetField : props.source]
  const classes = useStyles()

  if (!objectData) {
    return null
  }

  return (
    <span className={classes.root}>
      {Object.keys(objectData).map((source) => {
        let name = source
        if (isNetzkinoCustomer || isKixiCustomer) {
          if (source === 'pmdUrl') {
            name = 'Film-Url'
          } else if (source === '__typename') {
            return null
          }
        } else {
          if (source === 'pmdUrl') {
            return null
          }
        }
        return (
          <div key={name}>
            <TextField
              id={name}
              label={name}
              defaultValue={objectData[source]}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
              }}
              className={classes.textBox}
            />
          </div>
        )
      })}
    </span>
  )
}
