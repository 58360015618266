import { Popper, PopperProps } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { Autocomplete } from '@material-ui/lab'
import arrayMutators from 'final-form-arrays'
import inflection from 'inflection'
import React, { useCallback, useLayoutEffect, useMemo, useState } from 'react'
import { Form } from 'react-final-form'
import {
  FieldFilterInfo,
  FilterableManyToManyFieldDescriptor,
} from '../dataProvider/introspections/SchemaTraverser'
import {
  ManyToManyOverrides,
  TypeDisplaySetting,
} from '../genericData/settings/TypeDisplaySetting'
import {
  FilterInputField,
  renderFilterFieldIfPossible,
} from './customFields/FilterInputField'
import { getRandomKey } from './filterBuilder/filterPresetDefinitions'

const useStyles = makeStyles(() => ({
  formControl: {
    margin: 2,
    minWidth: 150,
    float: 'left',
  },
  formInput: {
    margin: 2,
    minWidth: 150,
    float: 'left',
    '& > div': {
      marginTop: 0,
      marginBottom: -12,
    },
  },
  deleteIcon: {
    padding: 5,
    marginTop: 15,
  },
}))

export type Filter = {
  /** cmsMovieContentCategoriesByContentMovieId -> category.slug */
  alias?: string
  /** column from data, can also be a nested field if the manyToMany fields are set */
  fieldName: string
  /** is the oneToManyParent?.alias, e.g. categoryConnection for the cmsMovieContentCategoriesByContentMovieId field  */
  oneToManyAlias?: string
  /** the name of the field in the parent GraphQL Filter Object, e.g. cmsMovieContentCategoriesByContentMovieId */
  oneToManyField?: string
  /** i.e. startWith, equalTo, includes, ... */
  filterOp: string
  value: any // what the user wants to search for
  /** a JSON text that has $$ where a replacement should be made based on filterOp */
  template: string | null
  shouldRender: boolean
}

export type FilterState = Partial<Filter>
export function fieldFilterToFilterState(
  selected: FieldFilterInfo | null
): Omit<FilterState, 'value' | 'filterOp'> {
  let newFieldName: string | undefined = selected?.name
  return {
    alias: selected?.alias,
    fieldName: newFieldName,
    oneToManyAlias: selected?.oneToManyParent?.alias,
    oneToManyField: selected?.oneToManyParent?.parentField.name,
    template: selected?.template ?? null,
  }
}

type CustomFilterFormProps = {
  resource: string
  onChange: (filter: FilterState) => void
  filter: FilterState
  filterableFields: FieldFilterInfo[]
  // TODO: use oneToManyFields and the non-existant manyToManyFields
  relatedFilterableFields: FilterableManyToManyFieldDescriptor[]
  additionalFilters?: ManyToManyOverrides[]
  onRemove: () => void
}

const SizeAdjustingPopper = function (props: PopperProps) {
  return (
    <Popper
      {...props}
      style={{
        ...(props.style ?? null),
        width: 'fit-content',
        minWidth: 250,
      }}
      placement="bottom-start"
    />
  )
}

export const CustomFilterForm = React.memo((props: CustomFilterFormProps) => {
  const {
    filter,
    filterableFields,
    relatedFilterableFields,
    onChange,
    onRemove,
    resource,
  } = props

  const getOptionLabel = useCallback(function getOptionLabel(
    option: FieldFilterInfo
  ) {
    // same as the GenericDataList. Also respect edit page labels

    const resourceSettings = TypeDisplaySetting[resource]

    const fieldSettingsEditAndCreate: { label?: string } | undefined =
      resourceSettings?.editAndCreate?.fields[option.field.name]?.props

    const fieldSettingsList: { label?: string } | undefined =
      resourceSettings?.list?.fields[option.field.name]?.props

    return (
      fieldSettingsList?.label ||
      fieldSettingsEditAndCreate?.label ||
      inflection.transform(option.alias || option.name, [
        'underscore',
        'humanize',
        'titleize',
      ])
    )
  },
  [])

  const cleanedFilterableFields = useMemo(() => {
    const sortByGroupAndField = (a: FieldFilterInfo, b: FieldFilterInfo) => {
      // keep groups separated
      if (a.oneToManyParent?.parentField !== b.oneToManyParent?.parentField) {
        return (a.oneToManyParent?.alias || '').localeCompare(
          b.oneToManyParent?.alias || ''
        )
      }
      // sort within the same group
      const labelCompare = (a.fullAlias || getOptionLabel(a)).localeCompare(
        b.fullAlias || getOptionLabel(b)
      )
      return labelCompare
    }

    const relatedFields = (relatedFilterableFields ?? [])
      .map((oneToManyDescriptor) => {
        return oneToManyDescriptor.filterableSubfields.map((subField) => {
          const fieldFilterInfo: FieldFilterInfo = {
            ...subField,
            alias: oneToManyDescriptor.alias + '.' + subField.name,
            name: subField.name,
            template: oneToManyDescriptor.template,
          }
          return fieldFilterInfo
        })
      })
      .flat(1)
      .sort(sortByGroupAndField)

    // find all filters that have at least one possible operation
    const list = [...filterableFields]
    list.sort(sortByGroupAndField)
    list.push(...relatedFields)
    return list
      .map((f) => {
        return {
          ...f,
          operations: f.operations.filter((op) => {
            // do a fake render to see if the filter can render an input
            const rendered = renderFilterFieldIfPossible({
              currentFilter: filter,
              filter: f,
              filterOpName: op.operationName,
              onChange() {},
              // @ts-ignore
              onDebouncedChange() {},
              translate() {
                return ''
              },
            })
            if (!rendered) {
              console.warn(
                'ignoring operation "%s" for field "%s"',
                op.operationName,
                f.name
              )
              return false
            }
            return true
          }),
        }
      })
      .filter((f) => {
        if (f.operations.length > 0) {
          return true
        } else {
          console.warn(
            'ignoring field "%s" as it has no possible filter operations',
            f.name
          )
        }
      })
  }, [filterableFields, relatedFilterableFields])

  const [selectId] = useState(() => {
    // never changing, only here to generate accessibility ids that are unique on the page
    return getRandomKey()
  })
  const classes = useStyles()
  const selectedFieldName = filter?.alias || filter?.fieldName

  const filterInfoForSelectedField = useMemo(() => {
    return cleanedFilterableFields.find(
      (pFilter) =>
        (pFilter.fullAlias ?? pFilter.alias ?? pFilter.name) ===
        (filter.alias || filter.fieldName)
    )
  }, [cleanedFilterableFields, filter])
  const possibleFilterOperationNames = filterInfoForSelectedField?.operations.map(
    (op) => op.operationName
  )

  const selectedFilterOp =
    filter.filterOp ||
    (possibleFilterOperationNames?.length === 1
      ? possibleFilterOperationNames[0]
      : '')

  const selectedFieldFilterInfo =
    cleanedFilterableFields.find((f) => f.name === selectedFieldName) || null
  const [inputText, setInputText] = useState(selectedFieldName)
  useLayoutEffect(() => {
    if (selectedFieldName) {
      // in case the user deletes a form row, it would keep the old state
      setInputText(selectedFieldName)
    }
  }, [selectedFieldName])

  if (cleanedFilterableFields.length === 0) {
    console.error('nothing to show', cleanedFilterableFields)
    return null
  }
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <FormControl className={classes.formControl}>
        {/* <InputLabel htmlFor={'filter-type' + selectId}>Field Name</InputLabel> */}
        <Autocomplete<FieldFilterInfo>
          id={'filter-type' + selectId}
          PopperComponent={SizeAdjustingPopper}
          value={selectedFieldFilterInfo}
          onChange={(ev, selected) => {
            // @ts-ignore
            const s = fieldFilterToFilterState(selected)

            onChange({
              ...s,
              filterOp: undefined,
              value: undefined,
            })
            // @ts-ignore
            setInputText(selected?.alias || s.fieldName!)
          }}
          openOnFocus
          options={cleanedFilterableFields}
          groupBy={(opt) => {
            return (
              opt.alias?.split('.')[0] ??
              opt.oneToManyParent?.alias ??
              `Fields within ${resource}`
            )
          }}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                // variant="filled"
                size="small"
                label="Field Name"
                style={{ minWidth: 220 }}
                InputProps={{
                  ...params.InputProps,
                  style: {
                    minHeight: 32, // a regular text box/Menu has this height
                  },
                }}
                inputProps={{
                  ...params.inputProps,
                  onChange: (ev) => {
                    setInputText(ev.currentTarget.value)

                    // @ts-ignore
                    params.inputProps?.onChange(ev)
                  },
                  value: inputText || '',
                }}
              />
            )
          }}
          filterOptions={(options, state) => {
            return options.filter((f) =>
              (f.alias || f.name)
                .toLowerCase()
                .includes(state.inputValue.toLowerCase())
            )
          }}
          getOptionLabel={(option, ...args) => {
            // return option.name
            const splitted = option.name.split('.')
            if (splitted.length === 1) {
              return splitted[0]
            }
            return splitted[splitted.length - 1]
          }}
          // getOptionLabel={getOptionLabel}
          // autoFocus
        />
        {/* <Select
          id={'filter-type' + selectId}
          value={selectedFieldName || ''}
          onChange={(ev) => {
            const newFieldName = ev.target.value as string
            onChange({
              fieldName: newFieldName,
              filterOp: undefined,
              value: undefined,
            })
          }}
          autoFocus
        >
          {cleanedFilterableFields.map((pFilter) => (
            <MenuItem key={pFilter.name} value={pFilter.name}>
              {pFilter.name}
            </MenuItem>
          ))}
        </Select> */}
      </FormControl>

      <FormControl className={classes.formControl}>
        <InputLabel htmlFor={'filter-op' + selectId}>
          Filter Operation
        </InputLabel>
        <Select
          id={'filter-op' + selectId}
          value={
            possibleFilterOperationNames?.includes(selectedFilterOp)
              ? selectedFilterOp
              : ''
          }
          onChange={(ev) => {
            const newFilterOperation = ev.target.value as string
            onChange({
              ...filter,
              filterOp: newFilterOperation,
              value: undefined, // TODO: template here,
            })
          }}
          autoFocus
          disabled={selectedFieldName === undefined}
        >
          {selectedFieldName &&
            filterInfoForSelectedField?.operations?.map((filterOp) => {
              return (
                <MenuItem key={filterOp.label} value={filterOp.operationName}>
                  {filterOp.label}
                </MenuItem>
              )
            })}
        </Select>
      </FormControl>

      {/* Placeholder Textinput */}
      {(selectedFilterOp.length === 0 ||
        !possibleFilterOperationNames?.includes(selectedFilterOp)) && (
        <FormControl className={classes.formControl} disabled={true}>
          <TextField
            id={'placeholder-filter' + selectId}
            label="Filter Input"
            disabled={true}
          />
        </FormControl>
      )}

      {filter !== undefined &&
        selectedFilterOp.length > 0 &&
        filterInfoForSelectedField !== undefined &&
        possibleFilterOperationNames?.includes(selectedFilterOp) && (
          <FormControl className={classes.formInput}>
            <Form
              onSubmit={handleFinalFormSubmit}
              mutators={{ ...arrayMutators }}
              render={(formProps) => {
                return (
                  <>
                    <FilterInputField
                      // INFO: line below is quick fix and would remove Input on operation changes
                      key={selectedFilterOp}
                      filter={filterInfoForSelectedField}
                      filterOpName={selectedFilterOp || ''}
                      onChange={(updated) => {
                        onChange({
                          ...filter,
                          ...updated,
                          filterOp: filter.filterOp ?? selectedFilterOp, // handle default selection of the first item
                        })
                      }}
                      variant="standard"
                      currentFilter={filter}
                    />
                  </>
                )
              }}
            />
          </FormControl>
        )}

      <FormControl>
        <Tooltip title="Remove Filter" placement="top">
          <IconButton
            aria-label="Remove Filter"
            className={classes.deleteIcon}
            onClick={() => {
              onRemove()
            }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Tooltip>
        {/* )} */}
      </FormControl>
    </div>
  )
})

export const handleFinalFormSubmit = () => {}
