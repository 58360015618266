import { Button } from '@material-ui/core'
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
} from 'ra-core'
import { useCallback, useState } from 'react'
import { Record, useDataProvider } from 'react-admin'
import { connect } from 'react-redux'
import { useEndpointAvailable } from '../../../hooks/useEndpointAvailable'
import { PAYMENT_ENDPOINT_API } from '../../../lib/config'
import { NodeActionProps } from '../NodeActionType'
import { cancelSubscription } from '../subscription/lib/subscriptionService'
import { createSimpleAuthenticatedClient } from '../user/lib/simpleClient'

function CancelScheduledSubscription({
  record: scheduledSubscription,
  resource,
  refreshView,
  showNotification,
}: NodeActionProps & {
  refreshView: typeof refreshViewAction
  showNotification: typeof showNotificationAction
}) {
  const [sending, setSending] = useState(false)
  const [subscription, setSubscription] = useState<Record | undefined>()

  let paymentEndpointAPI: string =
    // we have no selector here for platform like the add sub... not supported for Uni yet
    // also see cancel-subscription.ts
    // platform && parsedPaymentAPI !== undefined
    //   ? parsedPaymentAPI[platform] // if role has access to one of the platform, then they should also be able to access the others as well
    //   :
    PAYMENT_ENDPOINT_API
  const endpoint = 'admin/subscription/cancel'
  const isEndpointAvailable = useEndpointAvailable(
    createSimpleAuthenticatedClient(paymentEndpointAPI),
    endpoint
  )

  const { isCancelled, subscriptionId } = scheduledSubscription
  const dataProvider = useDataProvider()

  // get the subcription
  useState(() => {
    const fetchSubscription = async () => {
      try {
        const res = await dataProvider.getOne('subscription', {
          id: subscriptionId,
        })
        if (res.data) {
          setSubscription(res.data)
        }
      } catch (error) {
        console.error(error)
      }
    }
    fetchSubscription()
  })

  const cancelHandler = useCallback(
    async function CancelScheduledSubscription(sendEmail: boolean) {
      if (sending) return
      setSending(true)
      const cleanUp = () => setSending(false)

      try {
        await cancelSubscription({
          subscriptionId,
          sendCancelEmail: sendEmail,
          endpoint,
        })
        showNotification(
          'Subscription canceled on payment provider side! Reloading Data'
        )
        refreshView()
      } catch (error) {
        console.error(
          'could not cancel subscription payment provider side',
          error
        )
        showNotification(
          'Error: Could not cancel subscription payment provider side!',
          'warning'
        )
      }
      cleanUp()
    },
    [scheduledSubscription, sending, refreshView, showNotification]
  )

  if (!isEndpointAvailable) {
    return null
  }

  if (isCancelled) {
    return (
      <Button variant="outlined" disabled={true} size="small">
        Subscription has been cancelled.
      </Button>
    )
  } else if (subscription && subscription.managedBy) {
    return (
      <Button variant="outlined" disabled={true} size="small">
        {`Managed by ${subscription.managedBy}. Please cancel on their dashboard..`}
      </Button>
    )
  } else if (subscription && !subscription.managedBy && !isCancelled) {
    return (
      <>
        <Button
          disabled={sending}
          onClick={() => cancelHandler(false)}
          variant="outlined"
          size="small"
        >
          {sending ? 'Sending...' : `Cancel Subscription`}
        </Button>
        <Button
          disabled={sending}
          onClick={() => cancelHandler(false)}
          variant="outlined"
          size="small"
        >
          {sending ? 'Sending...' : `Cancel Subscription with Email`}
        </Button>
      </>
    )
  }
  return null
}

CancelScheduledSubscription.label = 'CancelScheduledSubscription'

export default connect(null, {
  refreshView: refreshViewAction,
  showNotification: showNotificationAction,
})(CancelScheduledSubscription)
