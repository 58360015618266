import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import IconButton from '@material-ui/core/IconButton'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import InputLabel from '@material-ui/core/InputLabel'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CheckboxIcon from '@material-ui/icons/CheckBox'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import React, { useState } from 'react'
import { Users_allUsers_nodes } from '../../__generated__/Users'
import { CountrySelectionFields } from '../../genericData/customFields/CountrySelectionFields'
import { LinkButton } from '../../utils/LinkButton'

const useStyles = makeStyles(() => ({
  expandedSpace: {
    display: 'inline-grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gridGap: '1rem',
    width: 'fill-available',
  },
  label: { marginBottom: 10, marginTop: 10 },
  input: {
    width: 'fill-available',
    '& > *': { color: 'black' },
  },
  headingContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  heading: {
    display: 'inline-flex',
    alignItems: 'center',
  },
  secondaryHeading: {
    marginLeft: '40px',
    color: 'gray',
  },
  summaryPanel: {
    '& > div:first-child': {
      justifyContent: 'space-between',
    },
  },
}))

type UserPanelProps = {
  user: Users_allUsers_nodes | null
  onSave: (text: string) => void
  onCopy: (text: string) => void
}

export const UserPanel = React.memo((props: UserPanelProps) => {
  const { user, onSave, onCopy } = props
  const classes = useStyles()

  if (user === null) {
    return null
  }

  const {
    id,
    name,
    username,
    paymentTransactionsByUserId,
    subscriptionsByUserId,
    paymentCountry,
  } = user

  // thing we display to user
  let paymentProviderId: string | null = null
  let requestId: string | null = null
  let transactionStep: string | null = null
  let merchantConfigurationName: string | null = null

  // we only queried for 1 elem (first: 1) so just return it
  const transaction =
    paymentTransactionsByUserId.nodes.length > 0
      ? paymentTransactionsByUserId.nodes[0]
      : null

  if (transaction !== null) {
    paymentProviderId = transaction.paymentProviderId
    const paymentTransactionLogsByPaymentTransactionId =
      transaction.paymentTransactionLogsByPaymentTransactionId

    // this should also only contain 1 elem based on query filter of 'first: 1'
    const transactionLog =
      paymentTransactionLogsByPaymentTransactionId.nodes.length > 0
        ? paymentTransactionLogsByPaymentTransactionId.nodes[0]
        : null

    if (transactionLog !== null) {
      const log = transactionLog.log
      transactionStep = transactionLog.transactionStep

      if (log) {
        requestId = JSON.parse(log).payment['request-id']
      }
    }
  }

  if (subscriptionsByUserId) {
    const sub =
      subscriptionsByUserId.nodes.length > 0
        ? subscriptionsByUserId.nodes[0]
        : null
    if (sub && sub.subscriptionProduct?.merchantConfiguration?.name) {
      merchantConfigurationName =
        sub.subscriptionProduct.merchantConfiguration.name
    }
  }

  // don't show users that have no transaction in the past, aka. new 'FREE' user
  if (!requestId && !paymentProviderId) {
    return null
  }

  const inputProps = {
    className: classes.input,
    multiline: true,
    disabled: true,
    disableUnderline: true,
  }
  const [isOpen, setOpen] = useState(true)
  const [isDone, setDone] = useState(false)
  return (
    <Accordion expanded={isOpen}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.summaryPanel}
        onClick={() => setOpen(!isOpen)}
      >
        <div className={classes.headingContainer}>
          <Typography id={username} className={classes.heading}>
            {username}
          </Typography>
          <LinkButton
            style={{
              marginLeft: 10,
              marginRight: 10,
            }}
            variant="outlined"
            to={`/User/${id}/show`}
          >
            User Details
          </LinkButton>
          {isDone ? <CheckboxIcon color="primary" fontSize="large" /> : null}
        </div>
        {name && (
          <Typography id={name} className={classes.secondaryHeading}>
            {name}
          </Typography>
        )}
        {/* For Testing Purpose if paymentCountryIsNull === false */}
        {paymentCountry && (
          <Typography id={paymentCountry} className={classes.secondaryHeading}>
            {paymentCountry}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails className={classes.expandedSpace}>
        {/* Select Country Field */}
        <CountrySelectionFields
          id={id}
          onSave={(t) => {
            setOpen(false)
            setDone(true)
            onSave(t)
          }}
        />

        {/* Merchant Configuration */}
        {merchantConfigurationName ? (
          <div>
            <InputLabel className={classes.label}>
              Merchant Configuration
            </InputLabel>
            <Input {...inputProps} defaultValue={merchantConfigurationName} />
          </div>
        ) : null}

        {/* Payment Provider Id */}
        {paymentProviderId ? (
          <div>
            <InputLabel className={classes.label}>
              Payment Provider Id
            </InputLabel>
            <Input
              {...inputProps}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="copy value"
                    onClick={() => onCopy(paymentProviderId!)}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
              defaultValue={paymentProviderId}
            />
          </div>
        ) : null}

        {/* Request Id */}
        {requestId ? (
          <div>
            <InputLabel className={classes.label}>Request Id</InputLabel>
            <Input
              {...inputProps}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="copy value"
                    onClick={() => onCopy(requestId!)}
                  >
                    <FileCopyIcon />
                  </IconButton>
                </InputAdornment>
              }
              defaultValue={requestId}
            />
          </div>
        ) : null}

        {/* Transaction Step */}
        {transactionStep ? (
          <div>
            <InputLabel className={classes.label}>Transaction Step</InputLabel>
            <Input {...inputProps} defaultValue={transactionStep} />
          </div>
        ) : null}
      </AccordionDetails>
    </Accordion>
  )
})
