import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import SearchIcon from '@material-ui/icons/Search'
import { GetOneResult, useRefresh } from 'ra-core'
import { useCallback, useEffect, useState } from 'react'
import { Record, useDataProvider, useNotify, useQuery } from 'react-admin'
import { useField, useForm, useFormState } from 'react-final-form'
import { useConfirmation } from '../ConfirmationService'
import { CreateModal } from '../CreateModal'
import { SMPL_TEMP_PERSON } from '../GenericEditPage'
import { DataGridModal } from '../renderInput'

const useStyles = makeStyles(() => ({
  box: {
    border: 'thin solid #80808029',
    padding: 5,
    borderRadius: 5,
  },
  chip: {
    margin: '5px 5px 10px 15px',
  },
  divider: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  catCard: {
    marginBottom: 10,
  },
}))

// For using inside of <Form>
export const PersonInputWithExistingRecord = (props: any) => {
  const {
    record,
    resource,
  }: {
    record: Record
    resource: string
  } = props

  let target

  switch (resource) {
    case 'CmsMovie':
      target = 'cmsMovieContentPeopleByContentMovieId'
      break
    case 'CmsSeason':
      target = 'cmsMovieContentPeopleByContentSeasonId'
      break
    case 'CmsSery':
      target = 'cmsMovieContentPeopleByContentSeriesId'
      break
    case 'CmsEpisode':
      target = 'cmsMovieContentPeopleByContentEpisodeId'
      break
    default:
      console.log(resource + ' is currently not supported in PersonInput')
      break
  }

  if (!target) return null

  const { loading, error, data } = useQuery({
    type: 'getManyReference',
    resource: resource,
    payload: {
      id: record.id,
      target: target,
    },
  })

  if (data) {
    return <PersonInput {...props} existingPersonConnectionData={data || []} />
  }
  if (error) {
    console.log(error)
  }
  return null
}

type PersonConnectionType = 'director' | 'producer' | 'writer' | 'actor'
const ConnectionType: PersonConnectionType[] = [
  'director',
  'producer',
  'writer',
  'actor',
]
export type TempPerson = {
  id: string
  name: string
  connectionType: PersonConnectionType
}

// For using inside of <Form>
export const PersonInput = (props: any) => {
  const {
    record,
    resource,
    existingPersonConnectionData,
  }: {
    record: Record
    resource: string
    existingPersonConnectionData:
      | {
          id: string
          personId: string
          connectionType: string
        }[]
      | undefined
  } = props

  const referenceTypeName = 'CmsPerson'

  // Hooks
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const confirm = useConfirmation()
  const [queriedExistingPerson, setQueriedExistingPerson] = useState<
    GetOneResult<Record>[]
  >([])

  useEffect(() => {
    async function getPersonFromPersonConnection() {
      if (
        existingPersonConnectionData &&
        existingPersonConnectionData.length > 0
      ) {
        const persons = await Promise.all(
          existingPersonConnectionData.map(async (p) => {
            return await dataProvider.getOne('CmsPerson', {
              id: p!.personId,
            })
          })
        )
        setQueriedExistingPerson(persons)
      }
    }

    getPersonFromPersonConnection()
  }, [existingPersonConnectionData])

  //// Setup Temp Person In Form
  useField(
    SMPL_TEMP_PERSON
    // don't do this or 'Maximum update depth exceeded' will happen
    //   {
    //   initialValue: [],
    // }
  )
  const form = useForm()
  const currentFieldState = useFormState()
  const tempPerson: TempPerson[] =
    currentFieldState.values[SMPL_TEMP_PERSON] || []
  //// Setup Temp Person In Form

  //// For Temp Selection Inside Modal
  const [selectedEntries, setSelectedEntries] = useState<Record[] | null>([])
  const [tempConType, setTempConType] = useState<string | null>(null)
  //// For Temp Selection Inside Modal

  //// For Open/Close Selection Modal
  const [selectionOpen, setSelectionOpen] = useState(false)
  const openSelectionModal = (conType: PersonConnectionType) => {
    setSelectionOpen(true)
    setTempConType(conType)
  }
  const closeSelectionModal = useCallback(() => {
    setSelectionOpen(false)
  }, [])
  //// For Open/Close Selection Modal

  //// For Open/Close Create Modal
  const [createOpen, setCreateOpen] = useState(false)
  const openCreateModal = (conType: PersonConnectionType) => {
    setCreateOpen(true)
    setTempConType(conType)
  }
  const closeCreateModal = useCallback(() => {
    setCreateOpen(false)
  }, [])
  const setFunctionCreate = useCallback((data: Record) => {
    setSelectedEntries([data])
  }, [])
  //// For Open/Close Create Modal

  //// For Temp Add
  useEffect(() => {
    if (selectedEntries && selectedEntries.length > 0) {
      const existingPersons: string[] = []
      const newTempPersons: Record[] = []

      selectedEntries.forEach((selectedEntry) => {
        let existingPerson
        // check in existing
        if (existingPersonConnectionData) {
          existingPerson = existingPersonConnectionData.find((entry) => {
            if (
              entry.personId === selectedEntry.id &&
              entry.connectionType === tempConType
            ) {
              return entry
            }
            return null
          })
        }

        // check in temp
        if (!existingPerson) {
          existingPerson = tempPerson.find(
            (tPerson) =>
              tPerson.id === selectedEntry.id &&
              tPerson.connectionType === tempConType
          )
        }

        // not exist in existing and not in exist in temp
        if (!existingPerson) {
          newTempPersons.push(selectedEntry)
        } else {
          existingPersons.push(selectedEntry.name)
        }
      })

      if (newTempPersons.length > 0) {
        const tempPersonInForm = newTempPersons.map((selectedEntry) => ({
          id: selectedEntry.id,
          name: selectedEntry.name,
          connectionType: tempConType,
        }))

        if (tempPerson.length === 0) {
          form.change(SMPL_TEMP_PERSON, tempPersonInForm)
        } else {
          form.change(SMPL_TEMP_PERSON, [...tempPerson, ...tempPersonInForm])
        }
        notify(
          `Persons temporarily added to ${tempConType}. Don't forget to save!`,
          'success'
        )
      }

      if (existingPersons.length > 0) {
        notify(
          `Following persons already exist on this movie: ${existingPersons.join(
            ', '
          )}`,
          'warning'
        )
      }

      // reset selected
      setTempConType(null)
      setSelectedEntries([])
    }
  }, [existingPersonConnectionData, notify, selectedEntries])
  //// For Temp Add

  //// For delete on temp
  const removeFromTemp = (selectedId: string, job: string) => {
    const newTempCat = tempPerson.filter((tPerson) => {
      if (tPerson.id === selectedId && tPerson.connectionType === job) {
        return false
      }
      return true
    })
    form.change(SMPL_TEMP_PERSON, newTempCat)
  }
  //// For delete on temp

  //// For delete on exitsing
  const onConfirm = async (selectedId: string) => {
    const resolve = await deletePersonConnection(selectedId)

    if (resolve) {
      notify('Person successfully removed!', 'info')
    } else {
      notify(
        'Removing person failed. If this problem persists, please contact the support.',
        'warning'
      )
    }
    refresh()
  }
  const onDeny = () => {
    // Do nothing
  }
  const queryDeleteConfirmation = (
    personConnectionId: string,
    person?: string
  ) => {
    confirm({
      catchOnCancel: true,
      title: 'Delete "' + person + '" from this entry?',
      description:
        'Are you sure you want to delete the "' +
        person +
        '" connection from this entry? Deleting will also remove all changes so far. You may want to save before proceeding with the deletion.',
      id: personConnectionId,
      onConfirm: onConfirm,
      onDeny: onDeny,
    })
  }
  const deletePersonConnection = async (personConnectionId: string) => {
    const deleteMe = existingPersonConnectionData
      ? existingPersonConnectionData.find((personCon) => {
          if (personCon && personCon.id === personConnectionId) {
            return personCon
          }
          return null
        })
      : null

    if (!deleteMe) {
      return false
    }

    try {
      await dataProvider.delete('CmsMovieContentPerson', {
        id: deleteMe.id,
        previousData: { ...deleteMe },
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
  //// For delete on exitsing

  return (
    <div className={classes.box}>
      {/* Create Person Button */}
      {ConnectionType.map((conType) => {
        return (
          <Chip
            key={conType + 'create' + 'chip'}
            label={`Create ${conType[0].toUpperCase() + conType.substring(1)}`}
            variant="outlined"
            icon={<AddIcon />}
            onClick={() => openCreateModal(conType)}
            className={classes.chip}
          />
        )
      })}

      <Divider className={classes.divider} variant="middle" />

      {/* Add Person Button */}
      {ConnectionType.map((conType) => {
        return (
          <Chip
            key={conType + 'add' + 'chip'}
            label={`Select ${conType[0].toUpperCase() + conType.substring(1)}`}
            variant="outlined"
            icon={<SearchIcon />}
            onClick={() => openSelectionModal(conType)}
            className={classes.chip}
          />
        )
      })}
      <Divider className={classes.divider} />
      {ConnectionType.map((conType) => {
        let shouldShowCardOfThisConType = existingPersonConnectionData
          ? !!existingPersonConnectionData.find(
              (p) => p.connectionType === conType
            )
          : false

        shouldShowCardOfThisConType = shouldShowCardOfThisConType
          ? true
          : tempPerson
          ? !!tempPerson.find((p) => p.connectionType === conType)
          : false

        if (!shouldShowCardOfThisConType) {
          return null
        }

        return (
          <div key={conType + 'card'}>
            <Card className={classes.catCard}>
              <CardContent>
                <Typography
                  className={classes.title}
                  color="textSecondary"
                  gutterBottom
                >
                  {conType[0].toUpperCase() + conType.substring(1)}
                </Typography>
                {/* Existing Person Chip Of This Connection Type*/}
                {existingPersonConnectionData &&
                  queriedExistingPerson.length > 0 &&
                  existingPersonConnectionData.map((personConnectionEntry) => {
                    if (
                      personConnectionEntry &&
                      personConnectionEntry.connectionType === conType
                    ) {
                      const pId = personConnectionEntry.id
                      const personData = queriedExistingPerson.find(
                        (p) => p.data.id === personConnectionEntry.personId
                      )
                      return (
                        <Chip
                          key={pId}
                          label={personData!.data.name}
                          color="primary"
                          onDelete={() =>
                            // Call a Dialog asking for delete confirmation
                            queryDeleteConfirmation(pId, personData!.data.name)
                          }
                          className={classes.chip}
                        />
                      )
                    }
                    return null
                  })}

                {/* Temp Category Chip Of This Connection Type*/}
                {tempPerson &&
                  tempPerson.length > 0 &&
                  tempPerson.map((tPerson) => {
                    if (tPerson && tPerson.connectionType === conType) {
                      const pId = tPerson.id
                      console.log(pId)
                      return (
                        <Chip
                          key={pId + 'temp'}
                          label={tPerson.name}
                          variant="outlined"
                          icon={<QueryBuilderIcon />}
                          onDelete={() =>
                            removeFromTemp(pId, tPerson.connectionType)
                          }
                          className={classes.chip}
                        />
                      )
                    }
                    return null
                  })}
              </CardContent>
            </Card>
          </div>
        )
      })}

      {/* Modal for creating new Category */}
      <CreateModal
        open={createOpen}
        onClose={closeCreateModal}
        onCloseDataGridModal={closeCreateModal}
        resource={referenceTypeName}
        hasEdit
        hasList
        hasShow
        // setFunction={setSelectedId}
        setFunctionEntry={setFunctionCreate}
        fieldName={tempConType ? tempConType.toUpperCase() : ''}
      />

      {/* Modal for adding a Person Connection*/}
      <DataGridModal
        open={selectionOpen}
        onClose={closeSelectionModal}
        resource={referenceTypeName}
        basePath={`/${referenceTypeName}`}
        location={{
          hash: '',
          pathname: `/${referenceTypeName}`,
          search: '',
          state: null,
        }}
        calledFromModal={true}
        // setFunction={setSelectedId}
        setFunctionEntries={setSelectedEntries}
        openedModal={setSelectionOpen}
      />
    </div>
  )
}
