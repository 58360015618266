import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import React from 'react'
import { componentByType } from './index'

const exampleData = [
  {
    key: 'cancellations_last_30_days',
    type: 'KEY_PERFORMANCE_INDICATOR',
    props: {
      label: 'Cancellations',
      description: 'Last 30 days',
      numberOfDays: 30,
      query: `
      query cancellationsLast30Days($fromDate: Datetime!) {
        kpiData: allPaymentTransactionLogs(filter: {
            createdDate: {
              greaterThan: $fromDate
            },
            transactionStep: {
              equalTo: "cancel"
            }
        }) {
          kpi: totalCount
        }
      }
    `,
    },
  },
  {
    key: 'subscriptions_last_30_days',
    type: 'KEY_PERFORMANCE_INDICATOR',
    props: {
      label: 'New Subscriptions',
      description: 'Last 30 days',
      numberOfDays: 30,
      query: `
      query latestSubscriptions($fromDate: Datetime!) {
        kpiData: allPaymentTransactions (filter: {
            createdDate: {
              greaterThan: $fromDate
            },
            status: {
              equalTo: SUCCESSFUL,
            }
            parentPaymentTransactionId: {
              isNull: true
            }
        }){
          kpi: totalCount
        }
      }
    `,
    },
  },
  {
    key: 'active_subscriptions',
    type: 'KEY_PERFORMANCE_INDICATOR',
    props: {
      label: 'Currently',
      description: 'Active Subscriptions',
      numberOfDays: 30,
      query: `
        query subscriptions {
          kpiData: allSubscriptions(filter: {
              isActive: { equalTo:true },
              isRecurringActive: {
                equalTo: true
              }
          })
          {
            kpi: totalCount
          }
        }
    `,
    },
  },

  {
    key: 'spacer_1',
    type: 'SPACER',
    props: {},
  },
  {
    key: 'registrations_in_time',
    type: 'TIME_CHART',
    props: {
      label: 'Registrations',
      query: `
        query seriesData($fromDate: Datetime!, $toDate: Datetime!) {
          users: allUsers(filter: {
            createdDate: {
              lessThanOrEqualTo: $toDate,
              greaterThan: $fromDate
            },
          }) {
            totalCount
          }
        }
      `,
      series: [
        {
          label: 'Users',
          key: 'users.totalCount',
          color: '#2AA38B',
          areaColor: 'rgba(42, 163, 139, 0.5)',
        },
      ],
    },
  },
  {
    key: 'subscription_in_time',
    type: 'TIME_CHART',
    props: {
      label: 'Subscriptions',
      query: `
        query seriesData($fromDate: Datetime!, $toDate: Datetime!) {
          cancellations: allPaymentTransactionLogs(filter: {
            createdDate: {
              greaterThanOrEqualTo: $fromDate,
              lessThanOrEqualTo: $toDate
            },
            transactionStep: {
              equalTo: "cancel"
            }
          }) {
            totalCount
          }
          subscriptions: allPaymentTransactions(filter: {
            createdDate: {
              greaterThanOrEqualTo: $fromDate,
              lessThanOrEqualTo: $toDate
            },
            status: {
              equalTo: SUCCESSFUL
            },
            parentPaymentTransactionId: {
              isNull: true
            }
          }) {
            totalCount
          }
        }
      `,
      series: [
        {
          label: 'Cancellations',
          key: 'cancellations.totalCount',
          color: '#FA7252',
          areaColor: 'rgba(250, 114, 82, 0.5)',
        },
        {
          label: 'Subscriptions',
          key: 'subscriptions.totalCount',
          color: '#2AA38B',
          areaColor: 'rgba(42, 163, 139, 0.5)',
        },
      ],
    },
  },
]

export class InsightsGQL extends React.PureComponent {
  render() {
    return (
      <div>
        <Card controls={false}>
          <CardContent>
            <Typography gutterBottom variant="h6" component="h2">
              SMPL Insights
            </Typography>
            <Typography component="p">
              The insights dashboard shows the most crucial data points for your
              application.
            </Typography>
          </CardContent>
        </Card>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'center',
          }}
        >
          {exampleData.map(({ type, key, props }, index, { length }) => {
            const Comp = componentByType[type]
            if (!Comp) {
              console.error('invalid insight component type', type)
              return null
            }
            const renderedComp = <Comp key={key || index} {...props} />
            if (type === 'SPACER') {
              return renderedComp
            }

            return (
              <div
                key={key || index}
                style={{
                  display: 'flex',
                  marginTop: 10,
                  margin: 5,
                }}
              >
                {renderedComp}
              </div>
            )
          })}
        </div>
      </div>
    )
  }
}
