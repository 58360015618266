import Button from '@material-ui/core/Button'
import Fade from '@material-ui/core/Fade'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import { useRefresh } from 'ra-core'
import { useState } from 'react'
import { useNotify } from 'react-admin'
import { getToken } from '../../authProvider'
import { MEDIA_CONVERT_CONTROLLER_API } from '../../lib/config'

export const UpdateRatingFields = (props: any) => {
  const { record, resource } = props
  const { externalIdentifier, title, imdbIdentifier } = record
  const notify = useNotify()
  const refresh = useRefresh()
  const [amp, setAmp] = useState<number>(1)

  const handleRatingChange = () => {
    if (!externalIdentifier) {
      notify('Update failed, externalIdentifier is missing.', 'warning')
      return
    }
    if (!imdbIdentifier) {
      notify('Update failed, imdbIdentifier is missing.', 'warning')
      return
    }
    // For testing with mediaService running on port 8000
    // const URL: string = 'http://localhost:8000/updateResource'
    const URL: string = MEDIA_CONVERT_CONTROLLER_API + '/updateResource'
    // only this is needed, the rest is done in the backend
    const bodyMessage = {
      amplifier: amp,
      externalIdentifiers: [externalIdentifier],
    }
    const params = {
      body: JSON.stringify({ Message: bodyMessage }),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }

    console.log(
      'Update rating of',
      resource,
      title,
      'with an amplifier of',
      amp
    )

    fetch(URL, params)
      .then((res: Response) => {
        return res.json()
      })
      .then((data) => {
        // notify based on response message
        if (data.success === false) {
          const errorMessage = data.error[0].error
          if (errorMessage) {
            notify('Update failed. ' + errorMessage, 'warning')
          } else {
            notify(
              'Update failed. If this problem persists, please contact the support.'
            )
          }
        } else {
          notify('Update successfully.')
        }
        refresh()
      })
      .catch((error) => {
        // notify on failure
        notify('Update failed:' + error.message, 'warning')
        console.error(error)
        refresh()
      })
  }
  // TODO: add input end adornment for explaination
  return (
    <div style={{ display: 'flex' }}>
      <div>
        <Input
          defaultValue={1}
          type="number"
          onChange={(e) => {
            const amp = Number(e.target.value)
            // allow negative amp?
            if (!isNaN(amp)) {
              setAmp(amp)
            }
          }}
          endAdornment={
            <InputAdornment position="end">
              <Tooltip
                TransitionComponent={Fade}
                TransitionProps={{ timeout: 500 }}
                enterDelay={100}
                title={
                  'Additive amplifier used in the calculation of the rating. If the amplifier is 0, the original IMDb rating is used. The maximum rating is 5.'
                }
                placement="top"
              >
                <InfoOutlinedIcon color="primary" />
              </Tooltip>
            </InputAdornment>
          }
        />
        <FormHelperText id="apmlifier-helper-text">
          Amplifier for rating
        </FormHelperText>
      </div>

      <Button
        onClick={handleRatingChange}
        size="small"
        variant="contained"
        color="primary"
        style={{ marginLeft: 10 }}
      >
        Update with amplifier
      </Button>
    </div>
  )
}
