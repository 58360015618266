import { useHistory } from 'react-router-dom' // Import useHistory from react-router-dom
import { CUSTOMER } from '../lib/config'
import { ARDLogo } from './customerThemes/ard'
import { FilmhomeLogo } from './customerThemes/filmhome'
import { FlexgoldLogo } from './customerThemes/flexgold'
import { FlorianhommLogo } from './customerThemes/florianhomm'
import { KixiLogo } from './customerThemes/kixi'
import { NetzkinoLogo } from './customerThemes/netzkino'
import { StandardLogo } from './customerThemes/standard'
import { UniversumLogo } from './customerThemes/universum'

const style = {
  wrapper: {
    width: 230,
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    '@media(maxWidth: 600px)': {
      width: 0,
    },
  },
}

const Logo = () => {
  const history = useHistory() // Access the history object

  const customerLogos = {
    ard: ARDLogo,
    kixi: KixiLogo,
    netzkino: NetzkinoLogo,
    universum: UniversumLogo,
    filmhome: FilmhomeLogo,
    flexgold: FlexgoldLogo,
    florianhomm: FlorianhommLogo,
  }

  const handleLogoClick = () => {
    history.push('/')
  }

  return (
    <div style={style.wrapper}>
      <div onClick={handleLogoClick} style={{ cursor: 'pointer' }}>
        {customerLogos[CUSTOMER] || StandardLogo}
      </div>
    </div>
  )
}

export default Logo
