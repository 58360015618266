import { useEffect } from 'react'
import { Prompt } from 'react-router'

function useExitPrompt(when: boolean, message: string) {
  useEffect(() => {
    if (when) {
      const listener = (ev: BeforeUnloadEvent) => {
        ev.preventDefault()
        return (ev.returnValue = message)
      }
      window.addEventListener('beforeunload', listener)
      return () => {
        window.removeEventListener('beforeunload', listener)
      }
    }
  }, [when, message])
}

export function PageExitPrompt({
  when,
  message,
}: {
  when: boolean
  message: string
}) {
  useExitPrompt(when, message)
  return <Prompt when={when} message={message} />
}
