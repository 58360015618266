import gql from 'graphql-tag'
import { getFragmentForType } from './getFragmentForType'
import {
  functionGetListParamsToVariables,
  GetListParams,
  getOrderByType,
} from './getList'
import { getOneQueryName } from './getOne'
import { Resource } from './ReactAdmin-Types'
import { SchemaTraverser } from './SchemaTraverser'

export type GetManyReferenceParams = GetListParams & {
  readonly id: string
  readonly target: string
  readonly source: string
}

function getManyReferenceParamsToVariables(
  { id, ...listParams }: GetManyReferenceParams,
  resource: Resource
) {
  return {
    ...functionGetListParamsToVariables(listParams, [], resource),
    id,
  }
}

const getManyReferenceQuery = function getManyReferenceQuery(
  queryName: string,
  resource: Resource,
  target: string,
  source: string,
  schemaTraverser: SchemaTraverser
) {
  const {
    type: { name: typeName },
  } = resource
  /*
   * Example: on the user page, load paymentTransactionsByOwnerId
   * <ReferenceManyField resource="User" target="paymentTransactionsByOwnerId" />
   * `source` will be "paymentTransactionsByOwnerId"
   * resource = UserResource
   * target is "id" -> set everytime, unsure why the naming is off
   */

  // User in our example
  const baseType = schemaTraverser.getResourceTraverserByName(typeName)
  if (!baseType) {
    throw new Error('invalid target type!' + typeName)
  }
  /**
   * queryName must be userById <-- from base type
   * target must be the field in the base type, e.g watchlistItemsById
   * We use the <TypeName>Connection for field detection, see getOneToManyRelationshipFields TODO: copy over
   *
   */
  // FIXME rename
  // get the "paymentTransactionsByOwnerId" field
  const targetFieldOfBaseType = baseType.getFieldByName(target)
  if (!baseType || !targetFieldOfBaseType) {
    throw new Error(`invalid target field "${target}" on ${typeName}!`)
  }
  const { getManyReferenceType } = targetFieldOfBaseType
  if (!getManyReferenceType) {
    throw new Error(
      `invalid target field "${target}" on ${typeName}! The field is does not provide a hasMany relationship!`
    )
  }

  // get WatchlistItem type that has the id of the base object
  const { name: relatedType } = getManyReferenceType.referenceType
  const relatedTypeTraverser = schemaTraverser.getResourceTraverserByName(
    relatedType
  )

  if (!relatedTypeTraverser) {
    throw new Error(
      `invalid target field "${target}" on ${typeName}! The target field type cannot be resolved to ResourceTraverser!`
    )
  }

  const OrderBy = getOrderByType(relatedTypeTraverser.resource.type)
  const {
    fragment: AllTypesFragment,
    fragmentName: AllTypesFragmentName,
  } = getFragmentForType(relatedTypeTraverser.resource.type)
  const queryWithoutApollo = gql
  const query = queryWithoutApollo`
  ${AllTypesFragment}

  query getManyReference($id: Guid!, $paginationLimit: Int, $paginationOffset: Int, $orderBy: [${OrderBy}!]) {
      data: ${queryName}(id: $id) {
        id
        relatedData: ${target}(first: $paginationLimit, offset: $paginationOffset, orderBy: $orderBy) {
          totalCount
          nodes {
            nodeId
            ...${AllTypesFragmentName}
          }
        }
      }
  }
`
  return query
}

export function getManyReferenceQueryName(resource: Resource) {
  return getOneQueryName(resource)
  // return `all${pluralize(nameFromResource(resource))}`;
}

export function graphQlQueryDescriptor(
  queryName: string,
  resource: Resource,
  params: GetManyReferenceParams,
  schemaTraverser: SchemaTraverser
) {
  const { source, target } = params

  return {
    query: getManyReferenceQuery(
      queryName,
      resource,
      target,
      source,
      schemaTraverser
    ),
    variables: getManyReferenceParamsToVariables(params, resource), // params = { id: ... }
    parseResponse: (response: Object) => {
      // @ts-ignore
      if (!response || !response.data || !response.data.data) {
        console.error('graphql could not find any data!', response)
        throw new Error(`Cannot get related data for ${target}`)
      }
      const {
        // @ts-ignore
        data: {
          data: {
            id,
            relatedData: { nodes, totalCount },
          },
        },
      } = response
      return {
        id,
        data: nodes,
        total: totalCount,
      }
    },
  }
}
