// @ts-check
import { setContext } from 'apollo-link-context'
import { createHttpLink } from 'apollo-link-http'
import buildApolloClient from 'ra-data-graphql/esm/buildApolloClient'
// for unknown reason the new version of react-scripts which chooses the esm version by default. This breaks
import createGraphQlProvider from 'ra-data-graphql/lib/index'
import authProvider, { getToken } from '../authProvider'
import { getErrorNotificationMessages } from '../i18n/errorMessages'
import { SMPL_DATA_API_URL } from '../lib/config'
import { buildQuery } from './buildQuery'
import { introspection } from './smplIntrospection'

const URL = `${SMPL_DATA_API_URL.replace(/^http:/, 'https:')}`
const httpLink = createHttpLink({
  uri: URL,
})

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getToken()
  // return the headers to the context so httpLink can read them
  return {
    headers: token
      ? {
          ...headers,
          authorization: `Bearer ${token}`,
        }
      : headers,
  }
})

/**
 * @type {import('apollo-client').ApolloClient<any>}
 */
export const apolloClient = buildApolloClient({
  uri: URL,
  link: authLink.concat(httpLink),
})

export default async function getGraphQlDataProvider() {
  try {
    // const res = await fetch(URL, {})
    const smplDataProvider = await createGraphQlProvider({
      ...(process.env.NODE_ENV === 'development'
        ? { schema: require('./schema.json') }
        : null),
      client: apolloClient,
      introspection,
      buildQuery,
    })
    return function GraphQLDataProvider(type, resource, params) {
      return smplDataProvider(type, resource, params).catch((err) => {
        const errorText = getErrorNotificationMessages(err)

        if (Array.isArray(errorText)) {
          const errorWithoutPermError = errorText.filter((err) => {
            if (err.includes('permission denied')) {
              console.log('User has no access to related table, therfore', err)
              return false
            }
            return true
          })
          if (errorWithoutPermError.length > 0) {
            console.error('graphql request failed', errorWithoutPermError[0])
            throw errorWithoutPermError[0]
          } else {
            return
          }
        }

        console.error('graphql request failed', err)
        throw errorText ? errorText : err
      })
    }
  } catch (e) {
    console.error('could not create GraphQL Provider', e)
    authProvider.checkError({
      status: 401,
    })
    throw e
  }
}
