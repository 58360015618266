import { useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Select from 'react-dropdown-select'
import { useField, useFormState } from 'react-final-form'

export const StringListToSelectInput = (props: {
  fieldName: string
  label: string
  inputProps: {
    isCountry: boolean
    options: {
      label: string
      value: string
    }[]
  }
}) => {
  const { fieldName, label, inputProps } = props
  const { isCountry, options } = inputProps
  const currentFieldValue = useFormState().values[fieldName!]
  const formField = useField(fieldName)
  const theme = useTheme()

  const valuesArray: string[] = currentFieldValue
  const values = valuesArray?.map((value) => {
    return {
      label: value,
      value: value,
    }
  })

  return (
    <div style={{ width: 'inherit' }}>
      <Typography color="textSecondary" variant="caption">
        {label ? label : fieldName}
      </Typography>
      <Select
        options={options}
        multi={true}
        values={values}
        valueField="value"
        onChange={(values) => {
          const choosenValuesArray = values.map((v) => {
            if (isCountry) {
              // only upper case if country/regions
              return v.value.toUpperCase()
            }
            return v.value
          })
          formField.input.onChange(choosenValuesArray)
        }}
        create={true}
        dropdownHeight="120px"
        dropdownPosition="auto"
        color={theme.palette.primary.main}
      />
    </div>
  )
}
