import { Card, CardContent, Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import React from 'react'
import { connect } from 'react-redux'
import { AppPanel } from './AppPanel'

function AppStoreRenderer({ apps }) {
  return (
    <>
      <Card>
        <CardContent>
          <Typography variant="h5">SMPL Apps</Typography>
          <Typography component="p">
            Apps are services that enhance the SMPL infrastructure with business
            logic functionality based on your personal project requirements.
            <br />
          </Typography>
          <Typography component="p" color="error">
            The SMPL app functionality is still work in progress. At the moment,
            you cannot enable/disable apps.
          </Typography>
        </CardContent>
      </Card>
      <div
        style={{
          margin: 30,
          flexWrap: 'wrap',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {apps.map((app) => (
          <AppPanel key={app.shortKey} app={app} style={{ margin: 12 }} />
        ))}
        {/*rows.map((apps, index) => (
          <div key={index} className="responsiveFlex">
            {apps.map(app => (
              <div
                key={app.id}
                style={{ padding: 8 }}
                className="responsiveFlex"
              >
                <AppPanel app={app} style={{ display: 'flex' }} />
              </div>
            ))}
          </div>
        ))*/}
      </div>
    </>
  )
}

export const AppStore = connect(function mapStateToProps({ apps }) {
  return {
    apps,
  }
})(AppStoreRenderer)

AppStore.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.object,
}
