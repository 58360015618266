import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { ErrorCard } from '../../ErrorCard'
import { authedFetch } from '../../dataProvider/authedFetch'
import LocalStorage, { INSIGHTS_STORAGE_KEY } from '../../datagrid/LocalStorage'
import { GRAFANA_DASHBOARD_URLS, SMPL_GRAFANA_PATH } from '../../lib/config'

const useStyles = makeStyles({
  box: {
    marginTop: '20px',
    height: 'calc(100vh - 180px)',
    overflow: 'auto',
  },
  select: {
    width: '300px',
    marginBottom: '10px',
  },
  iframeCard: {
    width: '100%',
    height: 'calc(100% - 50px)',
    position: 'relative',
  },
  iframe: {
    border: 0,
    height: '100%',
    width: '100%',
    left: 0,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
  },
})

export const InsightsGrafana = () => {
  const storage = LocalStorage
  /**
   * @type Array<{label: string, value: string}>
   */
  const grafanaUrlOptions =
    typeof GRAFANA_DASHBOARD_URLS === 'string'
      ? JSON.parse(GRAFANA_DASHBOARD_URLS)
      : null

  let selectedUrlOption = storage.get(INSIGHTS_STORAGE_KEY, 'selectedUrlOption')

  if (selectedUrlOption && grafanaUrlOptions) {
    // check if it is also inside grafanaUrlOptions
    selectedUrlOption = grafanaUrlOptions.find(
      (op) =>
        op.label === selectedUrlOption.label && op.url === selectedUrlOption.url
    )

    // something changed since last time (label, url or completly removed)
    if (selectedUrlOption === undefined) {
      selectedUrlOption = grafanaUrlOptions[0]
    }
  } else if (grafanaUrlOptions) {
    selectedUrlOption = grafanaUrlOptions[0]
  }

  const [currentIframeUrl, setCurrentIframeUrl] = useState(
    JSON.stringify(selectedUrlOption)
  )
  const classes = useStyles()

  const handleChange = (event) => {
    storage.set(
      INSIGHTS_STORAGE_KEY,
      'selectedUrlOption',
      JSON.parse(event.target.value)
    )
    setCurrentIframeUrl(event.target.value)
  }

  const [loadingState, setLoadingState] = useState('LOADING')

  useEffect(() => {
    authedFetch(SMPL_GRAFANA_PATH, {
      credentials: 'include', // sets smpl.grafana.session cookie
    })
      .then((res) => {
        setLoadingState('READY')
      })
      .catch((err) => {
        console.error('Failed to login to grafana', err)
        setLoadingState('ERROR')
      })
  }, [])

  return (
    <div>
      {/* <Card controls={false}>
        <CardContent>
          <Typography gutterBottom variant="h6" component="h2">
            SMPL Insights
          </Typography>
        </CardContent>
      </Card> */}

      {'LOADING' === loadingState ? (
        <Card controls={false} className={classes.box}>
          <CardContent>
            <div style={{ margin: 'auto', width: '40px' }}>
              <CircularProgress />
            </div>
          </CardContent>
        </Card>
      ) : null}

      {'ERROR' === loadingState ? (
        <div className={classes.box}>
          <ErrorCard>
            Error while displaying Insights. Please try again.
          </ErrorCard>
        </div>
      ) : null}

      {'READY' === loadingState && currentIframeUrl ? (
        <div className={classes.box}>
          <Select
            className={classes.select}
            value={currentIframeUrl}
            onChange={handleChange}
          >
            {grafanaUrlOptions.map((op) => {
              return (
                <MenuItem key={op.label} value={JSON.stringify(op)}>
                  {op.label}
                </MenuItem>
              )
            })}
          </Select>
          <Card controls={false} className={classes.iframeCard}>
            <iframe
              className={classes.iframe}
              title="Grafana Dashboard"
              src={JSON.parse(currentIframeUrl).url}
            ></iframe>
          </Card>
        </div>
      ) : null}
    </div>
  )
}
