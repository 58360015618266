import { ListContextProvider } from 'ra-core'
import { ListView } from 'ra-ui-materialui'
import { useListController } from 'react-admin'

export function LocalStateDataList(props: any) {
  const controllerProps = useListController(props)

  return (
    <ListContextProvider value={controllerProps}>
      <ListView {...controllerProps} {...props} />
    </ListContextProvider>
  )
}
