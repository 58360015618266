import { Theme, useMediaQuery } from '@material-ui/core'
import { FieldProps } from 'react-admin'
// import { PrismAsyncLight as SyntaxHighlighter } from 'react-syntax-highlighter'
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter'
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json'

import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { Token } from '../../utils/Token'

SyntaxHighlighter.registerLanguage('json', json)

type Props = FieldProps & {
  showContent?: boolean
  customStyle?: Object
}

const style = {
  maxHeight: '500px',
  //  don't set width here or it 'bleed' over other component if this component is not used inside a customTab
  overflow: 'auto',
  background: '#1e1e1e',
  color: 'black',
  borderRadius: 5,
  padding: 10,
  width: 300,
}

const moreSpace = {
  width: 600,
}

export function JsonField({ showContent, ...props }: Props) {
  const { source, record, customStyle } = props

  if (!source || !record || record[source] === undefined) {
    console.error(
      'JsonField, trying to render a field that is not in the record',
      source,
      record
    )
    return null
  }

  const data = record[source]
  const formatted =
    data && typeof data === 'string'
      ? JSON.stringify(JSON.parse(data), null, 2)
      : JSON.stringify(data, null, 2)
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  if (showContent) {
    return (
      <pre
        style={
          customStyle
            ? customStyle
            : {
                ...style,
                ...(source === 'metadata' && !isMd ? moreSpace : {}),
              }
        }
      >
        {/* <Typography variant="caption">{formatted || ''}</Typography> */}
        <SyntaxHighlighter language="json" style={vs2015}>
          {formatted || ''}
        </SyntaxHighlighter>
      </pre>
    )
  } else {
    return (
      <Token color="primary" variant="light" title={formatted}>
        JSON
      </Token>
    )
  }
}
