import { Button, CardActions, Tooltip } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp'
import ImageEye from '@material-ui/icons/RemoveRedEye'
import SearchIcon from '@material-ui/icons/Search'
import arrayMove from 'array-move'
import { useRefresh } from 'ra-core'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import {
  FieldProps,
  useDataProvider,
  useMutation,
  useNotify,
} from 'react-admin'
import { SortableContainer, SortableElement } from 'react-sortable-hoc'
import { DragHandle } from '../../datagrid/SelectionDialog'
import { CreateModal } from '../CreateModal'
import { DataGridModal } from '../renderInput'

export const useStyles = makeStyles(() => ({
  container: {
    width: 'calc(85vw - 240px)',
    display: 'flex',
    flexDirection: 'column',
  },
  containerSort: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '0.25rem',
  },
  // INFO giving items a variable width would lead to 'jumping' behavior of the last element in row
  containerItem: {
    position: 'relative',
    listStyleType: 'none',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    background:
      'linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0.05) 100%)',
    // backdropFilter: 'blur(10px)',
    paddingLeft: '0',
    paddingRight: '1.5rem',
    borderRadius: '0.5rem',
    '&:hover': {
      background:
        'linear-gradient(0deg, rgba(0,0,0,0.15) 0%, rgba(0,0,0,0.1) 100%)',
      '& img': {
        filter: 'grayscale(0)',
        opacity: 1,
      },
      '& svg': {
        opacity: 0.75,
      },
    },
  },
  itemTextContainer: {
    flex: '1',
    display: 'flex',
    justifyContent: 'space-around',
  },
  itemText: {
    // display: 'inline-block',
    width: '30%',
    textAlign: 'center',
  },
  actionBar: {
    paddingLeft: '100px',
  },
  sortableHelper: {
    zIndex: 100,
  },
  paper: {
    backgroundColor: 'white',
    width: '80vw',
    height: '80vh',
    left: '10vw',
    top: '10vh',
    overflow: 'auto',
  },
  tinyMenu: {
    display: 'flex',
    flexDirection: 'column',
    height: '80px',
    justifyContent: 'center',
    borderRadius: '0.5rem',
  },
  arrowButtons: {
    height: '40px',
    '& svg': {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%) scale(1)',
      transition: '0.4s',
    },
    '&:hover': {
      '& svg': {
        transform: 'translate(-50%, -50%) scale(1.5)',
      },
    },
  },
}))

// INFO: root categories are categories that we don't want to have parents (may cause loop and stuff)
// include featured as root?
export const rootCat = [
  'start-homepage',
  'movie-homepage',
  'series-homepage',
  'start-filmtastic', // <-- homepage missing therefore regex for 'homepage' not possible right now
  'movie-homepage-filmtastic',
  'series-homepage-filmtastic',
]

const openNewTab = (props: { contentResourceType: any; contentId: any }) => {
  const { contentResourceType, contentId } = props
  const hostname = window.location.origin
  const pathname = '/' + contentResourceType + '/' + contentId + '/show'
  const url = hostname + pathname
  window.open(url, '_blank')
}

export const CustomShowButton = (props: {
  contentResourceType: any
  contentId: any
}) => {
  // open new Tab on click on Show
  return (
    <Button color="primary" size="small" onClick={() => openNewTab(props)}>
      <ImageEye style={{ paddingRight: 5 }} /> Show
    </Button>
  )
}

const SortContainer = SortableContainer<{
  children: ReactNode
}>(({ children }: any) => {
  const classes = useStyles()
  return <ul className={classes.containerSort}>{children}</ul>
})

const RemoveAsSubCategoryButton = (props: {
  childCategory: {
    [fieldName in string]: string
  }
  contentResourceType: string
}) => {
  const { childCategory, contentResourceType } = props
  const [mutate, { data, loaded }] = useMutation()
  const refresh = useRefresh()

  useEffect(() => {
    if (data !== null && loaded === true) {
      // mutation finish
      refresh()
    }
  }, [refresh, mutate, data, loaded])

  return (
    <Button
      color="primary"
      size="small"
      onClick={() => {
        mutate({
          type: 'update',
          resource: contentResourceType,
          payload: {
            id: childCategory.id,
            data: {
              ...childCategory,
              parentId: null,
              sortOrder: null,
            },
            previousData: childCategory,
          },
        })
      }}
    >
      <DeleteIcon color="error" style={{ paddingRight: 5 }} /> Remove
    </Button>
  )
}

type SortableItemProps = {
  value: {
    [fieldName in string]: string
  }
  toTop: (itemIndex: number) => void
  toBottom: (itemIndex: number) => void
  itemIndex: number
}

export const SortableItem = SortableElement<SortableItemProps>(
  (props: SortableItemProps) => {
    const classes = useStyles()
    const { value, toBottom, toTop, itemIndex } = props

    return (
      <>
        <li className={classes.containerItem}>
          <div className={classes.tinyMenu}>
            <Tooltip title="Move item to top" placement="left">
              <Button
                color="primary"
                onClick={() => toTop(itemIndex)}
                startIcon={<KeyboardArrowUpIcon />}
                className={classes.arrowButtons}
                style={{ borderRadius: '0.5rem 0 0 0' }}
              />
            </Tooltip>
            <Tooltip title="Move item to bottom" placement="left">
              <Button
                color="primary"
                onClick={() => toBottom(itemIndex)}
                startIcon={<KeyboardArrowDownIcon />}
                className={classes.arrowButtons}
                style={{ borderRadius: '0 0 0 0.5rem' }}
              />
            </Tooltip>
          </div>
          <DragHandle />

          <div className={classes.itemTextContainer}>
            <div className={classes.itemText}>{value.sortOrder}</div>
            <div className={classes.itemText}>{value.title}</div>
            <div className={classes.itemText}>{value.slug}</div>
            <div className={classes.itemText}>{value.platform}</div>
          </div>

          <CustomShowButton
            contentResourceType={value.__typename}
            contentId={value.id}
          />
          {/* TODO: add delete icon */}
          <RemoveAsSubCategoryButton
            childCategory={value}
            contentResourceType={value.__typename}
          />
        </li>
      </>
    )
  }
)

export const SortableListFieldsCategories = (props: FieldProps) => {
  const { record } = props
  const { id: parentId, __typename: resource } = record!
  const [refetch, setRefetch] = useState(true)
  const [queriedData, setQueriedData] = useState<
    Array<{ [fieldName in string]: any }>
  >()
  const [open, setOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [selectedId, setSelectedId] = useState(null)
  const [{ items }, setItems] = useState<{
    items: Array<{ [fieldName in string]: any }> | null
  }>({ items: null })
  const [mutate, { data, loaded }] = useMutation()
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const referenceTypeName = 'CmsCategory'

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  const openCreateModal = () => {
    setCreateOpen(true)
  }

  const closeCreateModal = () => {
    setCreateOpen(false)
  }

  const allCategoriesQuery = useRef()

  // put item from specific index top of the list
  const toTop = async (itemIndex: number) => {
    const element = document.querySelectorAll('li')
    element[itemIndex].classList.add('sortableListUpwards')
    setTimeout(() => {
      element[itemIndex].classList.remove('sortableListUpwards')
    }, 500)
    await new Promise((r) => setTimeout(r, 500))
    if (itemIndex === 0) {
      notify('item is already on top of the list', 'warning')
    }
    const itemToMoveOnTop = items?.splice(itemIndex, 1)
    if (itemToMoveOnTop && items) {
      setItems({ items: [...itemToMoveOnTop, ...items] })
    } else {
      notify('An error occurred. Please refresh the page.', 'warning')
    }
  }

  // put item from specific index bottom of the list
  const toBottom = async (itemIndex: number) => {
    const element = document.querySelectorAll('li')
    element[itemIndex].classList.add('sortableListDownwards')
    setTimeout(() => {
      element[itemIndex].classList.remove('sortableListDownwards')
    }, 500)
    await new Promise((r) => setTimeout(r, 500))
    if (items) {
      if (itemIndex === items.length - 1) {
        notify('item is already on bottom of the list', 'warning')
      }
      const itemToMoveOnBottom = items?.splice(itemIndex, 1)
      if (itemToMoveOnBottom) {
        setItems({ items: [...items, ...itemToMoveOnBottom] })
      }
    } else {
      notify('An error occurred. Please refresh the page.', 'warning')
    }
  }

  useEffect(() => {
    if (data !== null && loaded === true) {
      // mutation finish
      refresh()
    }
    if (selectedId) {
      dataProvider
        .getList(resource, {
          // @ts-ignore
          pagination: {},
          filter: {},
          sort: { field: 'createdDate', order: 'DESC' },
        })
        .then(({ data }: any) => {
          allCategoriesQuery.current = data
        })
        .then(() => {
          // @ts-ignore
          let previousElem = allCategoriesQuery!.current!.find(
            (e: { [fieldName in string]: any }) => e.id === selectedId
          )

          if (previousElem) {
            // prevent adding the 3 root categories and itself as subcategory
            if (
              rootCat.includes(previousElem.slug) ||
              previousElem.id === parentId
            ) {
              notify(
                'Can not add root categories or itself as subcategory.',
                'warning'
              )
              return
            }

            // if the selected category already has parentId === parentId
            if (previousElem.parentId === parentId) {
              refresh()
              return
            }

            // TODO: prevent adding cross origin? e.g. serien-horror as subcategory of movie-homepage

            // write/overwrite parentId of the selected category
            mutate({
              type: 'update',
              resource: resource,
              payload: {
                id: previousElem.id,
                data: { ...previousElem, parentId: parentId },
                previousData: previousElem,
              },
            })
          } else {
            // since we fetch the latest data this should never happens, but who knows
            notify('An error occurred. Please refresh the page.', 'warning')
          }
        })
        .catch((error: any) => console.error(error))
    }
  }, [
    allCategoriesQuery,
    selectedId,
    data,
    refresh,
    loaded,
    mutate,
    notify,
    parentId,
    resource,
    dataProvider,
  ])

  // on refresh: fetching data to render
  useEffect(() => {
    if (refetch) {
      dataProvider
        .getList(resource, {
          sort: { field: 'sortOrder', order: 'ASC' },
          filter: {
            // don't know why this don't works
          },
          // @ts-ignore
          pagination: {},
        })
        .then(({ data }: any) => {
          // filter out Categories with specified parentId
          const rootCategories = data.filter((cat: any) => {
            return cat.parentId === parentId
          })
          setQueriedData(rootCategories)
        })
        .catch((error: any) => console.error(error))
    }
    setRefetch(false)
  }, [dataProvider, queriedData, refetch, resource, parentId])

  useEffect(() => {
    // sortOrder is ASC therefore the data array will have elem with sortOrder=1 at the beginning
    if (typeof queriedData !== 'undefined' && !refetch && items === null) {
      queriedData.forEach((elem: any) => {
        const currentItem = {
          ...elem,
          sortOrder:
            elem.sortOrder !== null
              ? elem.sortOrder
              : queriedData.indexOf(elem) + 1,
        }
        setItems(({ items }) => {
          if (items) {
            return { items: [...items, currentItem] }
          }
          return { items: [currentItem] }
        })
      })
    }
  }, [queriedData, refetch, items])

  const onSortEnd = ({ oldIndex, newIndex }: any) => {
    setItems(({ items }) => {
      if (items) {
        return { items: arrayMove(items, oldIndex, newIndex) }
      }
      return { items }
    })
  }

  const fakeActionProps: FakeSortListActionsProps = {
    basePath: '/' + resource,
    hasCreate: true,
    openModal: openModal,
    openCreateModal: openCreateModal,
  }

  if (!items)
    return (
      <div className={classes.container}>
        <FakeSortListActions {...fakeActionProps} />
        {/* Modal for creating a Category*/}
        <CreateModal
          open={createOpen}
          onClose={closeCreateModal}
          onCloseDataGridModal={closeModal}
          resource={referenceTypeName}
          hasEdit
          hasList
          hasShow
          record={{}}
          setFunction={setSelectedId}
        />
        {/* Modal for adding a Category*/}
        <DataGridModal
          open={open}
          onClose={closeModal}
          resource={referenceTypeName}
          basePath={`/${referenceTypeName}`}
          location={{
            hash: '',
            pathname: `/${referenceTypeName}`,
            search: '',
            state: null,
          }}
          calledFromModal={true}
          setFunction={setSelectedId}
          openedModal={setOpen}
          hasCreateInModal={true}
          onOpenCreateModal={openCreateModal}
        />
      </div>
    )

  if (items && queriedData) {
    const actionProps: SortListActionsProps = {
      items: items,
      previousData: queriedData,
      resource: resource,
      hasCreate: true,
      openModal: openModal,
      openCreateModal: openCreateModal,
    }

    return (
      <>
        <div className={classes.container}>
          <SortListActions {...actionProps} />
          <SortContainer
            onSortEnd={onSortEnd}
            useDragHandle
            helperClass={classes.sortableHelper}
          >
            {items.map((value, index) => (
              <SortableItem
                toTop={toTop}
                toBottom={toBottom}
                key={value.id}
                index={index}
                itemIndex={index}
                value={value}
              />
            ))}
          </SortContainer>
        </div>
        {/* Modal for creating a Category*/}
        <CreateModal
          open={createOpen}
          onClose={closeCreateModal}
          onCloseDataGridModal={closeModal}
          resource={referenceTypeName}
          hasEdit
          hasList
          hasShow
          record={{}}
          setFunction={setSelectedId}
        />
        {/* Modal for adding a Category*/}
        <DataGridModal
          open={open}
          onClose={closeModal}
          resource={referenceTypeName}
          basePath={`/${referenceTypeName}`}
          location={{
            hash: '',
            pathname: `/${referenceTypeName}`,
            search: '',
            state: null,
          }}
          calledFromModal={true}
          setFunction={setSelectedId}
          openedModal={setOpen}
          hasCreateInModal={true}
          onOpenCreateModal={openCreateModal}
        />
      </>
    )
  }

  return <div></div>
}

type FakeSortListActionsProps = {
  hasCreate: boolean
  openModal: Function
  basePath: string
  openCreateModal: Function
}

const FakeSortListActions = (props: FakeSortListActionsProps) => {
  const { hasCreate, openModal, openCreateModal } = props
  const classes = useStyles()
  return (
    <CardActions className={classes.actionBar}>
      <Button
        aria-label="Save to database"
        color="primary"
        variant="contained"
        size="small"
        disabled
      >
        Save Order
      </Button>
      <AddSubCat openModal={openModal} />
      {hasCreate ? <CreateSubCat openCreateModal={openCreateModal} /> : null}
      {/* <ExportButton {...props} /> */}
    </CardActions>
  )
}

type SortListActionsProps = {
  items: Array<{ [fieldName in string]: any }>
  previousData: Array<{ [fieldName in string]: any }>
  resource: string
  hasCreate: boolean
  openModal: Function
  openCreateModal: Function
}

const SortListActions = (props: SortListActionsProps) => {
  const {
    items,
    previousData,
    resource,
    hasCreate,
    openModal,
    openCreateModal,
  } = props
  const [mutate] = useMutation()
  const notify = useNotify()
  const classes = useStyles()
  const refresh = useRefresh()

  // saving order
  const update = useCallback(
    async (
      currentOrder: number,
      previousElem: { [fieldName in string]: any }
    ) => {
      mutate({
        type: 'update',
        resource: resource,
        payload: {
          id: previousElem.id,
          data: { ...previousElem, sortOrder: currentOrder },
          previousData: previousElem,
        },
      })
    },
    [mutate, resource]
  )

  // saving order
  const updateDatabase = useCallback(async () => {
    let updated = false // is there at least 1 change in order?
    const promises = items.map(async (item) => {
      const index = items.indexOf(item)
      const previousElem = previousData.find(
        (e: { [fieldName in string]: any }) => e.id === item.id
      )
      if (
        previousElem &&
        (previousElem.sortOrder === null ||
          index + 1 !== previousElem.sortOrder)
      ) {
        const currentOrder = index + 1
        updated = true
        return await update(currentOrder, previousElem)
      }
    })

    await Promise.all(promises).then(() => {
      if (updated) {
        notify('Changes to the sort order have been saved.')
      } else {
        notify('No changes in the sort order.', 'warning')
      }
    })

    refresh()
  }, [items, previousData, update, notify, refresh])

  return (
    <CardActions className={classes.actionBar}>
      <Button
        aria-label="Save to database"
        onClick={updateDatabase}
        color="primary"
        variant="contained"
        size="small"
      >
        Save Order
      </Button>
      <AddSubCat openModal={openModal} />
      {hasCreate ? <CreateSubCat openCreateModal={openCreateModal} /> : null}
      {/* <ExportButton {...props} /> */}
    </CardActions>
  )
}

export const CreateSubCat = ({
  openCreateModal,
}: {
  openCreateModal: Function
}) => {
  return (
    <Button
      aria-label="Open Selection Modal"
      color="primary"
      onClick={() => openCreateModal()}
      size="small"
      startIcon={<AddIcon />}
    >
      Create
    </Button>
  )
}

export const AddSubCat = ({
  openModal,
  label,
}: {
  openModal: Function
  label?: string
}) => {
  return (
    <Button
      aria-label="Open Selection Modal"
      color="primary"
      onClick={() => openModal()}
      size="small"
      startIcon={<SearchIcon />}
    >
      {label ?? 'Add'}
    </Button>
  )
}
