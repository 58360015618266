import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Typography,
} from '@material-ui/core'
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
import PropTypes from 'prop-types'
import React from 'react'
import { LinkButton } from '../utils/LinkButton'
import { hasComponent } from './AppPageLoader'

const nl2p = (text) => {
  // text is now with "\n" instead of a real line break character...
  return (text || '').split(/\n|\\n/).map((line, index) => (
    <Typography component="p" key={index}>
      {line}
    </Typography>
  ))
}

const URL_REGEX = /^(?:https?:)?\/\//

function AppPanelImpl({ app, app: { shortKey, state }, style = {} }) {
  const canShowLink = state === 'enabled' && hasComponent(shortKey)

  const isAlpha = app.state === 'alpha'
  const imgStyle = {
    ...(isAlpha ? { filter: 'grayscale(50%)' } : {}),
    ...{
      width: '100%',
      //   maxWidth: '50%',
      height: 150,
      marginTop: 12,
      backgroundSize: 'contain',
      display: 'flex',
    },
  }

  const padding = '5px 30px'
  const stateHelper = {
    color: red[500],
    label: 'Deactivated',
  }

  if (isAlpha) {
    stateHelper.color = orange[500]
    stateHelper.label = 'Work in Progress'
  } else if (app.state === 'enabled') {
    stateHelper.color = green[500]
    stateHelper.label = 'Activated'
  }
  const { imageUrl } = app
  return (
    <Card
      style={{
        ...(isAlpha ? { opacity: 0.5, cursor: 'not-allowed' } : null),
        ...style,
        width: '100%',
        maxWidth: 300,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardMedia
        image={
          imageUrl
            ? URL_REGEX.test(imageUrl)
              ? imageUrl
              : process.env.PUBLIC_URL + '/' + imageUrl
            : 'imgs/app/apps/dummy.png'
        }
        style={imgStyle}
      />
      <CardHeader
        style={{ marginTop: 20, marginBottom: 20, padding }}
        title={app.name || app.shortKey}
      />
      <CardContent
        style={{
          display: 'flex',
          padding,
          flexGrow: 1,
          flexDirection: 'column',
          marginBottom: 12,
        }}
      >
        {nl2p(app.description)}
      </CardContent>
      <div style={{ display: 'flex', flex: 1 }} />
      <CardActions
        style={{
          padding,
          paddingRight: canShowLink ? 5 : null,
          color: 'white',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          background: stateHelper.color,
        }}
      >
        <Typography component="p" style={{ color: 'white' }}>
          {stateHelper.label}
        </Typography>
        {canShowLink ? (
          <LinkButton
            to={`/apps/${app.shortKey}`}
            variant="text"
            style={{ color: 'white' }}
          >
            Open
          </LinkButton>
        ) : (
          // placeholder for correct height spacing
          <Button disabled> </Button>
        )}
      </CardActions>
    </Card>
  )
}

export const AppPanel = React.memo(AppPanelImpl)

AppPanel.propTypes = {
  app: PropTypes.object.isRequired,
  style: PropTypes.object,
}
