// source https://github.com/fizix-io/ra-customizable-datagrid
import filter from 'lodash/filter'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import T from 'prop-types'
import React, { Component } from 'react'
import { Datagrid } from 'react-admin'
import {
  DefaultColumnSetting,
  DefaultColumnSettingVersion,
} from '../genericData/settings/DefaultColumnSetting'
import { filterNotEmpty } from '../lib/filterNonEmpty'
import { DatagridHeader } from './DatagridHeader'
import LocalStorage, {
  DATAGRID_STORAGE_DEFAULT_VERSION_KEY,
  DATAGRID_STORAGE_KEY,
} from './LocalStorage'
import { SelectionDialog } from './SelectionDialog'

const arrayToSelection = (values) =>
  values.reduce((selection, columnName) => {
    selection[columnName] = true
    return selection
  }, {})

const checkEqualArrays = (defaultCol, previousCol) => {
  if (JSON.stringify(defaultCol) === JSON.stringify(previousCol)) {
    return true
  }
  return false
}

// CustomizableDatagrid allows to show/hide columns dynamically
// the preferences are stored in local storage
class CustomizableDatagrid extends Component {
  constructor(props) {
    super(props)
    this.state = {
      modalOpened: false,
      selection: this.getInitialSelection(),
    }
  }

  getColumnNames() {
    const { children } = this.props
    return filter(
      React.Children.map(children, (field) => get(field, ['props', 'source']))
    )
  }

  getColumnLabels() {
    const { children } = this.props
    return filter(
      React.Children.map(
        children,
        (field) =>
          field && {
            source: get(field, ['props', 'source']),
            label: get(field, ['props', 'label']),
          }
      ),
      (item) => item && item.source
    )
  }

  getInitialSelection() {
    const { defaultColumns, resource, storage, fromReference } = this.props
    const res = fromReference || resource

    // there are default setting, return it
    if (
      !storage.getVersion(DATAGRID_STORAGE_DEFAULT_VERSION_KEY) ||
      parseFloat(storage.getVersion(DATAGRID_STORAGE_DEFAULT_VERSION_KEY)) !==
        DefaultColumnSettingVersion
    ) {
      storage.setVersion(
        DATAGRID_STORAGE_DEFAULT_VERSION_KEY,
        DefaultColumnSettingVersion
      )
      if (DefaultColumnSetting[res] !== undefined) {
        const customDefaultSelection = DefaultColumnSetting[res]
        storage.set(DATAGRID_STORAGE_KEY, res, customDefaultSelection)
        return customDefaultSelection
      }

      // can't show user that it was changed
      // useNotify hook doens't work here, applying connect from react-redux will break at another position
    }

    let previousSelection = storage.get(DATAGRID_STORAGE_KEY, res)
    // if we have a previously stored value, let's return it
    if (!isEmpty(previousSelection)) {
      const previousSelectionArray = Object.keys(previousSelection).filter(
        // following fields are hidden in default but may selected by user
        (key) =>
          key !== 'createdById' &&
          key !== 'lastModifiedById' &&
          key !== 'lastModifiedDate' &&
          key !== 'id'
      )

      if (checkEqualArrays(defaultColumns, previousSelectionArray)) {
        return previousSelection
      }

      // example default = [a,b,c,d], previous = [a,b,x,y,z,], previous = [a,b,x] or previous = [a,b]
      let modifiedSelection = { ...previousSelection }

      // delete no longer existing fields from selection
      for (var y = 0; y !== previousSelectionArray.length; y++) {
        if (!defaultColumns.includes(previousSelectionArray[y])) {
          delete modifiedSelection[previousSelectionArray[y]]
        }
      }

      // adding fields that is new
      for (var i = 0; i !== defaultColumns.length; i++) {
        if (!(defaultColumns[i] in previousSelection)) {
          modifiedSelection[defaultColumns[i]] = true
        }
      }

      // update local storage and return
      storage.set(DATAGRID_STORAGE_KEY, res, modifiedSelection)
      return modifiedSelection
    }

    // if defaultColumns are set let's return them
    if (!isEmpty(defaultColumns)) {
      const defaultSelection = arrayToSelection(defaultColumns)
      storage.set(DATAGRID_STORAGE_KEY, res, defaultSelection)
      return defaultSelection
    }

    // otherwise we fallback on the default behaviour : display all columns
    return arrayToSelection(this.getColumnNames())
  }

  // updates the storage with the internal state value
  updateStorage = () => {
    const { resource, fromReference } = this.props
    const res = fromReference || resource
    this.props.storage.set(DATAGRID_STORAGE_KEY, res, this.state.selection)
  }

  toggleColumn = (columnName) => {
    const { storage, resource, fromReference } = this.props
    const res = fromReference || resource
    const previousSelection = storage.get(DATAGRID_STORAGE_KEY, res)
    const selection = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName],
    }
    this.setState({ selection }, this.updateStorage)
  }

  handleOpen = () => this.setState({ modalOpened: true })
  handleClose = () =>
    this.setState({ modalOpened: false, selection: this.getInitialSelection() })

  renderChild = (child) => {
    const source = get(child, ['props', 'source'])
    const { selection } = this.state

    // Show children without source, or children explicitly visible
    if (!source || selection[source]) {
      return React.cloneElement(child, {})
    }

    return null
  }

  render() {
    const {
      children,
      defaultColumns,
      storage,
      resource,
      fromReference,
      ...rest
    } = this.props
    const { selection, modalOpened } = this.state

    const allChildren = children.flat().filter(filterNotEmpty)

    let renderableChildren = allChildren.filter((child) => child.key === null)
    let sortedRenderableChildren = []
    // get sorted renderable children based on selection
    Object.keys(selection).forEach((element) => {
      const child = allChildren.find((child) => child.key === element)
      if (child) sortedRenderableChildren.push(child)
    })
    renderableChildren.push(sortedRenderableChildren)
    return (
      <>
        {modalOpened && (
          <SelectionDialog
            selection={selection}
            onColumnClicked={this.toggleColumn}
            onClose={this.handleClose}
            resource={fromReference || resource}
            storage={storage}
            label="Column Configuration"
          />
        )}
        <Datagrid header={DatagridHeader} {...rest}>
          {React.Children.map(renderableChildren, this.renderChild)}
        </Datagrid>
      </>
    )
  }
}

CustomizableDatagrid.propTypes = {
  defaultColumns: T.arrayOf(T.string),
  storage: T.shape({
    get: T.func.isRequired,
    set: T.func.isRequired,
  }),
  fromReference: T.string,
}

CustomizableDatagrid.defaultProps = {
  defaultColumns: [],
  storage: LocalStorage,
  fromReference: undefined,
}

export default CustomizableDatagrid
