import { Tooltip } from '@material-ui/core'
import AllInboxIcon from '@material-ui/icons/AllInbox'
import WarningIcon from '@material-ui/icons/Warning'
import { motion } from 'framer-motion'
import { MenuItemLink } from 'react-admin'
import { monitoringTags } from '../commons/flexgold/constants'
import { filterPresetRessourceMapping } from '../genericData/filterBuilder/filterPresetDefinitions'

const styles = {
  sublink: {
    display: 'flex',
    width: 'calc(100% - 0.75rem)',
    margin: '0.06rem 0.5rem',
    padding: '1rem 0.5rem 1rem 2rem',
    borderRadius: '0.5rem',
    height: 64,
  },
  sublinkWithIcon: {
    margin: '0.06rem 0.5rem',
    padding: '1rem 0.5rem',
    background: '#00000008',
    width: 'calc(100% - 0.8rem)',
    borderRadius: '0.5rem',
    height: 64,
  },
}

const NO_FILTER = 'NO_FILTER'

export const taggedMonitoringUserJobMenus: {
  label: string
  filter: undefined | string
}[] = [
  { label: 'All Tags', filter: 'allTaggedIdentificationData' },
  {
    label: monitoringTags.depositsOver500k,
    filter: 'depositOver500kTaggedIdentificationData',
  },
  {
    label: monitoringTags.freqDepositsAndPayouts,
    filter: 'frequentDepositsAndPayoutsTaggedIdentificationData',
  },
  {
    label: monitoringTags.depostisOutScale5xSalary,
    filter: 'depositsOutScale5xSalaryTaggedIdentificationData',
  },
  {
    label: monitoringTags.highRiskIndustry,
    filter: 'highRiskIndustryTaggedIdentificationData',
  },
  {
    label: monitoringTags.pep,
    filter: 'pepTaggedIdentificationData',
  },
  {
    label: monitoringTags.noAmlProof,
    filter: 'noAmlTaggedIdentificationData',
  },
  {
    label: monitoringTags.freqAddressChange,
    filter: 'frequentAddressChangeTaggedIdentificationData',
  },
  {
    label: monitoringTags.highRiskOther,
    filter: 'highRiskOtherTaggedIdentificationData',
  },
]

export const renderTaggedMonitoringUsersMenus = () => {
  const isAllJobsPageActive =
    location.href.includes('allTaggedIdentificationData') &&
    Object.keys(filterPresetRessourceMapping).every(
      (resource) =>
        !location.href.includes(
          filterPresetRessourceMapping.IdentificationDatum[resource]
        )
    )
  return taggedMonitoringUserJobMenus.map((menu, i) => {
    return (
      <motion.div
        key={menu.label}
        initial={{ x: -150 }}
        animate={{ x: 0 }}
        transition={{ delay: i / 25 }}
      >
        <Tooltip
          key={menu.label}
          placement="right"
          title={
            menu.label
            //   smplTexts.menu[name]?.tooltip ? smplTexts.menu[name].tooltip : ''
          }
        >
          <MenuItemLink
            key={menu.label}
            to={{
              pathname: '/IdentificationDatum',
              search:
                menu.filter === NO_FILTER
                  ? 'filter={}' // disable potentially previously set filters in case of navigating from a transcodingjob menu page with a preset filter
                  : `filter=%7B"__preset"%3A%7B"matches"%3A%5B"${menu.filter}"%5D%7D%7D`,
            }}
            leftIcon={
              menu.label === 'All Tags' ? (
                <AllInboxIcon />
              ) : menu.label === monitoringTags.depositsOver500k ? (
                <WarningIcon />
              ) : menu.label === monitoringTags.freqDepositsAndPayouts ? (
                <WarningIcon />
              ) : menu.label === monitoringTags.depostisOutScale5xSalary ? (
                <WarningIcon />
              ) : menu.label === monitoringTags.highRiskIndustry ? (
                <WarningIcon />
              ) : menu.label === monitoringTags.pep ? (
                <WarningIcon />
              ) : menu.label === monitoringTags.noAmlProof ? (
                <WarningIcon />
              ) : menu.label === monitoringTags.freqAddressChange ? (
                <WarningIcon />
              ) : menu.label === monitoringTags.highRiskOther ? (
                <WarningIcon />
              ) : null
            }
            primaryText={`${menu.label}`}
            style={styles.sublink}
            isActive={() => {
              // Sub menus for the same resource get set as active, despite different keys. This is hacky and error prone work around.
              // TODO: improve logic for setting sub menu for the same resource (but different filters) as active
              return menu.filter !== NO_FILTER
                ? location.href.includes(menu.filter)
                : isAllJobsPageActive
            }}
          />
        </Tooltip>
      </motion.div>
    )
  })
}
