import { CustomerTheme } from './customerThemeType'

export const ARDTheme: Partial<CustomerTheme> = {
  primary: {
    main: '#003480',
    light: '#589eff',
    dark: '#002B6B',
  },
  secondary: {
    main: '#589eff',
  },
  // @ ts-expect-error
  // gray: {
  //   200: '#fff0ff',
  // },
}

export const ARDLogo = (
  <svg
    height="48"
    viewBox="0 0 805.3 220.7"
    style={{
      width: 100,
      height: 'auto',
      paddingLeft: 25,
    }}
  >
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M363.89 137.01V57.4l-79.92 28.74v19.87l26.49-9.63v59.75l53.43-19.12z"
    ></path>
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M333.42 11c-31.78 0-61.56 15.5-79.82 41.51l14.1 9.84c14.89-21.37 39.26-34.14 65.3-34.25 43.79 0 78.6 35.89 78.6 79.84s-34.81 79.84-78.6 79.84c-27 0-50.77-13.84-65.17-34.36l-14.35 10c17.52 25 46.08 41.41 79 41.41 53.6.04 97.1-43.38 97.14-96.98v-.02C429.57 54.3 386.93 11 333.42 11"
    ></path>
    <path
      fill="#fff"
      d="M210.59 63.12c-9.46 0-15.73.16-20.76.29-3.13.08-5.6.14-7.93.14h-.44V152l3.5.09c7 .17 14.88.36 22.82.36 30.52 0 46.65-15.64 46.65-45.24s-15.16-44.09-43.84-44.09m-1.14 74.11c-3.41.03-6.83-.15-10.22-.54V78.48c2.56-.15 6.34-.23 10.22-.23 17.65 0 26.24 9.7 26.24 29.67s-8.34 29.31-26.24 29.31M60 63.85l-.1-.3H37.71L7.48 152.19H25.4L31.19 134h33.42l5.84 18.22h19.67L60 63.85ZM36 119l8.6-27.15c1.16-3.62 2.32-7.82 3.21-11.56.83 3.2 1.95 7.11 3.35 11.65L59.82 119H36ZM150.62 121.69c-1.35-2.31-2.94-4.65-4.21-6.43 11.26-4 18-13 18-25.13 0-17.67-11.08-27-32-27-9.28 0-17.74.19-23.93.32-3.21.07-5.74.12-7.19.12h-.43v88.64h17.71v-33.8h9.8l20 33.8h21.3l-19.05-30.52Zm-32.08-43.4c1.36 0 5.93-.16 12.61-.16 9.77 0 14.53 4.16 14.53 12.71s-5.07 12.71-15.06 12.71h-12.08V78.29Z"
    ></path>
    <path
      fill="#589fff"
      fillRule="evenodd"
      d="M459.19 51.27v113.21h331.73V51.27H459.19Zm15.53 20.95 44.15 19.48-44.15 20.58V72.22Zm56 50.56c-3.94 0-8.27-.4-8.27-.4v29.81h-14.28v-43.81c7.81 1.11 10.8 1.52 12.37 1.73 3.73.58 7.49.91 11.26 1 13.26 0 20.09-7.87 20.09-18.38s-6.31-18-20.75-18c-3.71 0-8.36.25-12 .4s-11 .6-11 .6V63.57S521 63 532.45 63c23.24 0 34.14 11.82 34.14 28 0 17.86-13.13 31.78-35.84 31.78h-.03Zm94.67 29.41h-44.74V63.57H595V140h30.46l-.07 12.19Zm79.31-34.14c0 23.64-12.74 35.45-36 35.45-27.78 0-33.7-15.23-33.7-31.5V63.57h14.31v57.37c0 11.82 3.94 20.75 20.35 20.75 14.44 0 21-6.05 21-22.59V63.57h14.05v54.48Zm37.42 35.45c-6.81.06-13.6-.78-20.19-2.5l1.57-13.66c6.2 2.38 12.79 3.62 19.43 3.66 10 0 17.33-3.68 17.33-12.87 0-18.64-39.25-12.08-39.25-40.7 0-15 11.81-25.21 32-25.21 5.38.04 10.74.62 16 1.71l-1.44 12.6c-5-1.63-10.23-2.47-15.49-2.49-10.9 0-16.29 4.59-16.29 12 0 18 39.52 11.94 39.52 40.7.05 15.47-11.63 26.76-33.16 26.76h-.03Z"
    ></path>
  </svg>
)
