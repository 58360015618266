import React from 'react'

export class Spacer extends React.Component {
  render() {
    return (
      <div
        style={{
          display: 'flex',
          flexGrow: 1,
          flexShrink: 1,
          flexBasis: '100%',
          heigth: 0,
        }}
      />
    )
  }
}
