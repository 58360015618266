// import { createMuiTheme } from '@material-ui/core/styles'
import { createMuiTheme } from 'ra-ui-materialui'
import { theme } from './customerThemes'

export const darkTheme = {
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
  },
}

export const smplColors = theme

const smplThemeLight = createMuiTheme({
  palette: {
    type: 'light',
    text: {
      primary: smplColors.gray[900], //? MAIN TEXT COLOR
      secondary: smplColors.gray[800], //? MENU TEXT COLOR AND MORE
      disabled: smplColors.gray[500], //? IN USE
    },
    action: {
      active: smplColors.primary.main, //? USED BY OUR MOST OF OUR ICONS ( MOST IN THE MENU )
      hover: `${smplColors.primary.light}50!important`, //? HOVER COLOR FOR TABLE ENTRIES AND ALSO IN THE MENU (ACTUALLY OVERWRITTEN)
      selected: smplColors.primary.light, //! SEEMS LIKE ITS UNUSED
      disabled: '#555555', //? IN USE
      disabledBackground: '#cccccc30', //? IN USE
    },
    primary: {
      main: smplColors.primary.main, //? MAIN COLOR ACCENT
      light: smplColors.secondary.main, //? USED FOR TABLE COLUMN -> COSTUM DATA SOURCE
      dark: '#04471C', //! UNUSED
    },
    secondary: {
      main: smplColors.secondary.main, //? RERUN AUTOMATION TASK AND CONFIG CHECKBOX BG
      light: '#000000', //! UNUSED
      dark: '#000000', //! UNUSED
    },
    error: {
      main: smplColors.error.main, //? F.E. FILTER DELETE TRASHCAN ICON
      light: '#000000', //! UNUSED
      dark: '#000000', //! UNUSED
    },
    warning: {
      main: '#000000', //! UNUSED
      light: '#000000', //! UNUSED
      dark: '#000000', //! UNUSED
    },
    info: {
      main: '#000000', //! UNUSED
      light: '#000000', //! UNUSED
      dark: '#000000', //! UNUSED
    },
    success: {
      main: smplColors.success.main, //! UNUSED
      light: '#00a86b', //! UNUSED
      dark: '#00a86b', //! UNUSED
    },
    background: {
      default: smplColors.gray[200], //? OVERALL BACKGROUND
      paper: smplColors.gray[100], //? TABLES BACKGROUND
    },
    divider: smplColors.gray[8000], //? DIVIDER SEARCH RESULTS AND MENU
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: '0.9rem',
        background: `${smplColors.primary.main}!important`,
        color: 'white!important',
      },
    },
    MuiTab: {
      root: {},
      wrapper: {
        fontWeight: 900,
        fontSize: '0.8rem',
      },
    },
    MuiTabs: {
      root: {
        color: smplColors.primary.main,
      },
      indicator: {
        background: smplColors.secondary.main,
        '&::after': {
          content: '""',
          position: 'absolute',
          width: '100%',
          height: '200px',
          left: '50%',
          top: '-35px',
          transform: 'translate(-50%, -50%)',
          background: `${smplColors.secondary.main}55`,
          animation: 'pulsor 3s ease-in-out infinite',
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: '1.5rem 1.5rem 0 0',
      },
    },
    MuiAccordion: {
      root: {
        borderRadius: '1rem!important',
        boxShadow: '0.1rem 0.1rem 0.25rem #00000020',
        background: '#ffffff90',
      },
    },
    MuiAccordionSummary: {
      root: {
        textTransform: 'uppercase',
        '& h6': {
          fontSize: '0.75rem',
          color: smplColors.primary.main,
        },
      },
    },
    MuiButton: {
      contained: {
        '&:hover': {
          background: `${smplColors.secondary.main}!important`,
        },
      },
    },
  },
})

export const lightTheme = smplThemeLight
