import { useLayoutEffect, useState } from 'react'

// assumming different endpoints to different services all have different names
let accessibleEndpointCache: {
  [x: string]: boolean
} = {}

export function clearEndpointCache() {
  accessibleEndpointCache = {}
}

export function useEndpointAvailable(
  smplClient: (
    method: 'POST' | 'GET' | 'HEAD' | 'OPTIONS',
    endpoint: string,
    body?: Object,
    options?: RequestInit
  ) => Promise<Response>,
  endpointUrl: string,
  endpointMethod: 'POST' | 'GET' = 'POST'
) {
  const [available, setAvailable] = useState(
    accessibleEndpointCache[endpointUrl] ?? false
  )

  useLayoutEffect(() => {
    const checkAccessible = async () => {
      if (accessibleEndpointCache[endpointUrl] !== undefined) {
        return
      } else {
        try {
          const response = await smplClient(
            endpointMethod === 'POST' ? 'OPTIONS' : 'HEAD',
            endpointUrl,
            {}
          )
          if (response.ok) {
            setAvailable(true)
            accessibleEndpointCache[endpointUrl] = true
          } else {
            setAvailable(false)
            accessibleEndpointCache[endpointUrl] = false
          }
        } catch (error) {
          setAvailable(false)
          accessibleEndpointCache[endpointUrl] = false
        }
      }
    }

    checkAccessible()
  }, [endpointUrl])

  return available
}
