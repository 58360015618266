export async function resetPassword(props: {
  username: string
  customer?: string
  authServiceClient: (
    method: string,
    endpoint: string,
    body?: Object,
    options?: RequestInit
  ) => Promise<Response>
  endpoint: string
}) {
  const { authServiceClient, username, customer, endpoint } = props
  return await authServiceClient('POST', endpoint, {
    username,
    customer,
  })
}

export async function addSubscription(props: {
  userId: string
  numberOfDays: number
  contentAccess: string
  paymentServiceClient: (
    method: string,
    endpoint: string,
    body?: Object,
    options?: RequestInit
  ) => Promise<Response>
  endpoint: string
}) {
  const {
    contentAccess,
    numberOfDays,
    paymentServiceClient,
    userId,
    endpoint,
  } = props

  return paymentServiceClient('POST', endpoint, {
    userId,
    numberOfDays,
    contentAccess,
  })
}
