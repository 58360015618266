export function looksLikeSmplId(str: string) {
  // is a 15 len string with a at the beginning and no space
  if (str.length !== 15 && str.length !== 18) {
    return false
  }
  return /^[a-zA-Z0-9]{15}[a-zA-Z]{0,3}$/gm.test(str)
}

const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ012345'

export const toCaseSafeId = (input: string): string => {
  if (input.length != 15) {
    throw 'Cannot convert ids with len != 15'
  }

  let suffix = ''
  for (let i = 0; i < 3; i++) {
    let bitmask = 0
    for (let j = 0; j < 5; j++) {
      const char = input.charAt(i * 5 + j)
      if (char >= 'A' && char <= 'Z') {
        bitmask += 1 << j
      }
    }
    suffix += alphabet.charAt(bitmask)
  }
  return input + suffix
}

export const fromCaseSafeId = (input: string): string => {
  if (input.length != 18) {
    throw 'Cannot convert ids with len != 15'
  }

  let output = ''
  const suffix = input.substring(15, 18).toUpperCase()

  for (let i = 0; i < 3; i++) {
    const bitmask = alphabet.indexOf(suffix[i])
    for (let j = 0; j < 5; j++) {
      if ((bitmask >> j) & 1) {
        output += input[i * 5 + j].toUpperCase()
      } else {
        output += input[i * 5 + j].toLowerCase()
      }
    }
  }
  return output
}
