import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core'
import Modal from '@material-ui/core/Modal'
import { useEffect, useState } from 'react'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import {
  Labeled,
  LoadingIndicator,
  Record,
  SimpleForm,
  Toolbar,
  useNotify,
  useRefresh,
} from 'react-admin'
import { useFormState } from 'react-final-form'
import LocalStorage, { EDIT_STORAGE_KEY } from '../datagrid/LocalStorage'
import { authedFetch } from '../dataProvider/authedFetch'
import { FieldTraverser } from '../dataProvider/introspections/SchemaTraverser'
import { useResourceTraverser } from '../hooks/useSchemaTraverser'
import { CreateOrEditAction } from './customActions/CreateOrEditAction'
import { FallbackSourceField } from './customFields/FallbackSourceField'
import { ShowTypeReferenceField } from './customFields/ShowTypeReferenceField'
import { CategoriesInput } from './customInputs/CategoriesInput'
import {
  cleanFields,
  extractAndRemoveCustomFields,
} from './customSave/movieSave'
import { fallbackSources } from './GenericDataList'
import {
  AccordionGroupRenderer,
  getInputs,
  SMPL_TEMP_CATEGORIES,
  SMPL_TEMP_CATEGORIES_FOR_REMOVAL,
  SMPL_TEMP_STRINGLIST_FOR_REMOVAL,
} from './GenericEditPage'
import {
  camelCaseToText,
  ReferenceFieldNoData,
  RenderScalarField,
} from './renderField'
import { IMAGE } from './settings/TypeDisplaySetting'

const useStyles = makeStyles({
  cardContent: {
    '& > div:first-child': {
      //  display enables a flex context for all its direct children
      // wrap let it show the children in multiple rows if there are more children then box width
      display: 'inline-grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(250px, 1fr))',
      overflow: 'auto',
      // gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '1rem',
      height: '100%',
      width: 'calc(100% - 30px)', // eye-balled, not sure why 100% is larger
    },
  },
  paper: {
    backgroundColor: 'white',
    maxWidth: '80vw',
    maxHeight: '80vh',
    left: '10vw',
    top: '10vh',
    overflow: 'auto',
    overflowX: 'hidden',
  },
  stickyToolbar: {
    position: 'sticky',
    bottom: 0,
    zIndex: 2,
  },
  dropZoneField: {
    marginTop: 'auto !important',
  },
  header: {
    textAlign: 'center',
    marginTop: '1em',
  },
  formInputAction: {
    width: '100%',
    display: 'flex',
    zIndex: 2,
    position: 'relative',
    alignItems: 'center',
  },
  groupsWrapper: {
    width: '100%',
    // padding: '10px',
    paddingTop: '10px !important',
    display: 'flex',
    flexWrap: 'wrap',
    flexDirection: 'row',
    '& > div': {
      flex: '1',
      padding: '10px',
      minWidth: 350,
      maxWidth: '100%',
      '& > div': {
        width: 'auto',
      },
    },
  },
  confirmationForm: {
    '& > div': {
      display: 'flex',
      columnGap: 10,
      '& > div': {
        flexGrow: 1,
        '& > div': {
          width: 'auto',
        },
      },
    },
  },
  confirmationGroup: {
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    overflow: 'auto',
    gridGap: '1rem',
    height: '100%',
    width: '100%',
  },
})

type BatchEditModalProps = {
  open: boolean
  onClose: () => void
  resource: string
  selectedData: Record[]
}

/**
 * only support primitive types for unique
 */
export const mergeArraysAndReturnUnique = (
  array1: any[] | undefined,
  array2: any[] | undefined
) => {
  if (!array1 && !array2) {
    return undefined
  }
  if (!array1) {
    return array2
  }
  if (!array2) {
    return array1
  }
  const mergedArray = [...array1, ...array2]
  // @ts-ignore
  return [...new Set(mergedArray)]
}

export const BatchEditModal = (props: BatchEditModalProps) => {
  const {
    open: batchEditModalOpen,
    onClose: closeBatchEditModal,
    resource,
    selectedData,
  } = props
  const storage = LocalStorage
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const traverser = useResourceTraverser(resource)
  if (!traverser) throw new Error('Resource not found! ' + resource)

  const fieldTraverser = traverser.fields

  /**
   * STATE
   */
  const [isSubmitting, setSubmitting] = useState(false)
  const [confirmationOpen, setConfirmationOpen] = useState(false)
  const [recordToConfirm, setRecordToConfirm] = useState<Record | null>(null)
  const [recordToAdd, setRecordToAdd] = useState<Record | {}>({})
  const [recordToRemove, setRecordToRemove] = useState<Record | {}>({})
  /**
   * STATE END
   */

  useEffect(() => {
    if (recordToConfirm) {
      // separation in add and remove to render them separately in the confirmation modal
      let forAdd = {}
      let forRemoval = {}
      for (const fieldName in recordToConfirm) {
        if (
          // name convention for removal virtual fields:
          fieldName.startsWith('smpl_') &&
          fieldName.endsWith('_for_removal')
        ) {
          // decide case by case depend on the type of the removal
          // we want {[fieldName in string]: any} at the end
          switch (fieldName) {
            case SMPL_TEMP_STRINGLIST_FOR_REMOVAL:
              const fields: { [fieldName in string]: any } =
                recordToConfirm[fieldName]
              for (const realFieldName in fields) {
                if (fields[realFieldName] && fields[realFieldName].length > 0) {
                  forRemoval[realFieldName] = fields[realFieldName]
                }
              }
              break
            case SMPL_TEMP_CATEGORIES_FOR_REMOVAL:
              const catToBeRemoved: Record[] = recordToConfirm[fieldName]
              // we will fake this in renderFields
              forRemoval[SMPL_TEMP_CATEGORIES_FOR_REMOVAL] = catToBeRemoved
              break
            default:
              console.error(
                'This is not supported yet to shown on the mutation confirmation page',
                fieldName
              )
              break
          }
        } else {
          forAdd[fieldName] = recordToConfirm[fieldName]
        }
      }

      setRecordToAdd(forAdd)
      setRecordToRemove(forRemoval)
    }
  }, [recordToConfirm])

  console.log('recordToAdd', recordToAdd)
  console.log('recordToRemove', recordToRemove)

  const closeConfirmationModal = () => {
    setConfirmationOpen(false)
  }

  const inputs = getInputs({
    traverser,
    resource,
    disableUniqueInput: true,
    allowRemovalOption: true, // example -> FlagsInput on Movie
    // TODO: taking the overlapping values as default values
    // defaultValues
  })

  // get current groups expansion from localStorage
  const getGroupsExpansion = () => {
    return storage.get(EDIT_STORAGE_KEY, resource)
  }

  // save to localStorage whether that group was opened or closed for the next time opening a edit/create page of this resource
  const saveCurrentGroupExpansion = (group: string, expanded: boolean) => {
    const groupsExpansion = getGroupsExpansion()
    const newGroupsExpansion = {
      ...groupsExpansion,
      [group]: expanded,
    }
    storage.set(EDIT_STORAGE_KEY, resource, newGroupsExpansion)
  }

  const onSave = async (values: Record) => {
    console.log('overwriting entries with values', values)
    setSubmitting(true)

    notify(
      `Updating ${selectedData.length} ${resource} ${
        selectedData.length === 1 ? 'entry' : 'entries'
      } values.`,
      'info'
    )

    const {
      valuesWithoutCustomFields,
      customValues,
    } = extractAndRemoveCustomFields(values as Record)
    let cleanValues = cleanFields(valuesWithoutCustomFields)

    const stringListFieldName = fieldTraverser
      .filter((f) => f.typeName === 'String' && f.valueKind === 'LIST')
      .map((f) => f.name)

    try {
      // UPDATE ENTRIES
      const backendUrl =
        process.env.PUBLIC_URL + '/api/bulkResource/update/by/id'
      const body = {
        resource: resource,
        updatedEntries: selectedData.map((sD) => {
          // INFO special handling of cleanValues on all stringList fields
          for (const fieldName of stringListFieldName) {
            const currentStringListValues: string[] = sD[fieldName]
            // merge new and existing stringField values
            let newStringListValues: string[] = cleanValues[fieldName]

            if (!currentStringListValues && !newStringListValues) {
              continue
            }

            newStringListValues = mergeArraysAndReturnUnique(
              currentStringListValues,
              newStringListValues
            ) as string[]

            const toBeRemovedStringListValues: string[] = customValues[
              SMPL_TEMP_STRINGLIST_FOR_REMOVAL
            ]
              ? customValues[SMPL_TEMP_STRINGLIST_FOR_REMOVAL][fieldName] || []
              : []

            // remove all values that we want to be removed
            newStringListValues = newStringListValues.filter(
              (v) => !toBeRemovedStringListValues.includes(v)
            )
            cleanValues = {
              ...cleanValues,
              [fieldName]: newStringListValues,
            }
          }

          return {
            id: sD.id,
            ...cleanFields(cleanValues),
          } as Record
        }),
      }

      const res = await authedFetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      if (res.status !== 200) {
        console.error(res.json())
        notify(
          `Something went wrong during updating ${
            selectedData.length > 1 ? 'multiple' : ''
          } ${resource} values. If this problem persists, please contact the support.`,
          'error'
        )
      } else {
        notify(
          `${
            selectedData.length > 1 ? 'Multiple' : ''
          } ${resource} values updated successfully.`,
          'success'
        )
      }

      // UPDATE CONNECTIONS - also if the values update failed (? currently we do it, yes)
      if (Object.keys(customValues).length > 0) {
        notify(
          `Updating ${selectedData.length} ${resource} ${
            selectedData.length === 1 ? 'entry' : 'entries'
          } connections.`,
          'info'
        )
        let thereWasError = false
        for (const smplFieldKey in customValues) {
          let res: any
          let customVal: any
          switch (smplFieldKey) {
            case SMPL_TEMP_CATEGORIES:
              customVal = customValues[smplFieldKey] as Record[]
              res = await authedFetch(
                process.env.PUBLIC_URL +
                  '/api/bulkCategoryConnection/create/many',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    ids: selectedData.map((sD) => sD.id),
                    categoryIds: customVal.map((e: Record) => e.id),
                  }),
                }
              )
              break
            case SMPL_TEMP_CATEGORIES_FOR_REMOVAL:
              customVal = customValues[smplFieldKey] as Record[]
              res = await authedFetch(
                process.env.PUBLIC_URL +
                  '/api/bulkCategoryConnection/remove/many',
                {
                  method: 'POST',
                  headers: {
                    'Content-Type': 'application/json',
                  },
                  body: JSON.stringify({
                    ids: selectedData.map((sD) => sD.id),
                    categoryIds: customVal.map((e: Record) => e.id),
                  }),
                }
              )
              break
            default:
              console.log(`${smplFieldKey} is not supported yet.`)
              res = {
                status: 200,
              }
              break
          }

          if (res.status !== 200) {
            console.error(res.json())
            thereWasError = true
          }
        }

        if (thereWasError) {
          notify(
            `Failed to create or remove one or more connections. If this problem persists, please contact the support.`,
            'error'
          )
        } else {
          notify(`Connections updated successfully.`, 'success')
        }
      }
    } catch (error) {
      console.error(error)
      notify(
        `Failed to connect to server. If this problem persists, please contact the support.`,
        'error'
      )
    } finally {
      setSubmitting(false)
      closeConfirmationModal()
      closeBatchEditModal()
      refresh()
    }
  }

  const StickyToolbar = (props: { saveOrConfirm: 'save' | 'confirm' }) => {
    const { saveOrConfirm } = props
    const formState = useFormState()

    return (
      <Toolbar className={classes.stickyToolbar}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            if (saveOrConfirm === 'confirm') {
              setConfirmationOpen(true)
              setRecordToAdd({})
              setRecordToRemove({})
              setRecordToConfirm(formState.values as Record)
            } else {
              // updated with confirmed Record
              // don't use formState.values as it now points to the form of confirmation modal
              onSave(recordToConfirm)
            }
          }}
          disabled={isSubmitting}
        >
          {saveOrConfirm === 'confirm'
            ? `Override ${selectedData.length} ${
                selectedData.length === 1 ? 'entry' : 'entries'
              }`
            : 'Confirm changes'}
        </Button>
        {isSubmitting && <LoadingIndicator />}
      </Toolbar>
    )
  }

  return (
    <>
      {/* BATCH EDIT MODAL */}
      <Modal
        open={batchEditModalOpen}
        onClose={closeBatchEditModal}
        style={{
          top: 10 + '%',
          left: 10 + '%',
          width: '80vw',
        }}
      >
        <div className={classes.paper}>
          <Typography variant="h5" gutterBottom className={classes.header}>
            Batch Edit {selectedData.length} selected {resource}{' '}
            {selectedData.length === 1 ? 'entry' : 'entries'}
          </Typography>
          {Array.isArray(inputs) ? (
            <SimpleForm
              children={inputs}
              className={classes.cardContent}
              redirect={null} // prevent redirection after create
              submitOnEnter={false}
              toolbar={<StickyToolbar saveOrConfirm="confirm" />}
            />
          ) : (
            <SimpleForm
              submitOnEnter={false}
              redirect={null} // prevent redirection after create
              toolbar={<StickyToolbar saveOrConfirm="confirm" />}
            >
              <div className={classes.formInputAction}>
                <CreateOrEditAction
                  // @ts-ignore
                  CreateOrEdit={{ name: 'Create' }}
                  // @ts-ignore
                  extendedProps={{
                    resource: resource,
                  }}
                />
              </div>
              <div className={classes.groupsWrapper}>
                <div>
                  {Object.keys(inputs.primary).map((group) => {
                    const groupsExpansion = getGroupsExpansion()
                    if (
                      groupsExpansion === undefined ||
                      groupsExpansion[group] === undefined
                    ) {
                      saveCurrentGroupExpansion(group, true)
                    }
                    return AccordionGroupRenderer(
                      inputs.primary[group],
                      group,
                      classes,
                      groupsExpansion,
                      saveCurrentGroupExpansion
                    )
                  })}
                  {resource === 'CmsMovie' || resource === 'CmsSery' ? (
                    <CategoriesInput supportCategoriesRemoval={true} />
                  ) : null}
                </div>
                <div>
                  {Object.keys(inputs.secondary).map((group) => {
                    const groupsExpansion = getGroupsExpansion()
                    if (
                      groupsExpansion === undefined ||
                      groupsExpansion[group] === undefined
                    ) {
                      // INFO default to false -> not opening on very first call, except for IMAGE
                      saveCurrentGroupExpansion(
                        group,
                        group === IMAGE ? true : false
                      )
                    }
                    return AccordionGroupRenderer(
                      inputs.secondary[group],
                      group,
                      classes,
                      groupsExpansion,
                      saveCurrentGroupExpansion
                    )
                  })}
                </div>
              </div>
            </SimpleForm>
          )}
        </div>
      </Modal>

      {/* CONFIRMATION MODAL */}
      <Modal
        open={confirmationOpen}
        onClose={closeConfirmationModal}
        style={{
          top: 11 + '%',
          left: 11 + '%',
          width: '78vw',
        }}
      >
        <div className={classes.paper}>
          <Typography variant="h5" gutterBottom className={classes.header}>
            The Following Fields Will Be Changed
          </Typography>
          <SimpleForm
            submitOnEnter={false}
            redirect={null} // prevent redirection after create
            toolbar={<StickyToolbar saveOrConfirm="save" />}
            className={classes.confirmationForm}
          >
            {recordToConfirm && recordToAdd && (
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" gutterBottom>
                    <div>ADD</div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.confirmationGroup}>
                    {Object.keys(recordToAdd).map((fieldName) => {
                      const field = fieldName.startsWith('smpl_')
                        ? getFakeFieldTraverserFor(fieldName, resource)
                        : fieldTraverser.find((f) => f.name === fieldName)

                      return (
                        <div key={fieldName + 'add'}>
                          <Labeled
                            label={
                              fieldName.startsWith('smpl_')
                                ? camelCaseToText(
                                    fieldName.replace('smpl_', '')
                                  )
                                : camelCaseToText(fieldName)
                            }
                            source={fieldName}
                            record={recordToAdd}
                            resource={resource}
                          >
                            {field.isScalar && field.referenceTypeName ? (
                              <ReferenceFieldNoData
                                record={recordToAdd as Record}
                                source={fieldName}
                                reference={field.referenceTypeName}
                                renderType="show"
                                allowEmpty
                              >
                                <FallbackSourceField
                                  sources={fallbackSources}
                                  Field={ShowTypeReferenceField}
                                  tooltip={field.referenceTypeName}
                                  fixedWidth
                                />
                              </ReferenceFieldNoData>
                            ) : (
                              <RenderScalarField
                                record={recordToAdd as Record}
                                source={fieldName}
                                // @ts-expect-error
                                field={field}
                                renderType="show"
                              />
                            )}
                          </Labeled>
                        </div>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
            {recordToConfirm && recordToRemove && (
              <Accordion defaultExpanded={true}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Typography variant="h6" gutterBottom>
                    <div>REMOVE</div>
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div className={classes.confirmationGroup}>
                    {Object.keys(recordToRemove).map((fieldName) => {
                      const field = fieldName.startsWith('smpl_')
                        ? getFakeFieldTraverserFor(fieldName, resource)
                        : fieldTraverser.find((f) => f.name === fieldName)
                      return (
                        <div key={fieldName + 'remove'}>
                          <Labeled
                            label={
                              fieldName.startsWith('smpl_')
                                ? camelCaseToText(
                                    fieldName
                                      .replace('smpl_', '')
                                      .replace('_for_removal', '')
                                  )
                                : camelCaseToText(fieldName)
                            }
                            source={fieldName}
                            record={recordToRemove}
                            resource={resource}
                          >
                            <RenderScalarField
                              record={recordToRemove as Record}
                              source={fieldName}
                              // @ts-expect-error because Partial on faking
                              field={field}
                              renderType="show"
                            />
                          </Labeled>
                        </div>
                      )
                    })}
                  </div>
                </AccordionDetails>
              </Accordion>
            )}
          </SimpleForm>
        </div>
      </Modal>
    </>
  )
}

const getFakeFieldTraverserFor = (fieldName: string, resource: string) => {
  let fakeFieldTraverser: Partial<FieldTraverser>
  switch (fieldName) {
    case SMPL_TEMP_CATEGORIES:
      fakeFieldTraverser = {
        name: SMPL_TEMP_CATEGORIES,
        valueKind: 'LIST',
        typeName: 'Record',
        // @ts-ignore
        resource: {
          name: resource,
        },
      }
      break
    case SMPL_TEMP_CATEGORIES_FOR_REMOVAL:
      fakeFieldTraverser = {
        name: SMPL_TEMP_CATEGORIES_FOR_REMOVAL,
        valueKind: 'LIST',
        typeName: 'Record',
        // @ts-ignore
        resource: {
          name: resource,
        },
      }
      break

    default:
      console.error(
        'for this fake field we dont have a fake traverser yet',
        fieldName
      )
      break
  }

  return fakeFieldTraverser
}
