import {
  FieldFilter,
  FilterCompositions,
  GraphQLFilter,
} from '../../dataProvider/buildFilter'
import { ResourceTraverser } from '../../dataProvider/introspections/SchemaTraverser'
import { filterNotEmpty } from '../../lib/filterNonEmpty'
import { FAKE_PRESET_FILTER_FIELD, SEARCH_FIELD } from '../CustomFilter'
import { FilterState } from '../CustomFilterForm'

/**
 * removes internal filter hacks and normalizes the filter operation to a list of AND operations
 * @param filterInput
 * @returns
 */
export function sanatizeAndNormalizeFilterToAnd(
  filterInput: GraphQLFilter | FilterCompositions,
  baseResource: ResourceTraverser
): FilterState[] {
  // { title: { equalTo: "..." } }
  const filter = { ...filterInput }

  // @ts-ignore
  delete filter[FAKE_PRESET_FILTER_FIELD]
  // @ts-ignore
  delete filter[SEARCH_FIELD]

  return Object.entries(filter)
    .map(([fieldName, operation]) => {
      if (fieldName == 'and' || fieldName == 'or') {
        return (
          // @ts-ignore
          operation?.map((f: any) => {
            return sanatizeAndNormalizeFilterToAnd(f, baseResource)
          }) ?? null
        )
      } else {
        // TODO: we currently do not support more than one operation!
        // value => { equalTo: "..."}
        // @ts-ignore
        return Object.keys(operation).map((filterOp) => {
          // @ts-ignore
          const filterValue = operation[filterOp]
          const res: FilterState = {
            fieldName: fieldName,
            value: filterValue,
            filterOp: filterOp,
          }
          return res
        })
      }
    })
    .flat(20)
}

export function applyFilterTemplate(template: string, filter: GraphQLFilter) {
  return JSON.parse(template.replace('$$', JSON.stringify(filter)))
}

export function revertNormalization(filterStates: FilterState[]) {
  return {
    and: filterStates
      .map((f) => {
        if (!f.fieldName || !f.filterOp || f.value === undefined) return
        const filter = {
          [f.alias ?? f.fieldName!]: {
            [f.filterOp!]: f.value!,
          },
        } as FieldFilter

        return filter
      })
      .filter(filterNotEmpty),
  }
}

export function mergeFilters(
  filterStatesA: GraphQLFilter,
  filterStatesB: GraphQLFilter,
  baseResource: ResourceTraverser
): GraphQLFilter {
  const { and: bAnd, or: bOr, ...otherFieldsB } = filterStatesB
  const newAnd = [
    ...(filterStatesA.and || []),
    ...sanatizeAndNormalizeFilterToAnd(
      otherFieldsB as FieldFilter,
      baseResource
    ),
    ...(bAnd || []),
  ]
  const newOr = [...(filterStatesA.or || []), ...(bOr || [])]
  return {
    ...filterStatesA,
    ...(newOr.length > 0 ? { or: newOr } : null),
    ...(newAnd.length > 0 ? { and: newAnd } : null),
  } as GraphQLFilter
}
