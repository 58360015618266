import { CircularProgress, Tooltip } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import BlockRoundedIcon from '@material-ui/icons/BlockRounded'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { Record, useNotify, useRefresh } from 'ra-core'
import { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'
import useXmlDownload from '../../hooks/useXmlDownload'
import { isArdCustomer } from '../../lib/config'
import { CustomActionProps } from '../GenericTypeActions'

export type XmlCreationRequest = {
  externalIdentifier: string
  __typename: 'CmsSeason' | 'CmsMovie'
}

type XmlCreationResponse = {
  success: boolean
  externalIdentifier: string
  log: string
  xmlFileName?: string
  xml?: string
}

const DownloadMetadataXmlButton = (props: CustomActionProps) => {
  const { data } = props
  const { downloadXmls, loading } = useXmlDownload()
  const notify = useNotify()

  const handleDownload = () => {
    if (!data || !data.externalIdentifier || !data.__typename) {
      notify('Missing required data', 'error')
      return
    }

    const payload = [
      {
        externalIdentifier: data.externalIdentifier,
        __typename: data.__typename,
      },
    ]

    downloadXmls(payload)
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      startIcon={loading ? <CircularProgress size={20} /> : <DownloadIcon />}
      onClick={handleDownload}
      disabled={loading}
    >
      XML
    </Button>
  )
}

const DisplayAvailabilityStatusWithoutTooltip = (props: CustomActionProps) => {
  const { classes, data, resource } = props
  const [licenseData, setLicenseData] = useState<Record | undefined>()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  useEffect(() => {
    if (data && (resource === 'CmsMovie' || resource === 'CmsEpisode')) {
      const { id: _id } = data
      const fetchLicenseData = async () => {
        let filterString: string = 'contentMovieId'
        switch (resource) {
          case 'CmsMovie':
            filterString = 'contentMovieId'
            break
          case 'CmsEpisode':
            filterString = 'contentEpisodeId'
            break
          default:
            break
        }
        let licenseData = await dataProvider.getList('CmsLicense', {
          filter: { [filterString]: { equalTo: _id } },
          pagination: {
            page: 1,
            perPage: 1000,
          },
          sort: { field: 'createdDate', order: 'DESC' },
        })

        const today = new Date()

        const currentLicense = licenseData.data.find((license) => {
          const licenseStart = new Date(license.licenseStart)
          const licenseEnd = new Date(license.licenseEnd)
          return licenseStart < today && today < licenseEnd
        })

        if (currentLicense) {
          setLicenseData(currentLicense)
        }
      }

      fetchLicenseData()
    }
  }, [data, resource])

  const isActive = data?.isActive ?? false
  if (resource === 'CmsSery' || resource === 'CmsSeason') {
    const buttonText = isActive ? 'Online' : 'Offline'
    const buttonColor = isActive ? 'primary' : 'secondary'
    const buttonIcon = isActive ? <CheckIcon /> : <CloseIcon />
    const buttonDisabled = !isActive

    return (
      <Button
        variant="text"
        color={buttonColor}
        startIcon={buttonIcon}
        disabled={buttonDisabled}
        className={classes.statusButton}
      >
        {buttonText}
      </Button>
    )
  }

  const validLicense = licenseData?.isActive ?? false
  const missingSourceContent = !data?.isActive && validLicense
  if (licenseData && licenseData.forceOffline !== undefined) {
    if (licenseData.forceOffline === false) {
      return (
        <>
          <Button
            variant="text"
            color="primary"
            startIcon={<CheckIcon />}
            disableElevation
            disableRipple
            disableFocusRipple
            className={classes.statusButton}
          >
            Online
          </Button>
          <Button
            variant="outlined"
            color="default"
            startIcon={<BlockRoundedIcon />}
            onClick={async () => {
              try {
                await dataProvider.update('CmsLicense', {
                  id: licenseData.id,
                  data: {
                    ...licenseData,
                    forceOffline: true,
                  },
                  previousData: {
                    ...licenseData,
                  },
                })
                notify(
                  'The object was successfully taken offline. To take it online again, use the "Force Offline" switch in the license object.',
                  'success'
                )
              } catch (error) {
                console.error(error)
                notify(
                  'The object could not be taken offline. If this problem persists, please contact the support.',
                  'error'
                )
              }
              refresh()
            }}
          >
            Force Offline
          </Button>
        </>
      )
    } else {
      return (
        <>
          <Button
            variant="text"
            color="secondary"
            startIcon={<CloseIcon />}
            disabled
            className={classes.statusButton}
          >
            Offline
          </Button>
          <Button
            variant="outlined"
            color="default"
            startIcon={<ArrowUpwardIcon />}
            onClick={async () => {
              try {
                await dataProvider.update('CmsLicense', {
                  id: licenseData.id,
                  data: {
                    ...licenseData,
                    forceOffline: false,
                  },
                  previousData: {
                    ...licenseData,
                  },
                })
                notify('The object was successfully taken online.', 'success')
              } catch (error) {
                console.error(error)
                notify(
                  'The object could not be taken online. If this problem persists, please contact the support.',
                  'error'
                )
              }
              refresh()
            }}
          >
            Activate
          </Button>
        </>
      )
    }
  }

  return (
    <Button
      variant="text"
      color={!validLicense ? 'secondary' : 'primary'}
      startIcon={!validLicense ? <CloseIcon /> : <CheckIcon />}
      disabled={!validLicense}
      className={classes.statusButton}
    >
      {missingSourceContent
        ? 'Offline (Missing Image or Video Source)'
        : 'No Valid License'}
    </Button>
  )
}

export const MovieAction = (props: CustomActionProps) => {
  return (
    <>
      <Tooltip title="Notice that the availability is only recalculated every 10 minutes">
        <span>
          <DisplayAvailabilityStatusWithoutTooltip {...props} />
        </span>
      </Tooltip>
      {(isArdCustomer && props.resource === 'CmsSeason') ||
      props.resource === 'CmsMovie' ? (
        <DownloadMetadataXmlButton {...props} />
      ) : null}
    </>
  )
}
