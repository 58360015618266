import gql from 'graphql-tag'

export const UpdateCmsMovieContentCategoryByCategoryIdMutation = gql`
  mutation UpdateCmsMovieContentCategoryByCategoryId(
    $id: Guid!
    $patch: CmsMovieContentCategoryPatch!
  ) {
    updateCmsMovieContentCategoryById(
      input: { id: $id, cmsMovieContentCategoryPatch: $patch }
    ) {
      cmsMovieContentCategory {
        id
      }
    }
  }
`
export const CreateCmsMovieContentCategoryMutation = gql`
  mutation CreateCmsMovieContentCategory(
    $input: CmsMovieContentCategoryInput!
  ) {
    createCmsMovieContentCategory(input: { cmsMovieContentCategory: $input }) {
      cmsMovieContentCategory {
        id
      }
    }
  }
`
