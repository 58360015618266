import gql from 'graphql-tag'

export const CurrentUserCheck = gql`
  query AdminCheck {
    currentUser {
      id
      role
      name
      username
      givenName
      familyName
    }
  }
`
