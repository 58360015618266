import gql from 'graphql-tag'

export const TranscodingJobByClipIdQuery = gql`
  query TranscodingJobByClipId($id: Guid!) {
    cmsClipById(id: $id) {
      id
      transcodingJobsByClipId(first: 1, orderBy: [CREATED_DATE_DESC]) {
        nodes {
          id
          metadata
          mediaInfo
          awsStatus
          status
          vendorId
          jobTemplate
        }
      }
    }
  }
`

export const TranscodingJobByMovieIdQuery = gql`
  query TranscodingJobByMovieId($id: Guid!) {
    cmsMovieById(id: $id) {
      id
      transcodingJobsByMovieId(first: 1, orderBy: [CREATED_DATE_DESC]) {
        nodes {
          id
          metadata
          mediaInfo
          awsStatus
          status
          jobTemplate
          vendorId
        }
      }
    }
  }
`

export const TranscodingJobByEpisodeIdQuery = gql`
  query TranscodingJobByEpisodeId($id: Guid!) {
    cmsEpisodeById(id: $id) {
      id
      transcodingJobsByEpisodeId(first: 1, orderBy: [CREATED_DATE_DESC]) {
        nodes {
          id
          metadata
          mediaInfo
          awsStatus
          status
          jobTemplate
          vendorId
        }
      }
    }
  }
`

export const TranscodingJobMutation = gql`
  mutation UpdateTranscodingJob($id: Guid!, $patch: TranscodingJobPatch!) {
    updateTranscodingJobById(input: { id: $id, transcodingJobPatch: $patch }) {
      transcodingJob {
        id
      }
    }
  }
`
