import { Button } from '@material-ui/core'
import { useRefresh } from 'ra-core'
import { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { MEDIA_CONVERT_CONTROLLER_API } from '../../../../lib/config'
import { NodeActionProps } from '../../NodeActionType'
import { createSimpleAuthenticatedClient } from '../../user/lib/simpleClient'

const ApproveJobButton = (props: NodeActionProps) => {
  const { record, resource, targetResource, targetId } = props
  const [approve, setApprove] = useState(false)
  const { videoSource } = record
  const notify = useNotify()
  const refresh = useRefresh()

  const endpoint: string = 'approveJob'

  const mediaConvertClient = createSimpleAuthenticatedClient(
    MEDIA_CONVERT_CONTROLLER_API
  )

  // on approve
  useEffect(() => {
    if (approve && targetResource && targetId && videoSource) {
      // For testing with mediaService running on port 8000
      // const URL: string = 'http://localhost:8000/approveJob'
      const {
        vendorId,
        mediaInfo,
        awsStatus,
        videoSource,
        runtimeInSeconds,
        introStartTime,
        introEndTime,
        creditsStartTime,
      } = record
      const bodyMessage = {
        vendorId: vendorId,
        targetResource: targetResource,
        targetId: targetId,
        mediaInfo: mediaInfo,
        awsStatus: awsStatus,
        videoSource: videoSource,
        videoInfo: {
          runtimeInSeconds,
          introStartTime,
          introEndTime,
          creditsStartTime,
        },
      }

      console.log(
        'Approving transcoding job with vendorId',
        vendorId,
        'for',
        targetResource,
        'with Id',
        targetId
      )

      mediaConvertClient('POST', endpoint, { Message: bodyMessage })
        .then((data) => {
          // notify based on response message
          notify('Update successfully.')
          refresh()
        })
        .catch((error) => {
          notify('Failed to update resource.', 'warning')
          console.error(error.error || error.message)
          refresh()
        })
    }
    setApprove(false)
  }, [
    approve,
    record,
    resource,
    videoSource,
    targetId,
    targetResource,
    notify,
    refresh,
  ])

  return (
    <Button
      color="primary"
      onClick={() => setApprove(true)}
      variant="outlined"
      size="small"
    >
      Approve Job
    </Button>
  )
}

ApproveJobButton.label = 'ApproveJobButton'

export default ApproveJobButton
