import { TextField } from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Tooltip from '@material-ui/core/Tooltip'
import ImportExportIcon from '@material-ui/icons/ImportExport'
import ms from 'ms'
import { Record, useDataProvider, useInput, useNotify } from 'ra-core'
import React, { useCallback } from 'react'
import { CreateResult } from 'react-admin'
import { useQuery } from 'react-apollo-hooks'
import { useForm, useFormState } from 'react-final-form'
import { GraphQLFilter } from '../../dataProvider/buildFilter'
import { CategoryGenresQuery } from '../../graphQL/categories'
import { isNetzkinoCustomer } from '../../lib/config'
import { slugify } from '../../lib/slugify'
import {
  GenresCategory,
  GenresCategory_allCmsCategories_nodes_cmsCategoriesByParentId_nodes,
} from '../../__generated__/GenresCategory'
import { SMPL_TEMP_CATEGORIES, SMPL_TEMP_PERSON } from '../GenericEditPage'
import { countryOptions } from '../settings/OptionSetting'
import { TempPerson } from './PersonInput'

type ImportFromOmdbButtonProps = {
  imdbValue: string
}

interface OmdbParsedResponseFailure {
  Response: 'False'
  Error: string
}

interface OmdbParsedResponseSuccess {
  Title: string
  Year: string
  Rated: string
  Released: string // '15 Jan 2015'
  Runtime: string // '96 min'
  Genre: string // 'Drama, Romance'
  Director: string // 'name 1, name 2,...'
  Writer: string // 'name 1, name 2,...'
  Actors: string // 'name 1, name 2,...'
  Plot: string
  Language: string // 'English'
  Country: string // 'USA'
  Awards: string
  Poster: string
  Ratings: {
    Source: string
    Value: string
  }[]
  Metascore: string
  imdbRating: string
  imdbVotes: string
  imdbID: string
  Type: string
  DVD: string
  BoxOffice: string
  Production: string
  Website: string
  Response: 'True'
}

const dbToOmdbMapping = {
  title: 'Title',
  originalTitle: 'Title',
  productionYear: 'Year',
  originalLanguage: 'Language', // TODO? mapping this to ISO code too?
  productionCountry(data: OmdbParsedResponseSuccess) {
    const c = data.Country
    if (!c) return null

    const countriesISO = c
      .split(',')
      .map((country) => country.trim())
      .map((countryString) => {
        const foundOption = countryOptions.find((o) =>
          o.label.includes(countryString.trim())
        )
        if (!foundOption) return countryString
        return foundOption.value
      })

    return countriesISO.join(', ')
  },
  rating: 'imdbRating',
  runtimeInSeconds: 'Runtime',
  slug: (data: OmdbParsedResponseSuccess) => {
    if (!data.Title) {
      return null
    }
    return slugify(data.Title)
  },
  customData(data: OmdbParsedResponseSuccess) {
    if (isNetzkinoCustomer) {
      const awardsString = data.Awards
      if (!awardsString)
        return {
          awards: null,
        }
      const newAwardsString = awardsString
        .replace('wins', 'Auszeichnungen')
        .replace('win', 'Auszeichnung')
        .replace('nominations', 'Nominierungen')
        .replace('nomination', 'Nominierung')
      return {
        awards: newAwardsString,
      }
    }
    return {}
  },
} as const

const personMapping: {
  Director: 'director'
  Actors: 'actor'
  Writer: 'writer'
} = {
  Director: 'director',
  Actors: 'actor',
  Writer: 'writer',
}

type ImdbImportInputProps = {
  fieldName: string
  label?: string
  resource: string
  className: string
}

export const ImdbImportInput = (props: ImdbImportInputProps) => {
  const { fieldName: source, label, resource, className: style } = props
  const {
    id,
    input,
    isRequired,
    meta: { touched },
  } = useInput({
    source: source,
    resource: resource,
    type: 'text',
  })

  const { value: imdbValue, onBlur, onFocus, ...rest } = input
  const form = useForm()
  const formState = useFormState()
  const currentFieldState = useFormState()
  const dataProvider = useDataProvider()

  const entryId = formState.initialValues.id
  const { data: genresData } = useQuery<GenresCategory>(CategoryGenresQuery, {
    notifyOnNetworkStatusChange: true,
  })

  const notify = useNotify()

  const handleFocus = useCallback(
    (event) => {
      onFocus && onFocus(event)
    },
    [onFocus]
  )

  const handleBlur = useCallback(
    (event) => {
      onBlur && onBlur(event)
    },
    [onBlur]
  )

  async function createPerson(pName: string) {
    return await dataProvider.create('CmsPerson', {
      data: {
        id: '000000000000000',
        name: pName,
      },
    })
  }

  const handleClick = async () => {
    if (imdbValue.length === 0) {
      notify('Can not query information. Imdb field is empty!', 'warning')
      return
    }

    const res = await fetch('/api/imdbAPI', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        imdbID: imdbValue,
      }),
    })

    const replaceNA = (data: { [x: string]: any }) => {
      Object.keys(data).forEach((field) => {
        if (data[field] === 'N/A') {
          data[field] = null
        }
      })
    }

    const data:
      | OmdbParsedResponseSuccess
      | OmdbParsedResponseFailure = await res.json()
    if (data.Response === 'False' || !res.ok) {
      // @ts-ignore
      notify(data.Error || data.message, 'error')
    } else {
      // TODO: show a modal with changes that will happens (and let user decide what to override)?
      // INFO: right now only override if and only if that field is empty

      replaceNA(data)

      Object.keys(dbToOmdbMapping).forEach(
        // @ts-ignore
        (field: keyof typeof dbToOmdbMapping) => {
          const fieldCurrentValue = currentFieldState.values[field]

          if (field !== 'customData') {
            if (
              (typeof fieldCurrentValue === 'string' &&
                fieldCurrentValue.length > 0) ||
              typeof fieldCurrentValue == 'number' ||
              typeof fieldCurrentValue == 'boolean' ||
              fieldCurrentValue
            ) {
              // values exist, do nothing
              return
            }
          }

          // simply change field value, with some special case
          const fieldMapping = dbToOmdbMapping[field]
          const mappedData =
            typeof fieldMapping === 'function'
              ? fieldMapping(data)
              : data[fieldMapping]

          if (mappedData) {
            switch (field) {
              case 'runtimeInSeconds':
                const valueInSec = ms(mappedData as string) / 1000
                form.change(field, valueInSec)
                break
              case 'productionYear':
                const valueProdYear = mappedData
                form.change(field, parseInt(valueProdYear as string, 10))
                break
              case 'rating':
                const valueRating = mappedData
                form.change(field, parseFloat(valueRating as string) / 2)
                break
              case 'customData':
                if (fieldCurrentValue !== undefined) {
                  const customJson = JSON.parse(fieldCurrentValue)
                  const newJson = {
                    ...customJson,
                    ...(mappedData as object),
                  }
                  form.change(field, JSON.stringify(newJson))
                } else {
                  form.change(field, JSON.stringify(mappedData as object))
                }
                break
              default:
                const value = mappedData
                form.change(field, value)
                break
            }
          }
        }
      )

      // Genres - Category
      if (
        data.Genre &&
        data.Genre.length > 0 &&
        genresData &&
        genresData.allCmsCategories &&
        genresData.allCmsCategories.nodes.length > 0
      ) {
        // INFO: we only save right now if it match with existing sub-cat of genres
        const queriedGenresName = data.Genre.split(',').map((n) => n.trim())
        // @ts-ignore
        const matchingGenresCat = genresData.allCmsCategories.nodes[0].cmsCategoriesByParentId.nodes.filter(
          (cat) => cat && queriedGenresName.includes(cat.title)
        )
        if (matchingGenresCat && matchingGenresCat.length > 0) {
          const currentTempCat:
            | (GenresCategory_allCmsCategories_nodes_cmsCategoriesByParentId_nodes | null)[]
            | undefined = currentFieldState.values[SMPL_TEMP_CATEGORIES]

          let newTempCat = currentTempCat ? [...currentTempCat] : []

          let filterString: string | undefined
          switch (resource) {
            case 'CmsMovie':
              filterString = 'contentMovieId'
              break
            case 'CmsSery':
              filterString = 'contentSeriesId'
              break
            default:
              break
          }

          let currentExistCat: Record[] | undefined
          if (filterString && entryId) {
            const connectionResult = await dataProvider.getList(
              'CmsMovieContentCategory',
              {
                filter: {
                  [filterString]: { equalTo: entryId },
                } as GraphQLFilter,
                pagination: {
                  page: 1,
                  perPage: 1000, // there will not really be 1000 connection, this will just give back everything
                },
                sort: { field: 'createdDate', order: 'ASC' },
              }
            )
            currentExistCat = connectionResult.data
          }

          currentTempCat || currentExistCat
            ? matchingGenresCat.forEach((cat) => {
                if (!cat) return
                const existInTemp = currentTempCat
                  ? !!currentTempCat.find((cCat) => cCat && cCat.id === cat.id)
                  : false
                const existInExisting = currentExistCat
                  ? !!currentExistCat.find(
                      (cECat) => cECat && cECat.categoryId === cat.id
                    )
                  : false
                // push in new if not exist
                if (!existInTemp && !existInExisting) {
                  newTempCat.push(cat)
                }
              })
            : (newTempCat = [...matchingGenresCat])

          // @ts-ignore
          form.change(SMPL_TEMP_CATEGORIES, newTempCat)
        }
      }

      // save if found with id, if not found then create and save id
      const personResult = await Promise.all(
        Object.keys(personMapping).map(
          // @ts-ignore
          async (job: 'Director' | 'Actors' | 'Writer') => {
            if (data[job] && data[job].length > 0) {
              const queriedPersonNameFromData = data[job]
                .split(',')
                .map((n) => n.trim())
              const queriedPerson: TempPerson[] = []
              const personToBeCreated: string[] = []

              await Promise.all(
                queriedPersonNameFromData.map(async (pName) => {
                  // get entry (only 1 with filter, since name on CmsPerson is unique) with that name on CmsPerson
                  // if not found then remember it to be created later
                  const result = await dataProvider.getList('CmsPerson', {
                    filter: { name: { equalTo: pName } } as GraphQLFilter,
                    pagination: {
                      page: 1,
                      perPage: 10, // there is either 1 or none at all
                    },
                    sort: { field: 'name', order: 'ASC' },
                  })
                  if (result && result.data.length === 1) {
                    queriedPerson.push({
                      id: result.data[0].id as string,
                      name: result.data[0].name as string,
                      connectionType: personMapping[job],
                    })
                  } else {
                    personToBeCreated.push(pName)
                  }
                })
              )

              // creating Person that was not found on CmsPerson with name
              if (personToBeCreated.length > 0) {
                console.log('person to be created', personToBeCreated)
                const createdPersons: CreateResult<Record>[] = []

                for (const person of personToBeCreated) {
                  try {
                    const createdPerson = await createPerson(person)
                    createdPersons.push(createdPerson)
                  } catch (error) {
                    console.error(error)
                  }
                }

                if (createdPersons) {
                  createdPersons.forEach((person) => {
                    queriedPerson.push({
                      id: person.data.id as string,
                      name: person.data.name as string,
                      connectionType: personMapping[job],
                    })
                  })
                }
              }
              return queriedPerson
            }
          }
        )
      )

      if (Array.isArray(personResult)) {
        const personArray = personResult.flat(1)

        // query for existing person connection, only push in form not existing connection
        let filterString: string | undefined
        switch (resource) {
          case 'CmsMovie':
            filterString = 'contentMovieId'
            break
          case 'CmsSery':
            filterString = 'contentSeriesId'
            break
          case 'CmsSeason':
            filterString = 'contentSeasonId'
            break
          case 'CmsEpisode':
            filterString = 'contentEpisodeId'
            break
          default:
            break
        }

        let currentExistPerson: Record[] | undefined
        if (entryId && filterString) {
          // id exists, we are in edit
          const connectionResult = await dataProvider.getList(
            'CmsMovieContentPerson',
            {
              filter: { [filterString]: { equalTo: entryId } } as GraphQLFilter,
              pagination: {
                page: 1,
                perPage: 1000, // there will not really be 1000 connection, this will just give back everything
              },
              sort: { field: 'createdDate', order: 'ASC' },
            }
          )
          currentExistPerson = connectionResult.data
        }

        const currentTempPerson: TempPerson[] | undefined =
          currentFieldState.values[SMPL_TEMP_PERSON]

        const newTempPerson: TempPerson[] | undefined = []
        personArray.forEach((p) => {
          if (!p) return
          const existInExisting = currentExistPerson
            ? !!currentExistPerson?.find(
                (cEP) =>
                  cEP.personId === p.id &&
                  cEP.connectionType === p.connectionType
              )
            : undefined
          const existInTemp = currentTempPerson
            ? !!currentTempPerson.find(
                (cTP) =>
                  cTP.id === p.id && cTP.connectionType === p.connectionType
              )
            : undefined
          if (!existInExisting && !existInTemp) {
            newTempPerson.push(p)
          }
        })

        let newPersonArray: TempPerson[] = []

        if (currentTempPerson) {
          newPersonArray = [...currentTempPerson]
        }
        if (newTempPerson) {
          newPersonArray = [...newPersonArray, ...newTempPerson]
        }

        form.change(SMPL_TEMP_PERSON, newPersonArray)
      }
    }
  }

  return (
    <TextField
      id={id}
      label={label}
      value={imdbValue}
      variant="filled"
      margin="dense"
      onFocus={handleFocus}
      onBlur={handleBlur}
      InputProps={{
        endAdornment: (
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 500 }}
            enterDelay={100}
            title={
              'Get data from IMDb. Filled out fields will not be overwritten.'
            }
            placement="top"
          >
            <InputAdornment position="end">
              <IconButton
                onClick={handleClick}
                aria-label="Get data from IMDb. Filled out fields will not be overwritten."
              >
                <ImportExportIcon />
              </IconButton>
            </InputAdornment>
          </Tooltip>
        ),
      }}
      className={style}
      {...rest}
    />
  )
}
