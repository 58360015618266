import { ListProps, SearchInput } from 'react-admin'
import { GraphQLFilter } from '../dataProvider/buildFilter'
import { useResourceTraverser } from '../hooks/useSchemaTraverser'
import { CustomFilter, SEARCH_FIELD } from './CustomFilter'
import { FilterPreset } from './filterBuilder/filterPresetDefinitions'
import { NONE, TypeDisplaySetting } from './settings/TypeDisplaySetting'

type Props = Omit<ListProps, 'filter'> & {
  forcedFilters?: GraphQLFilter
  filterValues?: GraphQLFilter
  activeFilterPresets?: FilterPreset[]
  possibleFilterPresets?: FilterPreset[]
  selectFilterPreset?: (preset: FilterPreset | null) => void
}

export function GenericFilter({
  forcedFilters,
  filterValues,
  activeFilterPresets,
  possibleFilterPresets,
  selectFilterPreset,
  ...props
}: Props) {
  const traverser = useResourceTraverser(props.resource || '')
  const typeDisplaySettings = TypeDisplaySetting[props.resource]?.show?.fields
  if (!traverser) {
    throw new Error('invalid resource ' + props.resource)
  }
  console.log('XXX filterValues from above', filterValues)
  return (
    // @ts-ignore
    <CustomFilter
      filterableFields={traverser.filterableFields.filter((f) => {
        const { name } = f
        if (
          typeDisplaySettings &&
          typeDisplaySettings[name] &&
          typeDisplaySettings[name].group === NONE
        ) {
          return false
        }
        return true
      })}
      relatedFilterableFields={
        traverser.filterableManyToOneOrManyFieldDescriptors
      }
      {...props}
      forcedFilters={forcedFilters}
      filterValues={filterValues}
      activeFilterPresets={activeFilterPresets}
      possibleFilterPresets={possibleFilterPresets}
      selectFilterPreset={selectFilterPreset}
    >
      <SearchInput source={SEARCH_FIELD} alwaysOn />
      {/* {renderedFields} */}
    </CustomFilter>
  )
}
