export function dataProviderReducer(
  previousState = null,
  action: any
  // { type, payload }: any
) {
  if (action.type === 'SMPL/SET_DATA_PROVIDER') {
    return action.payload
  }
  return previousState
}
