import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import React from 'react'
import cc_tut_1 from '../../imgs/userCountry/creditcard_tut_1_oxi.png'
import cc_tut_2 from '../../imgs/userCountry/creditcard_tut_2_oxi.png'
import pp_sepa_tut_1 from '../../imgs/userCountry/paypal_tut_1_oxi.png'
import pp_sepa_tut_2 from '../../imgs/userCountry/paypal_tut_2_oxi.png'

const useStyles = makeStyles(() => ({
  tutCard: {
    marginBottom: 10,
  },
  heading: {},
  summaryPanel: {
    '& > div:first-child': {
      justifyContent: 'space-between',
    },
  },
  tutPanelDetails: {
    display: 'block',
  },
  tutImg: {
    width: '50vw',
  },
  oList: {
    '& > li': {
      marginBottom: 10,
    },
  },
}))

type TutorialsCardProps = {}

export const TutorialsCard = React.memo((props: TutorialsCardProps) => {
  const classes = useStyles()
  return (
    <Card className={classes.tutCard}>
      <Accordion key="pp_sepa_tutorial">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.summaryPanel}
        >
          <Typography className={classes.heading}>
            Paypal/Sepa Tutorial
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.tutPanelDetails}>
          <table>
            <tbody>
              <tr>
                <td>
                  <img
                    src={pp_sepa_tut_1}
                    alt="paypal_sepa_tut_1_oxi.png"
                    className={classes.tutImg}
                  />
                </td>
                <td>
                  <ol start={0} className={classes.oList}>
                    <li>Open wirecard page and login</li>
                    <li>
                      Go to "Transaction Management" and open "Search
                      Alternative Payments (EE)"
                    </li>
                    <li>
                      Choose an appropriate "Transaction Creation Timestamp"
                    </li>
                    <li>
                      Copy "Request Id" from dashboard and paste it into
                      "Merchant Transaction Id"
                    </li>
                    <li>Click "Search"</li>
                    <li>
                      Select one of the results. If no results are displayed,
                      consider changing "Transaction Creation Timestamp".
                    </li>
                    <li>Open "Details"</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src={pp_sepa_tut_2}
                    alt="paypal_sepa_tut_2_oxi.png"
                    className={classes.tutImg}
                  />
                </td>
                <td>
                  <ol start={7}>
                    <li>
                      Under "Transaction Details" inside "Details" you will find
                      "Instrument Country ID", which specifies the payment
                      country
                    </li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
      <Accordion key="cc_tutorial">
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          className={classes.summaryPanel}
        >
          <Typography className={classes.heading}>
            Credit Card Tutorial
          </Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.tutPanelDetails}>
          <table>
            <tbody>
              <tr>
                <td>
                  <img
                    src={cc_tut_1}
                    alt="creditcard_tut_1_oxi.png"
                    className={classes.tutImg}
                  />
                </td>
                <td>
                  <ol start={0} className={classes.oList}>
                    <li>Open wirecard page and login</li>
                    <li>
                      Go to "Transaction Management" and open "Search Card
                      Transactions (EE)"
                    </li>
                    <li>
                      Choose an appropriate "Transaction Creation Timestamp"
                    </li>
                    <li>
                      Copy "Payment Provider ID" from dashboard and paste it
                      into "Transaction Id"
                    </li>
                    <li>Click "Search"</li>
                    <li>
                      Select one of the results. If no results are displayed,
                      consider changing "Transaction Creation Timestamp".
                    </li>
                    <li>Open "Details"</li>
                  </ol>
                </td>
              </tr>
              <tr>
                <td>
                  <img
                    src={cc_tut_2}
                    alt="creditcard_tut_2_oxi.png"
                    className={classes.tutImg}
                  />
                </td>
                <td>
                  <ol start={7}>
                    <li>
                      Under "Transaction Details" inside "Details" you will find
                      "Instrument Country ID", which specifies the payment
                      country
                    </li>
                  </ol>
                </td>
              </tr>
            </tbody>
          </table>
        </AccordionDetails>
      </Accordion>
    </Card>
  )
})
