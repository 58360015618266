import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  fade,
  makeStyles,
} from '@material-ui/core'
import { useState } from 'react'
import { Record, useNotify, useRefresh } from 'react-admin'
import { MetalEnum, TransferTypeEnum } from '../../apps/futureOrdersReport'
import { EXTERNAL_API_ORDER_ENDPOINT } from '../../lib/config'
import { createSimpleAuthenticatedClient } from '../node-actions/user/lib/simpleClient'

const transferTypeArray = [
  TransferTypeEnum.clearance,
  TransferTypeEnum.lending,
  TransferTypeEnum.foreign,
]

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '95%',
    height: '1px',
    backgroundColor: theme.palette.text.hint,
    marginLeft: '.3rem',
  },
  deleteIcon: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      cursor: 'pointer',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  reasonItem: {
    padding: '.2rem .5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reasonList: {
    padding: '.2rem',
  },
}))

export type CreateTransferOrderProps = {
  data: Record
}

const CreateTransferOrderForm = (props: CreateTransferOrderProps) => {
  const { data } = props
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const [currentTransferType, setCurrentTransferType] = useState<
    TransferTypeEnum | ''
  >('')
  const [amount, setAmount] = useState<number | ''>('')
  const [metalType, setMetalType] = useState<MetalEnum | ''>('')

  const handleAddTransferType = async (vaultId: string) => {
    if (currentTransferType && amount && metalType && vaultId) {
      try {
        const authClient = createSimpleAuthenticatedClient(
          EXTERNAL_API_ORDER_ENDPOINT
        )
        const response = await authClient('POST', '/admin/transfer', {
          amount,
          unitType: metalType,
          sourceVaultId: vaultId,
          transferType: currentTransferType,
        })

        if (response.statusCode === 201 && !response.error) {
          notify('Order created')
          refresh()

          // Clear the inputs after successful order creation
          setCurrentTransferType('')
          setAmount('')
          setMetalType('')
        } else {
          throw new Error('Failed to create transfer order')
        }
      } catch (error) {
        notify(error)
      }
    } else {
      notify('Please fill in all fields')
    }
  }

  return (
    <Container
      classes={{
        root: `${classes.reasonList} MuiContainer-root`,
      }}
    >
      <Paper>
        <Container
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            padding: '1rem',
          }}
        >
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="transfer-type-label">Transfer Type</InputLabel>
            <Select
              labelId="transfer-type-label"
              value={currentTransferType}
              label="Transfer Type"
              onChange={(e) =>
                setCurrentTransferType(e.target.value as TransferTypeEnum)
              }
            >
              {transferTypeArray.map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <TextField
            fullWidth
            variant="outlined"
            label="Amount"
            type="number"
            value={amount}
            onChange={(e) => setAmount(Number(e.target.value))}
          />

          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="metal-type-label">Metal Type</InputLabel>
            <Select
              labelId="metal-type-label"
              value={metalType}
              label="Metal Type"
              onChange={(e) => setMetalType(e.target.value as MetalEnum)}
            >
              {Object.values(MetalEnum).map((metal) => (
                <MenuItem key={metal} value={metal}>
                  {metal}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            color="primary"
            variant="contained"
            onClick={() => handleAddTransferType(data.id as any)}
            style={{ marginTop: '1rem' }}
          >
            Create Transfer Order
          </Button>
        </Container>
      </Paper>
    </Container>
  )
}

export default CreateTransferOrderForm
