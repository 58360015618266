import { IntrospectionField } from 'graphql'

export function plainFields(fields: ReadonlyArray<IntrospectionField>) {
  const plainFields = fields.filter((field) => {
    const {
      type: {
        kind,
        // @ts-ignore
        ofType = {},
      },
    } = field

    if (
      (kind !== 'NON_NULL' &&
        kind !== 'SCALAR' &&
        kind !== 'ENUM' &&
        kind !== 'LIST') ||
      (kind === 'NON_NULL' && ofType && ofType.kind === 'OBJECT') ||
      (kind === 'LIST' && ofType && ofType.kind !== 'SCALAR') ||
      // non-nullable list of pg custom type // TODO this gets absurd if nested further
      (kind === 'NON_NULL' &&
        ofType &&
        ofType.kind === 'LIST' &&
        ofType.ofType &&
        ofType.ofType.kind === 'OBJECT')
    ) {
      // PostgraphQL v4 returns a different value here
      return false
    }
    if (['nodeId'].includes(field.name)) {
      return false
    }
    if (field.args.length > 0) {
      // skip computed fields
      return false
    }

    return !!field
  })

  return plainFields
}

export function nonRelationalOrFunctionalObjectFields(
  fields: ReadonlyArray<IntrospectionField>
) {
  const fieldsName = fields.map(({ name }) => name)

  return fields.filter((field) => {
    const {
      name,
      args,
      type: { kind },
    } = field

    // filtering non, relational and functional object out
    if (
      kind !== 'OBJECT' ||
      fieldsName.indexOf(name + 'Id') > -1 ||
      args.length > 0
    ) {
      return false
    }

    return !!field
  })
}

export function nonRelationalOrFunctionalListObjectFields(
  fields: ReadonlyArray<IntrospectionField>
) {
  const fieldsName = fields.map(({ name }) => name)

  // e.g. dashAudioInfo, tags etc.
  return fields.filter((field) => {
    const {
      name,
      args,
      type: {
        kind,
        // @ts-ignore
      },
      type,
    } = field

    // this are non nullable list
    if (kind === 'NON_NULL') {
      const {
        // @ts-ignore
        ofType: { kind: subKind },
      } = type
      if (subKind === 'LIST') {
        return !!field
      }
    }

    // filtering out non LIST
    if (
      kind !== 'LIST' ||
      fieldsName.indexOf(name + 'Id') > -1 ||
      args.length > 0
    ) {
      return false
    }

    return !!field
  })
}
