import { NoData } from './NoData'

export const AudioInfoFields = (props: any) => {
  const { source, record, track } = props

  const fields = ['Channels', 'Language']

  let jsonObject = null
  if (record[source]) {
    jsonObject = JSON.parse(record[source])

    const tracks = jsonObject.media.track

    return (
      <div>
        {Object.keys(tracks).map((i) => {
          if (tracks[i]['@type'] === track) {
            return (
              <div key={i}>
                <span>Audio Asset {tracks[i]['@typeorder']}</span>
                <div>
                  {fields.map((field) => {
                    return (
                      <div key={field}>
                        <label>{field}: </label>
                        <span>{tracks[i][field]}</span>
                      </div>
                    )
                  })}
                  <br></br>
                </div>
              </div>
            )
          }
          return null
        })}
      </div>
    )
  } else {
    return <NoData />
  }
}
