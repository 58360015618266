export const SET_APPS = 'SET_APPS'
export type SetAppsAction = {
  type: typeof SET_APPS
  payload: any[]
}

export const setApps = (appList: any[]) => ({
  type: SET_APPS,
  payload: appList,
})
