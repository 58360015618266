import { ComponentType } from 'react'
import { Record } from 'react-admin'

type FieldPropsNoSource<T, FieldName> = Pick<T, Exclude<keyof T, FieldName>>

type Props<T, FieldName extends string> = {
  record?: Record
  sources: string[]
  Field: ComponentType<T>
  fieldToFallbackTo?: string
} & FieldPropsNoSource<T, FieldName>

export function getUsableDataSource(
  sources: string[],
  record: Record
): string | undefined {
  if (!record) {
    return undefined
  }
  return sources.find((sourceName) => !!record[sourceName])
}

export function FallbackSourceField<T, V extends string = 'source'>({
  sources,
  Field,
  fieldToFallbackTo = 'source',
  ...otherProps
}: Props<T, V>) {
  const { record = { id: 'null' } } = otherProps

  const source = getUsableDataSource(sources, record) || 'id'

  if (!source) {
    return null
  }
  const p = {
    [fieldToFallbackTo]: source,
  }

  // @ts-ignore
  return <Field {...p} {...otherProps} />
}
