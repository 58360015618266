import Slider from '@material-ui/core/Slider'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { NumberInput } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'

const useStyles = makeStyles({
  sliderField: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: 'thin solid #80808029',
    borderRadius: 5,
  },
  slider: {
    width: '70%',
  },
  input: {
    width: '25%',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    '& > p': {
      display: 'none',
    },
  },
})

type SliderInputProps = {
  fieldName: string
  resource: string
  className: string
  inputProps?: {
    min: number
    max: number
    step: number
    defaultValue: number
  }
  label?: string
  source: any
}

export const SliderInput = (props: SliderInputProps) => {
  const {
    fieldName,
    resource,
    className: style,
    inputProps,
    label,
    ...fieldProps
  } = props

  const currentFieldValue = useFormState().values[fieldName]
  const classes = useStyles()
  const form = useForm()
  const [sliderValue, setSliderValue] = useState(currentFieldValue || 1)
  const [savingToForm, setSavingToForm] = useState(false)

  useEffect(() => {
    if (savingToForm === true && sliderValue !== currentFieldValue) {
      form.change(fieldName, sliderValue)
      setSavingToForm(false)
    }
  }, [sliderValue, savingToForm])

  return (
    <div className={style}>
      <div className={classes.sliderField}>
        <Slider
          className={classes.slider}
          aria-label={label}
          value={sliderValue}
          step={inputProps?.step ? inputProps.step : 1}
          min={inputProps?.min ? inputProps.min : 0}
          max={inputProps?.max ? inputProps.max : 10}
          valueLabelDisplay="auto"
          onChange={(event: object, value: number | number[]) => {
            setSliderValue(value as number)
          }}
          onChangeCommitted={(event: object, value: number | number[]) => {
            setSavingToForm(true)
          }}
        />
        <NumberInput
          className={classes.input}
          min={inputProps?.min ? inputProps.min : 0}
          max={inputProps?.max ? inputProps.max : 10}
          step={inputProps?.step ? inputProps.step : 1}
          {...fieldProps}
          onChange={(event) => {
            setSliderValue(event.target.value as number)
          }}
          variant="outlined"
        />
      </div>
    </div>
  )
}
