import { CardActions } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { useNotify, useRefresh } from 'ra-core'
import { useEndpointAvailable } from '../../hooks/useEndpointAvailable'
import { TASK_SCHEDULER_ENDPOINT } from '../../lib/config'
import { CustomActionProps } from '../GenericTypeActions'
import { createSimpleAuthenticatedClient } from '../node-actions/user/lib/simpleClient'

export const ScheduledTaskAction = (props: CustomActionProps) => {
  const { classes, data } = props
  const notify = useNotify()
  const refresh = useRefresh()

  const taskSchedulerAPI = TASK_SCHEDULER_ENDPOINT
  const endpoint = '/runTask'
  const authedClient = createSimpleAuthenticatedClient(taskSchedulerAPI)
  const isEndpointAvailable = useEndpointAvailable(authedClient, endpoint)

  if (!isEndpointAvailable) {
    return null
  }

  return (
    <CardActions className={classes.leftAction}>
      <Button
        variant="outlined"
        color="primary"
        className={classes.statusButton}
        onClick={async () => {
          const res = await authedClient('POST', endpoint, {
            task: data,
          })

          if (res.success === true) {
            notify('Task started.', 'success')
          } else {
            notify(
              'Task can not be started. If this problem persists, please contact the support.',
              'error'
            )
          }
          refresh()
        }}
      >
        Run Task
      </Button>
    </CardActions>
  )
}
