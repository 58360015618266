import { Button } from '@material-ui/core'
import { useMutation } from 'react-admin'
import { NodeActionProps } from '../../NodeActionType'

const RejectJobButton = (props: NodeActionProps) => {
  const { record, resource } = props

  const [reject, { loading }] = useMutation({
    type: 'update',
    resource: resource,
    payload: {
      id: record.id,
      data: { ...record, status: 'REJECTED' },
      previousData: { ...record },
    },
  })

  return (
    <Button
      color="primary"
      onClick={reject}
      disabled={loading}
      variant="outlined"
      size="small"
    >
      Reject Job
    </Button>
  )
}

RejectJobButton.label = 'CreateJobButton'

export default RejectJobButton
