import {
  CircularProgress,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import red from '@material-ui/core/colors/red'
import { makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import LibraryAddCheckIcon from '@material-ui/icons/LibraryAddCheck'
import ListIcon from '@material-ui/icons/List'
import SearchIcon from '@material-ui/icons/Search'
import SettingsIcon from '@material-ui/icons/Settings'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  BulkActionsToolbar,
  FieldProps,
  Identifier,
  ListContextProvider,
  Pagination,
  Record,
  ShowButton,
  useDataProvider,
  useList,
  useNotify,
  useRecordContext,
  useRefresh,
  useUnselectAll,
} from 'react-admin'
import { Link } from 'react-router-dom'
import CustomizableDatagrid from '../../../datagrid/CustomizeableDatagrid'
import { authedFetch } from '../../../dataProvider/authedFetch'
import { getSchemaTraverser } from '../../../dataProvider/buildQuery'
import { filterNotEmpty } from '../../../lib/filterNonEmpty'
import { renderField } from '../../renderField'
import { DataGridModal } from '../../renderInput'

const redTheme = createMuiTheme({
  palette: {
    primary: red,
  },
})

const useStyles = makeStyles((theme) => ({
  box: {
    border: 'thin solid #80808029',
    padding: 5,
    borderRadius: 5,
    marginBottom: 5,
  },
  chip: {
    margin: 10,
    width: 'fill-available',
  },
  datalist: {
    marginBottom: 20,
  },
  configuration: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  backdropItem: {
    display: 'block',
    textAlign: 'center',
  },
  loadingOverlay: {
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 100,
    backgroundColor: 'rgba(0,0,0,0.2)',
  },
}))

export const RelatedTableAllocationFields = (
  props: React.PropsWithChildren<FieldProps<Record>>,
  connectionInfo: {
    connectionTableName: string
    relatedRessourceNames: string[]
  }
) => {
  const { record, resource } = props
  const { connectionTableName, relatedRessourceNames } = connectionInfo

  return (
    <>
      {/* List of CmsMovieContentCategory by categoryId */}
      <Typography align="center" variant="h5" gutterBottom>
        {`Assign '${record.title || resource}'${
          record.slug ? ` (${record.slug})` : ''
        } to ${relatedRessourceNames
          .map((name) => (name === 'CmsSery' ? 'CmsSeries' : name))
          .join(' or ')}`}
      </Typography>
      {relatedRessourceNames.map((resName) => (
        <RenderDatalistForResource
          key={resName}
          baseResource={resource}
          baseRecord={record}
          resource={resName}
          connectionTableName={connectionTableName}
        />
      ))}
    </>
  )
}

const RenderDatalistForResource = (props: {
  baseResource: string
  baseRecord: Record
  resource: string
  connectionTableName: 'CmsMovieContentCategory' | string
}) => {
  const { baseResource, baseRecord, resource, connectionTableName } = props

  const dataProvider = useDataProvider()
  const traverser = getSchemaTraverser()
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()

  /**
   * STATES START
   */
  // for opening the correct selection modal
  const [referenceTypeName, setReferenceTypeName] = useState<string>(resource)
  // modal open/close state
  const [open, setOpen] = useState(false)
  // data of the connection table
  const [connectionData, setConnectionData] = useState<Record[] | null>(null)
  // data of the base connection table (not the connection m:n table)
  const [data, setData] = useState<Record[] | null>(null)
  // requery the base connection table?
  const [shouldQuery, setShouldQuery] = useState(true)
  // backdrop of the base connection table
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [selectedModalEntries, setSelectedModalEntries] = useState<
    Record[] | null
  >([])
  // used for assigment of connected ressource to another related ressource
  // example: a selection of currently connected movies will be assigned to anther categories
  const [selectedConnectedEntriesId, setSelectedConnectedEntriesId] = useState<
    Identifier[] | null
  >([])
  /**
   * STATES END
   */

  const reQuery = () => {
    setShouldQuery(true)
  }

  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  /**
   * SIDE EFFECT
   */
  // INFO: query the connectionTableName table to get the connection info
  useEffect(() => {
    const queryResData = async (connectionData: Record[], resource: string) => {
      console.log('query for', resource, 'data')

      try {
        let ids: string[] = []

        switch (resource) {
          case 'CmsMovie':
            ids = connectionData
              .map((d) => d.contentMovieId)
              .filter(filterNotEmpty)
            break
          case 'CmsSery':
            ids = connectionData
              .map((d) => d.contentSeriesId)
              .filter(filterNotEmpty)
            break
          case 'SubscriptionProduct':
            ids = connectionData.map((d) => d.productId)
            break
          default:
            console.error('Missing filter for id by resource', resource)
            break
        }

        const filterString = 'id' // id[in]

        const res = await dataProvider.getList(resource, {
          filter: { [filterString]: { in: ids } },
          pagination: {
            page: 1,
            perPage: 1000, // there will not really be 1000 connection, this will just give back everything
          },
          sort: { field: 'createdDate', order: 'ASC' },
        })

        return res.data
      } catch (error) {
        console.error(error)
      }
    }

    const queryData = async () => {
      try {
        let filter: object

        switch (referenceTypeName) {
          case 'CmsMovie': // fall though
          case 'CmsSery':
            filter = { categoryId: { equalTo: baseRecord.id } }
            break
          case 'SubscriptionProduct':
            filter = { campaignId: { equalTo: baseRecord.id } }
            break
          default:
            console.error(
              'no supported filter for referenceTypeName',
              referenceTypeName
            )
            break
        }

        const res = await dataProvider.getList(connectionTableName, {
          //@ts-ignore
          pagination: {},
          filter: filter,
          sort: { field: 'createdDate', order: 'DESC' },
        })

        if (res && res.data.length > 0) {
          setConnectionData(res.data)
          const queriedData = await queryResData(res.data, resource)
          setData(queriedData)
        } else {
          setConnectionData(null)
          setData(null)
        }
      } catch (error) {
        console.error(error)
      }
      setOpenBackdrop(false)
    }
    if (shouldQuery) {
      console.log('query for connection data')
      queryData()
      setShouldQuery(false)
    }
  }, [shouldQuery])

  useEffect(() => {
    switch (referenceTypeName) {
      case 'CmsMovie': // fall though
      case 'CmsSery':
        if (selectedModalEntries && selectedModalEntries.length > 0) {
          addNewConnectionsToCurrentRessource()
        }
        break
      case 'CmsCategory':
        if (
          selectedConnectedEntriesId &&
          selectedConnectedEntriesId.length > 0 &&
          selectedModalEntries &&
          selectedModalEntries.length > 0
        ) {
          addNewConnectionsToRelatedRessource()
        }
        break
      case 'SubscriptionProduct':
        if (selectedModalEntries && selectedModalEntries.length > 0) {
          addNewConnectionsToCurrentRessource()
        }
        break

      default:
        console.error('no save action defined for', referenceTypeName)
        break
    }
  }, [selectedModalEntries])
  /**
   * SIDE EFFECT END
   */

  // INFO assigning another ressource to this table
  // example assigning additional movies to current category
  const addNewConnectionsToCurrentRessource = async () => {
    setOpenBackdrop(true)

    let body = {}
    let backendUrl = process.env.PUBLIC_URL
    switch (connectionTableName) {
      case 'CmsMovieContentCategory':
        backendUrl += '/api/bulkCategoryConnection/create/single'
        body = {
          categoryId: baseRecord.id,
          ids: selectedModalEntries.map((sE) => sE.id),
        }
        break
      case 'VoucherCampaignPaymentProduct':
        backendUrl += '/api/bulkVoucherCampaignPaymentProduct/create/single'
        body = {
          voucherCampaignId: baseRecord.id,
          productIds: selectedModalEntries.map((sE) => sE.id),
        }
        break
      default:
        console.error(
          'no adding behaviour yet for connectionTableName',
          connectionTableName
        )
        break
    }

    try {
      // INFO using backend function for adding Category Connection
      // server need to run (yarn start:server on another console)
      const res = await authedFetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })
      if (res.status !== 200) {
        console.error(res.json())
        notify(
          `Something went wrong during adding multiple ${
            referenceTypeName === 'CmsSery' ? 'CmsSeries' : referenceTypeName
          } to this ${baseResource}. If this problem persists, please contact the support.`,
          'error'
        )
      } else {
        notify(
          `${
            referenceTypeName === 'CmsSery' ? 'CmsSeries' : referenceTypeName
          } added to this ${baseResource}. Refreshing Table.`,
          'success'
        )
      }
    } catch (error) {
      console.error(error)
      notify(
        `Something went wrong during adding multiple ${
          referenceTypeName === 'CmsSery' ? 'CmsSeries' : referenceTypeName
        } to this ${baseResource}. If this problem persists, please contact the support.`,
        'error'
      )
    } finally {
      setSelectedModalEntries([])
      reQuery()
    }
  }

  const addNewConnectionsToRelatedRessource = async () => {
    setOpenBackdrop(true)
    // INFO using backend function for adding Category Connection
    // server need to run (yarn dev:server on another console)
    // we want to create m:n connections
    let backendUrl = process.env.PUBLIC_URL
    let body = {}
    switch (connectionTableName) {
      case 'CmsMovieContentCategory':
        backendUrl += '/api/bulkCategoryConnection/create/many'
        body = {
          categoryIds: selectedModalEntries.map((e) => e.id),
          ids: selectedConnectedEntriesId,
        }
        break
      default:
        break
    }

    try {
      const res = await authedFetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      if (res.status !== 200) {
        console.error(res.json())
        notify(
          `Failed to add selected entries to ${
            selectedModalEntries.length > 1 ? 'one or more' : ''
          } ${
            selectedModalEntries.length > 1
              ? selectedModalEntries[0].__typename
              : selectedModalEntries[0].slug ||
                selectedModalEntries[0].title ||
                selectedModalEntries[0].name
          }. If this problem persists, please contact the support.`,
          'error'
        )
      } else {
        notify(
          `Selected entries were added to ${
            selectedModalEntries.length > 1 ? selectedModalEntries.length : ''
          } ${
            selectedModalEntries.length > 1
              ? selectedModalEntries[0].__typename
              : selectedModalEntries[0].slug ||
                selectedModalEntries[0].title ||
                selectedModalEntries[0].name
          }`,
          'success'
        )
      }
    } catch (error) {
      console.error(error)
      notify(
        `Failed to connect to server. If this problem persists, please contact the support.`,
        'error'
      )
    } finally {
      setOpenBackdrop(false)
      setSelectedModalEntries([])
    }
  }

  // INFO: using backend function for removing Category Connection
  const removeConnection = async (
    record: Record,
    selectedModalEntriesId: Identifier[]
  ) => {
    setOpenBackdrop(true)

    let body = {}
    let backendUrl = process.env.PUBLIC_URL
    switch (connectionTableName) {
      case 'CmsMovieContentCategory':
        backendUrl += '/api/bulkCategoryConnection/remove/single'
        body = {
          categoryId: record.id,
          ids: selectedModalEntriesId,
        }
        break
      case 'VoucherCampaignPaymentProduct':
        backendUrl += '/api/bulkVoucherCampaignPaymentProduct/remove/single'

        const connectionIds = connectionData
          .filter((cD) => selectedModalEntriesId.includes(cD.productId))
          .map((d) => d.id)

        body = {
          voucherCampaignProductIds: connectionIds,
        }
        break
      default:
        console.error(
          'No delete action defined for connectionTableName',
          connectionTableName
        )
        break
    }

    try {
      const res = await authedFetch(backendUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      })

      console.log(res)

      if (res.status !== 200) {
        console.error(await res.json())
        notify(
          `Delete was not successful. If this problem persists, please contact the support.`,
          'error'
        )
      } else {
        notify(`Selected connections were deleted.`, 'success')
      }
    } catch (error) {
      console.error(error)
      notify(
        `Delete was not successful. If this problem persists, please contact the support.`,
        'error'
      )
    } finally {
      reQuery()
    }
  }

  const dataGridRef = useRef<CustomizableDatagrid>(null)

  // ra 3.17
  const listContext = useList({
    data: data ? data : [],
    ids: data ? data.map((entry) => entry.id) : [],
    loading: data ? false : true,
    loaded: data ? true : false,
    perPage: 5,
  })

  const openCustomizer = useCallback(() => {
    dataGridRef.current?.handleOpen()
  }, [dataGridRef])

  const resourceTraverser = useMemo(() => {
    if (!traverser) {
      return null
    }
    const r = traverser.getResourceTraverserByName(resource)
    if (!r) {
      throw new Error('Resource not found! ' + resource)
    }
    return r
  }, [traverser])

  const renderedFields = useMemo(() => {
    return (
      resourceTraverser &&
      resourceTraverser.fields
        .map((field) => {
          const rendered = renderField(field, 'list')
          if (rendered) {
            return {
              field,
              rendered,
            }
          } else {
            return null
          }
        })
        .filter(filterNotEmpty)
    )
  }, [resourceTraverser])

  const DeleteSingleEntryButton = () => {
    const record = useRecordContext()
    return (
      <MuiThemeProvider theme={redTheme}>
        <Button
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={async () => {
            await removeConnection(baseRecord, [record.id])
          }}
        >
          DELETE
        </Button>
      </MuiThemeProvider>
    )
  }

  const BulkDeleteButton = (props: any) => {
    const { selectedIds }: { selectedIds: Identifier[] } = props
    return (
      <MuiThemeProvider theme={redTheme}>
        <Button
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={async (prop: any) => {
            await removeConnection(baseRecord, selectedIds)
            // INFO we use refresh instead of reQuery here as there is a bug I can not solve
            // i.e. the bulk bar does not close even after calling useUnselectAll
            // unselectAll()
            // KNOWN PROBLEM using refresh will leads to the tables briefly disappearing on rebuild
            refresh()
          }}
        >
          DELETE
        </Button>
      </MuiThemeProvider>
    )
  }

  const BulkAddToAnotherRessource = (props: any) => {
    const { selectedIds }: { selectedIds: Identifier[] } = props
    return (
      <MuiThemeProvider theme={redTheme}>
        <Button
          color="inherit"
          startIcon={<LibraryAddCheckIcon />}
          onClick={async (prop: any) => {
            // selected entries from table
            setSelectedConnectedEntriesId(selectedIds)
            // resource the modal should open for selection
            setReferenceTypeName(baseResource)
            openModal()
          }}
        >
          ASSIGN TO OTHER {baseResource === 'CmsCategory' ? 'CATEGORIES' : ''}
        </Button>
      </MuiThemeProvider>
    )
  }

  return (
    <>
      <div className={classes.datalist}>
        <span>
          <div className={classes.configuration}>
            <div>
              <Typography align="left" variant="caption">
                {resource === 'CmsSery' ? 'CmsSeries' : resource} by{' '}
                {baseResource} Id
              </Typography>
            </div>
            <div>
              <Button
                size="medium"
                aria-label={`Assign Category To ${
                  resource === 'CmsSery' ? 'CmsSeries' : resource
                }`}
                color="primary"
                onClick={() => {
                  setReferenceTypeName(resource)
                  openModal()
                }}
              >
                <SearchIcon /> ASSIGN TO{' '}
                {resource === 'CmsSery' ? 'CmsSeries' : resource}
              </Button>
              <Button
                size="medium"
                aria-label="Go To List Page"
                component={Link}
                to={`/${resource}/`}
                color="primary"
              >
                <ListIcon /> LIST
              </Button>

              <Button
                size="small"
                aria-label="Change Columns"
                onClick={openCustomizer}
              >
                <SettingsIcon />
              </Button>
            </div>
          </div>
          <div style={{ position: 'relative' }}>
            <ListContextProvider value={listContext}>
              <>
                <BulkActionsToolbar>
                  {baseResource === 'CmsCategory' ? (
                    <BulkAddToAnotherRessource />
                  ) : (
                    <></>
                  )}
                  <BulkDeleteButton />
                </BulkActionsToolbar>
                <CustomizableDatagrid
                  ref={dataGridRef} // this one is ref for the customizer (placing and on/off of column)
                  fromReference={`${baseResource}_${resource}`}
                  defaultColumns={
                    renderedFields
                      ? renderedFields
                          .filter(({ field: { isHiddenByDefaultInList } }) => {
                            return !isHiddenByDefaultInList
                          })
                          .map(({ field: { name } }) => {
                            return name
                          })
                      : []
                  }
                  sort={{ field: 'createdDate', order: 'DESC' }}
                  hasBulkActions={true}
                >
                  <MuiThemeProvider theme={redTheme}>
                    <DeleteSingleEntryButton />
                  </MuiThemeProvider>

                  <ShowButton basePath={'/' + resource} />

                  {renderedFields
                    ? renderedFields.map(({ rendered }) => {
                        return rendered
                      })
                    : null}
                </CustomizableDatagrid>
                <Pagination />
              </>
            </ListContextProvider>
            {/* TODO: replace this with something else? */}
            <div
              className={classes.loadingOverlay}
              style={{
                position: 'absolute',
                left: 0,
                right: 0,
                bottom: 0,
                top: 0,
                display: openBackdrop ? 'flex' : 'none',
              }}
            >
              <div className={classes.backdropItem}>
                <CircularProgress />
                <Typography
                  align="center"
                  variant="h6"
                  component="div"
                  style={{ color: 'rgb(67,160,71)' }}
                >
                  Refreshing Tables
                </Typography>
              </div>
            </div>
          </div>
        </span>
      </div>
      {/* Modal for selecting object of a Resource*/}
      <DataGridModal
        open={open}
        onClose={closeModal}
        resource={referenceTypeName}
        basePath={`/${referenceTypeName}`}
        location={{
          hash: '',
          pathname: `/${referenceTypeName}`,
          search: '',
          state: null,
        }}
        calledFromModal={true}
        setFunctionEntries={setSelectedModalEntries}
        openedModal={setOpen}
      />
    </>
  )
}
