import { Route } from 'react-router-dom'
// import Configuration from './configuration/Configuration';
import { AppPageLoader } from './apps/AppPageLoader'

export default [
  <Route
    exact
    path="/apps/:appName"
    component={AppPageLoader}
    key="/apps/:appName"
  />,
  // <Route exact path="/configuration" component={Configuration} />,
]
