import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import React, { useEffect, useState } from 'react'
import { useGetIdentity, useResourceContext } from 'react-admin'
import { useField } from 'react-final-form'
import { ADMIN_ROLE_SELECTION } from '../../lib/config'

type AdminRoleSelection = { role: string; label: string }[]

const useStyles = makeStyles({
  formControl: {
    width: 'fill-available',
  },
  select: {
    backgroundColor: 'rgba(0,0,0,0)',
    height: 61,
  },
})

/**
 * for using inside of Form only
 * @param props
 */
export const SimpleSelect = (props: {
  fieldName: string
  label: string
  inputProps: { options: { value: any; label: string }[] }
  multiple?: boolean
  saveAs?: 'string' | 'array'
}) => {
  const {
    fieldName,
    label,
    inputProps,
    multiple = false,
    saveAs = 'string',
  } = props
  const { identity, loaded } = useGetIdentity()
  const [selectData, setSelectData] = useState<
    {
      value: any
      label: string
    }[]
  >(inputProps.options)

  const resourceName = useResourceContext()

  useEffect(() => {
    if (loaded && identity) {
      if (resourceName === 'User') {
        if (identity.role === 'admin') {
          setSelectData(getAdminRoleSelectionArray())
        }
      }
    }
  }, [loaded, identity])

  const field = useField(fieldName)

  const classes = useStyles()

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    // SetValue(event.target.value as string)
    if (!multiple) {
      field.input.onChange(event.target.value as string)
    } else {
      if (saveAs === 'string') {
        field.input.onChange(
          (event.target.value as string[]).join(', ') as string
        )
      } else {
        field.input.onChange(event.target.value as string[])
      }
    }
  }

  return (
    <FormControl variant="filled" className={classes.formControl}>
      <InputLabel id="select-label">{label}</InputLabel>
      <Select
        multiple={multiple}
        className={classes.select}
        id="simple-select"
        value={
          multiple
            ? field.input.value
              ? (field.input.value as string).split(', ')
              : []
            : field.input.value
        }
        onChange={handleChange}
      >
        {selectData.map((option: { [fieldName in string]: string }) => {
          return (
            <MenuItem
              key={option.value ? option.value : 'undefined'}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

// Check if parsedVar matches the expected type
const getAdminRoleSelectionArray = () => {
  // default user role selection for an admin
  let roleSelection = [
    { label: 'User', value: 'user' },
    { label: 'Full Admin Access', value: 'admin' },
  ]

  try {
    console.log(ADMIN_ROLE_SELECTION, typeof ADMIN_ROLE_SELECTION === 'string')
    const parsedRoleSelectionFromEnv = ADMIN_ROLE_SELECTION
      ? typeof ADMIN_ROLE_SELECTION === 'string'
        ? JSON.parse(ADMIN_ROLE_SELECTION.replace(/\\"/g, '"')) // TODO: someone who has access, fix env vars on dev/live to avoid escaping ("\")
        : ADMIN_ROLE_SELECTION
      : roleSelection
    // check if parsed var is valid
    if (
      Array.isArray(parsedRoleSelectionFromEnv) &&
      parsedRoleSelectionFromEnv.every((item) => typeof item?.role === 'string')
    ) {
      roleSelection = parsedRoleSelectionFromEnv.map(
        (roleItem: { label: string; role: string }) => {
          return {
            label: roleItem?.label
              ? roleItem.label
              : roleItem.role.charAt(0).toUpperCase() + roleItem.role.slice(1),
            value: roleItem.role.toLowerCase(),
          }
        }
      )
    }
    console.log('3', roleSelection)
  } catch (err) {
    console.error(
      `Failed to parse roles to select from env variable. Error: ${
        'message' in err ? err.message : err?.toString()
      }`
    )
  }
  return roleSelection
}
