import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import ListIcon from '@material-ui/icons/List'
import SettingsIcon from '@material-ui/icons/Settings'
import { motion } from 'framer-motion'
import pluralize from 'pluralize'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import {
  ReferenceManyField,
  Show,
  ShowButton,
  ShowProps,
  Tab,
  TabbedShowLayout,
  useGetIdentity,
  useNotify,
  usePermissions,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { GetIdentity, Permissions } from '../authProvider'
import {
  FieldTraverser,
  ResourceTraverser,
  systemFieldNames,
} from '../dataProvider/introspections/SchemaTraverser'
import CustomizableDatagrid from '../datagrid/CustomizeableDatagrid'
import { useResourceTraverser } from '../hooks/useSchemaTraverser'
import { smplColors } from '../layout/themes'
import { DASHBOARD_TABLES_DISABLE_RENDER_BY_ROLE } from '../lib/config'
import { filterNotEmpty } from '../lib/filterNonEmpty'
import { TitleComponent } from '../utils/TitleComponent'
import { CustomTab } from './CustomTab'
import { smplTexts } from './CustomTextMappings'
import { PostPagination } from './GenericDataList'
import { GenericTypeActions } from './GenericTypeActions'
import { camelCaseToText, renderCustomField, renderField } from './renderField'
import { TabLayoutSettings } from './settings/TabLayoutSetting'
import { TypeDisplaySetting } from './settings/TypeDisplaySetting'

const useStyles = makeStyles(() => ({
  show: {
    // Show Page Wrapper
    '&>div>div>div:last-of-type': {
      paddingBottom: '1rem',
    },
    '&>div>div': {
      borderRadius: '1.5rem',
    },
  },
  referenceFieldSidebarOpened: {
    overflow: 'auto',
    // Sidebar open is 240px + (otherStuff)px
    maxWidth: 'calc(100vw - 285px)',
    gridColumn: '1 / end',
  },
  referenceFieldSidebarClosed: {
    overflow: 'auto',
    // Sidebar closed is 55px + (otherStuff)px
    maxWidth: 'calc(100vw - 105px)',
    gridColumn: '1 / end',
  },
  tabLayout: {
    '& > div:nth-of-type(2) > span': {
      display: 'inline-grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(333px, 1fr))',
      overflow: 'auto',
      // gridTemplateColumns: '1fr 1fr 1fr 1fr',
      gridGap: '1rem',
      height: '100%',
      width: '100%',
    },
  },
  tabBlockLayout: {
    '& > div:nth-of-type(2) > span': {
      display: 'block',
      overflow: 'auto',
      height: '100%',
      width: '100%',
    },
  },
  tabLayoutTab: {
    '& svg': {
      transition: '.4s ease',
    },
    '&:hover': {
      '& svg': {
        color: smplColors.secondary.main,
      },
    },
  },
  // for default Tab without Grid
  tabSidebarOpened: {
    overflow: 'auto',
    // with -260px the left and right padding of datagrid is visually 'identical'
    // at full screen browser view
    maxWidth: 'calc(100vw - 290px)',
  },
  tabSidebarClosed: {
    overflow: 'auto',
    // with -75px the left and right padding of datagrid is visually 'identical'
    // at full screen browser view
    maxWidth: 'calc(100vw - 105px)',
  },
  configuration: {
    float: 'right',
  },
}))

type Props = ShowProps & {
  resource: string
  record: any
}

// @ts-ignore something with FC not found etc. no idea how to fix this, too lazy to write it new as its working fine

export const GenericShowPage: FC<ShowProps> = (props) => {
  const { resource, record } = props
  const notify = useNotify()
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  const classes = useStyles()
  const traverser = useResourceTraverser(resource)

  /** PERMISSION */
  const [permissions, setPermissions] = useState<Permissions>(undefined)
  const {
    permissions: loadedPermissions,
    loaded: permissionLoaded,
  } = usePermissions<Permissions>()
  useEffect(() => {
    if (loadedPermissions) {
      setPermissions(loadedPermissions)
    }
  }, [permissionLoaded])

  /** IDENTITY */
  const [identity, setIdentity] = useState<{
    fullName: string
    id: string
    role: string
  }>(undefined)
  const {
    loaded: identityLoaded,
    identity: loadedIdentity,
  } = useGetIdentity() as GetIdentity
  useEffect(() => {
    if (loadedIdentity) {
      setIdentity(loadedIdentity)
    }
  }, [identityLoaded])

  // hidden shortcut to copy the ID of the Element CMD + C ( if nothing else is selected )
  useEffect(() => {
    const handleEvent = (e: KeyboardEvent) => {
      const textSelected = window.getSelection().toString() !== ''
      const activeElement = document.activeElement.tagName.toLowerCase()
      const nothingSelected =
        activeElement !== 'input' &&
        activeElement !== 'textarea' &&
        !textSelected
      if (
        (e.metaKey || e.ctrlKey) &&
        e.key === 'c' &&
        e.repeat === false &&
        nothingSelected
      ) {
        notify(
          `${props.id} copied to your clipboard ( Use CMD / CTRL + V for a fast redirect )`
        )
        navigator.clipboard.writeText(props.id)
      }
    }

    document.addEventListener('keydown', handleEvent)
    return () => document.removeEventListener('keydown', handleEvent)
  }, [])

  const renderedFields = useMemo(() => {
    if (!traverser) {
      throw new Error('Resource not found! ' + resource)
    }
    return traverser.fields
      .filter(({ field: { name } }) => !systemFieldNames.includes(name))
      .map((field) => renderField(field, 'show'))
      .filter(filterNotEmpty)
  }, [traverser, resource])

  const renderedSystemFields = useMemo(() => {
    if (!traverser) {
      throw new Error('Resource not found! ' + resource)
    }
    return traverser.fields
      .filter((field) => systemFieldNames.includes(field.field.name))
      .sort(
        ({ name }, { name: name2 }) =>
          systemFieldNames.indexOf(name) - systemFieldNames.indexOf(name2)
      )
      .map((field) => renderField(field, 'show'))
      .filter(filterNotEmpty)
  }, [traverser, resource])

  const renderedFieldsList = (referenceType: ResourceTraverser) => {
    return (
      referenceType &&
      referenceType.fields
        .map((field: FieldTraverser) => {
          const rendered = renderField(field, 'list')
          if (rendered) {
            return {
              field,
              rendered,
            }
          } else {
            return null
          }
        })
        .filter(filterNotEmpty)
    )
  }

  const RenderDatagrid = (props: any) => {
    const classes = useStyles()
    const { referenceType, resource, ...rest } = props

    // replace resource for it to update correctly in localStorage
    const datagridProps = { ...rest }
    datagridProps.resource = referenceType.name

    // this should gives every datagrid its own ref
    const dataGridRef = useRef<CustomizableDatagrid>(null)

    const openCustomizer = useCallback(() => {
      dataGridRef.current?.handleOpen()
    }, [dataGridRef])

    return (
      <span>
        <span className={classes.configuration}>
          <Button
            size="medium"
            aria-label="Go To List Page"
            component={Link}
            to={`/${referenceType.name}/`}
            color="primary"
          >
            <ListIcon /> LIST
          </Button>
          <Button
            size="small"
            aria-label="Change Columns"
            onClick={openCustomizer}
          >
            <SettingsIcon />
          </Button>
        </span>
        <CustomizableDatagrid
          ref={dataGridRef} // this one is ref for the customizer (placing and on/off of column)
          fromReference={`${resource}_${referenceType.name}`}
          rowClick={(id: String) => {
            // TODO: update if routes change
            return `/${referenceType.name}/${id}/show`
          }}
          defaultColumns={renderedFieldsList(referenceType)
            .filter(({ field: { isHiddenByDefaultInList } }) => {
              return !isHiddenByDefaultInList
            })
            .map(({ field: { name } }) => name)}
          {...datagridProps}
        >
          <ShowButton basePath={'/' + referenceType.name} />
          {renderedFieldsList(referenceType).map(({ rendered }) => {
            return rendered
          })}
        </CustomizableDatagrid>
      </span>
    )
  }

  const renderedHasManyField = (() => {
    if (!traverser) {
      throw new Error('Resource not found! ' + resource)
    }
    if (!permissions || !identity) {
      return null
    }

    const noRenderTables = (JSON.parse(
      DASHBOARD_TABLES_DISABLE_RENDER_BY_ROLE
    ) as [
      {
        role: string
        tableNames: string[]
      }
    ])
      .filter((r) => r.role === identity.role)
      .pop()?.tableNames

    return traverser.oneToManyFields
      .map(({ name, getManyReferenceType }) => {
        if (!getManyReferenceType) return null
        const {
          referenceType,
          relatedField,
          relatedType,
        } = getManyReferenceType

        if (permissions.tablePermissions[relatedType]?.canSelect === false) {
          console.log(
            'User has no access to',
            relatedType,
            'to render linked datalist',
            name
          )
          return null
        }

        if (noRenderTables && noRenderTables.includes(relatedType)) {
          console.log(
            'User has access to',
            relatedType,
            'but we do not want to render this linked datalist',
            name
          )
          return null
        }

        return (
          <ReferenceManyField
            key={name}
            reference={resource}
            target={name}
            pagination={<PostPagination />}
            sort={{ field: 'createdDate', order: 'DESC' }}
            label={`${pluralize.plural(
              referenceType.name
            )} by ${camelCaseToText(relatedField)}`}
            perPage={5}
            className={
              open
                ? classes.referenceFieldSidebarOpened
                : classes.referenceFieldSidebarClosed
            }
          >
            {/* 
            TODO: rowClick=show would lead to the base type, not the referenced 
            INFO: <ReferenceManyField> only accepts a single child 
            */}
            <RenderDatagrid referenceType={referenceType} />
          </ReferenceManyField>
        )
      })
      .filter(filterNotEmpty)
  })()

  // check if should render custom tabs instead of the default Details|Related|System
  if (TabLayoutSettings[resource]) {
    // get the custom tabs
    const tabs = TabLayoutSettings[resource].tabs
    const tabsLayout = TabLayoutSettings[resource].layout || 'grid'
    // fetch this custom tab (CmsCategory) in order to place it just after "DETAILS" (ARD-911)
    let smplSortTab:
      | [
          string,
          {
            fields: string[]
            useCustomTab?: boolean | undefined
          }
        ]
      | null = null
    if (tabs?.SmplSort) {
      smplSortTab = ['SmplSort', tabs.SmplSort]
    }

    // where to find the rendered fields
    const combinedRenderedFields = [
      ...renderedFields,
      ...(renderedHasManyField ? renderedHasManyField : []),
      ...renderedSystemFields,
    ]

    const renderExtraTab = (
      tab: [
        string,
        {
          fields: string[]
          useCustomTab?: boolean | undefined
        }
      ]
    ) => {
      // get what we want to render
      const [tabKey, tabValue] = tab

      const fieldArray = tabValue.fields
      const TabKind = tabValue.useCustomTab ? CustomTab : Tab
      if (fieldArray.length > 0) {
        return (
          // @ts-ignore
          <TabKind
            resource={resource}
            label={
              smplTexts.showPageTabs[tabKey]?.tabName
                ? smplTexts.showPageTabs[tabKey]?.tabName
                : tabKey
            }
            path={tabKey}
            key={tabKey}
            icon={
              smplTexts.showPageTabs[tabKey]?.icon
                ? smplTexts.showPageTabs[tabKey]?.icon
                : null
            }
            className={classes.tabLayoutTab}
          >
            {fieldArray.map((field) => {
              for (let i = 0; i < combinedRenderedFields.length; i++) {
                if (field === combinedRenderedFields[i].key) {
                  return combinedRenderedFields[i]
                }
              }
              return renderCustomField(resource, field, record)
            })}
          </TabKind>
        )
      }
      return null
    }

    if (Object.keys(tabs).length > 0 && combinedRenderedFields.length > 0) {
      return (
        <motion.div
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ ease: 'backOut', duration: 1 }}
        >
          <Show
            {...props}
            title={<TitleComponent record={record} resource={resource} />}
            actions={<GenericTypeActions />}
            className={classes.show}
          >
            <TabbedShowLayout
              className={`${
                tabsLayout === 'block'
                  ? classes.tabBlockLayout
                  : classes.tabLayout
              } ${open ? classes.tabSidebarOpened : classes.tabSidebarClosed}`}
            >
              {TypeDisplaySetting[resource]?.show ? (
                // @ts-ignore
                <CustomTab
                  icon={smplTexts.showPageTabs.Details.icon}
                  resource={resource}
                  label={smplTexts.showPageTabs.Details.tabName}
                  path=""
                  className={classes.tabLayoutTab}
                >
                  {renderedFields}
                </CustomTab>
              ) : (
                <Tab
                  icon={smplTexts.showPageTabs.Details.icon}
                  label={smplTexts.showPageTabs.Details.tabName}
                  path=""
                  className={classes.tabLayoutTab}
                >
                  {renderedFields}
                </Tab>
              )}
              {smplSortTab ? renderExtraTab(smplSortTab) : null}
              {renderedHasManyField && renderedHasManyField.length > 0 ? (
                <Tab
                  icon={smplTexts.showPageTabs.Related.icon}
                  label={smplTexts.showPageTabs.Related.tabName}
                  path="related"
                  className={classes.tabLayoutTab}
                >
                  {renderedHasManyField}
                </Tab>
              ) : null}

              {renderedSystemFields && renderedSystemFields.length > 0 ? (
                <Tab
                  icon={smplTexts.showPageTabs.System.icon}
                  label={smplTexts.showPageTabs.System.tabName}
                  path="system"
                  className={classes.tabLayoutTab}
                >
                  {renderedSystemFields}
                </Tab>
              ) : null}

              {Object.entries(tabs).map((tab) => {
                if (tab[0] === 'SmplSort') {
                  return null
                } else {
                  return renderExtraTab(tab)
                }
              })}
            </TabbedShowLayout>
          </Show>
        </motion.div>
      )
    }

    // Bad case:
    throw new Error(
      'Type is defined in TLS but it has nothing to render or something happens to the data.'
    )
  } else {
    return (
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ ease: 'backOut', duration: 1 }}
      >
        <Show
          {...props}
          title={<TitleComponent record={record} resource={resource} />}
          actions={<GenericTypeActions />}
          className={classes.show}
        >
          <TabbedShowLayout
            className={`${classes.tabLayout} ${
              open ? classes.tabSidebarOpened : classes.tabSidebarClosed
            }`}
          >
            {/* @ts-ignore */}
            <CustomTab
              resource={resource}
              icon={smplTexts.showPageTabs.Details.icon}
              label={smplTexts.showPageTabs.Details.tabName}
              path=""
              className={classes.tabLayoutTab}
            >
              {renderedFields}
            </CustomTab>

            {renderedHasManyField && renderedHasManyField.length > 0 ? (
              <Tab
                icon={smplTexts.showPageTabs.Related.icon}
                label={smplTexts.showPageTabs.Related.tabName}
                path="related"
                className={classes.tabLayoutTab}
              >
                {renderedHasManyField}
              </Tab>
            ) : null}

            {renderedSystemFields && renderedSystemFields.length > 0 ? (
              <Tab
                icon={smplTexts.showPageTabs.System.icon}
                label={smplTexts.showPageTabs.System.tabName}
                path="system"
                className={classes.tabLayoutTab}
              >
                {renderedSystemFields}
              </Tab>
            ) : null}
          </TabbedShowLayout>
        </Show>
      </motion.div>
    )
  }
}
