import { useEffect, useState } from 'react'
import { usePermissions } from 'react-admin'
import { OnTablePermissions, Permissions } from '../../../authProvider'
import { NodeActionProps } from '../NodeActionType'
import {
  ApproveJobButton,
  CreateJobButton,
  CreateResourceButton,
  RejectJobButton,
} from './buttons'

const TranscodingJob = (props: NodeActionProps) => {
  const { record, resource } = props
  const { videoSource, movieId, episodeId, clipId, vendorId } = record

  const idExist = movieId !== null || episodeId !== null || clipId !== null

  const [resPermissions, setResPermissions] = useState<OnTablePermissions>(
    undefined
  )

  const {
    permissions,
    loaded: permissionLoaded,
  } = usePermissions<Permissions>()

  useEffect(() => {
    if (permissions) {
      setResPermissions(permissions.tablePermissions[resource])
    }
  }, [permissionLoaded])

  if (!resPermissions) {
    return undefined
  }

  switch (record.status) {
    case 'READY_FOR_TRANSCODING': {
      if (resPermissions.canUpdate) {
        return <CreateJobButton {...props} />
      }
      return undefined
    }
    case 'READY_FOR_QA': {
      if (resPermissions.canUpdate) {
        return (
          <>
            {/* Reject Job - show this button if either approve job is  */}
            <RejectJobButton {...props} />

            {/* VideoSource exist */}
            {videoSource !== null && idExist ? (
              // when either movie/episode/clipId exist then show approve:
              clipId !== null ? (
                <ApproveJobButton
                  {...props}
                  targetResource="CmsClip"
                  targetId={clipId}
                />
              ) : episodeId !== null ? (
                <ApproveJobButton
                  {...props}
                  targetResource="CmsEpisode"
                  targetId={episodeId}
                />
              ) : (
                <ApproveJobButton
                  {...props}
                  targetResource="CmsMovie"
                  targetId={movieId}
                />
              )
            ) : /_trailer$/.test(vendorId) ? (
              // clip
              <CreateResourceButton {...props} targetResource="CmsClip" />
            ) : (
              <>
                {/* movie or episode */}
                <CreateResourceButton {...props} targetResource="CmsMovie" />
                <CreateResourceButton {...props} targetResource="CmsEpisode" />
              </>
            )}
          </>
        )
      } else {
        return undefined
      }
    }
    case 'ERROR': {
      if (resPermissions.canUpdate) {
        return <CreateJobButton {...props} label="Retry Job" />
      }
      return undefined
    }
    default: {
      console.log(`No supported action found for status ${record.status}`)
      return undefined
    }
  }
}

TranscodingJob.label = 'CreateJob'

export default TranscodingJob
