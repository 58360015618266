import { Divider } from '@material-ui/core'
import { Record } from 'ra-core'
import React from 'react'
import { cmsWithCat, cmsWithLicense, cmsWithPerson } from '../GenericEditPage'
import {
  CategoriesInput,
  CategoriesInputWithExistingRecord,
} from '../customInputs/CategoriesInput'
import {
  LicenseInput,
  LicenseInputWithExistingRecord,
} from '../customInputs/LicenseInput'
import {
  PersonInput,
  PersonInputWithExistingRecord,
} from '../customInputs/PersonInput'
import { GroupOrder } from '../settings/TypeDisplaySetting'

export type CustomInputFormProps = {
  inputGroup: {
    [x: string]: JSX.Element[]
  }
  resource: string
  record: Record
  classes: any
  setDisableSaveButtonFlag: (flag: boolean) => void
  saveCurrentGroupExpansion: (group: string, expanded: boolean) => void
  getGroupsExpansion: () => any
  isSmartCategoryEnabled: boolean
  setSmartCategoryEnabled: React.Dispatch<React.SetStateAction<boolean>>
  isInitialCustomFormRenderRef: React.MutableRefObject<boolean>
}

/**
 * Custom Form that will be displayed on the left side for movie cms (Movie, Series, Season, Episode)
 * @param props
 */
export const MovieInputForm = (props: CustomInputFormProps) => {
  const { inputGroup, resource, record, classes } = props

  return (
    <>
      {GroupOrder.primary.map((group) => {
        if (inputGroup[group]) {
          return (
            <>
              <div className={classes.invisibleGroup}>{inputGroup[group]}</div>
              <Divider light={true} className={classes.divider} />
            </>
          )
        }
        return undefined
      })}

      {/* Related Categories Management */}
      {cmsWithCat.includes(resource) ? (
        record.id !== undefined ? (
          <>
            <div className={classes.relatedFields}>
              <CategoriesInputWithExistingRecord
                key={resource}
                resource={resource}
                record={record}
              />
            </div>
            <Divider light={true} className={classes.divider} />
          </>
        ) : (
          <>
            <div className={classes.relatedFields}>
              <CategoriesInput
                key={resource}
                resource={resource}
                record={record}
              />
            </div>
            <Divider light={true} className={classes.divider} />
          </>
        )
      ) : null}

      {/* Related Person Management */}
      {cmsWithPerson.includes(resource) ? (
        record.id !== undefined ? (
          <div className={classes.relatedFields}>
            <PersonInputWithExistingRecord
              key={resource}
              resource={resource}
              record={record}
            />
          </div>
        ) : (
          <div className={classes.relatedFields}>
            <PersonInput key={resource} resource={resource} record={record} />
          </div>
        )
      ) : null}

      {/* Related Licenses Management */}
      {cmsWithLicense.includes(resource) ? (
        record.id !== undefined ? (
          <div className={classes.relatedFields}>
            <LicenseInputWithExistingRecord
              key={resource}
              resource={resource}
              record={record}
              callFromCreate={false}
            />
          </div>
        ) : (
          <div className={classes.relatedFields}>
            <LicenseInput
              key={resource}
              resource={resource}
              record={record}
              callFromCreate={true}
            />
          </div>
        )
      ) : null}
    </>
  )
}
