// based on https://github.com/marmelab/react-admin/pull/6119#issuecomment-866781413
import { useEffect, useRef } from 'react'

function tryToSetDocumentTitle(title: string) {
  try {
    document.title = title
  } catch (e) {
    console.warn('Document is undefined')
  }
}

export function useDocumentTitle({
  title,
  revertOnUnmount = true,
}: {
  title: string
  revertOnUnmount?: boolean
}) {
  const isBrowser = typeof document !== 'undefined'
  const prevPageTitleRef = useRef(isBrowser ? document.title : null)

  useEffect(() => {
    if (isBrowser) {
      prevPageTitleRef.current = document.title
    }
  }, [isBrowser])

  useEffect(() => {
    if (isBrowser) {
      tryToSetDocumentTitle(title + ' - SMPL Administration')
    }

    return () => {
      if (isBrowser && revertOnUnmount && prevPageTitleRef.current !== null) {
        tryToSetDocumentTitle(prevPageTitleRef.current)
      }
    }
  }, [isBrowser, title, revertOnUnmount])
}
