import { FormControl, makeStyles, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import { CustomImageField } from './CustomImageField'
import { JsonField } from './JsonField'

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  },
  fieldGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  field: {
    width: '45%',
    margin: '0 auto',
    marginBottom: 10,
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  fieldNoBackground: {
    width: '45%',
    margin: '0 auto',
    marginBottom: 10,
  },
})

type CustomDataFieldsProps = {
  record: { [x: string]: any }
  label: string
  inputProps: {
    fields: {
      name: string
      label: string
      type: string
      options: any // for input, not interessting for field
    }[]
  }
}

export const CustomDataFields = (props: CustomDataFieldsProps) => {
  const { label, record, inputProps } = props
  const { fields } = inputProps
  const classes = useStyles()

  const keyList = fields
    .filter((f) => Object.keys(record).includes(f.name))
    .map((f) => f.name)

  const allSupportedFieldName = fields.map((f) => f.name)
  const undefinedKeyList = Object.keys(record).filter((k) => {
    if (allSupportedFieldName.includes(k)) {
      return false
    } else {
      return true
    }
  })

  return (
    <Card elevation={2}>
      <div className={classes.fieldGroup}>
        {undefinedKeyList.map((fieldName) => {
          const value =
            typeof record[fieldName] === 'string'
              ? record[fieldName]
              : JSON.stringify(record[fieldName])
          return (
            <FormControl key={fieldName} className={classes.field}>
              <Typography color="textSecondary" variant="caption">
                {fieldName}
              </Typography>
              <Typography component="span" variant="body2">
                {value}
              </Typography>
            </FormControl>
          )
        })}
        {keyList.map((fieldName) => {
          let fieldType = fields.find((f) => f.name === fieldName)?.type

          const value =
            typeof record[fieldName] === 'string'
              ? record[fieldName]
              : JSON.stringify(record[fieldName])

          if (fieldType === 'Json') {
            return (
              <FormControl key={fieldName} className={classes.field}>
                <Typography color="textSecondary" variant="caption">
                  {fieldName}
                </Typography>
                <JsonField
                  source={fieldName}
                  // @ts-ignore
                  record={record}
                  showContent
                />
              </FormControl>
            )
          }

          if (fieldType === 'Image') {
            return (
              <FormControl
                key={fieldName}
                className={classes.fieldNoBackground}
              >
                <Typography color="textSecondary" variant="caption">
                  {fieldName}
                </Typography>
                <CustomImageField source={fieldName} record={record} />
              </FormControl>
            )
          }

          return (
            <FormControl key={fieldName} className={classes.field}>
              <Typography color="textSecondary" variant="caption">
                {fieldName}
              </Typography>
              <Typography component="span" variant="body2">
                {value}
              </Typography>
            </FormControl>
          )
        })}
      </div>
    </Card>
  )
}
