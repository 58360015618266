import isEmpty from 'lodash/isEmpty'
import { fallbackSources } from '../genericData/GenericDataList'
import { getUsableDataSource } from '../genericData/customFields/FallbackSourceField'
import { useDocumentTitle } from '../hooks/useDocumentTitle'

export type TitleComponentProps = {
  record: any
  resource: any
}

export const TitleComponent = ({ record, resource }: TitleComponentProps) => {
  if (isEmpty(record)) {
    return <>{`${resource}`}</>
  }

  const source = getUsableDataSource(fallbackSources, record)
  let data: string = record.id
  if (source && source in record) {
    data = record[source]
  }

  const title = `${resource} ${data}`
  useDocumentTitle({
    title,
  })
  return <>{title}</>
}
