import {
  adminReducer,
  adminSaga,
  defaultI18nProvider,
  GET_LIST,
  USER_LOGOUT,
} from 'react-admin'
import { routerMiddleware, connectRouter } from 'connected-react-router'
import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { all, fork } from 'redux-saga/effects'
import { appsReducer } from '../reducers/appsReducer'
import { dataProviderReducer } from '../reducers/dataProviderReducer'
import themeReducer from '../reducers/themeReducer'
import sagas from '../sagas'

export async function createAdminStore({
  authProvider,
  dataProvider,
  i18nProvider = defaultI18nProvider,
  history,
}) {
  const reducer = combineReducers({
    admin: adminReducer,
    router: connectRouter(history),
    theme: themeReducer,
    apps: appsReducer,
    dataProvider: dataProviderReducer,
  })
  const resettableAppReducer = (state, action) =>
    reducer(
      action.type !== USER_LOGOUT
        ? state
        : {
            // reset and only keep dataProvider
            dataProvider: state.dataProvider,
          },
      action
    )

  const saga = function* rootSaga() {
    yield all(
      [adminSaga(dataProvider, authProvider, i18nProvider), ...sagas].map(fork)
    )
  }
  const sagaMiddleware = createSagaMiddleware()

  let apps = null
  try {
    console.log('LOADING APPS in admin store')
    const { data } = await dataProvider(GET_LIST, 'App', {
      pagination: {},
      sort: {},
    })
    apps = data
  } catch (e) {
    console.error('could not load apps', e)
  }

  const store = createStore(
    resettableAppReducer,
    {
      dataProvider,
      apps,
    },
    compose(
      applyMiddleware(
        sagaMiddleware,
        routerMiddleware(history)
        // add your own middlewares here
      ),
      typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__
        ? window.__REDUX_DEVTOOLS_EXTENSION__()
        : (f) => f
      // add your own enhancers here
    )
  )
  sagaMiddleware.run(saga)
  return store
}
