import { IntrospectionType } from 'graphql'
import memoize from 'lodash/memoize'
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE,
} from 'ra-core'
import { deleteManyQueryName } from './introspections/deleteMany'
import { getListQueryName } from './introspections/getList'
import { getManyReferenceQueryName } from './introspections/getManyReference'
import { getOneQueryName } from './introspections/getOne'
import { updateOneQueryName } from './introspections/updateOne'

const useNameAsCacheKey = (r: IntrospectionType) => r.name

export const introspection = {
  operationNames: {
    //@ts-ignore
    [GET_LIST]: memoize(getListQueryName, useNameAsCacheKey),
    //@ts-ignore
    [GET_ONE]: memoize(getOneQueryName, useNameAsCacheKey),
    // [GET_MANY]: memoize(getListQueryName, useNameAsCacheKey), // same as many
    //@ts-ignore
    [GET_MANY_REFERENCE]: memoize(getManyReferenceQueryName, useNameAsCacheKey),
    [CREATE]: memoize(
      (resource: IntrospectionType) => `create${resource.name}`,
      useNameAsCacheKey
    ),
    [UPDATE]: memoize(updateOneQueryName, useNameAsCacheKey), // TODO: ById?
    [DELETE]: memoize(deleteManyQueryName, useNameAsCacheKey),
    [DELETE_MANY]: memoize(deleteManyQueryName, useNameAsCacheKey),
  },
  exclude: function (type: IntrospectionType) {
    return ['Node'].includes(type.name)
  },
  // include: undefined,
}
