import { Button } from '@material-ui/core'
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
} from 'ra-core'
import { useEffect, useState } from 'react'
import { useDataProvider, usePermissions } from 'react-admin'
import { connect } from 'react-redux'
import { OnTablePermissions, Permissions } from '../../../authProvider'
import { NodeActionProps } from '../NodeActionType'

function SetStatus({
  record: subscriptionTerm,
  resource,
  refreshView,
  showNotification,
}: NodeActionProps & {
  refreshView: typeof refreshViewAction
  showNotification: typeof showNotificationAction
}) {
  const dataProvider = useDataProvider()
  const { id, status } = subscriptionTerm
  const [resPermissions, setResPermissions] = useState<OnTablePermissions>(
    undefined
  )
  const {
    permissions,
    loaded: permissionLoaded,
  } = usePermissions<Permissions>()

  useEffect(() => {
    if (permissions) {
      setResPermissions(permissions.tablePermissions[resource])
    }
  }, [permissionLoaded])

  const clickHandler = async function (status: string) {
    try {
      await dataProvider.update('SubscriptionTerm', {
        id,
        previousData: subscriptionTerm,
        data: {
          status,
        },
      })
      refreshView()
    } catch (error) {
      console.error('Could not set status', error)
      showNotification('Error: Could not set status!', 'warning')
    }
  }

  if (!resPermissions) {
    return null
  }

  if (resPermissions.canUpdate) {
    if (!status || status === 'CANCELED') {
      return (
        <Button
          onClick={() => clickHandler('VALID')}
          variant="outlined"
          size="small"
        >
          Set VALID Status
        </Button>
      )
    } else {
      return (
        <Button
          onClick={() => clickHandler('CANCELED')}
          variant="outlined"
          size="small"
        >
          Set CANCELLED Status
        </Button>
      )
    }
  }

  return null
}

SetStatus.label = 'SetStatus'

export default connect(null, {
  refreshView: refreshViewAction,
  showNotification: showNotificationAction,
})(SetStatus)
