import { replace } from 'connected-react-router'
import { GET_LIST } from 'ra-core'
import { call, put, takeEvery } from 'redux-saga/effects'
import graphql from '../dataProvider/graphql'
import { setApps } from '../reducers/actions/actions'
import { getAfterLoginRedirect } from '../utils/afterLoginURL'

function* userLoggedIn(_payload: any) {
  // const state: { apps: Object[]; dataProvider: DataProvider } = yield select()

  const dataProvider = yield call(graphql)
  yield put({ type: 'SMPL/SET_DATA_PROVIDER', payload: dataProvider })
  // const { dataProvider } = state

  console.log('LOADING APPS in userLoggedIn Saga')
  const { data: apps } = yield call(dataProvider, GET_LIST, 'App', {
    pagination: {},
    sort: {},
  })

  yield put(setApps(apps))
  yield new Promise<void>((resolve) => {
    // wait for the app to render once
    requestAnimationFrame(() => resolve())
  })

  // restore the initially loaded URL
  yield put(replace(getAfterLoginRedirect()))
}

export function* appStartupSaga() {
  yield takeEvery('SMPL/LOGGED_IN', userLoggedIn)
}
