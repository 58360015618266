import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListItemText from '@material-ui/core/ListItemText'
import Modal from '@material-ui/core/Modal'
import Tooltip from '@material-ui/core/Tooltip'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import SearchIcon from '@material-ui/icons/Search'
import { IntrospectionEnumValue } from 'graphql'
import React, { useCallback, useEffect, useState } from 'react'
import {
  BooleanInput,
  DateInput,
  DateTimeInput,
  Identifier,
  InputProps,
  ListProps,
  NumberInput,
  Record,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { FieldTraverser } from '../dataProvider/introspections/SchemaTraverser'
import { CreateModal } from './CreateModal'
import { GenericDataList } from './GenericDataList'
import { LocalStateDataList } from './LocalStateDataList'
import { ObjectArrayJsonFields } from './customFields/ObjectArrayJsonFields'
import { ObjectJsonFields } from './customFields/ObjectJsonFields'
import { AutocompleteInput } from './customInputs/AutocompleteInput'
import { CustomDataInput } from './customInputs/CustomDataInput'
import { FlagsInput } from './customInputs/FlagsInput'
import { ImageInput } from './customInputs/ImageInput'
import { ImdbImportInput } from './customInputs/ImdbImportInput'
import { JsonInput } from './customInputs/JsonInput'
import { SimpleSelect } from './customInputs/SimpleSelect'
import { SliderInput } from './customInputs/SliderInput'
import { StringListInput } from './customInputs/StringListInput'
import { StringListToSelectInput } from './customInputs/StringListToSelectInput'
import { CmsImportRelation } from './settings/ImportRelationSetting'
import { currencyCodes } from './settings/OptionSetting'
import {
  TypeDisplaySetting,
  defaultListShowEditAndCreate,
  marginTop,
  noBGColor,
  sizeL,
  sizeM,
  sizeMax,
  sizeS,
  sizeXL,
  sizeXS,
} from './settings/TypeDisplaySetting'

const useStyles = makeStyles({
  // no helperText
  noHelperTextStyle: {
    '& > p': {
      display: 'none',
    },
  },
  // for left side
  customDefaultStyle: {
    justifySelf: 'center',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    '& > p': {
      marginLeft: 0,
      marginRight: 0,
      textAlign: 'justify',
      hyphens: 'auto',
    },
  },
  heigherField: {
    '& > div': {
      minHeight: 70,
    },
  },
  marginRight: {
    marginRight: 10,
  },
  [sizeXS]: {
    minWidth: 150,
  },
  [sizeS]: {
    minWidth: 200,
  },
  [sizeM]: {
    minWidth: 250,
  },
  [sizeL]: {
    minWidth: 290,
    width: 300,
  },
  [sizeXL]: {
    minWidth: 290,
    width: 350,
  },
  [sizeMax]: {
    width: '100%',
  },
  [noBGColor]: {
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0)',
    },
  },
  [marginTop]: {
    marginTop: '8px',
  },
  // for right side
  defaultStyle: {
    width: '100%',
    justifySelf: 'center',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    '& > p': {
      marginLeft: 0,
      marginRight: 0,
      textAlign: 'justify',
      hyphens: 'auto',
    },
  },
  jsonField: {
    width: '100%',
    justifySelf: 'center',
    maxHeight: '30vh',
    overflow: 'auto',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
    // gridColumn: '1 / span 2', // this lead to other fields in the same grid not breaking lines correctly
    '& > p': {
      display: 'none',
    },
    marginTop: '8px',
    marginBottom: '4px',
  },
  longTextHeight: {
    maxHeight: 400,
    overflow: 'auto',
  },
  longTextAdornment: {
    marginTop: '-17px',
  },
  paper: {
    backgroundColor: 'white',
    width: '80vw',
    maxHeight: '80vh',
    left: '10vw',
    top: '10vh',
    // overflow: 'auto',
  },
  input: {
    width: 'inherit',
    justifySelf: 'center',
  },
})

function renderSelectOptionText({ name, description }: IntrospectionEnumValue) {
  return <ListItemText primary={name} secondary={description || undefined} />
}

// https://stackoverflow.com/a/1026087
function capitalizeFirstLetter(string?: string) {
  return string ? string.charAt(0).toUpperCase() + string.slice(1) : string
}

function addPoint(string?: string) {
  return string
    ? ['.', '!', '?'].includes(string.charAt(string.length - 1))
      ? string
      : string + '.'
    : string
}

function filterSpecialAnnotation(string: string) {
  const des = string.startsWith('§')
    ? string
        .split(' ')
        .filter((t) => !t.startsWith('§'))
        .join(' ')
    : string
  if (des.length === 1 && des[0] === ' ') {
    return undefined
  }
  return des
}

function getToolTipText(
  showTooltipText: boolean,
  isNullable: boolean,
  isUnique: boolean
) {
  if (!showTooltipText) return ''
  if (!isNullable && isUnique) {
    return 'This field cannot be empty and must be unique.'
  } else if (!isNullable) {
    return 'This field cannot be empty.'
  } else {
    return 'This field must be unique.'
  }
}

export type FormInputProps = InputProps & {
  label?: string
  record?: Record
  resettable?: boolean
  allowEmpty?: boolean
  className?: string
  classes?: any
}

// OuterProps
type Props = FormInputProps & {
  field: FieldTraverser
}

export type InnerProps = Props & {
  field: FieldTraverser
  defaultValues?: { [fieldName in string]: string }
  useDefaultStyle: boolean
}

const RenderScalarInputImpl = ({
  field, // fieldTraverser
  defaultValues,
  useDefaultStyle,
  disableUniqueInput,
  allowRemovalOption,
  ...fieldProps
}: InnerProps & {
  disableUniqueInput: boolean
  allowRemovalOption: boolean
}) => {
  const {
    name,
    typeName,
    isNullable,
    isUnique,
    field: { description: des },
  } = field

  let description = des
    ? addPoint(capitalizeFirstLetter(filterSpecialAnnotation(des)))
    : undefined

  const currentFieldValue = useFormState().values[name]
  const classes = useStyles()
  const className = `${classes.defaultStyle} ${
    description ? '' : classes.noHelperTextStyle
  }`

  const resource = field.resource.name

  // changing form values if default values is given
  const form = useForm()
  useEffect(() => {
    if (defaultValues && defaultValues[name]) {
      if (resource === 'CmsImage') {
        if (name === 'name') {
          if (currentFieldValue === undefined) {
            form.change(name, defaultValues[name])
          } else {
            // ! don't replace the Name Field if it's set by user
          }
        } else {
          // replace everything else !!! only for CmsImage
          form.change(name, defaultValues[name])
        }
      } else {
        if (currentFieldValue === undefined) {
          form.change(name, defaultValues[name])
        }
      }
    }
  }, [defaultValues, form, name, resource, currentFieldValue])

  // optional currency start adornment in case of prices
  const currencyCode = useFormState().values['currencyCode']
  const priceStartAdormentTooltip =
    name === 'price' &&
    currencyCode &&
    currencyCodes.find((cC) => cC.value === currencyCode) ? (
      <InputAdornment position="start">
        {
          // @ts-ignore
          currencyCodes.find((cC) => cC.value === currencyCode).symbol
        }
      </InputAdornment>
    ) : undefined

  let showTooltipText = !isNullable || isUnique
  let tooltipText = getToolTipText(showTooltipText, isNullable, isUnique)

  // endAdornment for not Nullable field
  const options = {
    InputProps: {
      startAdornment: priceStartAdormentTooltip,
      endAdornment: (
        <>
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 500 }}
            enterDelay={100}
            title={tooltipText}
            placement="top"
          >
            <InputAdornment position="end">
              <IconButton
                className={
                  typeName === 'JSON' ||
                  TypeDisplaySetting[resource]?.editAndCreate.fields?.[name]
                    ?.type === 'LONG_TEXT_INPUT'
                    ? classes.longTextAdornment
                    : ''
                }
                aria-label={tooltipText}
              >
                {currentFieldValue ? (
                  isUnique ? (
                    <DoneAllIcon />
                  ) : (
                    <DoneAllIcon color="primary" />
                  )
                ) : (
                  <ErrorOutlineIcon color="error" />
                )}
              </IconButton>
            </InputAdornment>
          </Tooltip>
        </>
      ),
    },
  }

  if (field.isEnum) {
    return (
      <SelectInput
        // @ts-ignore The type 'readonly IntrospectionEnumValue[]' is 'readonly' and cannot be assigned to the mutable type 'object[]'
        choices={field.enumValues}
        optionValue="name"
        // optionLabel="name"
        optionText={renderSelectOptionText}
        translateChoice={false}
        className={className}
        {...fieldProps}
        helperText={description}
      />
    )
  }

  // Custom case where the field (and resource) is defined in TypeDisplaySetting.ts
  const displaySettings = TypeDisplaySetting[resource]
    ? TypeDisplaySetting[resource]
    : defaultListShowEditAndCreate

  if (displaySettings) {
    const typeDisplaySettingName = displaySettings!.editAndCreate.fields[name]
    if (typeDisplaySettingName) {
      // INFO switching through the type standing in TypeDisplaySetting (which later coming from a database)
      // INFO instead of the type standing in field: FieldTraverser, gives better possibilities of styling/customizing

      const typeDisplaySettingNameType = typeDisplaySettingName.type
      const {
        // @ts-ignore can not find it for whatever reason
        custom_style,
        ...otherSettingProps
      } = typeDisplaySettingName.props || {}

      let customClassName = useDefaultStyle
        ? `${classes.defaultStyle}`
        : `${classes.customDefaultStyle}`

      if (custom_style) {
        custom_style.forEach((style: string) => {
          customClassName =
            customClassName +
            ' ' +
            // @ts-ignore
            `${classes[style]}`
        })
      }

      if (!description && !otherSettingProps.hasOwnProperty('helperText')) {
        customClassName = customClassName + ' ' + `${classes.noHelperTextStyle}`
      }

      switch (typeDisplaySettingNameType) {
        case 'LONG_TEXT_INPUT':
          return (
            <TextInput
              multiline
              helperText={description}
              options={showTooltipText ? options : {}}
              className={customClassName}
              {...(otherSettingProps || null)}
              {...fieldProps}
              {...(disableUniqueInput === true
                ? {
                    disabled: isUnique,
                  }
                : {})}
            />
          )
        case 'TEXT_INPUT':
          return (
            <TextInput
              helperText={description}
              options={showTooltipText ? options : {}}
              className={customClassName}
              {...(otherSettingProps || null)}
              {...fieldProps}
              {...(disableUniqueInput === true
                ? {
                    disabled: isUnique,
                  }
                : {})}
            />
          )
        case 'DROPDOWN_INPUT':
          return (
            <div className={customClassName}>
              {/* @ts-ignore */}
              <SimpleSelect fieldName={name} {...(otherSettingProps || null)} />
            </div>
          )
        case 'AUTOCOMPLETE_INPUT':
          return (
            <div className={customClassName}>
              {/* @ts-ignore */}
              <AutocompleteInput
                fieldName={name}
                {...(otherSettingProps || null)}
              />
            </div>
          )
        case 'IMDB_IMPORT_INPUT':
          return (
            <ImdbImportInput
              fieldName={name}
              resource={resource}
              className={customClassName}
              {...(otherSettingProps || null)}
            />
          )
        case 'SLIDER_INPUT':
          return (
            <SliderInput
              fieldName={name}
              resource={resource}
              className={customClassName}
              {...(otherSettingProps || null)}
              {...fieldProps}
            />
          )
        case 'FLAGS_INPUT':
          return (
            // @ts-ignore
            <FlagsInput
              fieldName={name}
              resource={resource}
              className={customClassName}
              allowRemovalOption={allowRemovalOption}
              {...(otherSettingProps || null)}
              {...fieldProps}
            />
          )
        case 'CUSTOM_DATA_INPUT':
          if (
            otherSettingProps.inputProps &&
            otherSettingProps.inputProps.fields &&
            otherSettingProps.inputProps.fields.length > 0
          ) {
            return (
              // @ts-ignore
              <CustomDataInput
                fieldName={name}
                // resource={resource}
                {...(otherSettingProps || null)}
                {...fieldProps}
              />
            )
          } else {
            return <JsonInput name={name} helperText={description} />
          }
        case 'STRING_ARRAY_SELECT_INPUT':
          return (
            // @ts-ignore
            <StringListToSelectInput
              fieldName={name}
              {...(otherSettingProps || null)}
              {...fieldProps}
            />
          )
        case 'BOOLEAN_INPUT':
          return (
            <BooleanInput
              helperText={description}
              className={customClassName}
              {...(otherSettingProps || null)}
              {...fieldProps}
            />
          )
        case 'NUMBER':
          return (
            <NumberInput
              helperText={description}
              options={showTooltipText ? options : {}}
              className={customClassName}
              {...(otherSettingProps || null)}
              {...fieldProps}
            />
          )
        default: {
          // falls-through to default case
        }
      }
    }
  }

  // Default case

  // JSON and others
  if (field.valueKind === 'OBJECT') {
    return <ObjectJsonFields name={name} helperText={description} />
  }

  // String List
  if (field.valueKind === 'LIST') {
    switch (typeName) {
      case 'String':
        return <StringListInput name={name} helperText={description} />
      default:
        return <ObjectArrayJsonFields name={name} helperText={description} />
    }
  }

  switch (typeName) {
    case 'Date': {
      return (
        <DateInput
          className={className}
          options={showTooltipText ? options : {}}
          {...fieldProps}
          helperText={description}
        />
      )
    }
    case 'Datetime': {
      return (
        <DateTimeInput
          options={showTooltipText ? options : {}}
          className={className}
          {...fieldProps}
          helperText={description}
        />
      )
    }
    case 'Boolean': {
      return (
        <BooleanInput
          className={className}
          {...fieldProps}
          helperText={description}
        />
      )
    }
    case 'Guid': {
      return (
        <TextInput
          options={showTooltipText ? options : {}}
          className={className}
          {...fieldProps}
          helperText={description}
        />
      )
    }
    case 'ID': {
      // nodeId
      return null
    }
    case 'String': {
      return (
        <TextInput
          options={showTooltipText ? options : {}}
          className={className}
          {...fieldProps}
          helperText={description}
          {...(disableUniqueInput === true
            ? {
                disabled: isUnique,
              }
            : {})}
        />
      )
    }
    case 'Float': // falls-through
    case 'BigFloat': // falls-through
    case 'Int': {
      // nodeId
      return (
        <NumberInput
          options={showTooltipText ? options : {}}
          className={className}
          {...fieldProps}
          helperText={description}
        />
      )
    }
    case 'JSON': {
      // nodeId
      return <JsonInput name={name} helperText={description} {...fieldProps} />
    }
    default: {
      debugger
    }
  }
  return null
}

const RenderScalarInput = React.memo(
  (props: {
    field: FieldTraverser
    defaultValues?: { [fieldName in string]: any }
    useDefaultStyle: boolean
    disableUniqueInput: boolean
    allowRemovalOption: boolean
  }) => {
    const {
      field,
      defaultValues,
      useDefaultStyle,
      disableUniqueInput,
      allowRemovalOption,
    } = props

    const commonProps: FormInputProps = {
      source: field.name,
      allowEmpty: field.isNullable,
    }

    return (
      <RenderScalarInputImpl
        field={field}
        defaultValues={defaultValues}
        useDefaultStyle={useDefaultStyle}
        disableUniqueInput={disableUniqueInput}
        allowRemovalOption={allowRemovalOption}
        {...commonProps}
      />
    )
  }
)

export const DataGridModal = (
  props: ListProps & {
    open: boolean
    onClose: () => void
    onOpenCreateModal?: () => void
    location: Object
    calledFromModal: boolean
    setFunction?: Function
    setFunctionEntry?: Function
    setFunctionEntries?: React.Dispatch<React.SetStateAction<Record[] | null>>
    openedModal: Function
    hasCreateInModal?: boolean
    fieldName?: string
  }
) => {
  const classes = useStyles()
  const { open, onClose, fieldName, ...otherProps } = props
  return (
    <Modal
      open={open}
      onClose={onClose}
      style={{
        top: 10 + '%',
        left: 10 + '%',
        maxWidth: '80vw',
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      <div className={classes.paper}>
        <GenericDataList
          {...otherProps}
          ListComponent={LocalStateDataList}
          modalFieldName={fieldName}
        />
      </div>
    </Modal>
  )
}

const RenderRelationInputImpl = ({
  field, // fieldTraverser
  defaultValues,
}: InnerProps) => {
  const {
    name,
    referenceTypeName: relatedResource,
    resource: traverser,
  } = field
  const commonProps: FormInputProps = {
    source: field.name,
    allowEmpty: field.isNullable,
  }
  const fieldName = field.name
  const fieldNames = Object.keys(field.resource.fieldsByName)
  const form = useForm()
  const formState = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const fields = traverser.fields
  const imageFields = fields.filter(
    (f) => f.field.type.kind === 'OBJECT' && f.field.type.name === 'CmsImage'
  )
  const imageFieldNames = imageFields
    ? imageFields.map((iF) => iF.field.name + 'Id')
    : []
  const resource = field.resource.name

  useEffect(() => {
    if (defaultValues && defaultValues[name]) {
      form.change(name, defaultValues[name])
    }
  }, [defaultValues, form, name])

  const [selectionOpen, setSelectionOpen] = useState(false)
  const [createOpen, setCreateOpen] = useState(false)
  const [selectedId, setSelectedId] = useState<Identifier | null>(null)
  const classes = useStyles()

  const openSelectionModal = useCallback(() => {
    setSelectionOpen(true)
  }, [])

  const closeSelectionModal = useCallback(() => {
    setSelectionOpen(false)
  }, [])

  const openCreateModal = useCallback(() => {
    setCreateOpen(true)
  }, [])

  const closeCreateModal = useCallback(() => {
    setCreateOpen(false)
  }, [])

  // opening a modal to choose ref
  const options = {
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 500 }}
            enterDelay={100}
            title={'Open Create Modal'}
            placement="top"
          >
            <IconButton
              aria-label="Open Create Modal"
              onClick={() => {
                openCreateModal()
              }}
            >
              <AddIcon />
            </IconButton>
          </Tooltip>
          <Tooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 500 }}
            enterDelay={100}
            title={'Open Selection Modal'}
            placement="top"
          >
            <IconButton
              aria-label="Open Selection Modal"
              onClick={() => {
                openSelectionModal()
              }}
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      ),
    },
  }

  const getRelatedResourceObjectById = async (id: Identifier, rRes: string) => {
    const { data: seletedData } = await dataProvider.getOne(rRes, {
      id: id,
    })
    return seletedData
  }

  const setFieldByRelated = async (
    fields: {
      [name: string]: string
    },
    id: Identifier,
    rRes: string
  ) => {
    const relatedData = await getRelatedResourceObjectById(id, rRes)
    const currentValues = formState.values
    const overwritableFields = Object.keys(fields)
    const overwrittenFields: string[] = []
    if (overwritableFields.length > 0) {
      overwritableFields.forEach((ovField) => {
        // only override if field is currently empty
        // or we are in create so it is undefined
        if (
          currentValues[ovField] === null ||
          (currentValues[ovField] === undefined && fieldNames.includes(ovField))
        ) {
          overwrittenFields.push(ovField)
          form.change(ovField, relatedData[fields[ovField]])
        } else {
          // special case - not empty customData
          if (ovField === 'customData') {
            const currentCustomData: { [x: string]: any } = JSON.parse(
              currentValues[ovField]
            )
            const relatedCustomData: { [x: string]: any } = JSON.parse(
              relatedData[fields[ovField]]
            )

            const newCustomData: { [x: string]: any } = {}

            Object.keys(relatedCustomData).forEach((field) => {
              if (
                currentCustomData.hasOwnProperty(field) &&
                currentCustomData[field] === ''
              ) {
                newCustomData[field] = relatedCustomData[field]
              } else if (!currentCustomData.hasOwnProperty(field)) {
                newCustomData[field] = relatedCustomData[field]
              }
            })

            overwrittenFields.push(ovField)
            form.change(ovField, newCustomData)
          }
        }
      })
    }
    if (overwrittenFields.length > 0) {
      notify(
        `The following fields have been overwritten: ${overwrittenFields.join(
          ', '
        )}`,
        'success'
      )
    } else {
      notify('No field was overwritten.', 'info')
    }
  }

  // change the value upon selecting an id inside the modal
  useEffect(() => {
    if (selectedId && relatedResource) {
      form.change(name, selectedId)
      if (CmsImportRelation[resource] && CmsImportRelation[resource][name]) {
        notify(
          'Transfer possible fields from selected resource.',
          'info',
          {},
          false,
          1000
        )
        // fields we will override if it is currently empty
        const { fields } = CmsImportRelation[resource][name]
        setFieldByRelated(fields, selectedId, relatedResource)
      }
    }
  }, [selectedId, form, name])
  const displaySettings = TypeDisplaySetting[resource]
  let helperText
  if (displaySettings) {
    const typeDisplaySettingName = displaySettings.editAndCreate.fields[name]
    if (typeDisplaySettingName) {
      // INFO switching through the type standing in TypeDisplaySetting (which later coming from a database)
      // INFO instead of the type standing in field: FieldTraverser, gives better possibilities of styling/customizing

      if (typeDisplaySettingName.props?.helperText) {
        helperText = typeDisplaySettingName.props.helperText
      }

      const typeDisplaySettingNameType = typeDisplaySettingName.type
      const {
        // @ts-ignore can not find it for whatever reason
        custom_style,
        ...otherSettingProps
      } = typeDisplaySettingName.props || {}
      let className = `${classes.customDefaultStyle}`
      if (custom_style) {
        custom_style.forEach((style: string) => {
          className =
            className +
            ' ' +
            // @ts-ignore
            `${classes[style]}`
        })
      }

      switch (typeDisplaySettingNameType) {
        case 'IMAGE_INPUT':
          return (
            <ImageInput
              field={field}
              className={className}
              {...(otherSettingProps || null)}
            />
          )
        default: {
          // falls-through to default case of textfield with
        }
      }
    }
  }

  // check for in TDS undefined image field and render it as IMAGE_INPUT with default values
  if (
    imageFieldNames.find((iFN) => fieldName === iFN) // is imageField
  ) {
    let className = `${classes.customDefaultStyle}`
    ;[sizeMax, noBGColor].forEach((style: string) => {
      className =
        className +
        ' ' +
        // @ts-ignore
        `${classes[style]}`
    })

    return (
      <ImageInput
        field={field}
        className={className}
        label={capitalizeFirstLetter(
          fieldName
            .substring(0, fieldName.length - 2) // remove Id
            .replace(/([a-z])([A-Z])/g, '$1 $2') // splitting camelCase
        )}
      />
    )
  }

  // Ids etc. TextField with possibility to create new resource of related in modal and search for existing one
  return (
    <span className={classes.input}>
      <TextInput
        options={options}
        className={classes.defaultStyle}
        {...commonProps}
        label={field.name}
        helperText={helperText}
      />
      {relatedResource !== null ? (
        <>
          <CreateModal
            open={createOpen}
            onClose={closeCreateModal}
            onCloseDataGridModal={closeCreateModal}
            resource={relatedResource}
            hasEdit
            hasList
            hasShow
            setFunction={setSelectedId}
            fieldName={field.name}
          />
          <DataGridModal
            open={selectionOpen}
            onClose={closeSelectionModal}
            onOpenCreateModal={openCreateModal}
            resource={relatedResource}
            basePath={`/${relatedResource}`}
            location={{
              hash: '',
              pathname: `/${relatedResource}`,
              search: '',
              state: null,
            }}
            calledFromModal={true}
            setFunction={setSelectedId}
            openedModal={setSelectionOpen}
            hasCreateInModal={true}
            fieldName={field.name}
          />
        </>
      ) : null}
    </span>
  )
}

const RenderRelationInput = React.memo(RenderRelationInputImpl)

export function renderInput(props: {
  field: FieldTraverser
  defaultValues: { [fieldName in string]: any }
  useDefaultStyle: boolean
  disableUniqueInput?: boolean
  allowRemovalOption?: boolean
}) {
  const {
    field,
    defaultValues,
    useDefaultStyle,
    disableUniqueInput = false,
    allowRemovalOption = false,
  } = props

  // TODO: extra variable that tell field render function to only use the default style in case we are not in resources with custom pages
  if (field.isInternal || !field.isEditable || field.isHiddenOnEdit) {
    return null
  }

  // (field.field.type.kind === 'LIST' && field.field.type.ofType.kind === 'OBJECT') === 'true', we don't want to show all of them only some
  const allowedListObjectField = [
    'dashAudioInfo',
    'dashTextTrackInfo',
    'hlsAudioInfo',
    'hlsTextTrackInfo',
  ]

  if (
    allowedListObjectField.includes(field.name) ||
    field.isScalar ||
    field.isEnum ||
    field.isObject ||
    field.isList
  ) {
    const { referenceTypeName } = field
    if (referenceTypeName) {
      return (
        <RenderRelationInput
          key={field.name}
          field={field}
          source={field.name}
          defaultValues={defaultValues}
          useDefaultStyle={useDefaultStyle}
        />
      )
    }

    return (
      <RenderScalarInput
        key={field.name}
        field={field}
        defaultValues={defaultValues}
        useDefaultStyle={useDefaultStyle}
        disableUniqueInput={disableUniqueInput}
        allowRemovalOption={allowRemovalOption}
      />
    )
  }

  return null
}
