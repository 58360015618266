import { useState } from 'react'
import { useNotify } from 'react-admin'
import { authedFetch } from '../dataProvider/authedFetch'

interface XmlCreationRequest {
  externalIdentifier: string
  __typename: string
}

interface XmlCreationResponse {
  success: boolean
  xml?: string
  log?: string
  xmlFileName?: string
  externalIdentifier: string
}

const useXmlDownload = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const notify = useNotify()

  const downloadBlob = (
    content: string,
    mimeType: string,
    fileName: string
  ) => {
    const blob = new Blob([content], { type: mimeType })
    const url = window.URL.createObjectURL(blob)
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    document.body.appendChild(a)
    a.click()
    window.URL.revokeObjectURL(url)
    a.remove()
  }

  const downloadXmls = async (
    payload: XmlCreationRequest[],
    batchSize = 1,
    delay = 10
  ) => {
    if (loading) return

    setLoading(true)

    const payloadString = encodeURIComponent(JSON.stringify(payload))
    const url = `${process.env.PUBLIC_URL}/api/getMetadataXml?payload=${payloadString}`

    try {
      const res = await authedFetch(url, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })

      const response = await res.json()

      if (response?.success) {
        const requestResponses: XmlCreationResponse[] =
          response.xmlCreationResponse
        let xmlSuccessCount = 0
        let xmlFailureCount = 0

        requestResponses.forEach((r) => {
          if (r.success) xmlSuccessCount++
          else xmlFailureCount++
        })

        const downloadBatch = (
          batch: XmlCreationResponse[],
          batchIndex: number
        ) => {
          return new Promise<void>((resolve) => {
            setTimeout(() => {
              batch.forEach((r, index) => {
                const fileNamePrefix =
                  r.xmlFileName ||
                  r.externalIdentifier ||
                  `UNKNOWN_IDENTIFIER-${batchIndex * batchSize + index}`
                if (r.success && r.xml) {
                  downloadBlob(
                    r.xml,
                    'application/xml',
                    `${fileNamePrefix}.xml`
                  )
                } else if (!r.success) {
                  downloadBlob(
                    r.log || '',
                    'text/plain',
                    `${fileNamePrefix}-errorlog.txt`
                  )
                }
              })
              resolve()
            }, batchIndex * delay)
          })
        }

        for (let i = 0; i < requestResponses.length; i += batchSize) {
          const batch = requestResponses.slice(i, i + batchSize)
          await downloadBatch(batch, i / batchSize)
        }

        if (xmlSuccessCount > 0) {
          notify(
            `Successfully created ${xmlSuccessCount} XML file(s).`,
            'success'
          )
        }
        if (xmlFailureCount > 0) {
          notify(
            `Failed to create ${xmlFailureCount} XML file(s). Please check the respective error-log file(s) in your browser's download folder for details.`,
            'warning'
          )
        }
      } else {
        notify(`Error: ${response?.error || response?.message}`, 'error')
      }
    } catch (error) {
      console.error(error)
      notify('An error occurred. Please try again.', 'error')
    } finally {
      setLoading(false)
    }
  }

  return { downloadXmls, loading }
}

export default useXmlDownload
