import logo from '../../imgs/logos/florianhomm.png'
import { CustomerTheme } from './customerThemeType'

export const FlorianhommTheme: Partial<CustomerTheme> = {
  primary: {
    main: '#001F3F',
    light: '#c2b195',
    dark: '#a67e3c',
  },
  secondary: {
    main: '#c2b195',
  },
}

export const FlorianhommLogo = (
  <img
    style={{
      width: 65,
      height: 'auto',
      paddingLeft: 25,
    }}
    src={logo}
  />
)
