import { Card, CardContent } from '@material-ui/core'
import { motion } from 'framer-motion'
import { connect } from 'react-redux'
import { InsightsGQL } from './insights/InsightsGQL'
import { InsightsGrafana } from './insights/InsightsGrafana'

function InsightsRenderer(props) {
  if (!props.hasInsights && !props.hasGrafana)
    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0 }}
      >
        <Card>
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5, ease: 'backOut' }}
          >
            <CardContent>
              <h1>Welcome to SMPL</h1>
              <p>
                This is the SMPL dashboard. You can use it to access data
                throughout the system.
              </p>
              <p>Please choose one of the menu options to start.</p>
            </CardContent>
          </motion.div>
        </Card>
      </motion.div>
    )
  return props.hasGrafana ? <InsightsGrafana /> : <InsightsGQL />
}

export const Insights = connect(function mapStateToProps({ apps }) {
  return {
    hasGrafana: apps.some(
      (app) => app.shortKey === 'insights-grafana' && app.state === 'enabled'
    ),
    hasInsights: apps.some(
      (app) => app.shortKey === 'insights' && app.state === 'enabled'
    ),
  }
})(InsightsRenderer)
