import { ErrorCard } from '../ErrorCard'
import { AppStore } from './AppStore'
import { Insights } from './Insights'
import { MovieCache } from './MovieCache'
import { CampaignStatistic } from './campaignStatistic'
import { futureOrdersReport } from './futureOrdersReport'
import { GraphQLDebugger } from './graphqldebugger'
import { InfrastructureOverview } from './infrastructureOverview'
import { LicenseReport } from './licenseReport'
import { UserCountry } from './userCountry/index'

export default {
  NotFound: ({ params: { appName } = {} }) => (
    <ErrorCard>Unknown App identifier: {appName}</ErrorCard>
  ),
  // data: Data,
  // auth: Auth,
  // admin: Admin,
  // acl: Acl,
  movieCache: MovieCache,
  insights: Insights,
  store: AppStore,
  graphqlDebugger: GraphQLDebugger,
  infrastructureOverview: InfrastructureOverview,
  userCountry: UserCountry,
  licenseReport: LicenseReport,
  futureOrdersReport: futureOrdersReport,
  campaignStatistic: CampaignStatistic,
}
