import {
  Avatar,
  Chip,
  createStyles,
  Tooltip,
  WithStyles,
  withStyles,
} from '@material-ui/core'
import LinkIcon from '@material-ui/icons/Link'
import React from 'react'
import { FieldProps } from 'react-admin'
import { compose } from 'recompose'
import { smplColors } from '../../layout/themes'
import { MaxLengthTextField } from './MaxLengthTextField'

type Props = FieldProps & {
  tooltip: string
  originalReference?: string
  fixedWidth?: boolean
}

const styles = createStyles({
  fixedWidth: {
    width: 150,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    display: 'inline-block',
  },
})

type InternalProps = Props & WithStyles<typeof styles>

function ShowTypeReferenceFieldImpl({
  originalReference,
  tooltip,
  classes,
  className,
  fixedWidth,
  ...fieldProps
}: InternalProps) {
  const { record, source } = fieldProps

  if (!source || !record || record[source] === undefined) {
    console.error(
      'ShowTypeReferenceFieldImpl, trying to render a field that is not in the record',
      source,
      record
    )
    return null
  }

  const name = record[source]
  return (
    <Tooltip title={tooltip + (name ? ' - ' + name : '')}>
      <Chip
        clickable
        avatar={
          <Avatar
            style={{
              background: smplColors.primary.light,
              color: '#ffffff',
              transform: 'scale(0.9)',
            }}
          >
            <LinkIcon />
          </Avatar>
        }
        label={
          !record && originalReference ? (
            <span
              title={originalReference}
              className={`${className || ''} ${
                fixedWidth ? classes.fixedWidth : ''
              }`}
            >
              {originalReference}
            </span>
          ) : (
            <MaxLengthTextField
              style={{ paddingTop: 3 }}
              {...fieldProps}
              className={`${className || ''} ${
                fixedWidth ? classes.fixedWidth : ''
              }`}
            />
          )
        }
      />
    </Tooltip>
  )
}
export const ShowTypeReferenceField = compose<InternalProps, Props>(
  withStyles(styles),
  React.memo
)(ShowTypeReferenceFieldImpl)
