import { Chip } from '@material-ui/core'
import { createStyles, withStyles, WithStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import AudiotrackIcon from '@material-ui/icons/Audiotrack'
import ClosedCaptionIcon from '@material-ui/icons/ClosedCaption'
import NoteIcon from '@material-ui/icons/Note'
import SubtitlesIcon from '@material-ui/icons/Subtitles'
import VideocamIcon from '@material-ui/icons/Videocam'
import get from 'lodash/get'
import React from 'react'
import { truncateString } from '../renderField'
import { NoData } from './NoData'

const styles = createStyles({
  icon: {
    width: 25,
    height: 25,
    paddingLeft: 5,
  },
  cell: {
    width: 200,
  },
})

type JsonObject = {
  assetsOverview: AssetsOverview
  availabilityStatus: Object
  fileSizeStatus: Object
  formatStatus: Object
  audioStatus: Object
}

type AssetsOverview = {
  assets: AssetsType
}

type AssetsType = {
  video: Array<any>
  audio: Array<AudioType>
  subtitle: Array<SubtitleType>
  forced_subtitles: Array<ForcedSubtitleType>
  notes: Array<NotesType>
}

type VideoType = {
  file_name: string
  'smpl:resolution': {
    'smpl:width': string
    'smpl:height': string
  }
}

type AudioType = {
  Filename: string
  TrackLanguage: string
  Preset: string
}

type SubtitleType = {
  file_name: string
}

type ForcedSubtitleType = {
  file_name: string
}

type NotesType = {
  file_name: string
}

type InnerProps = WithStyles<typeof styles>

const AssetsImpl = (props: any & InnerProps) => {
  const { source, record, keyPath, classes } = props

  let jsonObject: JsonObject
  let assets: AssetsType
  let videoAssets: Array<VideoType>
  let audioAssets: Array<AudioType>
  let subtitleAssets: Array<SubtitleType>
  let forcedSubtitleAssets: Array<ForcedSubtitleType>
  let notesAssets: Array<NotesType>

  if (record[source]) {
    jsonObject = JSON.parse(record[source])
  } else {
    return <NoData />
  }

  if (jsonObject) {
    // @ts-ignore
    assets = get(jsonObject.assetsOverview, keyPath)
    videoAssets = assets['video']
    subtitleAssets = assets['subtitle']
    forcedSubtitleAssets = assets['forced_subtitles']
    notesAssets = assets['notes']
    //@ts-ignore
    audioAssets = jsonObject['audioStatus']['audioAssets']
  } else {
    return <NoData />
  }

  const RenderTable = (assets: JSON) => {
    return Object.keys(assets).map((key) => {
      switch (key) {
        case 'video': {
          if (videoAssets) {
            return videoAssets.map((asset: VideoType) => {
              const res = asset['smpl:resolution']
              const width: string = res && res['smpl:width']
              const height: string = res && res['smpl:height']
              const resolution = `${width}x${height}`
              return (
                <TableRow key={asset['file_name']}>
                  <TableCell title={asset['file_name']}>
                    {truncateString(asset['file_name'], 30)}
                  </TableCell>

                  <TableCell>
                    <Chip
                      className={classes.text}
                      avatar={<VideocamIcon className={classes.icon} />}
                      label={key}
                    />
                  </TableCell>
                  <TableCell>
                    <Chip label={resolution} />
                  </TableCell>
                </TableRow>
              )
            })
          } else {
            return null
          }
        }
        case 'audio': {
          if (audioAssets) {
            return audioAssets.map((asset) => {
              const language = asset['TrackLanguage']
              const preset = asset['Preset']
              return (
                <TableRow key={asset['Filename']}>
                  <TableCell>{asset['Filename']}</TableCell>
                  <TableCell>
                    <Chip
                      avatar={<AudiotrackIcon className={classes.icon} />}
                      label={key}
                    />
                  </TableCell>
                  <TableCell className={classes.cell}>
                    <span>
                      <Chip label={language} />
                      <Chip label={preset} />
                    </span>
                  </TableCell>
                </TableRow>
              )
            })
          } else {
            return null
          }
        }
        case 'subtitle': {
          if (subtitleAssets) {
            return subtitleAssets.map((asset) => {
              return (
                <TableRow key={asset['file_name']}>
                  <TableCell>{asset['file_name']}</TableCell>
                  <TableCell>
                    <Chip
                      avatar={<ClosedCaptionIcon className={classes.icon} />}
                      label={key}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            })
          } else {
            return null
          }
        }
        case 'forced_subtitles': {
          if (forcedSubtitleAssets) {
            return forcedSubtitleAssets.map((asset) => {
              return (
                <TableRow key={asset['file_name']}>
                  <TableCell>{asset['file_name']}</TableCell>
                  <TableCell>
                    <Chip
                      avatar={<SubtitlesIcon className={classes.icon} />}
                      label={key}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            })
          } else {
            return null
          }
        }
        case 'notes': {
          if (notesAssets) {
            return notesAssets.map((asset) => {
              return (
                <TableRow key={asset['file_name']}>
                  <TableCell>{asset['file_name']}</TableCell>
                  <TableCell>
                    <Chip
                      avatar={<NoteIcon className={classes.icon} />}
                      label={key}
                    />
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              )
            })
          } else {
            return null
          }
        }
      }
      return <NoData key="no_data" />
    })
  }

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell align="left">File</TableCell>
          <TableCell align="left">Type</TableCell>
          <TableCell align="left" style={{ width: '350px !important' }}>
            Details
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {/* 
        //@ts-ignore */}
        {<RenderTable {...assets} />}
      </TableBody>
    </Table>
  )
}

export const Assets = withStyles(styles)(React.memo(AssetsImpl))
