export const DefaultColumnSettingVersion = 1.1 // if this change, the setting below will be pushed to the localStorage by force

// INFO: order matter
export const DefaultColumnSetting = {
  CmsCategory: {
    createdDate: true,
    isActive: true,
    slug: true,
    externalIdentifier: true,
    title: true,
    parentId: true,
    flags: true,
    sortOrder: true,
    platform: true,
    seoTitle: false,
    seoDescription: false,
    liveStreamUrl: false,
  },
  CmsCategory_CmsCategory: {
    createdDate: true,
    isActive: true,
    slug: true,
    externalIdentifier: true,
    title: true,
    parentId: true,
    flags: true,
    sortOrder: true,
    platform: true,
    seoTitle: false,
    seoDescription: false,
  },
  CmsCategory_CmsMovie: {
    createdDate: false,
    isActive: true,
    externalIdentifier: true,
    title: true,
    originalTitle: false,
    slug: true,
    status: false,
    licensorId: false,
    imdbIdentifier: false,
    fskRating: false,
    longSynopsis: false,
    mediumSynopsis: false,
    shortSynopsis: false,
    supershortSynopsis: false,
    productionYear: false,
    rating: false,
    coverImageId: false,
    widescreenImageId: false,
    seoTitle: false,
    seoDescription: false,
    seoSharingImageId: false,
    audioLanguages: false,
    subtitleLanguages: false,
    runtimeInSeconds: false,
    copyright: false,
    publishingRegions: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    isan: false,
    originalLanguage: false,
    productionCountry: false,
    encodingHouseId: false,
    headerImageId: false,
    logoId: false,
    dashAudioInfo: false,
    dashTextTrackInfo: false,
    hlsAudioInfo: false,
    hlsTextTrackInfo: false,
    searchIndex: false,
    cinemaStart: false,
    internationalReleaseType: false,
    flags: true,
    customImage920X368Id: false,
    customImage1440X540Id: false,
    customData: false,
    hasVideoSource: false,
    comment: true,
  },
  CmsCategory_CmsSery: {
    createdDate: true,
    isActive: true,
    title: true,
    originalTitle: false,
    slug: true,
    externalIdentifier: true,
    status: false,
    licensorId: false,
    imdbIdentifier: false,
    fskRating: false,
    longSynopsis: false,
    mediumSynopsis: false,
    shortSynopsis: false,
    supershortSynopsis: false,
    productionYear: false,
    rating: false,
    coverImageId: false,
    widescreenImageId: false,
    seoTitle: false,
    seoDescription: false,
    seoSharingImageId: false,
    audioLanguages: false,
    subtitleLanguages: false,
    runtimeInSeconds: false,
    copyright: false,
    publishingRegions: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    isan: false,
    originalLanguage: false,
    productionCountry: false,
    encodingHouseId: false,
    headerImageId: false,
    logoId: false,
    dashAudioInfo: false,
    dashTextTrackInfo: false,
    hlsAudioInfo: false,
    hlsTextTrackInfo: false,
    searchIndex: false,
    cinemaStart: false,
    internationalReleaseType: false,
    flags: true,
    customImage920X368Id: false,
    customImage1440X540Id: false,
    customData: false,
    comment: true,
  },
  CmsMovie: {
    createdDate: true,
    isActive: true,
    title: true,
    originalTitle: false,
    slug: true,
    externalIdentifier: true,
    status: false,
    licensorId: false,
    fskRating: false,
    imdbIdentifier: false,
    longSynopsis: true,
    mediumSynopsis: false,
    shortSynopsis: false,
    supershortSynopsis: false,
    productionYear: false,
    rating: false,
    coverImageId: true,
    widescreenImageId: true,
    seoTitle: false,
    seoDescription: false,
    seoSharingImageId: false,
    audioLanguages: false,
    subtitleLanguages: false,
    runtimeInSeconds: false,
    copyright: false,
    publishingRegions: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    isan: false,
    originalLanguage: false,
    productionCountry: false,
    encodingHouseId: false,
    headerImageId: true,
    logoId: true,
    customImage920X368Id: true,
    customImage1440X540Id: true,
    flags: true,
    dashAudioInfo: false,
    dashTextTrackInfo: false,
    hlsAudioInfo: false,
    hlsTextTrackInfo: false,
    cinemaStart: false,
    internationalReleaseType: false,
    customData: false,
    hasVideoSource: false,
    comment: false,
    searchIndex: false,
  },
  CmsEpisodes: {
    createdDate: true,
    isActive: true,
    title: true,
    originalTitle: false,
    slug: true,
    externalIdentifier: true,
    seasonId: true,
    episodeInSeason: true,
    seasonTitle: false,
    nextEpisodeId: false,
    status: false,
    licensorId: false,
    imdbIdentifier: false,
    fskRating: true,
    longSynopsis: true,
    mediumSynopsis: false,
    shortSynopsis: false,
    supershortSynopsis: false,
    productionYear: false,
    rating: false,
    coverImageId: true,
    widescreenImageId: true,
    flags: true,
    seoTitle: false,
    seoDescription: false,
    seoSharingImageId: false,
    audioLanguages: false,
    subtitleLanguages: false,
    runtimeInSeconds: false,
    copyright: false,
    publishingRegions: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    isan: false,
    originalLanguage: false,
    productionCountry: false,
    encodingHouseId: false,
    dashAudioInfo: false,
    dashTextTrackInfo: false,
    hlsAudioInfo: false,
    hlsTextTrackInfo: false,
    customData: false,
    hasVideoSource: false,
    searchIndex: false,
    comment: false,
  },
  CmsSeason: {
    createdDate: true,
    isActive: true,
    title: true,
    originalTitle: false,
    slug: true,
    externalIdentifier: true,
    status: false,
    licensorId: false,
    seriesId: true,
    seasonInSeries: true,
    imdbIdentifier: false,
    fskRating: false,
    longSynopsis: true,
    mediumSynopsis: false,
    shortSynopsis: false,
    supershortSynopsis: false,
    productionYear: false,
    rating: false,
    coverImageId: true,
    widescreenImageId: true,
    seoTitle: false,
    seoDescription: false,
    seoSharingImageId: false,
    audioLanguages: false,
    subtitleLanguages: false,
    runtimeInSeconds: false,
    copyright: false,
    publishingRegions: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    isan: false,
    originalLanguage: false,
    productionCountry: false,
    encodingHouseId: false,
    dashAudioInfo: false,
    dashTextTrackInfo: false,
    hlsAudioInfo: false,
    hlsTextTrackInfo: false,
    headerImageId: true,
    logoId: true,
    flags: true,
    customData: false,
    searchIndex: false,
    comment: false,
  },
  CmsSery: {
    createdDate: true,
    isActive: true,
    title: true,
    originalTitle: false,
    slug: true,
    externalIdentifier: true,
    status: false,
    licensorId: false,
    imdbIdentifier: false,
    fskRating: false,
    longSynopsis: true,
    mediumSynopsis: false,
    shortSynopsis: false,
    supershortSynopsis: false,
    productionYear: false,
    rating: false,
    coverImageId: true,
    widescreenImageId: true,
    seoTitle: false,
    seoDescription: false,
    seoSharingImageId: false,
    audioLanguages: false,
    subtitleLanguages: false,
    runtimeInSeconds: false,
    copyright: false,
    publishingRegions: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    isan: false,
    originalLanguage: false,
    productionCountry: false,
    encodingHouseId: true,
    headerImageId: true,
    logoId: true,
    customImage920X368Id: true,
    customImage1440X540Id: true,
    flags: true,
    dashAudioInfo: false,
    dashTextTrackInfo: false,
    hlsAudioInfo: false,
    hlsTextTrackInfo: false,
    cinemaStart: false,
    internationalReleaseType: false,
    customData: false,
    searchIndex: false,
    comment: false,
  },
  CmsContract: {
    createdDate: true,
    isActive: true,

    contractDate: true,
    name: true,
    contractType: true,
    parentId: true,
    contractStart: true,
    contractEnd: true,
    licensorId: true,
    activeCountries: true,
    properties: true,
    channels: true,
    portals: true,
    foreignPortals: true,
    ov: true,
    omu: true,
    languages: true,
    subtitleLanguages: true,
    uncut: true,
    exclusive: true,
    offlineAvailableDays: true,
    customData: false,
    comment: false,
  },
  CmsLicense: {
    createdDate: true,
    isActive: true,
    contractId: true,
    externalIdentifier: true,
    contentExternalIdentifier: true,
    licenseStart: true,
    licenseEnd: true,
    contentMovieId: true,
    contentEpisodeId: true,
    activeCountries: true,
    properties: true,
    channel: true,
    channels: true,
    portals: true,
    foreignPortals: true,
    ov: true,
    omu: true,
    languages: true,
    subtitleLanguages: true,
    uncut: true,
    exclusive: true,
    offlineAvailableDays: true,
    forceOffline: true,
    customData: false,
    comment: false,
  },
  CmsEpisode_WatchlistItem: {
    createdDate: true,
    externalMovieId: true,
    ownerId: true,
    currentTime: true,
    movieId: true,
    episodeId: true,
    finishedWatching: true,
    seasonId: true,
    seriesId: true,
  },
  CmsEpisode_CmsMovieContentPerson: {
    createdDate: true,
    connectionType: true,
    roleName: true,
    personId: true,
    contentMovieId: false,
    contentClipId: false,
    contentSeriesId: false,
    contentSeasonId: false,
    contentEpisodeId: true,
    contentTrailerId: false,
  },
  CmsEpisode_CmsLicense: {
    createdDate: true,
    isActive: true,
    contractId: true,
    externalIdentifier: true,
    contentExternalIdentifier: true,
    licenseStart: true,
    licenseEnd: true,
    contentMovieId: false,
    contentEpisodeId: true,
    activeCountries: true,
    properties: true,
    channel: true,
    channels: true,
    portals: true,
    foreignPortals: true,
    ov: true,
    omu: true,
    languages: true,
    subtitleLanguages: true,
    uncut: true,
    exclusive: true,
    offlineAvailableDays: true,
    forceOffline: true,
    customData: false,
    comment: false,
  },
  CmsMovie_WatchlistItem: {
    createdDate: true,
    externalMovieId: true,
    ownerId: true,
    currentTime: true,
    movieId: true,
    episodeId: true,
    finishedWatching: true,
    seasonId: true,
    seriesId: true,
  },
  CmsMovie_WishlistItem: {
    createdDate: true,
    ownerId: true,
    externalMovieId: true,
    movieId: true,
    seriesId: true,
    isVisible: true,
  },
  CmsMovie_CmsMovieContentPerson: {
    createdDate: true,
    connectionType: true,
    roleName: true,
    personId: true,
    contentMovieId: true,
    contentClipId: false,
    contentSeriesId: false,
    contentSeasonId: false,
    contentEpisodeId: false,
    contentTrailerId: false,
  },
  CmsMovie_CmsClip: {
    createdDate: true,
    title: true,
    externalIdentifier: true,
    imdbIdentifier: true,
    fskRating: true,
    longSynopsis: true,
    mediumSynopsis: true,
    shortSynopsis: true,
    supershortSynopsis: true,
    productionYear: true,
    rating: true,
    coverImageId: true,
    widescreenImageId: true,
    seoTitle: true,
    seoDescription: true,
    seoSharingImageId: true,
    audioLanguages: true,
    subtitleLanguages: true,
    runtimeInSeconds: true,
    licensorId: true,
    copyright: true,
    publishingRegions: true,
    introStartTime: true,
    introEndTime: true,
    creditsStartTime: true,
    isan: true,
    originalTitle: true,
    originalLanguage: true,
    productionCountry: true,
    encodingHouseId: true,
    status: false,
    parentSeriesId: true,
    parentMovieId: true,
    type: true,
    dashAudioInfo: true,
    dashTextTrackInfo: true,
    hlsAudioInfo: true,
    hlsTextTrackInfo: true,
    searchIndex: true,
    slug: true,
    comment: true,
    flags: true,
    customData: true,
    hasVideoSource: true,
  },
  CmsMovie_CmsMovieContentCategory: {
    createdDate: true,
    categoryId: true,
    contentMovieId: true,
    contentSeriesId: false,
    sortOrder: true,
    isValid: true,
  },
  CmsMovie_CmsLicense: {
    createdDate: true,
    isActive: true,
    contractId: true,
    externalIdentifier: true,
    contentExternalIdentifier: true,
    licenseStart: true,
    licenseEnd: true,
    contentMovieId: true,
    contentEpisodeId: false,
    activeCountries: true,
    properties: true,
    channel: true,
    channels: true,
    portals: true,
    foreignPortals: true,
    ov: true,
    omu: true,
    languages: true,
    subtitleLanguages: true,
    uncut: true,
    exclusive: true,
    offlineAvailableDays: true,
    forceOffline: true,
    customData: false,
    comment: false,
  },
  CmsSery_WishlistItem: {
    createdDate: true,
    ownerId: true,
    externalMovieId: true,
    movieId: true,
    seriesId: true,
    isVisible: true,
  },
  CmsSery_CmsMovieContentPerson: {
    createdDate: true,
    connectionType: true,
    roleName: true,
    personId: true,
    contentMovieId: false,
    contentClipId: false,
    contentSeriesId: true,
    contentSeasonId: false,
    contentEpisodeId: false,
    contentTrailerId: false,
  },
  CmsSery_CmsClip: {
    createdDate: true,
    title: true,
    externalIdentifier: true,
    imdbIdentifier: true,
    fskRating: true,
    longSynopsis: true,
    mediumSynopsis: true,
    shortSynopsis: true,
    supershortSynopsis: true,
    productionYear: true,
    rating: true,
    coverImageId: true,
    widescreenImageId: true,
    seoTitle: true,
    seoDescription: true,
    seoSharingImageId: true,
    audioLanguages: true,
    subtitleLanguages: true,
    runtimeInSeconds: true,
    licensorId: true,
    copyright: true,
    publishingRegions: true,
    introStartTime: true,
    introEndTime: true,
    creditsStartTime: true,
    isan: true,
    originalTitle: true,
    originalLanguage: true,
    productionCountry: true,
    encodingHouseId: true,
    status: false,
    parentSeriesId: true,
    parentMovieId: true,
    type: true,
    dashAudioInfo: true,
    dashTextTrackInfo: true,
    hlsAudioInfo: true,
    hlsTextTrackInfo: true,
    searchIndex: true,
    slug: true,
    comment: true,
    flags: true,
    customData: true,
    hasVideoSource: true,
  },
  CmsSery_CmsSeason: {
    createdDate: true,
    isActive: true,
    title: true,
    slug: true,
    originalTitle: false,
    externalIdentifier: true,
    status: false,
    seriesId: true,
    imdbIdentifier: false,
    fskRating: false,
    longSynopsis: false,
    mediumSynopsis: false,
    shortSynopsis: false,
    supershortSynopsis: false,
    productionYear: false,
    rating: false,
    coverImageId: false,
    widescreenImageId: false,
    seoTitle: false,
    seoDescription: false,
    seoSharingImageId: false,
    audioLanguages: false,
    subtitleLanguages: false,
    runtimeInSeconds: false,
    licensorId: false,
    copyright: false,
    publishingRegions: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    isan: false,
    originalLanguage: false,
    productionCountry: false,
    encodingHouseId: false,
    seasonInSeries: true,
    dashAudioInfo: false,
    dashTextTrackInfo: false,
    hlsAudioInfo: false,
    hlsTextTrackInfo: false,
    headerImageId: false,
    logoId: false,
    searchIndex: false,
    customData: false,
    flags: true,
    comment: true,
  },
  CmsSery_CmsMovieContentCategory: {
    createdDate: true,
    categoryId: true,
    contentMovieId: false,
    contentSeriesId: true,
    sortOrder: true,
    isValid: true,
  },
  CmsSeason_CmsMovieContentPerson: {
    createdDate: true,
    connectionType: true,
    roleName: true,
    personId: true,
    contentMovieId: false,
    contentClipId: false,
    contentSeriesId: false,
    contentSeasonId: true,
    contentEpisodeId: false,
    contentTrailerId: false,
  },
  CmsSeason_CmsEpisode: {
    createdDate: true,
    isActive: true,
    title: true,
    originalTitle: true,
    externalIdentifier: true,
    slug: true,
    seasonId: true,
    seasonTitle: true,
    episodeInSeason: true,
    nextEpisodeId: true,
    imdbIdentifier: false,
    status: false,
    fskRating: false,
    longSynopsis: false,
    mediumSynopsis: false,
    shortSynopsis: false,
    supershortSynopsis: false,
    productionYear: false,
    rating: false,
    coverImageId: false,
    widescreenImageId: false,
    seoTitle: false,
    seoDescription: false,
    seoSharingImageId: false,
    audioLanguages: false,
    subtitleLanguages: false,
    runtimeInSeconds: false,
    licensorId: false,
    copyright: false,
    publishingRegions: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    isan: false,
    originalLanguage: false,
    productionCountry: false,
    encodingHouseId: false,
    dashAudioInfo: false,
    dashTextTrackInfo: false,
    hlsAudioInfo: false,
    hlsTextTrackInfo: false,
    searchIndex: false,
    flags: true,
    customData: false,
    hasVideoSource: false,
    comment: true,
  },
  CmsContract_CmsLicense: {
    createdDate: true,
    isActive: true,
    contractId: true,
    externalIdentifier: true,
    contentExternalIdentifier: true,
    licenseStart: true,
    licenseEnd: true,
    contentMovieId: true,
    contentEpisodeId: true,
    activeCountries: true,
    properties: true,
    channel: true,
    channels: true,
    portals: true,
    foreignPortals: true,
    ov: true,
    omu: true,
    languages: true,
    subtitleLanguages: true,
    uncut: true,
    exclusive: true,
    customData: false,
    offlineAvailableDays: true,
    forceOffline: true,
    comment: true,
  },
  CmsContract_CmsContract: {
    createdDate: true,
    isActive: true,
    contractDate: true,
    name: true,
    contractType: true,
    parentId: true,
    contractStart: true,
    contractEnd: true,
    licensorId: true,
    activeCountries: true,
    properties: true,
    channels: true,
    portals: true,
    foreignPortals: true,
    ov: true,
    omu: true,
    languages: true,
    subtitleLanguages: true,
    uncut: true,
    exclusive: true,
    offlineAvailableDays: true,
    customData: false,
    comment: false,
  },
  CmsLicensor_CmsContract: {
    createdDate: true,
    isActive: true,
    contractDate: true,
    name: true,
    contractType: true,
    parentId: true,
    contractStart: true,
    contractEnd: true,
    licensorId: true,
    activeCountries: true,
    properties: true,
    channels: true,
    portals: true,
    foreignPortals: true,
    ov: true,
    omu: true,
    languages: true,
    subtitleLanguages: true,
    uncut: true,
    exclusive: true,
    offlineAvailableDays: true,
    customData: false,
    comment: false,
  },
  // ARD-106, Improve visibility of Transcoding UI by removing unnecessary  columns
  TranscodingJob: {
    progress: false,
    metadata: false,
    awsStatus: false,
    awsLastNotification: false,
    awsResponseCode: false,
    awsResponseDescription: false,
    movieId: false,
    episodeId: false,
    mediaInfo: false,
    comment: false,
    jobTemplate: false,
    clipId: false,
    runtimeInSeconds: false,
    introStartTime: false,
    introEndTime: false,
    creditsStartTime: false,
    jobType: false,
    createdDate: true,
    status: true,
    vendorId: true,
  },
}
