import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { green } from '@material-ui/core/colors'
import { makeStyles } from '@material-ui/core/styles'
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder'
import SaveIcon from '@material-ui/icons/Save'
import SearchIcon from '@material-ui/icons/Search'
import { useRefresh } from 'ra-core'
import React, { useEffect, useState } from 'react'
import {
  Record,
  useDataProvider,
  useNotify,
  useQueryWithStore,
} from 'react-admin'
import { useConfirmation } from '../ConfirmationService'
import { DataGridModal } from '../renderInput'

const useStyles = makeStyles(() => ({
  box: {
    border: 'thin solid #80808029',
    padding: 5,
    borderRadius: 5,
  },
  chip: {
    marginRight: 10,
    marginBottom: 10,
  },
  divider: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  catCard: {
    marginBottom: 10,
  },
}))

// INFO: this is curently an implementation for CmsMovie
// INFO: for using this with CmsSeries additional changes need to be made

// For using outside of <Form>
export const CategoriesFieldsWithExistingRecord = (props: any) => {
  const {
    record,
    resource,
  }: {
    record: Record
    resource: string
  } = props
  const target =
    resource === 'CmsMovie'
      ? 'cmsMovieContentCategoriesByContentMovieId'
      : resource === 'CmsSery'
      ? 'cmsMovieContentCategoriesByContentSeriesId'
      : null

  const { loaded, error, data } = useQueryWithStore({
    type: 'getManyReference',
    resource: resource,
    payload: {
      target: target,
      id: record.id,
    },
  })

  if (loaded) {
    return <CategoriesFields {...props} movieCategoriesData={data} />
  }
  if (error) {
    console.log(error)
  }
  return null
}

// For using outside of <Form>
export const CategoriesFields = (props: any) => {
  const dataProvider = useDataProvider()
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const [open, setOpen] = useState(false)
  const [selectedEntries, setSelectedEntries] = useState<Record[] | null>([])
  const [tempCat, setTempCat] = useState<
    Array<{ [fieldName in string]: string }>
  >([])
  const [parentCat, setParentCat] = useState<
    Array<{ [fieldName in string]: string }>
  >([])
  const confirm = useConfirmation()
  const {
    record,
    resource,
    setCreateEditTempCat,
    movieCategoriesData,
  }: {
    record: Record
    resource: string
    setCreateEditTempCat:
      | React.Dispatch<
          React.SetStateAction<
            {
              [x: string]: string
            }[]
          >
        >
      | undefined
    movieCategoriesData: any
  } = props
  const referenceTypeName = 'CmsCategory'

  const categoriesQuery = useQueryWithStore({
    type: 'getList',
    resource: referenceTypeName,
    payload: {},
  })
  const categoriesData = categoriesQuery.data

  useEffect(() => {
    if (categoriesData) {
      let catParent: Array<{ [fieldName in string]: string }> = []
      if (movieCategoriesData) {
        catParent = movieCategoriesData.map(
          (entry: { [fieldName in string]: string }) => {
            // find the existing cat in allCatList
            const catEntry = categoriesData.find(
              (cat: { [fieldName in string]: string }) =>
                cat.id === entry.categoryId
            )
            if (catEntry && catEntry.parentId !== null) {
              // find the parent Cat in the allCatList
              const parentCatEntry: {
                [fieldName in string]: string
              } = categoriesData.find(
                (cat: { [fieldName in string]: string }) =>
                  cat.id === catEntry.parentId
              )
              if (parentCatEntry) {
                return parentCatEntry
              }
            }
            return null
          }
        )
      }

      if (tempCat) {
        const tempCatParent: Array<
          { [fieldName in string]: string } | null
        > = tempCat.map((tCat) => {
          if (tCat.parentId !== null) {
            // find the parent Cat in the allCatList
            const parentCatEntry: {
              [fieldName in string]: string
            } = categoriesData.find(
              (cat: { [fieldName in string]: string }) =>
                cat.id === tCat.parentId
            )
            if (parentCatEntry) {
              return parentCatEntry
            }
          }
          return null
        })

        if (tempCatParent.length > 0) {
          tempCatParent.forEach((tCat) => {
            if (tCat !== null) {
              catParent.push(tCat)
            }
          })
        }
      }

      if (catParent.length > 0) {
        // remove duplicates and null
        const filteredCatParent = catParent
          .filter(
            (cat: { [fieldName in string]: string }, index: number) =>
              catParent.indexOf(cat) === index
          )
          .filter((cat: { [fieldName in string]: string }) => cat !== null)
        setParentCat(filteredCatParent)
      }
    }
  }, [movieCategoriesData, categoriesData, tempCat])

  // For Open/Close Selection Modal
  const openModal = () => {
    setOpen(true)
  }

  const closeModal = () => {
    setOpen(false)
  }

  // for saving tempCat to DB
  const makeConnectionToCategory = async (selectedId: string) => {
    try {
      await dataProvider.create('CmsMovieContentCategory', {
        data: {
          id: '000000000000000',
          categoryId: selectedId,
          contentMovieId: resource === 'CmsMovie' ? record.id : null,
          contentSeriesId: resource === 'CmsSery' ? record.id : null,
        },
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  const handleSaveChanges = async () => {
    const promises = tempCat.map(async (tCat) => {
      return await makeConnectionToCategory(tCat.id)
    })
    const resolveArr = await Promise.all(promises)
    const withoutError = resolveArr.find((r) => r === false)

    if (withoutError !== undefined && withoutError === false) {
      notify(
        'Adding categories failed. If this problem persists, please contact the support.',
        'warning'
      )
    } else {
      notify('Categories successfully added!', 'info')
    }
    refresh()
  }

  // For delete on temp
  const removeFromTemp = (selectedId: string) => {
    const newTempCat = tempCat.filter((tCat) => tCat.id !== selectedId)
    setTempCat(newTempCat)
    if (setCreateEditTempCat) {
      setCreateEditTempCat(newTempCat)
    }
  }

  // For delete on exitsing
  const onConfirm = async (selectedId: string) => {
    const resolve = await deleteCategory(selectedId)

    if (resolve) {
      notify('Category successfully removed!', 'info')
    } else {
      notify(
        'Removing category failed. If this problem persists, please contact the support.',
        'warning'
      )
    }
    refresh()
  }

  const onDeny = () => {
    // Do nothing
  }

  const queryDeleteConfirmation = (catId: string, label: string) => {
    confirm({
      catchOnCancel: true,
      title: 'Delete category "' + label + '" from "' + record.title + '"?',
      description:
        'Are you sure you want to delete the "' +
        label +
        '" category from this movie? Deleting will also remove all temporarily added categories. You may want to save before proceeding with the deletion.',
      id: catId,
      onConfirm: onConfirm,
      onDeny: onDeny,
    })
  }

  const deleteCategory = async (catId: string) => {
    const deleteMe = movieCategoriesData.find(
      (movCat: { [fieldName in string]: string }) => {
        if (
          movCat.categoryId === catId &&
          ((resource === 'CmsMovie' && movCat.contentMovieId === record.id) ||
            (resource === 'CmsSery' && movCat.contentSeriesId === record.id))
        ) {
          return movCat
        }
        return null
      }
    )

    if (!deleteMe) {
      return false
    }

    try {
      await dataProvider.delete('CmsMovieContentCategory', {
        id: deleteMe.id,
        previousData: { ...deleteMe },
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  // For Temp Add
  useEffect(() => {
    if (selectedEntries && selectedEntries.length > 0) {
      const existingCats: string[] = []
      const newTempCat: Record[] = []

      selectedEntries.forEach((selectedEntry) => {
        let existingCat

        // check in existing
        if (movieCategoriesData) {
          existingCat = movieCategoriesData.find(
            (entry: { [fieldName in string]: string }) => {
              if (entry.categoryId === selectedEntry.id) {
                return entry
              }
              return null
            }
          )
        }

        // check in temp
        if (!existingCat) {
          existingCat = tempCat.find((tCat) => tCat.id === selectedEntry.id)
        }

        if (!existingCat && selectedEntry.id) {
          newTempCat.push(selectedEntry)
        } else {
          existingCats.push(selectedEntry.slug)
        }
      })

      if (newTempCat.length > 0) {
        if (tempCat.length === 0) {
          setTempCat(newTempCat)
          if (setCreateEditTempCat) {
            setCreateEditTempCat(newTempCat)
          }
        } else {
          setTempCat([...tempCat, ...newTempCat])
          if (setCreateEditTempCat) {
            setCreateEditTempCat([...tempCat, ...newTempCat])
          }
        }
        notify("Categories temporarily added. Don't forget to save!", 'success')
      }

      if (existingCats.length > 0) {
        notify(
          `Following categories already exist on this movie: ${existingCats.join(
            ', '
          )}`,
          'warning'
        )
      }

      setSelectedEntries([])
    }
  }, [
    movieCategoriesData,
    notify,
    tempCat,
    setTempCat,
    setCreateEditTempCat,
    selectedEntries,
  ])

  if (categoriesData) {
    return (
      <div className={classes.box}>
        {/* Add Button */}
        <Chip
          label="Add Category"
          variant="outlined"
          icon={<SearchIcon />}
          onClick={() => openModal()}
          className={classes.chip}
        />
        {/* Save Button */}
        {tempCat.length > 0 && setCreateEditTempCat === undefined ? (
          <Chip
            label="Save Changes"
            variant="outlined"
            icon={<SaveIcon style={{ color: green[600] }} />}
            onClick={handleSaveChanges}
            className={classes.chip}
          />
        ) : null}

        <Divider className={classes.divider} />

        {/* Category Chip with no Parent */}
        {movieCategoriesData &&
          movieCategoriesData.map(
            (linkedEntry: { [fieldName in string]: string }) => {
              const catEntry = categoriesData
                .filter(
                  (cat: { [fieldName in string]: string }) =>
                    cat.parentId === null
                )
                .find(
                  (cat: { [fieldName in string]: string }) =>
                    cat.id === linkedEntry.categoryId
                )
              if (catEntry) {
                const catId = linkedEntry.categoryId
                return setCreateEditTempCat === undefined ? (
                  <Chip
                    key={catId}
                    label={
                      catEntry.platform
                        ? `${catEntry.title} (${catEntry.platform})`
                        : catEntry.title
                    }
                    color="primary"
                    onDelete={() =>
                      // Call a Dialog asking for delete confirmation
                      queryDeleteConfirmation(catId, catEntry.title)
                    }
                    className={classes.chip}
                  />
                ) : (
                  <Chip
                    key={catId}
                    label={
                      catEntry.platform
                        ? `${catEntry.title} (${catEntry.platform})`
                        : catEntry.title
                    }
                    color="primary"
                    // no support of delete in edit page for existing category entries
                    // onDelete={() =>
                    //   // Call a Dialog asking for delete confirmation
                    //   queryDeleteConfirmation(catId, catEntry.title)
                    // }
                    className={classes.chip}
                  />
                )
              }
              return null
            }
          )}

        {/* Temporary Category Chip with no Parent */}
        {tempCat.map((tCat) => {
          const catEntry = categoriesData.find(
            (cat: { [fieldName in string]: string }) => cat.id === tCat.id
          )
          if (catEntry.parentId) {
            const parentExist = parentCat.find(
              (pCat) => pCat.id === catEntry.parentId
            )
            if (parentExist) {
              return null
            }
          }

          const catId = tCat.id
          return (
            <Chip
              key={catId + 'temp'}
              label={
                catEntry.platform
                  ? `${catEntry.title} (${catEntry.platform})`
                  : catEntry.title
              }
              variant="outlined"
              icon={<QueryBuilderIcon />}
              onDelete={() =>
                // Call a Dialog asking for delete confirmation
                removeFromTemp(catId)
              }
              className={classes.chip}
            />
          )
        })}

        {/* Parent && Category Chips */}
        {(movieCategoriesData && movieCategoriesData.length > 0) ||
        (tempCat && tempCat.length > 0)
          ? parentCat.map((pCat: { [fieldName in string]: string }) => {
              const catParentId = pCat.id
              return (
                <div key={pCat.id}>
                  <Card className={classes.catCard}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {pCat.platform
                          ? `${pCat.title} (${pCat.platform})`
                          : pCat.title}
                      </Typography>
                      {/* Category Chip */}
                      {movieCategoriesData &&
                        movieCategoriesData.map(
                          (linkedEntry: { [fieldName in string]: string }) => {
                            const catEntry = categoriesData
                              .filter(
                                (cat: { [fieldName in string]: string }) =>
                                  cat.parentId === catParentId
                              )
                              .find(
                                (cat: { [fieldName in string]: string }) =>
                                  cat.id === linkedEntry.categoryId
                              )
                            if (catEntry) {
                              const catId = linkedEntry.categoryId
                              return setCreateEditTempCat === undefined ? (
                                <Chip
                                  key={catId}
                                  label={
                                    catEntry.platform
                                      ? `${catEntry.title} (${catEntry.platform})`
                                      : catEntry.title
                                  }
                                  color="primary"
                                  onDelete={() =>
                                    // Call a Dialog asking for delete confirmation
                                    queryDeleteConfirmation(
                                      catId,
                                      catEntry.title
                                    )
                                  }
                                  className={classes.chip}
                                />
                              ) : (
                                <Chip
                                  key={catId}
                                  label={
                                    catEntry.platform
                                      ? `${catEntry.title} (${catEntry.platform})`
                                      : catEntry.title
                                  }
                                  color="primary"
                                  // no support of delete in edit page for existing category entries
                                  // onDelete={() =>
                                  //   // Call a Dialog asking for delete confirmation
                                  //   queryDeleteConfirmation(catId, catEntry.title)
                                  // }
                                  className={classes.chip}
                                />
                              )
                            }
                            return null
                          }
                        )}

                      {/* Temp Category Chip */}
                      {tempCat &&
                        tempCat.map((tCat) => {
                          const catEntry = categoriesData
                            .filter(
                              (cat: { [fieldName in string]: string }) =>
                                cat.parentId === catParentId
                            )
                            .find(
                              (cat: { [fieldName in string]: string }) =>
                                cat.id === tCat.id
                            )
                          if (catEntry) {
                            const catId = tCat.id
                            return (
                              <Chip
                                key={catId + 'temp'}
                                label={
                                  catEntry.platform
                                    ? `${catEntry.title} (${catEntry.platform})`
                                    : catEntry.title
                                }
                                variant="outlined"
                                icon={<QueryBuilderIcon />}
                                onDelete={() =>
                                  // Call a Dialog asking for delete confirmation
                                  removeFromTemp(catId)
                                }
                                className={classes.chip}
                              />
                            )
                          }
                          return null
                        })}
                    </CardContent>
                  </Card>
                </div>
              )
            })
          : null}

        {/* Modal for adding a Category*/}
        <DataGridModal
          open={open}
          onClose={closeModal}
          resource={referenceTypeName}
          basePath={`/${referenceTypeName}`}
          location={{
            hash: '',
            pathname: `/${referenceTypeName}`,
            search: '',
            state: null,
          }}
          calledFromModal={true}
          // setFunction={setSelectedId}
          setFunctionEntries={setSelectedEntries}
          openedModal={setOpen}
        />
      </div>
    )
  }
  return <div></div>
}
