import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import RemoveIcon from '@material-ui/icons/Remove'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import SearchIcon from '@material-ui/icons/Search'
import { useRefresh } from 'ra-core'
import { useCallback, useEffect, useState } from 'react'
import {
  Record,
  useDataProvider,
  useNotify,
  useQuery,
  useQueryWithStore,
} from 'react-admin'
import { useField, useForm, useFormState } from 'react-final-form'
import { GenresCategory_allCmsCategories_nodes_cmsCategoriesByParentId_nodes } from '../../__generated__/GenresCategory'
import { useConfirmation } from '../ConfirmationService'
import { CreateModal } from '../CreateModal'
import {
  SMPL_TEMP_CATEGORIES,
  SMPL_TEMP_CATEGORIES_FOR_REMOVAL,
} from '../GenericEditPage'
import { DataGridModal } from '../renderInput'

const useStyles = makeStyles(() => ({
  box: {
    border: 'thin solid #80808029',
    padding: 5,
    borderRadius: 5,
  },
  chip: {
    margin: '5px 10px 10px 15px',
  },
  paper: {
    backgroundColor: 'white',
    width: '80vw',
    height: '80vh',
    left: '10vw',
    top: '10vh',
    overflow: 'auto',
  },
  divider: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  catCard: {
    marginBottom: 10,
  },
}))

// INFO: this is curently an implementation for CmsMovie
// INFO: for using this with CmsSeries additional changes need to be made

// For using inside of <Form>
export const CategoriesInputWithExistingRecord = (props: any) => {
  const {
    record,
    resource,
  }: {
    record: Record
    resource: string
  } = props

  let target = null

  switch (resource) {
    case 'CmsMovie':
      target = 'cmsMovieContentCategoriesByContentMovieId'
      break
    case 'CmsSery':
      target = 'cmsMovieContentCategoriesByContentSeriesId'
      break
    default:
      console.log(resource + ' is currently not supported in CategoriesInput')
      break
  }

  if (!target) return null

  const { loaded, error, data } = useQuery({
    type: 'getManyReference',
    resource: resource,
    payload: {
      target: target,
      id: record.id,
    },
  })

  if (loaded) {
    return <CategoriesInput {...props} existingCategoriesData={data} />
  }
  if (error) {
    console.log(error)
  }
  return null
}

export const CategoriesInput = (props: any) => {
  const {
    record,
    resource,
    existingCategoriesData,
    // used by batchEdit, removing of existing already supported
    // change logic to this also?
    supportCategoriesRemoval,
  }: {
    record: Record
    resource: string
    existingCategoriesData: any
    supportCategoriesRemoval: boolean
  } = props

  // Hooks
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const confirm = useConfirmation()

  //// Setup Temp Cat
  useField(
    SMPL_TEMP_CATEGORIES
    // don't do this or 'Maximum update depth exceeded' will happens
    //   {
    //   initialValue: [],
    // }
  )
  const form = useForm()
  const currentFieldState = useFormState()
  const tempCat: GenresCategory_allCmsCategories_nodes_cmsCategoriesByParentId_nodes[] =
    currentFieldState.values[SMPL_TEMP_CATEGORIES] || []
  // used by batchEdit for removal if supportCategoriesRemoval is true
  const tempCatForRemoval: GenresCategory_allCmsCategories_nodes_cmsCategoriesByParentId_nodes[] =
    currentFieldState.values[SMPL_TEMP_CATEGORIES_FOR_REMOVAL] || []

  //   console.log('tempCat', tempCat)
  //   console.log('tempCat4Removal', tempCatForRemoval)

  //// Setup Temp Cat
  const [selectedEntries, setSelectedEntries] = useState<Record[] | null>([])

  const [parentCat, setParentCat] = useState<
    Array<{ [fieldName in string]: string }>
  >([])

  // Query All Cat
  const referenceTypeName = 'CmsCategory'
  const categoriesQuery = useQueryWithStore({
    type: 'getList',
    resource: referenceTypeName,
    payload: {},
  })
  const categoriesData = categoriesQuery.data

  // without this the tempCat in depArr will trigger infinite loop of the useEffect below
  const [initParent, setInitParent] = useState(true)
  useEffect(() => {
    if (categoriesData && initParent) {
      let catParent: Array<{ [fieldName in string]: string }> = []
      // In case of exisitng record find the parent of existing cat
      if (existingCategoriesData) {
        catParent = existingCategoriesData.map(
          (entry: { [fieldName in string]: string }) => {
            // find the existing cat in allCatList
            const catEntry = categoriesData.find(
              (cat: { [fieldName in string]: string }) =>
                cat.id === entry.categoryId
            )
            if (catEntry && catEntry.parentId !== null) {
              // find the parent Cat in the allCatList
              const parentCatEntry: {
                [fieldName in string]: string
              } = categoriesData.find(
                (cat: { [fieldName in string]: string }) =>
                  cat.id === catEntry.parentId
              )
              if (parentCatEntry) {
                return parentCatEntry
              }
            }
            return null
          }
        )
      }

      if (tempCat || tempCatForRemoval) {
        const tempCatParent: Array<{ [fieldName in string]: string } | null> = [
          ...tempCat,
          ...tempCatForRemoval,
        ].map((tCat) => {
          if (tCat.parentId !== null) {
            // find the parent Cat in the allCatList
            const parentCatEntry: {
              [fieldName in string]: string
            } = categoriesData.find(
              (cat: { [fieldName in string]: string }) =>
                cat.id === tCat.parentId
            )
            if (parentCatEntry) {
              return parentCatEntry
            }
          }
          return null
        })

        if (tempCatParent.length > 0) {
          tempCatParent.forEach((tCat) => {
            if (tCat !== null) {
              catParent.push(tCat)
            }
          })
        }
      }

      if (catParent.length > 0) {
        // remove duplicates and null
        const filteredCatParent = catParent
          .filter(
            (cat: { [fieldName in string]: string }, index: number) =>
              catParent.indexOf(cat) === index
          )
          .filter((cat: { [fieldName in string]: string }) => cat !== null)
        setParentCat(filteredCatParent)
      }

      setInitParent(false)
    }
  }, [existingCategoriesData, categoriesData, tempCat, initParent])

  //// For Open/Close Selection Modal
  const [selectionOpen, setSelectionOpen] = useState(false)
  const openSelectionModal = useCallback(() => {
    setSelectionOpen(true)
  }, [])
  const closeSelectionModal = useCallback(() => {
    setSelectionOpen(false)
  }, [])
  const [addOrRemove, setAddOrRemove] = useState<'add' | 'remove'>('add')
  //// For Open/Close Selection Modal

  //// For Open/Close Create Modal
  const [createOpen, setCreateOpen] = useState(false)
  const openCreateModal = useCallback(() => {
    setCreateOpen(true)
  }, [])
  const closeCreateModal = useCallback(() => {
    setCreateOpen(false)
  }, [])
  const setFunctionCreate = useCallback((data: Record) => {
    setSelectedEntries([data])
  }, [])
  //// For Open/Close Create Modal

  //// For delete on temp
  const removeFromTemp = (selectedId: string) => {
    const newTempCats = tempCat.filter((tCat) => tCat.id !== selectedId)
    form.change(SMPL_TEMP_CATEGORIES, newTempCats)
  }
  const removeFromTempForRemoval = (selectedId: string) => {
    const newTempCats = tempCatForRemoval.filter(
      (tCat) => tCat.id !== selectedId
    )
    form.change(SMPL_TEMP_CATEGORIES_FOR_REMOVAL, newTempCats)
  }
  //// For delete on temp

  //// For delete on exitsing
  const onConfirm = async (selectedId: string) => {
    const resolve = await deleteCategory(selectedId)

    if (resolve) {
      notify('Category successfully removed!', 'info')
    } else {
      notify(
        'Removing category failed. If this problem persists, please contact the support.',
        'warning'
      )
    }
    refresh()
  }
  const onDeny = () => {
    // Do nothing
  }
  const queryDeleteConfirmation = (catId: string, label: string) => {
    console.log(record)
    confirm({
      catchOnCancel: true,
      title: 'Delete category "' + label + '"?',
      description:
        'Are you sure you want to delete the "' +
        label +
        '" category from this movie? Deleting will also remove all changes. You may want to save before proceeding with the deletion.',
      id: catId,
      onConfirm: onConfirm,
      onDeny: onDeny,
    })
  }
  const deleteCategory = async (catId: string) => {
    const deleteMe = existingCategoriesData
      ? existingCategoriesData.find(
          (movCat: { [fieldName in string]: string }) => {
            if (
              movCat.categoryId === catId &&
              ((resource === 'CmsMovie' &&
                movCat.contentMovieId === record.id) ||
                (resource === 'CmsSery' &&
                  movCat.contentSeriesId === record.id))
            ) {
              return movCat
            }
            return null
          }
        )
      : null

    if (!deleteMe) {
      return false
    }

    try {
      await dataProvider.delete('CmsMovieContentCategory', {
        id: deleteMe.id,
        previousData: { ...deleteMe },
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }
  //// For delete on exitsing

  //// For Temp Add
  useEffect(() => {
    if (selectedEntries && selectedEntries.length > 0) {
      if (addOrRemove === 'add') {
        const existingCats: string[] = []
        const newTempCats: Record[] = []

        selectedEntries.forEach((entry) => {
          let existingCat
          // check in existing
          if (existingCategoriesData) {
            existingCat = existingCategoriesData.find(
              (entry: { [fieldName in string]: string }) => {
                if (entry.categoryId === entry.id) {
                  return entry
                }
                return null
              }
            )
          }
          // check in temp
          if (!existingCat) {
            existingCat = tempCat.find((tCat) => tCat.id === entry.id)
          }
          if (!existingCat && entry.id) {
            // if not exist then push in form
            newTempCats.push(entry)
          } else {
            // else notify user that cat already exist
            existingCats.push(entry.slug)
          }
        })

        if (newTempCats.length > 0) {
          if (tempCat.length === 0) {
            form.change(SMPL_TEMP_CATEGORIES, newTempCats)
          } else {
            form.change(SMPL_TEMP_CATEGORIES, [...tempCat, ...newTempCats])
          }
          notify("Categories temporarily added. Don't forget to save!", 'info')
          setInitParent(true)
        }

        if (existingCats.length > 0) {
          notify(
            `Following categories already exist on this movie: ${existingCats.join(
              ', '
            )}`,
            'warning'
          )
        }
      } else {
        // batchEdit cat for removal
        if (selectedEntries.length > 0) {
          if (selectedEntries.length === 0) {
            form.change(SMPL_TEMP_CATEGORIES_FOR_REMOVAL, selectedEntries)
          } else {
            form.change(SMPL_TEMP_CATEGORIES_FOR_REMOVAL, [
              ...tempCatForRemoval,
              ...selectedEntries,
            ])
          }
          notify(
            "Categories to be removed temporarily added. Don't forget to save!",
            'info'
          )
          setInitParent(true)
        }
      }

      // reset selected
      setSelectedEntries([])
    }
  }, [existingCategoriesData, notify, selectedEntries])
  //// For Temp Add

  if (!categoriesData) {
    return <div></div>
  } else {
    return (
      <div className={classes.box}>
        {/* Add Cat Button */}
        <Chip
          label="Create Category"
          variant="outlined"
          icon={<AddIcon />}
          onClick={() => openCreateModal()}
          className={classes.chip}
        />

        {/* <Divider className={classes.divider} variant="middle" /> */}

        <Chip
          label="Select Category"
          variant="outlined"
          icon={<SearchIcon />}
          onClick={() => {
            setAddOrRemove('add')
            openSelectionModal()
          }}
          className={classes.chip}
        />

        {supportCategoriesRemoval ? (
          <Chip
            label="Remove Category"
            variant="outlined"
            icon={<RemoveIcon />}
            onClick={() => {
              setAddOrRemove('remove')
              openSelectionModal()
            }}
            className={classes.chip}
          />
        ) : (
          <></>
        )}

        <Divider className={classes.divider} />

        {/* Category Chip with no Parent */}
        {existingCategoriesData &&
          existingCategoriesData.map(
            (linkedEntry: { [fieldName in string]: string }) => {
              const catEntry = categoriesData
                .filter(
                  (cat: { [fieldName in string]: string }) =>
                    cat.parentId === null
                )
                .find(
                  (cat: { [fieldName in string]: string }) =>
                    cat.id === linkedEntry.categoryId
                )
              if (catEntry) {
                const catId = linkedEntry.categoryId
                return (
                  <Chip
                    key={catId}
                    label={
                      catEntry.platform
                        ? `${catEntry.title} (${catEntry.platform})`
                        : catEntry.title
                    }
                    color="primary"
                    onDelete={() =>
                      // Call a Dialog asking for delete confirmation
                      queryDeleteConfirmation(catId, catEntry.title)
                    }
                    className={classes.chip}
                  />
                )
              }
              return null
            }
          )}

        {/* Temporary Category Chip with no Parent */}
        {tempCat &&
          tempCat.length > 0 &&
          tempCat.map((tCat) => {
            let catEntry = categoriesData.find(
              (cat: { [fieldName in string]: string }) => cat.id === tCat.id
            )

            if (!catEntry) catEntry = tCat

            if (catEntry.parentId) {
              const parentExist = parentCat.find(
                (pCat) => pCat.id === catEntry.parentId
              )
              if (parentExist) {
                return null
              }
            }

            const catId = tCat.id
            return (
              <Chip
                key={catId + 'temp'}
                label={
                  catEntry.platform
                    ? `${catEntry.title} (${catEntry.platform})`
                    : catEntry.title
                }
                variant="outlined"
                icon={<AddCircleIcon />}
                onDelete={() => removeFromTemp(catId)}
                className={classes.chip}
              />
            )
          })}

        {/* Temporary Category Chip with no Parent */}
        {tempCatForRemoval &&
          tempCatForRemoval.length > 0 &&
          tempCatForRemoval.map((tCat) => {
            let catEntry = categoriesData.find(
              (cat: { [fieldName in string]: string }) => cat.id === tCat.id
            )

            if (!catEntry) catEntry = tCat

            if (catEntry.parentId) {
              const parentExist = parentCat.find(
                (pCat) => pCat.id === catEntry.parentId
              )
              if (parentExist) {
                return null
              }
            }

            const catId = tCat.id
            return (
              <Chip
                key={catId + 'temp' + 'removal'}
                label={
                  catEntry.platform
                    ? `${catEntry.title} (${catEntry.platform})`
                    : catEntry.title
                }
                variant="default"
                icon={<RemoveCircleIcon />}
                onDelete={() => removeFromTempForRemoval(catId)}
                className={classes.chip}
              />
            )
          })}

        {/* Parent && Category Chips */}
        {(existingCategoriesData && existingCategoriesData.length > 0) ||
        (tempCat && tempCat.length > 0) ||
        (tempCatForRemoval && tempCatForRemoval.length > 0)
          ? parentCat.map((pCat: { [fieldName in string]: string }) => {
              const catParentId = pCat.id
              return (
                <div key={pCat.id}>
                  <Card className={classes.catCard}>
                    <CardContent>
                      <Typography
                        className={classes.title}
                        color="textSecondary"
                        gutterBottom
                      >
                        {pCat.platform
                          ? `${pCat.title} (${pCat.platform})`
                          : pCat.title}
                      </Typography>
                      {/* Category Chip */}
                      {existingCategoriesData &&
                        existingCategoriesData.map(
                          (linkedEntry: { [fieldName in string]: string }) => {
                            const catEntry = categoriesData
                              .filter(
                                (cat: { [fieldName in string]: string }) =>
                                  cat.parentId === catParentId
                              )
                              .find(
                                (cat: { [fieldName in string]: string }) =>
                                  cat.id === linkedEntry.categoryId
                              )
                            if (catEntry) {
                              const catId = linkedEntry.categoryId

                              return (
                                <Chip
                                  key={catId}
                                  label={
                                    catEntry.platform
                                      ? `${catEntry.title} (${catEntry.platform})`
                                      : catEntry.title
                                  }
                                  color="primary"
                                  onDelete={() =>
                                    // Call a Dialog asking for delete confirmation
                                    queryDeleteConfirmation(
                                      catId,
                                      catEntry.title
                                    )
                                  }
                                  className={classes.chip}
                                />
                              )
                            }
                            return null
                          }
                        )}

                      {/* Temp Category Chip */}
                      {tempCat &&
                        tempCat.length > 0 &&
                        tempCat.map((tCat) => {
                          let catEntry = categoriesData
                            .filter(
                              (cat: { [fieldName in string]: string }) =>
                                cat.parentId === catParentId
                            )
                            .find(
                              (cat: { [fieldName in string]: string }) =>
                                cat.id === tCat.id
                            )

                          if (!catEntry) catEntry = tCat

                          if (
                            catEntry &&
                            catEntry.parentId &&
                            catEntry.parentId === catParentId
                          ) {
                            const catId = tCat.id
                            return (
                              <Chip
                                key={catId + 'temp'}
                                label={
                                  catEntry.platform
                                    ? `${catEntry.title} (${catEntry.platform})`
                                    : catEntry.title
                                }
                                variant="outlined"
                                icon={<AddCircleIcon />}
                                onDelete={() => removeFromTemp(catId)}
                                className={classes.chip}
                              />
                            )
                          }
                          return null
                        })}

                      {/* Temp 4 Removal Category Chip */}
                      {tempCatForRemoval &&
                        tempCatForRemoval.length > 0 &&
                        tempCatForRemoval.map((tCat) => {
                          let catEntry = categoriesData
                            .filter(
                              (cat: { [fieldName in string]: string }) =>
                                cat.parentId === catParentId
                            )
                            .find(
                              (cat: { [fieldName in string]: string }) =>
                                cat.id === tCat.id
                            )

                          if (!catEntry) catEntry = tCat

                          if (
                            catEntry &&
                            catEntry.parentId &&
                            catEntry.parentId === catParentId
                          ) {
                            const catId = tCat.id
                            return (
                              <Chip
                                key={catId + 'temp' + 'removal'}
                                label={
                                  catEntry.platform
                                    ? `${catEntry.title} (${catEntry.platform})`
                                    : catEntry.title
                                }
                                variant="outlined"
                                color="secondary"
                                icon={<RemoveCircleIcon />}
                                onDelete={() => removeFromTempForRemoval(catId)}
                                className={classes.chip}
                              />
                            )
                          }
                          return null
                        })}
                    </CardContent>
                  </Card>
                </div>
              )
            })
          : null}

        {/* Modal for creating new Category */}
        <CreateModal
          open={createOpen}
          onClose={closeCreateModal}
          onCloseDataGridModal={closeCreateModal}
          resource={referenceTypeName}
          hasEdit
          hasList
          hasShow
          // setFunction={setSelectedId}
          setFunctionEntry={setFunctionCreate}
          // fieldName={field.name}
        />

        {/* Modal for adding a Category*/}
        <DataGridModal
          open={selectionOpen}
          onClose={closeSelectionModal}
          resource={referenceTypeName}
          basePath={`/${referenceTypeName}`}
          location={{
            hash: '',
            pathname: `/${referenceTypeName}`,
            search: '',
            state: null,
          }}
          calledFromModal={true}
          setFunctionEntries={setSelectedEntries}
          openedModal={setSelectionOpen}
        />
      </div>
    )
  }
}
