import { makeStyles } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import AndroidIcon from '@material-ui/icons/Android'
import AppleIcon from '@material-ui/icons/Apple'
import AutorenewIcon from '@material-ui/icons/Autorenew'
import TvIcon from '@material-ui/icons/Tv'
import WebIcon from '@material-ui/icons/Web'
import WidgetsIcon from '@material-ui/icons/Widgets'

const useStyles = makeStyles({
  chip: { margin: 2 },
})

type FlagsFieldsProps = {
  flags: readonly (string | null)[]
  prefixAsIcon?: boolean
  inputProps: {
    flagData: {
      options: {
        label: string
        value: string
        flagOptions: { label: string; value: string }[]
      }[]
    }
  }
}

export const FlagsFields = (props: FlagsFieldsProps) => {
  const { flags, prefixAsIcon, inputProps } = props
  const {
    flagData: { options },
  } = inputProps
  const classes = useStyles()
  return (
    <div>
      {flags.map((f) => {
        if (typeof f !== 'string') return null
        const prefixOptionRegex = /^(.*?):/
        const prefixOptionArr = f.match(prefixOptionRegex)
        const prefixOption =
          prefixOptionArr !== null
            ? options.find((pO) => pO.value === prefixOptionArr[0])
            : null

        const { flagOptions } = prefixOption || { flagOptions: [] }

        const flRegex = /:(.*)/
        const flArr = f.match(flRegex)
        const flag = flArr && flagOptions.find((f) => f.value === flArr[1])

        let icon
        if (prefixAsIcon) {
          switch (prefixOption?.label) {
            case 'SmartTVs':
              icon = <TvIcon />
              break
            case 'Android':
              icon = <AndroidIcon />
              break
            case 'iOS':
              icon = <AppleIcon />
              break
            case 'Android & iOS':
              icon = (
                <span style={{ marginRight: 16 }}>
                  <AndroidIcon fontSize="small" />
                  <AppleIcon fontSize="small" />
                </span>
              )
              break
            case 'Web':
              icon = <WebIcon />
              break
            case 'Automation':
              icon = <AutorenewIcon />
              break
            case 'Widgets':
              icon = <WidgetsIcon />
              break
            default:
              break
          }
        }

        return (
          <Chip
            key={f}
            icon={icon}
            label={
              prefixOption && flag
                ? icon === undefined
                  ? `${prefixOption.label} - ${flag.label}`
                  : `${flag.label}`
                : f
            }
            color="primary"
            className={classes.chip}
            size={prefixAsIcon ? 'small' : 'medium'}
          />
        )
      })}
    </div>
  )
}
