import { Button } from '@material-ui/core'
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
} from 'ra-core'
import { useCallback, useState } from 'react'
import { connect } from 'react-redux'
import { useEndpointAvailable } from '../../../hooks/useEndpointAvailable'
import { PAYMENT_ENDPOINT_API } from '../../../lib/config'
import { NodeActionProps } from '../NodeActionType'
import { cancelSubscription } from '../subscription/lib/subscriptionService'
import { createSimpleAuthenticatedClient } from '../user/lib/simpleClient'

function CancelSubscription({
  record: subscription,
  resource,
  refreshView,
  showNotification,
}: NodeActionProps & {
  refreshView: typeof refreshViewAction
  showNotification: typeof showNotificationAction
}) {
  const [sending, setSending] = useState(false)

  let paymentEndpointAPI: string =
    // we have no selector here for platform like the add sub... not supported for Uni yet
    // also see cancel-scheduled-subscription.ts
    // platform && parsedPaymentAPI !== undefined
    //   ? parsedPaymentAPI[platform] // if role has access to one of the platform, then they should also be able to access the others as well
    //   :
    PAYMENT_ENDPOINT_API
  const endpoint = 'admin/subscription/cancel'
  const isEndpointAvailable = useEndpointAvailable(
    createSimpleAuthenticatedClient(paymentEndpointAPI),
    endpoint
  )

  const { managedBy, isRecurringActive } = subscription

  const clickHandler = useCallback(
    async function CancelSubscription(sendEmail: boolean) {
      if (sending) return
      setSending(true)
      const cleanUp = () => setSending(false)
      if (!subscription) {
        throw new Error('cannot cancel undefined subscription')
      }

      const { id } = subscription

      try {
        await cancelSubscription({
          subscriptionId: id as string,
          sendCancelEmail: sendEmail,
          endpoint,
        })
        showNotification(
          'Subscription canceled on payment provider side! Reloading Data'
        )
        refreshView()
      } catch (error) {
        console.error(
          'Could not cancel subscription on payment provider side',
          error
        )
        showNotification(
          'Error: Could not cancel subscription on payment provider side!',
          'warning'
        )
      }
      cleanUp()
    },
    [subscription, sending, refreshView, showNotification]
  )

  if (!isEndpointAvailable) {
    return null
  }

  if (!isRecurringActive) {
    return (
      <Button variant="outlined" disabled={true} size="small">
        Subscription has been cancelled.
      </Button>
    )
  } else if (managedBy) {
    return (
      <Button variant="outlined" disabled={true} size="small">
        {`Managed by ${managedBy}. Please cancel on their dashboard..`}
      </Button>
    )
  } else if (!managedBy && isRecurringActive) {
    return (
      <>
        <Button
          disabled={sending}
          onClick={() => clickHandler(false)}
          variant="outlined"
          size="small"
        >
          {sending ? 'Sending...' : `Cancel Subscription`}
        </Button>
        <Button
          disabled={sending}
          onClick={() => clickHandler(true)}
          variant="outlined"
          size="small"
        >
          {sending ? 'Sending...' : `Cancel Subscription with Email`}
        </Button>
      </>
    )
  }

  return null
}

CancelSubscription.label = 'CancelSubscription'

export default connect(null, {
  refreshView: refreshViewAction,
  showNotification: showNotificationAction,
})(CancelSubscription)
