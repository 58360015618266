import gql from 'graphql-tag'

export const MovieMutation = gql`
  mutation UpdateMovie($id: Guid!, $patch: CmsMoviePatch!) {
    updateCmsMovieById(input: { id: $id, cmsMoviePatch: $patch }) {
      cmsMovie {
        id
      }
    }
  }
`
export const MovieForSortableListFieldByIdQuery = gql`
  query cmsMovieById($id: Guid!) {
    qry: cmsMovieById(id: $id) {
      id
      title
      widescreenImage {
        masterUrl
      }
    }
  }
`
