import logo from '../../imgs/logos/flexgold.png'
import { CustomerTheme } from './customerThemeType'

export const FlexgoldTheme: Partial<CustomerTheme> = {
  primary: {
    main: '#243d6c',
    light: '#bea16b',
    dark: '#0a1d36',
  },
  secondary: {
    main: '#bea16b',
  },
}

export const FlexgoldLogo = (
  <img
    style={{
      width: 140,
      height: 'auto',
      paddingLeft: 25,
    }}
    src={logo}
  />
)
