import { Button, FormControl, InputLabel, makeStyles } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import toNumber from 'lodash/toNumber'
import {
  refreshView as refreshViewAction,
  showNotification as showNotificationAction,
} from 'ra-core'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import { useEndpointAvailable } from '../../../hooks/useEndpointAvailable'
import {
  PAYMENT_API_MAPPING,
  PAYMENT_ENDPOINT_API,
  isNetzkinoCustomer,
  isUniversumCustomer,
} from '../../../lib/config'
import { NodeActionProps } from '../NodeActionType'
import { createSimpleAuthenticatedClient } from './lib/simpleClient'
import { addSubscription } from './lib/userService'

const parsedPaymentAPI = JSON.parse(PAYMENT_API_MAPPING || '{}')

const useStyles = makeStyles({
  formControl: {
    width: 'fill-available',
    margin: 5,
  },
})

function AddSubscription({
  record: user,
  refreshView,
  showNotification,
}: NodeActionProps & {
  refreshView: typeof refreshViewAction
  showNotification: typeof showNotificationAction
}) {
  const [openAddingSubDialog, setOpenAddingSubDialog] = useState(false)

  const [sending, setSending] = useState(false)

  const [platform, setPlatform] = useState<string | undefined>()
  const [contentAccess, setContentAccess] = useState<string>('svod')
  const [numberOfDays, setNumberOfDays] = useState<number>(14)
  const classes = useStyles()

  let paymentEndpointAPI: string =
    platform && parsedPaymentAPI !== undefined
      ? parsedPaymentAPI[platform] // if role has access to one of the platform, then they should also be able to access the others as well
      : PAYMENT_ENDPOINT_API
  const endpoint = '/admin/subscription/add'

  const isEndpointAvailable = useEndpointAvailable(
    createSimpleAuthenticatedClient(paymentEndpointAPI),
    endpoint
  )

  const addSubscriptionClickHandler = async () => {
    if (!user) {
      throw new Error('cannot add subscription for undefined user')
    }
    const { id } = user
    if (typeof id !== 'string') {
      throw new Error('invalid user object! Id is missing!')
    }

    if (isUniversumCustomer) {
      if (!platform) {
        showNotification('Please select a platform for the subscription.')
      }
    }

    if (!numberOfDays || Number.isNaN(numberOfDays) || numberOfDays <= 0) {
      showNotification('Number of days must be greater than zero!', 'error')
      return
    }

    try {
      await addSubscription({
        userId: id,
        numberOfDays,
        contentAccess,
        paymentServiceClient: createSimpleAuthenticatedClient(
          platform && parsedPaymentAPI !== undefined
            ? parsedPaymentAPI[platform]
            : PAYMENT_ENDPOINT_API
        ),
        endpoint,
      })
      showNotification('Applied subscription! Reloading Data')
      handleAddingSubClose()
      refreshView()
    } catch (e) {
      console.error('could not add subscription', e)
      handleAddingSubClose()
      showNotification('Error: Could not add subscription!', 'warning')
    }
  }

  const handleClickAddingSubOpen = () => {
    setOpenAddingSubDialog(true)
  }

  const handleAddingSubClose = () => {
    setOpenAddingSubDialog(false)
  }

  const DropDownSelect = (props: {
    label: string
    options: {
      label: string
      value: string
    }[]
    value: string | undefined
    setFunc:
      | React.Dispatch<React.SetStateAction<string | undefined>>
      | React.Dispatch<React.SetStateAction<string>>
  }) => {
    const { label, options, value, setFunc } = props

    return (
      <FormControl className={classes.formControl}>
        <InputLabel htmlFor="max-width">{label}</InputLabel>
        <Select
          multiple={false}
          id="platform-type-select"
          value={value}
          onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
            setFunc(event.target.value as string)
          }}
        >
          {options.map((option: { [fieldName in string]: string }) => {
            return (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            )
          })}
        </Select>
      </FormControl>
    )
  }

  if (!isEndpointAvailable) {
    return null
  }

  return (
    <>
      <Button
        onClick={handleClickAddingSubOpen}
        variant="outlined"
        size="small"
      >
        {'Add Subscription'}
      </Button>
      <Dialog
        open={openAddingSubDialog}
        onClose={handleAddingSubClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Adding Subscription To User
        </DialogTitle>
        <DialogContent>
          {isUniversumCustomer ? (
            <DropDownSelect
              label="Platform"
              options={[
                { label: 'Home of Horror', value: 'Home of Horror' },
                { label: 'Filmtastic', value: 'Filmtastic' },
                { label: 'Arthouse CNMA', value: 'Arthouse CNMA' },
              ]}
              value={platform}
              setFunc={setPlatform}
            />
          ) : undefined}

          <DropDownSelect
            label="Content Access"
            options={[
              { label: 'svod', value: 'svod' },
              ...(isNetzkinoCustomer
                ? [{ label: 'adfree', value: 'adfree' }]
                : []),
            ]}
            value={contentAccess}
            setFunc={setContentAccess}
          />

          <FormControl className={classes.formControl}>
            <TextField
              autoFocus
              id="days input"
              label="Subscription Length In Days"
              type="number"
              value={numberOfDays}
              onChange={(event: React.ChangeEvent<{ value: unknown }>) => {
                setNumberOfDays(toNumber(event.target.value) as number)
              }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={async () => {
              setSending(true)
              await addSubscriptionClickHandler()
            }}
            color="primary"
          >
            Add Subscription
          </Button>
          <Button onClick={handleAddingSubClose} color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
AddSubscription.label = 'AddSubscription'

export default connect(null, {
  refreshView: refreshViewAction,
  showNotification: showNotificationAction,
})(AddSubscription)
