import { Button, TextField } from '@material-ui/core'
import { useState } from 'react'
import { Mutation } from 'react-admin'

export const CommentaryField = (props: any) => {
  const { record, keyPath } = props

  const [state, setState] = useState(record[keyPath])

  const options = {
    undoable: false,
    onSuccess: {
      notification: { body: 'Success', level: 'info' },
    },
    onFailure: {
      notification: { body: 'Error', level: 'warning' },
    },
  }
  const payload = {
    id: record.id,
    data: { ...record, comment: state },
    previousData: record,
  }

  return (
    <div>
      <TextField
        multiline
        defaultValue={state}
        onChange={(e) => setState(e.target.value)}
      />
      <Mutation
        type="UPDATE"
        resource="TranscodingJob"
        payload={payload}
        options={options}
      >
        {(comment) => {
          return (
            <Button
              onClick={() => {
                comment()
              }}
            >
              Save
            </Button>
          )
        }}
      </Mutation>
    </div>
  )
}
