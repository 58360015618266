import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import FormHelperText from '@material-ui/core/FormHelperText'
import Input from '@material-ui/core/Input'
import Modal from '@material-ui/core/Modal'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import CreateIcon from '@material-ui/icons/Create'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import SaveIcon from '@material-ui/icons/Save'
import { useState } from 'react'
import { Record, useNotify } from 'react-admin'
import { useMutation } from 'react-apollo-hooks'
import { BulkCreateVoucher } from '../../../__generated__/BulkCreateVoucher'
import { authedFetch } from '../../../dataProvider/authedFetch'
import { BulkCreateVoucherMutation } from '../../../graphQL/voucherCodes'

const useStyles = makeStyles({
  paper: {
    backgroundColor: 'white',
    maxWidth: '60vw',
    maxHeight: '80vh',
    overflowX: 'hidden',
    display: 'flex',
  },
  buttonGroup: {
    marginLeft: 10,
    width: 'min-content',
    '& > *': {
      width: 'fill-available',
      marginBottom: 7,
    },
  },
  codesWrapper: {
    overflow: 'auto',
    overflowX: 'auto',
    // maxHeight: '75vh',
    width: '100%',
    padding: '20px',
    backgroundColor: 'white',
    textAlign: 'center',
    // display: 'flex',
    '& > *': {
      marginRight: 10,
    },
  },
  codeArea: {
    //minHeight: '50vh', // this affect the height of the modal can not set maxHeight on textarea and minHeight == height
    resize: 'none',
    minWidth: 240,
  },
  bottomButtonGroup: {
    '& > *': {
      width: 'fill-available',
      marginTop: 4,
      marginBottom: 3,
    },
  },
  inModalButtonGroup: {
    display: 'flex',
    '& > div': {
      display: 'grid',
      '& > *': {
        marginTop: 10,
      },
    },
  },
})

const DefaultAmount = 1000

export const GenerateVoucherFields = (props: any) => {
  const { record }: { record: Record } = props
  const { id } = record
  const [amount, setAmount] = useState<number>(DefaultAmount)
  const [codes, setCodes] = useState<string[]>([])
  const [codeInput, setCodeInput] = useState<string[]>([])
  const [uploadingCodes, setUploadingCodes] = useState<boolean>(false)
  const [modalState, setModalState] = useState<'closed' | 'copy' | 'input'>(
    'closed'
  )
  const classes = useStyles()
  const notify = useNotify()
  const bulkCreate = useMutation<BulkCreateVoucher>(BulkCreateVoucherMutation)

  const onModalClose = () => {
    setModalState('closed')
  }

  const onGenerateClick = async () => {
    if (typeof amount !== 'number' || amount <= 0) {
      notify(
        'The number of voucher codes to be created should be greater than 0.',
        'error'
      )
      return
    }
    // call backend generateVoucher with the amount and wait for it to finish
    const bodyData = {
      amount: amount,
    }
    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    }
    try {
      setModalState('copy')
      setCodes([])
      setCodeInput([])
      const res = await authedFetch(
        process.env.PUBLIC_URL + '/api/generateVouchers',
        params
      )
      const resData: {
        success: boolean
        error?: {
          message: string
        }
        codes?: string[]
      } = await res.json()
      if (resData) {
        if (resData.success === false) {
          if (resData.error) {
            notify(
              'Generating voucher codes failed.' + resData.error.message,
              'error'
            )
            console.error(
              'Generating voucher codes failed.',
              resData.error.message
            )
          } else {
            notify(
              'Generating voucher codes failed. No codes were generated.',
              'error'
            )
            console.error(
              'Generating voucher codes failed. No codes were generated.'
            )
          }
          return
        }
        const voucherCodes: string[] | undefined = resData.codes

        if (voucherCodes) {
          notify(
            voucherCodes.length +
              ' voucher code(s) generated. Start uploading...',
            'success'
          )
          await createVoucher(voucherCodes)
          setCodes(voucherCodes) // for preview
        }
      }
    } catch (error) {
      notify(
        'Generating voucher codes failed. If this problem persists, please contact the support.',
        'error'
      )
      console.error(
        'Generating voucher codes failed. Communication with backend server failed.',
        error
      )
    }
  }

  const importBulk = async (partialVoucherCodes: string[]) => {
    try {
      // try to import them as chunk
      const res = await bulkCreate({
        variables: {
          codes: partialVoucherCodes,
          campaign: id,
        },
      })
      return true
    } catch (error) {
      // import them one by one and catch the duplicate
      return false
    }
  }

  const createVoucher = async (voucherCodes: string[]) => {
    // TODO: the DB bulkCreate should ideally take all the codes and return those duplicates instead of frontend implementation below
    // BUT I have no idea how to write it in SQL as we are using UNNEST there for bulk import
    if (voucherCodes.length <= 0) {
      notify('There are no codes to be saved to database.', 'error')
      return []
    }

    // we have to catch codes that already exist in the database
    // and tell the user about them
    const duplicateCodes = new Set<string>()

    const recursiveImport = async (c: string[]) => {
      const res = await importBulk(c)
      if (res) {
        return true
      } else {
        if (c.length === 1) {
          duplicateCodes.add(c[0])
          return false
        } else {
          const middleIndex = Math.ceil(c.length / 2)
          const firstHalf = c.slice().splice(0, middleIndex)
          const secondHalf = c.slice().splice(middleIndex, c.length)
          await Promise.allSettled([
            recursiveImport(firstHalf),
            recursiveImport(secondHalf),
          ])
          return true
        }
      }
    }

    await recursiveImport(voucherCodes)

    return Array.from(duplicateCodes)
  }

  const downloadTxtFile = (textArray: string[], name: string) => {
    const element = document.createElement('a')
    const file = new Blob([textArray.join('\r\n')], {
      type: 'text/plain',
    })
    element.href = URL.createObjectURL(file)
    element.download = `${name}.txt`
    document.body.appendChild(element)
    element.click()
  }

  // expand it with possiblities to generate 4~5 part codes?
  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      <div style={{ display: 'flex' }}>
        <div>
          <Input
            defaultValue={DefaultAmount}
            type="number"
            onChange={(e) => {
              const amount = parseInt(e.target.value)
              if (amount) {
                setAmount(amount)
              }
            }}
          />
          <FormHelperText id="amount-helper-text">
            Amount of voucher codes to generate
          </FormHelperText>
        </div>

        <div className={classes.buttonGroup}>
          <Button
            onClick={() => {
              onGenerateClick()
            }}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<CloudUploadIcon />}
          >
            Generate & Save
          </Button>
          <Button
            disabled={codes.length > 0 ? false : true}
            onClick={() => setModalState('copy')}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<FileCopyIcon />}
          >
            Copy Codes
          </Button>
          <Button
            onClick={() => {
              setCodes([])
              setModalState('input')
            }}
            size="small"
            variant="contained"
            color="primary"
            startIcon={<CreateIcon />}
          >
            Input Codes
          </Button>
        </div>
      </div>

      <Modal
        open={modalState != 'closed'}
        onClose={onModalClose}
        style={{ top: 10 + '%', margin: 'auto', width: '300px' }}
      >
        <div className={classes.paper}>
          <div className={classes.codesWrapper}>
            {codes.length > 0 || modalState == 'input' ? (
              <>
                {modalState === 'input' ? (
                  <Typography>
                    Please enter <strong>one code per line</strong>! <br />
                    Large amounts might not work, please avoid adding more than
                    1000 at once.
                    <br />
                    Each code must not exist in the database yet and be unique.
                  </Typography>
                ) : null}
                {/* INFO: this takes very long to render for above 100000 codes*/}
                <textarea
                  key={modalState}
                  className={classes.codeArea}
                  disabled={modalState === 'copy'}
                  defaultValue={
                    codes.length > 0
                      ? codes.join('\r\n')
                      : codeInput.join('\r\n')
                  }
                  onChange={(ev) => {
                    const lines = ev.target.value
                      .split(/\r?\n/)
                      .map((e) => e.trim())
                      .filter((e) => e.length > 1)
                    setCodeInput(lines)
                  }}
                  rows={
                    codes && codes.length >= 30
                      ? 30
                      : Math.max(10, codes.length)
                  }
                />
                <div className={classes.inModalButtonGroup}>
                  <div>
                    {modalState === 'copy' ? (
                      <>
                        <Button
                          disabled={codes.length > 0 ? false : true}
                          onClick={() =>
                            copy2Clipboard(
                              codes.join('\r\n'), // each code in a new line
                              notify,
                              'Codes added to clip board'
                            )
                          }
                          size="small"
                          variant="contained"
                          startIcon={<FileCopyIcon />}
                        >
                          Copy Codes
                        </Button>
                        <Button
                          disabled={codes.length > 0 ? false : true}
                          onClick={() =>
                            downloadTxtFile(codes, 'generatedCodes')
                          }
                          size="small"
                          variant="contained"
                          startIcon={<CloudDownloadIcon />}
                        >
                          Download Codes
                        </Button>
                      </>
                    ) : (
                      <Button
                        disabled={codeInput.length === 0 || uploadingCodes}
                        onClick={async () => {
                          if (codeInput.find((c) => c.length < 3)) {
                            notify(
                              'There are codes with less than 3 characters in the list! Please remove these',
                              'error'
                            )
                          } else if (codeInput.length < 0) {
                            notify('Please enter codes first', 'error')
                          } else {
                            setUploadingCodes(true)
                            const dup: string[] = await createVoucher(codeInput)
                            if (codeInput.length > 0 && dup.length === 0) {
                              notify(
                                'Voucher codes successfully added to this campaign.',
                                'success'
                              )
                            } else if (codeInput.length > 0 && dup.length > 0) {
                              downloadTxtFile(
                                dup,
                                'possibleDuplicateVoucherCodes'
                              )
                              notify(
                                'Error adding some voucher codes to this campaign.',
                                'error'
                              )
                            }
                            setUploadingCodes(false)
                            setModalState('closed')
                          }
                        }}
                        size="small"
                        variant="contained"
                        startIcon={<SaveIcon />}
                      >
                        Import{' '}
                        {uploadingCodes ? (
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: 10 }}
                          />
                        ) : null}
                      </Button>
                    )}
                  </div>
                </div>
              </>
            ) : null}
            {codes.length === 0 && modalState === 'copy' ? (
              <>
                <CircularProgress />
                <Typography variant="h6">
                  Please wait while voucher codes are generated and uploaded.
                  This may take a while...
                </Typography>
              </>
            ) : null}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export const copy2Clipboard = async (
  text: string,
  notificationFunction?: (
    message: string,
    type: 'warning' | 'info' | 'error'
  ) => void,
  notificationMessage?: string
) => {
  navigator.clipboard.writeText(text).then(
    function () {
      if (notificationFunction && notificationMessage) {
        notificationFunction(notificationMessage, 'info')
      }
    },
    function () {
      if (notificationFunction) {
        notificationFunction('Copy to clipboard failed.', 'error')
      }
    }
  )
}

// Doesn't work inside a modal
export const copyToClipboard = (
  text: string,
  notificationFunction?: (
    message: string,
    type: 'warning' | 'info' | 'error'
  ) => void,
  notificationMessage?: string
) => {
  var temp = document.createElement('textarea')
  temp.value = text
  temp.setAttribute('readonly', '')
  // @ts-ignore
  temp.style = { position: 'absolute', left: '-999px' }
  document.body.appendChild(temp)
  temp.select()
  document.execCommand('copy')
  document.body.removeChild(temp)
  if (notificationFunction && notificationMessage) {
    notificationFunction(notificationMessage, 'info')
  }
}
