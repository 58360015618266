import {
  CircularProgress,
  Container,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import SendIcon from '@material-ui/icons/Send'
import { useCallback, useMemo, useState } from 'react'
import { Button, Identifier, useNotify } from 'react-admin'
import { useDropzone } from 'react-dropzone'
import { headersForApi } from '../../commons/flexgold/constants'
import { authedFetch } from '../../dataProvider/authedFetch'
import { smplColors } from '../../layout/themes'
import { SMPL_FLEXGOLD_API_BASEURL } from '../../lib/config'

export interface UploadFormProps {
  brokerVaultId?: string | Identifier | undefined | null
  btnClasses?: string
  noPadding?: boolean
}

const useStyles = makeStyles({
  wrap: {
    padding: '2rem 2rem 2rem 2rem',
    borderRadius: 25,
  },
  padBottom: {
    paddingBottom: '2rem',
  },
  title: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: smplColors.primary.main,
  },
  subTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    color: smplColors.primary.main,
  },
  bodyText: {
    fontSize: 12,
    color: smplColors.primary.main,
    marginTop: '1rem',
  },
  spacer: {
    width: '100%',
    height: '2.5rem',
  },
  flexRow: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  permissionsPresetIndicators: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.25rem',
    paddingTop: '0.75rem',
    width: '100%',
  },
  indicator: {
    color: smplColors.primary.main,
    background: '#00000010',
    display: 'flex',
    flex: '1',
    gap: '0.5rem',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0.5rem 0.6rem',
    fontSize: 12,
    borderRadius: 5,
  },
  newBrokers: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.25rem',
  },
  newBrokerElement: {
    background: '#00000010',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '1rem',
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
    borderRadius: 5,
    position: 'relative',
  },
  NewbrokerInputWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    padding: '5px',
    width: 'calc(100% - 5rem)',
  },
  brokerOptionsWrapper: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    gap: '1rem',
    width: 'calc(100% - 8rem)',
    padding: '1rem',
    paddingLeft: '2.8rem',
  },
  accountRoles: {
    display: 'flex',
    flexDirection: 'column',
    padding: '0.25rem',
    fontSize: '0.75rem',

    '& span': {
      fontSize: 12,
    },
  },
  accountNumber: {
    fontSize: 15,
    paddingLeft: '1rem',
    paddingTop: '0.6rem',
  },
  closeButton: {
    position: 'absolute',
    right: 2,
    top: 5,
    transform: 'scale(0.75)',
  },
  dropzoneContainer: {
    backgroundColor: '#FFF',
  },
  dropzone: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: '2px',
    borderRadius: '2px',
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  noPadding: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  fileWrap: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  fileHeader: {
    marginTop: '0px',
    marginBottom: '.5rem',
  },
  asideStyle: {
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  fileDeleteIcon: {
    cursor: 'pointer',
  },
})

export const BrokerAgentUploadForm = ({
  brokerVaultId,
  noPadding,
}: UploadFormProps) => {
  const notify = useNotify()
  const classes = useStyles()
  const [file, setFile] = useState<File>()

  const onFileDrop = useCallback(
    (acceptedFiles: File[]) => {
      setFile(acceptedFiles[0])
    },
    [setFile]
  )
  const removeFile = useCallback(() => {
    setFile(null)
  }, [setFile])
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    noKeyboard: true,
    maxFiles: 1,
    onDrop: onFileDrop,
  })
  const [loading, setLoading] = useState(null)
  const [data, setData] = useState(null)
  const errors = useMemo(() => data?.parseErrors || data?.rowErrors, [data])

  const onSubmit = useCallback(
    async (e: React.FormEvent<HTMLButtonElement>) => {
      e.preventDefault()

      setLoading(true)
      setData(null)

      try {
        if (
          typeof SMPL_FLEXGOLD_API_BASEURL !== 'string' ||
          SMPL_FLEXGOLD_API_BASEURL === ''
        ) {
          notify('The SMPL_FLEXGOLD_API_BASEURL env is missing')
        } else {
          const url = new URL(
            `/admin/broker/${brokerVaultId}/agents/bulkInvite`,
            SMPL_FLEXGOLD_API_BASEURL
          )
          const urlString = url.toString()
          const formData = new FormData()
          formData.append('file', acceptedFiles[0], acceptedFiles[0].name)

          const response = await authedFetch(urlString, {
            method: 'POST',
            headers: headersForApi,
            body: formData,
          })

          const jsonData = await response.json()

          setData(jsonData)
        }
      } finally {
        setLoading(false)
      }
    },
    [acceptedFiles]
  )

  if (!brokerVaultId) {
    return null
  }

  return (
    <Container
      classes={{
        root: noPadding && classes.noPadding,
      }}
    >
      <Paper>
        <Container
          classes={{
            root: `${classes.wrap} MuiContainer-root`,
          }}
        >
          <div className={classes.padBottom}>
            <p>
              Uploading the CSV will create new broker agents (app users)
              associated with the current vault. The broker agents will receive
              their broker-invitation via email and can directly claim their
              user and login.
            </p>
          </div>
          <div {...getRootProps({ className: classes.dropzone })}>
            <input {...getInputProps()} />
            <p>Drag and Drop your csv file here (max filesize 200kb)</p>
          </div>
          <Container
            classes={{
              root: `${classes.wrap} MuiContainer-root`,
            }}
          >
            {file && (
              <aside className={classes.asideStyle}>
                <h4 className={classes.fileHeader}>Uploaded file</h4>
                <div key={file.name} className={classes.fileWrap}>
                  <span>{file.name}</span>{' '}
                  <DeleteIcon
                    color="error"
                    classes={{
                      root: `${classes.fileDeleteIcon} MuiIcon-root`,
                    }}
                    onClick={removeFile}
                  />
                </div>
              </aside>
            )}
          </Container>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Button
              variant="contained"
              onClick={onSubmit}
              style={{
                color: smplColors.gray[100],
                backgroundColor: smplColors.primary.main,
              }}
              endIcon={
                loading ? (
                  <CircularProgress
                    size="1rem"
                    style={{ color: smplColors.gray[100] }}
                  />
                ) : (
                  <SendIcon />
                )
              }
              disabled={loading}
            >
              <>Create agents from csv</>
            </Button>
            <a
              href="/examples/broker-invite-example.csv"
              style={{
                color: smplColors.primary.main,
                paddingTop: '1em',
                textAlign: 'center',
              }}
            >
              Download example csv
            </a>
          </div>
        </Container>
        {data &&
          !loading &&
          (!Array.isArray(errors) || errors.length < 1) &&
          !data?.error?.message && (
            <Container
              classes={{
                root: `${classes.wrap} MuiContainer-root`,
              }}
            >
              <h3>Success</h3>
              {data?.message && <p>{data.message}</p>}
            </Container>
          )}
        {data?.error?.message && (
          <Container
            classes={{
              root: `${classes.wrap} MuiContainer-root`,
            }}
          >
            <h3>Error</h3>
            <p>{data?.error?.message} bytes</p>
          </Container>
        )}
        {Array.isArray(errors) && errors.length > 0 && (
          <Container
            classes={{
              root: `${classes.wrap} MuiContainer-root`,
            }}
          >
            <h3>Errors</h3>
            {data?.message && <p>{data.message}</p>}
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">Email</TableCell>
                    <TableCell align="left">Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.map((row) => (
                    <TableRow key={row.email}>
                      <TableCell component="th" scope="row">
                        {row.email}
                      </TableCell>
                      <TableCell align="left">
                        <ul>
                          {row.error.map((e) => {
                            // eslint-disable-next-line
                            return <li>{e}</li>
                          })}
                        </ul>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        )}
      </Paper>
    </Container>
  )
}
