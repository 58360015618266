import React from 'react'

function NoDataImpl() {
  return (
    <span title="No Data available (null)">
      <svg
        style={{ width: '1.25rem', height: '1.2rem', opacity: 0.5 }}
        viewBox="0 0 24 24"
      >
        <path
          fill="currentColor"
          d="M22.11 21.46L2.39 1.73L1.11 3L4.06 5.95C2.78 7.63 2 9.72 2 12C2 17.5 6.5 22 12 22C14.28 22 16.37 21.23 18.05 19.94L20.84 22.73L22.11 21.46M12 20C7.58 20 4 16.42 4 12C4 10.27 4.56 8.68 5.5 7.38L16.62 18.5C15.32 19.45 13.73 20 12 20M8.17 4.97L6.72 3.5C8.25 2.56 10.06 2 12 2C17.5 2 22 6.5 22 12C22 13.94 21.44 15.75 20.5 17.28L19.03 15.83C19.65 14.69 20 13.39 20 12C20 7.58 16.42 4 12 4C10.61 4 9.31 4.35 8.17 4.97Z"
        />
      </svg>
    </span>
  )
}
export const NoData = React.memo(NoDataImpl)
