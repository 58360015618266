import { CreateJobCommandInput } from '@aws-sdk/client-mediaconvert/dist-types/commands/CreateJobCommand'
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import ButtonGroup from '@material-ui/core/ButtonGroup'
import { makeStyles } from '@material-ui/core/styles'
import BlockIcon from '@material-ui/icons/Block'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'
import MinimizeIcon from '@material-ui/icons/Minimize'
import PriorityHighIcon from '@material-ui/icons/PriorityHigh'
import StopIcon from '@material-ui/icons/Stop'
import WarningIcon from '@material-ui/icons/Warning'

import CheckIcon from '@material-ui/icons/Check'
import DoneAllIcon from '@material-ui/icons/DoneAll'
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty'
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo'
import StopCircle from '@material-ui/icons/Stop'
import { motion } from 'framer-motion'
import React from 'react'
import {
  Record,
  ReferenceField,
  TextField,
  useNotify,
  useRefresh,
} from 'react-admin'
import { smplColors } from '../../../layout/themes'
import {
  DEFAULT_TRANSCODING_QUEUE_ARN,
  HIGH_TRANSCODINGJOB_PRIORITY,
  LOW_TRANSCODINGJOB_PRIORITY,
  MEDIA_CONVERT_CONTROLLER_API,
  REGULAR_TRANSCODINGJOB_PRIORITY,
} from '../../../lib/config'
import { NoData } from '../../customFields/NoData'
import { createSimpleAuthenticatedClient } from '../user/lib/simpleClient'

const useStyles = makeStyles({
  activePriority: {
    position: 'relative',
    '&:after': {
      content: '"active"',
      position: 'absolute',
      width: '100%',
      height: 16,
      fontSize: '0.6rem',
      top: -8,
      left: '50%',
      background: smplColors.primary.main,
      transform: 'translate(-50%,-50%)',
      color: smplColors.secondary.main,
      borderRadius: '2px 2px 0 0',
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      width: '90%',
      height: '80%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      border: `3px solid ${smplColors.secondary.main}AA`,
    },
  },
})

/**
 *
 * Available Priorities (TODO: should we reevaluate/change the current available priority ladder? )
 *
 * LOW - running job on reserved queue with priority value 30 {@link LOW_TRANSCODINGJOB_PRIORITY}
 * MID - running job on reserved queue with priority value 40 {@link REGULAR_TRANSCODINGJOB_PRIORITY}
 * High - running job on reserved queue with priority value 45 {@link HIGH_TRANSCODINGJOB_PRIORITY}
 * ON_DEMAND - running job on on-demand queue (priority value does not matter here, since the queue apparently run all jobs in parallel, at least that happened during our stress test for ARD)
 * STOP - cancel job
 *
 */
type JobPriority = 'low' | 'mid' | 'high' | 'on_demand' | 'stop' // if changing, change these in mediaconvert controller as well

export const TranscodingJobPrioritySelectionDialog = (props: {
  record: Record
}) => {
  const notify = useNotify()
  const classes = useStyles()
  const { id, jobTemplate, status, vendorId } = props.record
  let parsedJob: CreateJobCommandInput

  // do not render priority selection if mandatory data is missing or job is not queued
  if (!jobTemplate || !id || !vendorId) {
    return null
  }

  parsedJob = JSON.parse(jobTemplate)

  const refresh = useRefresh()
  const [open, setOpen] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [priority, setPriority] = React.useState<JobPriority | null>(
    parseHumanReadablePriorityFromJobTemplate(parsedJob, status)
  )

  const endpoint: string = 'changePriorityForJob'

  const mediaConvertClient = createSimpleAuthenticatedClient(
    MEDIA_CONVERT_CONTROLLER_API
  )

  const currentPriority = parseHumanReadablePriorityFromJobTemplate(
    parsedJob,
    status
  )

  const handleClose = (
    event: React.SyntheticEvent<unknown>,
    reason?: string,
    priority?: JobPriority
  ) => {
    if (reason === 'backdropClick') {
      event.stopPropagation()
      return
    } else if (reason === 'cancel') {
      setOpen(false)
      refresh()
    } else if (reason === 'ok' && priority) {
      setLoading(true)
      /**
       * The endpoint in the mediaconvert controller service will search for a movie with the provided vendor id among
       * current movies in the mediaconvert job list with status "SUBMITTED" or "PROGRESSING".
       *
       * If a Job is found it will be cancelled and subsequently (if the priority was not "stop") created again in mediaconvert
       * with the updated priority and the job template will be updated accordingly in the DB.
       *
       */
      mediaConvertClient('POST', endpoint, {
        Message: {
          jobSystemId: id,
          jobTemplate,
          jobVendorId: vendorId,
          jobPriority: priority,
        },
      })
        .then((data) => {
          // notify based on response message
          notify('Update successfully.', 'success')
          setLoading(false)
          setOpen(false)
          refresh()
        })
        .catch((error) => {
          notify('Failed to change priority for this job.', 'error')
          console.error(error.error || error.message)
          setLoading(false)
          setOpen(false)
          refresh()
        })
    }
  }

  const renderPriorityButton = (priority: JobPriority, icon: JSX.Element) => (
    <Button
      onClick={(e) => {
        handleClickOpen(priority)
        e.stopPropagation() // Prevent rowclick event from triggering
      }}
      className={
        parseHumanReadablePriorityFromJobTemplate(parsedJob, status) ===
        priority
          ? classes.activePriority
          : ''
      }
      style={priority === 'on_demand' ? { background: '#E53935' } : null}
    >
      {icon}
    </Button>
  )

  const handleClickOpen = (selectedPriority: JobPriority) => {
    if (currentPriority === selectedPriority) {
      notify('this priority is already set for the job', 'warning')
    } else {
      setPriority(selectedPriority)
      setOpen(true)
    }
  }

  const shouldRenderPriorityButtons = status === 'IN_QUEUE'

  return isPriorityMenuAvailable(parsedJob, status) ? (
    <div>
      <ButtonGroup
        size="small"
        color="primary"
        variant="contained"
        style={{ transform: 'translateY(-9px)' }}
      >
        <Button
          onClick={(e) => {
            handleClickOpen('stop')
            e.stopPropagation() // otherwise it will trigger rowclick to show
          }}
        >
          <StopIcon />
        </Button>
        {/* Changing the priority is only available for queued jobs */}
        {shouldRenderPriorityButtons &&
          renderPriorityButton(
            'low',
            <DoubleArrowIcon
              style={{ transform: 'rotate(90deg) scale(0.8)' }}
            />
          )}
        {shouldRenderPriorityButtons &&
          renderPriorityButton(
            'mid',
            <MinimizeIcon
              style={{ transform: 'scale(1.4) translateY(-8px)' }}
            />
          )}
        {shouldRenderPriorityButtons &&
          renderPriorityButton(
            'high',
            <DoubleArrowIcon
              style={{ transform: 'rotate(-90deg) scale(0.8)' }}
            />
          )}
        {shouldRenderPriorityButtons &&
          renderPriorityButton(
            'on_demand',
            <PriorityHighIcon style={{ transform: ' scale(0.8)' }} />
          )}
      </ButtonGroup>
      <Dialog
        disableEscapeKeyDown
        open={open}
        onClose={handleClose}
        onClick={(e) => e.stopPropagation()} // otherwise it will trigger rowclick to show
        aria-labelledby="change-priority-confirm-dialog"
        style={{
          backgroundColor: 'rgba(255,255,255,0.1)',
          backdropFilter: 'blur(5px)',
        }}
      >
        <DialogTitle
          style={{
            textAlign: 'center',
            color: smplColors.primary.main,
            textTransform: 'uppercase',
            borderBottom: '1px solid #ccc',
          }}
        >
          Priority Change
        </DialogTitle>
        <DialogContent style={{ marginTop: '1rem' }}>
          <Box component="form" style={{ display: 'flex', flexWrap: 'wrap' }}>
            <DialogContentText
              style={{
                maxWidth: 350,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
              }}
            >
              <h3
                style={{ color: smplColors.primary.main }}
                color={smplColors.primary.main}
              >
                {vendorId}
              </h3>
              <motion.div
                initial={{ opacity: 0, scale: 0 }}
                animate={{ opacity: 1, scale: 3 }}
                transition={{ delay: 0.5 }}
                style={{ marginTop: '5rem', marginBottom: '4rem' }}
              >
                {(() => {
                  switch (priority) {
                    case 'low':
                      return (
                        <DoubleArrowIcon
                          color="primary"
                          style={{ transform: 'rotate(90deg)' }}
                        />
                      )
                    case 'mid':
                      return (
                        <MinimizeIcon
                          color="primary"
                          style={{ transform: 'scale(1.4) translateY(-8px)' }}
                        />
                      )
                    case 'high':
                      return (
                        <DoubleArrowIcon
                          color="primary"
                          style={{ transform: 'rotate(-90deg)' }}
                        />
                      )
                    case 'on_demand':
                      return (
                        <DoubleArrowIcon
                          color="primary"
                          style={{ transform: 'rotate(-90deg)' }}
                        />
                      )
                    case 'stop':
                      return <StopIcon color="primary" />
                    default:
                      return '?'
                  }
                })()}
              </motion.div>
              {`This will change the priority to "${priority.toUpperCase()}".`}
              {priority === 'on_demand' ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    color: 'red',
                  }}
                >
                  <br />
                  <WarningIcon />
                  <br />
                  This Transcoding-Job will be moved to the On-Demand-Queue
                  causing additional AWS costs
                </div>
              ) : null}
            </DialogContentText>
          </Box>
        </DialogContent>
        <DialogActions
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            size="small"
            disabled={loading}
            onClick={(event) => {
              handleClose(event, 'ok', priority)
            }}
          >
            {loading && (
              <CircularProgress
                color="primary"
                size={25}
                thickness={2}
                style={{ marginRight: '5px' }}
              />
            )}
            Ok
          </Button>
          <Button
            disabled={loading}
            color="primary"
            size="small"
            variant="contained"
            onClick={(event) => {
              handleClose(event, 'cancel')
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ) : (
    <div>
      <ButtonGroup
        size="small"
        color="primary"
        variant="contained"
        style={{ transform: 'translateY(-9px)' }}
      >
        <Button
          disabled
          onClick={(e) => {
            handleClickOpen('stop')
            e.stopPropagation() // otherwise it will trigger rowclick to show
          }}
        >
          <BlockIcon></BlockIcon>
        </Button>
      </ButtonGroup>
    </div>
  )
}

export const TranscodingJobAssetTitle: React.FC<{ record: Record }> = ({
  record,
}) => {
  if (record.clipId || record.movieId || record.episodeId) {
    return (
      <ReferenceField
        source={
          record.clipId ? 'clipId' : record.movieId ? 'movieId' : 'episodeId'
        }
        reference={
          record.clipId ? 'CmsClip' : record.movieId ? 'CmsMovie' : 'CmsEpisode'
        }
        record={record}
      >
        <TextField source="title" />
      </ReferenceField>
    )
  } else {
    return <NoData />
  }
}

export const TranscodingJobStatusIndicator: React.FC<{ record: Record }> = ({
  record,
}) => {
  switch (record.status) {
    case 'COMPLETED':
      return <DoneAllIcon style={{ color: 'lime' }} />
    case 'READY_FOR_QA':
      return <CheckIcon style={{ color: 'orange' }} />
    case 'ERROR':
      return <WarningIcon style={{ color: 'red' }} />
    case 'IN_QUEUE':
      return <HourglassEmptyIcon style={{ color: 'gray' }} />
    case 'READY_FOR_TRANSCODING':
      return <StopCircle style={{ color: 'gray' }} />
    case 'IN_PROGRESS':
      return <SlowMotionVideoIcon style={{ color: 'black' }} />
    default:
      return <></>
  }
}

const parseHumanReadablePriorityFromJobTemplate = (
  jobTemplate: CreateJobCommandInput,
  status: string
): JobPriority | null => {
  if (!isPriorityMenuAvailable(jobTemplate, status)) {
    return null
  } else {
    if (jobTemplate.Queue === DEFAULT_TRANSCODING_QUEUE_ARN) {
      return 'on_demand'
    } else if (jobTemplate.Priority === HIGH_TRANSCODINGJOB_PRIORITY) {
      return 'high'
    } else if (jobTemplate.Priority === REGULAR_TRANSCODINGJOB_PRIORITY) {
      return 'mid'
    } else if (jobTemplate.Priority === LOW_TRANSCODINGJOB_PRIORITY) {
      return 'low'
    } else return null
  }
}

const isPriorityMenuAvailable = (
  jobTemplate: CreateJobCommandInput,
  status: string
) => {
  return (
    (status === 'IN_QUEUE' || status === 'IN_PROGRESS') &&
    jobTemplate.Priority &&
    jobTemplate.Queue
  )
}
