import { Theme, useMediaQuery } from '@material-ui/core'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import MuiTab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import DescriptionIcon from '@material-ui/icons/Description'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTranslate } from 'ra-core'
import Labeled from 'ra-ui-materialui/esm/input/Labeled'
import * as React from 'react'
import { Record, TabProps } from 'react-admin'
import { Link } from 'react-router-dom'
import LocalStorage, { SHOW_STORAGE_KEY } from '../datagrid/LocalStorage'
import { useResourceTraverser } from '../hooks/useSchemaTraverser'
import { smplColors } from '../layout/themes'
import { compareByPostionInCreateEditTypeName } from './GenericEditPage'
import {
  CUSTOM_DATA,
  FieldDefinition,
  GroupOrder,
  IMAGE,
  NONE,
  OTHERS,
  TypeDisplaySetting,
  defaultListShowEditAndCreate,
} from './settings/TypeDisplaySetting'

const useStyles = makeStyles({
  tabWrapper: {
    display: 'block !important', // override display styles passed down from above
  },
  // TODO: placing groups 2 below groups 1 if display is small (handheld)
  groupsWrapper: {
    width: '100%',
    display: 'inline-grid',
    gridTemplateColumns: '50% 50%', // 2 cols for primary and secondary
    overflow: 'auto',
    '& > div': {
      padding: 5,
      '& > div': {
        marginBottom: 5,
        paddingTop: 10,
        overflow: 'auto',
      },
    },
  },
  groupContent: {
    display: 'inline-grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    overflow: 'auto',
    gridGap: '1rem',
    height: '100%',
    width: '100%',
  },
  groupContentFullWidth: {
    display: 'inline-grid',
    // gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    overflow: 'auto',
    gridGap: '1rem',
    height: '100%',
    width: '100%',
  },
  moreSpace: {
    gridColumn: 'span 2',
  },
  headerTypo: {
    paddingLeft: '1%',
  },
  panelSummary: {
    '& > div': {
      margin: '0px !important',
    },
    '& svg': {
      transition: '.5s ease',
    },

    '&:hover': {
      '& svg': {
        color: `${smplColors.secondary.main}!important`,
      },
    },
  },
})

// CustomTab.propTypes = {
//   className: PropTypes.string,
//   contentClassName: PropTypes.string,
//   children: PropTypes.node,
//   context: PropTypes.oneOf(['header', 'content']),
//   icon: PropTypes.element,
//   label: PropTypes.string.isRequired,
//   value: PropTypes.string,
// }

type CustomTabProps = TabProps & {
  basePath: string
  children: JSX.Element[]
  contentClassName?: string
  context: 'header' | 'content'
  icon: string | React.ReactElement
  label: string
  value?: string
  record: Record
  resource: string
  storage: typeof LocalStorage
}

/**
 * Checks if the field is eligible for inclusion in the {@link IMAGE} group.
 * Evaluates whether the field will be placed in the image group by default
 * or if it already belongs to another predefined group (see {@link TypeDisplaySetting}).
 */
export const checkFieldForImageGroupInclusion = (
  source,
  type: {
    [x: string]: FieldDefinition
  },
  imageFieldNames: string[]
) => {
  return (
    imageFieldNames.find((iFN) => source === iFN) &&
    (!type[source] || ('group' in type[source] && type[source].group === IMAGE))
  )
}

export const CustomTab = (props: CustomTabProps) => {
  const {
    basePath,
    children,
    context,
    icon,
    label,
    record,
    resource,
    value,
    storage = LocalStorage,
  } = props

  const translate = useTranslate()
  const classes = useStyles()
  const traverser = useResourceTraverser(resource)
  const fields = traverser ? traverser.fields : undefined
  const imageFields = fields
    ? fields.filter(
        (f) =>
          f.field.type.kind === 'OBJECT' && f.field.type.name === 'CmsImage'
      )
    : undefined
  const imageFieldNames = imageFields
    ? imageFields.map((iF) => iF.field.name + 'Id')
    : []

  const renderHeader = () => (
    // it seem like this is a deprecated form of MuiTab since some of the attributes are not shown in the mui doc
    // but needed here for it to work
    // @ts-ignore
    <MuiTab
      key={label}
      label={translate(label, { _: label })}
      value={value}
      icon={icon}
      component={Link}
      to={value}
    />
  )

  const renderedChildren = React.useMemo(() => {
    const type:
      | {
          [x: string]: FieldDefinition
        }
      | undefined = {
      ...defaultListShowEditAndCreate.show.fields,
      ...(TypeDisplaySetting[resource]?.show?.fields
        ? TypeDisplaySetting[resource]?.show?.fields
        : {}),
    }

    if (!type) {
      return children
    }

    let primaryChildrenGroup: {
      [fieldName in string]: JSX.Element[]
    } = {}
    let secondaryChildrenGroup: {
      [fieldName in string]: JSX.Element[]
    } = {}

    GroupOrder.primary.forEach((orderGroup) => {
      if (orderGroup !== OTHERS) {
        const thisGroupChildren = (children as JSX.Element[])
          .filter((child) => type[child.props.source]?.group === orderGroup)
          .sort((a, b) => {
            return compareByPostionInCreateEditTypeName(a, b)
          })
        if (thisGroupChildren && thisGroupChildren.length > 0) {
          primaryChildrenGroup[orderGroup] = thisGroupChildren
        }
      } else {
        const othersGroupChildren = (children as JSX.Element[])
          .filter(
            (child) =>
              type[child.props.source] === undefined ||
              type[child.props.source].group === OTHERS ||
              type[child.props.source].group === undefined
          )
          .sort((a, b) => {
            return compareByPostionInCreateEditTypeName(a, b)
          })
        if (othersGroupChildren && othersGroupChildren.length > 0) {
          primaryChildrenGroup[orderGroup] = othersGroupChildren
        }
      }
    })

    GroupOrder.secondary.forEach((orderGroup) => {
      if (orderGroup !== OTHERS) {
        const thisGroupChildren = (children as JSX.Element[])
          .filter((child) => {
            if (!fields) return type[child.props.source]?.group === orderGroup
            else {
              if (
                checkFieldForImageGroupInclusion(
                  child.props.source,
                  type,
                  imageFieldNames
                )
              ) {
                if (
                  (orderGroup === IMAGE && !type[child.props.source]) || // not defined yet in TDS -> new field
                  (orderGroup === IMAGE &&
                    /**  defined in {@link TypeDisplaySetting} and is not "Not Displaying" */
                    type[child.props.source] !== undefined &&
                    'group' in type[child.props.source] &&
                    type[child.props.source].group !== NONE)
                ) {
                  return true
                } else {
                  return false
                }
              } else {
                return type[child.props.source]?.group === orderGroup
              }
            }
          })
          .sort((a, b) => {
            return compareByPostionInCreateEditTypeName(a, b)
          })
        if (thisGroupChildren && thisGroupChildren.length > 0) {
          secondaryChildrenGroup[orderGroup] = thisGroupChildren
        }
      } else {
        const othersGroupChildren = (children as JSX.Element[])
          .filter((child) => {
            if (!fields)
              return (
                type[child.props.source] === undefined ||
                type[child.props.source].group === OTHERS ||
                type[child.props.source].group === undefined
              )
            else {
              if (imageFieldNames.find((iFN) => child.props.source === iFN)) {
                // default render of img in img group so it should not be render again
                return false
              } else {
                return (
                  type[child.props.source] === undefined ||
                  type[child.props.source].group === OTHERS ||
                  type[child.props.source].group === undefined
                )
              }
            }
          })
          .sort((a, b) => {
            return compareByPostionInCreateEditTypeName(a, b)
          })
        if (othersGroupChildren && othersGroupChildren.length > 0) {
          secondaryChildrenGroup[orderGroup] = othersGroupChildren
        }
      }
    })

    // preventing empty spaces on one side
    if (
      Object.keys(primaryChildrenGroup).length === 0 ||
      Object.keys(secondaryChildrenGroup).length === 0
    ) {
      const temp = { ...primaryChildrenGroup, ...secondaryChildrenGroup }
      primaryChildrenGroup = {}
      secondaryChildrenGroup = {}
      Object.keys(temp).forEach((orderGroup, index) => {
        if (index < Object.keys(temp).length / 2) {
          primaryChildrenGroup[orderGroup] = temp[orderGroup]
        } else {
          secondaryChildrenGroup[orderGroup] = temp[orderGroup]
        }
      })
    }

    return {
      primary: primaryChildrenGroup,
      secondary: secondaryChildrenGroup,
    }
  }, [resource, children])

  // get current groups expansion from localStorage
  const getGroupsExpansion = () => {
    return storage.get(SHOW_STORAGE_KEY, resource)
  }

  // save to localStorage whether that group was opened or closed for the next time opening a edit/create page of this resource
  const saveCurrentGroupExpansion = (group: string, expanded: boolean) => {
    const groupsExpansion = getGroupsExpansion()
    const newGroupsExpansion = {
      ...groupsExpansion,
      [group]: expanded,
    }
    storage.set(SHOW_STORAGE_KEY, resource, newGroupsExpansion)
  }

  const renderContent = () => (
    <span className={classes.tabWrapper}>
      {Array.isArray(renderedChildren) ? (
        React.Children.map(renderedChildren, (child) => {
          if (child) {
            return (
              <div key={child.props.source}>
                {child.props.addLabel ? (
                  <Labeled
                    label={child.props.label}
                    source={child.props.source}
                    basePath={basePath}
                    record={record}
                    resource={resource}
                  >
                    {child}
                  </Labeled>
                ) : typeof child.type === 'string' ? (
                  child
                ) : (
                  React.cloneElement(child, {
                    basePath,
                    record,
                    resource,
                  })
                )}
              </div>
            )
          } else {
            return null
          }
        })
      ) : (
        <div className={classes.groupsWrapper}>
          <div>
            {Object.keys(renderedChildren.primary).map((group) => {
              const groupsExpansion = getGroupsExpansion()
              if (
                groupsExpansion === undefined ||
                groupsExpansion[group] === undefined
              ) {
                saveCurrentGroupExpansion(group, true)
              }
              return GroupRenderer(
                renderedChildren.primary,
                group,
                classes,
                basePath,
                record,
                resource,
                groupsExpansion,
                saveCurrentGroupExpansion
              )
            })}
          </div>
          <div>
            {Object.keys(renderedChildren.secondary).map((group) => {
              const groupsExpansion = getGroupsExpansion()
              if (
                groupsExpansion === undefined ||
                groupsExpansion[group] === undefined
              ) {
                saveCurrentGroupExpansion(group, true)
              }
              return GroupRenderer(
                renderedChildren.secondary,
                group,
                classes,
                basePath,
                record,
                resource,
                groupsExpansion,
                saveCurrentGroupExpansion
              )
            })}
          </div>
        </div>
      )}
    </span>
  )

  return context === 'header' ? renderHeader() : renderContent()
}

const GroupRenderer = (
  renderedChildren: {
    [x: string]: JSX.Element[]
  },
  group: string,
  classes: any,
  basePath: string,
  record: Record,
  resource: string,
  groupsExpansion: any,
  saveCurrentGroupExpansion: (group: string, expanded: boolean) => void
) => {
  const isMd = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Accordion
      key={group}
      defaultExpanded={
        groupsExpansion === undefined || groupsExpansion[group] === undefined
          ? true // default true if first time opening this resource or a new group
          : groupsExpansion[group]
      }
      onChange={(e, expanded) => {
        saveCurrentGroupExpansion(group, expanded)
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.panelSummary}
      >
        <DescriptionIcon
          style={{ marginRight: 10, color: '#d3d3d3', marginTop: 3 }}
        />
        <Typography variant="h6" gutterBottom style={{ paddingTop: 5 }}>
          {group}
        </Typography>
      </AccordionSummary>
      <AccordionDetails style={{ padding: '8px 24px' }}>
        <div
          className={
            group === CUSTOM_DATA
              ? classes.groupContentFullWidth
              : classes.groupContent
          }
        >
          {React.Children.map(renderedChildren[group], (child) => {
            if (child) {
              return (
                <div
                  key={child.props.source}
                  className={
                    child.key === 'metadata' && !isMd ? classes.moreSpace : null
                  }
                >
                  {child.props.addLabel ? (
                    <Labeled
                      label={child.props.label}
                      source={child.props.source}
                      basePath={basePath}
                      record={record}
                      resource={resource}
                    >
                      {child}
                    </Labeled>
                  ) : typeof child.type === 'string' ? (
                    child
                  ) : (
                    React.cloneElement(child, {
                      basePath,
                      record,
                      resource,
                    })
                  )}
                </div>
              )
            } else {
              return null
            }
          })}
        </div>
      </AccordionDetails>
    </Accordion>
  )
}
