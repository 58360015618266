import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import { useCallback, useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import Select from 'react-dropdown-select'
import { useField, useForm, useFormState } from 'react-final-form'
import { PageExitPrompt } from '../../lib/PageExitPrompt'
import {
  SMPL_TEMP_STRINGLIST_FOR_REMOVAL,
  StringListForRemoval,
} from '../GenericEditPage'

const useStyles = makeStyles({
  flagWrapper: {
    marginTop: 8,
  },
  chip: { margin: 2 },
  divider: { marginBottom: 10, marginTop: 5 },
  selectWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: 10,
  },
  select: {
    width: '45%',
  },
  actionButton: {
    float: 'right',
    marginRight: 10,
  },
})

export const FlagsInput = (props: {
  fieldName: string
  label: string
  allowRemovalOption: boolean
  inputProps: {
    flagData: {
      options: {
        label: string
        value: string
        flagOptions: { label: string; value: string }[]
      }[]
    }
  }
}) => {
  const { fieldName, inputProps, allowRemovalOption } = props
  const {
    flagData: { options },
  } = inputProps

  const form = useForm()
  const currentFieldState = useFormState()
  const currentFieldValue = currentFieldState.values[fieldName]
  const field = useField(fieldName)

  // used if allowRemovalOption is true
  const [, updateState] = useState() // because form change of SMPL_TEMP_STRINGLIST_FOR_REMOVAL won't re-render
  // @ts-ignore
  const forceUpdate = useCallback(() => updateState({}), [])
  const fieldValuesForRemoval: StringListForRemoval =
    currentFieldState.values[SMPL_TEMP_STRINGLIST_FOR_REMOVAL] || []

  console.log('fieldValuesForRemoval', fieldValuesForRemoval)

  const [flag, setFlag] = useState<{
    label: string
    value: string
  } | null>()
  const [selectedPrefixOption, setSelectedPrefixOption] = useState<{
    label: string
    value: string
    flagOptions: { label: string; value: string }[]
  } | null>()
  const [shouldShowPrompt, setShouldShowPrompt] = useState(false)
  const classes = useStyles()
  const notify = useNotify()

  const getFlagFromSelection = () => {
    const prefix = selectedPrefixOption?.value
    const fl = flag?.value
    if (!prefix || !fl) {
      // notify user that one field is not set
      if (!prefix) {
        notify('Please select a target for this flag.', 'warning')
        return
      }
      if (!fl) {
        notify('Please select a valid flag for this target.', 'warning')
        return
      }
    }
    return prefix + fl
  }

  const handleAddFlag = () => {
    const flagWithPrefix = getFlagFromSelection()
    if (!flagWithPrefix) {
      return
    }
    // check to prevent adding same flag again
    const flagExist = currentFieldValue?.find(
      (f: string) => f === flagWithPrefix
    )
    if (flagExist) {
      notify('Flag already exists in this category.', 'warning')
      return
    }
    if (currentFieldValue !== null && currentFieldValue !== undefined) {
      field.input.onChange([...currentFieldValue, flagWithPrefix])
    } else {
      field.input.onChange([flagWithPrefix])
    }
  }

  const handleRemoveFlag = (flag: string, modus: 'add' | 'removal') => {
    if (modus === 'add') {
      if (currentFieldValue) {
        const newFlagsArray = currentFieldValue.filter(
          (f: string) => f !== flag
        )
        if (newFlagsArray.length === 0) {
          field.input.onChange(null)
        }
        field.input.onChange(newFlagsArray)
      }
    } else {
      const forRemovalFieldValues = fieldValuesForRemoval
      forRemovalFieldValues[fieldName] = forRemovalFieldValues[
        fieldName
      ].filter((f) => f !== flag)
      form.change(SMPL_TEMP_STRINGLIST_FOR_REMOVAL, forRemovalFieldValues)
      forceUpdate()
    }
  }

  const handleFlagRemoval = () => {
    const flagWithPrefix = getFlagFromSelection()
    if (!flagWithPrefix) {
      return
    }
    const flagsForRemoval = fieldValuesForRemoval[fieldName] || []
    if (!flagsForRemoval.includes(flagWithPrefix)) {
      flagsForRemoval.push(flagWithPrefix)
    }
    const forRemovalFieldValues = fieldValuesForRemoval
    forRemovalFieldValues[fieldName] = flagsForRemoval
    form.change(SMPL_TEMP_STRINGLIST_FOR_REMOVAL, forRemovalFieldValues)
    forceUpdate()
  }

  useEffect(() => {
    if (selectedPrefixOption && flag) {
      const newFlagWithPrefix = selectedPrefixOption.value + flag.value
      const flagExist = currentFieldValue?.find(
        (f: string) => f === newFlagWithPrefix
      )
      if (!flagExist) {
        setShouldShowPrompt(true)
      } else {
        setShouldShowPrompt(false)
      }
    }
  }, [currentFieldValue, flag, selectedPrefixOption])

  const FlagChip = (f: string, modus: 'add' | 'removal') => {
    const prefixOptionRegex = /^(.*?):/

    const prefixOptionArr = f.match(prefixOptionRegex)
    const selectedPrefixOption =
      prefixOptionArr && options.find((pO) => pO.value === prefixOptionArr[0])

    const { flagOptions } = selectedPrefixOption || { flagOptions: [] }

    const flRegex = /:(.*)/
    const flArr = f.match(flRegex)
    const flag = flArr && flagOptions.find((f) => f.value === flArr[1])

    return (
      <Chip
        key={f}
        label={
          selectedPrefixOption && flag
            ? `${selectedPrefixOption.label} - ${flag.label}`
            : f
        }
        color={modus === 'add' ? 'primary' : 'secondary'}
        className={classes.chip}
        onDelete={() => handleRemoveFlag(f, modus)}
      />
    )
  }

  return (
    <div className={classes.flagWrapper}>
      <PageExitPrompt
        when={shouldShowPrompt}
        message="There is an unsaved flag option. Are you sure you want to leave this page?"
      />
      <Typography color="textSecondary">Flags</Typography>
      {currentFieldValue !== null &&
        currentFieldValue?.map((f: string) => FlagChip(f, 'add'))}
      {allowRemovalOption &&
        fieldValuesForRemoval[fieldName] !== null &&
        fieldValuesForRemoval[fieldName]?.map((f: string) =>
          FlagChip(f, 'removal')
        )}
      <Divider className={classes.divider} />
      <div className={classes.selectWrapper}>
        <div className={classes.select}>
          <Typography color="textSecondary" variant="caption">
            Select Target
          </Typography>
          <Select
            options={options}
            values={[]}
            onChange={(values) => {
              setFlag(null)
              setSelectedPrefixOption(values[0])
            }}
            onDropdownOpen={() => {
              field.input.onFocus()
            }}
            onDropdownClose={() => {
              field.input.onBlur()
            }}
            dropdownHeight="150px"
            dropdownPosition="auto"
          />
        </div>
        <div className={classes.select}>
          <Typography color="textSecondary" variant="caption">
            Select Flag
          </Typography>
          <Select
            disabled={!selectedPrefixOption}
            key={
              selectedPrefixOption?.value === 'automation:'
                ? selectedPrefixOption.value
                : 'device'
            }
            options={
              selectedPrefixOption ? selectedPrefixOption.flagOptions : []
            }
            values={[]}
            onChange={(values) => {
              setFlag(values[0])
            }}
            onDropdownOpen={() => {
              field.input.onFocus()
            }}
            onDropdownClose={() => {
              field.input.onBlur()
            }}
            dropdownHeight="150px"
            dropdownPosition="auto"
          />
        </div>
      </div>
      <>
        {allowRemovalOption ? (
          <Button
            variant="contained"
            onClick={() => handleFlagRemoval()}
            color="secondary"
            className={classes.actionButton}
            size="small"
          >
            Remove
          </Button>
        ) : (
          <></>
        )}
        <Button
          variant="contained"
          onClick={() => handleAddFlag()}
          color="primary"
          className={classes.actionButton}
          size="small"
        >
          Add
        </Button>
      </>
    </div>
  )
}
