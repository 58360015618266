import {
  FormControlLabel,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Modal from '@material-ui/core/Modal'
import Tooltip from '@material-ui/core/Tooltip'
import BookmarksIcon from '@material-ui/icons/Bookmarks'
import DeleteIcon from '@material-ui/icons/Delete'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import ListIcon from '@material-ui/icons/List'
import SaveIcon from '@material-ui/icons/Save'
import { Autocomplete } from '@material-ui/lab'
import { makeStyles } from '@material-ui/styles'
import { Record } from 'ra-core'
import { ShowButton } from 'ra-ui-materialui'
import { ComponentType, useEffect, useState } from 'react'
import { CreateProps, EditProps, useNotify } from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import { Link } from 'react-router-dom'
import LocalStorage, {
  TEMPLATE_STORAGE_KEY,
  USE_TEMPLATE_STORAGE_KEY,
} from '../../datagrid/LocalStorage'
import { useResourceTraverser } from '../../hooks/useSchemaTraverser'
import { ExtendedEditInternalProps } from '../GenericEditPage'
import { CUSTOMDATASOURCE_FIELDNAME } from '../customForm/CategoryInputForm'

const useStyles = makeStyles(() => ({
  leftAction: {
    width: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-start',
    '& > div': {
      marginRight: 5,
    },
  },
  rightAction: {
    width: '50%',
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'flex-end',
    '& > div': {
      marginRight: 5,
    },
  },
  templateSelect: {
    width: 250,
    backgroundColor: 'rgba(0,0,0,0.00)',
  },
  paper: {
    backgroundColor: 'white',
    width: '30vw',
    height: '20vh',
    minHeight: 200,
    minWidth: 350,

    overflow: 'auto',
    overflowX: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputField: {
    borderRadius: 10,
    border: '2px solid #43a047',
    padding: 10,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'center',
    '&>div': {
      margin: 5,
    },
  },

  paperHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 10,
    marginTop: 20,
  },
  paperForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 40,
    '&>div': {
      margin: 5,
    },
  },
}))

export const SimpleCreateOrEditAction = (props: {
  CreateOrEdit: ComponentType<EditProps | CreateProps>
  extendedProps: ExtendedEditInternalProps
}) => {
  const { CreateOrEdit, extendedProps } = props
  const { basePath, id, resource } = extendedProps
  const classes = useStyles()

  const createOrEdit: 'Create' | 'Edit' | string = CreateOrEdit.name

  return (
    <>
      <div className={classes.leftAction}></div>
      <div className={classes.rightAction}>
        {createOrEdit === 'Create' ? (
          <ListButton resource={resource} />
        ) : (
          <ShowButton basePath={basePath} record={{ id: id }} />
        )}
      </div>
    </>
  )
}

type TEMPLATE = {
  value: {
    [x: string]: any
  }
  label: string
}

export const CreateOrEditAction = (props: {
  CreateOrEdit: ComponentType<EditProps | CreateProps>
  extendedProps: ExtendedEditInternalProps
  isSmartCategoryEnabled: boolean
  setSmartCategoryEnabled: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const classes = useStyles()
  const storage = LocalStorage

  // const redirect = useRedirect()
  // const notify = useNotify()
  // const formState = useFormState() // no access since we are not inside the form here
  // const form = useForm()
  // const dataProvider = useDataProvider()

  const {
    CreateOrEdit,
    extendedProps,
    setSmartCategoryEnabled,
    isSmartCategoryEnabled,
  } = props
  const createOrEdit: 'Create' | 'Edit' | string = CreateOrEdit.name
  const { basePath, id, resource } = extendedProps

  const [useTemplateInput, setUseTemplateInput] = useState(
    storage.get(USE_TEMPLATE_STORAGE_KEY, resource) === undefined
      ? false
      : storage.get(USE_TEMPLATE_STORAGE_KEY, resource)
  )

  let resourceTraverser
  if (resource) resourceTraverser = useResourceTraverser(resource)
  if (!resourceTraverser) {
    // should be there, big error if not
    throw new Error('Resource traverser not found.')
  }

  const editableFieldNames = resourceTraverser.editableFields.map((f) => f.name)

  return (
    <>
      <div className={classes.leftAction}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {useTemplateInput ? (
            <div>
              <Tooltip title="Close Template Selection">
                <Button
                  color="secondary"
                  size="medium"
                  onClick={() => {
                    setUseTemplateInput(!useTemplateInput)
                  }}
                  startIcon={<HighlightOffIcon />}
                >
                  Close Template
                </Button>
              </Tooltip>
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <Tooltip title="Display Template Selection">
                <Button
                  color="primary"
                  size="medium"
                  onClick={() => {
                    setUseTemplateInput(!useTemplateInput)
                  }}
                  startIcon={<BookmarksIcon />}
                >
                  Use Template
                </Button>
              </Tooltip>
            </div>
          )}
          {resource === 'CmsCategory' &&
          editableFieldNames.includes(CUSTOMDATASOURCE_FIELDNAME) ? (
            <div>
              <FormControlLabel
                style={{ marginLeft: '1em' }}
                control={
                  <Switch size="medium" checked={isSmartCategoryEnabled} />
                }
                label="USE SMART CATEGORY"
                onChange={(event, value) => {
                  setSmartCategoryEnabled(value)
                }}
              />
            </div>
          ) : null}
        </div>

        <div>
          <TemplateInputField
            resource={resource}
            useTemplateInput={useTemplateInput}
            editableFieldNames={editableFieldNames}
          />
        </div>
      </div>
      <div className={classes.rightAction}>
        {createOrEdit === 'Edit' ? (
          <ShowButton basePath={basePath} record={{ id: id }} />
        ) : null}
        <ListButton resource={resource} />
        {/* TODO: add Save Button here? */}
      </div>
    </>
  )
}

export const TemplateInputField = (props: any) => {
  const { resource, useTemplateInput, editableFieldNames } = props
  const storage = LocalStorage
  const formState = useFormState() // no access since we are not inside the form here
  const form = useForm()
  const classes = useStyles()
  const notify = useNotify()

  const [newTemplateName, setNewTemplateName] = useState('')
  const [selectedTemplate, setSelectedTemplate] = useState<TEMPLATE | null>(
    null
  )

  const [open, setOpen] = useState(false)

  // TODO: the templates should be retrieved from and saved in the DB instead

  useEffect(() => {
    if (storage.get(USE_TEMPLATE_STORAGE_KEY, resource) !== useTemplateInput) {
      storage.set(USE_TEMPLATE_STORAGE_KEY, resource, useTemplateInput)
    }
  }, [useTemplateInput])

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const resourceTempateData: {
    value: { [key in string]: any }
    label: string
  }[] =
    storage.get(TEMPLATE_STORAGE_KEY, resource) !== undefined
      ? storage.get(TEMPLATE_STORAGE_KEY, resource)
      : []

  const handleTemplateSelectChange = (
    _event: any,
    template: TEMPLATE | null
  ) => {
    if (template) {
      setSelectedTemplate(template)
    }
  }

  const handleUseTemplate = () => {
    if (selectedTemplate) {
      const { value: templateValues } = selectedTemplate

      const notOverwrittenFields: string[] = []

      // overwrite only if field is empty
      Object.keys(templateValues).forEach((fieldName) => {
        if (formState.values[fieldName] && formState.values[fieldName] !== '') {
          notOverwrittenFields.push(fieldName)
        } else {
          form.change(fieldName, templateValues[fieldName])
        }
      })

      notify('Template applied.', 'success')

      if (notOverwrittenFields.length > 0) {
        notify(
          `Following fields have not been overwritten: ${notOverwrittenFields.join(
            ', '
          )}`
        )
      }
    }
  }

  const handleRemoveTemplate = () => {
    if (selectedTemplate) {
      const newLocalTemplates = (storage.get(
        TEMPLATE_STORAGE_KEY,
        resource
      ) as TEMPLATE[]).filter((temp) => temp.label !== selectedTemplate.label)

      storage.set(TEMPLATE_STORAGE_KEY, resource, newLocalTemplates)

      setSelectedTemplate(null)
      notify(`Template '${selectedTemplate.label}' deleted.`, 'success')
    } else {
      notify('Please select a template to delete.')
    }
  }

  const cleanValues = (values: Record) => {
    const newValues: { [x: string]: any } = {}

    editableFieldNames.forEach((fname: string) => {
      if (values[fname]) {
        newValues[fname] = values[fname]
      }
    })

    return newValues
  }

  const handleSaveTemplate = () => {
    if (newTemplateName.length > 0) {
      storage.set(TEMPLATE_STORAGE_KEY, resource, [
        ...(storage.get(TEMPLATE_STORAGE_KEY, resource) !== undefined
          ? (storage.get(TEMPLATE_STORAGE_KEY, resource) as TEMPLATE[])
          : []),
        {
          label: newTemplateName,
          value: cleanValues(formState.values as Record),
        },
      ])
      notify(`Template '${newTemplateName}' saved.`, 'success')
      setNewTemplateName('')
      setOpen(false)
    } else {
      notify('Please enter a name for the new template.', 'warning')
    }
  }

  if (!useTemplateInput) return null

  return (
    <>
      {useTemplateInput ? (
        <div className={classes.inputField}>
          <Autocomplete
            id="template-select"
            options={resourceTempateData}
            className={classes.templateSelect}
            onChange={handleTemplateSelectChange}
            value={selectedTemplate}
            size="medium"
            renderInput={(params) => (
              <TextField {...params} variant="outlined" label="Template" />
            )}
            getOptionLabel={(option) => {
              return option.label
            }}
          />
          <div style={{ display: 'flex' }}>
            <div>
              <Tooltip title="Use Selected Template">
                <Button
                  color="primary"
                  size="medium"
                  onClick={handleUseTemplate}
                  startIcon={<FileCopyIcon />}
                >
                  Use
                </Button>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Remove Selected Template">
                <Button
                  color="secondary"
                  size="medium"
                  onClick={handleRemoveTemplate}
                  startIcon={<DeleteIcon />}
                >
                  Remove
                </Button>
              </Tooltip>
            </div>
            <div>
              <Tooltip title="Save Current Form As New Template">
                <Button
                  color="primary"
                  size="medium"
                  onClick={handleOpen}
                  startIcon={<SaveIcon />}
                >
                  Save
                </Button>
              </Tooltip>
            </div>
          </div>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-new-template-name"
            aria-describedby="modal-new-template-description"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                top: `${top}%`,
                margin: 'auto',
              }}
              className={classes.paper}
            >
              <div className={classes.paperHeader}>
                <Typography variant="h5">Saving New Template</Typography>
              </div>
              <div className={classes.paperForm}>
                <TextField
                  variant="filled"
                  size="medium"
                  label="New Template Name"
                  onChange={(event) => {
                    setNewTemplateName(event.target.value)
                  }}
                />
                <div>
                  <Button
                    color="primary"
                    size="medium"
                    onClick={handleSaveTemplate}
                    startIcon={<SaveIcon />}
                  >
                    Save Template
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      ) : null}
    </>
  )
}

export const ListButton = (props: { resource: string | undefined }) => {
  const { resource } = props
  return (
    <Button
      size="medium"
      aria-label="Go To List Page"
      component={Link}
      to={`/${resource}/`}
      color="primary"
      startIcon={<ListIcon />}
    >
      LIST
    </Button>
  )
}
