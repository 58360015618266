import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { fade } from '@material-ui/core/styles/colorManipulator'
import ActionCheck from '@material-ui/icons/CheckCircle'
import AlertError from '@material-ui/icons/ErrorOutline'
import { useTranslate } from 'ra-core'
import { FC, MouseEventHandler, useCallback } from 'react'

const useStyles = makeStyles(
  (theme) => ({
    contentText: {
      minWidth: 400,
    },
    confirmPrimary: {
      color: theme.palette.primary.main,
    },
    confirmWarning: {
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: fade(theme.palette.error.main, 0.12),
        // Reset on mouse devices
        '@media (hover: none)': {
          backgroundColor: 'transparent',
        },
      },
    },
    iconPaddingStyle: {
      paddingRight: '0.5em',
    },
  }),
  { name: 'RaConfirm' }
)

/**
 * Confirmation dialog
 *
 * @example
 * <Confirm
 *     isOpen={true}
 *     title="Delete Item"
 *     content="Are you sure you want to delete this item?"
 *     confirm="Yes"
 *     confirmColor="primary"
 *     cancel="Cancel"
 *     onConfirm={() => { // do something }}
 *     onClose={() => { // do something }}
 * />
 */
export const CustomDeleteConfirm: FC<ConfirmProps> = (props) => {
  const {
    isOpen,
    loading,
    title,
    content,
    confirm = 'Confirm',
    cancel = 'Cancel',
    confirmClasses,
    onClose,
    onConfirm,
    disableCancelBtn,
  } = props
  const classes = useStyles(props)
  const translate = useTranslate()

  const handleConfirm = useCallback(
    (e) => {
      e.stopPropagation()
      onConfirm(e)
    },
    [onConfirm]
  )

  const handleClick = useCallback((e) => {
    e.stopPropagation()
  }, [])

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      onClick={handleClick}
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.contentText}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {!disableCancelBtn && (
          <Button disabled={loading} onClick={onClose}>
            <AlertError className={classes.iconPaddingStyle} />
            {translate(cancel, { _: cancel })}
          </Button>
        )}
        <Button
          disabled={loading}
          onClick={handleConfirm}
          className={confirmClasses ?? classes.confirmWarning}
          autoFocus
        >
          <ActionCheck className={classes.iconPaddingStyle} />
          {translate(confirm, { _: confirm })}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export interface ConfirmProps {
  cancel?: string
  classes?: object
  confirm?: string
  confirmColor?: string
  confirmClasses?: string
  content: string | React.ReactNode
  isOpen: boolean
  loading?: boolean
  onClose: MouseEventHandler
  onConfirm: MouseEventHandler
  title: string
  translateOptions?: object
  disableCancelBtn?: boolean
}

CustomDeleteConfirm.defaultProps = {
  cancel: 'ra.action.cancel',
  classes: {},
  confirm: 'ra.action.confirm',
  confirmColor: 'primary',
  isOpen: false,
}
