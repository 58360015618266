import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  fade,
  makeStyles,
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useState } from 'react'
import { useQuery } from 'react-apollo-hooks'
import { allVaultFreezeReasons } from '../../__generated__/flexgold/allVaultFreezeReasons'
import { FreezeReasonsQuery } from '../../graphQL/flexgold/vault'
import { LoadingSpinner } from '../../utils/LoadingSpinner'

export type VaultFreezeFormProps = {
  freezeReasons?: string[]
  setFreezeReasons: React.Dispatch<React.SetStateAction<string[]>>
}

const useStyles = makeStyles((theme) => ({
  divider: {
    width: '95%',
    height: '1px',
    backgroundColor: theme.palette.text.hint,
    marginLeft: '.3rem',
  },
  deleteIcon: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: fade(theme.palette.error.main, 0.12),
      cursor: 'pointer',
      // Reset on mouse devices
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  reasonItem: {
    padding: '.2rem .5rem',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  reasonList: {
    padding: '.2rem',
  },
}))

type ReasonListProps = Pick<VaultFreezeFormProps, 'freezeReasons'> & {
  onRemoveReason?: (i: number) => void
  noItemsText: string
}

const ReasonList = ({
  freezeReasons,
  onRemoveReason,
  noItemsText,
}: ReasonListProps) => {
  const classes = useStyles()
  const reasons = Array.isArray(freezeReasons) ? freezeReasons : []
  return (
    <>
      {!reasons.length ? (
        <p style={{ textAlign: 'center', padding: '.5rem 0' }}>{noItemsText}</p>
      ) : null}
      {reasons.map((reason, i) => (
        <div key={reason + i}>
          {i === 0 ? null : <div className={classes.divider} />}
          <div className={classes.reasonItem}>
            <div>{reason}</div>

            {!!onRemoveReason ? (
              <DeleteIcon
                color="error"
                classes={{
                  root: `${classes.deleteIcon} MuiIcon-root`,
                }}
                onClick={() => onRemoveReason(i)}
              />
            ) : null}
          </div>
        </div>
      ))}
    </>
  )
}

export const VaultFreezeForm = (props: VaultFreezeFormProps) => {
  const { freezeReasons, setFreezeReasons } = props
  const { loading, error, data } = useQuery<allVaultFreezeReasons>(
    FreezeReasonsQuery
  )
  const [currentReason, setCurrentReason] = useState<string>(undefined)
  const classes = useStyles()
  if (loading) {
    return <LoadingSpinner />
  }

  if (!data || !data.vaultFreezeReasons) {
    return <p>Couldn't load freeze reason options.</p>
  }

  const validReasons = data.vaultFreezeReasons.nodes.map((r) => r.reason)

  const validReasonsLeft = validReasons.filter(
    (r) => !freezeReasons.includes(r)
  )

  const handleRemoveReason = (index: number) => {
    setFreezeReasons(
      freezeReasons.slice(0, index).concat(freezeReasons.slice(index + 1))
    )
  }

  return (
    <Container
      classes={{
        root: `${classes.reasonList} MuiContainer-root`,
      }}
    >
      <Paper>
        <ReasonList
          freezeReasons={freezeReasons}
          onRemoveReason={handleRemoveReason}
          noItemsText="No reason currently selected"
        />
      </Paper>
      {!!error ? (
        <p>Couldn't load freeze reason options.</p>
      ) : (
        <Container style={{ display: 'flex', marginTop: '1rem' }}>
          <FormControl fullWidth size="small" variant="outlined">
            <InputLabel id="freason-label">Reason</InputLabel>
            <Select
              labelId="freason-label"
              // value={age}
              label="Reason"
              onChange={(e) => setCurrentReason(e.target.value as string)}
            >
              {validReasonsLeft.map((reason, i) => (
                <MenuItem value={reason} key={reason + i}>
                  {reason}
                </MenuItem>
              ))}
              {!validReasonsLeft.length ? (
                <MenuItem value={null}>No reasons left</MenuItem>
              ) : null}
            </Select>
          </FormControl>
          <Button
            color="primary"
            // size="small"
            variant="contained"
            onClick={() =>
              setFreezeReasons((prev) =>
                currentReason == null ? prev : [...prev, currentReason]
              )
            }
            style={{ marginLeft: '1rem' }}
          >
            Add
          </Button>
        </Container>
      )}
    </Container>
  )
}

export const VaultUnfreeze = ({
  freezeReasons,
}: Pick<VaultFreezeFormProps, 'freezeReasons'>) => {
  const classes = useStyles()

  return (
    <Paper>
      <Container
        classes={{
          root: `${classes.reasonList} MuiContainer-root`,
        }}
      >
        <ReasonList
          freezeReasons={freezeReasons}
          noItemsText="There are currently no unresolved reasons"
        />
      </Container>
    </Paper>
  )
}
