// use this and check in case of saving if popup is needed asking for overwriting of data
export const CmsEditSaveRelation: {
  [name: string]: {
    [name: string]: {
      target: string
      relativeTarget: string
      fields: { [name: string]: string }
    }
  }
} = {
  // on saving:
  CmsContract: {
    // INFO: PostSavePopup currently only support 1 (the first one)
    // get all:
    CmsLicense: {
      // by this field based on id current.id:
      target: 'contractId',
      relativeTarget: 'cmsLicensesByContractId',
      fields: {
        activeCountries: 'activeCountries',
        contractStart: 'licenseStart',
        contractEnd: 'licenseEnd',
        properties: 'properties',
        channels: 'channels',
        portals: 'portals',
        foreignPortals: 'foreignPortals',
        ov: 'ov',
        omu: 'omu',
        uncut: 'uncut',
        exclusive: 'exclusive',
        offlineAvailableDays: 'offlineAvailableDays',
        languages: 'languages',
        subtitleLanguages: 'subtitleLanguages',
      },
    },
  },
}
