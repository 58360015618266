import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import Tooltip from '@material-ui/core/Tooltip'
import React from 'react'
import { ImageField, TextField, TextFieldProps } from 'react-admin'

type Props = TextFieldProps & {
  maxLength?: number
  ignoreMaxLength?: boolean
  singleLine?: boolean
  shortenUrl?: boolean
  popUpPic?: boolean
  renderType?: string
  typeDisplaySettings?: { type?: string; props?: Object }
}

const useStyles = makeStyles({
  // if singleLine === true
  fieldSingleLine: {
    display: 'inline-block',
    maxwidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    'white-space': 'nowrap',
  },
  // if singleLine === shortenUrl === true
  fieldUrlSingleLine: {
    // instead of showing http://bla... , it shows ...example.jpg
    display: 'inline-block',
    maxwidth: '20em',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    'white-space': 'nowrap',
    direction: 'rtl' as 'rtl',
  },
  shorten: {
    maxWidth: 150,
    overflow: 'hidden',
  },
  wordBreak: {
    wordBreak: 'break-word',
  },
})

export function MaxLengthTextField({
  maxLength = 30,
  ignoreMaxLength = false,
  singleLine = false,
  shortenUrl = false,
  popUpPic = false,
  source,
  record,
  className,
  typeDisplaySettings,
  renderType,
  ...otherProps
}: Props) {
  const classes = useStyles()

  if (!source || !record || record[source] === undefined) {
    console.error(
      'MaxLengthTextField, trying to render a field that is not in the record',
      source,
      record
    )
    return null
  }
  const val = record[source]
  let shouldShorten = typeof val === 'string' && val.length > maxLength
  if (ignoreMaxLength) {
    shouldShorten = false
  }

  const content = (
    <TextField
      source={source}
      record={record}
      // formClassName="TEST2"
      {...otherProps}
      className={`${className || ''} ${classes.wordBreak} ${
        singleLine
          ? shortenUrl
            ? classes.fieldUrlSingleLine
            : classes.fieldSingleLine
          : ''
      } ${shouldShorten ? classes.shorten : ''}`}
      title={shouldShorten ? record[source] : undefined}
    />
  )

  if (popUpPic && typeDisplaySettings) {
    return (
      <Tooltip
        {...(typeDisplaySettings.props || null)}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        placement="top"
        enterDelay={100}
        title={
          <React.Fragment>
            <ImageField source={source} record={record} />
          </React.Fragment>
        }
      >
        {/* avoid the title warning */}
        <>{content}</>
      </Tooltip>
    )
  }
  return content
}
