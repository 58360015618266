import {
  createStyles,
  withStyles,
  WithStyles,
  withTheme,
  WithTheme,
} from '@material-ui/core'
import React, { ReactNode } from 'react'
import { compose } from 'recompose'

type Props = {
  color: 'primary' | 'secondary' | 'error'
  variant?: 'light' | 'dark' | 'main'
  children: ReactNode
  title?: string
}

const styles = createStyles({
  token: {
    whiteSpace: 'nowrap',
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 5,
  },
})

type InnerProps = Props & WithStyles<typeof styles> & WithTheme

function TokenImpl({
  color,
  variant = 'main',
  classes,
  children,
  theme,
  title,
}: InnerProps) {
  const themeColor = theme.palette[color]
  const backgroundColor = themeColor[variant]

  return (
    <span
      className={classes.token}
      style={{
        backgroundColor,
        color: themeColor.contrastText,
      }}
      title={title}
    >
      {children}
    </span>
  )
}

export const Token = React.memo(
  compose<InnerProps, Props>(withStyles(styles), withTheme)(TokenImpl)
)
