import { Card, CardContent, Typography } from '@material-ui/core'

type Props = {
  children: string
}
export function ErrorCard({ children }: Props) {
  return (
    <Card>
      <CardContent>
        <Typography color="error">{children}</Typography>
      </CardContent>
    </Card>
  )
}
