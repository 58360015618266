const errorMapping: { [x: string]: string } = {
  slug_unique: 'Provided "slug" must be not used by other objects.',
  name_unique: 'Provided "name" must be not used by other objects.',
  external_identifier_unique:
    'Provided "external_identifier" must be not used by other objects.',

  // TODO: add more if found during working in dashboard

  // explicit declared constraint in DB
  payment_provider_id_unique:
    'Provided "payment_provider_id" must be not used by other objects.',
  voucher_code_code_key: 'Provided "code" must be not used by other objects.',
  watchlist_item_unique_movie_index:
    'This user already has an object with this "movie_id".',
  watchlist_item_unique_episode_index:
    'This user already has an object with this "episode_id".',
  wishlist_item_unique_movie_index:
    'This user already has an object with this "movie_id".',
  wishlist_item_unique_episode_index:
    'This user already has an object with this "episode_id".',
  wishlist_item_unique_series_index:
    'This user already has an object with this "series_id".',
  cms_movie_content_category_movie_unique_index:
    'Provided "content_movie_id" must be not used by other objects.',
  cms_movie_content_category_series_unique_index:
    'Provided "content_series_id" must be not used by other objects.',
  cms_movie_content_person_clip_content_with_role:
    'Combination of connectionType, roleName, person_id, content_clip_id already exists.',
  cms_movie_content_person_clip_content:
    'Combination of connectionType, person_id, content_clip_id already exists.',
  cms_movie_content_person_movie_content_with_role:
    'Combination of connectionType, roleName, person_id, content_clip_id already exists.',
  cms_movie_content_person_movie_content:
    'Combination of connectionType, person_id, content_clip_id already exists.',
  cms_movie_content_person_series_content_with_role:
    'Combination of connectionType, roleName, person_id, content_clip_id already exists.',
  cms_movie_content_person_series_content:
    'Combination of connectionType, person_id, content_clip_id already exists.',
  cms_movie_content_person_season_content_with_role:
    'Combination of connectionType, roleName, person_id, content_clip_id already exists.',
  cms_movie_content_person_season_content:
    'Combination of connectionType, person_id, content_clip_id already exists.',
  cms_movie_content_person_episode_content_with_role:
    'Combination of connectionType, roleName, person_id, content_clip_id already exists.',
  cms_movie_content_person_episode_content:
    'Combination of connectionType, person_id, content_clip_id already exists.',
  cms_movie_content_person_trailer_content_with_role:
    'Combination of connectionType, roleName, person_id, content_clip_id already exists.',
  cms_movie_content_person_trailer_content:
    'Combination of connectionType, person_id, content_clip_id already exists.',
}

const errorKeys = Object.keys(errorMapping)

export const getErrorNotificationMessages = (error: {
  message?: string
  extraInfo: any
  graphQLErrors: {
    locations: {
      column: number
      line: number
    }[]
    message: string
    networkError: any
  }[]
}) => {
  if (error.graphQLErrors) {
    const errors = error.graphQLErrors.map((err) => err.message)
    return errors
  }

  const errorMessage = error.message

  if (errorMessage) {
    let message = null

    for (let index = 0; index < errorKeys.length; index++) {
      const k = errorKeys[index]
      if (errorMessage.includes(k)) {
        message = errorMapping[k]
      }
    }

    if (!message) return null

    return message
  }
}
