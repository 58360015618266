import { Button, CardActions, makeStyles, Tooltip } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import SettingsIcon from '@material-ui/icons/Settings'
import { motion } from 'framer-motion'
import { useRefresh } from 'ra-core'
import React, { ReactElement, useCallback, useEffect } from 'react'
import { CreateButton, ListProps } from 'react-admin'
import CustomizableDatagrid from '../datagrid/CustomizeableDatagrid'
import { smplColors } from '../layout/themes'
import { AllAutomationTaskAction } from './customActions/AllAutomationTaskAction'
import { ListCompSelect } from './customFields/ListCompSelect'
import { smplTexts } from './CustomTextMappings'
import { ExportButton } from './ExportButton'

const useStyles = makeStyles(() => ({
  actionBar: {
    '& a': {
      padding: '0.5rem 0.5rem',
      background: '#cdcdcd',
      fontSize: 14,
    },
  },
  actionButton: {
    padding: '0.5rem 0.5rem',
    background: '#cdcdcd',
  },
  uploadConfirmBtn: {
    color: smplColors.primary.main,
  },
}))

export function ResourceActions(
  props: ListProps & {
    dataGridRef: { readonly current: CustomizableDatagrid | null }
    hasCreateInModal: boolean
    onOpenCreateModal?: () => void
    bulkActions?: ReactElement // | boolean
    selectedIds?: string[]
    onUnselectItems?: Function
    showFilter?: Function
    displayedFilters?: Object
    filterValues?: Object
    forceUpdate: () => void
    setHasSelectedIds: React.Dispatch<React.SetStateAction<Boolean>>
  }
) {
  const {
    bulkActions,
    filters,
    basePath,
    resource,
    selectedIds,
    onUnselectItems,
    showFilter,
    displayedFilters,
    filterValues,
    hasCreate,
    dataGridRef,
    hasCreateInModal,
    onOpenCreateModal,
    forceUpdate,
    setHasSelectedIds,
  } = props

  const refresh = useRefresh()

  const openCustomizer = useCallback(() => {
    dataGridRef.current?.handleOpen()
  }, [dataGridRef])

  const classes = useStyles()

  useEffect(() => {
    if (selectedIds) {
      if (selectedIds.length > 0) {
        setHasSelectedIds(true)
      } else {
        setHasSelectedIds(false)
      }
    }
  }, [selectedIds])

  return (
    <CardActions className={classes.actionBar}>
      {bulkActions &&
        React.cloneElement(bulkActions, {
          basePath,
          filterValues,
          resource,
          selectedIds,
          onUnselectItems,
        })}
      {filters &&
        // @ts-ignore don't know
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button',
        })}

      <ListCompSelect resource={resource!} forceUpdate={forceUpdate} />

      {resource === 'CmsCategory' ? (
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.8, ease: 'backOut' }}
        >
          <AllAutomationTaskAction />
        </motion.div>
      ) : null}

      {hasCreate ? (
        <motion.div
          initial={{ opacity: 0, y: -100 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 1, delay: 0.7, ease: 'backOut' }}
        >
          <CreateButton basePath={basePath} />
        </motion.div>
      ) : null}
      {hasCreateInModal ? (
        <Button
          size="medium"
          color="primary"
          startIcon={<AddIcon />}
          className={classes.actionButton}
          onClick={onOpenCreateModal}
        >
          Create
        </Button>
      ) : null}
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.6, ease: 'backOut' }}
      >
        <ExportButton {...props} color="primary" />
      </motion.div>
      <motion.div
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1, delay: 0.5, ease: 'backOut' }}
      >
        <Tooltip title={smplTexts.datagrid.config}>
          <Button
            size="small"
            color="primary"
            className={classes.actionButton}
            aria-label="Change Columns"
            onClick={openCustomizer}
            style={{ marginRight: 13 }}
          >
            <SettingsIcon />
          </Button>
        </Tooltip>
      </motion.div>
    </CardActions>
  )
}
