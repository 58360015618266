import { Tooltip, useMediaQuery } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import { Theme, makeStyles } from '@material-ui/core/styles'
import AndroidIcon from '@material-ui/icons/Android'
import { useNotify, useRefresh } from 'ra-core'
import { useEndpointAvailable } from '../../hooks/useEndpointAvailable'
import { TASK_SCHEDULER_ENDPOINT } from '../../lib/config'
import { smplTexts } from '../CustomTextMappings'
import { createSimpleAuthenticatedClient } from '../node-actions/user/lib/simpleClient'

const useStyles = makeStyles(() => ({
  statusButton: {
    padding: '0.5rem 1rem',
    background: '#cdcdcd',
    whiteSpace: 'nowrap',
  },
}))

export type CustomActionProps = {}

export const AllAutomationTaskAction = (props: CustomActionProps) => {
  const classes = useStyles()
  const notify = useNotify()
  const refresh = useRefresh()
  const isSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

  const taskSchedulerAPI = TASK_SCHEDULER_ENDPOINT
  const endpoint = '/runAllAutomationTask'
  const authedClient = createSimpleAuthenticatedClient(taskSchedulerAPI)
  const isEndpointAvailable = useEndpointAvailable(authedClient, endpoint)

  if (!isEndpointAvailable) {
    return null
  }

  return (
    <Tooltip title={smplTexts.datagrid.autotask}>
      <Button
        variant="text"
        color="secondary"
        className={classes.statusButton}
        startIcon={<AndroidIcon />}
        onClick={async () => {
          const res = await authedClient('POST', endpoint, {})

          if (res.success === true)
            notify('All automation task started.', 'success')
          else
            notify(
              'All automation task can not be started. If this problem persists, please contact the support.',
              'error'
            )
          refresh()
        }}
      >
        {isSmall ? null : 'Auto Task'}
      </Button>
    </Tooltip>
  )
}
