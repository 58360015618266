import React from 'react'
import { getToken } from '../../authProvider'
import { SMPL_DATA_API_URL } from '../../lib/config'

import raw from 'raw.macro'

const URL = `${SMPL_DATA_API_URL.replace(/^http:/, 'https:')}`

const GraphQLPlayground = raw('./index.html')

const style = {
  margin: 0,
  padding: 0,
  border: 'none',
  height: 'calc(100vh - 48px - 2*24px)', // -48px for the header bar, 24px for padding
}

export class GraphQLDebugger extends React.Component {
  loadGraphQLWithData(target: HTMLIFrameElement, data: object) {
    if (target.contentWindow) {
      target.contentWindow.postMessage(
        JSON.stringify(data),
        window.location.href
      )
    }
  }

  render() {
    const options = {
      endpoint: URL,
      theme: 'light',
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    }
    return (
      <iframe
        srcDoc={GraphQLPlayground}
        style={style}
        title="GraphQL Playground"
        onLoad={({ target }) => {
          this.loadGraphQLWithData(target as HTMLIFrameElement, options)
        }}
      />
    )
  }
}
