import {
  Button,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@material-ui/core'
import { useState } from 'react'
import { Record, useNotify, useRefresh } from 'react-admin'
import { useMutation, useQuery } from 'react-apollo-hooks'
import { allAMLTagsReasons } from '../../__generated__/flexgold/allAMLTagsReasons'
import {
  toggleAMLTag,
  toggleAMLTagVariables,
} from '../../__generated__/flexgold/toggleAMLTag'
import {
  AllAMLTagsQuery,
  ToggleAMLTagMutation,
} from '../../graphQL/flexgold/identificationDatum'
import { LoadingSpinner } from '../../utils/LoadingSpinner'

type AMLTag = string

export type ToggleAMLTagsFormProps = {
  data: Record
}

export const ToggleAMLTagsForm = (props: ToggleAMLTagsFormProps) => {
  const { data } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const [amlTag, setAMLTag] = useState<string>()
  const [comment, setComment] = useState('')
  const [isToggling, setIsToggling] = useState(false)
  const isTagActive = data.monitoringTags?.includes(amlTag) ?? false
  const { loading, error, data: validTagsData } = useQuery<allAMLTagsReasons>(
    AllAMLTagsQuery
  )
  const toggleMutationFN = useMutation<toggleAMLTag, toggleAMLTagVariables>(
    ToggleAMLTagMutation
  )

  // @ts-ignore
  const allValidTags = validTagsData.monitoringTags?.nodes.map(({ tag }) => tag)

  const toggleHandler = async (vars: {
    amlTag: string
    comment: string
    identificationDatumId: string
  }) => {
    if (isToggling) {
      return
    }
    setIsToggling(true)

    try {
      await toggleMutationFN({
        variables: {
          input: {
            comment: vars.comment,
            tagParam: vars.amlTag,
            identificationDataParam: vars.identificationDatumId,
          },
        },
      })
      setIsToggling(false)
      notify(
        `${isTagActive ? 'Removed' : 'Added'} tag: '${amlTag}' successfully.`
      )
      refresh()
    } catch (err) {
      notify(`Failed to ${isTagActive ? 'remove' : 'add'} tag: '${amlTag}'!`)
    }
  }

  return (
    <Container
      classes={{
        root: 'MuiContainer-root',
      }}
    >
      {!!error ? (
        <p>Couldn't load the list of valid aml tags.</p>
      ) : loading ? (
        <LoadingSpinner />
      ) : (
        <Container
          style={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'column',
          }}
        >
          <Paper style={{ padding: '1rem' }}>
            {isToggling ? (
              <div style={{ padding: '4rem' }}>
                <LoadingSpinner />
              </div>
            ) : (
              <>
                <FormControl fullWidth size="small" variant="outlined">
                  <InputLabel id="tag-label">Tag</InputLabel>
                  <Select
                    labelId="tag-label"
                    label="Tag"
                    onChange={(e) => setAMLTag(String(e.target.value))}
                  >
                    {allValidTags.map((reason, i) => (
                      <MenuItem value={reason} key={reason + i}>
                        {reason}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl>
                  <TextField
                    label="Comment"
                    multiline
                    value={comment}
                    onChange={(e) => setComment(e.target.value)}
                    style={{ width: '100%', marginTop: '1rem' }}
                  />
                </FormControl>
              </>
            )}
          </Paper>
          <Button
            color="primary"
            disabled={
              !amlTag ||
              (!comment.trim().length && isTagActive) ||
              isToggling ||
              loading
            }
            variant="contained"
            onClick={() =>
              toggleHandler({
                amlTag,
                comment,
                identificationDatumId: data.id as any,
              })
            }
            style={{ marginTop: '1rem' }}
          >
            {isTagActive ? 'Remove' : 'Add'}
          </Button>
        </Container>
      )}
    </Container>
  )
}
