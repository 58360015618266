import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Maybe from 'graphql/tsutils/Maybe'
import { useEffect, useState } from 'react'
import { useField } from 'react-final-form'
import { camelCaseToText } from '../renderField'

const useStyles = makeStyles({
  stringListField: {
    width: '100%',
    justifySelf: 'center',
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
      height: 48,
    },
    '& > p': {
      margin: 0,
    },
    marginTop: '8px',
    marginBottom: '4px',
  },
})

type StringListInputProps = {
  name: string
  helperText: Maybe<string>
}

export const StringListInput = (props: StringListInputProps) => {
  const { name: fieldName, helperText, ...rest } = props
  const classes = useStyles()

  const formField = useField(fieldName)
  const [fieldString, setFieldString] = useState<string | undefined>(
    formField.input.value ? formField.input.value.join('; ') : undefined
  )

  useEffect(() => {
    if (fieldString) {
      const stringArray = fieldString
        .split(';')
        .map((v) => v.trim())
        .filter((v) => v.length > 0)
      console.log('stringArray:', stringArray)
      formField.input.onChange(stringArray)
    }
  }, [fieldString])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('input string', event.target.value)
    setFieldString(event.target.value)
    if (event.target.value.length === 0) {
      formField.input.onChange(null)
    }
  }

  return (
    <TextField
      className={classes.stringListField}
      value={fieldString}
      onChange={handleChange}
      label={camelCaseToText(fieldName)}
      helperText={
        helperText || `Semicolon separated string value, i.e. this; is; a; test`
      }
      variant="filled"
    />
  )
}
