import { getSchemaTraverser } from '../dataProvider/buildQuery'
import {
  ResourceTraverser,
  SchemaTraverser,
} from '../dataProvider/introspections/SchemaTraverser'

export function useSchemaTraverser(): SchemaTraverser | null {
  return getSchemaTraverser()
}

export function useResourceTraverser(
  resourceName: string
): ResourceTraverser | null {
  const schemaTraverser = useSchemaTraverser()
  if (!schemaTraverser) {
    return null
  }
  return schemaTraverser.getResourceTraverserByName(resourceName)
}
