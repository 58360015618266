import { PAYMENT_ENDPOINT } from '../../../../lib/config'
import { createSimpleAuthenticatedClient } from '../../user/lib/simpleClient'

//  'admin/subscription/cancel'
export async function cancelSubscription(props: {
  subscriptionId: string
  sendCancelEmail: boolean
  endpoint: string
}) {
  const { endpoint, sendCancelEmail = false, subscriptionId } = props
  const paymentEndpoint: string = PAYMENT_ENDPOINT

  if (paymentEndpoint) {
    const paymentServiceClient = createSimpleAuthenticatedClient(
      paymentEndpoint
    )

    return paymentServiceClient('POST', endpoint, {
      subscriptionId: subscriptionId,
      sendCancelEmail,
    })
  }
}
