import { NoData } from './NoData'

export const MetadataFields = (props: any) => {
  const { source, record, keyPath } = props

  let jsonObject = null
  if (record[source]) {
    jsonObject = JSON.parse(record[source])
    return jsonObject[keyPath].status
  } else {
    return <NoData />
  }
}

// export const CustomField2 = ({ record, source }: FieldProps) => {
//   console.log(record)
//   return (
//     <span>
//       {source}
//       {/* {record && record[source]} */}
//     </span>
//   )
// }
