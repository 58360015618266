import { Box, CardActions, Tooltip, Typography } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Fade from '@material-ui/core/Fade'
import { Theme, makeStyles } from '@material-ui/core/styles'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import TouchAppIcon from '@material-ui/icons/TouchApp'
import TreeItem from '@material-ui/lab/TreeItem'
import TreeView from '@material-ui/lab/TreeView'
import { motion } from 'framer-motion'
import pluralize from 'pluralize'
import React from 'react'
import {
  CreateButton,
  EditButton,
  Error,
  List,
  ListProps,
  Loading,
  ShowButton,
} from 'react-admin'
import { useQuery } from 'react-apollo-hooks'
import {
  Categories,
  Categories_allCmsCategories_nodes,
} from '../../__generated__/Categories'
import { CategoriesQuery } from '../../graphQL/categories'
import { smplColors } from '../../layout/themes'
import { filterNotEmpty } from '../../lib/filterNonEmpty'
import { ExportButton } from '../ExportButton'
import { FlagsFields } from '../customFields/FlagsFields'
import { ListCompSelect } from '../customFields/ListCompSelect'
import { CategoryFlagsData } from '../settings/FlagSetting'

type DefaultListProps = React.ComponentProps<typeof List>

interface Tree {
  category: Categories_allCmsCategories_nodes
  children?: Tree[]
  index?: any
}

interface RenderTree {
  id: string
  name: string
  children?: Tree[]
}

const useStyles = makeStyles((theme: Theme) => ({
  box: {
    maxHeight: '68vh',
    overflow: 'auto',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 1),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  labelInfo: {
    flexGrow: 1,
    display: 'grid',
    gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  },
  labelLeft: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  labelRight: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'flex-end',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
    '& > div': {
      textAlign: 'right',
    },
  },
  isActiveIcon: {
    position: 'absolute',
    marginLeft: 4,
  },
  isActiveOrb: {
    position: 'relative',
    width: 12,
    height: 12,
    background:
      'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(163,163,163,1) 28%, rgba(84,84,84,1) 51%, rgba(161,161,161,1) 72%, rgba(195,195,195,1) 100%)',
    border: '',
    boxShadow: '0.1rem 0.1rem 0.2rem #00000060',
    borderRadius: 100,
    marginTop: 5,
    marginLeft: 4,
    zIndex: 2,
    '&::before': {
      position: 'absolute',
      left: 2,
      top: 2,
      content: '""',
      borderRadius: 100,
      width: 8,
      height: 8,
      background: `radial-gradient(circle, rgba(170,255,0,1) 0%, rgba(84,255,0,1) 50%, rgba(34,84,10,1) 100%)`,
      boxShadow: '0 0 0.5rem rgba(170,255,0,1)',
      zIndex: 1,
    },
  },
  isNotActiveOrb: {
    position: 'relative',
    width: 12,
    height: 12,
    background:
      'linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(163,163,163,1) 28%, rgba(84,84,84,1) 51%, rgba(161,161,161,1) 72%, rgba(195,195,195,1) 100%)',
    border: '',
    boxShadow: '0.1rem 0.1rem 0.2rem #00000060',
    borderRadius: 100,
    marginTop: 5,
    marginLeft: 4,
    zIndex: 2,
    '&::before': {
      position: 'absolute',
      left: 2,
      top: 2,
      content: '""',
      borderRadius: 100,
      width: 8,
      height: 8,
      background: `radial-gradient(circle, rgba(255,156,0,1) 0%, rgba(255,0,0,1) 50%, rgba(84,15,10,1) 100%)`,
      boxShadow: '0 0 0.5rem rgba(255,156,0,1)',
      zIndex: 1,
    },
    '&::after': {
      position: 'absolute',
      left: 2,
      top: 2,
      content: '""',
      borderRadius: 100,
      width: 8,
      height: 8,
      background: `radial-gradient(circle, rgba(200,130,0,1) 30%, rgba(150,2,0,1) 50%, rgba(84,15,10,1) 100%)`,
      boxShadow: '0 0 0.5rem rgba(255,156,0,1)',
      zIndex: 2,
      animation: '$inactiveFlashy 5s infinite',
    },
  },
  '@keyframes inactiveFlashy': {
    '0%': {
      opacity: 1,
    },
    '50%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
}))

type Props = ListProps & {
  // set Type for Custom Component example:
  ListComponent?: React.ComponentType<DefaultListProps>
  forceUpdate: () => void
  onShowButtonClick?: (id: string, record: object) => void
  showButtonLabel?: string
}

const SortListActions = (props: Props) => {
  const { basePath, hasCreate, forceUpdate, resource } = props
  return (
    <CardActions>
      <ListCompSelect resource={resource!} forceUpdate={forceUpdate} />
      {hasCreate ? <CreateButton basePath={basePath} /> : null}
      <ExportButton {...props} />
    </CardActions>
  )
}

export const CategorySortableTreePage = React.memo((props: Props) => {
  const {
    ListComponent = List,
    onShowButtonClick,
    showButtonLabel,
    ...otherProps
  } = props
  const { resource, basePath } = otherProps
  const classes = useStyles()

  const { data, loading, error, refetch } = useQuery<Categories>(
    CategoriesQuery,
    {
      variables: {
        isNull: true,
      },
      notifyOnNetworkStatusChange: true,
    }
  )

  if (!resource || error)
    return (
      <Error
        error={{
          name: 'Network Error',
          message: 'Can not query Categories.',
        }}
      />
    )
  if (loading) return <Loading />

  const treeData: RenderTree = {
    id: 'root',
    name: resource,
    children: [],
  }

  const getTreeChildren = (
    nodes: readonly (Categories_allCmsCategories_nodes | null)[] | undefined,
    parentId: string
  ) => {
    if (!nodes) return

    // @ts-ignore
    const children: Tree[] =
      nodes
        .filter((subNodes) => {
          if (!subNodes) {
            return false
          }
          if (subNodes.parentId !== parentId) {
            return false
          }
          return true
        })
        .map((subNodes, i) => {
          if (!subNodes) {
            return null
          }
          const tree: Tree = {
            category: subNodes,
            children: getTreeChildren(nodes, subNodes.id),
            index: i,
          }
          return tree
        })
        .filter(filterNotEmpty) || []

    return children
  }

  data?.allCmsCategories?.nodes.forEach((cat) => {
    if (!cat) return
    if (!cat.parentId) {
      treeData.children?.push({
        category: cat,
        children: getTreeChildren(data?.allCmsCategories?.nodes, cat.id),
      })
    }
  })

  const renderTree = (nodes: Tree) => (
    <motion.div
      initial={{ opacity: 0, y: 100 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{
        duration: 0.5,
        bounce: 1,
        ease: 'anticipate',
        delay: nodes.children ? nodes.index * 0.1 : 0,
      }}
    >
      <TreeItem
        key={nodes.category.id}
        nodeId={nodes.category.id}
        collapseIcon={
          <ExpandMoreIcon
            style={{
              transform: 'scale(1.5)',
              color: smplColors.secondary.main,
              marginRight: 25,
            }}
          />
        }
        expandIcon={
          <ChevronRightIcon
            style={{
              transform: 'scale(1.5)',
              color: smplColors.secondary.main,
              marginRight: 25,
            }}
          />
        }
        style={{ paddingLeft: 15 }}
        label={
          <>
            <div className={classes.labelRoot}>
              <div className={classes.labelIcon}>
                <ShowButton
                  resource={resource}
                  basePath={basePath}
                  record={{ id: nodes.category.id }}
                  // @ts-ignore
                  onClick={
                    onShowButtonClick
                      ? (e: Event) => {
                          e.stopPropagation()
                          e.preventDefault()
                          onShowButtonClick(nodes.category.id, nodes.category)
                        }
                      : undefined
                  }
                  icon={
                    showButtonLabel === 'select' ? <TouchAppIcon /> : undefined // defaultIcon
                  }
                  label={showButtonLabel}
                />
              </div>
              <div className={classes.labelInfo}>
                <div className={classes.labelLeft} style={{ marginLeft: 10 }}>
                  <motion.div
                    initial={{ x: 30 }}
                    animate={{ x: 0 }}
                    transition={{
                      duration: 0.5,
                      delay: 0.1,
                      bounce: 1,
                      ease: 'backInOut',
                    }}
                  >
                    <Typography variant="body1">
                      {nodes.category.title}{' '}
                      <Typography
                        component="span"
                        variant="body2"
                        className={classes.isActiveIcon}
                      >
                        <Tooltip
                          title={
                            nodes.category.isActive
                              ? 'Is active'
                              : 'Is not active'
                          }
                          placement="top"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 500 }}
                          arrow
                        >
                          {nodes.category.isActive === true ? (
                            <motion.div
                              initial={{ scale: 0 }}
                              animate={{ scale: 1 }}
                              transition={{
                                duration: 0.5,
                                delay: 0.55,
                                bounce: 1,
                                ease: 'backInOut',
                              }}
                            >
                              <div className={classes.isActiveOrb}></div>
                            </motion.div>
                          ) : (
                            <motion.div
                              initial={{ scale: 0 }}
                              animate={{ scale: 1 }}
                              transition={{
                                duration: 0.5,
                                delay: 0.55,
                                bounce: 1,
                                ease: 'backInOut',
                              }}
                            >
                              <div className={classes.isNotActiveOrb}></div>
                            </motion.div>
                          )}
                        </Tooltip>
                      </Typography>{' '}
                    </Typography>
                  </motion.div>
                  <span>
                    <motion.div
                      initial={{ x: 30 }}
                      animate={{ x: 0 }}
                      transition={{
                        duration: 0.5,
                        delay: 0.2,
                        bounce: 1,
                        ease: 'backInOut',
                      }}
                    >
                      <Typography variant="caption">
                        slug: {nodes.category.slug}
                      </Typography>
                    </motion.div>

                    {nodes.category.platform ? (
                      <>
                        {' | '}
                        <Typography variant="caption">
                          Platform: {nodes.category.platform}
                        </Typography>
                      </>
                    ) : null}
                  </span>
                </div>
                <div className={classes.labelRight}>
                  {nodes.category.flags ? (
                    <motion.div
                      initial={{ y: 30, opacity: 0 }}
                      animate={{ y: 10, opacity: 1 }}
                      transition={{
                        duration: 0.5,
                        delay: 0.5,
                        bounce: 1,
                        ease: 'backInOut',
                      }}
                    >
                      <FlagsFields
                        flags={nodes.category.flags}
                        prefixAsIcon={true}
                        inputProps={{ flagData: CategoryFlagsData }}
                      />
                    </motion.div>
                  ) : null}
                </div>
              </div>

              <div className={classes.labelIcon}>
                <EditButton
                  resource={resource}
                  basePath={basePath}
                  record={{ id: nodes.category.id }}
                />
              </div>
            </div>
            <Divider orientation="horizontal" />
          </>
        }
      >
        {Array.isArray(nodes.children)
          ? nodes.children.map((node) => renderTree(node))
          : null}
      </TreeItem>
    </motion.div>
  )

  return (
    <>
      {/* <TypeDescription resource={resource} /> */}
      <ListComponent
        {...otherProps}
        title={pluralize.plural(resource)}
        exporter={false}
        actions={<SortListActions {...otherProps} />}
      >
        <Box className={classes.box}>
          <TreeView defaultExpanded={['root']}>
            <TreeItem
              key={treeData.id}
              nodeId={treeData.id}
              onLabelClick={(event: any) => {
                event.preventDefault()
              }}
              label={''}
            >
              <Divider orientation="horizontal" />
              {Array.isArray(treeData.children)
                ? treeData.children.map((node) => renderTree(node))
                : null}
            </TreeItem>
          </TreeView>
        </Box>
      </ListComponent>
    </>
  )
})
