import logo from '../../imgs/logos/netzkino.png'
import { CustomerTheme } from './customerThemeType'

export const NetzkinoTheme: Partial<CustomerTheme> = {
  primary: {
    main: '#131313',
    light: '#0099cc',
    dark: '#0099cc',
  },
  secondary: {
    main: '#0099cc',
  },
}

export const NetzkinoLogo = (
  <img
    style={{
      width: 100,
      height: 'auto',
      paddingLeft: 25,
    }}
    src={logo}
  />
)
