import AppBar from '@material-ui/core/AppBar'
import Button from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import Checkbox from '@material-ui/core/Checkbox'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import Fade from '@material-ui/core/Fade'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Paper from '@material-ui/core/Paper'
import Slide from '@material-ui/core/Slide'
import Toolbar from '@material-ui/core/Toolbar'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined'
import SettingsIcon from '@material-ui/icons/Settings'
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react'
import {
  BulkActionProps,
  Identifier,
  ListProps,
  Loading,
  Record,
  Responsive,
  useDataProvider,
  useNotify,
  useRefresh,
} from 'react-admin'
import { getSchemaTraverser } from '../dataProvider/buildQuery'
import CustomizableDatagrid from '../datagrid/CustomizeableDatagrid'
import { filterNotEmpty } from '../lib/filterNonEmpty'
import { DEFAULT_SORT } from './GenericDataList'
import { GenericFilter } from './GenericFilter'
import { LocalStateDataList } from './LocalStateDataList'
import { renderField } from './renderField'
import { CmsEditSaveRelation } from './settings/SaveRelationSetting'

export const cmsWithPostSavePopup = ['CmsContract']

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  button: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  popupContent: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  fieldSelector: {
    marginBottom: 10,
    '& > div': {
      marginBottom: 10,
    },
    flexGrow: 1,
  },
  fieldSelectorPaper: {
    width: '80%',
    minWidth: 200,
    margin: '0 auto',
  },
  tablePaper: {
    maxHeight: '88vh',
    overflow: 'auto',
  },
  relatedObjectTable: {
    maxWidth: '80%',
    flexGrow: 3,
    marginBottom: 10,
    '& > div': {
      padding: 5,
    },
  },
  newValueCard: {
    flexGrow: 1,
  },

  // Datalist
  header: {
    textAlign: 'center',
    paddingTop: '1em',
    marginBottom: 'revert',
  },
  list: {
    '& > div > div > div': {
      justifyContent: 'flex-start',
      '& > div': {
        marginRight: 50,
      },
    },
  },
}))

export type PostSavePopupProps = {
  resource: string
  currentData: {
    data: Record
    dirtyFields: {
      [key: string]: boolean
    }
  }

  setOpenPostSavePopup: React.Dispatch<React.SetStateAction<boolean>>
}

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />
})

export const PostSavePopupImpl = (props: PostSavePopupProps) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  const { currentData, resource, setOpenPostSavePopup } = props
  const [dirtyFieldSelection, setDirtyFieldSelection] = useState<string>()
  const [isUpdating, setIsUpdating] = useState(false)
  // dirtyFields only include fields that are actually changed and have new value
  const { data, dirtyFields } = currentData

  const relatedCmsTables = Object.keys(CmsEditSaveRelation[resource])
  // INFO currently assuming we only have one related for MVP
  const relatedResource = relatedCmsTables[0]
  const settingRelatedResource = CmsEditSaveRelation[resource][relatedResource]

  if (!relatedCmsTables || relatedCmsTables.length === 0) {
    // no related cms table defined, just simply 'close' it, this will lead to redirect
    setOpenPostSavePopup(false)
    notify('No related resource was defined.', 'info')
    return null
  }

  const dirtyFieldsName = Object.keys(dirtyFields).filter((dF) =>
    Object.keys(settingRelatedResource.fields).includes(dF)
  )

  if (dirtyFieldsName.length === 0) {
    // non of the dirtyField can be taken over to the related resource
    setOpenPostSavePopup(false)
    notify('Nothing to update on related resource.', 'info')
    return null
  }

  if (!dirtyFieldSelection) {
    setDirtyFieldSelection(dirtyFieldsName[0])
  }

  const handleClose = () => {
    setOpenPostSavePopup(false)
  }

  const handleOverride = async (ids: Identifier[] | undefined) => {
    if (dirtyFieldSelection && ids) {
      if (ids.length > 0) {
        setIsUpdating(true)

        const relatedDirtyField =
          settingRelatedResource.fields[dirtyFieldSelection]

        const res = await Promise.allSettled(
          ids.map(async (id) => {
            const _data = {
              [relatedDirtyField]: data[dirtyFieldSelection],
            }

            const previousData = await dataProvider.getOne(relatedResource, {
              id: id,
            })

            return await dataProvider.update(relatedResource, {
              id: id,
              data: _data,
              // @ts-ignore
              previousData: previousData.data,
            })
          })
        )

        // if old and new value are the same then it will reject, we dont care for that
        notify('Related resources updated successfully.', 'success')
        refresh()
        setIsUpdating(false)
      } else {
        notify('No related resource selected for update.', 'info')
      }
    }
  }

  return (
    <Dialog
      open={true}
      // no onClose -> will be handled in Action
      fullScreen
      // @ts-ignore
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <Typography variant="h6" className={classes.title}>
            {`${resource} - Overwriting changed fields to related ${relatedResource} Object.`}
          </Typography>
          <Button
            className={classes.button}
            autoFocus
            variant="contained"
            color="primary"
            onClick={handleClose}
            startIcon={<CloseIcon />}
            disabled={isUpdating}
          >
            Finish
          </Button>
        </Toolbar>
      </AppBar>

      {isUpdating ? (
        <Loading loadingPrimary="Updating related resources." />
      ) : (
        <DialogContent>
          <div className={classes.popupContent}>
            {/* INFO LEFT selection field & New value filed */}
            <div className={classes.fieldSelector}>
              <Paper elevation={3} className={classes.fieldSelectorPaper}>
                <Card>
                  <CardContent>
                    <Typography variant="overline">
                      Changed Fields{' '}
                      <Tooltip
                        TransitionComponent={Fade}
                        TransitionProps={{ timeout: 500 }}
                        enterDelay={100}
                        title={`Transferable fields from ${resource} to ${relatedResource}.`}
                        placement="top"
                      >
                        <InfoOutlinedIcon
                          style={{ position: 'relative', top: '7px' }}
                        />
                      </Tooltip>
                    </Typography>
                    <List>
                      {dirtyFieldsName.map((value) => {
                        return (
                          <ListItem
                            key={value}
                            role={undefined}
                            dense
                            button
                            onClick={(event) => {
                              setDirtyFieldSelection(value)
                            }}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={value === dirtyFieldSelection}
                                disableRipple
                                color="primary"
                              />
                            </ListItemIcon>
                            <ListItemText id={value} primary={`${value}`} />
                          </ListItem>
                        )
                      })}
                    </List>
                  </CardContent>
                </Card>
              </Paper>
              {dirtyFieldSelection ? (
                <Paper elevation={3} className={classes.fieldSelectorPaper}>
                  <Card>
                    <CardContent>
                      <Typography variant="overline">New Value</Typography>
                      <Typography variant="h6">
                        {typeof data[dirtyFieldSelection] !== 'string'
                          ? data[dirtyFieldSelection].toString()
                          : data[dirtyFieldSelection]}
                      </Typography>
                    </CardContent>
                  </Card>
                </Paper>
              ) : null}
            </div>
            {/* INFO RIGHT Datalist */}
            <div className={classes.relatedObjectTable}>
              <Paper elevation={3} className={classes.tablePaper}>
                <PostSaveDataList
                  ListComponent={LocalStateDataList}
                  resource={relatedResource}
                  parentResource={resource}
                  targetField={settingRelatedResource.target}
                  targetId={data.id}
                  handleOverride={handleOverride}
                  basePath={`/${relatedResource}`}
                  location={{
                    hash: '',
                    pathname: `/${relatedResource}`,
                    search: '',
                    state: null,
                  }}
                />
              </Paper>
            </div>
          </div>
        </DialogContent>
      )}
    </Dialog>
  )
}

type DefaultListProps = React.ComponentProps<typeof List>

type Props = ListProps & {
  // set Type for Custom Component example:
  ListComponent: React.ComponentType<DefaultListProps>
  location: Object
  parentResource: string
  targetField: string
  targetId: Identifier
  handleOverride: (ids: Identifier[] | undefined) => void
}

// don't put the functionality of this into GenericDataList it's already overloaded there and setFunction would clash
const PostSaveDataList = (props: Props) => {
  const {
    ListComponent = List, // if ListComponent === null ? List : props.ListCompoent
    ...otherProps
  } = props

  const {
    resource,
    parentResource,
    targetField,
    targetId,
    handleOverride,
  } = otherProps

  const { sort = DEFAULT_SORT } = props
  const classes = useStyles()

  const dataGridRef = useRef<CustomizableDatagrid>(null)
  const traverser = getSchemaTraverser()

  const resourceTraverser = useMemo(() => {
    if (!traverser || !resource) {
      return null
    }
    const r = traverser.getResourceTraverserByName(resource)
    if (!r) {
      throw new Error('Resource not found! ' + resource)
    }
    return r
  }, [traverser, resource])

  const renderedFields = useMemo(() => {
    return (
      resourceTraverser &&
      resourceTraverser.fields
        .map((field) => {
          const rendered = renderField(field, 'show')
          if (rendered) {
            return {
              field,
              rendered,
            }
          } else {
            return null
          }
        })
        .filter(filterNotEmpty)
    )
  }, [resourceTraverser])

  const openCustomizer = useCallback(() => {
    dataGridRef.current?.handleOpen()
  }, [dataGridRef])

  if (!traverser || !resource || !renderedFields) {
    return null
  }

  return (
    <>
      <Typography variant="h5" gutterBottom className={classes.header}>
        Select {resource} elements to override with changed value from{' '}
        {parentResource}
      </Typography>
      <ListComponent
        {...otherProps}
        // @ts-ignore
        sort={sort}
        hasCreate={false}
        filters={<GenericFilter />}
        filter={{
          and: [
            {
              [targetField]: { equalTo: targetId },
            },
          ],
        }}
        exporter={false}
        actions={
          <CardActions>
            <Button
              size="small"
              aria-label="Change Columns"
              onClick={openCustomizer}
              startIcon={<SettingsIcon />}
            >
              Column Customizer
            </Button>
          </CardActions>
        }
        bulkActionButtons={<CustomBulkButton handleOverride={handleOverride} />}
        className={classes.list}
      >
        <Responsive
          medium={
            <CustomizableDatagrid
              ref={dataGridRef}
              rowClick="toggleSelection"
              defaultColumns={renderedFields
                .filter(
                  ({ field: { isHiddenByDefaultInList } }) =>
                    !isHiddenByDefaultInList
                )
                .map(({ field: { name } }) => name)}
            >
              {renderedFields.map(({ rendered }) => {
                return rendered
              })}
            </CustomizableDatagrid>
          }
        />
      </ListComponent>
    </>
  )
}

type CustomBulkButtonProps = BulkActionProps & {
  handleOverride: (ids: Identifier[] | undefined) => void
}

const CustomBulkButton = (props: CustomBulkButtonProps) => {
  const { selectedIds, handleOverride } = props
  return (
    <Fragment>
      <Button
        variant="contained"
        color="primary"
        onClick={() => handleOverride(selectedIds)}
        startIcon={<FileCopyIcon />}
      >
        Override
      </Button>
    </Fragment>
  )
}
