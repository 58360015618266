import { createBrowserHistory } from 'history'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { useEffect, useState } from 'react'
import { Admin, Resource } from 'react-admin'
import { ApolloProvider } from 'react-apollo-hooks'
import { Provider } from 'react-redux'
import { Redirect } from 'react-router'
import './App.css'
import authProvider, { Permissions } from './authProvider'
import { getSchemaTraverser } from './dataProvider/buildQuery'
import dataProviderFactory, { apolloClient } from './dataProvider/graphql'
import { ConfirmationServiceProvider } from './genericData/ConfirmationService'
import { GenericDataList } from './genericData/GenericDataList'
import {
  GenericCreatePage,
  GenericEditPage,
} from './genericData/GenericEditPage'
import { GenericShowPage } from './genericData/GenericShowPage'
import englishMessages from './i18n/en'
import { Layout, Login } from './layout'
import { CustomLayoutLoading } from './layout/Layout'
import { smplColors } from './layout/themes'
import { filterNotEmpty } from './lib/filterNonEmpty'
import customRoutes from './routes'
import sagas from './sagas'
import { createAdminStore } from './sagas/createAdminStore'
import { LoadingSpinner } from './utils/LoadingSpinner'
import { getLoginPathPageNavigation } from './utils/afterLoginURL'
import { hideSplashScreen } from './utils/splashScreen'

const i18nProvider = polyglotI18nProvider(
  (locale) => {
    // Always fallback on english
    return englishMessages
  },
  'en',
  { allowMissing: true } // no longer throw missing error if using useNotify
)

const MainPageRedirect = () => {
  return <Redirect to="apps/insights" />
}
const LoginRedirect = () => {
  return (
    <Redirect
      to={getLoginPathPageNavigation(location.pathname + location.search)}
    />
  )
}

const history = createBrowserHistory()

type UnPromisify<T> = T extends Promise<infer U>
  ? U
  : T extends (...args: any[]) => Promise<infer U>
  ? U
  : T
type Store = UnPromisify<ReturnType<typeof createAdminStore>>
type DProvider = UnPromisify<ReturnType<typeof dataProviderFactory>>

const App = () => {
  const [{ store, dataProvider }, setRaData] = useState<{
    store: Store | null
    dataProvider: DProvider | null
  }>({ store: null, dataProvider: null })

  useEffect(() => {
    const fetchDataProvider = async () => {
      const canAccessAPI = await authProvider
        .checkAuth({})
        .then(() => true)
        .catch(() => false)

      if (!canAccessAPI && !location.pathname.startsWith('/login')) {
        location.href = getLoginPathPageNavigation(
          location.pathname + location.search
        )
        return
      }

      // if the user is not allowed to access the Data API or is not logged in. We don't want them to be able to load any data beforehand!
      const dProvider = canAccessAPI
        ? await dataProviderFactory()
        : await createFakeDataProvider()

      const s = await createAdminStore({
        dataProvider: dProvider,
        authProvider,
        history,
      })
      setRaData({ store: s, dataProvider: dProvider })
    }
    fetchDataProvider()

    const scrollBarStyle = `
    <style>
      ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      ::-webkit-scrollbar-track {
        background: ${smplColors.primary.main};
      }
      ::-webkit-scrollbar-thumb {
        background: ${smplColors.primary.light};
      }
      ::-webkit-scrollbar-thumb:hover {
        background: ${smplColors.primary.light};
      }
      ::selection {
        background: ${smplColors.primary.light};
        color: ${smplColors.primary.main}
      }
      .smpl__loader-cube {
        width: 10px;
        height: 10px;
        background: ${smplColors.secondary.main};
        box-shadow: 0 0 0.5rem #33333320, 0 0 1rem #33333320;
      }
      .sidebar__menu a[aria-current="page"]{
        background: ${smplColors.primary.main}!important;
        color: #ffffff!important;
        overflow:hidden;
      }
      .sidebar__menu a svg{
      }
      .sidebar__menu a[aria-current="page"] svg{
        fill: white!important;
      }
      .sidebar__menu a::after{
        content:"";
      }
      .sidebar__menu a[aria-current="page"]::after{
        content: "";
        position: absolute;
        width: 200px;
        height: 100px;
        background: ${smplColors.primary.light};
        left: 50%;
        top: 50%;
        filter: blur(15px);
        transform: translate(-50%, -50%);
        border-radius: 5rem;
        animation: swooshy 5s ease-in-out infinite;
      }
      @keyframes swooshy{
        0% {
          transform: translate(-50%, -50%) scale(0.2);
          opacity: 0;
          filter: blur(25px);
        }
        25% {
          transform: translate(-50%, -50%) scale(1);
          opacity: 0.4;
          filter: blur(15px);
        }
        100% {
          transform: translate(-50%, -50%) scale(2);
          opacity: 0;
        }
      }
      .search__results__list-item::before{
        content:"▸";
        position:absolute;
        font-size: 0.8rem;
      }

      .listItemEllipsis{
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    </style>
    `

    document.head.insertAdjacentHTML('beforeend', scrollBarStyle)
  }, [])

  useEffect(() => {
    if (!store) return
    const unsub = store.subscribe(() => {
      const state = store.getState()
      setRaData((prev) => {
        if (prev.dataProvider !== state.dataProvider) {
          console.log('data provider changed, triggering rerender', state)
          return { ...prev, dataProvider: state.dataProvider }
        }
        return prev
      })
    })
    return () => {
      unsub()
    }
  }, [store])

  const schemaTraverser = getSchemaTraverser()

  useEffect(() => {
    if (schemaTraverser) {
      // this is to prevent double loading screen
      authProvider.getPermissions({}).finally(() => hideSplashScreen())
    }
  }, [schemaTraverser])

  if (!store) {
    return <LoadingSpinner />
  }

  return (
    <ApolloProvider client={apolloClient}>
      {/* @ts-expect-error redux 8's TS support should solve this */}
      <Provider store={store}>
        <ConfirmationServiceProvider>
          <Admin
            title="SMPL Administration"
            // @ts-ignore
            dataProvider={dataProvider}
            customRoutes={customRoutes}
            // customSagas={schemaTraverser ? sagas : undefined}
            customSagas={sagas}
            authProvider={authProvider}
            dashboard={MainPageRedirect}
            loginPage={Login}
            // the layout is hidden anyway while the data provider is not ready (splash screen loading spinner)
            // unfortunately the layout uses the schema traverser to get the resource names all other the place
            layout={schemaTraverser ? Layout : CustomLayoutLoading}
            i18nProvider={i18nProvider}
            history={history}
            disableTelemetry
            // ready={LoadingSpinner} // replacing RA loading screen with ours
            loading={LoadingSpinner} // replacing RA loading screen with ours
          >
            {(permissions: Permissions) => {
              return (
                getSchemaTraverser()
                  .resourceNames // we can not do the filter here as ressource still have to exists (even if user with role can not access them),
                  // for the referenceField to not breaking completely
                  // .filter((rName) => permissions.tablePermissions[rName])
                  .map((rName) => {
                    const { canInsert, canUpdate } = permissions
                      .tablePermissions[rName] || {
                      canInsert: false,
                      canUpdate: false,
                    }

                    return (
                      <Resource
                        key={rName}
                        name={rName}
                        list={GenericDataList}
                        create={canInsert ? GenericCreatePage : undefined}
                        edit={canUpdate ? GenericEditPage : undefined}
                        show={GenericShowPage}
                      />
                    )
                  })
                  .filter(filterNotEmpty)
              )
            }}
          </Admin>
        </ConfirmationServiceProvider>
      </Provider>
    </ApolloProvider>
  )
}

export default App

/**
 * unfortunately the loading of the app is in the wrong order, the dataProvider is already triggering an introspection query before the auth provider could verify the session
 * @returns
 */
const createFakeDataProvider: typeof dataProviderFactory = async () => {
  return function LoadingStateFakeDataProvider(
    type: any,
    resource: any,
    params: any
  ) {
    const noOp = (...args) => {
      console.log('fake data provider called', type, resource, params, args)
      return Promise.reject('Data Provider not ready')
    }
    return {
      // get a list of records based on sort, filter, and pagination
      getList: noOp,
      // get a single record by id
      getOne: noOp,
      // get a list of records based on an array of ids
      getMany: noOp,
      // get the records referenced to another record, e.g. comments for a post
      getManyReference: noOp,
      // create a record
      create: noOp,
      // update a record based on a patch
      update: noOp,
      // update a list of records based on an array of ids and a common patch
      updateMany: noOp,
      // delete a record by id
      delete: noOp,
      // delete a list of records based on an array of ids
      deleteMany: noOp,
    }
  }
}
