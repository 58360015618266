import {
  ButtonGroup,
  CircularProgress,
  InputAdornment,
} from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Chip from '@material-ui/core/Chip'
import MuiTextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import EjectIcon from '@material-ui/icons/Eject'
import FastForwardIcon from '@material-ui/icons/FastForward'
import RedoIcon from '@material-ui/icons/Redo'
import SaveIcon from '@material-ui/icons/Save'
import { motion } from 'framer-motion'
import React, { ComponentProps, Suspense, useMemo, useState } from 'react'
import { useDataProvider, useNotify } from 'react-admin'
import { useMutation, useQuery } from 'react-apollo-hooks'
import { TranscodingJobByClipId } from '../../__generated__/TranscodingJobByClipId'
import { TranscodingJobByEpisodeId } from '../../__generated__/TranscodingJobByEpisodeId'
import { TranscodingJobByMovieId } from '../../__generated__/TranscodingJobByMovieId'
import { UpdateEpisode } from '../../__generated__/UpdateEpisode'
import { UpdateMovie } from '../../__generated__/UpdateMovie'
import { UpdateTranscodingJob } from '../../__generated__/UpdateTranscodingJob'
import {
  CmsMoviePatch,
  TranscodingJobPatch,
} from '../../__generated__/globalTypes'
import { authedFetch } from '../../dataProvider/authedFetch'
import { EpisodeMutation } from '../../graphQL/episode'
import { MovieMutation } from '../../graphQL/movie'
import {
  TranscodingJobByClipIdQuery,
  TranscodingJobByEpisodeIdQuery,
  TranscodingJobByMovieIdQuery,
  TranscodingJobMutation,
} from '../../graphQL/transcodingJob'
import { smplColors } from '../../layout/themes'
import {
  HLS_VIDEO_URL_PREFIX,
  PMD_VIDEO_URL_PREFIX,
  SMPL_ENV,
  VIDEOPLAYER_FAIRPLAY_CERTIFICATE_URL,
  VIDEOPLAYER_FAIRPLAY_LICENSE_SERVER,
  VIDEOPLAYER_PLAYREADY_LICENSE_SERVER,
  VIDEOPLAYER_WIDEWINE_LICENSE_SERVER,
} from '../../lib/config'
import { MediaInfo } from '../node-actions/transcoding/MediaInfo'
import { MaxLengthUrlField } from './MaxLengthUrlField'

import { toNumber } from 'lodash'
import {
  animDelay,
  animInitial,
  animInitialScale,
  animTarget,
  animTargetScale,
  animTransition,
} from '../../layout/animationSettings'

const fieldWidth = window.innerWidth - 400
const aspectRatioHeight = Math.floor(fieldWidth * 0.5625)

const useStyles = makeStyles(() => ({
  urlField: {
    width: 600,
  },
  container: {
    width: fieldWidth,
    overflowY: 'hidden',
  },
  videoWrapper: {
    marginTop: 20,
  },
  videoContainer: {
    width: fieldWidth,
    height: aspectRatioHeight,
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 20,
    '& video': {
      width: fieldWidth,
      minHeight: aspectRatioHeight,
    },
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: 12,
  },
  timeBox: {
    width: 'max-content',
  },
  selectorBox: {
    display: 'flex',
    '&>div': {
      marginTop: 'auto',
      marginRight: 10,
    },
  },
  playerDisplay: {
    width: fieldWidth,
    height: 70,
    backgroundColor: 'black',
    color: 'white',
    fontFamily: "'DS-Digital', sans-serif",
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 50,
    borderRadius: '0 0 15px 15px',
    boxShadow: `${smplColors.secondary.main}50 0 0 50px inset`,
  },
  timer: {
    color: smplColors.secondary.main,
    textShadow: `${smplColors.secondary.main} 0 0 15px`,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& span:first-child': {
      fontSize: 12,
    },
    '& span:last-child': {
      fontSize: 30,
    },
  },
  controlBox: {
    width: fieldWidth,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 50,
    paddingTop: 10,
    paddingBottom: 15,
  },
  controlGroup: {
    display: 'flex',
    gap: 5,
    flexDirection: 'column',
    alignItems: 'center',
  },
  controlTitle: {
    color: 'white',
    fontSize: 10,
    textTransform: 'uppercase',
  },
  controlLine: {
    height: 1,
    width: '100%',
    background: smplColors.secondary.main,
    opacity: 0.5,
  },
  controlWrap: {
    background: smplColors.primary.main,
    borderRadius: '0 0 15px 15px',
  },

  addtionalInfoBox: {
    display: 'inline-flex',
    gap: 15,
  },
  addionalInfoGroup: {
    marginTop: 30,
    marginLeft: 30,
  },

  chip: { margin: 2 },

  shortcutBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: 15,
    maxWidth: fieldWidth,
    // backgroundColor: `${smplColors.secondary.main}30`,
    // marginTop: 80,
    padding: '3rem 3rem',
    borderRadius: 15,
  },
  shortcutGroup: {
    display: 'flex',
    gap: 30,
    backgroundColor: `${smplColors.primary.main}`,
    color: 'white',
    alignItems: 'center',
    padding: '1rem 1.25rem',
    borderRadius: 8,
  },
  shortCutCombo: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  shortcutDescription: {
    fontSize: 14,
    textTransform: 'uppercase',
  },
  shortcutKey: {
    background: '#cccccc',
    padding: '0.5rem 0.85rem',
    fontWeight: 800,
    color: '#333333',
    borderRadius: 5,
    boxShadow:
      '1px 1px 0 #777777, 0px 2px 0 #777777, 0px 3px 0 #666666, 0px 4px 0 #555555, 0px 5px 0 #444444, inset 0 0 8px #ffffff80',
  },
}))

const AsyncVideoPlayer = React.lazy(() => {
  return import('../../apps/transcoding/VideoPlayer').then(
    ({ VideoPlayer }) => {
      return {
        default: VideoPlayer,
      }
    }
  )
})

export type TimeFieldSetting = {
  label: string
  value: number | number[]
  field: string
  setFunc:
    | React.Dispatch<React.SetStateAction<number>>
    | React.Dispatch<React.SetStateAction<number[]>>
}

export type TimeFieldSettings = TimeFieldSetting[]

const VideoplayerImpl = (props: any) => {
  // record will contain awsLastNotification if it's called from TranscodingJob not if called from Movie or Episode
  const { record, resource } = props
  // externalIdentifier === vendorId, vendorId is externalIdentifier equivalence in transcodingJob
  const {
    videoSource,
    externalIdentifier,
    vendorId,
    metadata,
    id: contentGuid,

    // only exist in TransacodingJob
    awsStatus,
    status,
    mediaInfo,
    movieId,
    episodeId,

    introStartTime,
    introEndTime,
    creditsStartTime,
    runtimeInSeconds,
    adBreakStartTimes,
  } = record

  const dataProvider = useDataProvider()

  const applyChangeFunc =
    resource === 'CmsMovie'
      ? useMutation<UpdateMovie>(MovieMutation)
      : resource === 'CmsEpisode'
      ? useMutation<UpdateEpisode>(EpisodeMutation)
      : useMutation<UpdateTranscodingJob>(TranscodingJobMutation)

  const secondaryApplyChangeFunc =
    resource === 'TranscodingJob'
      ? movieId
        ? useMutation<UpdateMovie>(MovieMutation)
        : episodeId
        ? useMutation<UpdateEpisode>(EpisodeMutation)
        : undefined
      : undefined

  let parsedMetadata: { [fieldName in string]?: any }
  let parsedMetaInfo: MediaInfo | undefined
  let parsedJobtempate:
    | {
        Settings?: {
          Inputs?: {
            FileInput?: string // s3://xyz-transcodinginput/xvz-...
          }[]
        }
      }
    | undefined
  let framerate = 24
  const shouldShowTimeSetter =
    resource === 'CmsMovie' ||
    resource === 'CmsEpisode' ||
    resource === 'TranscodingJob'

  // Player tracker
  const [selectedTime, setSelectedTime] = useState<number | string>(0)
  const [playerLoaded, setPlayerLoaded] = useState<boolean>(false)

  // value tracker
  const [currentIntroStartTime, setCurrentIntroStartTime] = useState<number>(
    introStartTime ? introStartTime : 0
  )
  const [currentIntroEndTime, setCurrentIntroEndTime] = useState<number>(
    introEndTime ? introEndTime : 0
  )
  const [
    currentCreditsStartTime,
    setCurrentCreditsStartTime,
  ] = useState<number>(creditsStartTime ? creditsStartTime : 0)
  const [currentRunTime, setCurrentRunTime] = useState<number>(
    runtimeInSeconds ? runtimeInSeconds : 0
  )
  const [currentAdBreakStartTimes, setCurrentAdBreakStartTimes] = useState<
    number[]
  >(adBreakStartTimes ? adBreakStartTimes : [])

  // Player control
  const [timePositionToJump, setTimePositionToJump] = useState<
    number | undefined
  >(undefined)

  const timeFieldSettings: TimeFieldSettings = [
    {
      label: 'Intro Start Time',
      value: currentIntroStartTime,
      field: 'introStartTime',
      setFunc: setCurrentIntroStartTime,
    },
    {
      label: 'Intro End Time',
      value: currentIntroEndTime,
      field: 'introEndTime',
      setFunc: setCurrentIntroEndTime,
    },
    {
      label: 'Credits Start Time',
      value: currentCreditsStartTime,
      field: 'creditsStartTime',
      setFunc: setCurrentCreditsStartTime,
    },
    {
      label: 'Run Time',
      value: currentRunTime,
      field: 'runtimeInSeconds',
      setFunc: setCurrentRunTime,
    },
    {
      label: 'Ad Break Start Times',
      value: currentAdBreakStartTimes, // number[]
      field: 'adBreakStartTimes',
      setFunc: setCurrentAdBreakStartTimes,
    },
  ]

  const setTimeAndSaveToField = async (tfs: TimeFieldSetting) => {
    const { label, value, field, setFunc } = tfs
    try {
      if (typeof value === 'number' && isNumber(selectedTime)) {
        const truncatedTime = toNumber((selectedTime as number).toFixed(3))
        const res = await applyChangeFunc({
          variables: {
            id: record.id,
            patch: {
              [field]: truncatedTime,
            } as TranscodingJobPatch,
          },
        })
        notify(`${label} set to ${truncatedTime}s`, 'success')

        if (secondaryApplyChangeFunc) {
          const id = movieId || episodeId
          const res = await secondaryApplyChangeFunc({
            variables: {
              id,
              patch: {
                [field]: truncatedTime,
              } as CmsMoviePatch,
            },
          })
          notify(
            `${label} of ${
              movieId ? 'movie' : 'epsiode'
            } object set to ${truncatedTime}s`,
            'success'
          )
        }
        // @ts-expect-error value is of type number and so is selectedTime
        setFunc(selectedTime)
      } else if (Array.isArray(value) && isNumber(selectedTime)) {
        const truncatedTime = toNumber((selectedTime as number).toFixed(3))

        // check if selectedTime already exists
        if (value.includes(truncatedTime)) {
          notify(`${label} already contains ${selectedTime}s.`, 'warning')
          return
        }

        const newValues = [...value, truncatedTime].sort((a, b) => a - b)
        const res = await applyChangeFunc({
          variables: {
            id: record.id,
            patch: {
              [field]: newValues,
            } as TranscodingJobPatch,
          },
        })
        notify(`Saving ${truncatedTime}s into ${label}`, 'success')

        if (secondaryApplyChangeFunc) {
          const id = movieId || episodeId
          const res = await secondaryApplyChangeFunc({
            variables: {
              id,
              patch: {
                [field]: newValues,
              } as CmsMoviePatch,
            },
          })
          notify(
            `${label} of ${
              movieId ? 'movie' : 'epsiode'
            } object set to ${truncatedTime}s`,
            'success'
          )
        }
        // @ts-expect-error value is of type number[]
        setFunc(newValues)
      }
    } catch (error) {
      console.error(error)
      notify(`Failed to save ${label}.`, 'error')
    }
  }

  // only to be called for value of type number[]
  const removeTimeAndSaveToField = async (
    tfs: TimeFieldSetting,
    toBeRemovedTimeValue: number
  ) => {
    const { label, value, field, setFunc } = tfs
    try {
      if (Array.isArray(value) && isNumber(toBeRemovedTimeValue)) {
        const truncatedTime = toNumber(
          (toBeRemovedTimeValue as number).toFixed(3)
        )
        const newValue = value.filter((v) => v !== truncatedTime)
        const res = await applyChangeFunc({
          variables: {
            id: record.id,
            patch: {
              [field]: newValue,
            } as TranscodingJobPatch,
          },
        })
        notify(`Removing ${truncatedTime}s from ${label}`, 'success')

        if (secondaryApplyChangeFunc) {
          const id = movieId || episodeId
          const res = await secondaryApplyChangeFunc({
            variables: {
              id,
              patch: {
                [field]: newValue,
              } as CmsMoviePatch,
            },
          })
          notify(
            `${label} of ${
              movieId ? 'movie' : 'epsiode'
            } object set to ${newValue}`,
            'success'
          )
        }
        // @ts-expect-error newValue is of type number[]
        setFunc(newValue)
      }
    } catch (error) {
      console.error(error)
      notify(`Failed to save ${label}.`, 'error')
    }
  }

  // in CmsClip, can not call this conditionally but will return null so all is fine
  const clipRes = useQuery<TranscodingJobByClipId>(
    TranscodingJobByClipIdQuery,
    {
      variables: {
        id: contentGuid,
      },
    }
  )
  const movieRes = useQuery<TranscodingJobByMovieId>(
    TranscodingJobByMovieIdQuery,
    {
      variables: {
        id: contentGuid,
      },
    }
  )
  const episodeRes = useQuery<TranscodingJobByEpisodeId>(
    TranscodingJobByEpisodeIdQuery,
    {
      variables: {
        id: contentGuid,
      },
    }
  )

  const transcodingJob =
    clipRes.data?.cmsClipById?.transcodingJobsByClipId.nodes[0] ||
    movieRes.data?.cmsMovieById?.transcodingJobsByMovieId.nodes[0] ||
    episodeRes.data?.cmsEpisodeById?.transcodingJobsByEpisodeId.nodes[0]
  const mdata = transcodingJob?.metadata
  const mInfo = transcodingJob?.mediaInfo
  const jTemplate = transcodingJob?.jobTemplate

  if (mdata) {
    parsedMetadata = JSON.parse(mdata)
  }
  if (mInfo) {
    parsedMetaInfo = JSON.parse(mInfo)
  }
  if (jTemplate) {
    parsedJobtempate = JSON.parse(jTemplate)
  }

  // in TranscodingJob
  if (vendorId) {
    if (metadata) {
      parsedMetadata = JSON.parse(metadata)
    }
    if (mediaInfo) {
      parsedMetaInfo = JSON.parse(mediaInfo)
    }
  }

  if (parsedMetaInfo) {
    framerate = parsedMetaInfo.media.track.find((t) => t.FrameRate)
      ? parseFloat(
          parsedMetaInfo.media.track.find((t) => t.FrameRate)!.FrameRate!
        )
      : framerate
  }

  const classes = useStyles()
  const notify = useNotify()
  const [downloading, setDownloading] = useState(false)

  let isSafari = false
  let isChrome = false

  var ua = navigator.userAgent.toLowerCase()
  if (ua.indexOf('safari') !== -1) {
    if (ua.indexOf('chrome') > -1) {
      isChrome = true
    } else {
      isSafari = true
    }
  }

  let dashURL = ''
  let hlsURL = ''
  let pmdURL = ''

  // if videoSource === null there will be a Get
  if (videoSource !== null) {
    if (videoSource.hlsUrl) {
      hlsURL = videoSource.hlsUrl
    }

    if (videoSource.dashUrl) {
      dashURL = videoSource.dashUrl
    }

    if (videoSource.pmdUrl) {
      pmdURL = videoSource.pmdUrl
    }
  }

  const drm = useMemo(() => {
    return {
      widevine: {
        licenseUrl: VIDEOPLAYER_WIDEWINE_LICENSE_SERVER + contentGuid,
      },
      fairplay: {
        licenseUrl: VIDEOPLAYER_FAIRPLAY_LICENSE_SERVER + contentGuid,
        certificateUrl: VIDEOPLAYER_FAIRPLAY_CERTIFICATE_URL,
      },
      playready: {
        licenseUrl: VIDEOPLAYER_PLAYREADY_LICENSE_SERVER + contentGuid,
      },
    }
  }, [contentGuid])

  const trailerRegex = /_trailer$/

  const startDownload = async () => {
    let file_name: string, base_path: string
    if (parsedMetadata) {
      console.log('Attempt to extract file_name and base_path from metadata.')
      if (parsedMetadata?.assetsOverview?.assets?.video[0]?.file_name) {
        file_name = parsedMetadata.assetsOverview.assets.video[0]?.file_name
      }
      if (parsedMetadata?.assetsOverview?.assets?.video[0]?.base_path) {
        base_path = parsedMetadata.assetsOverview.assets.video[0]?.base_path
        if (base_path.endsWith('.mov')) {
          file_name = parsedMetadata.assetsOverview.assets.video[0]?.base_path
            .split('/')
            .pop()
        }
      }
    }

    if ((!file_name || !base_path) && parsedJobtempate) {
      console.log('query for mezzanine transcoding job')
      // checking for mezzanine_transcoding job existance
      const mezzTranscodingJob = await dataProvider.getList('TranscodingJob', {
        filter: {
          vendorId: { equalTo: transcodingJob.vendorId + '_mezzanine' },
        },
        pagination: {
          page: 1,
          perPage: 1000, // there will not really be 1000 connection, this will just give back everything
        },
        sort: { field: 'createdDate', order: 'ASC' },
      })

      if (mezzTranscodingJob.data.length > 0) {
        console.log('mezzanine transcoding job found', mezzTranscodingJob.data)

        for (const mTJ of mezzTranscodingJob.data) {
          const awsLN = mTJ.awsLastNotification
            ? JSON.parse(mTJ.awsLastNotification)
            : {}

          const outputFilePaths: string =
            awsLN.detail?.outputGroupDetails?.[0]?.outputDetails?.[0]
              ?.outputFilePaths?.[0]

          base_path = outputFilePaths.replace('s3://', '')
          file_name = outputFilePaths.split('/').pop()
        }
      }

      if (!file_name || !base_path) {
        console.log(
          'no mezzanine transcoding job found, attempt to extract from parsed jobTemplate instead'
        )
        if (parsedJobtempate?.Settings?.Inputs.length > 0) {
          for (const jTempl of parsedJobtempate.Settings.Inputs) {
            const fileInputs = jTempl.FileInput
            if (fileInputs) {
              base_path = fileInputs
              base_path = base_path.replace('s3://', '')
              file_name = fileInputs.split('/').pop()
            }
          }
        }
      }
    }

    if (!file_name || !base_path) {
      notify(
        'Download failed. If this problem persists, please contact the support.',
        'error'
      )
      console.error('Can not get file_name or base_path.')
      return
    }

    // trailer storage fix in TransacodingJob
    if (resource === 'TranscodingJob') {
      if (
        awsStatus === 'COMPLETE' &&
        status === 'COMPLETED' &&
        vendorId.includes('_STORAGE_FIX_trailer')
      ) {
        // change file_name && basePath
        file_name = vendorId.replace(
          '_STORAGE_FIX_trailer',
          '_STANDALONE_TRAILER_STEREO.mp4'
        )

        // split by '/' and remove the last as that is the old filename, add new filename
        const basePathSplit = base_path.split('/')
        base_path =
          basePathSplit.slice(0, basePathSplit.length - 1).join('/') +
          '/' +
          file_name
      }
    } else if (transcodingJob) {
      // we are in CmsClip, download are not in other resources
      if (
        transcodingJob.awsStatus === 'COMPLETE' &&
        transcodingJob.status === 'COMPLETED' &&
        transcodingJob.vendorId &&
        transcodingJob.vendorId.includes('_STORAGE_FIX_trailer')
      ) {
        // change file_name && basePath
        file_name = transcodingJob.vendorId.replace(
          '_STORAGE_FIX_trailer',
          '_STANDALONE_TRAILER_STEREO.mp4'
        )

        // split by '/' and remove the last as that is the old filename, add new filename
        const basePathSplit = base_path.split('/')
        base_path =
          basePathSplit.slice(0, basePathSplit.length - 1).join('/') +
          '/' +
          file_name
      }
    }

    const bodyData = {
      extId: externalIdentifier || vendorId,
      file_name: file_name,
      base_path: base_path,
    }

    console.log('request access for', bodyData)

    const params = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    }

    const res = await authedFetch(
      process.env.PUBLIC_URL + '/api/getPreSignedS3UrlForDownload',
      params
    )
    const resData = await res.json()

    if (resData.success === true) {
      const preSignedUrl = resData.preSignedUrl
      // start file download
      download(file_name, preSignedUrl)
    } else {
      notify(
        'Download failed. ' +
          resData.error.message +
          ' If this problem persists, please contact the support.',
        'error'
      )
      console.error(resData.error.message)
    }
  }

  /**
   * Call server for presigned url, then use that url to download the video
   */
  const onClickDownload = async () => {
    try {
      setDownloading(true)
      await startDownload()
    } catch (error) {
      notify(
        'Download failed. The server could not be contacted. If this problem persists, please contact the support.',
        'error'
      )
      console.error('Communication with backend server failed.', error)
    }

    setDownloading(false)
  }
  const content: ComponentProps<typeof AsyncVideoPlayer>['content'] = {
    __typename: record.__typename,
    id: record.id,
  }

  function replaceCDNURL(url: string) {
    // ard uses cookie based token protection which works (in incognito mode/Safari) only with the same domain
    return url.replace(
      'cdn.ardplus.de',
      SMPL_ENV !== 'dev' ? 'cdn.ard.eu1.smpl.services' : 'cdn.ard.smpl.dev'
    )
  }

  return (
    <div className={classes.container}>
      {/* Download button in case of trailer and only trailer */}
      {(externalIdentifier !== undefined &&
        trailerRegex.test(externalIdentifier)) ||
      (vendorId !== undefined && trailerRegex.test(vendorId)) ? (
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={onClickDownload}
          endIcon={downloading ? <CircularProgress size={23} /> : null}
          disabled={downloading}
        >
          Download trailer
        </Button>
      ) : null}
      {dashURL.length > 0 ? (
        <motion.div
          initial={animInitial}
          animate={animTarget}
          transition={animTransition}
        >
          <p className={classes.label}>Dash:</p>
          <MaxLengthUrlField
            className={classes.urlField}
            source="url"
            record={{
              id: 'null',
              url: dashURL.includes('http')
                ? dashURL
                : PMD_VIDEO_URL_PREFIX
                ? new URL(dashURL, PMD_VIDEO_URL_PREFIX).href
                : '',
            }}
          />
          <br></br>
        </motion.div>
      ) : null}
      {hlsURL.length > 0 ? (
        <motion.div
          initial={animInitial}
          animate={animTarget}
          transition={{ delay: animDelay, ...animTransition }}
        >
          <span>
            <p className={classes.label}>Hls:</p>
            <MaxLengthUrlField
              className={classes.urlField}
              source="url"
              record={{
                id: 'null',
                url: hlsURL.includes('http')
                  ? hlsURL
                  : HLS_VIDEO_URL_PREFIX
                  ? new URL(hlsURL, HLS_VIDEO_URL_PREFIX).href
                  : '',
              }}
            />
          </span>
          <br></br>
        </motion.div>
      ) : null}
      {pmdURL.length > 0 && PMD_VIDEO_URL_PREFIX ? (
        <>
          <span>
            <p className={classes.label}>Pmd:</p>
            <MaxLengthUrlField
              className={classes.urlField}
              source="url"
              prefix={PMD_VIDEO_URL_PREFIX}
              record={{
                id: 'null',
                url: pmdURL.includes('http')
                  ? pmdURL
                  : new URL(pmdURL, PMD_VIDEO_URL_PREFIX).href,
              }}
            />
          </span>
          <br></br>
        </>
      ) : null}
      <Suspense fallback={<div>Loading Videoplayer...</div>}>
        {isChrome ? (
          dashURL.length > 0 ? (
            <div className={classes.videoWrapper}>
              <motion.div
                initial={animInitial}
                animate={animTarget}
                transition={{ delay: animDelay * 2, ...animTransition }}
              >
                <Chip
                  color="primary"
                  label="Current format: Dash - Use safari browser for hls format preview."
                />
              </motion.div>

              <motion.div
                initial={animInitial}
                animate={animTarget}
                transition={{ delay: 0.75, ...animTransition }}
                className={classes.videoContainer}
              >
                <AsyncVideoPlayer
                  content={content}
                  videoSourceUrl={replaceCDNURL(
                    dashURL.includes('http')
                      ? dashURL
                      : PMD_VIDEO_URL_PREFIX
                      ? new URL(dashURL, PMD_VIDEO_URL_PREFIX).href
                      : ''
                  )}
                  drm={drm}
                  setSelectedTime={setSelectedTime}
                  setPlayerLoaded={setPlayerLoaded}
                  timePositionToJump={timePositionToJump}
                  setTimePositionToJump={setTimePositionToJump}
                  framerate={framerate}
                  timeFieldSettings={timeFieldSettings}
                  mutationByTfs={setTimeAndSaveToField}
                ></AsyncVideoPlayer>
              </motion.div>
            </div>
          ) : (
            <p>No video preview in Dash available.</p>
          )
        ) : null}
        {isSafari ? (
          hlsURL.length > 0 ? (
            <div className={classes.videoWrapper}>
              <motion.div
                initial={animInitial}
                animate={animTarget}
                transition={{ delay: animDelay * 3, ...animTransition }}
              >
                <Chip
                  color="primary"
                  label="Current format: HLS - Use chrome browser for dash format preview."
                />
              </motion.div>
              <motion.div
                initial={animInitial}
                animate={animTarget}
                transition={{ delay: animDelay * 4, ...animTransition }}
                className={classes.videoContainer}
              >
                <AsyncVideoPlayer
                  content={content}
                  videoSourceUrl={replaceCDNURL(
                    hlsURL.includes('http')
                      ? hlsURL
                      : HLS_VIDEO_URL_PREFIX
                      ? new URL(hlsURL, HLS_VIDEO_URL_PREFIX).href
                      : ''
                  )}
                  drm={drm}
                  setSelectedTime={setSelectedTime}
                  setPlayerLoaded={setPlayerLoaded}
                  timePositionToJump={timePositionToJump}
                  setTimePositionToJump={setTimePositionToJump}
                  framerate={framerate}
                  timeFieldSettings={timeFieldSettings}
                  mutationByTfs={setTimeAndSaveToField}
                ></AsyncVideoPlayer>
              </motion.div>
            </div>
          ) : (
            <p>No video preview in HLS available.</p>
          )
        ) : null}
        {PMD_VIDEO_URL_PREFIX ? (
          pmdURL && pmdURL.length > 0 ? (
            <>
              <motion.div
                initial={animInitial}
                animate={animTarget}
                transition={{ delay: animDelay * 3, ...animTransition }}
              >
                <Chip label="Current format: PMD" />
              </motion.div>
              <motion.div
                initial={animInitial}
                animate={animTarget}
                transition={{ delay: animDelay * 4, ...animTransition }}
                className={classes.videoContainer}
              >
                <AsyncVideoPlayer
                  content={content}
                  videoSourceUrl={replaceCDNURL(
                    pmdURL.includes('http')
                      ? pmdURL
                      : new URL(pmdURL, PMD_VIDEO_URL_PREFIX).href
                  )}
                  drm={drm}
                  setSelectedTime={setSelectedTime}
                  setPlayerLoaded={setPlayerLoaded}
                  timePositionToJump={timePositionToJump}
                  setTimePositionToJump={setTimePositionToJump}
                  framerate={framerate}
                  timeFieldSettings={timeFieldSettings}
                  mutationByTfs={setTimeAndSaveToField}
                ></AsyncVideoPlayer>
              </motion.div>
            </>
          ) : (
            <p>No video preview in Pmd available.</p>
          )
        ) : null}
      </Suspense>

      {playerLoaded && shouldShowTimeSetter ? (
        <>
          <div className={classes.controlWrap}>
            <motion.div
              initial={animInitial}
              animate={animTarget}
              transition={animTransition}
              className={classes.playerDisplay}
            >
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay * 2, ...animTransition }}
                className={classes.timer}
              >
                <span>Intro Start</span>
                <span>{`${currentIntroStartTime.toFixed(3)}`}</span>
              </motion.div>
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay, ...animTransition }}
                className={classes.timer}
              >
                <span>Intro End</span>
                <span>{`${currentIntroEndTime.toFixed(3)}`}</span>
              </motion.div>
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={animTransition}
                className={classes.timer}
                style={{ padding: '0 5rem' }}
              >
                <span>Current Frame</span>

                <span>{`${Number(selectedTime).toFixed(3)}`}</span>
              </motion.div>
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay, ...animTransition }}
                className={classes.timer}
              >
                <span>Credits Start</span>
                <span>{`${currentCreditsStartTime.toFixed(3)}`}</span>
              </motion.div>
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay * 2, ...animTransition }}
                className={classes.timer}
              >
                <span>Runtime</span>
                <span>{`${currentRunTime.toFixed(3)}`}</span>
              </motion.div>
            </motion.div>
            <div className={classes.controlBox}>
              {/* INTRO START */}
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay * 3, ...animTransition }}
                className={classes.controlGroup}
              >
                <div className={classes.controlTitle}>Intro Start</div>
                <div className={classes.controlLine}></div>
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={async () => {
                      await setTimeAndSaveToField(timeFieldSettings[0])
                    }}
                  >
                    <SaveIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={() => {
                      setTimePositionToJump(
                        timeFieldSettings[0].value as number
                      )
                    }}
                  >
                    <RedoIcon />
                  </Button>
                </ButtonGroup>
                <div className={classes.controlLine}></div>
              </motion.div>
              {/* INTRO END */}
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay * 2, ...animTransition }}
                className={classes.controlGroup}
              >
                <div className={classes.controlTitle}>Intro End</div>
                <div className={classes.controlLine}></div>
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={async () => {
                      await setTimeAndSaveToField(timeFieldSettings[1])
                    }}
                  >
                    <SaveIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={() => {
                      setTimePositionToJump(
                        timeFieldSettings[1].value as number
                      )
                    }}
                  >
                    <RedoIcon />
                  </Button>
                </ButtonGroup>
                <div className={classes.controlLine}></div>
              </motion.div>
              {/* FRAME BY FRAME */}
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay, ...animTransition }}
                className={classes.controlGroup}
              >
                <div className={classes.controlTitle}>Frame by Frame</div>
                <div className={classes.controlLine}></div>
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={() => {
                      if (
                        typeof selectedTime === 'number' &&
                        isNumber(selectedTime)
                      )
                        setTimePositionToJump(selectedTime - 1 / framerate) // jump also trigger setSelected
                    }}
                  >
                    <EjectIcon style={{ transform: 'rotate(-90deg)' }} />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={() => {
                      if (
                        typeof selectedTime === 'number' &&
                        isNumber(selectedTime)
                      )
                        setTimePositionToJump(selectedTime + 1 / framerate) // jump also trigger setSelected
                    }}
                  >
                    <EjectIcon style={{ transform: 'rotate(90deg)' }} />
                  </Button>
                </ButtonGroup>
                <div className={classes.controlLine}></div>
              </motion.div>
              {/* CREDITS START */}
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay * 2, ...animTransition }}
                className={classes.controlGroup}
              >
                <div className={classes.controlTitle}>Credits Start</div>
                <div className={classes.controlLine}></div>
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={async () => {
                      await setTimeAndSaveToField(timeFieldSettings[2])
                    }}
                  >
                    <SaveIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={() => {
                      setTimePositionToJump(
                        timeFieldSettings[2].value as number
                      )
                    }}
                  >
                    <RedoIcon />
                  </Button>
                </ButtonGroup>
                <div className={classes.controlLine}></div>
              </motion.div>
              {/* RUN TIME */}
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay * 3, ...animTransition }}
                className={classes.controlGroup}
              >
                <div className={classes.controlTitle}>Runtime</div>
                <div className={classes.controlLine}></div>
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={async () => {
                      await setTimeAndSaveToField(timeFieldSettings[3])
                    }}
                  >
                    <SaveIcon />
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={() => {
                      setTimePositionToJump(
                        timeFieldSettings[3].value as number
                      )
                    }}
                  >
                    <RedoIcon />
                  </Button>
                </ButtonGroup>
                <div className={classes.controlLine}></div>
              </motion.div>
              {/* AD BREAK */}
              <motion.div
                initial={animInitialScale}
                whileInView={animTargetScale}
                transition={{ delay: animDelay * 3, ...animTransition }}
                className={classes.controlGroup}
              >
                <div className={classes.controlTitle}>Ad Break</div>
                <div className={classes.controlLine}></div>
                <ButtonGroup>
                  <Button
                    variant="contained"
                    color="primary"
                    style={{ height: 40, filter: 'brightness(1.2)' }}
                    onClick={async () => {
                      await setTimeAndSaveToField(timeFieldSettings[4])
                    }}
                  >
                    <SaveIcon />
                  </Button>
                </ButtonGroup>
                <div className={classes.controlLine}></div>
              </motion.div>
            </div>
          </div>

          <div className={classes.addtionalInfoBox}>
            <div className={classes.addionalInfoGroup}>
              <div>
                <p className={classes.label}>Manually adjust current time</p>
                <MuiTextField
                  value={selectedTime}
                  type="number"
                  onChange={(e) => {
                    const val = e.target.value
                    if (val.length === 0) setSelectedTime('')
                    const numval = parseFloat(val)
                    if (isNumber(numval) && numval >= 0) {
                      setSelectedTime(numval)
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">s</InputAdornment>
                    ),
                  }}
                />
              </div>
              <div>
                <Button
                  onClick={() => {
                    if (
                      typeof selectedTime === 'number' &&
                      isNumber(selectedTime)
                    )
                      setTimePositionToJump(selectedTime)
                  }}
                  startIcon={<FastForwardIcon />}
                >
                  Jump To Position
                </Button>
              </div>
            </div>

            <div className={classes.addionalInfoGroup}>
              <div>
                <p className={classes.label}>Ad Breaks Times</p>
                {currentAdBreakStartTimes.length > 0
                  ? currentAdBreakStartTimes.map((timeValue) => {
                      return (
                        <Chip
                          className={classes.chip}
                          key={timeValue}
                          label={`${timeValue.toFixed(3)}s`}
                          color="primary"
                          icon={<RedoIcon />}
                          onClick={() => {
                            setTimePositionToJump(timeValue)
                          }}
                          onDelete={async () => {
                            await removeTimeAndSaveToField(
                              timeFieldSettings[4],
                              timeValue
                            )
                          }}
                        />
                      )
                    })
                  : undefined}
              </div>
            </div>
          </div>

          <div className={classes.shortcutBox}>
            Those Shortcut Keys only work when the video is selected.
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>A</div>
                <span>or</span>
                <div className={classes.shortcutKey}>D</div>
              </div>
              <div className={classes.shortcutDescription}>
                move backward / forward by 1 second
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>Shift</div>
                <span>+</span>
                <div className={classes.shortcutKey}>A</div>
                <span>or</span>
                <div className={classes.shortcutKey}>D</div>
              </div>
              <div className={classes.shortcutDescription}>
                move backward / forward by 1 frame
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>S</div>
              </div>
              <div className={classes.shortcutDescription}>
                jump to the beginning of video
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>F</div>
              </div>
              <div className={classes.shortcutDescription}>
                jump to the end of video
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>Shift</div>
                <span>+</span>
                <div className={classes.shortcutKey}>Q</div>
              </div>
              <div className={classes.shortcutDescription}>
                save current time to intro start
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>Shift</div>
                <span>+</span>
                <div className={classes.shortcutKey}>W</div>
              </div>
              <div className={classes.shortcutDescription}>
                save current time to intro end
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>Shift</div>
                <span>+</span>
                <div className={classes.shortcutKey}>E</div>
              </div>
              <div className={classes.shortcutDescription}>
                save current time to credits start
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>Shift</div>
                <span>+</span>
                <div className={classes.shortcutKey}>R</div>
              </div>
              <div className={classes.shortcutDescription}>
                save current time to run time
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>Shift</div>
                <span>+</span>
                <div className={classes.shortcutKey}>X</div>
              </div>
              <div className={classes.shortcutDescription}>
                save current time to ad break times
              </div>
            </motion.div>
            <motion.div
              initial={animInitial}
              whileInView={animTarget}
              transition={animTransition}
              viewport={{ once: true }}
              className={classes.shortcutGroup}
            >
              <div className={classes.shortCutCombo}>
                <div className={classes.shortcutKey}>SPACE</div>
              </div>
              <div className={classes.shortcutDescription}>
                start / stop video
              </div>
            </motion.div>
          </div>
          <div style={{ paddingBottom: 120 }} />
        </>
      ) : null}
    </div>
  )
}

function isNumber(value: any) {
  return typeof value === 'number' && isFinite(value)
}

export const Videoplayer = React.memo(VideoplayerImpl)

/**
 * The Content Disposition of the url have to be 'attachment' otherwise chrome will open the link instead of generating a download
 * @param file_name
 * @param url
 */
export function download(file_name: string, url: string) {
  var element = document.createElement('a')
  element.setAttribute('href', url)
  element.setAttribute('download', file_name)

  element.style.display = 'none'
  document.body.appendChild(element)

  element.click()

  document.body.removeChild(element)
}
