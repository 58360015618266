import { TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Autocomplete } from '@material-ui/lab'
import React from 'react'
import { useField } from 'react-final-form'

const useStyles = makeStyles({
  formControl: {
    width: 'fill-available',
  },
  select: {
    backgroundColor: 'rgba(0,0,0,0)',
  },
})

/**
 * for using inside of Form only
 * @param props
 */
export const AutocompleteInput = React.memo(
  (props: {
    fieldName: string
    label: string
    inputProps: { options: { value: any; label: string }[] }
  }) => {
    const { fieldName, label, inputProps } = props
    const selectData = inputProps.options

    const field = useField<string>(fieldName)

    const classes = useStyles()
    // const [value, SetValue] = useState(initValue ? initValue : '')
    type StateType = typeof inputProps.options[0]
    const handleChange = (_event: any, value: StateType | null) => {
      // SetValue(event.target.value as string)
      field.input.onChange(value?.value)
    }

    return (
      <Autocomplete<StateType>
        className={classes.select}
        id="simple-select"
        value={
          selectData.find((f) => f.value === field.input.value) || {
            value: field.input.value,
            label: field.input.value,
          }
        }
        onChange={handleChange}
        options={selectData}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="filled"
              InputProps={{
                ...(params.InputProps || {}),
                className: classes.select,
              }}
              label={label}
            />
          )
        }}
        getOptionLabel={(option) => {
          return option.label
        }}
      />
    )
  }
)
