// https://gist.github.com/stefanmaric/895f51652060a820e2ee7f164af87948
/**
 * Create an new deferred promise that can be resolved/rejected from outside.
 * @return {Promise} A new Promise with two extra methods: resolve and reject.
 *
 * @example
 * const unknownResult = () => {
 *   const deferredPromise = defer()
 *
 *   const errorTimeoutId = setTimeout(
 *     () => {
 *       clearTimeout(successTimeoutId)
 *       deferredPromise.reject(new Error('Error!'))
 *     },
 *     Math.round(Math.random() * 1e4)
 *   )
 *
 *   const successTimeoutId = setTimeout(
 *     () => {
 *       clearTimeout(errorTimeoutId)
 *       deferredPromise.resolve('Success!')
 *     },
 *     Math.round(Math.random() * 1e4)
 *   )
 *
 *   return deferredPromise
 * }
 *
 * unknownResult()
 *   .then(console.log)
 *   .catch(console.error)
 */
export function deferredPromise<Value>(): Promise<Value> & {
  resolve(val: Value)
  reject(e: any)
} {
  const bag = {}

  // @ts-expect-error
  return Object.assign(
    new Promise((resolve, reject) => Object.assign(bag, { resolve, reject })),
    bag
  )
}
