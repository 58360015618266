import gql from 'graphql-tag'

export const CategoriesQuery = gql`
  query Categories {
    allCmsCategories(
      orderBy: [SORT_ORDER_ASC, CREATED_DATE_ASC, PARENT_ID_DESC]
    ) {
      nodes {
        id
        title
        slug
        platform
        sortOrder
        parentId
        isActive
        flags
      }
    }
  }
`

export const CategoryGenresQuery = gql`
  query GenresCategory {
    allCmsCategories(filter: { slug: { equalTo: "genres" } }) {
      nodes {
        id
        cmsCategoriesByParentId {
          nodes {
            id
            title
            slug
            parentId
          }
        }
      }
    }
  }
`
