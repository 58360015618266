import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  TextField,
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useEffect, useState } from 'react'

export interface DialogSelectProps<T extends string> {
  isOpen: boolean
  handleOpen: () => void
  handleOk: (selected: string[]) => void
  handleClose: () => void
  options: T[]
  labels: Record<string /*value*/, string /*label*/>
  currentSelection: T[]
}

export const DialogSelect = <T extends string>({
  isOpen,
  handleOk,
  handleClose,
  handleOpen,
  options,
  labels,
  currentSelection,
}: DialogSelectProps<T>) => {
  const [selection, setSelection] = useState<T[]>([])

  useEffect(() => {
    if (isOpen) {
      setSelection(currentSelection)
    }
  }, [isOpen]) // don't care when currentSelection changes, as serves as an initial state only when opening the dialog

  return (
    <div style={{ display: 'flex', justifyContent: 'end' }}>
      <Button
        onClick={handleOpen}
        variant="contained"
        color="primary"
        size="small"
        style={{ marginBottom: '1em' }}
      >
        Add/remove events
      </Button>
      <Dialog disableEscapeKeyDown open={isOpen} onClose={handleClose}>
        <DialogTitle>Select the desired events</DialogTitle>
        <DialogContent>
          <Box>
            <FormControl>
              <Autocomplete
                multiple
                freeSolo={false}
                value={selection}
                options={options}
                onChange={(_, newVal) => setSelection(newVal)}
                getOptionLabel={(option) => labels[option as string] ?? option}
                renderInput={(params) => (
                  <TextField {...params} variant="standard" label="Event" />
                )}
              />
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={() => handleOk(selection)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
