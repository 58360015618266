import gql from 'graphql-tag'

export const SeriesForSortableListFieldByIdQuery = gql`
  query cmsSeryById($id: Guid!) {
    qry: cmsSeryById(id: $id) {
      id
      title
      widescreenImage {
        masterUrl
      }
    }
  }
`
