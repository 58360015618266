import gql from 'graphql-tag'
import memoize from 'lodash/memoize'
import snakeCase from 'lodash/snakeCase'
import pluralize from 'pluralize'
import {
  FAKE_PRESET_FILTER_FIELD,
  FAKE_PRESET_FILTER_OPERATION,
  SEARCH_FIELD,
  SEARCH_OPERATION,
} from '../../genericData/CustomFilter'
import { applyFilterTemplate } from '../../genericData/filterBuilder/normalizeFilter'
import { MOVIE_PREFIX } from '../../lib/config'
import { FieldFilter, GraphQLFilter, buildSearchFilter } from '../buildFilter'
import { getSchemaTraverser } from '../buildQuery'
import { Resource } from './ReactAdmin-Types'
import { FieldFilterInfo, nameFromResource } from './SchemaTraverser'
import { getFragmentForType } from './getFragmentForType'

export type GetListParams = {
  readonly sort: void | null | {
    field: string
    order: string
  }
  readonly pagination: null | {
    perPage: number
    page: number
  }
  readonly filter?: null | {
    [internalName: string]: any
  }
}

export const getOrderByType = memoize(
  function (type) {
    return pluralize(type.name) + 'OrderBy'
  },
  ({ name }) => name
)

export function getFilterType(typeName: string) {
  return typeName + 'Filter'
}
export function getTypeFromFilterType(typeName: string) {
  return typeName.replace(/Filter$/, '')
}

export function functionGetListParamsToVariables(
  params: GetListParams,
  fieldNamesArray: Array<string> = [],
  resource: Resource
) {
  const { sort, pagination, filter: rawFilter } = params
  const { perPage = null, page = null } = pagination || {}
  const resourceTypeName = resource.type.name

  const traverser = getSchemaTraverser()!.getResourceTraverserByName(
    resourceTypeName
  )

  const filter: GraphQLFilter & {
    [SEARCH_FIELD]?: {
      [SEARCH_OPERATION]: string
    }
  } & {
    [FAKE_PRESET_FILTER_FIELD]?: {
      [FAKE_PRESET_FILTER_OPERATION]: string
    }
  } = {
    ...(rawFilter || null),
  }
  filter.and = rawFilter?.and
    ?.map((unparsedFilter: FieldFilter) => {
      return Object.keys(unparsedFilter).map((fieldName) => {
        const filterOperation = unparsedFilter[fieldName]
        let foundField: FieldFilterInfo | undefined
        for (const descriptor of traverser?.filterableManyToOneOrManyFieldDescriptors ??
          []) {
          if (!fieldName.includes(descriptor.alias)) {
            continue
          }
          // FIXME does not detect category.slug, because it does not have
          foundField = descriptor.filterableSubfields.find((field) => {
            return (field.fullAlias ?? field.alias ?? field.name) == fieldName
          })
          if (foundField) {
            if (foundField.template) {
              const applied = applyFilterTemplate(foundField.template, {
                [foundField.name]: filterOperation,
              } as GraphQLFilter)

              return applied as GraphQLFilter
            } else {
              const filter: FieldFilter = {
                [foundField.oneToManyParent!.parentField.name]: {
                  some: {
                    [foundField.field.name]: filterOperation,
                  } as FieldFilter,
                },
              }
              return filter
            }
          }
        }
        return unparsedFilter
      })
    })
    .flat(2)

  // FIXME the delete operation is not used when the page is refreshed...
  const searchTermOperation =
    filter[SEARCH_FIELD] ||
    filter['and']?.find((filterObj: FieldFilter) => !!filterObj[SEARCH_FIELD])

  if (searchTermOperation != undefined) {
    // TODO: switch to AND
    delete filter[SEARCH_FIELD]
    const searchTerm = searchTermOperation[SEARCH_OPERATION] as
      | string
      | undefined
    if (searchTerm) {
      filter.or = [
        ...(filter['or'] ? filter['or'] : []),
        ...(buildSearchFilter(searchTerm, resource) || []),
        // ...operation,
      ]
    }
  }
  delete filter[FAKE_PRESET_FILTER_FIELD]
  //   filter.and = filter.and ?? []
  //   filter.and.push({
  //     [fieldName]: operation,
  //   } as FieldFilter)
  // })
  // }

  if (
    //
    resourceTypeName === 'CmsMovie' &&
    MOVIE_PREFIX
  ) {
    // do not add the id filter on every page reload
    filter.and = [...(filter.and ?? [])]
    filter.and.push({
      id: { startsWith: MOVIE_PREFIX },
    })
  }

  return {
    paginationLimit: typeof perPage === 'number' ? perPage : null,
    paginationOffset:
      typeof page === 'number' && typeof perPage === 'number'
        ? (page - 1) * perPage
        : null,
    orderBy:
      sort && sort.field && sort.order
        ? [snakeCase(`${sort.field}_${sort.order}`).toUpperCase()]
        : null,
    filter,
  }
}

const getListQuery = memoize(
  function getListQuery(queryName: string, resource: Resource) {
    const OrderBy = getOrderByType(resource.type)
    const FilterType = getFilterType(resource.type.name)
    const {
      fragment: AllTypesFragment,
      fragmentName: AllTypesFragmentName,
    } = getFragmentForType(resource.type)
    const query = gql
    return query`
          ${AllTypesFragment}
  
          query ${queryName}($paginationLimit: Int, $paginationOffset: Int, $orderBy: [${OrderBy}!], $filter: ${FilterType}, $startCursor: Cursor) {
              data: ${queryName}(first: $paginationLimit, offset: $paginationOffset, orderBy: $orderBy, filter: $filter, after: $startCursor) {
                  totalCount
                  pageInfo{
                    hasNextPage
                    endCursor
                  }
                  nodes {
                      ...${AllTypesFragmentName}
                  }
              }
          }
      `
  },
  // @ts-ignore
  (query: string, resource: Resource, isExport) => {
    const cacheKey = '' + query + '----' + resource.type.name + '--' + isExport
    return cacheKey
  }
)

export function getListQueryName(resource: Resource) {
  return `all${pluralize(nameFromResource(resource))}`
}

export function graphQlQueryDescriptor(
  queryName: string,
  resource: Resource,
  params: GetListParams,
  isExport: boolean = false
) {
  console.log('getList params', params)
  const fieldNamesArray: Array<string> = []
  resource.type?.fields.forEach((field) => {
    fieldNamesArray.push(field.name)
  })

  return {
    query: getListQuery(queryName, resource),
    variables: functionGetListParamsToVariables(
      params,
      fieldNamesArray,
      resource
    ), // params = { id: ... }
    parseResponse: (response: Object) => {
      // @ts-ignore
      if (!response || !response.data || !response.data.data) {
        console.error('graphql could not find any data!', response)
        return null
      }
      const {
        // @ts-ignore
        data: {
          data: { nodes, totalCount },
        },
      } = response
      return {
        data: nodes,
        total: totalCount,
      }
    },
  }
}
