import { makeStyles } from '@material-ui/core/styles'
import { Layout, Sidebar, SidebarProps, useAuthenticated } from 'react-admin'
import { useSelector } from 'react-redux'
import AppBar from './AppBar'
import { CustomMenu } from './CustomMenu'
import { darkTheme, lightTheme, smplColors } from './themes'

const useStyles = makeStyles((theme) => ({
  sidebarContainer: {
    width: 240,
    // width: DRAWER_WIDTH,
    // not showing if on mobile
    [theme.breakpoints.only('xs')]: {
      width: '0px',
    },
  },
  sidebarContainerClosed: {
    width: 240,
    // width: CLOSED_DRAWER_WIDTH,
    // not showing if on mobile
    [theme.breakpoints.only('xs')]: {
      width: '0px',
    },
  },
  sidebar: {
    overflowY: 'auto',
    position: 'fixed',
    top: '48px',
    bottom: '0px',

    '& a[href="/"]': {
      background: '#00000008',
      width: 'calc(100% - 0.8rem)',
      margin: '0.06rem 0.5rem',
      padding: '1rem 0.5rem',
      textTransform: 'uppercase',
      fontSize: '0.8rem',
      borderRadius: '0.5rem',
      height: 64,
    },
    '& .MuiTouchRipple-child': {
      //! Color of ButtonClickRipple Effect
      backgroundColor: smplColors.secondary.main,
    },
  },
}))

const CustomSidebar = (props: SidebarProps) => {
  const classes = useStyles()
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  return (
    <div
      className={
        open ? classes.sidebarContainer : classes.sidebarContainerClosed
      }
    >
      <Sidebar className={classes.sidebar} {...props} />
    </div>
  )
}

const CustomLayout = ({ location, ...props }: any) => {
  const theme = useSelector((state: any) =>
    state.theme === 'dark' ? darkTheme : lightTheme
  )
  useAuthenticated()
  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={CustomMenu}
      theme={theme}
    />
  )
}
export const CustomLayoutLoading = ({ location, ...props }: any) => {
  const theme = useSelector((state: any) =>
    state.theme === 'dark' ? darkTheme : lightTheme
  )
  useAuthenticated()
  return (
    <Layout {...props} appBar={null} sidebar={null} menu={null} theme={theme} />
  )
}

export default CustomLayout
