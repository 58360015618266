import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import {
  addDays,
  endOfDay,
  format,
  startOfDay,
  startOfMonth,
  subMonths,
} from 'date-fns'
import gql from 'graphql-tag'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import React from 'react'
import { useApolloClient } from 'react-apollo-hooks'

let _ChartJs
async function loadChartJs() {
  if (!_ChartJs) {
    _ChartJs = await import('chart.js').then((mod) => mod.default)
  }
  return _ChartJs
}
class InternalTimeChart extends React.Component {
  static propTypes = {
    label: PropTypes.string,
    query: PropTypes.string.isRequired,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        color: PropTypes.string,
        areaColor: PropTypes.string,
      })
    ),
  }

  constructor() {
    super()
    this.state = {
      loading: true,
      error: false,
    }
  }

  setupGraph(ChartJs, seriesData, timeframes) {
    const { canvasRef } = this
    if (!canvasRef) {
      console.error('cannot setupGraph without canvasRef')
      return
    }
    var ctx = canvasRef.getContext('2d')

    const datasets = seriesData.map(({ label, data, color, areaColor }) => ({
      label,
      data,
      borderColor: color,
      backgroundColor: areaColor,
    }))

    var data = {
      type: 'line',
      data: {
        labels: timeframes
          .map(({ fromDate }) => fromDate)
          .map((d) => format(d, 'MMMM')),
        datasets,
      },
      options: {},
    }
    new ChartJs(ctx, data)
  }

  formatDateWithoutTimezone(date) {
    return format(date, 'YYYY-MM-DD')
  }

  getTimeFrames(numberOfMonths) {
    const currentMonth = startOfMonth(Date.now())
    const timeframes = [
      {
        fromDate: startOfDay(currentMonth),
        toDate: endOfDay(addDays(Date.now(), 1)),
      },
    ]
    let previousMonth = currentMonth
    for (let i = 1; i < numberOfMonths; i++) {
      const firstOfMonth = subMonths(previousMonth, 1)
      timeframes.push({
        fromDate: startOfDay(firstOfMonth),
        // queries done with a strict lessThan
        toDate: endOfDay(previousMonth),
      })
      previousMonth = firstOfMonth
    }
    return timeframes.reverse() // we want today to be at the right-most position
  }

  async querySeries() {
    this.setState({
      loading: true,
    })
    const { series, client, query } = this.props

    try {
      const timeframes = this.getTimeFrames(12)
      console.log('timeframes', timeframes)

      const compiledQuery = gql(query)
      const promises = timeframes.map((variables) =>
        client.query({
          query: compiledQuery,
          variables,
        })
      )
      const [ChartJs, ...results] = await Promise.all([
        loadChartJs(),
        ...promises,
      ])
      const data = results.map(({ data }) => data) // array of combined data for each month
      const chartLines = series.map((seriesInfo) => {
        return {
          ...seriesInfo,
          data: data.map((d) => get(d, seriesInfo.key)),
        }
      })

      this.setupGraph(ChartJs, chartLines, timeframes)
      this.setState({
        loading: false,
        error: false,
      })
    } catch (e) {
      console.error('could not query timeseries', e)
      this.setState({
        error: true,
        loading: false,
      })
    }
  }

  componentDidMount() {
    this.querySeries()
  }

  setCanvasRef = (el) => {
    this.canvasRef = el
  }

  render() {
    const { label } = this.props
    const { loading, error } = this.state
    const loadingSpinner = loading ? (
      <CircularProgress size="small" style={{ paddingTop: 5 }} />
    ) : null
    return (
      <Card style={{ flexGrow: 1 }}>
        <CardContent>
          {label ? (
            <div style={{ flexDirection: 'row', display: 'flex' }}>
              <Typography>{label}</Typography> {loadingSpinner}
            </div>
          ) : (
            loadingSpinner
          )}
          {error ? (
            <Typography
              style={{ color: 'red', fontSize: '1.5em', fontWeight: 'bold' }}
            >
              Could not load Data!
            </Typography>
          ) : null}
          <canvas
            ref={this.setCanvasRef}
            id="line-chart"
            width={500}
            height={error ? 0 : 200}
          />
        </CardContent>
      </Card>
    )
  }
}

export const TimeChart = React.memo(function TimeChartMemo(props) {
  const client = useApolloClient()
  return <InternalTimeChart {...props} client={client} />
})

TimeChart.propTypes = {
  ...InternalTimeChart.propTypes,
}
