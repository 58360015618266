import { Record } from 'ra-core'
import { CustomSaveProps, SMPL_TEMP_IMG_FILES } from '../GenericEditPage'
import { TempImg } from '../customInputs/ImageInput'
import { toBeRedirectError } from './customCategorySave'
import {
  cleanFields,
  extractAndRemoveCustomFields,
  mergeValues,
  uploadImageAndIdToForm,
} from './movieSave'

export const cmsWithCustomSave = ['CmsContract']

export const customCreateSave = async (props: CustomSaveProps) => {
  const { dataProvider, previousValues, resource, values } = props
  const {
    valuesWithoutCustomFields,
    customValues,
  } = extractAndRemoveCustomFields(values as Record)
  let cleanValues = cleanFields(valuesWithoutCustomFields)

  try {
    const uploadImageRes = await uploadImageAndIdToForm(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_IMG_FILES] as TempImg
    )

    if (uploadImageRes) {
      cleanValues = mergeValues(cleanValues, uploadImageRes)
    }

    const createdCmsObject = await dataProvider.create(resource, {
      data: {
        // @ts-ignore Ignore because in create there are no id in cleanValues
        id: '000000000000000', // will be replaced server side with an actual id
        ...cleanValues,
      },
    })

    return {
      data: createdCmsObject.data,
    }
  } catch (error) {
    throw error
  }
}

export const customEditSave = async (props: CustomSaveProps) => {
  const { dataProvider, previousValues, resource, values } = props
  const {
    valuesWithoutCustomFields,
    customValues,
  } = extractAndRemoveCustomFields(values as Record)
  let cleanValues = cleanFields(valuesWithoutCustomFields)

  try {
    const uploadImageRes = await uploadImageAndIdToForm(
      dataProvider,
      resource,
      customValues[SMPL_TEMP_IMG_FILES] as TempImg
    )

    if (uploadImageRes) {
      cleanValues = mergeValues(cleanValues, uploadImageRes)
    }

    const updatedCmsObject = await dataProvider.update(resource, {
      // @ts-ignore
      id: values.id,
      data: { ...cleanValues },
      previousData: previousValues!,
    })
    return {
      data: updatedCmsObject.data,
    }
  } catch (error: any) {
    if (
      error instanceof Error &&
      error.message &&
      error.message.includes(toBeRedirectError)
    ) {
      // simply return the clean unchanged valuese, there was probably only connection of category, person etc.
      // this will lead to redirect to show page
      return { data: cleanValues }
    } else {
      throw error
    }
  }
}
