import { getSchemaTraverser } from '../dataProvider/buildQuery'
import { Resource } from './introspections/ReactAdmin-Types'

import {
  BigFloatFilter,
  BooleanFilter,
  DatetimeFilter,
  GuidFilter,
  IntFilter,
  JSONFilter,
  StringFilter,
  StringListFilter,
} from '../__generated__/globalTypes'
import LocalStorage, {
  SEARCH_CONFIGURATION_KEY,
} from '../datagrid/LocalStorage'
import { filterNotEmpty } from '../lib/filterNonEmpty'
import { fromCaseSafeId, looksLikeSmplId } from '../lib/idutils'
import { getFilterType } from './introspections/getList'

export type FieldFilter = {
  [fieldName in string]?:
    | { some: FieldFilter }
    | { every: FieldFilter }
    | { none: FieldFilter }
    | GuidFilter
    | DatetimeFilter
    | StringFilter
    | StringListFilter
    | BooleanFilter
    | IntFilter
    | BigFloatFilter
    | JSONFilter
    | FilterCompositions // relationship filter
}

export type FilterCompositions = {
  and?: FieldFilter[]
  or?: FieldFilter[]
}

export type GraphQLFilter = FieldFilter & FilterCompositions

export const equalToSearchFilter = ['code']

export function buildSearchFilter(searchTerm: string, resource: Resource) {
  if (looksLikeSmplId(searchTerm.trim())) {
    console.log(`searchTerm looks like smplId: "${searchTerm}"`)
    // the user searched for what looks to be an id,
    // thus we only search for records matching that id
    let idToSearch = searchTerm
    if (idToSearch.length === 18) {
      idToSearch = fromCaseSafeId(idToSearch)
    }
    return [
      {
        id: {
          // @ts-ignore
          equalTo: idToSearch,
        },
      },
    ]
  }

  const traverser = getSchemaTraverser()
  if (!traverser) throw new Error('SchemaTraverser not yet ready')

  const inputObject =
    traverser._filterTypeByName[getFilterType(resource.type.name)]

  const searchSelectionConfig = LocalStorage.get(
    SEARCH_CONFIGURATION_KEY,
    resource.type.name
  )

  if (inputObject && 'inputFields' in inputObject) {
    const searchFilters = inputObject.inputFields
      .map((input) => {
        const filterType = input.type
        if (
          'name' in filterType &&
          filterType.name === 'StringFilter' &&
          !equalToSearchFilter.includes(input.name)
        ) {
          return input.name
        }
        return null
      })
      .filter(filterNotEmpty)
      .filter((f) => {
        if (searchSelectionConfig) {
          return searchSelectionConfig[f]
        } else {
          return true
        }
      })
      .map((f) => {
        return { [f!]: { likeInsensitive: '%' + searchTerm + '%' } }
      })

    return searchFilters
  }
}
