import get from 'lodash/get'

export const LIST_STORAGE_KEY = 'raListConfig'
export const DATAGRID_STORAGE_KEY = 'raColumnsConfig'
export const DATAGRID_STORAGE_DEFAULT_VERSION_KEY =
  'raColumnsConfigDefaultVersion'
export const SEARCH_CONFIGURATION_KEY = 'raSearchConfig'
export const EDIT_STORAGE_KEY = 'raEditGroupsConfig'
export const SHOW_STORAGE_KEY = 'raShowGroupsConfig'
export const INSIGHTS_STORAGE_KEY = 'graphanaInsights'
export const TEMPLATE_STORAGE_KEY = 'raEditTemplateConfig'
export const USE_TEMPLATE_STORAGE_KEY = 'raEditUseTemplateConfig'
export const ROLE_PERMISSION_STORAGE_KEY = 'raTablePermission'

// Very basic storage helper
// values are stored in browser localStorage

const getRootValue = (storageKey) => {
  try {
    return JSON.parse(window.localStorage.getItem(storageKey))
  } catch (e) {
    return undefined
  }
}

const setRootValue = (storageKey, value) => {
  try {
    window.localStorage.setItem(storageKey, JSON.stringify(value))
  } catch (e) {}
}

const LocalStorage = {
  get(storageKey, key) {
    return get(getRootValue(storageKey), key)
  },
  set(storageKey, key, value) {
    const oldValue = getRootValue(storageKey)
    setRootValue(storageKey, {
      ...oldValue,
      [key]: value,
    })
  },
  getVersion(storageKey) {
    return window.localStorage.getItem(storageKey)
  },
  setVersion(storageKey, value) {
    setRootValue(storageKey, value)
  },
}

export default LocalStorage
