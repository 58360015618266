import { GraphQLFormattedError, IntrospectionType } from 'graphql'
import gql from 'graphql-tag'
import { Record } from 'react-admin'
import { Resource } from './ReactAdmin-Types'

export type DeleteManyParams = {
  [key: string]: any
  ids: [string]
}

export type DeleteOneParams = DeleteManyParams & {
  previousData?: Record // missing for DELETE_MANY
}

function transformDeleteManyParamsToVariables(params: DeleteManyParams) {
  console.log('deleteMany params', params)
  const { ids } = params
  const queryParams: { [variableName: string]: string } = {}
  return ids.reduce((obj, id, index) => {
    obj[`id_${index}`] = id
    obj[`clientMutationId_${index}`] = id
    return obj
  }, queryParams)
}

function deleteManyQuery(queryName: string, resource: Resource, ids: string[]) {
  const variables = ids.map(
    (id, index) => `$id_${index}: Guid!, $clientMutationId_${index}: String!, `
  )
  const queries = ids.map(
    (id, index) => `
      data_${index}: ${queryName}(input: { id: $id_${index}, clientMutationId: $clientMutationId_${index} }) {
        clientMutationId
      }
    `
  )
  const query = gql
  return query`
          mutation ${queryName}(
            ${variables.join(', ')}
          ) {
              ${queries.join('\n')}
          }
      `
}

export function deleteManyQueryName(resource: IntrospectionType) {
  /**
   *  TODO: possible to check via schema?
   * We currently cannot use the schemaTraverser here because buildQuery (where the traverser is created) is called *after* this function...
   * */
  if (resource.name === 'User') {
    return `remove${resource.name}ById`
  }
  return `delete${resource.name}ById`
}

export function graphQlQueryDescriptor(
  queryName: string,
  resource: Resource,
  params: DeleteManyParams,
  isDeleteOne?: boolean
) {
  return {
    query: deleteManyQuery(queryName, resource, params.ids),
    variables: transformDeleteManyParamsToVariables(params), // params = { id: ... }
    parseResponse: (response: {
      errors?: GraphQLFormattedError[]
      data: { [data: string]: null | { clientMutationId: string } }
    }) => {
      if (!response || !response.data) {
        console.error('graphql could not find any data!', response)
        throw new Error(`Deletion failed!`)
      }
      if (response.errors) {
        console.error('got errors from DELETE_MANY request', response)
        throw new Error('Error while deleting data')
      }
      const ids: string[] = []

      Object.keys(response.data).forEach((key) => {
        const data = response.data[key]

        if (data && data.clientMutationId) {
          ids.push(data.clientMutationId)
        } else {
          console.error(
            'No DELETE_MANY result data for id with',
            key,
            params.ids
          )
        }
      })

      if (isDeleteOne && ids && ids.length) {
        return {
          data: {
            id: ids[0],
          },
        }
      }

      return {
        data: ids,
      }
    },
  }
}
