import FormControl from '@material-ui/core/FormControl'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import LocalStorage, { LIST_STORAGE_KEY } from '../../datagrid/LocalStorage'
import { ListResourceSetting } from '../settings/ListResourceSetting'

type T = any

const useStyles = makeStyles(() => ({
  select: {
    width: '100px',
  },
}))

export type ListCompSelectProps = {
  resource: string
  forceUpdate?: () => void
}

export const ListCompSelect = (props: ListCompSelectProps) => {
  const { resource, forceUpdate } = props
  const classes = useStyles()
  const storage = LocalStorage

  // TODO: move state to GenericDataList
  const [selectedListComp, setSelectedListComp] = useState<string>()

  if (!ListResourceSetting[resource]) return null

  const listOptions: {
    name: string
    displayName: string
  }[] = Object.keys(ListResourceSetting[resource]).map((k: string) => {
    const object: {
      displayName: string
    } = ListResourceSetting[resource][k]
    return {
      name: k,
      displayName: object.displayName,
    }
  })

  useEffect(() => {
    if (!selectedListComp) {
      const initState = storage.get(LIST_STORAGE_KEY, resource)
        ? JSON.stringify(storage.get(LIST_STORAGE_KEY, resource))
        : JSON.stringify(listOptions[0])
      setSelectedListComp(initState)
    }
  })

  const handleChange = (event: any) => {
    storage.set(LIST_STORAGE_KEY, resource, JSON.parse(event.target.value))
    setSelectedListComp(event.target.value)
    forceUpdate ? forceUpdate() : null
  }

  return selectedListComp ? (
    <FormControl>
      <Select
        className={classes.select}
        value={selectedListComp}
        onChange={handleChange}
      >
        {listOptions.map((op) => {
          return (
            <MenuItem key={op.displayName} value={JSON.stringify(op)}>
              {op.displayName}
            </MenuItem>
          )
        })}
      </Select>
      {/* <FormHelperText margin="dense">Display Type</FormHelperText> */}
    </FormControl>
  ) : null
}
