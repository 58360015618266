import gql from 'graphql-tag'

export const IsActiveInternalByIdQuery = gql`
  query IsActiveInternalById($id: Guid!) {
    cmsMovieById(id: $id) {
      HomeOfHorrorDE: isActiveInternal(channel: "homeofhorror", country: "DE")
      FilmtasticDE: isActiveInternal(channel: "filmtastic", country: "DE")
      ArthouseCNMADE: isActiveInternal(channel: "arthousecnma", country: "DE")
    }
    cmsSeryById(id: $id) {
      HomeOfHorrorDE: isActiveInternal(channel: "homeofhorror", country: "DE")
      FilmtasticDE: isActiveInternal(channel: "filmtastic", country: "DE")
      ArthouseCNMADE: isActiveInternal(channel: "arthousecnma", country: "DE")
    }
    cmsSeasonById(id: $id) {
      HomeOfHorrorDE: isActiveInternal(channel: "homeofhorror", country: "DE")
      FilmtasticDE: isActiveInternal(channel: "filmtastic", country: "DE")
      ArthouseCNMADE: isActiveInternal(channel: "arthousecnma", country: "DE")
    }
    cmsEpisodeById(id: $id) {
      HomeOfHorrorDE: isActiveInternal(channel: "homeofhorror", country: "DE")
      FilmtasticDE: isActiveInternal(channel: "filmtastic", country: "DE")
      ArthouseCNMADE: isActiveInternal(channel: "arthousecnma", country: "DE")
    }
    cmsLicenseById(id: $id) {
      HomeOfHorrorDE: isActiveInternal(channel: "homeofhorror", country: "DE")
      FilmtasticDE: isActiveInternal(channel: "filmtastic", country: "DE")
      ArthouseCNMADE: isActiveInternal(channel: "arthousecnma", country: "DE")
    }
  }
`
