export const CmsImportRelation: {
  [name: string]: {
    [name: string]: {
      fields: { [name: string]: string }
    }
  }
} = {
  // on import inside:
  CmsLicense: {
    // on field:
    contractId: {
      fields: {
        activeCountries: 'activeCountries',
        licenseStart: 'contractStart',
        licenseEnd: 'contractEnd',
        properties: 'properties',
        channels: 'channels',
        portals: 'portals',
        foreignPortals: 'foreignPortals',
        ov: 'ov',
        omu: 'omu',
        uncut: 'uncut',
        exclusive: 'exclusive',
        offlineAvailableDays: 'offlineAvailableDays',
        languages: 'languages',
        subtitleLanguages: 'subtitleLanguages',
        customData: 'customData',
      },
    },
  },
  PaymentScheduledCancellation: {
    subscriptionId: {
      fields: {
        userId: 'userId',
      },
    },
  },
}
