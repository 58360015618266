import { IntrospectionSchema } from 'graphql'
import upperFirst from 'lodash/upperFirst'
import { deferredPromise } from '../lib/deferredPromise'
import { FetchType, Resource } from './introspections/ReactAdmin-Types'
import { SchemaTraverser } from './introspections/SchemaTraverser'
import {
  CreateOneParams,
  graphQlQueryDescriptor as createOne,
} from './introspections/createOne'
import {
  DeleteManyParams,
  DeleteOneParams,
  graphQlQueryDescriptor as deleteMany,
} from './introspections/deleteMany'
import {
  GetListParams,
  graphQlQueryDescriptor as getList,
} from './introspections/getList'
import {
  GetManyParams,
  graphQlQueryDescriptor as getMany,
} from './introspections/getMany'
import {
  GetManyReferenceParams,
  graphQlQueryDescriptor as getManyReference,
} from './introspections/getManyReference'
import {
  GetOneParams,
  graphQlQueryDescriptor as getOne,
} from './introspections/getOne'
import {
  UpdateOneParams,
  graphQlQueryDescriptor as updateOne,
} from './introspections/updateOne'

let schemaTraverser: SchemaTraverser | null = null
export function getSchemaTraverser(): SchemaTraverser | null {
  return schemaTraverser
}

export let schemaTraverserPromise = deferredPromise<SchemaTraverser>()

type DataResolver = ReturnType<typeof buildQuery>

let dataResolver: DataResolver | null = null
export function getDataResolver(): DataResolver {
  if (!dataResolver) {
    throw new Error('Data resolver not initiated')
  }
  return dataResolver
}

type BuildQueryParams = {
  // queries: IntrospectionType[],
  // types: IntrospectionType[],
  resources: Resource[]
  schema: IntrospectionSchema
}

export function getResource(resourceName: string, resources: Resource[]) {
  const resourceTypeName: string = upperFirst(resourceName)
  const resource = resources.find((r) => r.type.name === resourceTypeName)
  if (!resource) {
    console.error('cannot find resource', resourceTypeName)
    throw new Error('cannot find resource for ' + resourceTypeName)
  }
  return resource
}

export function buildQuery({
  // queries,
  // types,
  resources,
  schema,
}: BuildQueryParams) {
  // this is still a bit hacky from the prototype. Maybe use Apollo Cache?
  const traverser = new SchemaTraverser(schema, resources)
  schemaTraverser = traverser
  schemaTraverserPromise.resolve(traverser)
  // @ts-expect-error - since buildQuery can be called multiple times on login (see loginSaga)
  // and Promises are immutable, we just make sure to create a new one
  schemaTraverserPromise = Promise.resolve(traverser)

  const resolver = function DataResolver(
    raFetchType: FetchType,
    resourceName: string,
    params:
      | GetListParams
      | GetManyParams
      | GetOneParams
      | DeleteOneParams
      | DeleteManyParams
      | CreateOneParams
  ) {
    // this only contains types that have a GET_LIST and a GET_ONE operation
    const resource = getResource(resourceName, resources)
    const resourceGetter = resource[raFetchType]
    if (!resourceGetter) {
      console.error('trying to use unknown fetch operation', raFetchType)
      return
    }
    const queryName = resourceGetter.name

    switch (raFetchType) {
      case 'GET_MANY_REFERENCE': {
        return getManyReference(
          queryName,
          resource,
          params as GetManyReferenceParams,
          traverser
        )
      }
      case 'GET_LIST': {
        return getList(queryName, resource, params as GetListParams)
      }
      case 'GET_MANY': {
        return getMany(queryName, resource, params as GetManyParams)
      }
      case 'GET_ONE':
        return getOne(queryName, resource, params as GetOneParams)
      // ... other types handled here
      case 'DELETE': {
        const deleteManyParams: DeleteManyParams = {
          ids: [(params as DeleteOneParams).id],
        }

        return deleteMany(queryName, resource, deleteManyParams, true)
      }
      case 'DELETE_MANY': {
        return deleteMany(queryName, resource, params as DeleteManyParams)
      }
      case 'UPDATE': {
        return updateOne(queryName, resource, params as UpdateOneParams)
      }
      case 'CREATE': {
        return createOne(queryName, resource, params as CreateOneParams)
      }
      // ... other types handled here
      default:
        throw new Error('Unsupported fetch type: ' + raFetchType)
    }
  }
  dataResolver = resolver
  return resolver
}
