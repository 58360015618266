import { NoData } from './NoData'

export const MediaInfoFields = (props: any) => {
  const { source, record, keyPath } = props

  let jsonObject = null
  if (record[source]) {
    jsonObject = JSON.parse(record[source])
    return jsonObject.media.track[0][keyPath]
  } else {
    return <NoData />
  }
}
