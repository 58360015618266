import gql from 'graphql-tag'

export const TestQuery = gql`
  query Test {
    currentUser {
      id
    }
  }
`

export const UsersMutation = gql`
  mutation UpdateUser($id: Guid!, $patch: UserPatch!) {
    updateUserById(input: { id: $id, userPatch: $patch }) {
      user {
        id
      }
    }
  }
`

export const UsersQuery = gql`
  query Users(
    $role: String
    $paymentCountryIsNull: Boolean
    $activeSubscription: Boolean
  ) {
    allUsers(
      orderBy: [CREATED_DATE_ASC]
      filter: {
        and: [
          {
            role: { equalTo: $role }
            paymentCountry: { isNull: $paymentCountryIsNull }
          }
        ]
      }
    ) {
      nodes {
        id
        createdDate
        name
        username
        role
        paymentCountry
        subscriptionsByUserId(
          first: 1
          orderBy: [CREATED_DATE_DESC]
          filter: { isActive: { equalTo: $activeSubscription } }
        ) {
          nodes {
            isActive
            subscriptionProduct {
              merchantConfiguration {
                name
              }
            }
          }
        }
        paymentTransactionsByUserId(
          first: 1
          orderBy: [CREATED_DATE_DESC]
          filter: {
            status: { equalTo: SUCCESSFUL }
            paymentProviderId: { isNull: false }
          }
        ) {
          nodes {
            paymentProviderId
            paymentTransactionLogsByPaymentTransactionId(
              first: 1
              orderBy: [CREATED_DATE_DESC]
              filter: {
                or: [
                  { transactionStep: { equalTo: "authorization" } }
                  { transactionStep: { equalTo: "debit" } }
                  { transactionStep: { equalTo: "pending-debit" } }
                ]
              }
            ) {
              nodes {
                transactionStep
                log
                wirecardTransactionId
              }
            }
          }
        }
      }
    }
  }
`
