// use Details, Related or System as Tabname to add additional fields into those default tabs

const transcodingTabs = {
  Input: {
    fields: ['vendorId', 'status', 'custom:assets', 'custom:checks'],
    useCustomTab: true,
  },
  Output: {
    fields: [
      'progress',
      'awsStatus',
      'awsLastNotification',
      'awsResponseCode',
      'awsResponseDescription',
    ],
    useCustomTab: true,
  },
  Review: { fields: ['custom:videoPlayer'] },
  Debug: {
    fields: [
      'id',
      'awsLastNotification',
      'metadata',
      'mediaInfo',
      'jobTemplate',
    ],
    useCustomTab: true,
  },
  Comment: { fields: ['custom:comment'] },
}

type FieldName = string

type Tabs = {
  [tabName in string]: { fields: FieldName[]; useCustomTab?: boolean }
}

export const TabLayoutSettings: {
  [dataTypeName in string]: { tabs: Tabs; layout?: string }
} = {
  TranscodingJob: {
    tabs: transcodingTabs,
  },
  CmsCategory: {
    tabs: {
      SmplSort: { fields: ['custom:sortableMovieList'] },
      Advanced: { fields: ['custom:catAllocation'] },
      Subcategories: { fields: ['custom:sortableCategoryList'] },
    },
  },
  CmsMovie: {
    tabs: {
      Advanced: {
        fields: [
          'custom:categories',
          'custom:videoSource',
          //   'custom:updateRating',
        ],
        useCustomTab: true,
      },
      Review: { fields: ['custom:videoPlayer'] },
    },
  },
  CmsEpisode: {
    tabs: {
      Advanced: { fields: ['custom:videoSource'] },
      Review: { fields: ['custom:videoPlayer'] },
    },
  },
  CmsClip: {
    tabs: {
      Advanced: { fields: ['custom:videoSource'] },
      Review: { fields: ['custom:videoPlayer'] },
    },
  },
  CmsTrailer: {
    tabs: {
      Advanced: { fields: ['custom:videoSource'] },
    },
  },
  CmsSery: {
    tabs: {
      Advanced: {
        fields: [
          'custom:categories',

          //   'custom:updateRating'
        ],
      },
    },
  },
  VoucherCampaign: {
    tabs: {
      Advanced: {
        fields: ['custom:generateVouchers', 'custom:prodAllocation'],
      },
    },
    layout: 'block',
  },
}
