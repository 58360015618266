import Card from '@material-ui/core/Card'
import Chip from '@material-ui/core/Chip'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import AddIcon from '@material-ui/icons/Add'
import CheckIcon from '@material-ui/icons/Check'
import CloseIcon from '@material-ui/icons/Close'
import DeleteIcon from '@material-ui/icons/Delete'
import EditIcon from '@material-ui/icons/Edit'
import RefreshIcon from '@material-ui/icons/Refresh'
import SearchIcon from '@material-ui/icons/Search'
import { useRefresh } from 'ra-core'
import { useCallback, useEffect, useState } from 'react'
import { Record, useDataProvider, useNotify, useQuery } from 'react-admin'
import { useField, useForm, useFormState } from 'react-final-form'
import { isFilmhomeCustomer, isNetzkinoCustomer } from '../../lib/config'
import { useConfirmation } from '../ConfirmationService'
import { CreateModal } from '../CreateModal'
import { SMPL_TEMP_LICENSE } from '../GenericEditPage'
import { DataGridModal } from '../renderInput'

const useStyles = makeStyles(() => ({
  box: {
    border: 'thin solid #80808029',
    padding: 5,
    borderRadius: 5,
  },
  chip: {
    margin: '5px 10px 10px 15px',
  },
  deleteChip: {
    backgroundColor: 'red',
    marginRight: 10,
    marginBottom: 10,
    marginLeft: 10,
  },
  divider: {
    marginBottom: 10,
  },
  title: {
    fontSize: 14,
  },
  existingLicenseCard: {
    marginBottom: 10,
    paddingRight: 5,
    paddingLeft: 5,
  },
  licenseCardInfo: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    marginBottom: 10,
    '& > div': {
      margin: 10,
    },
  },
  licenseCardAction: {
    display: 'flex',
    flexDirection: 'row-reverse',
  },
}))

export type CmsLicense = {
  id: string
  channel: string
  externalIdentifier: string
  licenseStart: string
  licenseEnd: string
  activeCountries: string[]
  isActive: boolean
}

// For using inside of <Form>
export const LicenseInputWithExistingRecord = (props: any) => {
  const {
    record,
    resource,
  }: {
    record: Record
    resource: string
  } = props

  let target

  switch (resource) {
    case 'CmsMovie':
      target = 'cmsLicensesByContentMovieId'
      break
    case 'CmsEpisode':
      target = 'cmsLicensesByContentEpisodeId'
      break
    default:
      break
  }

  const { loading, error, data } = useQuery({
    type: 'getManyReference',
    resource: resource,
    payload: {
      id: record.id,
      target: target,
    },
  })

  if (data) {
    return (
      <LicenseInput
        {...props}
        existingLicenseData={data || []}
        target={target}
      />
    )
  }
  if (error) {
    console.log(error)
  }
  return null
}

// For using inside of <Form>
export const LicenseInput = (props: any) => {
  const {
    record,
    resource,
    existingLicenseData,
    callFromCreate,
    target,
  }: {
    record: Record
    resource: string
    existingLicenseData: CmsLicense[] | undefined
    callFromCreate: boolean
    target: string
  } = props

  const referenceTypeName = 'CmsLicense'

  // Hooks
  const classes = useStyles()
  const refresh = useRefresh()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const confirm = useConfirmation()
  // for capturing entry that was created in modal
  const [createdLicense, setCreatedLicense] = useState<CmsLicense | null>(null)
  // for capturing entires selected in select modal
  const [selectedEntries, setSelectedEntries] = useState<Record[] | null>(null)
  // for rendering:
  const [licenseArray, setLicenseArray] = useState<CmsLicense[] | null>(
    existingLicenseData || null
  )
  const [shouldRefresh, setShouldRefresh] = useState<Boolean>(false)
  const [showLicenseRefresh, setShowLicenseRefresh] = useState<Boolean>(true)

  //// Setup Temp License In Form
  // INFO: only use if we are in Create !!!
  useField(
    SMPL_TEMP_LICENSE
    // don't do this or 'Maximum update depth exceeded' will happen
    //   {
    //   initialValue: [],
    // }
  )
  const form = useForm()
  const currentFieldState = useFormState()

  useEffect(() => {
    if (createdLicense) {
      setLicenseArray([...(licenseArray || []), createdLicense])

      if (callFromCreate) {
        // save to form to replace contentMovieId or contentEpisodeId after creating resource
        form.change(SMPL_TEMP_LICENSE, [
          ...(currentFieldState.values[SMPL_TEMP_LICENSE] || []),
          { ...createdLicense },
        ])
      } else {
        // we do this to trigger the save button if this is the only change in this edit session
        // value will not be used if in edit mode
        form.change(SMPL_TEMP_LICENSE, [])
      }

      setCreatedLicense(null)
    }
  }, [createdLicense])

  useEffect(() => {
    async function makeConnectionToSelectedLicense() {
      const validSelected = selectedEntries!.filter(
        (se) => !se.contentMovieId && !se.contentEpisodeId
      )
      if (selectedEntries!.length !== validSelected.length) {
        notify(
          `Some of the selected licenses have already been assigned to films or episodes.`,
          'warning'
        )
      }
      const res = await Promise.allSettled(
        validSelected.map(async (se) => {
          return await dataProvider.update(referenceTypeName, {
            id: se.id,
            previousData: se,
            data: {
              ...(target === 'cmsLicensesByContentMovieId'
                ? { contentMovieId: record.id }
                : {}),
              ...(target === 'cmsLicensesByContentEpisodeId'
                ? { contentEpisodeId: record.id }
                : {}),
            },
          })
        })
      )
    }
    if (selectedEntries) {
      if (callFromCreate) {
        const validSelected = selectedEntries.filter(
          (se) => !se.contentMovieId && !se.contentEpisodeId
        )

        // this Movie/Episode does not exist yet
        // save to form to replace contentMovieId or contentEpisodeId after creating resource
        form.change(SMPL_TEMP_LICENSE, [
          ...(currentFieldState.values[SMPL_TEMP_LICENSE] || []),
          ...validSelected,
        ])
        setLicenseArray([
          ...(licenseArray || []),
          ...(validSelected as CmsLicense[]),
        ])
        if (selectedEntries.length !== validSelected.length) {
          notify(
            `Some of the selected licenses have already been assigned to films or episodes.`,
            'warning'
          )
        }
        setSelectedEntries(null)
      } else {
        makeConnectionToSelectedLicense()
        setShouldRefresh(true)
        // we do this to trigger the save button if this is the only change in this edit session
        // value will not be used if in edit mode
        form.change(SMPL_TEMP_LICENSE, [])
        setSelectedEntries(null)
      }
    }
  }, [selectedEntries])

  useEffect(() => {
    async function getConnectedLicense() {
      const res = await dataProvider.getManyReference(resource, {
        id: record.id,
        target: target,
        filter: {},
        pagination: {
          page: 1,
          perPage: 1000, // there will not really be 1000 connection, this will just give back everything
        },
        sort: { field: 'createdDate', order: 'ASC' },
      })

      // @ts-ignore
      setLicenseArray(res.data)
    }

    if (shouldRefresh) {
      getConnectedLicense()
      setShouldRefresh(false)
    }
  }, [shouldRefresh])

  //// For Open/Close Create Modal
  const [createOpen, setCreateOpen] = useState(false)
  const openCreateModal = useCallback(() => {
    setCreateOpen(true)
  }, [])
  const closeCreateModal = useCallback(() => {
    setCreateOpen(false)
  }, [])
  //// For Open/Close Create Modal

  //// For Open/Close Selection Modal
  const [selectionOpen, setSelectionOpen] = useState(false)
  const openSelectionModal = useCallback(() => {
    setSelectionOpen(true)
  }, [])
  const closeSelectionModal = useCallback(() => {
    setSelectionOpen(false)
  }, [])
  //// For Open/Close Selection Modal

  //// For delete on exitsing
  const onConfirm = async (selectedId: string) => {
    const resolve = await deleteLicense(selectedId)

    if (resolve) {
      notify('License successfully removed!', 'info')
    } else {
      notify(
        'Removing license failed. If this problem persists, please contact the support.',
        'warning'
      )
    }
    refresh()
  }
  const onDeny = () => {
    // Do nothing
  }
  const queryDeleteConfirmation = (licenseId: string | number) => {
    confirm({
      catchOnCancel: true,
      title: 'Delete this license?',
      description:
        'Are you sure you want to delete this license? Deleting will also remove all changes so far. You may want to save before proceeding with the deletion.',
      // @ts-ignore
      id: licenseId,
      onConfirm: onConfirm,
      onDeny: onDeny,
    })
  }
  const deleteLicense = async (licenseId: string) => {
    const deleteMe = licenseArray?.find((license) => {
      if (license.id === licenseId) {
        return license
      }
    })

    if (!deleteMe) {
      return false
    }

    try {
      await dataProvider.delete('CmsLicense', {
        id: deleteMe.id,
        previousData: { ...deleteMe },
      })
      return true
    } catch (error) {
      console.error(error)
      return false
    }
  }

  return (
    <div className={classes.box}>
      {/* Create License Button */}
      <Chip
        label={`Create New License`}
        variant="outlined"
        icon={<AddIcon />}
        onClick={() => openCreateModal()}
        className={classes.chip}
      />

      <Chip
        label="Select License"
        variant="outlined"
        icon={<SearchIcon />}
        onClick={() => openSelectionModal()}
        className={classes.chip}
      />

      {showLicenseRefresh ? (
        <Chip
          label={`Refresh License`}
          variant="outlined"
          color="primary"
          icon={<RefreshIcon />}
          onClick={() => {
            setShouldRefresh(true)
            setShowLicenseRefresh(false)
          }}
          className={classes.chip}
        />
      ) : null}

      <Divider className={classes.divider} />

      {licenseArray && licenseArray.length > 0
        ? licenseArray.map((license) => {
            const {
              id,
              channel,
              licenseEnd,
              licenseStart,
              externalIdentifier,
              activeCountries,
              isActive,
            } = license
            return (
              <div key={'exist' + id}>
                <Card className={classes.existingLicenseCard}>
                  <div className={classes.licenseCardInfo}>
                    <div>
                      <Typography variant="body2">{'Channel:'}</Typography>
                      <Typography variant="body2">{channel}</Typography>
                    </div>
                    <div>
                      <Typography variant="body2">
                        {'External Identifier:'}
                      </Typography>
                      <Typography variant="body2">
                        {externalIdentifier}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body2">
                        {'Active Countries:'}
                      </Typography>
                      <Typography variant="body2">
                        {activeCountries ? activeCountries.join(', ') : ''}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body2">
                        {'License Start:'}
                      </Typography>
                      <Typography variant="body2">
                        {new Date(licenseStart).toLocaleDateString()}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body2">{'License End:'}</Typography>
                      <Typography variant="body2">
                        {new Date(licenseEnd).toLocaleDateString()}
                      </Typography>
                    </div>
                    <div>
                      <Typography variant="body2">{'Is Active:'}</Typography>
                      <Typography variant="body2">
                        {isActive ? <CheckIcon /> : <CloseIcon />}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.licenseCardAction}>
                    <Chip
                      label={`Delete License`}
                      icon={<DeleteIcon />}
                      color="primary"
                      onClick={() => {
                        queryDeleteConfirmation(id)
                      }}
                      className={classes.deleteChip}
                    />
                    <Chip
                      label={`Edit License`}
                      icon={<EditIcon />}
                      color="primary"
                      onClick={() => {
                        // open new tab
                        const hostname = window.location.origin
                        const pathname =
                          '/' + referenceTypeName + '/' + id + '/edit'
                        const url = hostname + pathname
                        window.open(url, '_blank')
                        setShowLicenseRefresh(true)
                      }}
                    />
                  </div>
                </Card>
              </div>
            )
          })
        : null}

      <CreateModal
        open={createOpen}
        onClose={closeCreateModal}
        onCloseDataGridModal={closeCreateModal}
        resource={referenceTypeName}
        hasEdit
        hasList
        hasShow
        record={{
          contentMovieId: resource === 'CmsMovie' ? record.id : undefined,
          contentEpisodeId: resource === 'CmsEpisode' ? record.id : undefined,
          contentExternalIdentifier: currentFieldState.values[
            'externalIdentifier'
          ]
            ? currentFieldState.values['externalIdentifier']
            : undefined,
          channel: isNetzkinoCustomer
            ? 'netzkino'
            : isFilmhomeCustomer
            ? 'filmhome'
            : undefined,
          activeCountries: isFilmhomeCustomer
            ? [
                'HU',
                'DE', // INFO: this one is only temporary until DB is fixed
              ]
            : ['DE'],
        }}
        setFunctionEntry={useCallback(setCreatedLicense, [])}
        callFromCreate={callFromCreate}
        createInfoText={`The field ${
          resource === 'CmsMovie' ? 'contentMovieId' : 'contentEpisodeId'
        } will be filled automatically after this ${resource} has been saved.`}
      />

      {/* Modal for adding a Category*/}
      <DataGridModal
        open={selectionOpen}
        onClose={closeSelectionModal}
        resource={referenceTypeName}
        basePath={`/${referenceTypeName}`}
        location={{
          hash: '',
          key: 'asdf',
          pathname: `/${referenceTypeName}`,
          search: '',
          state: null,
        }}
        calledFromModal={true}
        setFunctionEntries={setSelectedEntries}
        openedModal={setSelectionOpen}
      />
    </div>
  )
}
