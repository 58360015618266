import { Card, CircularProgress } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { headersForApi } from '../../commons/flexgold/constants'
import { authedFetch } from '../../dataProvider/authedFetch'
import { smplColors } from '../../layout/themes'
import { SMPL_FLEXGOLD_API_BASEURL } from '../../lib/config'

const useStyles = makeStyles({
  wrap: {
    padding: '2rem 2rem 2rem 2rem',
    borderRadius: 25,
  },
  metals: {
    background: '#00000020',
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '2rem',
    marginBottom: '2rem',
    alignItems: 'center',
    borderRadius: '0 0 10px 10px',
    gap: 10,
    '@media (max-width: 1000px)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  metal: {
    flex: 1,
    width: '100%',
    background: '#ffffff',
    boxShadow: '5px 5px 10px 0px #00000050',
    borderRadius: 8,
    padding: '1rem 1rem 1rem 1rem',
  },
  date: {
    background: '#00000020',
    color: '#243D6C',
    padding: '0.8rem 0.5rem 0.5rem 1rem',
    borderRadius: '10px 10px 0 0',
    fontWeight: 'bold',
    fontSize: 12,
  },
  title: {
    fontSize: 14,
    textTransform: 'uppercase',
    fontWeight: 'bold',
    color: smplColors.primary.main,
  },
  subTitle: {
    fontSize: 11,
    textTransform: 'uppercase',
    color: smplColors.primary.main,
  },
  country: {
    fontSize: 14,
    backgroundColor: '#00000020',
    padding: '0.2rem 0.5rem 0.2rem 0.5rem',
    marginTop: '0.5rem',
    borderRadius: '5px 5px 0 0',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  value: {
    fontSize: 11,
    borderTop: '1px solid #00000020',
    background: '#00000030',
    padding: '0.2rem 0.5rem 0.2rem 0.5rem',
    marginBottom: '0.5rem',
    textAlign: 'right',
    borderRadius: '0 0 5px 5px',
  },
  originalValue: {
    marginTop: 10,
    marginBottom: 10,
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
  },
  trade: {
    textAlign: 'right',
    fontSize: 15,
    fontWeight: 700,
    marginTop: '1rem',
  },
  rates: {
    display: 'flex',
    justifyContent: 'flex-start',
    marginTop: 15,
    gap: 10,
    '@media (max-width: 550px)': {
      flexDirection: 'column',
      width: '100%',
    },
  },
  rate: {
    background: '#ffffff',
    boxShadow: '5px 5px 10px 0px #00000050',
    borderRadius: 8,
    padding: '1rem 1rem 1rem 1rem',
    fontSize: 12,
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  summary: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingBottom: '2rem',
    marginBottom: '2rem',
    alignItems: 'center',
    gap: 10,
  },
  summaryItem: {
    flex: 1,
    width: '100%',
    background: '#ffffff',
    boxShadow: '5px 5px 10px 0px #00000050',
    borderRadius: 8,
    padding: '1rem 1rem 1rem 1rem',
  },
})

export const availableCurrencies = ['EUR', 'CHF', 'GBP', 'USD'] as const

export type AvailableCurrencyType = typeof availableCurrencies[number]

type SpotPricesResponse = Record<
  AvailableCurrencyType,
  {
    GOLD: number
    SILVER: number
    PLATINUM: number
    PALLADIUM: number
  }
> & {
  lastupdate: string
}

export enum MetalEnum {
  Gold = 'Gold',
  Silver = 'Silver',
  Platinum = 'Platinum',
  Palladium = 'Palladium',
}

export enum TransferTypeEnum {
  foreign = 'foreign',
  lending = 'lending',
  clearance = 'clearance',
}

export type FutureOrdersByDayItemReturnType = Partial<
  Record<
    Uppercase<MetalEnum>,
    Partial<
      Record<
        AvailableCurrencyType,
        {
          originalValue: number
          value: string
          unit: string
          originalGram: number
          /**
           * For debug purposes
           */
          originalKg: number
        }
      > & {
        originalValue: number
        originalSum: number
        /**
         * For debug purposes
         */
        kgSum: number
        sum: string
        unit: string
      }
    >
  >
> & {
  date: string
}

type FutureOrderResponseType = {
  values: FutureOrdersByDayItemReturnType[]
  rates: SpotPricesResponse | null
}

type DateState = {
  urlString?: string | null | undefined
  inputString?: string | null | undefined
}

type SummarizedDataState = {
  gold: number
  silver: number
  platinum: number
  palladium: number
}

export const futureOrdersReport = () => {
  const classes = useStyles()
  const [init, setInit] = useState(false)
  const [data, setData] = useState<FutureOrderResponseType | null>(null)
  const [startDate, setStartDate] = useState<DateState>({})
  const [endDate, setEndDate] = useState<DateState>({})
  const [summary, setSummary] = useState<SummarizedDataState | null>(null)
  const notify = useNotify()

  const getData = async (startDate: DateState, endDate: DateState) => {
    if (
      typeof SMPL_FLEXGOLD_API_BASEURL !== 'string' ||
      SMPL_FLEXGOLD_API_BASEURL === ''
    ) {
      notify('The SMPL_FLEXGOLD_API_BASEURL env is missing')
    } else {
      if (
        typeof startDate.urlString === 'string' &&
        startDate.urlString !== '' &&
        typeof endDate.urlString === 'string' &&
        endDate.urlString !== ''
      ) {
        const url = new URL(
          '/order/reports/future-by-day',
          SMPL_FLEXGOLD_API_BASEURL
        )
        url.searchParams.append('startDate', startDate.urlString)
        url.searchParams.append('endDate', endDate.urlString)
        const urlString = url.toString()

        const response = await authedFetch(urlString, {
          method: 'GET',
          headers: headersForApi,
        })

        const jsonData = await response.json()

        if ('error' in jsonData) {
          if (Array.isArray(jsonData.error?.message)) {
            notify(`Error: ${jsonData.error.message.join(', ')}`)
          } else {
            notify(`Error: ${jsonData.error.message}`)
          }
        } else {
          setData(jsonData)
        }
      }
    }
  }

  const convertDateToURLString = (date: Date) => {
    return date.toISOString()
  }
  const convertDateToINPUTString = (date: Date) => {
    const d = date.getDate()
    const m = date.getMonth() + 1
    const y = date.getFullYear()
    return `${y}-${m < 10 ? '0' + m : m}-${d < 10 ? '0' + d : d}`
  }

  const intialDates = () => {
    const defaultRange = 1
    const today = new Date()
    let todayIn2Weeks = new Date()
    todayIn2Weeks.setDate(todayIn2Weeks.getDate() + defaultRange)
    const todayURLString = convertDateToURLString(today)
    const todayIn2WeeksURLString = convertDateToURLString(todayIn2Weeks)
    const todayINPUTString = convertDateToINPUTString(today)
    const todayIn2WeeksINPUTString = convertDateToINPUTString(todayIn2Weeks)

    setStartDate({
      urlString: todayURLString,
      inputString: todayINPUTString,
    })
    setEndDate({
      urlString: todayIn2WeeksURLString,
      inputString: todayIn2WeeksINPUTString,
    })
    setInit(true)
  }

  const handleStartDate = (date: string | number | Date) => {
    const newDate = new Date(date).toISOString()
    setStartDate({ urlString: newDate })
  }
  const handleEndDate = (date: string | number | Date) => {
    const newDate = new Date(date).toISOString()
    setEndDate({ urlString: newDate })
  }

  const summarizeData = (data: FutureOrderResponseType) => {
    const summarizedData = {
      gold: 0,
      silver: 0,
      platinum: 0,
      palladium: 0,
    }
    for (const [_, day] of Object.entries(data.values)) {
      day.GOLD ? (summarizedData.gold += Number(day.GOLD.sum)) : null
      day.SILVER ? (summarizedData.silver += Number(day.SILVER.sum)) : null
      day.PLATINUM
        ? (summarizedData.platinum += Number(day.PLATINUM.sum))
        : null
      day.PALLADIUM
        ? (summarizedData.palladium += Number(day.PALLADIUM.sum))
        : null
    }
    setSummary(summarizedData)
  }

  useEffect(() => {
    intialDates()
  }, [])

  useEffect(() => {
    getData(startDate, endDate)
  }, [startDate, endDate])

  useEffect(() => {
    if (data?.values) {
      summarizeData(data)
    }
    console.log('beep -> ', data)
  }, [data])

  return (
    <Card className={classes.wrap}>
      <div className={classes.title}>Settings</div> <br />
      {init ? (
        <form noValidate>
          <TextField
            id="dateStart"
            color="primary"
            label="Reporting Start"
            type="date"
            defaultValue={startDate.inputString}
            onChange={(e) => {
              handleStartDate(e.target.value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="dateEnd"
            color="primary"
            label="Reporting End"
            type="date"
            defaultValue={endDate.inputString}
            onChange={(e) => {
              handleEndDate(e.target.value)
            }}
            InputLabelProps={{
              shrink: true,
            }}
            style={{ marginLeft: 30 }}
          />
        </form>
      ) : null}
      <br />
      <br />
      <br />
      <div className={classes.title}>Exchange Rates</div> <br />
      <div className={classes.subTitle}>
        Last update:{' '}
        {data?.rates?.lastupdate
          ? new Date(data.rates.lastupdate).toLocaleString()
          : null}
      </div>
      {data ? (
        <div className={classes.rates}>
          <div className={classes.rate}>
            <div className={classes.title}>Gold</div>
            <span>
              {data ? Number(data.rates?.CHF.GOLD).toFixed(2) : null} CHF
            </span>
            <span>
              {data ? Number(data.rates?.EUR.GOLD).toFixed(2) : null} EURO
            </span>
            <span>
              {data ? Number(data.rates?.GBP.GOLD).toFixed(2) : null} GBP
            </span>
            <span>
              {data ? Number(data.rates?.USD.GOLD).toFixed(2) : null} USD
            </span>
          </div>
          <div className={classes.rate}>
            <div className={classes.title}>Silver</div>
            <span>
              {data ? Number(data.rates?.CHF.SILVER).toFixed(2) : null} CHF
            </span>
            <span>
              {data ? Number(data.rates?.EUR.SILVER).toFixed(2) : null} EURO
            </span>
            <span>
              {data ? Number(data.rates?.GBP.SILVER).toFixed(2) : null} GBP
            </span>
            <span>
              {data ? Number(data.rates?.USD.SILVER).toFixed(2) : null} USD
            </span>
          </div>
          <div className={classes.rate}>
            <div className={classes.title}>Platinum</div>
            <span>
              {data ? Number(data.rates?.CHF.PLATINUM).toFixed(2) : null} CHF
            </span>
            <span>
              {data ? Number(data.rates?.EUR.PLATINUM).toFixed(2) : null} EURO
            </span>
            <span>
              {data ? Number(data.rates?.GBP.PLATINUM).toFixed(2) : null} GBP
            </span>
            <span>
              {data ? Number(data.rates?.USD.PLATINUM).toFixed(2) : null} USD
            </span>
          </div>
          <div className={classes.rate}>
            <div className={classes.title}>Palladium</div>
            <span>
              {data ? Number(data.rates?.CHF.PALLADIUM).toFixed(2) : null} CHF
            </span>
            <span>
              {data ? Number(data.rates?.EUR.PALLADIUM).toFixed(2) : null} EURO
            </span>
            <span>
              {data ? Number(data.rates?.GBP.PALLADIUM).toFixed(2) : null} GBP
            </span>
            <span>
              {data ? Number(data.rates?.USD.PALLADIUM).toFixed(2) : null} USD
            </span>
          </div>
        </div>
      ) : (
        <CircularProgress style={{ marginTop: 21 }} />
      )}
      <br />
      <br />
      <br />
      <div className={classes.title}>Future Orders Report</div> <br />
      {data ? (
        data?.values?.map((day, i) => (
          <div key={i}>
            <div className={classes.date}>
              {new Date(day.date).toLocaleDateString()}
            </div>
            <div key={i} className={classes.metals}>
              <div
                className={classes.metal}
                style={day.GOLD ? {} : { opacity: 0.25 }}
              >
                <div className={classes.title}>Gold</div>
                {/* <div className={classes.originalValue}>
                  <span>Old State:</span>
                  <span>{day.GOLD?.originalValue} Kg</span>
                </div> */}
                <div
                  className={classes.country}
                  style={day.GOLD?.EUR ? {} : { opacity: 0.25 }}
                >
                  🇩🇪
                  <span>{day.GOLD?.EUR?.originalValue} EURO</span>
                </div>
                <div
                  style={day.GOLD?.EUR ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.GOLD?.EUR?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.GOLD?.CHF ? {} : { opacity: 0.25 }}
                >
                  🇨🇭
                  <span>{day.GOLD?.CHF?.originalValue} CHF</span>
                </div>
                <div
                  style={day.GOLD?.CHF ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.GOLD?.CHF?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.GOLD?.GBP ? {} : { opacity: 0.25 }}
                >
                  🇬🇧
                  <span>{day.GOLD?.GBP?.originalValue} GBP</span>
                </div>
                <div
                  style={day.GOLD?.GBP ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.GOLD?.GBP?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.GOLD?.USD ? {} : { opacity: 0.25 }}
                >
                  🇺🇸
                  <span>{day.GOLD?.USD?.originalValue} USD</span>
                </div>
                <div
                  style={day.GOLD?.USD ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.GOLD?.USD?.value} Kg
                </div>
                <div
                  className={classes.trade}
                  style={
                    day?.GOLD?.sum && parseFloat(day.GOLD.sum) > 0
                      ? { color: 'green' }
                      : { color: 'red' }
                  }
                >
                  {day.GOLD?.sum} Kg
                </div>
              </div>
              <div
                className={classes.metal}
                style={day.SILVER ? {} : { opacity: 0.25 }}
              >
                <div className={classes.title}>Silver</div>
                {/* <div className={classes.originalValue}>
                  <span>Old State:</span>
                  <span>{day.SILVER?.originalValue} Kg</span>
                </div> */}
                <div
                  className={classes.country}
                  style={day.SILVER?.EUR ? {} : { opacity: 0.25 }}
                >
                  🇩🇪
                  <span>{day.SILVER?.EUR?.originalValue} EURO</span>
                </div>
                <div
                  style={day.SILVER?.EUR ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.SILVER?.EUR?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.SILVER?.CHF ? {} : { opacity: 0.25 }}
                >
                  🇨🇭
                  <span>{day.SILVER?.CHF?.originalValue} CHF</span>
                </div>
                <div
                  style={day.SILVER?.CHF ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.SILVER?.CHF?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.SILVER?.GBP ? {} : { opacity: 0.25 }}
                >
                  🇬🇧
                  <span>{day.SILVER?.GBP?.originalValue} GBP</span>
                </div>
                <div
                  style={day.SILVER?.GBP ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.SILVER?.GBP?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.SILVER?.USD ? {} : { opacity: 0.25 }}
                >
                  🇺🇸
                  <span>{day.SILVER?.USD?.originalValue} USD</span>
                </div>
                <div
                  style={day.SILVER?.USD ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.SILVER?.USD?.value} Kg
                </div>
                <div
                  className={classes.trade}
                  style={
                    day?.SILVER?.sum && parseFloat(day.SILVER.sum) > 0
                      ? { color: 'green' }
                      : { color: 'red' }
                  }
                >
                  {day.SILVER?.sum} Kg
                </div>
              </div>
              <div
                className={classes.metal}
                style={day.PLATINUM ? {} : { opacity: 0.25 }}
              >
                <div className={classes.title}>Platinum</div>
                {/* <div className={classes.originalValue}>
                  <span>Old State:</span>
                  <span>{day.PLATINUM?.originalValue} Kg</span>
                </div> */}
                <div
                  className={classes.country}
                  style={day.PLATINUM?.EUR ? {} : { opacity: 0.25 }}
                >
                  🇩🇪
                  <span>{day.PLATINUM?.EUR?.originalValue} EURO</span>
                </div>
                <div
                  style={day.PLATINUM?.EUR ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.PLATINUM?.EUR?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.PLATINUM?.CHF ? {} : { opacity: 0.25 }}
                >
                  🇨🇭
                  <span>{day.PLATINUM?.CHF?.originalValue} CHF</span>
                </div>
                <div
                  style={day.PLATINUM?.CHF ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.PLATINUM?.CHF?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.PLATINUM?.GBP ? {} : { opacity: 0.25 }}
                >
                  🇬🇧
                  <span>{day.PLATINUM?.GBP?.originalValue} GBP</span>
                </div>
                <div
                  style={day.PLATINUM?.GBP ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.PLATINUM?.GBP?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.PLATINUM?.USD ? {} : { opacity: 0.25 }}
                >
                  🇺🇸
                  <span>{day.PLATINUM?.USD?.originalValue} USD</span>
                </div>
                <div
                  style={day.PLATINUM?.USD ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.PLATINUM?.USD?.value} Kg
                </div>
                <div
                  className={classes.trade}
                  style={
                    day?.PLATINUM?.sum && parseFloat(day.PLATINUM.sum) > 0
                      ? { color: 'green' }
                      : { color: 'red' }
                  }
                >
                  {day.PLATINUM?.sum} Kg
                </div>
              </div>
              <div
                className={classes.metal}
                style={day.PALLADIUM ? {} : { opacity: 0.25 }}
              >
                <div className={classes.title}>Palladium</div>
                {/* <div className={classes.originalValue}>
                  <span>Old State:</span>
                  <span>{day.PALLADIUM?.originalValue} Kg</span>
                </div> */}
                <div
                  className={classes.country}
                  style={day.PALLADIUM?.EUR ? {} : { opacity: 0.25 }}
                >
                  🇩🇪
                  <span>{day.PALLADIUM?.EUR?.originalValue} EURO</span>
                </div>
                <div
                  style={day.PALLADIUM?.EUR ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.PALLADIUM?.EUR?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.PALLADIUM?.CHF ? {} : { opacity: 0.25 }}
                >
                  🇨🇭
                  <span>{day.PALLADIUM?.CHF?.originalValue} CHF</span>
                </div>
                <div
                  style={day.PALLADIUM?.CHF ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.PALLADIUM?.CHF?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.PALLADIUM?.GBP ? {} : { opacity: 0.25 }}
                >
                  🇬🇧
                  <span>{day.PALLADIUM?.GBP?.originalValue} GBP</span>
                </div>
                <div
                  style={day.PALLADIUM?.GBP ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.PALLADIUM?.GBP?.value} Kg
                </div>
                <div
                  className={classes.country}
                  style={day.PALLADIUM?.USD ? {} : { opacity: 0.25 }}
                >
                  🇺🇸
                  <span>{day.PALLADIUM?.USD?.originalValue} USD</span>
                </div>
                <div
                  style={day.PALLADIUM?.USD ? {} : { opacity: 0.25 }}
                  className={classes.value}
                >
                  {day.PALLADIUM?.USD?.value} Kg
                </div>
                <div
                  className={classes.trade}
                  style={
                    day?.PALLADIUM?.sum && parseFloat(day.PALLADIUM.sum) > 0
                      ? { color: 'green' }
                      : { color: 'red' }
                  }
                >
                  {day.PALLADIUM?.sum} Kg
                </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <CircularProgress />
      )}
      <div className={classes.title}>Future Orders Summary</div> <br />
      {data ? (
        <div className={classes.summary}>
          <div
            className={classes.summaryItem}
            style={summary?.gold ? {} : { opacity: 0.6 }}
          >
            <div className={classes.title}>Gold</div>
            <br />
            <span
              style={
                typeof summary?.gold === 'number' && summary.gold > 0
                  ? { color: 'green' }
                  : { color: 'red' }
              }
            >
              {typeof summary?.gold === 'number' && summary.gold
                ? summary.gold.toFixed(2) + ' Kg'
                : 'No orders'}
            </span>
          </div>
          <div
            className={classes.summaryItem}
            style={summary?.silver ? {} : { opacity: 0.6 }}
          >
            <div className={classes.title}>Silver</div>
            <br />
            <span
              style={
                typeof summary?.silver === 'number' && summary.silver > 0
                  ? { color: 'green' }
                  : { color: 'red' }
              }
            >
              {typeof summary?.silver === 'number' && summary.silver
                ? summary.silver.toFixed(2) + ' Kg'
                : 'No orders'}
            </span>
          </div>
          <div
            className={classes.summaryItem}
            style={summary?.platinum ? {} : { opacity: 0.6 }}
          >
            <div className={classes.title}>Platinum</div>
            <br />
            <span
              style={
                typeof summary?.platinum === 'number' && summary.platinum > 0
                  ? { color: 'green' }
                  : { color: 'red' }
              }
            >
              {typeof summary?.platinum === 'number' && summary.platinum
                ? summary.platinum.toFixed(2) + ' Kg'
                : 'No orders'}
            </span>
          </div>
          <div
            className={classes.summaryItem}
            style={summary?.palladium ? {} : { opacity: 0.6 }}
          >
            <div className={classes.title}>Palladium</div>
            <br />
            <span
              style={
                typeof summary?.palladium === 'number' && summary.palladium > 0
                  ? { color: 'green' }
                  : { color: 'red' }
              }
            >
              {typeof summary?.palladium === 'number' && summary.palladium
                ? summary.palladium.toFixed(2) + ' Kg'
                : 'No orders'}
            </span>
          </div>
        </div>
      ) : (
        <CircularProgress />
      )}
    </Card>
  )
}

export default futureOrdersReport
