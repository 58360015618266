import MuiTextField from '@material-ui/core/TextField'
import { TextFieldProps } from 'react-admin'

type Props = TextFieldProps & {
  InputProps?: Object
}

type InnerProps = Props

export function EnhancedTextField({
  className,
  source,
  record = { id: 'null' },
  InputProps,
}: InnerProps) {
  const value = source ? record?.[source] : null
  return (
    <MuiTextField
      className={`${className}`}
      value={value}
      InputProps={{
        readOnly: true,
        disableUnderline: true,
        ...InputProps,
      }}
    />
  )
}
