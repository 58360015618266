import { IntrospectionField, IntrospectionObjectType } from 'graphql'
import gql from 'graphql-tag'
import { SchemaTraverser } from '../dataProvider/introspections/SchemaTraverser'
import { plainFields } from '../dataProvider/introspections/utils'
import { MOVIE_PREFIX } from '../lib/config'

export function createGlobalQuery(schemaTraverser: SchemaTraverser) {
  let allInOneFragment = ''

  schemaTraverser.resourceNames.forEach((resourceName) => {
    const fragmentName = schemaTraverser._resourceTypeByName![resourceName]!
      .GET_LIST.name

    const resourceTraverser = schemaTraverser.getResourceTraverserByName(
      resourceName
    )!

    const pFields = plainFields(
      (resourceTraverser.resource.type as IntrospectionObjectType).fields
    ).filter((field) => {
      if (field.name === 'id') {
        return true
      }

      if (field.name.slice(-2) === 'Id') {
        return false
      }

      if (
        field.type.kind === 'SCALAR' &&
        field.type.name === 'String' &&
        !['searchIndex'].includes(field.name)
      ) {
        return true
      }

      if (
        // @ts-ignore
        field.type.ofType &&
        // @ts-ignore
        field.type.ofType.kind === 'SCALAR' &&
        // @ts-ignore
        (field.type.ofType.name === 'String' ||
          // @ts-ignore
          field.type.ofType.name === 'Guid')
      ) {
        return true
      }
      return false
    })

    const filterArray = pFields.map((field) => {
      if (field.name === 'id') {
        return '{id: {equalTo: $id}}'
      } else if (field.type.kind === 'LIST') {
        return `{${field.name}: {anyEqualTo: $arrayValue}}`
      } else {
        return `{${field.name}: {likeInsensitive: $searchValue}}`
      }
    })

    let pFieldsFilter = ''

    // make sure series entries did not appear on movies results
    if (resourceName === 'CmsMovie') {
      pFieldsFilter = `
			and: [
				{
					id: { startsWith: "${MOVIE_PREFIX}" },
				},
				{
					or: [
						${filterArray.join(', \n')}
					]
				}
			]
			`
    } else {
      pFieldsFilter = `or: [
				${filterArray.join(', \n')}
			  ]`
    }

    const fragment = `${fragmentName} (
			first: 3,
			filter: {\n${pFieldsFilter}\n}
		  ) {
			${resourceName}: nodes {
			  ${pFields
          .map(({ name }: IntrospectionField) => {
            return name
          })
          .join(', ')}
			}
		  }`

    allInOneFragment = `
		  ${allInOneFragment}
		  ${fragment}
		`
  })

  const GlobalQuery = gql`
    query Global($id: Guid, $searchValue: String, $arrayValue: String) {
      ${allInOneFragment}
      # // @ts-ignore
    }
  `

  return GlobalQuery
}

export function createGlobalIdQuery(schemaTraverser: SchemaTraverser) {
  let allInOneFragment = ''

  schemaTraverser.resourceNames.forEach((resourceName) => {
    const fragmentName = schemaTraverser._resourceTypeByName![resourceName]!
      .GET_LIST.name

    const resourceTraverser = schemaTraverser.getResourceTraverserByName(
      resourceName
    )!

    const pFields = plainFields(
      (resourceTraverser.resource.type as IntrospectionObjectType).fields
    ).filter((field) => {
      if (field.name === 'id') {
        return true
      }
      return false
    })

    const filterArray = pFields.map((field) => {
      return '{id: {equalTo: $id}}'
    })

    const pFieldsFilter = `or: [
        ${filterArray.join(', \n')}
      ]`

    const fragment = `${fragmentName} (
        first: 3,
        filter: {\n${pFieldsFilter}\n}
      ) {
        ${resourceName}: nodes {
          ${pFields
            .map(({ name }: IntrospectionField) => {
              return name
            })
            .join(', ')}
        }
      }`

    allInOneFragment = `
      ${allInOneFragment}
      ${fragment}
    `
  })

  const GlobalIdQuery = gql`
    query GlobalId($id: Guid) {
      ${allInOneFragment}
      # // @ts-ignore
    }
  `

  return GlobalIdQuery
}
