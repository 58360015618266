import englishMessages from 'ra-language-english'

export default {
  ...englishMessages,
  pos: {
    configuration: 'Configuration',
    language: 'Language',
    theme: {
      name: 'Theme',
      light: 'Light',
      dark: 'Dark',
    },
    menu: {
      apps: 'Apps',
      tools: 'Tools',
      types: 'Advanced',
      cms: 'CMS',
      payment: 'Payment',
      schedule: 'Schedule',
      subscription: 'Subscription',
      voucher: 'Voucher',
      movieOfTheDay: 'Movie of the Day',
      'l&c': 'License & Contract',
      'p&p': 'Product & Payment',
      users: 'User Management',
      transcoding: 'Transcoding',
      customer: 'Customer',
      vault: 'Vault',
      termsAndConditions: 'Terms & Conditions',
      orders: 'Orders',
      messages: 'Messages',
      configuration: 'Configuration',
      logs: 'Logs',
      other: 'Other',
    },
  },
}
