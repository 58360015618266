import logo from '../../imgs/logos/kixi.png'
import { CustomerTheme } from './customerThemeType'

export const KixiTheme: Partial<CustomerTheme> = {
  primary: {
    main: '#0b84ae',
    light: '#ffc500',
    dark: '#002B6B',
  },
  secondary: {
    main: '#e10074',
  },
}

export const KixiLogo = (
  <img
    style={{
      width: 100,
      height: 'auto',
      paddingLeft: 25,
    }}
    src={logo}
  />
)
