import logo from '../../imgs/logos/filmhome.png'
import { CustomerTheme } from './customerThemeType'

export const FilmhomeTheme: Partial<CustomerTheme> = {
  primary: {
    main: '#000000',
    light: '#c80200',
    dark: '#c80200',
  },
  secondary: {
    main: '#c80200',
  },
}

export const FilmhomeLogo = (
  <img
    style={{
      width: 100,
      height: 'auto',
      paddingLeft: 25,
    }}
    src={logo}
  />
)
