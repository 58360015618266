import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router'

export function useURLSearchParams() {
  const history = useHistory()
  const [_, setForceRerender] = useState(0)
  useEffect(() => {
    const unregister = history.listen(() => {
      setForceRerender((e) => e + 1)
    })
    return () => {
      unregister()
    }
  }, [history])

  return useMemo(() => {
    const parsed = new URLSearchParams(history.location.search || '')
    return parsed
  }, [history?.location?.search])
}
