import { SET_APPS, SetAppsAction } from './actions/actions'

export function appsReducer(
  previousState = null,
  { type, payload }: SetAppsAction
) {
  if (type === SET_APPS) {
    return payload ?? null // can be null if the user is not logged in
  }
  return previousState
}
