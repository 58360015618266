import { Tooltip } from '@material-ui/core'
import Divider from '@material-ui/core/Divider'
import Input from '@material-ui/core/Input'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListSubheader from '@material-ui/core/ListSubheader'
import Popover from '@material-ui/core/Popover'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import debounce from 'lodash/debounce'
import { Record, useRedirect } from 'ra-core'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
// @ts-ignore
import { useNotify, UserMenu } from 'react-admin'
import { useApolloClient } from 'react-apollo-hooks'
import { Link } from 'react-router-dom'
import { getSchemaTraverser } from '../dataProvider/buildQuery'
import { createGlobalIdQuery, createGlobalQuery } from '../graphQL/globalSearch'
import AppBar from './RA-AppBar'
// import { GlobalQuery } from '../graphQL/globalSearch'
import { filterNotEmpty } from '../lib/filterNonEmpty'
import { fromCaseSafeId, looksLikeSmplId } from '../lib/idutils'

import { motion } from 'framer-motion'
import { smplTexts } from '../genericData/CustomTextMappings'
import Logo from './Logo'

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  spacer: {
    flex: 1,
  },
  input: {
    maxHeight: 40,
    color: 'white',
    marginRight: 10,
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: '5rem',
  },
  searchIcon: { marginRight: 5, marginLeft: 5, paddingLeft: 8 },
  appBar: {
    '& button svg': {
      fill: 'white',
    },
  },
})

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>{/* <ConfigurationMenu /> */}</UserMenu>
)

// we are searching in a lot of tables therefore the deboucetime should be a bit longer
const SEARCH_DEBOUNCE_TIMEOUT = 1500 // in ms

const CustomAppBar = (props: any) => {
  const classes = useStyles({})
  const redirect = useRedirect()
  const notify = useNotify()
  // this will be needed for mui PopOver Comp:
  const [anchorEl, setAnchorEl] = React.useState<HTMLInputElement | null>(null)
  const [openPopUp, setOpenPopUp] = useState(false)

  const apolloClient = useApolloClient()
  const [refactoredResult, setRefactoredResult] = useState<any[]>()

  // get Global query:
  const schemaTraverser = getSchemaTraverser()!
  const GlobalQuery = useMemo(() => createGlobalQuery(schemaTraverser), [])
  const GlobalIdQuery = useMemo(() => createGlobalIdQuery(schemaTraverser), [])

  const handleResponse = (
    res: {
      data: {
        [x: string]: {
          [x: string]: Record[]
        }
      }
    },
    searchValue: string
  ) => {
    const refactRes = Object.keys(res.data)
      .map((key) => {
        const cms = Object.keys(res.data[key])[0]
        if (res.data[key][cms].length === 0) return null
        return res.data[key]
      })
      .filter(filterNotEmpty)

    if (refactRes.length === 0) {
      // no result
      notify('No result was found for the input value.', 'warning')
    } else {
      // result exist
      // was the query an id?
      if (looksLikeSmplId(searchValue) && refactRes.length === 1) {
        // redirect
        // @ts-ignore
        notify('Input was an id, redirect to show page.')
        const key = Object.keys(refactRes[0])[0]
        redirect('show', '/' + key, refactRes[0][key][0].id)
      } else {
        // open popup and show it
        notify('Select an entry to redirect to show page.', 'info')
        setRefactoredResult(refactRes)
        setOpenPopUp(true)
      }
    }
  }

  const debounceFetchData = useCallback(
    debounce(async (newValue) => {
      try {
        if (newValue) {
          let res
          if (looksLikeSmplId(newValue)) {
            let idToSearch = newValue
            if (idToSearch.length === 18) {
              idToSearch = fromCaseSafeId(idToSearch)
            }
            res = await apolloClient.query({
              query: GlobalIdQuery,
              variables: {
                id: idToSearch,
                count: 1,
              },
            })
          } else {
            res = await apolloClient.query({
              query: GlobalQuery,
              variables: {
                id: newValue,
                searchValue: '%' + newValue + '%',
                arrayValue: newValue,
                count: 3,
              },
            })
          }

          handleResponse(res, newValue)
        }
      } catch (error) {
        console.error(error)
      }
    }, SEARCH_DEBOUNCE_TIMEOUT),
    []
  )

  const handleInputChange = (event: any) => {
    const value: string = event.target.value
    if (!anchorEl) setAnchorEl(event.currentTarget)
    // start debouce search only after the 2nd value
    if (value && value.length > 2) {
      debounceFetchData(value)
    } else {
      // discard previous debounce
      debounceFetchData(null)
    }
  }

  // hidden shortcut to navigate to specific element CMD + V
  useEffect(() => {
    const handleEvent = (e: KeyboardEvent) => {
      const activeElement = document.activeElement.tagName.toLowerCase()
      const nothingSelected =
        activeElement !== 'input' && activeElement !== 'textarea'
      if (
        (e.metaKey || e.ctrlKey) &&
        e.key === 'v' &&
        e.repeat === false &&
        nothingSelected
      ) {
        notify('Scanning your clipboard for an ID')
        navigateByClipboardShortcut()
      }
    }
    document.addEventListener('keydown', handleEvent)
    return () => document.removeEventListener('keydown', handleEvent)
  }, [])

  async function navigateByClipboardShortcut() {
    try {
      const searchValue = await navigator.clipboard.readText()
      debounceFetchData(searchValue)
    } catch (e) {
      console.error('could not copy from clipboard', e)
    }
  }

  return (
    <AppBar
      {...props}
      className={classes.appBar}
      userMenu={<CustomUserMenu />}
      color="primary"
    >
      <motion.div
        initial={{ y: -100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 1.5, ease: 'anticipate' }}
      >
        <Logo />
      </motion.div>
      <span className={classes.spacer} />
      <motion.div
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 1.5, delay: 0.5, ease: 'anticipate' }}
      >
        <Tooltip title={smplTexts.appbar.search}>
          <Input
            id="global-search"
            className={classes.input}
            placeholder="Search everywhere"
            onChange={(event: any) => handleInputChange(event)}
            onKeyDown={(event: any) => {
              if (event.key === 'Enter') {
                handleInputChange(event)
              }
            }}
            startAdornment={<SearchIcon className={classes.searchIcon} />}
            disableUnderline
          />
        </Tooltip>
      </motion.div>
      {refactoredResult && anchorEl && openPopUp ? (
        <ResultPopover
          refactoredResult={refactoredResult}
          open={openPopUp}
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          setOpenPopUp={setOpenPopUp}
        />
      ) : null}
    </AppBar>
  )
}

type ResultPopoverProps = {
  refactoredResult: any[]
  open: boolean
  anchorEl: HTMLInputElement | null
  setAnchorEl: React.Dispatch<React.SetStateAction<HTMLInputElement | null>>
  setOpenPopUp: React.Dispatch<React.SetStateAction<boolean>>
}

const ResultPopover = (props: ResultPopoverProps) => {
  const { refactoredResult, open, anchorEl, setAnchorEl, setOpenPopUp } = props

  const PRIMARY_TAG: string[] = ['title', 'email', 'name']
  const SECONDARY_TAG: string[] = [
    'id',
    'externalIdentifier',
    'slug',
    'username',
    'paymentCountry',
  ]

  return (
    <Popover
      id="global-search-result"
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        setOpenPopUp(false)
        setAnchorEl(null)
      }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      style={{ maxHeight: '90%' }}
    >
      {refactoredResult.map((cms) => {
        const resource = Object.keys(cms)[0]
        const elements = cms[resource]
        if (elements.length === 0) return null
        return (
          <div key={resource}>
            <List
              subheader={
                <ListSubheader
                  style={{
                    position: 'relative',
                    background: '#dddddd',
                    color: '#888888',
                    fontWeight: 600,
                  }}
                >
                  {resource === 'CmsSery' ? 'CmsSeries' : resource}
                </ListSubheader>
              }
              key={resource}
            >
              {elements.map((values: any) => {
                const { id, title, externalIdentifier } = values

                const primaryValue = PRIMARY_TAG.map((tag) => {
                  if (values[tag] !== null && values[tag] !== undefined) {
                    return values[tag]
                  } else {
                    return null
                  }
                }).filter((val) => val !== null)

                const secondaryValue = SECONDARY_TAG.map((tag) => {
                  if (values[tag] !== null && values[tag] !== undefined) {
                    return values[tag]
                  } else {
                    return null
                  }
                }).filter((val) => val !== null)

                return (
                  <ListItem
                    key={id + title + externalIdentifier}
                    className="search__results__list-item"
                    button
                    component={Link}
                    to={`/${resource}/${id}/show`}
                    onClick={() => {
                      setAnchorEl(null)
                      setOpenPopUp(false)
                    }}
                  >
                    <ListItemText
                      style={{ paddingLeft: 25 }}
                      primary={primaryValue.join(' | ')}
                      secondary={secondaryValue.join(' | ')}
                    />
                  </ListItem>
                )
              })}
            </List>
            <Divider />
          </div>
        )
      })}
    </Popover>
  )
}

export default CustomAppBar
