import {
  Checkbox,
  Fade,
  FormControl,
  FormControlLabel,
  Tooltip,
} from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useEffect, useState } from 'react'
import { Record, useDataProvider } from 'react-admin'
import Select from 'react-dropdown-select'
import { useField } from 'react-final-form'
import { isKixiCustomer, isNetzkinoCustomer } from '../../lib/config'

const useStyles = makeStyles({
  title: {
    fontSize: 14,
  },
  fieldGroup: {
    display: 'flex',
    flexWrap: 'wrap',
    overflow: 'auto',
  },
  fieldInMaxWidth: {
    width: '45%',
    margin: '0 auto',
    marginTop: 5,
    marginBottom: 5,
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  field: {
    width: '90%',
    margin: '0 auto',
    marginTop: 5,
    marginBottom: 5,
    '& > div': {
      backgroundColor: 'rgba(0,0,0,0.04)',
    },
  },
  selectBox: {
    // width is sometimes overwritten by system values, fix:
    zIndex: 100,
  },
  imgContainer: {
    width: 300,
    maxHeight: 200,
  },

  thumbAndButtonContainer: {
    width: '100%',
    maxHeight: 200,
  },
  thumb: {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    padding: 4,
    boxSizing: 'border-box',
    width: '100%',
    maxHeight: 200,
    position: 'relative',
    top: 5,
  },
  thumbInner: {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
  },
  img: {
    display: 'block',
    width: 'auto',
    height: '100%',
  },
})

export const CustomDataInput = (props: {
  fieldName: string
  label: string
  maxWidth?: boolean
  canAddNewField?: boolean
  inputProps: {
    saveType: string
    fields: {
      name: string
      label: string
      type: string
      options?: { label: string; value: any }[]
    }[]
  }
}) => {
  const classes = useStyles()

  const {
    fieldName,
    label,
    canAddNewField = false,
    maxWidth = false,
    inputProps,
  } = props
  const { saveType = 'string' } = inputProps
  const { fields } = inputProps
  const formField = useField(fieldName)
  const dataProvider = useDataProvider()
  const currentFieldValue = formField.input.value
  const customJson = !currentFieldValue
    ? '{}'
    : typeof currentFieldValue === 'string'
    ? currentFieldValue
    : JSON.stringify(currentFieldValue)
  const parsedJson = JSON.parse(customJson)
  const theme = useTheme()

  if (!fields || fields.length === 0) {
    return null
  }

  const allSupportedFieldName = fields.map((f) => f.name)
  const unsupportedFieldsInCData = parsedJson
    ? Object.keys(parsedJson).filter((k) => {
        if (allSupportedFieldName.includes(k)) {
          return false
        } else {
          return true
        }
      })
    : []

  const [fetchImages, setFetchImages] = useState<boolean>(true)
  const [images, setImages] = useState<Record[] | undefined>()
  const imgIds: string[] = []
  const imageFieldsInCustomData = fields
    .filter((f) => f.type === 'Image')
    .map((f) => f.name)
  for (const key of Object.keys(parsedJson)) {
    if (imageFieldsInCustomData.includes(key)) {
      imgIds.push(parsedJson[key])
    }
  }

  useEffect(() => {
    const fetchImage = async (imgId: string) => {
      try {
        const imgRes = await dataProvider.getOne('CmsImage', {
          id: imgId,
        })
        if (imgRes.data) {
          setImages((imgs) => {
            if (imgs) {
              return [...imgs, imgRes.data]
            } else {
              return [imgRes.data]
            }
          })
        } else {
          throw new Error(`Can not query exiting image with id ${imgId}.`)
        }
      } catch (error) {
        console.error(error)
      }
    }

    if (fetchImage) {
      if (imgIds.length > 0) {
        for (const imgId of imgIds) {
          fetchImage(imgId)
        }
      }
      setFetchImages(false)
    }
  }, [fetchImages])

  // console.log('currentFieldValue', currentFieldValue)

  const handleChange = (
    value: any,
    customJson: string,
    customFieldName: string
  ) => {
    const currentJson = JSON.parse(customJson)
    currentJson[customFieldName] = value
    saveType === 'string'
      ? formField.input.onChange(JSON.stringify(currentJson))
      : formField.input.onChange(currentJson)
  }

  return (
    <Card elevation={2}>
      <CardContent>
        <Typography
          className={classes.title}
          color="textSecondary"
          gutterBottom
        >
          {label}
        </Typography>
      </CardContent>
      <div className={classes.fieldGroup}>
        {unsupportedFieldsInCData.map((fieldName) => {
          const currentValue = parsedJson[fieldName]
          const fieldClass = maxWidth ? classes.fieldInMaxWidth : classes.field

          return (
            <TextField
              key={fieldName}
              className={fieldClass}
              variant="filled"
              label={fieldName}
              value={currentValue}
              focused={currentValue ? true : false}
              onChange={(event) => {
                const value = event.target.value
                handleChange(value, customJson, fieldName)
              }}
            />
          )
        })}
        {fields.map((field) => {
          const { name, type, label, options } = field
          const fieldClass = maxWidth ? classes.fieldInMaxWidth : classes.field

          if (fieldName === 'videoSource') {
            if (isKixiCustomer) {
              if (name !== 'pmdUrl') {
                return null // Hide if not 'pmdUrl' for Kixi or Liaw customers
              }
            } else if (!isNetzkinoCustomer && name === 'pmdUrl') {
              return null // Hide only 'pmdUrl' for non-Netzkino customers
            }
          }

          const currentValue = parsedJson[name]

          if (!type || type === 'String') {
            return (
              <TextField
                key={name}
                className={fieldClass}
                variant="filled"
                label={label}
                value={currentValue}
                focused={currentValue ? true : false}
                onChange={(event) => {
                  const value = event.target.value
                  handleChange(value, customJson, name)
                }}
              />
            )
          }

          if (type === 'Json') {
            return (
              <TextField
                key={name}
                className={fieldClass}
                variant="filled"
                label={label}
                value={JSON.stringify(currentValue, null, 2)}
                focused={currentValue ? true : false}
                onChange={(event) => {
                  const value = JSON.parse(event.target.value)
                  handleChange(value, customJson, name)
                }}
                multiline
              />
            )
          }

          if (type === 'Number') {
            return (
              <TextField
                key={name}
                className={fieldClass}
                variant="filled"
                label={label}
                type="number"
                value={currentValue}
                focused={currentValue ? true : false}
                onChange={(event) => {
                  const value = event.target.value
                  handleChange(value, customJson, name)
                }}
              />
            )
          }

          if (type === 'Date') {
            return (
              <TextField
                key={name}
                label={label}
                type="date"
                value={currentValue}
                focused={currentValue ? true : false}
                className={fieldClass}
                variant="filled"
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(event) => {
                  const value = event.target.value
                  handleChange(value, customJson, name)
                }}
              />
            )
          }

          if (type === 'Select' && options) {
            return (
              <FormControl key={name} className={fieldClass}>
                <Typography color="textSecondary" variant="caption">
                  {label}
                </Typography>
                <Select
                  options={options}
                  values={[
                    {
                      value: currentValue,
                      label: currentValue,
                    },
                  ]}
                  valueField="value"
                  onChange={(values) => {
                    handleChange(values[0].value, customJson, name)
                  }}
                  color={theme.palette.primary.main}
                />
              </FormControl>
            )
          }

          if (type === 'Image') {
            const image = images?.find((i) => i.id === currentValue)

            return (
              <FormControl key={name} className={fieldClass}>
                <TextField
                  key={name}
                  variant="filled"
                  label={label}
                  value={currentValue}
                  focused={currentValue ? true : false}
                  onChange={(event) => {
                    const value = event.target.value
                    handleChange(value, customJson, name)
                    setFetchImages(true)
                  }}
                />
                {image ? (
                  <div className={classes.thumbAndButtonContainer}>
                    <div className={classes.thumb}>
                      <div className={classes.thumbInner}>
                        <Tooltip
                          title={image.name}
                          placement="bottom"
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 500 }}
                          enterDelay={100}
                        >
                          <img
                            src={image.masterUrl}
                            className={classes.img}
                            alt={image.name}
                          />
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                ) : null}
              </FormControl>
            )
          }

          if (type === 'Boolean') {
            return (
              <FormControlLabel
                key={name}
                className={fieldClass}
                control={
                  <Checkbox
                    checked={!!currentValue}
                    onChange={(event) => {
                      handleChange(event.target.checked, customJson, name)
                    }}
                  />
                }
                label={label}
              />
            )
          }

          // TODO: add support for additional simple field type

          return null
        })}
      </div>
    </Card>
  )
}
