import {
  IntrospectionField,
  IntrospectionInputValue,
  IntrospectionListTypeRef,
  IntrospectionNamedTypeRef,
  IntrospectionObjectType,
  IntrospectionOutputType,
  IntrospectionOutputTypeRef,
  IntrospectionSchema,
  IntrospectionType,
} from 'graphql'
import { singularize } from 'inflection'
import lowerFirst from 'lodash/lowerFirst'
import memoize from 'lodash/memoize'
import snakeCase from 'lodash/snakeCase'
import { TypeDisplaySetting } from '../../genericData/settings/TypeDisplaySetting'
import { filterNotEmpty } from '../../lib/filterNonEmpty'
import { memoizeGetters } from '../../lib/memoizeGetters'
import { Resource } from './ReactAdmin-Types'
import { getFilterType, getTypeFromFilterType } from './getList'
import { getOneToManyRelationshipFieldData } from './getOneToManyRelationshipFieldData'
import { getRelationshipFieldsForType } from './getRelationshipFields'

type FilterOperation =
  | 'startsWith'
  | 'endsWith'
  | 'includesInsensitive'
  | 'startsWithInsensitive'
  | 'notStartsWithInsensitive'
  | 'includes'
  | 'lessThan'
  | 'lessThanOrEqualTo'
  | 'greaterThan'
  | 'greaterThanOrEqualTo'
  | 'equalTo'
  | 'notEqualTo'
  | 'isNull'

function isFieldOperationAllowed(type: string, operationName: FilterOperation) {
  if (
    type != 'JSON' &&
    (operationName === 'equalTo' || operationName === 'notEqualTo')
  ) {
    return true
  }
  switch (type) {
    case 'JSON': {
      return ['containsKey', 'contains'].includes(operationName)
    }
    case 'Guid': // falls-through
    case 'String': {
      return [
        'startsWith',
        'endsWith',
        'includesInsensitive',
        'includesInsensitive',
        'startsWithInsensitive',
        'notStartsWithInsensitive',
        'like',
        'likeInsensitive',
        'includes',
      ].includes(operationName)
    }
    case 'Int':
    case 'Date':
    case 'Float':
      return ['lessThanOrEqualTo', 'greaterThanOrEqualTo'].includes(
        operationName
      )
    case 'Datetime': {
      return [
        'lessThan',
        'lessThanOrEqualTo',
        'greaterThan',
        'greaterThanOrEqualTo',
      ].includes(operationName)
    }
    case 'StringList': {
      return ['notEqualTo', 'contains', 'anyEqualTo', 'anyNotEqualTo'].includes(
        operationName
      )
    }
    default: {
      return false
    }
  }
}

/*
 * Single file in order to avoid cyclic dependencies!
 */

export function nameFromResource(resource: Resource): string {
  // @ts-ignore
  return resource.name || resource.type.name
}

export const systemFieldNames = [
  'id',
  'createdById',
  'createdDate',
  'lastModifiedById',
  'lastModifiedDate',
]

function getTypeName(type: IntrospectionOutputTypeRef): string {
  switch (type.kind) {
    case 'LIST': // falls-through
    case 'NON_NULL': {
      return getTypeName(type.ofType)
    }
    default: {
      return (type as IntrospectionNamedTypeRef<IntrospectionOutputType>).name
    }
  }
}

function fieldIsNotWriteable(name: string) {
  return name === 'nodeId' || systemFieldNames.includes(name)
}

export class FieldTraverser {
  field: IntrospectionField
  resource: ResourceTraverser
  constructor(resource: ResourceTraverser, field: IntrospectionField) {
    this.resource = resource
    this.field = field
  }

  get name() {
    return this.field.name
  }

  get isInternal() {
    return 'nodeId' === this.name
  }

  get isHiddenByDefaultInList() {
    // this only hide, but can be turned on in setting
    return [
      'createdById',
      'lastModifiedById',
      //   'lastModifiedDate', // take back in on ARD request
      // 'createdDate', // is default sort, should be shown
      //   'id', // showing id is useful sometime so we take it back in
    ].includes(this.name)
  }

  get isObject() {
    const arrayOfObjectName = this.resource.fields
      .filter((field) => {
        return field.field.type.kind === 'OBJECT'
      })
      .map(({ field: { name } }) => name)
    // e.g. videoSource
    return [...arrayOfObjectName].includes(this.name)
  }

  get isFunctionalAndNonRelational() {
    const arrayOfFunctionalObjectName = this.resource.fields
      .filter((field) => {
        return (
          // kind: NON_NULL is from relational -> the Id's
          field.field.args.length > 0 && field.field.type.kind !== 'NON_NULL'
        )
      })
      .map(({ field: { name } }) => name)
    // e.g. isActiveInternal
    return [...arrayOfFunctionalObjectName].includes(this.name)
  }

  get isRelationship() {
    // field for which exist another field with the same name + Id behind
    const allFieldsName = this.resource.fields.map((field) => {
      return field.name
    })
    const arrayOfRelationshipField = this.resource.fields
      .filter((field) => {
        return field.field.type.kind === 'OBJECT'
      })
      .filter((field) => {
        return allFieldsName.includes(field.name + 'Id')
      })
      .map(({ field: { name } }) => name)
    return [...arrayOfRelationshipField].includes(this.name)
  }

  get isHiddenInList() {
    // do not get render in list
    return (
      ['availableFrom', 'availableUntil'].includes(this.name) ||
      this.isInternal ||
      this.isFunctionalAndNonRelational ||
      this.isObject ||
      this.hasArguments
    )
  }

  get isHiddenOnShow() {
    return (
      ['availableFrom', 'availableUntil'].includes(this.name) ||
      this.isInternal ||
      this.isFunctionalAndNonRelational ||
      this.hasArguments ||
      this.isRelationship
    )
  }

  get isHiddenOnEdit() {
    return (
      ['availableFrom', 'availableUntil'].includes(this.name) || this.isInternal
    )
  }

  get isEditable() {
    const { name } = this
    return (
      !fieldIsNotWriteable(name) &&
      this.resource.editableFields.find(
        ({ name: editableFieldName }) => editableFieldName === name
      )
    )
  }

  get hasArguments() {
    return this.field.args?.length > 0
  }

  get isNullable() {
    return this.field.type.kind !== 'NON_NULL'
  }

  get isUnique() {
    let isUnique = false
    if (
      this.field.description &&
      this.field.description.startsWith('§unique')
    ) {
      isUnique = true
    }
    return isUnique
  }

  get filterDefinitions() {
    return this.name
  }

  // for fields that are marked as NON_NULL, it seems we have to get the actual kind in a different way
  // this is based on a single example, no idea if we can simply generalize this?
  get valueKind():
    | IntrospectionType['kind']
    | IntrospectionListTypeRef['kind'] {
    return this.field.type.kind === 'NON_NULL'
      ? this.field.type.ofType.kind
      : this.field.type.kind
  }

  get nullable(): boolean {
    return this.field.type.kind !== 'NON_NULL'
  }

  get isScalar() {
    return this.valueKind === 'SCALAR'
  }

  get isList() {
    return this.valueKind === 'LIST'
  }

  get isEnum() {
    return this.valueKind === 'ENUM'
  }

  get enumTypeName() {
    const { type } = this.field
    if (!this.isEnum) return null

    if (type.kind === 'ENUM') {
      return type.name
    } else if (
      type.kind === 'NON_NULL' &&
      type.ofType &&
      type.ofType.kind === 'ENUM'
    ) {
      return type.ofType.name
    }
    return null
  }

  get enumValues() {
    const { enumTypeName } = this
    if (!enumTypeName) {
      throw new Error(
        'Trying to acces enumValues for a field which is not an enum'
      )
    }
    const enumType = this.resource.schema._typeByName[enumTypeName]
    if (!enumType) {
      throw new Error(
        `Field "${this.name}" is enum, but the referenced type ${enumTypeName} is missing!`
      )
    }
    if (enumType.kind === 'ENUM') {
      return enumType.enumValues
    } else {
      throw new Error(
        `Field "${this.name}" is enum, but the referenced type ${enumTypeName} is not!`
      )
    }
  }

  get typeName(): string {
    const { type } = this.field

    return getTypeName(type)
  }

  get referenceTypeName(): string | null {
    const { typeName, name } = this
    if (typeName === 'Guid') {
      const info = this.resource.relationshipFields.find(
        (f) => f.fieldName === name
      )
      if (!info) {
        return null
      }
      const referenceField = this.resource.getFieldByName(info.relatedFieldName)
      if (!referenceField) {
        return null
      }
      return referenceField.typeName
    } else {
      return null
    }
  }

  get getManyReferenceType() {
    const data = getOneToManyRelationshipFieldData(this)
    if (!data) {
      return null
    }
    const externalResource = this.resource.schema.getResourceTraverserByName(
      data.relatedType
    )

    if (!externalResource) {
      // this warning is wrong/not needed anymore
      // console.error(
      //   'getManyReferenceFieldData encountered a related type in field %s that is not a resource (%s)! Skipping!',
      //   this.name,
      //   data.relatedType
      // )
      return null
    }
    return {
      ...data,
      referenceType: externalResource,
    }
  }
}

memoizeGetters(FieldTraverser)

interface FieldByName {
  [name: string]: FieldTraverser
}

function isInternalResource(name: string) {
  return name === 'App'
}

export type FieldFilterInfo = {
  name: string
  alias?: string
  fullAlias?: string
  field: FieldTraverser
  type: string
  /** if this filter relates to a many-to-many relationship, this object is supplied */
  oneToManyParent?: {
    /** an internal name for the filter, like "category.slug". This is a human readable name that might not exactly match the internal structure */
    alias: string
    // filterField: FieldTraverser
    /** the field traverser for the field in the filter that links to a some/every/none filter */
    parentField: FieldTraverser
  }
  template: string | null
  operations: {
    operationName: string
    label: string
  }[]
}

export class ResourceTraverser {
  schema: SchemaTraverser
  resource: Resource

  constructor(schema: SchemaTraverser, resource: Resource) {
    this.schema = schema
    this.resource = resource
  }

  get name() {
    return nameFromResource(this.resource)
  }

  get isInternal() {
    return isInternalResource(this.name)
  }

  get fieldsByName(): FieldByName {
    const { fields } = this
    const cache: FieldByName = {}
    fields.forEach((field) => {
      cache[field.name] = field
    })
    return cache
  }

  get fields(): ReadonlyArray<FieldTraverser> {
    const {
      resource: {
        type: { fields },
      },
    } = this
    return (fields || []).map((field) => new FieldTraverser(this, field))
  }

  get relationshipFields() {
    const { type } = this.resource
    return getRelationshipFieldsForType(type as IntrospectionObjectType)
  }

  get oneToManyFields() {
    const { fields } = this
    return fields.filter(({ getManyReferenceType }) => !!getManyReferenceType)
  }

  get description() {
    const {
      type: { description },
    } = this.resource
    return description
  }

  getFieldByName(fieldName: string): FieldTraverser | null {
    return this.fieldsByName[fieldName] || null
  }

  get editableFields() {
    const { schema, name } = this
    const updateType = schema._typeByName[`${name}Patch`] // TODO: move to external file
    if (updateType && updateType.kind === 'INPUT_OBJECT') {
      const { inputFields } = updateType
      const patchFields = inputFields
        .filter(({ name }) => !fieldIsNotWriteable(name))
        .map((field) => {
          const {
            type: { kind },
            type,
          } = field

          if (
            (kind !== 'SCALAR' &&
              kind !== 'ENUM' &&
              kind !== 'INPUT_OBJECT' &&
              kind !== 'LIST') ||
            !('name' in type)
          ) {
            return null
          }

          // String List
          if (kind === 'LIST') {
            if (
              // @ts-ignore
              type.ofType.kind === 'SCALAR' &&
              // @ts-ignore
              type.ofType.name === 'String'
            ) {
              return {
                name: field.name,
                // @ts-ignore
                type: '[' + type.ofType.name + ']',
                description: field.description,
              }
            }
          }

          return {
            // field,
            name: field.name,
            type: type.name,
            description: field.description,
          }
        })
        .filter(filterNotEmpty)
      return patchFields
    }
    return []
  }

  get filterType() {
    return this.schema._typeByName[getFilterType(this.name)]
  }

  get filterableFields(): FieldFilterInfo[] {
    const { schema } = this
    const updateType = this.filterType // TODO: move to external file
    if (updateType && updateType.kind === 'INPUT_OBJECT') {
      const { inputFields } = updateType

      const filterableFields = inputFields
        .filter(({ name }) => !['not', 'and', 'or'].includes(name))
        .map((field) => {
          const { name, type } = field
          // TODO: support list?

          if (type.kind !== 'INPUT_OBJECT') {
            return null
          }
          const FilterType = schema._typeByName[type.name]
          if (!FilterType || FilterType.kind !== 'INPUT_OBJECT') {
            throw new Error(
              `Cannot find valid INPUT_OBJECT filter type ${type.name}`
            )
          }
          const fieldTraverser = this.fieldsByName[name]
          if (!fieldTraverser) {
            return null
          }

          const filterTypeName = getTypeFromFilterType(type.name)
          const operations = FilterType.inputFields.filter((operation) => {
            if (operation.name === 'isNull') {
              const { isNullable } = fieldTraverser
              return isNullable
            }
            if (
              !isFieldOperationAllowed(
                filterTypeName,
                operation.name as FilterOperation
              )
            ) {
              return null
            }
            return true
          })

          return {
            // field,
            name: field.name,
            field: fieldTraverser,
            type: filterTypeName,
            template: null,
            operations: operations.map(({ name: operationName }) => {
              let label = ''

              switch (operationName) {
                case 'equalTo': {
                  label = '='
                  break
                }
                case 'notEqualTo': {
                  label = '≠'
                  break
                }
                case 'lessThan': {
                  label = '<'
                  break
                }
                case 'lessThanOrEqualTo': {
                  label = '≤'
                  break
                }
                case 'greaterThan': {
                  label = '>'
                  break
                }
                case 'greaterThanOrEqualTo': {
                  label = '≥'
                  break
                }
                case 'isNull': {
                  label = 'is missing'
                  break
                }
                case 'like': {
                  label = 'SQL like (% = wildcard)'
                  break
                }
                case 'likeInsensitive': {
                  label = 'SQL like (% = wildcard, ignore case)'
                  break
                }
                default: {
                  label = snakeCase(operationName).replace(/_/g, ' ')
                  if (/insensitive/i.test(label)) {
                    label = label.replace(/insensitive/i, '') + ' (ignore case)'
                  }
                }
              }

              return {
                operationName,
                label,
              }
            }),
          }
        })
        .filter(filterNotEmpty)

      return filterableFields
    }
    return []
  }

  isFilterableRelationshipField = (f: IntrospectionInputValue) => {
    const { type } = f
    if (type.kind !== 'INPUT_OBJECT') return false
    const argumentType = this.schema._filterTypeByName[type.name]
    if (argumentType?.kind != 'INPUT_OBJECT') return false
    const some = argumentType.inputFields.find((f) => f.name == 'some')
    const every = argumentType.inputFields.find((f) => f.name == 'every')
    const none = argumentType.inputFields.find((f) => f.name == 'none')
    if (!!some && !!every && !!none) {
      return true
    }
    return false
  }

  get filterableManyToOneOrManyFields() {
    const { schema, name: resourceName } = this
    const filterType = this.schema._typeByName[getFilterType(resourceName)]

    if (filterType && filterType.kind === 'INPUT_OBJECT') {
      return filterType.inputFields.filter(this.isFilterableRelationshipField)
    } else {
      return []
    }
  }

  get typeDisplaySettings() {
    return TypeDisplaySetting[this.name]
  }

  getAliasForFilterType(fieldName: string) {
    let [type, nameOfForeignField] = fieldName.split('By')
    let label = fieldName
    if (
      nameOfForeignField &&
      !this.filterableManyToOneOrManyFields.find(
        (f) => f.name !== fieldName && f.name.startsWith(type)
      )
    ) {
      label = type
    }
    label =
      label
        .replace('Sery', 'Series')
        .replace(/^cms/, '')
        .replace(/^MovieContentCategories/, 'categoryConnection') // CmsPerson will have the same name, which is a problem
        .replace(/^MovieContentPeople/, 'personConnection') // CmsPerson will have the same name, which is a problem
        .replace(/^movieContent/i, '')
        .replace(/Id$/, '') || fieldName

    return lowerFirst(singularize(label))
  }

  get filterableManyToOneOrManyFieldDescriptors() {
    const combineAlias = (typeAliasOrName: string, fieldAliasOrName: string) =>
      typeAliasOrName + '.' + fieldAliasOrName
    const oneToManyFields = this.filterableManyToOneOrManyFields
      .map((field, index, all) => {
        let filterableSubfields: FieldFilterInfo[] = []

        if (field.type.kind === 'INPUT_OBJECT') {
          const subFieldTraverser = this.getFieldByName(field.name)
          const relationshipType = subFieldTraverser?.getManyReferenceType

          if (!relationshipType) return null

          const filterForSubfields = this.schema._filterTypeByName[
            getFilterType(relationshipType.referenceType.name)
          ]

          const typeAlias = this.getAliasForFilterType(field.name)

          if (filterForSubfields?.kind === 'INPUT_OBJECT') {
            filterableSubfields = relationshipType.referenceType.filterableFields.map(
              (f) => {
                const fieldAlias = f.alias ?? f.name
                const info: FieldFilterInfo = {
                  ...f,
                  fullAlias: typeAlias
                    ? combineAlias(typeAlias, fieldAlias)
                    : fieldAlias,
                  oneToManyParent: {
                    alias: typeAlias,
                    // filterField: field,
                    parentField: subFieldTraverser!,
                  },
                }
                return info
              }
            )
            // filterableSubfields = filterForSubfields.inputFields.filter((f) => {
            //   debugger
            //   // TODO: filter out "Exists" fields?
            //   return (
            //     !this.isFilterableRelationshipField(f) &&
            //     !['and', 'or', 'not'].includes(f.name)
            //   )
            // })
          }

          // TODO: remove fields that are hidden in the TypeSettings
          const fieldTraverser = this.getFieldByName(field.name)
          if (!fieldTraverser) {
            throw new Error('Could not find field traverser for ' + field.name)
          }
          return {
            alias: typeAlias,
            field: fieldTraverser,
            relationshipType,
            filterableSubfields,
            template: null as string | null,
          }
        } else {
          return null
        }
      })
      .filter(filterNotEmpty)

    type RelationshipDefinition = typeof oneToManyFields[0]

    const manyToManyFilterOverride = this.typeDisplaySettings?.filter
      ?.manyToManyRelations

    if (manyToManyFilterOverride) {
      const overrides = manyToManyFilterOverride
        .map(({ alias, filterPath, filterTemplate }) => {
          // the path represents the
          const pathsLeft = [...filterPath]
          let current = this
          let currentFilterType = this.schema._filterTypeByName[
            getFilterType(this.name)
          ]
          let field: IntrospectionInputValue
          let filterType
          let currentFieldName: string

          do {
            currentFieldName = pathsLeft.shift()!
            if (currentFilterType?.kind !== 'INPUT_OBJECT') {
              throw new Error(
                `${
                  this.name
                }: the provided filter from the TypeDisplaySettings path is not possible: ${filterPath.join(
                  ', '
                )}`
              )
            }
            field = currentFilterType.inputFields.find(
              (f) => f.name === currentFieldName
            )!

            if (!field) {
              throw new Error(
                `${
                  this.name
                }: the provided field from the TypeDisplaySettings could not be found: ${currentFieldName} from ${filterPath.join(
                  ', '
                )}`
              )
            }

            if ('name' in field.type) {
              currentFilterType = this.schema._filterTypeByName[field.type.name]
            } else {
              const t = field.type.ofType
              currentFilterType = this.schema._filterTypeByName[t.name]
            }
          } while (pathsLeft.length > 0)

          if (currentFilterType && currentFilterType.kind === 'INPUT_OBJECT') {
            const targetTypeName = getTypeFromFilterType(currentFilterType.name)
            const targetType = this.schema.getResourceTraverserByName(
              targetTypeName
            )!
            if (!targetType) {
              throw new Error(`Target type not found - ${targetTypeName}`)
            }
            const parentField = targetType.fieldsByName[currentFieldName]
            const res: RelationshipDefinition = {
              alias,
              field: parentField,
              filterableSubfields: targetType.filterableFields
                .filter((f) => !f.oneToManyParent)
                .map((f) => ({
                  ...f,
                  fullAlias: combineAlias(alias, f.alias ?? f.name),
                  template: filterTemplate,
                })),
              relationshipType: {
                referenceType: targetType,
                relatedType: targetType.name,
                relatedField: currentFieldName,
              },
              template: filterTemplate,
            }

            return res
          } else {
            return null
          }
        })
        .filter(filterNotEmpty)
      oneToManyFields.push(...overrides)
    }

    return oneToManyFields
  }
}
memoizeGetters(ResourceTraverser)

interface ResourceByType {
  [name: string]: Resource | undefined
}

interface TypeByName {
  [name: string]: IntrospectionType | undefined
}

let schemaVersion = 0
export class SchemaTraverser {
  schema: IntrospectionSchema
  resources: Resource[]
  version: number

  constructor(schema: IntrospectionSchema, resources: Resource[]) {
    this.schema = schema
    this.resources = resources
    this.version = schemaVersion++
    // this.getResourceTraverserByName = memoize(this.getResourceTraverserByName);
  }

  get objects() {
    return this.schema.types.filter((type) => type.kind === 'OBJECT')
  }

  //   get resourceTraversers(): ResourceTraverser[] {
  //     return this.resources.map(res => new ResourceTraverser(this.schema, res));
  //   }

  get resourceNames(): string[] {
    return this.resources
      .map(nameFromResource)
      .filter((name) => !isInternalResource(name))
  }

  get _typeByName(): TypeByName {
    const cache: TypeByName = {}
    this.schema.types.forEach((type) => {
      cache[type.name] = type
    })
    return cache
  }

  /**
   * {
   *  "SomeFilter": {...}
   * }
   */
  get _filterTypeByName(): TypeByName {
    const cache: TypeByName = {}
    this.schema.types.forEach((type) => {
      if (type.name.endsWith('Filter')) {
        cache[type.name] = type
      }
    })
    return cache
  }

  get _resourceTypeByName(): ResourceByType {
    const cache: ResourceByType = {}
    this.resources.forEach((resource) => {
      cache[nameFromResource(resource)] = resource
    })
    return cache
  }

  // memoized!
  getResourceTraverserByName = memoize(
    (resourceName: string): ResourceTraverser | null => {
      const resource = this._resourceTypeByName[resourceName]
      if (!resource) return null
      return new ResourceTraverser(this, resource)
    }
  )
}

memoizeGetters(SchemaTraverser)

export type FilterableManyToManyFieldDescriptor = InstanceType<
  typeof ResourceTraverser
>['filterableManyToOneOrManyFieldDescriptors'][0]
