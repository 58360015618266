import { Card, CardContent, CircularProgress } from '@material-ui/core'
import { subDays } from 'date-fns'
// import { Query } from 'react-apollo';
import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React, { useMemo } from 'react'
import { useQuery } from 'react-apollo-hooks'

function KeyPerformanceIndicatorImpl({
  label,
  description,
  query,
  numberOfDays = 30,
}) {
  const queryGql = useMemo(() => gql(query), [query])
  const apolloSettings = useMemo(
    () => ({
      notifyOnNetworkStatusChange: true,
      variables: {
        fromDate: subDays(new Date(), numberOfDays).toISOString(),
      },
    }),
    [numberOfDays]
  )

  function renderContent({ loading, error, data }) {
    if (loading)
      return (
        <div
          style={{
            width: 85,
            height: 100,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress />
        </div>
      )
    if (error) return `Error! ${error.message}`

    return (
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          textAlign: 'center',
        }}
      >
        <strong style={{ fontSize: '2em' }}>{data.kpiData.kpi}</strong>
        <div>
          {label}
          <p>{description}</p>
        </div>
      </div>
    )
  }

  const apolloCtx = useQuery(queryGql, apolloSettings)
  return (
    <Card controls={false}>
      <CardContent>{renderContent(apolloCtx)}</CardContent>
    </Card>
  )
}

export const KeyPerformanceIndicator = React.memo(KeyPerformanceIndicatorImpl)

KeyPerformanceIndicator.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  query: PropTypes.string.isRequired,
  variables: PropTypes.object,
  numberOfDays: PropTypes.number,
}
