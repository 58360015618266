import logo from '../../imgs/logos/leonine.png'
import { CustomerTheme } from './customerThemeType'

export const UniversumTheme: Partial<CustomerTheme> = {
  primary: {
    main: '#000000',
    light: '#ac8353',
    dark: '#0099cc',
  },
  secondary: {
    main: '#ac8353',
  },
}

export const UniversumLogo = (
  <img
    style={{
      width: 100,
      height: 'auto',
      paddingLeft: 25,
    }}
    src={logo}
  />
)
