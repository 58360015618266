import MenuItem from '@material-ui/core/MenuItem'
import Switch from '@material-ui/core/Switch'
import TextField from '@material-ui/core/TextField'
import { Autocomplete } from '@material-ui/lab'
import { DebouncedFunc } from 'lodash'
import debounce from 'lodash/debounce'
import { Translate, useTranslate } from 'ra-core'
import React, { useEffect, useMemo } from 'react'
import { FieldFilterInfo } from '../../dataProvider/introspections/SchemaTraverser'
import { Filter, FilterState } from '../CustomFilterForm'
import { JsonInput } from '../customInputs/JsonInput'
import { IsJsonString } from './ObjectArrayJsonFields'

type FilterInputFieldProps = {
  filter: FieldFilterInfo
  filterOpName: string
  onChange: (filter: FilterState) => void
  variant: string
  currentFilter: Partial<Filter>
}

export function renderFilterFieldIfPossible(
  props: FilterInputFieldProps & {
    onDebouncedChange: DebouncedFunc<
      (input: string, type: 'string' | 'float') => void
    >
    translate: Translate
    inputRef?: React.RefObject<any>
  }
) {
  const {
    filter,
    filterOpName,
    onChange,
    currentFilter,
    inputRef,
    translate,
    onDebouncedChange,
  } = props
  const { type, field, operations, name, fullAlias } = filter

  let defaultValue: any
  if (
    (fullAlias ?? name) === currentFilter.fieldName &&
    filterOpName === currentFilter.filterOp
  ) {
    defaultValue = currentFilter.value
  }

  const operation = operations.find((op) => op.operationName === filterOpName)

  if (operation) {
    if (operation.operationName === 'isNull') {
      // return <NullableBooleanInput {...commonProps} />
      return (
        <TextField
          id={'filter-isNull-input'}
          label="Filter"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            const isTrueSet =
              event.target.value.length > 0
                ? event.target.value === 'true'
                  ? true
                  : false
                : null
            onChange({
              value: isTrueSet,
            })
          }}
          defaultValue={defaultValue === undefined ? '' : defaultValue}
          select
          autoFocus
          inputRef={inputRef}
        >
          {/* <MenuItem value="">{translate('ra.boolean.null')}</MenuItem> */}
          <MenuItem value="false">{translate('ra.boolean.false')}</MenuItem>
          <MenuItem value="true">{translate('ra.boolean.true')}</MenuItem>
        </TextField>
      )
    }
    if (field.isEnum) {
      return (
        <Autocomplete<string>
          key={field.name}
          options={field.enumValues
            .map((v) => v.name)
            .sort((a, b) => a.localeCompare(b))}
          defaultValue={defaultValue ?? null}
          onChange={(_ev, value) => {
            onChange({
              value: value ?? defaultValue,
            })
          }}
          renderInput={(params) => (
            <TextField label="Value" {...params} style={{ minWidth: 200 }} />
          )}
        />
      )
    }
    let assumedType =
      operation.operationName === 'containsKey' && type === 'JSON'
        ? 'String'
        : type
    switch (assumedType) {
      case 'Boolean': {
        if (field.isNullable) {
          // return <NullableBooleanInput {...commonProps} />
          return (
            <TextField
              id={'filter-' + operation.operationName + '-input'}
              label="Filter"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                const isTrueSet =
                  event.target.value.length > 0
                    ? event.target.value === 'true'
                      ? true
                      : false
                    : null
                onChange({
                  value: isTrueSet,
                })
              }}
              defaultValue={defaultValue === undefined ? '' : defaultValue}
              select
              autoFocus
              inputRef={inputRef}
            >
              <MenuItem value="">{translate('ra.boolean.null')}</MenuItem>
              <MenuItem value="false">{translate('ra.boolean.false')}</MenuItem>
              <MenuItem value="true">{translate('ra.boolean.true')}</MenuItem>
            </TextField>
          )
        } else {
          return (
            <Switch
              checked={defaultValue}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                onChange({
                  value: event.target.checked as boolean,
                })
              }}
              color="primary"
            />
          )
        }
      }
      case 'Guid':
      case 'StringList':
      case 'String': {
        // return <TextInput {...commonProps}  />
        return (
          <TextField
            id={'filter-' + operation.operationName + '-input'}
            label="Filter Text Input"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onDebouncedChange(event.target.value as string, 'string')
            }}
            // TODO: does not support URL-only updates that keep the same operation and field
            defaultValue={defaultValue === undefined ? '' : defaultValue} // this seem to be ignored after the first render althought the id changes
            autoFocus
            inputRef={inputRef}
          />
        )
      }
      case 'JSON': {
        // the containsKey Operation is handled just like a "String" (see assumedType above)
        // return <TextInput {...commonProps}  />
        return (
          <JsonInput
            // @ts-ignore
            id={'filter-' + operation.operationName + '-input'}
            name="json"
            label="Json Input (must be valid)"
            record={{
              json: defaultValue ?? '',
            }}
            // @ts-ignore
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const value = event.target.value as string
              if (IsJsonString(value)) {
                onDebouncedChange(value, 'string')
              }
            }}
            // // TODO: does not support URL-only updates that keep the same operation and field
            defaultValue={defaultValue === undefined ? '' : defaultValue} // this seem to be ignored after the first render althought the id changes
            autoFocus
            inputRef={inputRef}
          />
        )
      }
      case 'Int':
      case 'Float':
      case 'BigFloat': {
        // return <NumberInput {...commonProps} />
        return (
          <TextField
            id={'filter-' + operation.operationName + '-input'}
            label="Filter Number Input"
            type="number"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onDebouncedChange(event.target.value as string, 'float')
            }}
            defaultValue={defaultValue === undefined ? 0 : defaultValue} // this seem to be ignored after the first render althought the id changes
            autoFocus
            inputRef={inputRef}
          />
        )
      }
      case 'Date':
      case 'Datetime': {
        // return <DateTimeInput {...commonProps} />
        return (
          <TextField
            id={'filter-' + operation.operationName + '-input'}
            label="Filter Date Input"
            type="datetime-local"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              onDebouncedChange(event.target.value as string, 'string')
            }}
            defaultValue={defaultValue}
            InputLabelProps={{
              shrink: true,
            }}
            autoFocus
            inputRef={inputRef}
          />
        )
      }
      case 'JSON': {
        return null // not supported!
      }
      default: {
        console.error(`Unsupported Filter type ${type}`)
        return null
      }
    }
  }
  return null
}

export const FilterInputField = React.memo((props: FilterInputFieldProps) => {
  const { filter, onChange } = props
  const inputRef = React.useRef()
  const translate = useTranslate()

  // fix for autofocus found online https://github.com/mui-org/material-ui/issues/1594#issuecomment-272547735
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (inputRef && inputRef.current) {
        // @ts-ignore
        inputRef.current.focus()
      }
    }, 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  // delayed firing of signal by x ms for input that require tipping
  const changeHandlerDebounce = useMemo(
    () =>
      debounce(
        (input: string, type: string) => {
          if (type === 'string') {
            onChange({
              value: input,
            })
          }
          if (type === 'float' && !isNaN(parseFloat(input))) {
            onChange({
              value: parseFloat(input),
            })
          }
        },
        300 // increase for greater delay after input; in ms
      ),
    [onChange]
  )

  return renderFilterFieldIfPossible({
    ...props,
    translate,
    inputRef,
    onDebouncedChange: changeHandlerDebounce,
  })
})
