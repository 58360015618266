import {
  Chip,
  Fade,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'
import { InfoOutlined } from '@material-ui/icons'
import ContentFilter from '@material-ui/icons/FilterList'
import SettingsIcon from '@material-ui/icons/Settings'
import { motion } from 'framer-motion'
import { showNotification as showNotificationAction } from 'ra-core'
import { IconButtonWithTooltip } from 'ra-ui-materialui'
import Button from 'ra-ui-materialui/esm/button/Button'
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import { connect } from 'react-redux'
import { GraphQLFilter, equalToSearchFilter } from '../dataProvider/buildFilter'
import { getSchemaTraverser } from '../dataProvider/buildQuery'
import {
  FieldFilterInfo,
  FilterableManyToManyFieldDescriptor,
} from '../dataProvider/introspections/SchemaTraverser'
import { getFilterType } from '../dataProvider/introspections/getList'
import LocalStorage, {
  SEARCH_CONFIGURATION_KEY,
} from '../datagrid/LocalStorage'
import { SelectionDialog } from '../datagrid/SelectionDialog'
import { filterNotEmpty } from '../lib/filterNonEmpty'
import { CustomFilterForm, Filter, FilterState } from './CustomFilterForm'
import { smplTexts } from './CustomTextMappings'
import { FilterPreset } from './filterBuilder/filterPresetDefinitions'
import {
  revertNormalization,
  sanatizeAndNormalizeFilterToAnd,
} from './filterBuilder/normalizeFilter'

const useStyles = makeStyles(() => ({
  formAlwaysOn: {
    // marginBottom: -8,
  },
  form: {
    minHeight: 0,
    marginTop: 5,
  },
  filterWrapper: {
    marginBottom: 10,
    maxWidth: '70%',
  },
  alwaysOnFilterAndAddButton: {
    display: 'flex',
  },
  hiddenFilter: {
    display: 'grid',
  },
  formControl: {
    marginTop: 8,
    minWidth: 150,
    float: 'left',
  },
  formInput: {
    margin: 2,
    minWidth: 150,
    float: 'left',
    '& > div > div': {
      marginTop: 3,
      marginBottom: -10,
    },
  },
  chip: {
    margin: '5px',
  },
  preFilterSelect: {
    marginTop: 10,
    marginLeft: 10,
  },
  actionButton: {
    padding: '0.5rem 1rem',
    maxHeight: 40.5,
    background: '#cdcdcd',
    marginTop: 8,
    marginLeft: 10,
  },
}))

export const SEARCH_FIELD = '__search'
export const SEARCH_OPERATION = 'matches'

export const FAKE_PRESET_FILTER_FIELD = '__preset'
export const FAKE_PRESET_FILTER_OPERATION = 'matches'

type InternalSpecialFilters = {
  [SEARCH_FIELD]?: { [SEARCH_OPERATION]: string }
  [FAKE_PRESET_FILTER_FIELD]?: { [FAKE_PRESET_FILTER_OPERATION]: string }
}

type CustomFilterProps = {
  children: ReactNode
  className?: string
  classes?: {
    form?: string
    button?: string
  }
  context?: 'form' | 'button'
  displayedFilters?: Object
  forcedFilters?: GraphQLFilter
  filterValues?: GraphQLFilter & InternalSpecialFilters
  hideFilter: (filterInternal: string) => void
  setFilters: (
    filters: { [key in string]: any },
    filterStates: Partial<Filter>[]
  ) => void
  setDisableSaveButton?: (flag: boolean) => void
  showFilter: (...args: any[]) => any
  resource: string

  activeFilterPresets?: FilterPreset[]
  possibleFilterPresets?: FilterPreset[]
  selectFilterPreset?: (preset: FilterPreset | null) => void
  disableSearchField?: boolean
}

type AdditionalFilterFormProps = {
  variant?: string
  filterableFields: FieldFilterInfo[]
  relatedFilterableFields: FilterableManyToManyFieldDescriptor[]
}

export const CustomFilter = connect(null, {
  showNotification: showNotificationAction,
})(function CustomFilter(props: CustomFilterProps & AdditionalFilterFormProps) {
  return props.context === 'form' ? RenderFilterButtonAndForm(props) : null
})

export type RenderFilterButtonAndFormProps = CustomFilterProps &
  AdditionalFilterFormProps

type LocalFilterState = FilterState & {
  key: string
  prefilter?: boolean
}

/***
 * INFO:
 * PLEASE NOTE THAT WE DO NOT SUPPORT EXTERNAL CHANGES TO `filterValues`.
 * We assume full controll
 */
const RenderFilterButtonAndForm = (
  props: RenderFilterButtonAndFormProps & {
    showNotification?: typeof showNotificationAction
  }
) => {
  const classes = useStyles()
  const {
    displayedFilters = {},
    filterValues = {},
    showNotification,
    showFilter,
    classes: classesOverride,
    className,
    resource,
    hideFilter,
    children,
    variant,
    filterableFields,
    relatedFilterableFields,
    setFilters,
    forcedFilters,
    possibleFilterPresets,
    activeFilterPresets,
    selectFilterPreset,
    disableSearchField = false,
    setDisableSaveButton,
    ...rest
  } = props

  useEffect(() => {
    const keys = Object.keys(filterValues || null)
    if (keys.length > 0 && keys.every((f) => f.includes('%5B'))) {
      showNotification!(
        'The Filter functionality was redesigned, making old bookmarks or links to a filtered page invalid. Please reconfigure your filter manually and update the bookmarks.\nWe are very sorry for the inconvience',
        'error'
      )
    }
  }, [JSON.stringify(filterValues)])

  const passedFilterElements = React.Children.toArray(children)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [configModalOpen, setConfigModalOpen] = useState<boolean>(false)
  const [configSelection, setConfigSelection] = useState<
    Record<string, boolean> | undefined
  >()
  const [searchValue, setSearchValue] = useState(
    filterValues[SEARCH_FIELD]?.[SEARCH_OPERATION] as string | undefined
  )
  // const { filterPresets } = useParams<{ filterPresets: string }>()

  const traverser = getSchemaTraverser()
  if (!traverser) {
    throw new Error('No SchemaTraverser')
  }

  const resourceTraverser = traverser.getResourceTraverserByName(resource)
  const { resource: res } = resourceTraverser || {}
  if (!res || !resourceTraverser) {
    throw new Error('Ressource not found')
  }

  // local filter state to manage the rendering of the filters
  // init on first call with existing filter based on values coming from RA
  // TODO: remove copied state
  // TODO: new filters disappear
  const [localFilterValues, setLocalFilterValues] = useState(
    sanatizeAndNormalizeFilterToAnd(filterValues, resourceTraverser!)
  )

  useEffect(() => {
    if (!resourceTraverser) return
    // FIXME: add partial filter states to the ?filter=... query param too!

    // this fix is not enough, it will duplicate items when switching the operation
    // const valuesToKeep = localFilterValues.filter(
    //   (f) => f.value === undefined || f.filterOp == undefined
    // )
    const normalized = sanatizeAndNormalizeFilterToAnd(
      filterValues,
      resourceTraverser!
    )
    console.log('updating localFilterValues', normalized)
    setLocalFilterValues(normalized) // .concat(valuesToKeep)
  }, [JSON.stringify(filterValues), resourceTraverser])

  const setFiltersSupportInternal = useCallback(
    function setFiltersSupportInternal(
      localFilterValues: Partial<Filter>[],
      presetObjects?: InternalSpecialFilters
    ) {
      // if the state of the form changes, we update the URL, but only if it needs to change
      const newFilterValues = revertNormalization(localFilterValues)
      const normalized = sanatizeAndNormalizeFilterToAnd(
        newFilterValues,
        resourceTraverser!
      )
      const internalFilters: InternalSpecialFilters = presetObjects || {}
      if (!presetObjects) {
        // keep the values by default
        if (filterValues[FAKE_PRESET_FILTER_FIELD]) {
          internalFilters[FAKE_PRESET_FILTER_FIELD] =
            filterValues[FAKE_PRESET_FILTER_FIELD]
        }
        if (filterValues[SEARCH_FIELD]) {
          internalFilters[SEARCH_FIELD] = filterValues[SEARCH_FIELD]
        }
      }

      const res = { ...newFilterValues, ...presetObjects }
      for (const key of Object.keys(res)) {
        // @ts-ignore
        if (res[key] === undefined) {
          // @ts-ignore
          delete res[key]
        }
      }
      // only update if necessary
      const { and, ...rest } = res
      if (
        JSON.stringify(res) != JSON.stringify(filterValues) &&
        // the normalization is not perfect
        !(
          and.length == 0 &&
          JSON.stringify(filterValues) === JSON.stringify(rest)
        )
      ) {
        /** FIXME: this has a critical race condition:
         * when the filter operation is changed, the applied filter might change to an empty filter
         * In that case it can happen that the filters will get completely removed.
         * It seems that the
         *  */
        const i = setTimeout(() => {
          setFilters(res, localFilterValues)
        }, 1000)
        return () => clearTimeout(i)
      }
    },
    [filterValues, setFilters]
  )

  useEffect(() => {
    setFiltersSupportInternal(localFilterValues, {
      [FAKE_PRESET_FILTER_FIELD]: filterValues[FAKE_PRESET_FILTER_FIELD],
      [SEARCH_FIELD]: filterValues[SEARCH_FIELD],
    })
  }, [JSON.stringify(localFilterValues)])

  // Using ref to detect changes in local filter state
  // if local state and RA state differs -> change RA
  const filterValuesRef = useRef(filterValues)
  filterValuesRef.current = filterValues

  // Add New Filter
  const handleAddNew = () => {
    if (setDisableSaveButton) {
      setDisableSaveButton(true)
    }
    setLocalFilterValues((prev) => {
      return [...prev, {}]
    })
  }

  // { first: ..., second: ..., and: [{ third:...}]} -> [{ first: ...}, { second: ...}, { third: ...}]

  // console.log('andListNormalizedFilters', andListNormalizedFilters)

  const searchValueRef = useRef<string | undefined>()

  const handleSearchValueChange = (searchTerm: string | undefined) => {
    setFiltersSupportInternal(localFilterValues, {
      [FAKE_PRESET_FILTER_FIELD]: filterValues[FAKE_PRESET_FILTER_FIELD],
      [SEARCH_FIELD]: searchTerm
        ? {
            [SEARCH_OPERATION]: searchTerm,
          }
        : undefined,
    })

    // Update the searchValueRef with the new value
    searchValueRef.current = searchTerm
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (searchValueRef.current !== searchValue) {
        handleSearchValueChange(searchValue)
      }
    }, 300)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [searchValue, handleSearchValueChange])

  const inputObject = traverser._filterTypeByName[getFilterType(resource)]

  const generalSearchableFields =
    inputObject && 'inputFields' in inputObject
      ? inputObject.inputFields
          .map((input) => {
            const filterType = input.type
            if (
              'name' in filterType &&
              filterType.name === 'StringFilter' &&
              !equalToSearchFilter.includes(input.name)
            ) {
              return input.name
            }
            return null
          })
          .filter((input) => input !== null)
          .filter(filterNotEmpty)
      : undefined

  const openConfigurator = () => {
    // check local storage else make default values with generalSearchableFields
    const selectionInStorage = LocalStorage.get(
      SEARCH_CONFIGURATION_KEY,
      resource
    )
    const selection: Record<string, boolean> = selectionInStorage || {}
    const fieldsKey = Object.keys(selection)
    for (const fieldName of generalSearchableFields!) {
      if (!fieldsKey.includes(fieldName)) {
        // default for new field is true
        selection[fieldName] = true
      }
    }
    setConfigSelection(selection)
    setConfigModalOpen(true)
  }

  const toggleColumn = (columnName: string) => {
    const previousSelection =
      LocalStorage.get(SEARCH_CONFIGURATION_KEY, resource) || configSelection
    const selection = {
      ...previousSelection,
      [columnName]: !previousSelection[columnName],
    }
    setConfigSelection(selection)
    // save to localStorage
    LocalStorage.set(SEARCH_CONFIGURATION_KEY, resource, selection)
  }

  const handleClose = () => {
    // save to localStorage
    setConfigModalOpen(false)

    // reset the search input
    setSearchValue(undefined)
  }

  console.log('XXX rendering CustomFilter', localFilterValues)

  return (
    <>
      {configModalOpen && configSelection && (
        <SelectionDialog
          selection={configSelection}
          onColumnClicked={toggleColumn}
          onClose={handleClose}
          resource={resource}
          storage={LocalStorage}
          storageKey={SEARCH_CONFIGURATION_KEY}
          label="Search Configuration"
          sortable={false}
        />
      )}
      <div className={classes.filterWrapper}>
        <div className={classes.alwaysOnFilterAndAddButton}>
          {/* All Search Filter */}
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.5, ease: 'backOut' }}
          >
            <TextField
              label="Search in List"
              style={{ marginLeft: 2 }}
              value={searchValue ?? ''}
              onChange={
                // onSearchChange
                (e) => {
                  setSearchValue(e.target.value)
                }
              }
              InputProps={
                generalSearchableFields
                  ? {
                      endAdornment: (
                        <Tooltip
                          TransitionComponent={Fade}
                          TransitionProps={{ timeout: 500 }}
                          enterDelay={100}
                          title="Configuring Search"
                          placement="top"
                        >
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="Configuring Search"
                              onClick={openConfigurator}
                            >
                              <SettingsIcon />
                            </IconButton>
                          </InputAdornment>
                        </Tooltip>
                      ),
                    }
                  : {}
              }
            >
              {/* <InputLabel>Test</InputLabel> */}
            </TextField>
          </motion.div>

          {(possibleFilterPresets?.length || 0) > 0 ? (
            <>
              <Button
                className="preset-filter"
                label="Preset Filter"
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                  setAnchorEl(event.currentTarget)
                }}
                size="medium"
                startIcon={<ContentFilter />}
              />
              <Menu
                id="preset-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                  setAnchorEl(null)
                }}
              >
                {possibleFilterPresets!.map((fItem) => {
                  return (
                    <MenuItem
                      key={fItem.label}
                      onClick={() => {
                        selectFilterPreset!(fItem)
                        // setFastFilter(fItem)
                        setAnchorEl(null)
                      }}
                    >
                      {fItem.label}
                    </MenuItem>
                  )
                })}
              </Menu>
            </>
          ) : null}

          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.6, ease: 'backOut' }}
          >
            <Tooltip title={smplTexts.datagrid.filter}>
              <Button
                className={classes.actionButton}
                label="Filter"
                onClick={handleAddNew}
                size="medium"
                startIcon={<ContentFilter />}
              />
            </Tooltip>
          </motion.div>
          <motion.div
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 1, delay: 0.7, ease: 'backOut' }}
          >
            <IconButtonWithTooltip
              style={{ marginLeft: 4, marginTop: 4, transform: 'scale(0.8)' }}
              label="Learn more about filters"
              // @ts-ignore
              href="https://simpletechs.notion.site/Filters-Finding-Data-in-the-Dashboard-0dfc9c673c874875ab8e90679e186a6d"
              // @ts-ignore
              target="_blank"
            >
              <InfoOutlined />
            </IconButtonWithTooltip>
          </motion.div>
        </div>

        <div>
          {(activeFilterPresets || []).map((preset) => {
            return (
              <Chip
                key={preset.label}
                label={preset.label}
                color="primary"
                onDelete={() => {
                  selectFilterPreset!(null)
                }}
                className={classes.chip}
              />
            )
          })}
        </div>

        <div>
          {localFilterValues.map((filterState, index, all) => {
            if (filterState.fieldName == SEARCH_FIELD) return null
            // const { key, shouldRender } = filter

            // if (shouldRender === false) {
            //   return null
            // }
            return (
              <div style={{ display: 'flex', marginBottom: 5 }} key={index}>
                <CustomFilterForm
                  resource={resource}
                  filter={filterState}
                  relatedFilterableFields={relatedFilterableFields}
                  filterableFields={filterableFields}
                  onChange={(filterState) => {
                    setLocalFilterValues((oldArray) => {
                      const copy = [...oldArray]
                      copy[index] = { ...copy[index], ...filterState }
                      return copy
                    })
                  }}
                  onRemove={() => {
                    setLocalFilterValues((oldArray) => {
                      // console.log('remove filter with key', key, 'from', oldArray)
                      return oldArray.filter((f, i) => index != i)
                    })
                  }}
                />
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}
