import CircularProgress from '@material-ui/core/CircularProgress'
import Fade from '@material-ui/core/Fade'
import InputAdornment from '@material-ui/core/InputAdornment'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import FalseIcon from '@material-ui/icons/Clear'
import TrueIcon from '@material-ui/icons/Done'
import { useQuery } from 'react-apollo-hooks'
import { IsActiveInternalById } from '../../__generated__/IsActiveInternalById'
import { IsActiveInternalByIdQuery } from '../../graphQL/isActiveInternal'
import { NoData } from './NoData'

const useStyles = makeStyles({
  root: {
    '& > div': {
      color: 'black !important',
    },
  },
})

export const IsActiveFields = (props: any) => {
  const { record, renderType } = props
  const { id } = record
  const classes = useStyles()
  const { loading, data, error } = useQuery<IsActiveInternalById>(
    IsActiveInternalByIdQuery,
    {
      variables: {
        id: id,
      },
    }
  )

  if (error) {
    console.error('Error at query for active internal.')
    return <NoData />
  }

  if (loading) {
    return <CircularProgress size={25} thickness={2} />
  }

  if (
    data &&
    (data.cmsEpisodeById ||
      data.cmsLicenseById ||
      data.cmsMovieById ||
      data.cmsSeasonById ||
      data.cmsSeryById)
  ) {
    const res =
      data.cmsEpisodeById ||
      data.cmsLicenseById ||
      data.cmsMovieById ||
      data.cmsSeasonById ||
      data.cmsSeryById

    if (res) {
      if (renderType === 'show') {
        return (
          <div>
            {Object.keys(res).map((channelWithCountryCode) => {
              if (channelWithCountryCode !== '__typename') {
                // @ts-ignore
                const isActiveOnChannel: boolean = res[channelWithCountryCode]

                const channel = channelWithCountryCode.substring(
                  0,
                  channelWithCountryCode.length - 2
                )
                const countryCode = channelWithCountryCode.substring(
                  channelWithCountryCode.length - 2
                )
                return (
                  <TextField
                    key={channelWithCountryCode}
                    defaultValue={
                      channel.replace(/([a-z0-9])([A-Z])/g, '$1 $2') +
                      ` ${countryCode}`
                    } // HomeOfHorrorDE -> Home Of Horror DE
                    disabled={true}
                    className={classes.root}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          {isActiveOnChannel === true ? (
                            <TrueIcon color="primary" />
                          ) : (
                            <FalseIcon color="error" />
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )
              }
              return null
            })}
          </div>
        )
      }
      return (
        <div style={{ display: 'inline-flex' }}>
          {Object.keys(res).map((channelWithCountryCode) => {
            if (channelWithCountryCode !== '__typename') {
              // @ts-ignore
              const isActiveOnChannel: boolean = res[channelWithCountryCode]
              const channel = channelWithCountryCode.substring(
                0,
                channelWithCountryCode.length - 2
              )
              const countryCode = channelWithCountryCode.substring(
                channelWithCountryCode.length - 2
              )
              // HomeOfHorrorDE -> Home Of Horror DE
              const tooltipsText =
                channel.replace(/([a-z0-9])([A-Z])/g, '$1 $2') +
                ` ${countryCode}`
              return (
                <Typography
                  component="span"
                  variant="body2"
                  key={channelWithCountryCode}
                >
                  <Tooltip
                    title={tooltipsText}
                    placement="top"
                    TransitionComponent={Fade}
                    TransitionProps={{ timeout: 500 }}
                    arrow
                  >
                    {isActiveOnChannel === true ? (
                      <TrueIcon color="primary" />
                    ) : (
                      <FalseIcon color="error" />
                    )}
                  </Tooltip>
                </Typography>
              )
            }
            return null
          })}
        </div>
      )
    }
  }

  // we are not inside a resource with isActiveInternal:
  const isActive = record.isActive

  if (isActive === null || isActive === undefined) {
    return <NoData />
  }

  return (
    <Typography component="span" variant="body2">
      <Tooltip
        title={isActive ? 'Is active' : 'Is not active'}
        placement="top"
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 500 }}
        arrow
      >
        {isActive === true ? (
          <TrueIcon color="primary" />
        ) : (
          <FalseIcon color="error" />
        )}
      </Tooltip>
    </Typography>
  )
}
