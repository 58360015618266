import 'react-app-polyfill/ie11'
import { createRoot } from 'react-dom/client'
import App from './App'
import './index.css'
import { NODE_ENV } from './lib/config'

if (
  NODE_ENV === 'production' &&
  window.location.protocol !== 'https:' &&
  window.location.hostname !== 'localhost'
) {
  location.protocol = 'https:' // eslint-disable-line
} else {
  const rootEl = document.getElementById('root')
  const root = createRoot(rootEl)
  root.render(<App />)

  if (module.hot) {
    module.hot.accept('./App', () => {
      const NextApp = require('./App').default
      root.render(<NextApp />)
    })
  }
}
