import { createContext, useContext, useState } from 'react'
import { ConfirmationDialog, ConfirmationOptions } from './ConfirmationDialog'

interface ConfirmationServiceProps {
  id?: string
  onConfirm: Function
  onDeny: Function
  data?: any
}

const ConfirmationServiceContext = createContext<
  // we will pass the openning dialog function directly to consumers
  (props: ConfirmationServiceProps & ConfirmationOptions) => void
>(Promise.reject)

export const useConfirmation = () => useContext(ConfirmationServiceContext)

export const ConfirmationServiceProvider = ({ children }: any) => {
  // simple State to save the current displaying option for the dialog
  const [
    confirmationState,
    setConfirmationState,
  ] = useState<ConfirmationOptions | null>(null)
  const [
    idAndFunction,
    setIdAndFunction,
  ] = useState<ConfirmationServiceProps | null>(null)
  const [
    dataAndFunction,
    setDataAndFunction,
  ] = useState<ConfirmationServiceProps | null>(null)

  const openConfirmation = (
    props: ConfirmationServiceProps & ConfirmationOptions
  ) => {
    const { id, onConfirm, onDeny, data, ...optionsProps } = props
    if (id) {
      setIdAndFunction({
        id: id,
        onConfirm: onConfirm,
        onDeny: onDeny,
      })
    } else if (data) {
      setDataAndFunction({
        data: data,
        onConfirm: onConfirm,
        onDeny: onDeny,
      })
    } else {
      setIdAndFunction({
        onConfirm: onConfirm,
        onDeny: onDeny,
      })
    }

    const options = { ...optionsProps }
    setConfirmationState(options)
  }

  // pass down to Dialog
  const handleClose = () => {
    if (idAndFunction) {
      idAndFunction.onDeny(idAndFunction.id)
    }
    if (dataAndFunction) {
      dataAndFunction.onDeny(dataAndFunction.data)
    }

    setConfirmationState(null)
    setIdAndFunction(null)
  }

  // pass down to Dialog
  const handleSubmit = () => {
    if (idAndFunction?.id) {
      idAndFunction.onConfirm(idAndFunction.id)
    } else if (dataAndFunction?.data) {
      dataAndFunction.onConfirm(dataAndFunction.data)
    } else {
      idAndFunction?.onConfirm()
    }
    setConfirmationState(null)
    setIdAndFunction(null)
  }

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>

      <ConfirmationDialog
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  )
}
