import { Theme, Tooltip, useMediaQuery } from '@material-ui/core'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import AppsIcon from '@material-ui/icons/Apps'
import AssessmentIcon from '@material-ui/icons/Assessment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import BuildIcon from '@material-ui/icons/Build'
import CloudIcon from '@material-ui/icons/Cloud'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import DescriptionIcon from '@material-ui/icons/Description'
import FolderSharedIcon from '@material-ui/icons/FolderShared'
import GavelIcon from '@material-ui/icons/Gavel'
import TypesIcon from '@material-ui/icons/List'
import LocalPlayIcon from '@material-ui/icons/LocalPlay'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import MovieIcon from '@material-ui/icons/Movie'
import MovieFilterIcon from '@material-ui/icons/MovieFilter'
import PersonIcon from '@material-ui/icons/Person'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import PublicIcon from '@material-ui/icons/Public'
import SettingsIcon from '@material-ui/icons/Settings'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'
import StorageIcon from '@material-ui/icons/Storage'
import StoreIcon from '@material-ui/icons/Store'
import TheatersIcon from '@material-ui/icons/Theaters'
import { motion } from 'framer-motion'
import sortBy from 'lodash/sortBy'
import { useState } from 'react'
import {
  DashboardMenuItem,
  Menu,
  MenuItemLink,
  useGetIdentity,
  usePermissions,
} from 'react-admin'
import { useSelector } from 'react-redux'
import { hasComponent } from '../apps/AppPageLoader'
import { GetIdentity, Permissions } from '../authProvider'
import { smplTexts } from '../genericData/CustomTextMappings'
import { useSchemaTraverser } from '../hooks/useSchemaTraverser'
import {
  DASHBOARD_TABLES_DISABLE_RENDER_BY_ROLE,
  isFlexgoldCustomer,
} from '../lib/config'
import { renderTaggedMonitoringUsersMenus } from './CustomTaggedMonitoringUsersMenu'
import { renderTranscodingJobMenus } from './CustomTranscodingJobMenu'
import {
  ResourceDisplaySetting,
  ResourceIconSetting,
} from './ResourceDisplaySetting'
import SubMenu from './SubMenu'

const styles = {
  sublink: {
    display: 'flex',
    width: 'calc(100% - 0.75rem)',
    margin: '0.06rem 0.5rem',
    padding: '1rem 0.5rem 1rem 2rem',
    borderRadius: '0.5rem',
    height: 64,
  },
  sublinkWithIcon: {
    margin: '0.06rem 0.5rem',
    padding: '1rem 1.5rem',
    width: 'calc(100% - 0.8rem)',
    borderRadius: '0.5rem',
    height: 64,
  },
  mainLink: {
    margin: '0.06rem 0.5rem',
    padding: '1rem 0.5rem',
    background: '#00000008',
    width: 'calc(100% - 0.8rem)',
    borderRadius: '0.5rem',
    height: 64,
  },
}

type MenuName = 'menuApps' | 'menuTools' | 'menuTypes'

export const CustomMenu = () => {
  const childMenuOpen = (resType: string, type: string) => {
    // @ts-ignore TODO ts-ing ResourceDisplaySetting may help here?
    const displaySettings = ResourceDisplaySetting[resType]
    if (displaySettings?.[type]) {
      return true
    }
    return false
  }

  const {
    permissions,
    loaded: permissionLoaded,
  } = usePermissions<Permissions>()
  const { loaded: identityLoaded, identity } = useGetIdentity() as GetIdentity
  let noRenderTables: string[] = undefined

  const [state, setState] = useState({
    menuApps: true,
    menuAppsSubMenu: Object.keys(ResourceDisplaySetting).reduce(
      (obj: { [dataTypeName in string]: boolean }, key) => {
        obj[key] = childMenuOpen(key, window.location.pathname.slice(1))
        return obj
      },
      {}
    ),
    menuTools: false,
    menuTypes: false,
  })
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'))

  // SchemaTraverserser
  const schemaTraverser = useSchemaTraverser()
  if (!schemaTraverser) {
    throw new Error('no schema traverser')
  }

  // get datas from store with hooks
  const open = useSelector((state: any) => state.admin.ui.sidebarOpen)
  const apps = useSelector((state: any) => state.apps)

  useSelector((state: any) => state.theme) // force rerender on theme change

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }))
  }

  const handleToggleSubMenu = (subMenu: string) => {
    setState((state) => {
      const newState = {
        ...state,
        menuAppsSubMenu: {
          ...state.menuAppsSubMenu,
          [subMenu]: !state.menuAppsSubMenu[subMenu],
        },
      }
      return newState
    })
  }

  const shouldRenderResource = (resType: string, resName: string) => {
    const settings = ResourceDisplaySetting?.[resType]
    const { canSelect } = permissions?.tablePermissions?.[resName] || {}
    // only render on side menu if user can select the resource
    if (settings?.[resName] && canSelect) {
      if (noRenderTables) {
        if (!noRenderTables.includes(resName)) {
          return resName
        } else {
          return null
        }
      } else {
        return resName
      }
    }
    return null
  }

  const queryIconFor = (resType: string) => {
    // @ts-ignore TODO ts-ing ResourceDisplaySetting may help here?
    const icon = ResourceIconSetting[resType]
    switch (icon) {
      case 'CMSIcon':
        return <StorageIcon />
      case 'TheatersIcon':
        return <TheatersIcon />
      case 'TranscodingIcon':
        return <MovieFilterIcon />
      case 'GravelsIcon':
        return <GavelIcon />
      case 'TicketsIcon':
        return <LocalPlayIcon />
      case 'PaymentsIcon':
        return <AttachMoneyIcon />
      case 'MessageIcon':
        return <MailOutlineIcon />
      case 'SettingsIcon':
        return <SettingsIcon />
      case 'VaultIcon':
        return <AccountBalanceIcon />
      case 'CustomerIcon':
        return <FolderSharedIcon />
      case 'OrdersIcon':
        return <ShoppingCartIcon />
      case 'taggedMonitoringUsers':
        return <MovieFilterIcon />
      case 'LogsIcon':
        return <DescriptionIcon />
      case 'OtherIcon':
        return <MoreVertIcon />
      case 'TermsAndCondtionsIcon':
        return <MailOutlineIcon />
      default:
        return <TypesIcon />
    }
  }

  const renderResource = (resType: string) => {
    const icon = queryIconFor(resType)
    const subMenu = (
      <SubMenu
        key={resType}
        handleToggle={() => handleToggleSubMenu(resType)}
        isOpen={state.menuAppsSubMenu[resType]} // || openOnUrl(resType) active Menu bug
        sidebarIsOpen={open}
        name={
          resType === 'taggedUsers' ? 'Tagged Users' : `pos.menu.${resType}`
        }
        icon={icon}
      >
        {[...schemaTraverser.resourceNames]
          .sort()
          .filter((name) => shouldRenderResource(resType, name))
          .map((name, i) => {
            // FLEX-1865 add new custom menu for tagged monitoring users
            if (isFlexgoldCustomer && resType === 'taggedUsers') {
              return renderTaggedMonitoringUsersMenus()
            }

            // ARD-106 - improve transcoding UI
            if (name === 'TranscodingJob') {
              return renderTranscodingJobMenus()
            } else {
              return (
                <motion.div
                  key={name}
                  initial={{ x: -150 }}
                  animate={{ x: 0 }}
                  transition={{ delay: i / 25 }}
                >
                  <Tooltip
                    key={name}
                    placement="right"
                    title={
                      smplTexts.menu[name]?.tooltip
                        ? smplTexts.menu[name].tooltip
                        : ''
                    }
                  >
                    <MenuItemLink
                      key={name}
                      to={`/${name}`}
                      leftIcon={
                        smplTexts.menu[name]?.icon ? (
                          smplTexts.menu[name].icon
                        ) : (
                          <AppsIcon />
                        )
                      }
                      primaryText={
                        smplTexts.menu[name]?.menuName
                          ? smplTexts.menu[name].menuName
                          : name
                      }
                      style={styles.sublink}
                    />
                  </Tooltip>
                </motion.div>
              )
            }
          })}
      </SubMenu>
    )
    if (subMenu.props.children.length > 0) {
      return subMenu
    }
    return null
  }

  const activeApps = sortBy(
    (apps || [])
      .filter(
        (app: { [dataTypeName in string]: string | null }) =>
          app.state === 'enabled'
      )
      .filter(
        // TODO 'string' type somehow don't work
        ({ shortKey }: any) => hasComponent(shortKey) && shortKey !== 'insights'
      ),
    'name'
  ) // insights is in dashboard

  if (!permissionLoaded || !identityLoaded) {
    return null
  }

  noRenderTables = (JSON.parse(DASHBOARD_TABLES_DISABLE_RENDER_BY_ROLE) as [
    {
      role: string
      tableNames: string[]
    }
  ])
    .filter((r) => r.role === identity.role)
    .pop()?.tableNames

  return (
    <motion.div initial={{ x: -150 }} animate={{ x: 0 }}>
      <Menu
        className="sidebar__menu"
        // @ts-expect-error
        style={{ width: 240 }}
      >
        <DashboardMenuItem />
        {/* <SubMenu
        handleToggle={() => handleToggle('menuApps')}
        isOpen={state.menuApps}
        sidebarIsOpen={open}
        name="pos.menu.apps"
        icon={<AppsIcon />}
      > */}

        {
          // Resource as Submenu, should later coming from database instead of ResourceDisplaySetting.js
        }

        {Object.keys(ResourceDisplaySetting).length > 0
          ? Object.keys(ResourceDisplaySetting).map((resType) =>
              renderResource(resType)
            )
          : ''}

        {permissions?.tablePermissions?.['User']?.canSelect ? (
          noRenderTables ? (
            !noRenderTables.includes('User') ? (
              <MenuItemLink
                to={`/User`}
                primaryText="User"
                style={styles.mainLink}
                leftIcon={<PersonIcon />}
              />
            ) : null
          ) : (
            <MenuItemLink
              to={`/User`}
              primaryText="User"
              style={styles.mainLink}
              leftIcon={<PersonIcon />}
            />
          )
        ) : null}

        {
          // App Store - only admin are allowed to see currently
        }

        {identity.role === 'admin' ? (
          <MenuItemLink
            to={`/apps/store`}
            primaryText="App Store"
            style={styles.mainLink}
            leftIcon={<StoreIcon />}
          />
        ) : null}

        {activeApps.length > 0 ? (
          <SubMenu
            handleToggle={() => handleToggle('menuTools')}
            isOpen={state.menuTools}
            sidebarIsOpen={open}
            name="pos.menu.tools"
            icon={<BuildIcon />}
          >
            {
              // GraphQlDebugger & Movie Transcoding & Set User Country
            }
            {activeApps.map(
              (app: { [dataTypeName in string]: string | null }, i: any) => {
                return (
                  <motion.div
                    key={app.shortKey}
                    initial={{ x: -150 }}
                    animate={{ x: 0 }}
                    transition={{ delay: i / 25 }}
                  >
                    <MenuItemLink
                      key={app.shortKey}
                      to={`/apps/${app.shortKey}`}
                      primaryText={app.name}
                      style={styles.sublinkWithIcon}
                      leftIcon={
                        app.shortKey === 'movieCache' ? (
                          <MovieIcon />
                        ) : app.shortKey === 'userCountry' ? (
                          <PublicIcon />
                        ) : app.shortKey === 'transcoding' ? (
                          <CloudUploadIcon />
                        ) : app.shortKey === 'licenseReport' ? (
                          <AssessmentIcon />
                        ) : app.shortKey === 'brokerSetupWizard' ? (
                          <PersonAddIcon />
                        ) : app.shortKey === 'futureOrdersReport' ? (
                          <ShoppingCartIcon />
                        ) : (
                          <CloudIcon />
                        )
                      }
                    />
                  </motion.div>
                )
              }
            )}
          </SubMenu>
        ) : null}
        {
          // All Types
        }
        {
          // For flexgold we don't want to show the Advanced menu. It's content is displayed in the corresponding submenus
          isFlexgoldCustomer ? null : (
            <SubMenu
              handleToggle={() => handleToggle('menuTypes')}
              isOpen={state.menuTypes}
              sidebarIsOpen={open}
              name="pos.menu.types"
              icon={<TypesIcon />}
            >
              {[...schemaTraverser.resourceNames]
                .filter((resName) => {
                  if (permissions?.tablePermissions?.[resName]?.canSelect) {
                    if (noRenderTables) {
                      if (!noRenderTables.includes(resName)) {
                        return true
                      } else {
                        return false
                      }
                    } else {
                      return true
                    }
                  }
                })
                .sort()
                .map((resName) => (
                  <MenuItemLink
                    key={resName}
                    to={`/${resName}`}
                    primaryText={resName === 'CmsSery' ? 'CmsSeries' : resName}
                    // leftIcon={<users.icon />}
                    style={styles.sublink}
                  />
                ))}
            </SubMenu>
          )
        }
      </Menu>
    </motion.div>
  )
}
