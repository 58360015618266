import get from 'lodash/get'
import lowerFirst from 'lodash/lowerFirst'
import pluralize from 'pluralize'
import { FieldTraverser } from './SchemaTraverser'

// We detect one-to-many relations based on the objects fields that return the <TypeName>Connection type
// we use the same pluralization library as PostGraphQL
export function getOneToManyRelationshipFieldData({
  name,
  field,
}: FieldTraverser) {
  if (name.endsWith('CreatedById') || name.endsWith('LastModifiedById')) {
    return null
  }
  if (get(field, 'type.ofType.kind') !== 'OBJECT') {
    return null
  }

  const fieldName = lowerFirst(field.name.split('By')[1]) // fields are always beginning with a lowercase letter!
  const relatedField = pluralize.singular(fieldName)

  return {
    relatedType: pluralize.singular(
      get(field, 'type.ofType.name').replace(/Connection$/, '')
    ),
    relatedField,
  }
}
