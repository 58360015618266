export function LoadingSpinner() {
  return (
    <div className="smpl__loader-container">
      <div className="smpl__loader-megacube">
        <div className="smpl__loader-col">
          <div className="smpl__loader-cube"></div>
          <div className="smpl__loader-cube"></div>
          <div className="smpl__loader-cube"></div>
        </div>
        <div className="smpl__loader-col">
          <div className="smpl__loader-cube"></div>
          <div className="smpl__loader-cube"></div>
          <div className="smpl__loader-cube"></div>
        </div>
        <div className="smpl__loader-col">
          <div className="smpl__loader-cube"></div>
          <div className="smpl__loader-cube"></div>
          <div className="smpl__loader-cube"></div>
        </div>
      </div>
    </div>
  )
}
